// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ControlsBar component should render without throwing an error 1`] = `
<Header
  toolbar={
    <Memo(wrappedComponent)
      currentUser={{}}
      diagramData={{}}
      flowData={{}}
    />
  }
  toolbarActions={
    <ToolbarActions
      exportOptions={
        [
          {
            "disabled": false,
            "exportArgs": {
              "graphId": {},
            },
            "text": "Export Process (.csv)",
          },
        ]
      }
      graphId={{}}
      openModal={[Function]}
    />
  }
/>
`;
