import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Menu, MenuItem, Popconfirm } from '@fortressiq/fiq-ds'
import qs from 'qs'

import ExportMenuItem from 'components/ExportButton/ExportMenuItem'
import ActionMenu from 'components/TrinityTable/ActionMenu/ActionMenu'

import callbackStore from 'stores/callback'
import handleIntegration from 'app/admin/integrations/helpers/handleIntegration'

import api from 'lib/Api'

const ActionsMenu = ({
  miningRun: { filterRules = {}, miningType, status, deleteState: [isDeleting] = [], ...miningRunDefault },
  reFetch,
  setShowFormModal,
  setClonedMiningRun,
  integrations,
  isLoadingIntegrations,
}) => {
  const [miningRun, setMiningRun] = useState(miningRunDefault)

  const params = {}
  if (filterRules?.rules && filterRules.rules.length) {
    params.filter = filterRules
  }

  const deleteRunHandler = async runId => {
    await api.delete(`/mining_runs/${runId}`)
    reFetch()
  }

  const onReady = args => {
    setMiningRun({
      ...miningRun,
      eventLogExportIds: [...miningRun.eventLogExportIds, args.eventLogExport.id],
    })
    callbackStore.call('eventLogExportSuccess', args)
  }

  const useReport = async () => {
    const { data: newData } = await api.get(`/process-boundary/show/${miningRun.id}`)
    const startSignatures = newData.start_stop_rankings
      .filter(item => item.user_selected && item.type === 'start')
      .map(item => ({ primary: item.screen_signature, secondary_signature_level: 'none' }))
    const endSignatures = newData.start_stop_rankings
      .filter(item => item.user_selected && item.type === 'end')
      .map(item => ({ primary: item.screen_signature, secondary_signature_level: 'none' }))
    const clonedMiningRun = {
      signatureLevel: 'none',
      miningType: 'default',
      fromReport: true,
      miningParams: {
        ...miningRun.miningParams,
        signature_level: 'screen_signature',
        end_signatures: { signatures: endSignatures },
        start_signatures: { signatures: startSignatures },
      },
      filterRules,
    }
    setClonedMiningRun(clonedMiningRun)
    setShowFormModal(true)
  }

  const menu = (
    <Menu bordered={false} dark={true}>
      {miningRun?.miningParams?.signature_level !== 'signature' && (
        <MenuItem
          key='clone-menu-item'
          onClick={() => {
            setClonedMiningRun({ ...miningRun, filterRules, miningType })
            setShowFormModal(true)
          }}
        >
          Clone
        </MenuItem>
      )}
      <MenuItem key='delete-menu-item' onClick={event => event.stopPropagation()}>
        <Popconfirm
          cancelText='No'
          okText='Yes'
          onConfirm={() => {
            deleteRunHandler(miningRun.id)
          }}
          placement='left-center'
          title='Are you sure you want to delete this mining run and all of its associated objects?'
        >
          {isDeleting ? 'Deleting' : 'Delete'}
        </Popconfirm>
      </MenuItem>
      {miningType !== 'report' && !!miningRun.eventLogExportIds.length && (
        <MenuItem key='view-exports-menu-item'>
          <Link to={`/event-log-exports?${miningRun.eventLogExportIds.map(eleId => `ids=${eleId}`).join('&')}`}>
            View Exports ({miningRun.eventLogExportIds.length})
          </Link>
        </MenuItem>
      )}
      {miningType !== 'report' && (
        <ExportMenuItem
          itemText='Export Mining Run as .csv'
          exportAPI={`/mining_runs/${miningRun.id}/export`}
          exportCallback={onReady}
          exportArgs={miningRun}
          exportName={`${miningRun.id}-mining_run`}
          callbackStore={callbackStore}
        />
      )}
      {miningType !== 'report' && !!Object.keys(params).length && (
        <MenuItem key='explore-link-menu-item'>
          <Link to={`/events?${qs.stringify(params)}`}>Explore Events</Link>
        </MenuItem>
      )}
      {!isLoadingIntegrations &&
        integrations.map(integration => (
          <MenuItem
            key={`integration-${integration.id}`}
            onClick={() => handleIntegration({ integrationId: integration.id, objectId: miningRun.id })}
          >
            <div>{integration.title}</div>
          </MenuItem>
        ))}
      {miningType === 'report' && status === 'done' && (
        <Fragment>
          <MenuItem key='view-report-menu-item'>
            <Link to={`/process-boundary/${miningRun.id}`}>View Process Boundary Detection</Link>
          </MenuItem>
          <MenuItem key='use-report-menu-item' onClick={useReport}>
            Create Mining Run from Report
          </MenuItem>
        </Fragment>
      )}
    </Menu>
  )
  return <ActionMenu menu={menu} />
}

export default ActionsMenu
