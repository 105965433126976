export const ADD_WEB_APPLICATION = 'ADD_WEB_APPLICATION'
export const GOT_APPLICATIONS_WITH_COUNTS = 'GOT_APPLICATIONS_WITH_COUNTS'
export const RESET_APPLICATIONS = 'RESET_APPLICATIONS'
export const SET_CLAIMING_EVENTS = 'SET_CLAIMING_EVENTS'
export const EVENTS_UPDATE = 'EVENTS_UPDATE'
export const EDIT_APPLICATION = 'EDIT_APPLICATION'
export const APPLICATION_DELETED = 'APPLICATION_DELETED'
export const COUNT_UPDATE = 'COUNT_UPDATE'
export const UPDATE_INFO = 'UPDATE_INFO'

export const CLEAR_UNCLAIMED = 'CLEAR_UNCLAIMED'
export const FETCH_UNCLAIMED = 'FETCH_UNCLAIMED'
export const TOGGLE_GROUP_TYPE = 'TOGGLE_GROUP_TYPE'
