import React, { Fragment } from 'react'
import { toJS } from 'mobx'

import Can from 'components/can/Can'

import EditDecision from './EditDecision'
import ActivityControlPanel from './ActivityControlPanel'
import NodeControls from './NodeControls'

import store from '../../stores/processExplorerStore'
import css from './nodeInspectorStyle.scss'

const NodeInspector = ({ onCollapse, onRemove, onExpand, menu }) => {
  const JSviewingNode = toJS(store.viewingNode)

  if (Object.keys(JSviewingNode).length > 0) {
    return (
      <div className={css.nodeInspectorContainer}>
        {store.viewingNode.data.type === 'decision' ? (
          <EditDecision />
        ) : (
          <Fragment>
            <ActivityControlPanel store={store} />
            <Can perform='/process-explorer:node-controls'>
              <NodeControls menu={menu} onCollapse={onCollapse} onRemove={onRemove} onExpand={onExpand} />
            </Can>
          </Fragment>
        )}
      </div>
    )
  } else {
    return <div className={`${css.container} ${css.empty}`}>Select a node to view details.</div>
  }
}

export default NodeInspector
