import React, { Fragment, useEffect } from 'react'
import { Heading } from '@fortressiq/fiq-ds'
import Main from 'components/main/Main'

import { useHeaderDispatch } from '../header/HeaderContext'

const gitCommit = `${GIT_BRANCH}: ${GIT_SHA}`
const appVersion = document.getElementById('fiq:app_version').getAttribute('content')

const pageTitle = 'Support'

const SupportPage = () => {
  const headerDispatch = useHeaderDispatch()

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: pageTitle,
      heading: (
        <Fragment>
          <Heading className='header-title' level={1}>
            {pageTitle}
          </Heading>
          <Heading className='header-title' level={2}>
            We&apos;re here to help.
          </Heading>
        </Fragment>
      ),
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  return (
    <Main>
      <Heading level={2}>Contact</Heading>
      <p>
        To learn more about Automation Anywhere Process Discovery visit our{' '}
        <a href='https://docs.automationanywhere.com' rel='noreferrer' target='_blank'>
          documentation
        </a>{' '}
        or create a support case via the{' '}
        <a href='https://apeople.automationanywhere.com' rel='noreferrer' target='_blank'>
          Automation Anywhere support portal
        </a>
        .
      </p>

      <Heading level={3}>Version {appVersion}</Heading>
      <p>{gitCommit}</p>
    </Main>
  )
}

export default SupportPage
