import React from 'react'
import { Typography, Tooltip, theme } from '@fortressiq/fiq-ds'

import controlTypeOptions from 'lib/controlTypeOptions'
import css from './eventDetailBarStyle.scss'

const { Paragraph } = Typography

const DetailTooltipTitle = ({ value }) => (
  <Paragraph copyable={true} style={{ color: theme.white, fontSize: theme['font-size-sm'] }}>
    {value}
  </Paragraph>
)

const Cell = ({ label, value }) => (
  <div className={css.cell}>
    <div className={css.label}>{label}</div>
    {value && (
      <Tooltip title={<DetailTooltipTitle value={value} />}>
        <div className={css.value}>{value}</div>
      </Tooltip>
    )}
  </div>
)

const EventDetailBar = ({ event, parentWidth }) => {
  const e = event || {}
  const controlType = controlTypeOptions.find(option => option.value === e.controlType)
  const controlTypeLabel = controlType ? controlType.label : ''

  return (
    <div className={css.eventDetailBar} style={{ width: `calc(${parentWidth})` }}>
      <Cell label='Title' value={e.title} />
      <Cell label='Application' value={e.application} />
      <Cell label='Action' value={e.action} />
      <Cell label='Value' value={e.value} />
      <Cell label='Id' value={e.id} />
      <Cell label='Screen' value={e.screen} />
      <Cell label='Control' value={controlTypeLabel} />
      <Cell label='Field' value={e.field} />
    </div>
  )
}

export default EventDetailBar
