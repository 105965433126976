import React, { useContext, useState } from 'react'
import startCase from 'lodash/startCase'
import { Button, ButtonGroup, Icon } from '@fortressiq/fiq-ds'

import { useInterval } from 'lib/hooks'
import WindowFocusHandler from 'components/WindowFocusHandler/WindowFocusHandler'

import css from './eventTimeline.scss'
import { Context } from './Context'

const SlideDetails = () => {
  const { prevSlide, nextSlide, activeEvent } = useContext(Context)
  const { id, application, title, field, observedAt: eventObservedAt = new Date() } = activeEvent
  const observedAt = new Date(eventObservedAt).toLocaleString('en-us')
  const details = { id, application, title, field, observedAt }
  const [isPlaying, setIsPlaying] = useState(false)

  useInterval(
    () => {
      nextSlide()
    },
    isPlaying ? 1500 : null
  )

  return (
    <WindowFocusHandler onBlur={() => setIsPlaying(false)}>
      <div className={css.eventDetails}>
        <ul>
          {Object.entries(details).map(([key, val]) => {
            return (
              !!val && (
                <li key={`event-${id}-${key}`}>
                  <strong>{startCase(key)}</strong>
                  {val}
                </li>
              )
            )
          })}
        </ul>
        <div>
          <ButtonGroup>
            <Button onClick={prevSlide} type='secondary'>
              <Icon icon='backward' size='nested' />
            </Button>
            <Button onClick={() => setIsPlaying(!isPlaying)} type='secondary'>
              {isPlaying ? <Icon icon='stop' size='nested' /> : <Icon icon='play' size='nested' />}
            </Button>
            <Button onClick={nextSlide} type='secondary'>
              <Icon icon='forward' size='nested' />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </WindowFocusHandler>
  )
}

export default SlideDetails
