// TODO will be moved to DS
// TODO consider to move sidebar boxShadow value and sidebar width to DS
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Icon, useFela, theme } from '@fortressiq/fiq-ds'
import { motion, AnimatePresence } from 'framer-motion'
import { bool, func } from 'prop-types'

import { sidebarHeader, sidebarScroller, sidebarWrapper, widths } from './styles'

export const sidebarEasing = [0.04, 0.62, 0.23, 0.98]
export const sidebarVariants = {
  open: { opacity: 1, transition: { duration: 0.6, ease: sidebarEasing } },
  closed: { opacity: 0, transition: { duration: 0.3, ease: sidebarEasing } },
}
const navVariants = {
  open: { width: widths.open, boxShadow: '3px 2px 10px 0 rgba(154, 162, 178, .2)' },
  collapsed: { width: widths.closed, boxShadow: '3px 2px 10px 0 rgba(154, 162, 178, 0)' },
}
const navTransition = { duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }
const md = theme['default-spacer-md']
const sm = theme['default-spacer-sm']

const Sidebar = ({ collapsedSidebar, setCollapsedSidebar, children }) => {
  const { css } = useFela({
    collapsedSidebar,
  })
  const trayDimension = 40
  const scaleFactor = 2.3
  const logoProps = {
    height: collapsedSidebar ? trayDimension : 41 * scaleFactor,
    icon: collapsedSidebar ? 'trayicon' : 'pddlogo',
    size: 'full',
    style: {
      ...(collapsedSidebar ? { paddingLeft: sm } : { padding: `${sm} 0 ${md}` }),
    },
    width: collapsedSidebar ? trayDimension : 80 * scaleFactor,
  }

  return (
    <motion.nav
      animate={collapsedSidebar ? 'collapsed' : 'open'}
      className={css(sidebarWrapper)}
      initial='collapsed'
      key='sidebar-wrapper'
      onMouseEnter={() => setCollapsedSidebar(false)}
      onMouseLeave={() => setCollapsedSidebar(true)}
      transition={navTransition}
      variants={navVariants}
    >
      <Link className={css(sidebarHeader)} to='/'>
        <AnimatePresence initial={false}>
          <Icon {...logoProps} />
        </AnimatePresence>
      </Link>
      <div className={css(sidebarScroller)}>{children}</div>
    </motion.nav>
  )
}

Sidebar.propTypes = {
  collapsedSidebar: bool.isRequired,
  setCollapsedSidebar: func.isRequired,
}

export default memo(Sidebar)
