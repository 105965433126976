import React, { Fragment } from 'react'
import { observer } from 'mobx-react'

import { Button, Element, Empty, Typography } from '@fortressiq/fiq-ds'

import { FLASH_LEVELS } from 'components/flash/Flash'
import localStorage from 'lib/Storage'

import Loader from 'components/loaders/MainLoader'

import api from 'lib/Api'
import { toAlpha } from 'lib/String'
import { getTimeElapsed } from 'lib/Time'
import parseApplicationData from 'lib/parseApplicationData'

import paneIcon from 'images/process_explore/panesIcon.svg'
import flashHOC from 'components/flash/flashHOC.jsx'

import { getFlowDataStats } from '../processes/utils.js'

import ProcessExplorationDiagram from './diagram/ProcessExplorationDiagram'

import DraggingNode from './diagram/DraggingNode'
import ControlsBar from './controls/ControlsBar'
import GroupsModal from './GroupsModal/GroupsModal'
import SubprocessModal from './subprocessModal/SubprocessModal'

import store from './stores/processExplorerStore'
import subprocessStore from './stores/subprocessStore'
import { diagramSectionCSS, emptyContainerCSS, procExploreCSS } from './styles/index.js'

@observer
class ProcessExplorer extends React.Component {
  constructor({ graphProcessId }) {
    super()

    store.graphId = graphProcessId

    this.state = {
      fetchingDiagram: true,
      minedRun: {},
      flowDataStats: {},
      applicationData: { applications: {}, total: 0, order: [] },
    }

    this.saveNew = this.saveNew.bind(this)
  }

  componentDidMount() {
    this.setData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { flow_data: fData, process_data: processData, name, mined_run: minedRun, graphProcessId } = this.props

    if (prevProps.graphProcessId !== graphProcessId) {
      store.setDefaults()
      store.graphId = graphProcessId
      store.resetModes()
      subprocessStore.clear()
      store.groups.clearBtn()
    }

    if (name !== prevProps.name) {
      store.processName = name
    }

    if (prevProps.flow_data !== fData || prevProps.process_data !== processData) {
      this.setData()
    }

    if (minedRun !== prevState.minedRun) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ minedRun })
    }
  }

  //////////////// RENDER //////////////////////
  render() {
    const { history } = this.props

    const { fetchingDiagram, data, flowData, flowDataStats, maxDepth, minedRun, applicationData, graphProcess } =
      this.state

    const { groupsMode, editGroupMode } = store.groups
    const { subprocessMode } = subprocessStore

    return (
      <Element style={procExploreCSS}>
        <ControlsBar
          createGroup={this.createGroup}
          diagramData={data}
          duration={getTimeElapsed(flowDataStats.totalDuration)}
          flowData={flowData}
          graphId={store.graphId}
          processName={store.processName}
          saveNew={this.saveNew}
        />

        <Element id='diagramSection' style={diagramSectionCSS({ groupsMode: store.groups.groupsMode, subprocessMode })}>
          {fetchingDiagram && <Loader />}

          {!data && !fetchingDiagram && (
            <Empty
              description={
                <Fragment>
                  <Typography.Title level={4}>No Process selected</Typography.Title>
                  <Typography.Paragraph>Select a Process from the upper left dropdown.</Typography.Paragraph>
                </Fragment>
              }
              showImage={true}
              style={emptyContainerCSS}
            />
          )}
          {data && !fetchingDiagram && (
            <ProcessExplorationDiagram
              paneIcon={paneIcon}
              diagramData={data}
              applicationData={applicationData}
              maxDepth={maxDepth}
              history={history}
              flowData={flowData}
              flowDataStats={flowDataStats}
              toggleHideDuration={this.toggleHideDuration}
              minedRun={minedRun}
              graphProcess={graphProcess}
            />
          )}

          <div id='dragContainer'>
            <DraggingNode />
          </div>
        </Element>

        {subprocessMode && <SubprocessModal graphId={store.graphId} />}

        {(groupsMode || editGroupMode) && <GroupsModal />}
      </Element>
    )
  }

  setData = () => {
    const {
      flow_data: fData,
      process_data: processData,
      max_pane_count: maxPaneCount,
      name,
      mined_run: minedRun,
      graphProcess,
    } = this.props

    const flowData = fData
      ? fData.map((flow, index) => {
          let hideDuration = false
          const hideDurationList = localStorage.get('processEx_hiddenDurations')
          if (hideDurationList) hideDuration = hideDurationList[flow.id]
          return { fill: '0.5', key: toAlpha(index, false), hideDuration: hideDuration, ...flow }
        })
      : []
    store.setDefaults()

    this.setState({
      fetchingDiagram: false,
      data: processData,
      applicationData: this.getApplicationData(processData),
      flowData,
      flowDataStats: getFlowDataStats(flowData.filter(flow => !flow.hideDuration)),
      maxDepth: maxPaneCount,
      minedRun,
      graphProcess,
    })
    store.flowData = flowData
    store.processName = name

    store.zoom = 1

    store.groups.setGraphProcessId(store.graphId)
    store.groups.fetchGroups()
  }

  toggleHideDuration = id => {
    const { flowData: newFlowData } = this.state

    let currentPath = {}
    const entry = newFlowData.find(flow => flow.id === id)
    if (entry) {
      currentPath = { ...entry }
      entry.hideDuration = !entry.hideDuration
    } else {
      return
    }
    if (Object.keys(currentPath).length === 0) return

    const oldHidden = localStorage.get('processEx_hiddenDurations')
    const newHidden = { ...oldHidden }
    newHidden[id] = !currentPath.hideDuration
    localStorage.set('processEx_hiddenDurations', newHidden, true)

    this.setState({
      flowData: newFlowData,
      flowDataStats: getFlowDataStats(newFlowData.filter(flow => !flow.hideDuration)),
    })
    store.flowData = newFlowData
  }

  async saveNew(name) {
    const { addNotification } = this.props
    const {
      data: { graphProcess },
    } = await api.post(`/graph_processes/${store.graphId}/duplicate`, { name })

    addNotification({
      cta: <Button href={`/process-explorer/${graphProcess.id}`}>View {name}</Button>,
      description: 'Process was saved successfully.',
      message: 'Success!',
      type: FLASH_LEVELS.SUCCESS,
    })
  }

  getApplicationData = processData => {
    // get application information from process data
    const { total_steps: stepTotal } = this.props

    const { applications, total, order } = parseApplicationData(processData)

    return { applications, total, order, stepTotal }
  }
}

export default flashHOC(ProcessExplorer)
