import React, { useEffect } from 'react'

import { AdvancedCell, ApplicationIcon, Button, DateCell, Image } from '@fortressiq/fiq-ds'

import TrinityTable, { columnWidths } from 'components/TrinityTable'

import { useHeaderDispatch } from '../header/HeaderContext'

const pageTitle = 'Template Registry'

const sortKeyMap = {
  applicationId: 'application_id',
  controlLabel: 'control_label',
  matchedCount: 'matched_count',
  createdAt: 'created_at',
}

const TemplateList = () => {
  const headerDispatch = useHeaderDispatch()

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: pageTitle,
      heading: pageTitle,
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  const columns = [
    {
      Header: 'Control Label',
      accessor: 'controlLabel',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
    {
      Header: 'Application ID',
      accessor: 'applicationId',
    },
    {
      Header: 'Application',
      accessor: 'applicationName',
      disableSortBy: true,
      width: 100,
      Cell: ({
        value: applicationName,
        row: {
          original: { applicationIconUrl },
        },
      }) => (
        <ApplicationIcon name={applicationName} size={35} src={applicationIconUrl}>
          {applicationName}
        </ApplicationIcon>
      ),
    },
    {
      Header: 'Matched Count',
      accessor: 'matchedCount',
      width: 100,
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
      disableSortBy: true,

      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      width: columnWidths.date,
      Cell: ({ value }) => <DateCell timestamp={value} />,
    },
    {
      Header: 'File Name',
      accessor: 'fileName',
      disableSortBy: true,

      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
    {
      Header: 'Image',
      accessor: 'imageUrl',
      align: 'center',
      width: 100,
      disableSortBy: true,
      Cell: ({ value }) => <Image src={value} width='95%' />,
    },
  ]

  return (
    <TrinityTable
      columns={columns}
      dataKey='templates'
      sortKeyMap={sortKeyMap}
      tableId='templates-table'
      toolbarActions={[
        <Button key='new-template-button' href='/templates/new' type='secondary'>
          Create Template
        </Button>,
      ]}
      url='/template_images'
    />
  )
}

export default TemplateList
