import dfgColors from '../dfgColors'

export const containerStyles = {
  background: dfgColors.backgroundGray,
  height: '100%',
  width: '100%',
  display: 'flex',
}

export const arrowStyles = { position: 'absolute', top: 0, left: 0 }

export const reactFlowStyles = { flex: 1 }

export const miniMap = {
  boxShadow: '1px 1px 5px 1px rgba(0,0,0,.1)',
  backdropFilter: 'blur(5px)',
  opacity: 0.9,
}

export const canvasControls = { borderRadius: '12px', overflow: 'hidden' }

export const graphZIndex = {
  edge: -1,
  overEdge: 0,
  node: 1,
  overNode: 2,
}
