import React, { Fragment, useEffect, useState } from 'react'

import { func, string, shape, arrayOf } from 'prop-types'

import api from 'lib/Api'

import constructApplicationOptions from 'components/form/helpers/constructApplicationOptions'
import SignatureSelect from './SignatureSelect'

const signatureCache = {}

// NOTE: This component should be refactored with the help of backend
// Main issue we store app names instead of app IDs
// To get the signatures we first need to get the app IDs
// To get signatures for multiple app, its one api call for each app

const SignatureBuilder = ({
  formState: { applications: selectedApplicationNames, startSignature, endSignature },
  onSelectChange,
}) => {
  const [applicationsCache, setApplicationsCache] = useState()
  const [selectedApplicationIds, setSelectedApplicationIds] = useState()
  const [options, setOptions] = useState()
  const [isLoading, setIsLoading] = useState(!!selectedApplicationNames)

  // Get application ids from application names
  // Rerun if application names changes
  useEffect(() => {
    const createApplicationHash = async () => {
      const { data: allApplications } = await api.get('/applications/filters', { service: true })
      const applicationsHash = constructApplicationOptions(allApplications).reduce((hash, elem) => {
        hash[elem.value] = elem.id
        return hash
      }, {})

      setApplicationsCache(applicationsHash)
    }

    // if applicationsCache is not defined and selectedApplicationNames is not empty
    // make a cached object where app name is key and app id is value
    if (!applicationsCache && selectedApplicationNames) {
      createApplicationHash()
    }

    // if applicationsCache is created and selectedApplicationNames is not empty
    // get the applications ids based on the selected application names
    if (applicationsCache && selectedApplicationNames) {
      setSelectedApplicationIds(selectedApplicationNames.map(appKey => applicationsCache[appKey]))
    }
  }, [selectedApplicationNames, applicationsCache])

  // Get signatures based on application Ids
  // Rerun if application ids changes
  useEffect(() => {
    const getSignatures = async application => {
      setIsLoading(true)
      if (signatureCache[application]) {
        return signatureCache[application]
      } else {
        const { data: sigs } = await api.get(`/applications/${application}/signatures`, {
          applicationUuid: application,
        })
        signatureCache[application] = sigs
        return sigs
      }
    }

    if (selectedApplicationIds) {
      Promise.all(selectedApplicationIds.map(application => getSignatures(application))).then(values => {
        const array = values.reduce((acc, sigs) => acc.concat(sigs), [])
        setOptions(array.map(item => ({ label: item, value: item })))
        setIsLoading(false)
      })
    }
  }, [selectedApplicationIds])

  return (
    <Fragment>
      <SignatureSelect
        isLoading={isLoading}
        label='Start Signatures'
        name='startSignature'
        onChange={onSelectChange}
        options={options}
        value={startSignature}
      />
      <SignatureSelect
        isLoading={isLoading}
        label='End Signatures'
        name='endSignature'
        onChange={onSelectChange}
        options={options}
        value={endSignature}
      />
    </Fragment>
  )
}

SignatureBuilder.propTypes = {
  formState: shape({
    startSignature: arrayOf(string),
    endSignature: arrayOf(string),
    applications: arrayOf(string),
  }).isRequired,
  onSelectChange: func.isRequired,
}

export default SignatureBuilder
