import Localizer from 'lib/Localizer'
import uiStore from 'lib/UiStore'
import api from 'lib/Api'
import { parse } from 'query-string'
import { JsonParam, decodeQueryParams } from 'use-query-params'

const downloadCSV = async query => {
  const { globalFilter } = decodeQueryParams({ globalFilter: JsonParam }, parse(query))
  function saveFile(data, filename, mimetype) {
    if (!data) return
    const blob = data.constructor !== Blob ? new Blob([data], { type: mimetype || 'application/octet-stream' }) : data
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename)
      return
    }
    const lnk = document.createElement('a')
    const url = window.URL
    if (mimetype) {
      lnk.type = mimetype
    }
    lnk.download = filename || 'untitled'
    lnk.href = url.createObjectURL(blob)
    lnk.dispatchEvent(new MouseEvent('click'))
    setTimeout(url.revokeObjectURL.bind(url, lnk.href))
  }
  const columns = [
    'id',
    'name',
    'machine',
    'user',
    'tags',
    'firstSeenAt',
    'lastSeenAt',
    'totalByteSum',
    'totalEventSum',
  ]
  const params = {
    per_page: 1000,
    page: 1,
    filters: { ...globalFilter },
  }
  const {
    data: { observers },
  } = await api.get('/observers', params)
  const items = observers.map(observer => ({
    ...observer,
    totalByteSum: observer.stats.bytes,
    totalEventSum: observer.stats.events,
  }))
  const replacer = (key, value) => (value === null ? '' : value)
  let csv = items.map(row =>
    columns
      .map(fieldName => {
        switch (fieldName) {
          case 'firstSeenAt':
            return JSON.stringify(Localizer.asDate(row[fieldName]), replacer).replace(/\\"/g, '""')
          case 'lastSeenAt':
            return JSON.stringify(Localizer.asDate(row[fieldName]) || '--', replacer).replace(/\\"/g, '""')
          case 'tags': {
            const firstTag = row.tags?.shift()?.name
            const tagString = row.tags?.reduce((acc, tag) => `${acc},${tag.name}`, firstTag)
            if (tagString) return JSON.stringify(tagString, replacer).replace(/\\"/g, '""')
            return ''
          }
          default:
            return JSON.stringify(row[fieldName], replacer).replace(/\\"/g, '""')
        }
      })
      .join(',')
  )
  csv.unshift(
    '"Observer ID","Observer Name","Machine ID","User","Tags","Date Connected","Last Detected","Total Data Collected","Total Events"'
  )
  csv = csv.join('\r\n')
  const blobData = new Blob([csv])
  saveFile(blobData, `${uiStore.tenant.name}-observers.csv`)
}

export default downloadCSV
