import React, { useEffect, useState } from 'react'
import { Element } from '@fortressiq/fiq-ds'

import { strategyOptions } from 'lib/SignatureStrategies'

import { sidebarItemCSS } from '../styles'

import { strategyParamsMap, isJsonValue } from '../utilities'
import Form from './Form'

const Single = ({
  applicationId,
  currentProvisionalStrategyParams,
  errors,
  getValues,
  id,
  provisionalStrategy,
  register,
  setValue,
  single,
  trigger,
  unregister,
  watch,
}) => {
  const [provisionalStrategyParams, setProvisionalStrategyParams] = useState(false)
  const [select, setSelect] = useState()
  const [options, setOptions] = useState([])

  const watchSelectedStrategy = watch(`provisionalStrategy.${id}.params.name`, provisionalStrategy)

  useEffect(() => {
    setSelect(register(`provisionalStrategy.${id}.params.name`, { required: 'Provisional Strategy is required' }))
    return () => {
      unregister(`provisionalStrategy.${id}.params.name`)
    }
  }, [])

  useEffect(() => {
    if (provisionalStrategy) {
      setValue(`provisionalStrategy.${id}.params.name`, provisionalStrategy)
      trigger()
    }
  }, [provisionalStrategy])

  useEffect(() => {
    if (currentProvisionalStrategyParams) {
      setProvisionalStrategyParams(currentProvisionalStrategyParams)
      setValue(`provisionalStrategy.${id}.params.name`, provisionalStrategy)
      trigger()
    }
  }, [currentProvisionalStrategyParams])

  useEffect(() => {
    const fieldKeys = options && options.length ? options.map(option => option.value) : []
    if (typeof provisionalStrategyParams !== 'string') {
      const customParams = Object.entries(provisionalStrategyParams).reduce((acc, [key, val]) => {
        if (!fieldKeys.includes(key)) {
          acc[key] = val
        } else {
          setValue(`provisionalStrategy.${id}.params.${key}`, isJsonValue(key) ? JSON.stringify(val) : val)
        }
        return acc
      }, {})
      setValue(`provisionalStrategy.${id}.params.customParams`, customParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provisionalStrategyParams, options])

  useEffect(() => {
    if (watchSelectedStrategy) {
      setOptions(strategyParamsMap[watchSelectedStrategy])
    }
  }, [watchSelectedStrategy])

  const selectChange = val => {
    setValue(`provisionalStrategy.${id}.params.name`, val)

    if (val === provisionalStrategy) {
      setProvisionalStrategyParams(currentProvisionalStrategyParams)
    } else {
      setProvisionalStrategyParams({})
    }

    trigger()
  }

  if (!select) return null

  const selectOptions = strategyOptions.filter(
    strategy => strategy.selectable === undefined || strategy.selectable === 0
  )
  const containerStyle = { ...sidebarItemCSS, border: 0 }

  return (
    <Element style={containerStyle}>
      <Form
        applicationId={applicationId}
        collapseRef={null}
        currentProvisionalStrategyName={watchSelectedStrategy}
        errors={errors}
        getValues={getValues}
        id={id}
        provisionalStrategy={provisionalStrategy}
        provisionalStrategyParams={provisionalStrategyParams}
        register={register}
        select={select}
        selectChange={selectChange}
        selectOptions={selectOptions}
        setValue={setValue}
        single={single}
        trigger={trigger}
        unregister={unregister}
      />
    </Element>
  )
}

export default Single
