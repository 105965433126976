/* eslint-disable camelcase */
import { observable } from 'mobx'
import qs from 'qs'

const defaultPrivacySettings = Object.freeze({
  observers: {
    mode: 'default',
    active: true,
  },
})

class UiStore {
  @observable config

  @observable tenant

  @observable shibumi = null

  @observable showAdminNav = true

  setShibumiAuthToken = token => {
    this.shibumi.shibumiAuthToken = token
  }

  setShibumiIntegration = tenantData => {
    if (!tenantData.coeIntakeUrl || !tenantData.coeUsername) {
      return undefined
    }

    const { appName, ent_id, program_id, uguid } = qs.parse(tenantData.coeIntakeUrl)
    const region = tenantData.coeIntakeUrl.split('.')[1]

    this.shibumi = {
      appName: appName,
      entId: ent_id,
      programId: program_id,
      uguid: uguid,
      coeIntakeUrl: tenantData.coeIntakeUrl,
      coeUsername: tenantData.coeUsername,
      region: region === 'shibumi' ? 'app' : region,
    }
  }

  setTenantData = ({ privacySettings, ...tenantData }) => {
    this.tenant = {
      privacySettings: { ...defaultPrivacySettings, ...privacySettings },
      ...tenantData,
    }
    this.setShibumiIntegration(tenantData)
  }
}

export default new UiStore()
