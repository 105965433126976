import React, { Fragment, useState } from 'react'
import { arrayOf, func, shape, string } from 'prop-types'

import { Button, DateTimePickerRange, Element, Label, Select, theme } from '@fortressiq/fiq-ds'

import Autocomplete from './Autocomplete'
import { selectStyles } from './constants'

const Filter = ({ handleFilterChange, setShowing, globalFilterOptions, controlledState }) => {
  const [localFilter, setLocalFilter] = useState(controlledState.globalFilter || {})
  const setDate = date => (date ? new Date(date) : undefined)
  const [dates, setDates] = useState([
    setDate(localFilter?.createdDates?.start),
    setDate(localFilter?.createdDates?.end),
  ])
  const resetDates = () => setDates([undefined, undefined])

  const handleApplyFilter = () => {
    handleFilterChange(localFilter)
    setShowing(false)
  }

  const handleResetFilter = () => {
    handleFilterChange({})
    setLocalFilter({})
    resetDates()
  }

  const onDateChange = ([start, end]) => {
    setDates([start, end])
    setLocalFilter({
      ...localFilter,
      createdDates: { start: start || undefined, end: end || undefined },
    })
  }

  const onCheckboxChange = (value, field) => {
    setLocalFilter({
      ...localFilter,
      [field]: localFilter[field] ? undefined : value,
    })
  }

  const onTypeaheadChange = (values, field) => {
    setLocalFilter({
      ...localFilter,
      [field]: values?.length ? values : undefined,
    })
  }

  const onSelectChange = (values, field) => {
    setLocalFilter({
      ...localFilter,
      [field]: values?.value || undefined,
    })
  }

  return (
    <Element style={{ display: 'flex', flexWrap: 'wrap', width: 500 }}>
      {globalFilterOptions.map((filter, i) => {
        return (
          <Element
            key={`${filter.field}-container`}
            style={{
              marginBottom: theme['default-spacer-md'],
              ...filter?.wrapStyle,
              width: filter?.width || '100%',
            }}
          >
            {filter.type !== 'switch' && filter?.label !== 'Email' && (
              <Label htmlFor={filter.field}>{filter.label}</Label>
            )}
            {(() => {
              switch (filter.type) {
                case 'switch':
                  return (
                    <label>
                      <input
                        type='checkbox'
                        onChange={() => onCheckboxChange(filter.value, filter.field)}
                        checked={!!localFilter[filter.field]}
                      />{' '}
                      {filter.label}
                    </label>
                  )
                case 'rangePicker':
                  return (
                    <DateTimePickerRange
                      bordered={true}
                      id={filter.field}
                      onChange={ds => onDateChange(ds)}
                      placeholder='Select a range of dates...'
                      withPortal={true}
                      value={dates}
                    />
                  )
                case 'typeahead':
                  return (
                    <Autocomplete
                      key={`${filter.type}-${filter.field}`}
                      onChange={values => onTypeaheadChange(values, filter.field)}
                      optionsValueKey={filter.optionsValueKey}
                      optionsLabelKey={filter.optionsLabelKey}
                      params={filter.params}
                      placeholder={filter.label}
                      url={filter.url}
                      value={localFilter[filter.field]}
                    />
                  )
                case 'select':
                  return (
                    <Select
                      isClearable={true}
                      isSearchable={true}
                      onChange={values => onSelectChange(values, filter.field)}
                      options={filter?.options}
                      placeholder={filter.label}
                      styles={selectStyles}
                      value={
                        localFilter[filter.field]
                          ? filter?.options.find(option => option.value === localFilter[filter.field])
                          : null
                      }
                    />
                  )
                default:
                  return <Fragment>Missing field type {filter.type}</Fragment>
              }
            })()}
          </Element>
        )
      })}

      <Button onClick={handleResetFilter}>Reset</Button>
      <Button onClick={handleApplyFilter} style={{ marginLeft: theme['default-spacer-sm'] }} type='secondary'>
        Apply
      </Button>
    </Element>
  )
}

Filter.propTypes = {
  globalFilterOptions: arrayOf(
    shape({
      field: string.isRequired,
      label: string.isRequired,
      type: string.isRequired,
    })
  ).isRequired,
  handleFilterChange: func.isRequired,
  setShowing: func.isRequired,
}

export default Filter
