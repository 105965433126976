export const eventFilterFields = [
  { label: 'Action', name: 'action' },
  { label: 'Annotation Type', name: 'annotation_type' },
  { label: 'Application Id', name: 'application_id' },
  { label: 'Application Name', name: 'application_name' },
  { label: 'Application Type', name: 'application_type' },
  { label: 'Control Key', name: 'value' },
  { label: 'Control Type', name: 'control_type' },
  { label: 'Field', name: 'field' },
  { label: 'ID', name: 'id' },
  { label: 'Is Browser', name: 'is_browser' },
  { label: 'Machine', name: 'machine' },
  { label: 'Observed At', name: 'observed_at' },
  { label: 'Observer Id', name: 'observer_id' },
  { label: 'Observer Name', name: 'observer_name' },
  { label: 'Process Name', name: 'win_process_name' },
  { label: 'Screen Name', name: 'name' },
  { label: 'Screen Signature', name: 'screen_signature' },
  { label: 'Signature', name: 'signature' },
  { label: 'Title', name: 'title' },
  { label: 'Url', name: 'url' },
  { label: 'User', name: 'user' },
]

export const operators = {
  eq: { name: '=', label: '=' },
  gt: { name: '>', label: '>' },
  gteq: { name: '>=', label: '>=' },
  in: { name: 'in', label: 'In' },
  lt: { name: '<', label: '<' },
  lteq: { name: '<=', label: '<=' },
  neq: { name: '!=', label: '!=' },
  nin: { name: 'not in', label: 'Not In' },
  nNull: { name: 'notNull', label: 'Is Not Null' },
  null: { name: 'null', label: 'Is Null' },
  matches: { name: '~', label: 'Matches' },
}

export const multipleOperators = [operators.in.name, operators.nin.name]

const controlTypes = [
  'table',
  'table_column',
  'tab',
  'icon',
  'checkbox',
  'button',
  'list_box',
  'text_field',
  'radio_button',
]

const generateValues = (...args) => args.reduce((acc, cur) => [...acc, { label: cur, name: cur }], [])

export const boolValues = generateValues('true', 'false')
export const applicationTypeValues = generateValues('web', 'windows')
export const actionValues = generateValues('KEY_PRESS', 'MOUSE_CLICK', 'APP_SWITCH')
export const annotationTypeValues = generateValues('auto', 'user')
export const controlTypeValues = generateValues(...controlTypes)

export const allEventFilterOperators = Object.values(operators)
export const eventExportLimit = 5000

export default module.exports
