import React, { useState, useRef, useCallback, useLayoutEffect, useEffect, createContext, useMemo } from 'react'
import { useNotification } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

const loadLimit = 12 // # of events to request from api
const uniqifyEvents = events => Object.values(events.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}))
export const Context = createContext(null)
export const { Consumer } = Context

export const Provider = ({ children, eventId }) => {
  const eventSliderRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [activeSlideIndex, setActiveSlideIndex] = useState(loadLimit)
  const [events, setEvents] = useState([])
  const [hasMore, setHasMore] = useState({ prev: true, next: true })
  const { addNotification } = useNotification()

  const setScrollPosition = useCallback(
    (index = loadLimit, shouldAnimate = false) => {
      const activeSlide = eventSliderRef.current.children[index]
      if (activeSlide) {
        eventSliderRef.current.scrollTo({
          left: activeSlide.offsetLeft,
          behavior: shouldAnimate ? 'smooth' : 'auto',
        })
      }
    },
    [eventSliderRef]
  )

  const setActive = useCallback(
    slideIndex => {
      setActiveSlideIndex(slideIndex)
      setScrollPosition(slideIndex, true)
    },
    [setScrollPosition, setActiveSlideIndex]
  )
  const nextSlide = () => {
    const newIndex = activeSlideIndex + 1
    setActive(newIndex)
  }

  const prevSlide = () => {
    const newIndex = activeSlideIndex - 1
    setActive(newIndex)
  }

  const loadMoreEvents = useCallback(
    async (type = 'next') => {
      setIsLoading(true)
      const activeEvent = events[activeSlideIndex]
      const {
        data: { events: loadedEvents },
      } = await api.get(`/event_logs/${activeEvent.id}/${type}`, { limit: loadLimit })
      if (type === 'next') {
        setEvents(uniqifyEvents([...events, ...loadedEvents]))
        if (!loadedEvents.length) {
          addNotification({ description: "You've reached the end of this timeline", message: 'Note', type: 'info' })
          setHasMore({ ...hasMore, next: false })
        }
      } else {
        if (loadedEvents.length) {
          setActiveSlideIndex(loadLimit)
          setScrollPosition(loadLimit, false)
        } else {
          addNotification({
            description: "You've reached the beginning of this timeline",
            message: 'Note',
            type: 'info',
          })
          setHasMore({ ...hasMore, prev: false })
        }
        setEvents(uniqifyEvents([...loadedEvents, ...events]))
      }
      setIsLoading(false)
    },
    [events, activeSlideIndex, addNotification, hasMore, setScrollPosition]
  )

  const viewScreenshot = () => {
    const activeSlide = events[activeSlideIndex]
    const screenshot = window.open(`/screenshot/${activeSlide.id}`, '_blank')
    screenshot.focus()
  }

  useLayoutEffect(() => {
    setIsLoading(true)
    api.get(`/event_logs/${eventId}/adjacent`, { limit: loadLimit }).then(({ data: { events: initialEvents } }) => {
      setEvents(initialEvents)
      setScrollPosition()
      setIsLoading(false)
    })
  }, [eventSliderRef, setScrollPosition, eventId, setEvents])

  useEffect(() => {
    if (activeSlideIndex === 0 && hasMore.prev) {
      loadMoreEvents('prev')
    } else if (activeSlideIndex === events.length - 1 && hasMore.next) {
      loadMoreEvents('next')
    }
  }, [activeSlideIndex, events, loadMoreEvents, setActive, hasMore])

  const value = useMemo(() => ({
    activeEvent: events[activeSlideIndex] || {},
    activeSlideIndex,
    events,
    eventSliderRef,
    isLoading,
    nextSlide,
    prevSlide,
    setActive,
    setActiveSlideIndex,
    viewScreenshot,
  }))

  return <Context.Provider value={value}>{children}</Context.Provider>
}
