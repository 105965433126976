export const overviewContainerCSS = ({ theme }) => ({
  background: theme['legacy-color-gray'],
  borderBottom: `1px solid ${theme['antd-card-border']}`,
  padding: theme['default-spacer-lg'],
})

export const giftCSS = () => ({
  marginRight: '5px',
  position: 'relative',
  top: 0,
})

export const sparkTextCSS = () => ({
  minWidth: '50px',
})
export const actionsIconCSS = () => ({
  marginRight: '19px',
})
