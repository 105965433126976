import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { Button, Element, helpers, Overlay, theme, Tooltip, useFela, useModal } from '@fortressiq/fiq-ds'
import classNames from 'classnames'
import { observer } from 'mobx-react'

import Can from 'components/can/Can'
import store from '../ClientDashboardStore'

import AddProcessModal from './AddProcessModal'

import { dropdownContainerCSS, menuLinkCSS } from '../../header/styles'

const AdminMenu = observer(() => {
  const [showing, setShowing] = useState(false)
  const { css } = useFela()
  const { addModal, removeModal } = useModal()
  const modalID = 'client-dashboard-add-process-modal'

  const modalMenuItem = useMemo(() => {
    return {
      icon: 'add',
      onClick: () => {
        setShowing(false)

        addModal({
          children: <AddProcessModal removeModal={removeModal} modalID={modalID} />,
          header: 'Add New Process',
          id: modalID,
          footer: false,
          config: { interactWithKeyboard: { closeOnEnter: false, closeOnEscape: true } },
        })
      },
      name: 'Add New Process',
      type: 'Process',
    }
  }, [addModal, removeModal])

  const menu = [modalMenuItem, ...store.dashboardCardsArray]

  const trigger = {
    showing: showing,
    setShowing: setShowing,
    text: 'Menu',
    width: 'auto',
    type: 'default',
  }
  const height = 32
  const heights = {
    height: height * 6,
  }
  const widths = {
    min: 200,
    max: 440,
    width: 'auto',
  }
  const placement = {
    overflow: {
      adjustX: true,
      adjustY: true,
    },
    points: ['br', 'tr'],
    targetOffset: [0, 8],
    useCssTransform: false,
  }

  const config = {
    disableOutsideClick: false,
    trigger: {
      button: {
        style: {
          borderRadius: theme['default-spacer-xs'],
        },
      },
      icons: {
        prefix: {
          icon: 'cog',
          size: 14,
        },
      },
    },
    content: {
      style: {
        background: theme.white,
        borderBottomLeftRadius: theme['default-spacer-sm'],
        borderBottomRightRadius: theme['default-spacer-sm'],
        border: `1px solid ${theme['table-border-color']}`,
        position: 'absolute',
        overflow: 'auto',
        '& .fiqds-overlay-dropdown': { border: 'none' },
      },
    },
  }
  const widthCSS = { width: helpers.formatString(widths.width) }

  const content = (
    <nav className={classNames('fiqds-overlay-dropdown', css(dropdownContainerCSS))} style={widthCSS}>
      {menu.map(({ icon, id, name, onClick, type }, i) => {
        const otherClick = type === 'Asset' ? () => store.removeUpload(id) : () => store.deleteCard(id)
        const tooltipText = `Delete ${name || '"Unnamed"'}`

        return (
          <Tooltip
            disabled={icon}
            key={`${name}-${i}`}
            mouseEnterDelay={600}
            placement='left-center'
            title={icon ? '' : tooltipText}
          >
            <Button
              className={classNames([css(menuLinkCSS), 'fiqds-overlay-dropdown-menuitem-text'])}
              onClick={onClick || otherClick}
              style={widthCSS}
              suffixIcon={true}
              suffixIconProps={{ icon: icon || 'trash', size: 16 }}
              type='link'
            >
              {name || <Element style={{ color: theme['gray-tints-0'], fontStyle: 'italic' }}>Unnamed</Element>}
            </Button>
          </Tooltip>
        )
      })}
    </nav>
  )

  return (
    <Can perform='/client-dashboard:open-admin-menu'>
      <Overlay
        closeIcon={false}
        config={config}
        content={content}
        heights={heights}
        id='add-process-overlay'
        noPadding={true}
        placement={placement}
        trigger={trigger}
        widths={widths}
      />
    </Can>
  )
})

export default AdminMenu
