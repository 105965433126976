import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Badge, DateCell } from '@fortressiq/fiq-ds'

import { getActive } from 'lib/Time'

const statusColorMap = {
  active: 'success',
  some: 'warning',
  inactive: 'default',
}

const LastDetected = ({ lastSeenAt }) => {
  const status = getActive(lastSeenAt)

  return (
    <Fragment>
      <Badge status={statusColorMap[status]} />
      {lastSeenAt ? <DateCell timestamp={lastSeenAt} /> : '--'}
    </Fragment>
  )
}

LastDetected.propTypes = {
  lastSeenAt: PropTypes.string.isRequired,
}

export default LastDetected
