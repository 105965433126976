import React, { useEffect, useState } from 'react'
import { Select } from '@fortressiq/fiq-ds'
import { func, string, shape } from 'prop-types'

import { useFetch } from 'lib/hooks'
import { selectStyles } from './constants'

const Autocomplete = ({ url, params, placeholder, value, onChange, optionsLabelKey, optionsValueKey }) => {
  const { data = [], isLoading } = useFetch(url, params)
  const [selectOptions, setOptions] = useState([])
  const [selectValue, setSelectValue] = useState() //component uses different data structure for value
  const isDisabled = data.length === 0

  useEffect(() => {
    setOptions(
      data.map(item => ({
        label: item[optionsLabelKey],
        value: item[optionsValueKey],
      }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, optionsLabelKey, optionsValueKey])

  // reset filter, transform values for component
  useEffect(() => {
    if (!value) setSelectValue(undefined)
    else {
      setSelectValue(selectOptions.filter(option => value?.includes(option.value)))
    }
  }, [selectOptions, value])

  const onChangeHandler = values => {
    onChange(values?.map(v => v.value))
  }

  return (
    <Select
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={true}
      onChange={onChangeHandler}
      options={selectOptions}
      placeholder={placeholder}
      styles={selectStyles}
      value={selectValue}
    />
  )
}

Autocomplete.defaultProps = {
  optionsLabelKey: 'label',
  optionsValueKey: 'value',
  params: undefined,
}

Autocomplete.propTypes = {
  optionsLabelKey: string,
  optionsValueKey: string,
  url: string.isRequired,
  params: shape({}),
  placeholder: string.isRequired,
  onChange: func.isRequired,
}

export default Autocomplete
