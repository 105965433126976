import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/loaders/MainLoader'

const LoadingContainer = ({ loading, children }) => (loading ? <Loader /> : children)

LoadingContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default LoadingContainer
