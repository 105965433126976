import React from 'react'
import { format } from 'date-fns'
import { Heading } from '@fortressiq/fiq-ds'
import { DATE_FORMAT_SHORT } from 'constants/app-constants'

import css from './miningRun.scss'

const MiningRun = ({ id, date, parameters }) => (
  <section className={css.miningRun}>
    <div>
      <Heading level={4}>Mining Run</Heading>
      <p>{id}</p>
      <p>{format(new Date(date), DATE_FORMAT_SHORT)}</p>
    </div>
    <div>
      <Heading level={4}>Mining Parameters</Heading>
      <p>{parameters.replace(',', ' ')}</p>
    </div>
  </section>
)

export default MiningRun
