import React from 'react'
import { observer } from 'mobx-react'

import { ApplicationIcon, Group, Typography } from '@fortressiq/fiq-ds'
import DetailCard from '../../DetailCard'

import store from '../../ClientDashboardStore'

const AppsCard = observer(() => {
  const { currentCard } = store

  return (
    <DetailCard title='Applications'>
      {(!currentCard.applications || currentCard.applications.length === 0) && (
        <Typography.Text disabled={true}>No Applications Added</Typography.Text>
      )}
      {currentCard.applications &&
        currentCard.applications.map((app, index) => {
          return (
            <Group gutter={8} style={{ marginBottom: '8px' }} key={index}>
              <ApplicationIcon name={app.display_name} size={27} />
              <span>{app.display_name}</span>
            </Group>
          )
        })}
    </DetailCard>
  )
})

export default AppsCard
