import React, { Fragment, memo } from 'react'

import { Heading } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import MainLoader from 'components/loaders/MainLoader'

import css from './styles.scss'

import EventStats from './sections/EventStats'
import CalendarStats from './sections/CalendarStats'

import { HeaderDispatchContext } from '../header/HeaderContext'

class Dashboard extends React.Component {
  static contextType = HeaderDispatchContext

  constructor(props) {
    super(props)

    this.state = {
      fetching: true,
      legend: {},
      eventStats: {},
    }
  }

  async componentDidMount() {
    const { data } = await api.get('/dashboard')

    const { calendar: pCalendar, observers, processes } = data

    const legend = processes.by_states
      .map(item => item.state_name)
      .filter(item => item !== 'Installation' && item !== 'Approval')

    const eventStats = {
      'Active in Last 3 Days': observers.recent.toLocaleString(),
      'Events in Last 7 Days': observers.recent_logs.toLocaleString(),
      'Total Observers': observers.total.toLocaleString(),
      'Total Logs': observers.total_logs.toLocaleString(),
    }

    const calendar = pCalendar.sort((a, b) => {
      const startA = new Date(a.start_at)
      const startB = new Date(b.start_at)
      return startA - startB
    })

    const events = observers.event_counts.map(event => {
      return { ...event, day: event.day }
    })

    this.setState({
      fetching: false,
      eventStats: eventStats,
      legend: legend,
      events: events,
      calendar: calendar,
      latestRuns: observers.last_runs,
    })

    this.context({
      type: 'set',
      heading: (
        <Fragment>
          <Heading className='header-title' level={1}>
            Dashboard
          </Heading>
          <Heading className='header-title' level={2}>
            What&apos;s new in your account.
          </Heading>
        </Fragment>
      ),
      title: 'Dashboard',
    })
  }

  componentWillUnmount() {
    this.context({
      type: 'clear',
    })
  }

  render() {
    const { fetching, legend, calendar, eventStats, events, latestRuns } = this.state

    return (
      <div className={css.dashboard}>
        <div className={css.greyBack}>
          {fetching ? (
            <MainLoader />
          ) : (
            <div className={css.dashCardGroup}>
              <EventStats
                legend={legend}
                openModal={this.addVis}
                eventStats={eventStats}
                events={events}
                latestRuns={latestRuns}
              />

              <CalendarStats openModal={this.addVis} calendar={calendar} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default memo(Dashboard)
