import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@fortressiq/fiq-ds'

import classnames from 'classnames/bind'
import css from './boundingBoxStyle.scss'

const cx = classnames.bind(css)

const BoundingBox = ({ bounds, ratio, i, deleteBoundingBox, onActiveBox, active, boxType, toggleHover, isHover }) => {
  const x1 = bounds[0] * ratio
  const y1 = bounds[1] * ratio
  const x2 = bounds[2] * ratio
  const y2 = bounds[3] * ratio

  const width = x2 - x1
  const height = y2 - y1

  return (
    <div
      onMouseEnter={() => toggleHover && toggleHover(bounds)}
      onMouseLeave={() => toggleHover && toggleHover()}
      onClick={() => onActiveBox(i, bounds, boxType)}
      className={cx(css.boundingBox, active && css.active, isHover && css.isHover)}
      style={{ left: x1, top: y1, width: width, height: height }}
    >
      <div className={cx(css.boundingBoxControl, css.tl)} data-direction='tl' />
      <div className={cx(css.boundingBoxControl, css.tr)} data-direction='tr' />
      <div className={cx(css.boundingBoxControl, css.bl)} data-direction='bl' />
      <div className={cx(css.boundingBoxControl, css.br)} data-direction='br' />
      <div className={cx(css.boundingBoxMove)} data-direction='move' />
      {deleteBoundingBox && (
        <div className={cx(css.boundingBoxDeleteTrigger)} onClick={() => deleteBoundingBox(i, boxType)}>
          <Icon fill='white' icon='colorx' size={16} />
        </div>
      )}
    </div>
  )
}

BoundingBox.defaultProps = {
  deleteBoundingBox: () => {},
  onActiveBox: () => {},
  toggleHover: () => {},
  i: undefined,
  active: false,
  isHover: false,
  boxType: undefined,
}

BoundingBox.propTypes = {
  bounds: PropTypes.arrayOf(PropTypes.number).isRequired,
  ratio: PropTypes.number.isRequired,
  i: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  deleteBoundingBox: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onActiveBox: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  active: PropTypes.bool,
  boxType: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  toggleHover: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isHover: PropTypes.bool,
}

export default BoundingBox
