import React, { Fragment, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import * as d3 from 'd3'
import { Button } from '@fortressiq/fiq-ds'

import css from './groupsModalStyle.scss'

import store from '../stores/processExplorerStore'

import GroupForm from './common/GroupForm'

const EditGroupForm = () => {
  const [oldName, setOldName] = useState('')
  const [nodeInfo, setNodeInfo] = useState({ start: '', end: '' })
  const { activeGroup } = store.groups
  const { groupName, groupId, activeGroupNodeIds } = activeGroup
  const steps = activeGroup.activeGroupNodeIds.length

  useEffect(() => {
    setOldName(groupName)
    getNodeInfo()
  }, [groupId])

  const getNodeInfo = () => {
    const d3NodeEnd = d3.select(`#node${activeGroupNodeIds[0]}`).data()
    const d3NodeStart = d3.select(`#node${activeGroupNodeIds[activeGroupNodeIds.length - 1]}`).data()
    setNodeInfo({ start: d3NodeStart[0].data.name, end: d3NodeEnd[0].data.name })
  }

  const saveEdit = () => {
    store.groups.updateGroup(() => setOldName(groupName))
  }

  return (
    <Fragment>
      <div className={css.topsection}>
        <div className={css.title}> Edit Group: {oldName} </div>
        <div className={`${css.flowTemplate} ${css.noInteract}`}>
          <div className={css.fakeNode}>{nodeInfo.start}</div>
          <div className={css.editNumOfNodes}>{steps ? `${steps} step(s)` : '# of steps'}</div>
          <div className={css.fakeNode}>{nodeInfo.end}</div>
          <div className={css.editConnected} />
        </div>
      </div>

      <div className={css.bottomsection}>
        <GroupForm />
        <Button onClick={saveEdit} disabled={!store.groups.isReadyForSave}>
          Save
        </Button>
        <Button onClick={store.groups.destroyBtn}>Delete</Button>
      </div>
    </Fragment>
  )
}

export default observer(EditGroupForm)
