import React, { forwardRef, Fragment, useEffect, useState } from 'react'
import {
  Button,
  DateTimePicker,
  defaultHandleProps,
  Element,
  Fieldset,
  get,
  Group,
  helpers,
  InputNumber,
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
  Sortable,
  Spin,
  theme,
  useFela,
  useForm,
} from '@fortressiq/fiq-ds'
import { useGetSetState, useLatest, useList, useUpdateEffect } from 'react-use'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'

import { strategyOptions } from 'lib/SignatureStrategies'
import { DS_DATE_FORMAT_MEDIUM } from 'constants/app-constants'

import { sidebarItemCSS } from '../styles'
import { fieldset, strategyBoxHeight } from './styles'
import { createStrategy, CHAIN_STRATEGY, strategyParamsMap, isJsonValue } from '../utilities'

import Chain from './Chain'
import Single from './Single'

const { customScrollbarCSS, formatString } = helpers

const xs = theme['default-spacer-xs']
const scrollableModifiers = [restrictToVerticalAxis, restrictToFirstScrollableAncestor]
const sortableHandle = { ...defaultHandleProps, size: get.numericValues('spacer').md }

const Provisional = forwardRef(({ appId, cycleId, currentProvisionalStrategyParams, isLoading }, ref) => {
  const [canApplyProvisional, setCanApplyProvisional] = useState(false)
  const [isEditingChain, setState] = useGetSetState({ editing: null })
  const [provisionalStrategyParams, setProvisionalStrategyParams] = useState({ 0: {} })
  const [strategies, setStrategies] = useState([])
  const [list, { removeAt, reset, set }] = useList([])
  const { css } = useFela()
  const indexBeingEdited = useLatest(isEditingChain().editing)

  const { register, unregister, handleSubmit, watch, getValues, setValue, trigger, formState } = useForm({
    mode: 'onChange',
  })

  const history = useHistory()

  const remove = index => {
    // fix our provisional strategy params
    const keys = Object.keys(provisionalStrategyParams.params)
    const params = {}
    let i = 0
    keys.forEach(key => {
      if (key !== index) {
        const currentStrategy = getValues(`provisionalStrategy.${key}`)
        const { name, customParams, ...rest } = currentStrategy.params
        const restParams = { ...rest }
        const formattedParams = {}
        Object.keys(restParams).forEach(paramKey => {
          if (isJsonValue(paramKey)) {
            try {
              const jsonValue = JSON.parse(restParams[paramKey])
              formattedParams[paramKey] = jsonValue
            } catch (error) {
              console.error('Invalid JSON', error)
            }
          } else {
            formattedParams[paramKey] = restParams[paramKey]
          }
        })

        params[i] = {
          apply_to: currentStrategy.applyTo,
          strategy: {
            name: name,
            params: {
              ...customParams,
              ...formattedParams,
            },
          },
        }
        i += 1
      }
    })

    setProvisionalStrategyParams({ ...provisionalStrategyParams, params })

    //wipe out form fields
    unregister('provisionalStrategy', { keepValue: false, keepDefaultValue: false })
    // clean up our list
    removeAt(index)
  }

  useUpdateEffect(() => {
    reset()

    setStrategies(provisionalStrategyParams.params)
  }, [provisionalStrategyParams.params])

  useUpdateEffect(() => {
    set(
      Object.keys(strategies).map(index => {
        const thisStrat = provisionalStrategyParams.params[index]

        return {
          apply_to: thisStrat?.apply_to,
          label: (
            <Chain
              applicationId={appId}
              defaultValues={thisStrat?.strategy}
              defaultApplyTo={thisStrat?.apply_to}
              errors={formState?.errors?.provisionalStrategy?.[index]}
              id={index}
              indexBeingEdited={indexBeingEdited}
              register={register}
              removeAt={remove}
              setIsEditingChain={setState}
              setValue={setValue}
              trigger={trigger}
              unregister={unregister}
              watch={watch}
              getValues={getValues}
            />
          ),
          id: index,
          strategy: thisStrat?.strategy,
        }
      })
    )
    // canApplyProvisional to trigger rerender when errors changes
  }, [strategies, canApplyProvisional, appId])

  useEffect(() => {
    if (currentProvisionalStrategyParams) {
      reset()
      unregister('provisionalStrategy')
      setProvisionalStrategyParams(currentProvisionalStrategyParams)

      if (currentProvisionalStrategyParams?.start_date) {
        setValue('start_date', currentProvisionalStrategyParams.start_date)
      }
      if (currentProvisionalStrategyParams?.end_date) {
        setValue('end_date', currentProvisionalStrategyParams.end_date)
      }
      if (currentProvisionalStrategyParams?.limit) {
        setValue('limit', currentProvisionalStrategyParams.limit.toString())
      }
    }
  }, [currentProvisionalStrategyParams, reset, unregister, setValue])

  useEffect(() => {
    setCanApplyProvisional(formState.isValid)
  }, [formState.isValid])

  const onSubmit = async data => {
    const newStrategies = []

    data.provisionalStrategy.forEach(strat => {
      const options = strategyParamsMap[strat.params.name]
      let strategyObject = {}

      options.forEach(option => {
        if (strat?.params[option.value] || strat?.params[option.value] === 0) {
          if (isJsonValue(option.value)) {
            try {
              const jsonValue = JSON.parse(strat.params[option.value])
              strategyObject[option.value] = jsonValue
            } catch (error) {
              console.error('Invalid JSON', error)
            }
          } else {
            strategyObject[option.value] = strat.params[option.value]
          }
        }
      })

      if (strat?.params?.customParams && Object.keys(strat.params.customParams).length > 0) {
        const jsonCustomParams = strat.params.customParams
        strategyObject = { ...strategyObject, ...jsonCustomParams }
      }

      newStrategies.push({
        apply_to: strat.applyTo || 'all',
        strategy: { name: strat.params.name, params: strategyObject },
      })
    })

    let jsonObject = {
      limit: data.limit || data.limit === '' ? parseInt(data.limit, 10) : undefined,
    }

    if (data.start_date) {
      const formattedStart = format(new Date(data.start_date), DS_DATE_FORMAT_MEDIUM)
      jsonObject.start_date = formattedStart
    }
    if (data.end_date) {
      const formattedEnd = format(new Date(data.end_date), DS_DATE_FORMAT_MEDIUM)
      jsonObject.end_date = formattedEnd
    }

    let strategy = CHAIN_STRATEGY

    // if we only have one strategy, let's just use that
    if (newStrategies.length === 1) {
      strategy = newStrategies[0].strategy.name
      jsonObject = {
        ...jsonObject,
        ...newStrategies[0].strategy.params,
      }
    } else {
      jsonObject = {
        ...jsonObject,
        params: { ...newStrategies },
      }
    }

    await createStrategy(strategy, jsonObject, appId, cycleId)
    history.push('/signature-service-jobs')
  }

  const rightAlignBlockCSS = { display: 'block', marginTop: theme['default-spacer-md'], textAlign: 'right' }

  const addStrategy = () => {
    const currentStrats = getValues('provisionalStrategy')
    const params = currentStrats.map(val => {
      const options = strategyParamsMap[val?.params?.name] ? [...strategyParamsMap[val?.params?.name]] : []
      let strategyObject = {}

      options.forEach(option => {
        if (val?.params[option.value] || val?.params[option.value] === 0) {
          if (isJsonValue(option.value)) {
            try {
              const jsonValue = JSON.parse(val.params[option.value])
              strategyObject[option.value] = jsonValue
            } catch (error) {
              console.error('Invalid JSON', error)
            }
          } else {
            strategyObject[option.value] = val.params[option.value]
          }
        }
      })

      if (val?.params?.customParams && Object.keys(val.params.customParams).length > 0) {
        const jsonCustomParams = val.params.customParams
        strategyObject = { ...strategyObject, ...jsonCustomParams }
      }

      return {
        apply_to: val.applyTo || 'all',
        strategy: { name: val.params.name, params: strategyObject },
      }
    })

    setState({ editing: `${Object.keys(provisionalStrategyParams.params).length}` })

    setProvisionalStrategyParams({
      ...provisionalStrategyParams,
      params: {
        ...params,
        [Object.keys(provisionalStrategyParams.params).length]: {
          apply_to: 'all',
          strategy: {
            name: strategyOptions[0].value,
            params: {},
          },
        },
      },
    })
  }

  if (isLoading || !provisionalStrategyParams?.params) return <Spin>Loading&hellip;</Spin>

  const paramsCount = Object.keys(provisionalStrategyParams.params).length
  const showSortable = paramsCount > 1

  const onChangeSort = strats => {
    const currentStrats = getValues('provisionalStrategy')
    const params = strats.map((val, index) => {
      unregister(`provisionalStrategy.${index}`, { keepValue: false, keepDefaultValue: false })

      const options = strategyParamsMap[currentStrats[val.id].params.name]
      let strategyObject = {}

      options.forEach(option => {
        if (currentStrats[val.id]?.params[option.value] || currentStrats[val.id]?.params[option.value] === 0) {
          if (isJsonValue(option.value)) {
            try {
              const jsonValue = JSON.parse(currentStrats[val.id].params[option.value])
              strategyObject[option.value] = jsonValue
            } catch (error) {
              console.error('Invalid JSON', error)
            }
          } else {
            strategyObject[option.value] = currentStrats[val.id].params[option.value]
          }
        }
      })

      if (
        currentStrats[val.id]?.params?.customParams &&
        Object.keys(currentStrats[val.id].params.customParams).length > 0
      ) {
        const jsonCustomParams = currentStrats[val.id].params.customParams
        strategyObject = { ...strategyObject, ...jsonCustomParams }
      }

      return {
        apply_to: currentStrats[val.id].applyTo || 'all',
        strategy: { name: currentStrats[val.id].params.name, params: strategyObject },
      }
    })

    setProvisionalStrategyParams({
      ...provisionalStrategyParams,
      params,
    })
  }

  const sharedBtnProps = {
    children: 'Add Chain Layer',
    icon: 'add',
    onClick: addStrategy,
    size: 'small',
    style: {
      display: 'block',
      ...(showSortable && {
        marginBottom: theme['default-spacer-sm'],
        marginRight: theme['default-spacer-sm'],
      }),
      marginLeft: 'auto',
    },
    type: 'secondary',
  }
  const sharedGroupProps = {
    colStyles: { width: '49%', '> span, > div': { width: '100%' } },
    noEndGutter: true,
    noOfCols: 2,
    style: { margin: `0 0 ${theme['default-spacer-sm']}`, width: '100%' },
    type: 'flex',
  }
  const endDateReg = register('end_date')
  const startDateReg = register('start_date')
  const limitReg = register('limit')

  const sharedDatePickerProps = {
    className: css({ width: '100%' }),
    dateFormat: DS_DATE_FORMAT_MEDIUM,
    showTime: false,
    valueAsDate: true,
    range: false,
  }

  const sortableStyle = {
    margin: `${theme['default-spacer-sm']} 0`,
    maxHeight: formatString(strategyBoxHeight * 2.4),
    padding: `${theme['default-spacer-sm']} ${theme['default-spacer-md']} ${theme['default-spacer-sm']} 0`,
    overflow: 'auto',
    ...customScrollbarCSS({ width: xs }),
  }
  const onDragEndHandler = () => setState({ editing: null })

  return (
    <Element ref={ref} style={sidebarItemCSS}>
      {showSortable && (
        <Fragment>
          <Button {...sharedBtnProps} />
          <Sortable
            handle={sortableHandle}
            items={list}
            modifiers={scrollableModifiers}
            onChange={onChangeSort}
            onDragEnd={onDragEndHandler}
            scrollOnAdd={true}
            style={sortableStyle}
          />
        </Fragment>
      )}
      {paramsCount <= 1 && (
        <Fragment>
          <Button {...sharedBtnProps}>Make A Chain</Button>
          {Object.keys(provisionalStrategyParams?.params).map(key => (
            <Single
              applicationId={appId}
              applyTo={provisionalStrategyParams.params[key]?.apply_to}
              currentProvisionalStrategyParams={provisionalStrategyParams.params[key]?.strategy?.params}
              errors={formState?.errors?.provisionalStrategy?.[0]}
              getValues={getValues}
              id={0}
              key={key}
              provisionalStrategy={provisionalStrategyParams.params[key]?.strategy?.name || strategyOptions[0].value}
              register={register}
              setValue={setValue}
              single={true}
              trigger={trigger}
              unregister={unregister}
              watch={watch}
            />
          ))}
        </Fragment>
      )}
      <Fieldset legend='Options' style={fieldset}>
        <Fragment>
          <Group {...sharedGroupProps}>
            <DateTimePicker
              {...sharedDatePickerProps}
              {...startDateReg}
              onChange={({ formattedStart }) => setValue('start_date', formattedStart)}
              label='Start Date'
              value={
                currentProvisionalStrategyParams?.start_date
                  ? new Date(currentProvisionalStrategyParams.start_date)
                  : null
              }
            />
            <DateTimePicker
              {...sharedDatePickerProps}
              {...endDateReg}
              onChange={({ formattedStart }) => setValue('end_date', formattedStart)}
              label='End Date'
              value={
                currentProvisionalStrategyParams?.end_date ? new Date(currentProvisionalStrategyParams.end_date) : null
              }
            />
          </Group>
          <Group {...sharedGroupProps}>
            <InputNumber
              label='Limit'
              name={limitReg.name}
              ref={limitReg.ref}
              min={0}
              setNum={newVal => {
                setValue(limitReg.name, newVal)
              }}
              defaultValue={currentProvisionalStrategyParams.limit}
            />
            <span
              className={css({
                color: theme['text-light'],
                fontSize: theme['font-size-xs'],
                position: 'relative',
                top: xs,
              })}
            >
              Default 25000. Values: 0 to infinity. Limit the number of event_logs to be considered for strategy.
            </span>
          </Group>
        </Fragment>
      </Fieldset>

      <Group style={rightAlignBlockCSS}>
        <Button disabled={!canApplyProvisional} noBorder={true} onClick={handleSubmit(onSubmit)} type='secondary'>
          Create Provisional Signature
        </Button>
      </Group>
    </Element>
  )
})

export default Provisional
