// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`PlaybackControls Component should render without throwing an error 1`] = `
<div
  className="fiqds-overlay-container fiqds-overlay-dropdown-container clickable"
>
  <div
    className="fiqds-overlay-trigger"
  >
    <Memo(Button)
      disabled={false}
      flat={true}
      icon="playcircle"
      onClick={[Function]}
    >
      Playback
    </Memo(Button)>
  </div>
</div>
`;
