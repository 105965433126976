import React, { Fragment, useState } from 'react'
import { Button, Input, Label, TextArea, Radio, RadioGroup, Typography, theme, useFela } from '@fortressiq/fiq-ds'
import { useFetch } from 'lib/hooks'

import debounce from 'lodash/debounce'

import api from 'lib/Api'
import CallbackStore from 'stores/callback'

import ErrorMsg from './ErrorMsg'

import { RULE_TYPES } from '../Constants'
import { EVENTS_UPDATE, ADD_WEB_APPLICATION, EDIT_APPLICATION, UPDATE_INFO } from '../reducers/Actions'

const tempApplicationInit = {
  name: '',
  type: RULE_TYPES.title,
  pattern: '',
}

const { Title } = Typography

export default ({ applications, onSubmit, application, dispatch }) => {
  const [tempApplication, setTempApplication] = useState(application || tempApplicationInit)
  const [nameError, setNameError] = useState(!application)
  const [patternError, setPatternError] = useState(!application)
  const validForm = !nameError && !patternError
  const { css } = useFela()

  const { data: allApplications } = useFetch('/applications/filters', { service: true })

  const validateName = debounce(name => {
    const trimName = name.trim()
    if (!trimName) {
      setNameError('Please enter in an application name')
      return
    }
    if (
      allApplications.findIndex(app => app.label.toLowerCase() === trimName.toLowerCase()) !== -1 &&
      (!application || application.displayName !== trimName)
    ) {
      setNameError('No duplicate application names')
      return
    }
    setNameError(null)
  }, 100)

  const validatePattern = debounce(pattern => {
    const trimPattern = pattern.trim()
    const patternFound = applications.findIndex(app => {
      if (tempApplication.type === RULE_TYPES.title) return app.titlePattern === trimPattern
      else return app.urlPattern === trimPattern
    })

    if (!trimPattern) {
      setPatternError('Please enter in a pattern (regex)')
      return
    }
    if (patternFound !== -1 && (!application || application.pattern !== trimPattern)) {
      setPatternError('No duplicate patterns (regexs)')
      return
    }
    setPatternError(null)
  }, 100)

  const validatePatternOnTypeChange = debounce(type => {
    const trimPattern = tempApplication.pattern.trim()
    const patternFound = applications.findIndex(app => {
      if (type === RULE_TYPES.title) return app.titlePattern === trimPattern
      else return app.urlPattern === trimPattern
    })
    if (!trimPattern) {
      setPatternError('Please enter in a pattern (regex)')
      return
    }
    if (patternFound !== -1) {
      setPatternError('No duplicate patterns (regexs)')
      return
    }
    setPatternError(null)
  }, 100)

  const setName = event => {
    setTempApplication({ ...tempApplication, displayName: event.target.value })
    validateName(event.target.value)
  }

  const setPattern = event => {
    setTempApplication({ ...tempApplication, pattern: event.target.value })
    validatePattern(event.target.value)
  }

  const setType = event => {
    setTempApplication({ ...tempApplication, type: event.selectedValue })
    validatePatternOnTypeChange(event.selectedValue)
  }

  const submit = () => {
    if (application) {
      editApplication()
    } else {
      createApplication()
    }
    onSubmit()
  }

  /*
   * Create a new application
   */
  const createApplication = async () => {
    const params = {
      display_name: tempApplication.displayName.trim(),
      title_pattern: tempApplication.type === RULE_TYPES.title ? tempApplication.pattern.trim() : '',
      url_pattern: tempApplication.type === RULE_TYPES.url ? tempApplication.pattern.trim() : '',
      app_type: 'web',
    }
    const { data } = await api.post('/applications', params)
    const callback = ({ claimedEvents: loggedEvents, firstSeenAt }) => {
      dispatch({ type: EVENTS_UPDATE, application: data.application, loggedEvents, firstSeenAt })
    }
    const callbackId = CallbackStore.add(callback)

    await api.put('web_applications/update_browser_events', {
      application_id: data.application.applicationId,
      callback_id: callbackId,
      applicationUuid: data.application.id,
    })
    dispatch({ type: ADD_WEB_APPLICATION, application: data.application })
    dispatch({ type: UPDATE_INFO })
  }

  /*
   * Edit an application
   */
  const editApplication = async () => {
    const { data } = await api.put(`/applications/${application.applicationId}`, {
      display_name: tempApplication.displayName.trim(),
      title_pattern: tempApplication.type === RULE_TYPES.title ? tempApplication.pattern.trim() : '',
      url_pattern: tempApplication.type === RULE_TYPES.url ? tempApplication.pattern.trim() : '',
      app_type: 'web',
      applicationUuid: application.id,
    })
    const callback = ({ claimedEvents: loggedEvents, firstSeenAt }) => {
      dispatch({ type: EVENTS_UPDATE, application: data.application, loggedEvents, firstSeenAt })
    }
    const callbackId = CallbackStore.add(callback)

    api.put('web_applications/update_browser_events', {
      application_id: data.application.applicationId,
      callback_id: callbackId,
      applicationUuid: data.application.id,
    })
    dispatch({ type: EDIT_APPLICATION, application: data.application })
    dispatch({ type: UPDATE_INFO })
  }

  const containerStyle = {
    padding: `${theme['default-spacer-sm']} ${theme['default-spacer-md']} ${theme['default-spacer-sm']} ${theme['default-spacer-md']}`,
    width: '600px',
    maxWidth: '100%',
  }
  const ruleTypeStyle = {
    fontStyle: 'italic',
    margin: `${theme['defaut-spacer-xs']} 0`,
    background: theme['legacy-color-gray'],
    padding: theme['default-spacer-sm'],
    borderRadius: theme['border-radius-base'],
  }
  const textAreaStyle = { marginBottom: theme['defailt-spacer-sm'] }
  const spaceElementsCSS = { margin: `${theme['default-spacer-md']} 0` }

  return (
    <div className={css(containerStyle)}>
      <Title level={3}>{application ? `Edit ${application.displayName}` : 'Create Web Application'}</Title>
      <Input
        label='Application Name'
        name='displayName'
        onChange={setName}
        placeholder='Enter desired Application Name'
        required={true}
        style={{ marginBottom: theme['default-spacer-sm'] }}
        value={tempApplication.displayName}
      />
      {nameError && <ErrorMsg msg={nameError} />}

      {application ? (
        <Fragment>
          <Label>Applied Rule Type</Label>
          <div className={css(ruleTypeStyle)}>{tempApplication.type || RULE_TYPES.title}</div>
        </Fragment>
      ) : (
        <RadioGroup
          label={{ text: 'Applied Rule Type' }}
          name='type'
          onChange={e => setType(e)}
          selectedValue={tempApplication.type || RULE_TYPES.title}
          style={spaceElementsCSS}
        >
          <Radio value={RULE_TYPES.title}>Title</Radio>
          <Radio value={RULE_TYPES.url}>URL</Radio>
        </RadioGroup>
      )}

      <TextArea
        label='Rule Pattern (regex)'
        name='pattern'
        style={{ ...textAreaStyle, ...spaceElementsCSS }}
        placeholder='Enter Pattern'
        value={tempApplication.pattern}
        onChange={setPattern}
        required={true}
        rows={6}
      />
      {patternError && <ErrorMsg msg={patternError} />}

      <Button disabled={!validForm} onClick={submit} style={{ float: 'right', marginTop: theme['default-spacer-sm'] }}>
        {application ? 'Save' : 'Create'}
      </Button>
    </div>
  )
}
