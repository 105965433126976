//TODO will be moved to DS
import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Icon, useFela } from '@fortressiq/fiq-ds'
import { bool, object, oneOfType, shape, string } from 'prop-types'

import { sidebarEasing, sidebarVariants } from './Sidebar'
import { iconWrap, menuItem, menuTitle, subMenu, subMenuArrow } from './styles'

const SubMenu = ({ children, collapsedSidebar, item: { icon, label }, isActive }) => {
  const [isOpen, setIsOpen] = useState(!!isActive)
  const [isHover, setIsHover] = useState(false)
  const { css } = useFela({ collapsedSidebar, isActive, isHover, isOpen, isSubMenu: true })

  useEffect(() => setIsOpen(!!isActive), [collapsedSidebar, isActive])

  return (
    <div
      className={css({ position: 'relative' })}
      data-id={`sub-nav-${label}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className={css(menuItem)} onClick={() => setIsOpen(!isOpen)}>
        <Icon icon={isOpen ? 'arrowdown' : 'arrowright'} size={18} style={subMenuArrow({ collapsedSidebar })} />
        <div className={css(iconWrap)}>
          {icon && <Icon icon={icon?.icon || icon} size={18} {...(!!icon?.icon && icon)} />}
        </div>
        <AnimatePresence initial={false}>
          {!collapsedSidebar && (
            <motion.span
              key='content'
              initial='closed'
              animate='open'
              exit='closed'
              variants={sidebarVariants}
              className={css(menuTitle)}
            >
              {label}
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence initial={false}>
        {!collapsedSidebar && isOpen && (
          <motion.div
            animate='open'
            className={css(subMenu)}
            exit='closed'
            initial='closed'
            key='content'
            transition={{ duration: 0.2, ease: sidebarEasing }}
            variants={{
              open: { opacity: 1, height: 'auto' },
              closed: { opacity: 0, height: 0 },
            }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

SubMenu.propTypes = {
  collapsedSidebar: bool.isRequired,
  item: shape({
    label: string.isRequired,
    icon: oneOfType([object, string]).isRequired,
  }).isRequired,
}

export default SubMenu
