export const getStartStrategy = miningTypeLabel => ({
  label: 'Start Strategy',
  description: `Determines which start finding strategy we use when not given a start ${miningTypeLabel}.`,
  key: 'start_strategy',
  type: 'select',
  options: [
    { value: 'dependency', label: 'Dependency' },
    { value: 'frequency', label: 'Frequency' },
    { value: 'interactivity', label: 'Interactivity' },
    { value: 'copy_paste', label: 'Copy Paste' },
  ],
  default: 'dependency',
})

export const getDefaultParams = miningTypeLabel => [
  {
    label: 'Number of Processes',
    description: 'Determines the number of unique starts to mine for in a blind mining run.',
    key: 'num_processes',
    type: 'number',
    default: '20',
    rules: { min: 2, max: 50 },
  },
  {
    label: 'Number of Paths',
    description: 'The number of unique paths to return to the process graph.',
    key: 'num_paths',
    type: 'number',
    default: '20',
    rules: { min: 2, max: 100 },
  },
  getStartStrategy(miningTypeLabel),
  {
    label: 'Path Filter',
    description: 'Determines which path filter strategy we use when determining and returning the top paths.',
    key: 'path_filter',
    type: 'select',
    options: [
      { value: 'frequency', label: 'Frequency' },
      { value: 'levenshtein', label: 'Levenshtein' },
      { value: 'dedup', label: 'Dedup' },
    ],
    default: 'frequency',
  },
]

const getMiningParamsPath = (miningTypeLabel = 'screen signature') => [
  {
    label: 'Min Path Length',
    description: 'The minimum path length of an instance for it to be included in the processes.',
    key: 'min_path_length',
    type: 'number',
    default: '2',
    rules: { min: 2 },
  },
  {
    label: 'Max Path Length',
    description: 'The maximum path length of an instance for it to be included in the processes.',
    key: 'max_path_length',
    type: 'number',
    default: '100',
    rules: { min: 2 },
  },
  ...getDefaultParams(miningTypeLabel),
  {
    label: 'Merge Starts',
    description: 'Include multiple starts in the same process',
    key: 'merge_starts',
    type: 'checkbox',
    default: 0,
  },
  {
    label: 'Shortest Path',
    description: 'Finds the shortest instance of a path rather than the longest',
    key: 'shortest_path',
    type: 'checkbox',
    default: 0,
  },
]

export default getMiningParamsPath
