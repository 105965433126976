import React from 'react'
import { useFela } from '@fortressiq/fiq-ds'

import { tooltip } from './styles'

const ButterflyTooltip = () => {
  const { css } = useFela()
  return <div id='ButterflyTooltip' className={css(tooltip)} style={{ visibility: 'hidden' }} />
}

export default ButterflyTooltip
