import React, { useState } from 'react'
import { bool, func, object, shape, string } from 'prop-types'
import { defaultModalConfig, MenuItem, useModal, useNotification } from '@fortressiq/fiq-ds'
import { useGetSetState } from 'react-use'

import { FLASH_LEVELS } from 'components/flash/Flash'

import api from 'lib/Api'
import uiStore from 'lib/UiStore'

import { addPXSuffix } from 'helpers'
import exportTypes from './exportTypes'

import EventExportModal from './Modal'

import { getColumns } from '../../app/events/EventColumns'

const ExportMenuItem = ({
  customExportAjax,
  exportType,
  itemText,
  exportAPI,
  exportArgs,
  exportName: name,
  exportCallback,
  callbackStore,
  modalMaxWidth = 400,
  showColumns,
  ...props
}) => {
  const isLite = uiStore?.tenant?.isLite
  const [exportState, setExportState] = useState({
    progress: null,
    step: 'Starting Export',
  })
  const [isExporting, setIsExporting] = useState(false)
  const { addModal, removeModal } = useModal()
  const { addNotification } = useNotification()
  const [get, setState] = useGetSetState({
    columns: getColumns(isLite).map(c => c.dataKey),
    name: name || exportArgs.exportName || exportArgs.name,
    showColumns: showColumns,
  })

  const modalId = 'export-modal'
  const closeModal = () => removeModal(modalId)

  let callbackId = null

  const exportNotification = fileNameExport =>
    addNotification({
      autoClose: 3000,
      description: `Starting export for "${fileNameExport}".`,
      message: 'Exporting',
      type: FLASH_LEVELS.INFO,
    })

  const onProgress = ({ progress, step }) => {
    progress = parseFloat(progress)
    if (progress === 100) {
      setIsExporting(false)
      setExportState({ progress, step })
      callbackStore.remove(callbackId, onProgress)
      if (exportCallback) exportCallback()
      return
    }
    setExportState({ progress, step })
  }

  //used when this component handles the entire export
  const exportFile = async () => {
    closeModal()

    if (exportType === 'event') callbackId = callbackStore.add(onProgress, undefined, true)
    const params = {
      ...exportArgs,
      exportName: get().name,
      callbackId,
      ...(exportType === 'event' ? { fields: get().columns } : null),
    }

    // TEMP: do not allow for start and end signatures
    if (params?.filter?.rules) {
      params.filter.rules = params.filter.rules.filter(
        rule => rule.field !== 'start_screen_signature' && rule.field !== 'end_screen_signature'
      )
    }

    exportNotification(get().name)
    setIsExporting(true)

    const data = await exportTypes[exportType](params)

    if (exportType !== 'event') {
      if (exportCallback) exportCallback(data)
      setIsExporting(false)
    }
  }

  //used when the end point is a custom one only
  // this component handles the rest
  // when prop exportAPI is given
  const exportFileCustomAPI = () => {
    closeModal()
    callbackId = callbackStore.add(args => {
      setIsExporting(false)
      exportCallback(args)
    })
    const params = { ...exportArgs, exportName: get().name, callbackId }

    // TEMP: do not allow for start and end signatures
    if (params?.filter?.rules) {
      params.filter.rules = params.filter.rules.filter(
        rule => rule.field !== 'start_screen_signature' && rule.field !== 'end_screen_signature'
      )
    }

    exportNotification(get().name)
    setIsExporting(true)

    api.post(exportAPI, params)
  }

  //used when complete flow is decided outside this component
  // when prop customExportAjax is given
  const customExportFile = async () => {
    const params = { ...exportArgs, exportName: get().name }
    exportNotification(get().name)
    setIsExporting(true)
    const data = await customExportAjax(params)

    if (exportCallback) exportCallback(data)
    setIsExporting(false)
  }

  const exportMessage = exportState.progress ? `${exportState.step}… ${exportState.progress}` : 'Exporting...'

  const text = isExporting ? exportMessage : itemText

  let exportFileMethod = exportFile
  if (exportAPI) exportFileMethod = exportFileCustomAPI
  if (customExportAjax) exportFileMethod = customExportFile

  return (
    <MenuItem
      {...props}
      disabled={isExporting}
      key='export-menu-item'
      onClick={() =>
        addModal({
          children: (
            <EventExportModal
              closeModal={closeModal}
              exportFile={exportFileMethod}
              setState={setState}
              state={get}
              title={`Export ${get().name}`}
            />
          ),
          config: { ...defaultModalConfig, interactWithKeyboard: false },
          footer: false,
          header: false,
          id: modalId,
          style: { maxWidth: addPXSuffix(modalMaxWidth) },
        })
      }
    >
      {text}
    </MenuItem>
  )
}

ExportMenuItem.propTypes = {
  itemText: string.isRequired,
  callbackStore: shape({}).isRequired,
  exportAPI: string,
  // eslint-disable-next-line react/forbid-prop-types
  exportArgs: object,
  exportCallback: func,
  exportName: string,
  customExportAjax: func,
  exportType: string,
  showColumns: bool,
}

ExportMenuItem.defaultProps = {
  exportAPI: null,
  exportCallback: null,
  exportName: '',
  customExportAjax: null,
  exportType: 'event',
  exportArgs: {},
  showColumns: false,
}

export default ExportMenuItem
