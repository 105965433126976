import React, { Fragment, useState, useEffect } from 'react'

import { Button, Heading } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import UiStore from 'lib/UiStore'

import TrinityTable, { columnWidths } from 'components/TrinityTable'

import {
  NameCell,
  GeneratedOnCell,
  CreatedByCell,
  SubmittedToCoECell,
  IncludesProcessesCell,
  ApplicationsCell,
  ActionsCell,
} from './PddListCells'

import { useHeaderDispatch } from '../header/HeaderContext'

const processSpecsUrl = '/process_specs'
const pageTitle = 'Process Specifications'

const sortKeyMap = {
  name: 'flowcharts.name',
  updatedAt: 'updated_at',
  createdBy: 'members.first_name',
  steps: 'step_count',
}

const PddList = () => {
  const [reFetch, setRefetch] = useState(1)
  const headerDispatch = useHeaderDispatch()

  const handleDeleteSpec = async specId => {
    await api.delete(`${processSpecsUrl}/${specId}`)
    setRefetch(reFetch + 1)
  }

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: pageTitle,
      heading: (
        <Fragment>
          <Heading className='header-title' level={1}>
            {pageTitle}
          </Heading>
          <Heading className='header-title' level={2}>
            Define Processes
          </Heading>
        </Fragment>
      ),
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 160,
      Cell: cellData => NameCell(cellData),
    },
    {
      Header: 'Generated On',
      accessor: 'updatedAt',
      width: 160,
      Cell: cellData => GeneratedOnCell(cellData),
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
      width: 240,
      Cell: cellData => CreatedByCell(cellData),
    },
    ...(UiStore.tenant?.coeIntakeUrl
      ? [
          {
            Header: 'Submitted to CoE Manager On',
            accessor: 'opportunities',
            width: 240,
            Cell: cellData => SubmittedToCoECell(cellData),
          },
        ]
      : []),
    {
      Header: 'Includes Processes',
      accessor: 'processes',
      width: 500,
      disableSortBy: true,
      Cell: cellData => IncludesProcessesCell(cellData),
    },
    {
      Header: 'Steps',
      accessor: 'steps',
      width: 100,
      disableSortBy: true,
      Cell: ({ value }) => value || '--',
    },
    {
      Header: 'Applications',
      accessor: 'apps',
      width: 120,
      disableSortBy: true,
      Cell: cellData => ApplicationsCell(cellData),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      align: 'center',
      width: columnWidths.actionMenu,
      disableSortBy: true,
      Cell: ({ row: { original } }) => ActionsCell({ original, handleDeleteSpec }),
    },
  ]

  return (
    <div>
      <TrinityTable
        toolbarActions={[
          <Button key='create-pdd' href='/diagrams/new' type='secondary'>
            Create Process Specification
          </Button>,
        ]}
        columns={columns}
        url='/process_specs'
        tableId='pdd-table'
        dataKey='pdds'
        sortKeyMap={sortKeyMap}
        reFetch={reFetch}
      />
    </div>
  )
}

export default PddList
