import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Element, Skeleton, Spin, theme, Typography } from '@fortressiq/fiq-ds'
import { useApplicationsMap } from 'lib/hooks'
import graphTypeDisplayNameMap from 'lib/graphTypeDisplayNameMap'

import { numberToColor } from 'app/processes/utils'
import MiningRunDetails from 'app/mining/tableComponents/MiningRunDetails'

import { miningParamsDefaults } from 'app/mining/MiningRunList'

import ProcessDashCard from '../components/ProcessDashCard'
import RunDetail from '../components/RunDetail'

const RunDetails = ({ runDetails, isLoading }) => {
  const { id, createdAt, graphProcessCount, type, applicationCount } = runDetails
  const { applicationsMap, isLoading: isLoadingApplicationsMap } = useApplicationsMap()
  return (
    <ProcessDashCard title='Run Details' loading={isLoading}>
      {!miningParamsDefaults[runDetails.miningType] || !runDetails?.id || isLoading ? (
        <Spin style={{ color: theme['legacy-color-gray15'], fontStyle: 'italic' }}>Data is Loading...</Spin>
      ) : (
        <Fragment>
          <Element
            style={{
              alignItems: 'center',
              borderBottom: `1px solid ${theme['legacy-color-gray1']}`,
              display: 'flex',
              paddingBottom: theme['default-spacer-md'],
            }}
          >
            <Element
              style={{
                borderLeft: `5px solid ${numberToColor(id)}`,
                fontSize: '14px',
                fontWeight: '500',
                padding: `0 ${theme['default-spacer-md']}`,
              }}
            >
              Mining Run: {id}
            </Element>
          </Element>
          <Element
            style={{
              borderBottom: `1px solid ${theme['legacy-color-gray1']}`,
              display: 'flex',
              justifyContent: 'space-between',
              padding: ` ${theme['default-spacer-md']} 0 ${theme['default-spacer-sm']}`,
            }}
          >
            <RunDetail title='created at' value={createdAt} />
            <RunDetail title='processes' value={graphProcessCount} />
            <RunDetail title='type' value={graphTypeDisplayNameMap[type]} />
            <RunDetail title='applications' value={applicationCount} />
          </Element>
          <Element style={{ paddingTop: theme['default-spacer-md'] }}>
            <Typography.Title style={{ fontSize: theme['font-size-xs'] }} uppercase={true} level={5}>
              Mining Parameters
            </Typography.Title>
            <Element
              style={{
                background: theme['legacy-color-gray'],
                border: `1px solid ${theme['legacy-color-gray2']}`,
                borderRadius: theme['default-spacer-xs'],
                marginTop: theme['default-spacer-sm'],
                minHeight: '130px',
                padding: '12px',
                width: '100%',
                wordBreak: 'break-word',
              }}
            >
              {runDetails && !isLoadingApplicationsMap ? (
                <MiningRunDetails
                  miningRun={runDetails}
                  miningParamsDefaults={miningParamsDefaults[runDetails.miningType]}
                  applicationsMap={applicationsMap}
                />
              ) : (
                <Skeleton active={true} />
              )}
            </Element>
          </Element>
        </Fragment>
      )}
    </ProcessDashCard>
  )
}

RunDetails.propTypes = {
  isLoading: PropTypes.bool,
  runDetails: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    graphProcessCount: PropTypes.number.isRequired,
    applicationCount: PropTypes.number.isRequired,
  }).isRequired,
}

export default RunDetails
