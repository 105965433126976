import React, { useEffect, useRef, useContext } from 'react'
import KeyDown, { Keys } from 'react-keydown'

import css from './eventTimeline.scss'
import { Provider, Context } from './Context'
import Slider from './Slider'
import SlideDetails from './SlideDetails'

const { LEFT, RIGHT, ENTER } = Keys

const EventTimeline = ({ eventId }) => {
  return (
    <Provider eventId={eventId}>
      <EventTimelineContents />
    </Provider>
  )
}

const EventTimelineContents = KeyDown([LEFT, RIGHT, ENTER])(({ keydown: { event: keyDownEvent } }) => {
  const { prevSlide, nextSlide, viewScreenshot } = useContext(Context)
  const timelineRef = useRef(null)

  useEffect(() => {
    const changeSlideMap = {
      [LEFT]: prevSlide,
      [RIGHT]: nextSlide,
      [ENTER]: viewScreenshot,
    }
    if (keyDownEvent && keyDownEvent.which) {
      changeSlideMap[keyDownEvent.which]()
    }
  }, [keyDownEvent, prevSlide, nextSlide, viewScreenshot])

  return (
    <div className={css.eventTimeline} ref={timelineRef} tabIndex='-1'>
      <div className={css.sliderMain}>
        <Slider />
        <SlideDetails />
      </div>
    </div>
  )
})

export default EventTimeline
