import React from 'react'
import { Select, theme } from '@fortressiq/fiq-ds'
import { useHistory } from 'react-router-dom'

const ProcessSelect = ({ allProcesses, isLoadingAllProcesses, processId }) => {
  const history = useHistory()
  const defaultValue = allProcesses?.find(process => process.value.toString() === processId)

  const onChange = ({ value }) => {
    history.push(`/flow-graph/${value}`)
  }

  const selectProps = {
    onChange: onChange,
    placeholder: 'Select a Process',
    style: {
      width: '60%',
    },
    styles: { menu: { zIndex: theme['z-modal-below'] } },
  }

  return <Select {...selectProps} value={defaultValue} isLoading={isLoadingAllProcesses} options={allProcesses} />
}

export default ProcessSelect
