import React from 'react'
import { observer } from 'mobx-react'
import { Button, ButtonGroup, Dropdown } from '@fortressiq/fiq-ds'

import store from 'app/process_explorer/stores/processExplorerStore'

const buttonWidthStyle = { width: '113px' }

const NodeControls = ({ onCollapse, onRemove, onExpand, menu }) => {
  const node = store.viewingNode
  const collapseDisabled = !node.parent || node.parent.data.type === 'start'

  return (
    <ButtonGroup>
      <Button icon='arrowleft' onClick={onCollapse} disabled={collapseDisabled} style={buttonWidthStyle}>
        Collapse
      </Button>
      <Button icon='subtract' onClick={onRemove} disabled={!node.children} style={buttonWidthStyle}>
        Remove
      </Button>
      <Button icon='add' onClick={onExpand} disabled={!node.hiddenChildren} style={buttonWidthStyle}>
        Expand
      </Button>
      <Dropdown overlay={menu} trigger={{ type: 'click' }}>
        <Button icon='horizontaldots' />
      </Dropdown>
    </ButtonGroup>
  )
}

export default observer(NodeControls)
