import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Table, theme, Element, AdvancedCell } from '@fortressiq/fiq-ds'

import Bar from 'components/detailsSidePanel/pathStatistics/Bar'

import store from '../stores/ButterflyProcessStore'

const StepStatistics = () => {
  const columns = [
    {
      Header: 'Step',
      accessor: 'signature',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
    {
      Header: 'Instances',
      accessor: 'frequency',
      width: 100,
      minWidth: 100,
      Cell: ({
        value,
        row: {
          original: { frequency },
        },
      }) => <Bar percent={(frequency / store.stepsData.totalFrequency) * 100} text={value} />,
    },
    {
      Header: '',
      accessor: 'examplesURL',
      width: 80,
      minWidth: 80,
      Cell: ({ value }) => <Link to={value}>Examples</Link>,
    },
  ]

  return (
    <Element style={{ margin: `-${theme['default-spacer-sm']}` }}>
      <Table
        data={store.stepsData.tableData || []}
        columns={columns}
        rowSelection={{
          selectedRows: store.selectedSteps,
          selectedRowKey: 'key',
          selectedRowColor: theme['table-row-select-background'],
        }}
        onRowClick={record => store.setSelectedSteps(record)}
        disablePagination={true}
        disableSortBy={true}
        verticalBorders={false}
        disableResizing={true}
      />
    </Element>
  )
}

export default observer(StepStatistics)
