import React from 'react'
import PropTypes from 'prop-types'
import { Element, Col, Header, Row, Tooltip, theme, Typography } from '@fortressiq/fiq-ds'

import { ellipsisCSS } from 'styles/utils'

import localizer from 'lib/Localizer'
import ToolbarActions from './ToolbarActions'

const ProcessHeader = ({ process, updateProcess }) => {
  const { updatedAt, name } = process
  const sharedTooltipProps = {
    mouseEnterDelay: 500,
    placement: 'top-start',
  }

  return (
    <Header
      toolbar={
        <Row type='flex'>
          <Col style={{ paddingLeft: theme['default-spacer-md'], paddingTop: '6px' }}>
            <Tooltip title={name} {...sharedTooltipProps}>
              <Typography.Title
                level={5}
                style={{
                  ...ellipsisCSS(),
                }}
              >
                {name}
              </Typography.Title>
            </Tooltip>
          </Col>
          <Col style={{ justifyContent: 'flex-end', paddingRight: theme['default-spacer-md'] }}>
            <Tooltip title={`updated: ${localizer.asDateTime(updatedAt)}`} {...sharedTooltipProps}>
              <Element as='span' style={{ color: theme['legacy-color-gray3'] }}>
                {localizer.asShortestDate(updatedAt)}
              </Element>
            </Tooltip>
          </Col>
        </Row>
      }
      toolbarActions={<ToolbarActions process={process} updateProcess={updateProcess} />}
    />
  )
}

ProcessHeader.propTypes = {
  process: PropTypes.shape({
    favorited: PropTypes.bool,
    graphType: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    updatedAt: PropTypes.string,
  }).isRequired,
}

export default ProcessHeader
