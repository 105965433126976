import { Keys } from 'react-keydown'

export const keys = Keys
export const minimapEnabledStorageKey = 'isScreenViewerMinimapEnabled'
export const scrollTimeout = 150
export const loadLimit = 25

export const tween = ({ from = 0, to = 1, duration = scrollTimeout, onUpdate, onComplete } = {}) => {
  const delta = to - from
  const startTime = performance.now()

  function update(currentTime) {
    const elapsed = currentTime - startTime
    const progress = Math.min(elapsed / duration, 1)
    const latest = from + progress * delta

    if (onUpdate) onUpdate(latest)

    if (progress < 1) {
      return requestAnimationFrame(update)
    } else if (onComplete) {
      return onComplete(latest)
    }
  }

  return requestAnimationFrame(update)
}
