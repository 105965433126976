// vendor
import 'vendor/rappid.css'
import 'react-virtualized/styles.css'

// app
import 'styles/app.scss'
import 'styles/fonts.scss'

// Import all components. This will add everything from the app folder into this pack.
const componentRequireContext = require.context('app', true)
const ReactRailsUJS = require('react_ujs')

// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext)
