import React from 'react'
import DashCard from './DashCard'

import css from './topListingsCardStyle.scss'

const TopTagsCard = ({ title, onClose, list }) => (
  <DashCard title={title} onClose={onClose}>
    {list.map((item, i) => {
      return (
        <div className={css.row} key={i}>
          <div className={css.count}>{item.count}</div>
          <div className={css.name}>{item.name}</div>
        </div>
      )
    })}
  </DashCard>
)

export default TopTagsCard
