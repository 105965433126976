import React, { Fragment } from 'react'
import { Typography, Button, Image } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import Flash from 'components/flash/Flash'
import store from 'app/diagram_composer/store/DiagramComposerStore'
import { pddGenProgressCallbackId } from 'app/diagram_composer/controlsBar/generatePdd'

import css from './pddGenToastStyle.scss'

const flashActions = {
  pending: 'info',
  complete: 'success',
  error: 'error',
}

export const PddProgressMessage = ({ statusMessage = 'Generating PDD', step, stepThumb, specId, isComplete }) => {
  const handleClickDownload = async () => {
    const {
      data: {
        processSpec: { pddUrl },
      },
    } = await api.get(`/process_specs/${specId}`)
    window.open(pddUrl, '_blank')
  }

  return (
    <Fragment>
      <Typography.Title level={5}>{statusMessage}</Typography.Title>
      {stepThumb && (
        <Image
          alt={step}
          src={`data:image/jpeg;base64,${stepThumb}`}
          width={80}
          bordered={true}
          className={css.stepThumb}
        />
      )}
      <Typography.Text>{step}</Typography.Text>
      {isComplete && <Button onClick={handleClickDownload}>Download</Button>}
    </Fragment>
  )
}

const pddGenProgress = ({ status = 'pending', ...progressMessageProps }) => {
  const isComplete = status === 'complete'
  if (flashActions[status]) {
    Flash[flashActions[status]](
      { description: <PddProgressMessage {...progressMessageProps} isComplete={isComplete} />, message: '' },
      {
        key: `${pddGenProgressCallbackId}-${status}`,
        id: `${pddGenProgressCallbackId}-${status}`,
        duration: 0,
      }
    )
  }
  if (progressMessageProps.specId) {
    if (progressMessageProps.pddUrl) {
      store.pddUrls[progressMessageProps.specId] = progressMessageProps.pddUrl
    }
    if (!isComplete && !store.generatingPdds.includes(progressMessageProps.specId)) {
      store.addGeneratingPdd(progressMessageProps.specId)
    }
  }
  if (isComplete) {
    store.removeGeneratingPdd(progressMessageProps.specId)
    Flash.remove(`${pddGenProgressCallbackId}-pending`)
  }
}

export default pddGenProgress
