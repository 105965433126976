import React, { memo, Suspense, lazy } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { useUserState } from 'context/UserContext'
import NoAccess from 'app/noAccess/NoAccess'

import NotFound from 'components/404/NotFound'
import TrackedRoute from 'components/TrackedRoute/TrackedRoute'
import LoadingFallback from 'components/loaders/MainLoader'

import checkAllowedRoutes from 'lib/checkAllowedRoutes'

import uiStore from 'lib/UiStore'

export const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('app/dashboard/Dashboard'),
    exact: true,
  },
  {
    path: '/dashboard',
    component: () => import('app/dashboard/Dashboard'),
    exact: true,
  },

  { path: '/admin', component: () => import('app/admin/Admin') },
  { path: '/support', component: () => import('app/support/Support') },
  { path: '/mining', component: () => import('app/mining/MiningRunList'), exact: true },
  {
    path: '/applications',
    component: () => import(/* webpackChunkName: "applications" */ 'app/applications/ApplicationRegistry'),
    exact: true,
  },
  {
    path: '/applications/:appId/:cycleId?',
    component: () => import(/* webpackChunkName: "applications" */ 'app/applications/Application/'),
    exact: true,
  },
  {
    path: '/applications/:applicationId/screens/:screenSignature/:cycleId?',
    component: () => import(/* webpackChunkName: "applications" */ 'app/screens/viewer/ScreenViewer'),
  },
  {
    path: '/web-applications',
    component: () => import('app/web-applications/WebApplications'),
  },
  { path: '/events/:observerIds?', component: () => import('app/events/Events') },
  {
    path: '/event-log-exports',
    component: () => import('app/admin/event_log_exports/EventLogExportList'),
  },
  { path: '/templates', component: () => import('app/templates/TemplateList'), exact: true },
  { path: '/templates/new', component: () => import('app/templates/TemplateCreator') },
  { path: '/visualizations/butterfly/:id?', component: () => import('app/butterfly/Butterfly') },
  {
    path: '/client-dashboard',
    component: () => import('app/client_dashboard/processListDashboard/Dashboard'),
    exact: true,
  },
  {
    path: '/client-dashboard/:id',
    component: () => import('app/client_dashboard/processViewDashboard/Dashboard'),
  },
  {
    path: '/diagrams/:id',
    component: () => import('app/diagram_composer/DiagramComposer'),
  },
  {
    path: '/observers',
    component: () => import('app/observers/Observers'),
    exact: true,
  },
  { path: '/observers/:id', component: () => import('app/observers/viewer/ObserverViewer') },
  { path: '/pdds', component: () => import('app/pdds/PddList') },
  {
    path: '/processes',
    component: () => import('app/processes/Processes'),
    exact: true,
  },
  {
    path: '/processes/:id',
    component: () => import('app/processes/viewer/ProcessViewerContainer'),
    exact: true,
  },
  {
    path: '/process-boundary/:reportId',
    component: () => import(/* webpackChunkName: "applications" */ 'app/ProcessBoundary'),
    exact: true,
  },
  {
    path: '/processes/:processId/state_actions/:state',
    component: () => import('app/processes/StateManager'),
  },
  { path: '/process-explorer/:id?', component: () => import('app/process_explorer/ProcessExplorerContainer') },
  { path: '/sandbox', component: () => import('app/sandbox/Sandbox') },
  { path: '/screenshot/:eventLogId', component: () => import('app/screenshot/ScreenshotViewer') },
  {
    path: '/screenshot-annotator',
    component: () => <Redirect to='/admin/account' />,
  },
  {
    path: '/instances-viewer/:id?',
    component: () => import('app/instances_viewer/InstancesViewer'),
    exact: true,
  },
  {
    path: '/signature-service-jobs',
    component: () => import('app/signature_service_jobs/SignatureServiceJobList'),
  },
  {
    path: '/review/:observerID?',
    component: () => import('app/review/Review'),
    exact: true,
  },
  {
    path: '/review/:observerID/new',
    component: () => import('app/review/event/Event'),
    exact: true,
  },
  {
    path: '/review/:observerID/:reviewID/new',
    component: () => import('app/review/event/Event'),
    exact: true,
  },
  {
    path: '/review/:observerID/:reviewID/:eventAnnotationID',
    component: () => import('app/review/event/Event'),
    exact: true,
  },
  {
    path: '/status',
    component: () => import('app/status/Status'),
    exact: true,
  },
  {
    path: '/roles',
    component: () => import('app/roles/Roles'),
    exact: true,
  },
  {
    path: '/cycles',
    component: () => import('app/cycles/'),
    exact: true,
  },
  {
    path: '/flow-graph',
    component: () => import('app/dfGraph/DfGraph'),
    exact: true,
  },
  {
    path: '/flow-graph/:processId',
    component: () => import('app/dfGraph/DfGraph'),
    exact: true,
  },
]

const AppRoutes = memo(() => {
  const { roles: userRoles } = useUserState()
  const [allowedRoutes, deniedRoutes] = checkAllowedRoutes(routes, userRoles, uiStore.tenant)

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Switch>
        {allowedRoutes.map(({ component, routes: nestedRoutes, switchRoutes, exact, roles, ...rest }, index) => {
          const Component = lazy(component)
          const key = `${rest.path.replace('/', '-')}-${index}`

          return <TrackedRoute exact={exact} key={key} render={props => <Component {...props} {...rest} />} {...rest} />
        })}
        {deniedRoutes.map(({ component, routes: nestedRoutes, switchRoutes, exact, roles, ...rest }, index) => {
          const key = `${rest.path.replace('/', '-')}-${index}`

          return <TrackedRoute exact={exact} key={key} render={props => <NoAccess {...props} {...rest} />} {...rest} />
        })}
        <TrackedRoute component={NotFound} />
      </Switch>
    </Suspense>
  )
})

export default AppRoutes
