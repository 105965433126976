import React, { useRef } from 'react'
import { Element } from '@fortressiq/fiq-ds'
import { Helmet } from 'react-helmet-async'

import { fixedHeaderCSS, fixedHeaderRowCSS } from './styles'

const FixedHeader = ({ children, className, helmetTitle }) => {
  const ref = useRef(null)

  return (
    <Element className={className} ref={ref} style={fixedHeaderCSS}>
      <Helmet defer={false}>
        <title>{helmetTitle} - Process Discovery</title>
      </Helmet>
      <Element style={fixedHeaderRowCSS}>{children}</Element>
    </Element>
  )
}

export default FixedHeader
