import React, { useState } from 'react'
import { Button, Input, get, Group, useForm, Upload, theme, Popover, Element, Icon, Row } from '@fortressiq/fiq-ds'
import { bool, func, shape } from 'prop-types'

import api from 'lib/Api'
import { liteTenantActions } from 'components/can/Can'

import ControlledSelect from 'components/form/ControlledSelect'
import ControlledCheckbox from 'components/form/ControlledCheckbox'

import FieldWrapper from 'components/form/FieldWrapper'

import uiStore from 'lib/UiStore'
import { emailRegex } from 'lib/validationRegex'

import ROLES from 'constants/roles'

const rolesOptions = Object.keys(ROLES).map(roleObj => ({ label: ROLES[roleObj].name, value: roleObj }))

const filteredRolesOptions = rolesOptions.filter(roleObj => {
  if (uiStore.tenant.isLite) return liteTenantActions('createTenants').find(el => el === roleObj.value)
  return roleObj.value !== 'superuser'
})

const getRoleObject = role => rolesOptions.find(option => option.value === role)

const rowProps = {
  gutter: get.numericValues('spacer', 'md'),
  noOfCols: 2,
  type: 'grid',
  align: 'flex-start',
}

const footerStyling = {
  margin: `0 -${theme['default-spacer-sm']}`,
  borderTop: `1px solid ${theme['dividing-line']}`,
  padding: `${theme['default-spacer-sm']} ${theme['default-spacer-sm']} 0`,
}

const UserEditor = ({ currentUser, handleReFetch, isPegEnabled, removeModal }) => {
  const [isSaving, setIsSaving] = useState(false)
  const isEditing = !!currentUser

  const { register, watch, setError, setValue, handleSubmit, control, formState } = useForm({
    defaultValues: {
      username: currentUser?.username || '',
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      email: currentUser?.email || '',
      role: getRoleObject(currentUser?.role || 'user'),
      unredacted: currentUser?.unredacted || false,
    },
  })

  const { name: avatarName } = register('avatar')

  const role = watch('role')

  const onChangeAvatar = files => {
    setValue('avatar', files[0])
  }

  const onSubmitHandler = async formValues => {
    setIsSaving(true)
    const params = {
      'user[username]': formValues.username || currentUser.username,
      'user[member][first_name]': formValues.firstName,
      'user[email]': formValues.email,
      'user[member][id]': currentUser?.memberId || '',
      'user[member][last_name]': formValues.lastName,
      unredacted: formValues.unredacted,
      role: role.value,
    }

    if (formValues.avatar) {
      params['user[member][avatar]'] = formValues.avatar
    }

    const url = currentUser ? `/users/${currentUser.id}` : '/users'
    const method = currentUser ? 'PUT' : 'POST'

    try {
      await api.upload(url, params, method)
      setIsSaving(false)
      removeModal()
      handleReFetch()
    } catch (err) {
      setIsSaving(false)
      if (err?.response?.data?.errors?.includes('Username has already been taken')) {
        setError('username', {
          type: 'manual',
          message: 'Username has already been taken.',
        })
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Row {...rowProps}>
        <FieldWrapper htmlFor='username' label='Username' error={formState.errors.username}>
          <Input
            {...register('username', {
              required: 'Username is required.',
            })}
            onBlur={({ target: { value } }) => {
              setValue('username', value)
            }}
            onChange={({ target: { value } }) => {
              if (value.match(emailRegex)) {
                setValue('email', value)
              } else {
                setValue('email', '')
              }
            }}
            disabled={isEditing}
          />
        </FieldWrapper>
      </Row>
      <Row {...rowProps}>
        <FieldWrapper htmlFor='firstName' label='First Name' error={formState.errors.firstName}>
          <Input {...register('firstName', { required: 'First Name is required.' })} />
        </FieldWrapper>
        <FieldWrapper htmlFor='lastName' label='Last Name' error={formState.errors.lastName}>
          <Input {...register('lastName', { required: 'Last Name is required.' })} />
        </FieldWrapper>
      </Row>
      <Row {...rowProps}>
        <FieldWrapper htmlFor='email' label='Email' error={formState.errors.email}>
          <Input
            {...register('email', {
              required: 'Email is required.',
              pattern: {
                value: emailRegex,
                message: 'Entered value does not match email format.',
              },
            })}
          />
        </FieldWrapper>
        <FieldWrapper htmlFor='role' label='Role' error={formState.errors.role}>
          <Element style={{ display: 'flex', alignItems: 'center' }}>
            <ControlledSelect
              control={control}
              isMulti={false}
              name='role'
              options={filteredRolesOptions}
              portal={true}
              rules={{ required: 'Role is required.' }}
              style={{ width: '100%' }}
            />

            <Popover
              content={<Element style={{ width: '300px' }}>{ROLES[role.value]?.description}</Element>}
              placement='top-center'
              title={ROLES[role.value].name}
            >
              <Element style={{ marginLeft: theme['default-spacer-sm'] }}>
                <Icon size={20} icon='infocircle' stroke='currentColor' />
              </Element>
            </Popover>
          </Element>
        </FieldWrapper>
      </Row>
      <Row {...rowProps}>
        <FieldWrapper htmlFor='avatar' label='Avatar' error={formState.errors.avatar}>
          <Upload accept='image/*' onDrop={onChangeAvatar} name={avatarName} showUploadList={false}>
            <Button onClick={e => e.preventDefault()}>
              <Icon before={true} extraClasses={{ iconName: true }} icon='uploadbutton' size='small' /> Choose Image
              File
            </Button>
          </Upload>
        </FieldWrapper>
        {isPegEnabled && (
          <FieldWrapper htmlFor='unredacted' label='Screenshots' error={formState.errors.role}>
            <ControlledCheckbox name='unredacted' control={control} style={{ marginTop: theme['default-spacer-sm'] }}>
              Show unredacted screenshot option
            </ControlledCheckbox>
          </FieldWrapper>
        )}
      </Row>
      <Element style={footerStyling}>
        <Group noEndGutter={true} justify='flex-end'>
          <Button
            onClick={e => {
              e.preventDefault()
              removeModal()
            }}
          >
            Cancel
          </Button>
          <Button disabled={isSaving} loading={isSaving} type='primary'>
            {isEditing ? 'Update User' : 'Create User'}
          </Button>
        </Group>
      </Element>
    </form>
  )
}

UserEditor.propTypes = {
  currentUser: shape({}),
  handleReFetch: func.isRequired,
  isPegEnabled: bool.isRequired,
  removeModal: func.isRequired,
}

export default UserEditor
