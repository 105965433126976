import React from 'react'
import PropTypes from 'prop-types'

import { Button, Input, Group, useForm, useFela } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

import ControlledSelect from 'components/form/ControlledSelect'
import FieldWrapper from 'components/form/FieldWrapper'

import ControlledApplicationInput from './ControlledApplicationInput'

import { modalWrapperCSS, scrollWrapperCSS, formFooterCSS } from '../../domainList/subComponents/styles'

const accessTypeOptions = [
  { label: 'Allow', value: 'allow' },
  { label: 'Block', value: 'block' },
]

const generateDefaultValues = editValues => {
  const { accessType, list, name } = editValues
  return {
    accessType: accessTypeOptions.filter(option => option.value === accessType)[0],
    applications: list,
    name: name,
  }
}

const ApplicationListForm = ({ editId, editValues, handleReFetch, removeModal, isInUse, applicationsOptions }) => {
  const isEditing = !!editId

  const { css } = useFela()

  const { register, clearErrors, setError, handleSubmit, control, formState } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: editValues ? { ...generateDefaultValues(editValues) } : { applications: [] },
  })

  const onSubmitHandler = async formValues => {
    const params = {
      list: formValues.applications,
      name: formValues.name,
      accessType: formValues.accessType.value,
      categoryType: 'application',
    }

    // If editId exist, we are in edit mode and use api.put instead of post
    try {
      const data = isEditing ? api.put(`/allow_block_lists/${editId}`, params) : api.post('/allow_block_lists', params)
      await data
      handleReFetch()
      removeModal()
    } catch (err) {
      if (err.response.data.errors.includes('Name already exists for this tenant')) {
        setError('name', {
          type: 'manual',
          message: `Name "${formValues.name}" already exist`,
        })
      }
    }
  }

  return (
    <div className={css(modalWrapperCSS)}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className={css(scrollWrapperCSS)}>
          <FieldWrapper htmlFor='name' label='Name' error={formState.errors.name}>
            <Input {...register('name', { required: 'This is required.' })} />
          </FieldWrapper>
          <FieldWrapper htmlFor='accessType' label='Type' error={formState.errors.accessType}>
            <ControlledSelect
              control={control}
              isDisabled={isInUse || isEditing}
              isMulti={false}
              name='accessType'
              options={accessTypeOptions}
              portal={true}
              rules={{ required: 'This field is required' }}
            />
          </FieldWrapper>

          <ControlledApplicationInput
            applicationsOptions={applicationsOptions}
            clearErrors={clearErrors}
            control={control}
            error={formState.errors.applications}
            name='applications'
            placeholder='Applications to be added to Applications List.'
          />
        </div>
        <Group noEndGutter={true} justify='flex-end' className={css(formFooterCSS)}>
          <Button
            onClick={e => {
              e.preventDefault()
              removeModal()
            }}
          >
            Cancel
          </Button>
          <Button type='primary'>{isEditing ? 'Update List' : 'Create List'}</Button>
        </Group>
      </form>
    </div>
  )
}

ApplicationListForm.defaultProps = {
  editValues: undefined,
  editId: undefined,
  isInUse: false,
}

ApplicationListForm.propTypes = {
  editId: PropTypes.number,
  editValues: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    accessType: PropTypes.string,
  }),
  handleReFetch: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired,
  isInUse: PropTypes.bool,
}

export default ApplicationListForm
