import React, { useState, useEffect } from 'react'
import { func } from 'prop-types'

import {
  Button,
  DateTimePickerRange,
  dropShadowCSS,
  get,
  Group,
  helpers,
  Overlay,
  Select,
  theme,
} from '@fortressiq/fiq-ds'
import { format, parseISO } from 'date-fns'

import api from 'lib/Api'

import { filtersProp } from './constants'

const dateFormat = 'yyyy-MM-dd'

const Filters = ({ filters, setFilters, setShowing }) => {
  const [dates, setDates] = useState([new Date(parseISO(filters.start_day)), new Date(parseISO(filters.end_day))])
  const [tempFilters, setTempFilters] = useState({ ...filters })
  const [userOptions, setUserOptions] = useState([])

  useEffect(() => {
    const fetchUserOptions = async () => {
      const { data } = await api.get('/observers/windows_usernames/filters')
      setUserOptions(data)
    }
    fetchUserOptions()
  }, [])

  const changeDateRange = ds => {
    setDates(ds)
    setTempFilters({
      ...tempFilters,
      start_day: format(ds[0], dateFormat),
      end_day: format(ds[1], dateFormat),
    })
  }

  const changeUsers = usersList => {
    setTempFilters({ ...tempFilters, windows_username: usersList })
  }

  const applyFilters = () => {
    setFilters(tempFilters)
    setShowing(false)
  }

  return (
    <Group noOfCols={3} noEndGutter={true}>
      <DateTimePickerRange
        bordered={true}
        dateFormat={dateFormat}
        onChange={changeDateRange}
        showTime={false}
        size='default'
        style={{ border: 'none' }}
        styles={{ popperStyle: { zIndex: theme['z-modal-above'] } }}
        value={dates}
      />
      <Select
        isMulti={true}
        name='windows_username'
        options={userOptions}
        onChange={changeUsers}
        placeholder='Include User(s)...'
        value={tempFilters.windows_username}
      />
      <Button onClick={applyFilters}>Filter</Button>
    </Group>
  )
}

const CreditsFilter = ({ filters, setFilters }) => {
  const [showing, setShowing] = useState(false)

  const placement = {
    overflow: { adjustX: true, adjustY: true },
    points: ['tl', 'bl'],
    targetOffset: [0, `-${get.numericValues('spacer').sm}`],
  }

  const trigger = {
    showing,
    setShowing,
    node: <Button icon='filter'>Filter Results</Button>,
  }

  const config = {
    content: {
      style: {
        padding: `0 ${theme['default-spacer-sm']} ${theme['default-spacer-sm']}`,
        ...dropShadowCSS,
      },
    },
    disableOutsideClick: true,
  }

  return (
    <Overlay
      basic={false}
      closeIcon={true}
      config={config}
      content={<Filters filters={filters} setFilters={setFilters} setShowing={setShowing} />}
      draggable={true}
      id='filter-credits-results'
      placement={placement}
      portal={true}
      trigger={trigger}
      zIndex={500} //below left nav bar
    />
  )
}

CreditsFilter.propTypes = {
  filters: filtersProp.isRequired,
  setFilters: func.isRequired,
}

Filters.propTypes = {
  setShowing: func,
}

Filters.defaultProps = {
  setShowing: helpers.noop,
}

export default CreditsFilter
