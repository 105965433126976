import React from 'react'
import { useFela } from '@fortressiq/fiq-ds'
import classNames from 'classnames'

const ReviewSidebar = ({ className, ...props }) => {
  const { css, theme } = useFela()
  const classes = classNames(
    className,
    css({
      background: theme.white,
      borderLeftColor: theme['legacy-product-gray-3'],
      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',
      flex: '0 auto',
      marginLeft: theme['default-spacer-xs'],
      padding: theme['default-spacer-md'],
      width: '360px',
    })
  )

  return <div className={classes} {...props} />
}

export default ReviewSidebar
