import * as d3 from 'd3'

import sourceTypes from 'components/dragAndDrop/sourceTypes'
import SVGDropZoneStore from 'components/dragAndDrop/SVGDropZoneStore'

import AppCircleRenderer from './AppCircleRenderer'
import ButterflyLinkRenderer from './ButterflyLinkRenderer'
import NodeRenderer from './NodeRenderer'

import ButterflyProcessStore from './stores/ButterflyProcessStore'

import d3Sankey from './D3Sankey.js'

class ButterflyRenderer {
  nodes = []

  links = []

  endNodes = []

  path = null

  d3Color = null

  svg = null

  sankey = null

  data = null

  render(config) {
    const { width, height, data, showApps, margins } = config
    this.data = data
    this.width = width
    this.height = height
    this.showApps = showApps
    this.nodePadding = 20

    if (this.showApps) this.nodePadding = 65

    d3.select('#butterflySVG').remove()

    this.svg = d3
      .select('#butterflyChart')
      .append('svg')
      .attr('id', 'butterflySVG')
      .attr('width', width + margins.left + margins.right)
      .attr('height', height + margins.top + margins.bottom)
      .append('g')
      .attr('transform', `translate(${margins.left},${margins.top})`)

    this.update({ margins })
  }

  update({ margins }) {
    //clears data with rerender
    //transform and parse data into structure that d3Sankey understands
    this.nodes = []
    this.links = []
    this.endNodes = []
    this.parseData(this.data)

    ButterflyProcessStore.d3LinkData = this.links

    this.sankey = d3Sankey().nodeWidth(39).nodePadding(this.nodePadding).size([this.width, this.height])

    this.path = this.sankey.link()

    this.sankey.nodes(this.nodes).links(this.links).layout(32, true)

    this.nodes.sort((a, b) => {
      return a.value - b.value
    })

    //render links
    ButterflyLinkRenderer({
      svg: this.svg,
      links: this.links,
      path: this.path,
    })

    //add nodes
    const node = NodeRenderer({
      d3color: this.d3color,
      svg: this.svg,
      dragEnd: this.dragEnd,
      dragged: this.dragged,
      nodes: this.nodes,
      sankey: this.sankey,
      that: this,
      margins: margins,
    })

    //add app circles
    if (this.showApps) {
      AppCircleRenderer({
        node: node,
      })
    }
  }

  dragged(d) {
    if (!ButterflyProcessStore.defineProcessMode) return
    const dragContainer = document.getElementById('butterflyDragContainer')
    SVGDropZoneStore.draggingNode = true
    SVGDropZoneStore.draggingType = sourceTypes.BUTTERFLYNODE
    SVGDropZoneStore.draggingDetails = {
      name: d.subject.name,
      application: d.subject.data.applications[0].application_name,
      pathID: d.subject.pathID,
    }
    SVGDropZoneStore.draggingNodeX = d.sourceEvent.pageX - 100
    SVGDropZoneStore.draggingNodeY = d.sourceEvent.pageY - 150
  }

  dragEnd(d) {
    console.log(d)
    const nodeObject = {
      name: d.subject.name,
      application: d.subject.data.applications[0].application_name,
      pathID: d.subject.pathID,
    }
    if (SVGDropZoneStore.inDropZone.get(`butterflyProcStart_${ButterflyProcessStore.selectionId}`)) {
      ButterflyProcessStore.startPath(nodeObject)
      d.subject.start = true
    }
    if (SVGDropZoneStore.inDropZone.get(`butterflyProcEnd_${ButterflyProcessStore.selectionId}`)) {
      ButterflyProcessStore.endPath(nodeObject)
      d.subject.end = true
    }
    SVGDropZoneStore.draggingNode = false
    SVGDropZoneStore.draggingType = null
  }

  parseData(data) {
    const nodeDictionary = {}

    data.paths.forEach((path, pathIndex) => {
      const nodeList = path.nodeIds

      nodeList.forEach((node, nodeColumn) => {
        const nodeData = data.nodes.find(n => {
          return parseInt(node.split('_')[0], 10) === n.id
        })

        if (!nodeDictionary[node] && nodeData) {
          const appData = {
            application_name: nodeData.application || '',
            application_icon_url: nodeData.applicationIconUrl,
          }
          const d3Data = {
            applications: [appData],
          }
          this.nodes.push({
            name: `${nodeData.signature}_${nodeColumn}`,
            id: node,
            data: d3Data,
            frequency: +path.frequency,
            exemplar_event: nodeData.exemplarEvent,
          })
          nodeDictionary[node] = true
        }
      })

      for (let i = 1; i < nodeList.length; i += 1) {
        const source = this.nodes.findIndex(node => node.id === nodeList[i - 1])
        const target = this.nodes.findIndex(node => node.id === nodeList[i])
        if (source !== target && source !== -1 && target !== -1) {
          this.links.push({
            source: source,
            target: target,
            value: +path.frequency,
            pathID: path.id,
            pathKey: path.key,
            linkID: `${pathIndex}${i}`,
            avgDuration: `${Math.round(path.averageDuration)}s`,
            endLink: nodeList.length - 1 === i,
          })
        }
      }
    })
  }
}

export default new ButterflyRenderer()
