import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Card, combineRules, Element } from '@fortressiq/fiq-ds'
import { useMount, useUpdateEffect } from 'react-use'

import api from 'lib/Api'
import { useFetch, useMergeState } from 'lib/hooks'
import { toAlpha } from 'lib/String'
import parseApplicationData from 'lib/parseApplicationData'

import Loader from 'components/loaders/MainLoader'
import StepsByApplication from 'components/detailsSidePanel/processOverview/viz/StepsByApplication'
import PathsByDuration from 'components/detailsSidePanel/pathStatistics/viz/PathsByDuration'
import PathsByInstances from 'components/detailsSidePanel/pathStatistics/viz/PathsByInstances'
import ProcessTime from 'components/detailsSidePanel/applications/viz/ProcessTime'

import ProcessHeader from './sections/ProcessHeader'
import Overview from './sections/Overview'
import RunDetailsWrapper from './sections/RunDetailsWrapper'
import UserMetadata from './sections/UserMetadata'
import TopSignaturesTable from './sections/TopSignaturesTable'

import { useHeaderDispatch } from '../../header/HeaderContext'

import { getInstanceStats, getFlowDataStats } from '../utils.js'

import { demiProcessCardCSS, processCardCSS, processCardsWrapperCSS } from './styles/index'

const overviewData = {
  pathsCount: null,
  flowData: [],
  steps: null,
  instancesCount: null,
  eventsCount: null,
  averageDuration: null,
  totalDuration: null,
  observersCount: null,
  applicationData: {
    applications: {},
    total: 0,
    order: [],
  },
  instanceData: [],
}

const GraphProcess = ({ id }) => {
  const headerDispatch = useHeaderDispatch()
  const { data: { graphProcess } = {}, isLoading, setData } = useFetch(`/graph_processes/${id}`)
  const [overview, setOverview] = useMergeState(overviewData)
  const [applicationsByTime, setApplicationsByTime] = useState([])

  useEffect(() => {
    const getApplications = async () => {
      const { data } = await api.get(`/graph_processes/${graphProcess?.id}/application_process_time`, {
        graphProcessId: graphProcess.id,
      })

      setApplicationsByTime(data?.topSignatures)
    }

    if (graphProcess?.id) {
      getApplications().catch(setApplicationsByTime([]))
    }
  }, [graphProcess?.id])

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: 'Processes',
      heading: 'Process View',
    })
  }, [headerDispatch])

  const fetchData = async () => {
    const getInstanceData = instances =>
      instances.map((instance, index) => {
        return {
          ...instance,
          key: index + 1,
        }
      })

    const getApplicationData = processData => {
      // get application information from process data
      const { applications, total, order } = parseApplicationData(processData)

      return { applications, total, order }
    }

    const { data: processOverview } = await api.get(`/graph_processes/${id}/overview`)

    const applicationData = getApplicationData(processOverview.process_data)
    const instanceData = getInstanceData(processOverview.instances)
    const flowData = processOverview.flow_data.map((fData, index) => ({ key: toAlpha(index, false), ...fData }))

    const updatedOverview = {
      pathsCount: flowData.length,
      flowData: flowData,
      steps: processOverview.total_steps,
      ...getInstanceStats(processOverview.instances),
      ...getFlowDataStats(processOverview.flow_data),
      applicationData,
      instanceData,
    }

    setOverview(updatedOverview)
  }

  useMount(() => fetchData())
  useUpdateEffect(() => fetchData(), [id])

  const updateProcess = process => setData({ graphProcess: process })

  const userMetaData = {
    id: graphProcess?.id,
    notes: graphProcess?.notes,
    tags: graphProcess?.tags,
    graphType: 'graphProcess',
  }

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <ProcessHeader process={graphProcess} updateProcess={updateProcess} />
      <Overview overview={overview} />
      <Element style={processCardsWrapperCSS}>
        <RunDetailsWrapper miningRunId={graphProcess?.runId} isLoading={isLoading} />
        <UserMetadata userMetaData={userMetaData} updateProcess={updateProcess} />
        <Card
          style={combineRules(processCardCSS, {
            flexGrow: '1',
            minWidth: '300px',
            width: '50%',
          })}
          title='Steps by Application'
        >
          <StepsByApplication
            applications={overview.applicationData.applications}
            total={overview.applicationData.total}
            order={overview.applicationData.order}
          />
        </Card>
        <Card
          style={combineRules(processCardCSS, {
            flexGrow: '1',
            minWidth: '300px',
          })}
          title='Top Screen Signatures'
        >
          <TopSignaturesTable graphProcessId={graphProcess?.id} />
        </Card>
        <Card style={combineRules(processCardCSS, demiProcessCardCSS)} title='Paths By Duration'>
          <PathsByDuration paths={overview.flowData} />
        </Card>
        <Card style={combineRules(processCardCSS, demiProcessCardCSS)} title='Paths by Instances'>
          <PathsByInstances paths={overview.flowData} />
        </Card>
        {applicationsByTime?.topSignatures?.length > 0 && (
          <Card style={combineRules(processCardCSS, { minWidth: '33%' })} title='Application Process Time'>
            <ProcessTime applications={applicationsByTime.topSignatures} />
          </Card>
        )}
      </Element>
    </Fragment>
  )
}

GraphProcess.propTypes = {
  id: PropTypes.string.isRequired,
}

export default GraphProcess
