const degreeOptions = [
  { label: 'None', value: 'None' },
  { label: 'Very Low', value: 'Very Low' },
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
  { label: 'Very High', value: 'Very High' },
]

export const attributesMap = {
  alignment: 'Strategic_Alignment',
  complexity: 'Complexity',
}

export const coeDropdownAttributes = [
  'Complexity_1__c',
  'Complexity_2__c',
  'Complexity_3__c',
  'Complexity_4__c',
  'Complexity_5__c',
  'Strategic_Alignment_1__c',
  'Strategic_Alignment_2__c',
  'Strategic_Alignment_3__c',
  'Strategic_Alignment_4__c',
  'Strategic_Alignment_5__c',
  'How_is_Process_Measured__c',
  'Workstream_Path__c',
]

const formPagesTemplate = [
  {
    title: '',
    attribute: 'details',
    questions: [
      {
        label: 'Business Unit',
        attribute: 'Workstream_Path__c',
        type: 'select',
        options: [
          { label: 'Finance', value: '1' },
          { label: 'HR', value: '2' },
          { label: 'Sales', value: '3' },
        ],
      },
      { label: 'Opportunity Name', attribute: 'name', operator: 'store', type: 'text' },
      {
        label: 'Description',
        attribute: 'description',
        type: 'textarea',
      },
      {
        label: 'E-mail Address',
        attribute: 'Submitter_Email__c',
        type: 'email',
      },
      {
        label: 'PDD',
        attribute: 'pdd_name',
        type: 'file',
      },
    ],
  },

  {
    attribute: 'How_is_Process_Measured',
    title: '',
    questions: [
      {
        label: 'What % of tasks can be automated in this process?',
        attribute: 'of_Tasks_that_can_be_Automated__c',
        operator: 'divide',
        value: '100',
        type: 'number',
      },
      {
        label: 'How many people perform this process?',
        attribute: 'Number_of_Personnel__c',
        operator: 'none',
        value: '',
        type: 'number',
      },
      {
        label: 'How is the process measured?',
        attribute: 'How_is_Process_Measured__c',
        operator: 'none',
        value: 'timesPerformed',
        type: 'select',
        options: [
          { label: '# of times performed', value: '# of times performed' },
          { label: '# of records processed', value: '# of records processed' },
        ],
        trigger: true,
      },
      {
        label: 'How many hours does each person spend on this each time they perform this process?',
        attribute: 'Hours_per_Person__c',
        type: 'number',
        nested: true,
      },
      {
        label: 'How many times per month do they perform this process?',
        attribute: 'Times_per_Month__c',
        type: 'number',
        nested: true,
      },
      {
        label: "What's the average hourly rate each person is paid?",
        attribute: 'Average_Hourly_Pay_Rate__c',
        type: 'number',
        nested: true,
      },
    ],
  },
  {
    attribute: 'alignment',
    title: 'To what degree does automating this opportunity align to the following strategic objectives?',
  },
  {
    attribute: 'complexity',
    title: 'Assess the degree of complexity to automate this process automation opportunity',
  },
]

///////////////////////////////
// nested form parts, handles one level of nesting
///////////////////////////////
export const nestedForms = {
  1: {
    'How_is_Process_Measured__c_# of records processed': [
      {
        label: 'How many records per month does each person process?',
        attribute: 'Records_per_Month__c',
        type: 'number',
        nested: true,
      },
      {
        label: 'How many minutes does it take to process each record?',
        attribute: 'Minutes_per_Record__c',
        type: 'number',
        nested: true,
      },
      {
        label: "What's the average hourly rate each person is paid?",
        attribute: 'Average_Hourly_Pay_Rate__c',
        type: 'number',
        nested: true,
      },
    ],
    'How_is_Process_Measured__c_# of times performed': [
      {
        label: 'How many hours does each person spend on this each time they perform this process?',
        attribute: 'Hours_per_Person__c',
        type: 'number',
        nested: true,
      },
      {
        label: 'How many times per month do they perform this process?',
        attribute: 'Times_per_Month__c',
        type: 'number',
        nested: true,
      },
      {
        label: "What's the average hourly rate each person is paid?",
        attribute: 'Average_Hourly_Pay_Rate__c',
        type: 'number',
        nested: true,
      },
    ],
  },
}

export default formPagesTemplate
