import React, { useState } from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  defaultDropdownLayerProps,
  Dropdown,
  Header,
  theme,
  Toolbar,
  useFela,
  useModal,
} from '@fortressiq/fiq-ds'

import Can from 'components/can/Can'
import ExportButton from 'components/ExportButton/ExportButton'
import callbackStore from 'stores/callback'

import store from '../stores/processExplorerStore'
import GroupsControl from './GroupsControl'

import { topBar2 } from './styles/controlsBar.js'
import FlowsControl from './FlowControl'
import NewProcessForm from './NewProcessForm'
import SubProcsControl from './SubprocessControl'

const ControlsToolbar = observer(({ diagramData, flowData }) => {
  const [flowFilterType, setFlowFilterType] = useState('duration')
  const disabled = !diagramData || !flowData
  const displayPathsBy = flowFilterType === 'frequency' ? 'Instances' : flowFilterType

  return (
    <Toolbar>
      <Can perform='/process-explorer:create-subprocess'>
        <SubProcsControl disabled={disabled} />
      </Can>
      <GroupsControl disabled={disabled} />
      <Button onClick={store.createGroup} icon='heirachy2' disabled={disabled}>
        Create Group
      </Button>
      <Dropdown
        disabled={disabled}
        trigger={{ type: 'click' }}
        layer={{
          ...defaultDropdownLayerProps,
          closeOnOustideClick: false,
          closeOnOverlayClick: false,
          placement: 'bottom-start',
          triggerOffset: 4,
        }}
        overlay={
          <FlowsControl
            flowFilterType={flowFilterType}
            setFlowFilterType={setFlowFilterType}
            diagramData={diagramData}
            flowData={flowData}
          />
        }
      >
        <Button icon='barchart' suffixIcon='arrowdown' style={{ width: '220px' }}>
          Display Paths by: {displayPathsBy}
        </Button>
      </Dropdown>
    </Toolbar>
  )
})

const ToolbarActions = ({ openModal, exportOptions, graphId, defaultExportName }) => [
  <ExportButton
    buttonText='Export Process as ...'
    callbackStore={callbackStore}
    defaultExportName={defaultExportName}
    disabled={!graphId}
    exportType='graph'
    key='export-graph-process'
    options={exportOptions}
  />,
  <Can perform='/process-explorer:save-as-new-process' key='save-as-new-process'>
    <Button
      style={{
        marginLeft: theme['default-spacer-sm'],
      }}
      onClick={openModal}
      type='secondary'
    >
      Save as New Process
    </Button>
  </Can>,
]

const ControlsBar = ({ diagramData, currentUser, flowData, saveNew, graphId = null, processName }) => {
  const { css } = useFela()

  const exportOptions = [
    {
      text: 'Export Process (.csv)',
      exportArgs: { graphId },
      disabled: false,
    },
  ]

  const { addModal, removeModal } = useModal()

  const modalID = 'process-explorer-save-new-process-modal'

  const thisModal = {
    children: <NewProcessForm saveNew={saveNew} removeModal={removeModal} modalID={modalID} />,
    disableOK: true,
    header: 'Save Process',
    id: modalID,
    footer: false,
    style: { maxWidth: '400px' },
    config: { interactWithKeyboard: false },
  }

  const openModal = () => addModal(thisModal)

  return (
    graphId && (
      <Header
        className={css(topBar2)}
        toolbar={<ControlsToolbar currentUser={currentUser} diagramData={diagramData} flowData={flowData} />}
        toolbarActions={
          <ToolbarActions
            openModal={openModal}
            exportOptions={exportOptions}
            graphId={graphId}
            defaultExportName={processName}
          />
        }
      />
    )
  )
}
export default ControlsBar
