import React from 'react'
import { ActionCableConsumer } from 'components/actioncable-provider'
import { observer } from 'mobx-react'
import localizer from 'lib/Localizer'
import Main from 'components/main/Main'

import TankStore from './TankStore'

const TankMonitor = () => {
  const { batches, update } = new TankStore()

  return (
    <Main>
      <h1>Tank: Aggregation Monitor</h1>
      {batches.length === 0 && (
        <div>
          <i>Waiting for an aggregation run to start...</i>
        </div>
      )}
      {batches.reverse().map(batch => (
        <div key={batch.id}>
          BatchID: {batch.id}
          <pre style={{ backgroundColor: batch.completed ? null : '#e4ffe0' }}>
            STATUS {batch.completed ? 'Done' : 'Processing...'}
            <br />
            ROWS TOTAL {localizer.asInteger(batch.total)}
            <br />
            ROWS PROCESSED {localizer.asInteger(batch.succeeded)}
            <br />
            ROWS FAILED {localizer.asInteger(batch.failed)}
            <br />
            OBSERVERS SEEN {batch.observers}
            <br />
            STARTED AT {localizer.asDateTime(batch.startedAt)}
            <br />
            ENDED AT {localizer.asDateTime(batch.endedAt)}
          </pre>
          <br />
        </div>
      ))}
      <ActionCableConsumer channel={{ channel: 'TankChannel' }} onReceived={update} />
    </Main>
  )
}

export default observer(TankMonitor)
