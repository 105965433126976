import React from 'react'

import FlowControl from '../FlowControl'

jest.mock('../../stores/processExplorerStore', () => ({
  setTreeFlow: jest.fn,
}))

jest.mock('@fortressiq/fiq-ds-themes/dist/js/graphColors.js', () => [
  'hsl(220.83,32.14%,56.08%)',
  'hsl(355,26.79%,56.08%)',
  'hsl(220.83,32.14%,56.08%)',
  'hsl(355,26.79%,56.08%)',
  'hsl(220.83,32.14%,56.08%)',
  'hsl(355,26.79%,56.08%)',
  'hsl(220.83,32.14%,56.08%)',
  'hsl(355,26.79%,56.08%)',
  'hsl(220.83,32.14%,56.08%)',
  'hsl(355,26.79%,56.08%)',
])

jest.mock('@fortressiq/fiq-ds', () => ({
  useFela: () => ({
    css: () => ({}),
  }),
  combineRules: () => undefined,
  Col: ({ props }) => <div {...props} />,
  Row: ({ props }) => <div {...props} />,
  Select: ({ props }) => <div {...props} />,
}))

describe('ControlsBar FlowsControl component', () => {
  it('should render without throwing an error', () => {
    const component = global.shallowWithTheme(<FlowControl diagramData={{ value: 0 }} flowData={[]} />)
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
})
