/* eslint-disable import/prefer-default-export */
import { theme } from '@fortressiq/fiq-ds'

export const plusApp = () => ({
  color: theme['legacy-color-gray3'],
})

export const content = () => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme['default-spacer-md'],
})

export const title = () => ({
  color: theme['legacy-color-blue'],
  fontSize: theme['font-size-base'],
  fontWeight: '600',
})

export const nameContainer = () => ({
  fontSize: '15px',
  marginTop: theme['default-spacer-sm'],
  overflowWrap: 'anywhere',
})
