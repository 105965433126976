import React from 'react'

import localizer from 'lib/Localizer'

import DashCard from './DashCard'

import css from './calendarCardStyle.scss'
import main from '../styles.scss'

const CalendarCard = ({ title, onClose, data }) => {
  const today = new Date()
  return (
    <DashCard title={title} onClose={onClose}>
      <h3>Current and Upcoming Events</h3>
      <div className={css.container}>
        {!data || (data.length === 0 && <div className={main.none}>Currently no data</div>)}
        {data.map((item, i) => {
          const start = new Date(item.start_at)
          const end = new Date(item.end_at)
          return (
            <div className={css.entry} key={i}>
              <div>
                <span className={css.title}>{item.title}</span>
                <span className={css.type}> - {item.type}</span>
              </div>
              <div className={css.time}>
                {localizer.asShortDateTime(item.start_at)} - {localizer.asShortDateTime(item.end_at)}
              </div>
              {today >= start && today <= end && <div className={css.current}> occuring now</div>}
            </div>
          )
        })}
      </div>
    </DashCard>
  )
}

export default CalendarCard
