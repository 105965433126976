import React from 'react'
import { Icon } from '@fortressiq/fiq-ds'

import Signature from './Signature'
import css from './signatureDetailsBarStyle.scss'

const SignatureDetailsBar = ({ event, parentWidth }) => {
  const { signature, screenSignature } = event || {}
  return (
    <div className={css.container} style={{ width: `calc(${parentWidth})` }}>
      <Signature
        title='Signature'
        icon={<Icon className={css.icon} icon='pen' color='white' size={15} />}
        signature={signature}
      />
      <Signature
        title='Screen Signature'
        icon={<Icon className={css.icon} icon='presentation' color='white' size={15} />}
        signature={screenSignature}
      />
    </div>
  )
}

export default SignatureDetailsBar
