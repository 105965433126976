import React, { memo, useState } from 'react'
import { Overlay } from '@fortressiq/fiq-ds'

export default memo(({ selectedPathId, paths, togglePath }) => {
  const [showing, setShowing] = useState(false)
  const menu = paths.map(path => ({
    icon: path.id === selectedPathId ? 'check' : null,
    onClick: () => {
      togglePath(path)
      setShowing(false)
    },
    style: { position: 'relative' },
    text: path.name || `Path ${path.key}`,
  }))

  const selectedPathCount = paths.reduce((acc, path) => {
    if (path.selected) {
      acc += 1
    }
    return acc
  }, 0)

  const trigger = {
    showing: showing,
    setShowing: setShowing,
    text: `Paths${paths.length > 0 ? `: ${selectedPathCount}` : ''}`,
  }

  const config = {
    content: {
      style: {
        marginTop: '14px',
      },
    },
    disableOutsideClick: false,
    dropdown: {
      closeOnRowClick: true,
      showArrow: false,
    },
    trigger: {
      icons: {
        prefix: {
          icon: 'paths',
          size: 18,
        },
      },
    },
  }
  const widths = { max: 200, min: 200, width: 200 }

  const heights = {
    height: 35,
    max: 35 * 5,
  }

  return <Overlay config={config} heights={heights} id='path-filter' menu={menu} trigger={trigger} widths={widths} />
})
