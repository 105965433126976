import React, { Fragment } from 'react'
import * as d3 from 'd3'
import { observer } from 'mobx-react'
import { ApplicationIcon, Icon, Group, Typography, Tooltip } from '@fortressiq/fiq-ds'

import DisabledScreenshot, { isDisabledScreenshot } from 'components/DisabledScreenshot/DisabledScreenshot'
import UnredactedImageViewerWithOption from 'components/image/UnredactedImageViewerWithOption'

import api from 'lib/Api'

import TextToInput from 'components/inputs/TextToInput'
import { roleActionCheck } from 'components/can/Can'
import { useUserState } from 'context/UserContext'

import css from './activityControlPanelStyle.scss'

const { Text } = Typography

const ActivityControlPanel = observer(({ store }) => {
  const { roles } = useUserState()

  const onSaveName = (_, value) => {
    const { data: viewingNodeData } = store.viewingNode
    d3.select(`.nodeId_${viewingNodeData.id} .eventFOhtml`).text(value)
    api.patch(`/graph_nodes/${node.id}`, { name: value })
  }

  const node = store.viewingNode
  const eventLog = store.viewingEventLog
  const { data } = node
  const [application] = data.applications

  const iconContainerStyles = { alignItems: 'center', display: 'flex', flexDirection: 'row' }

  const renderScreenshot = () => {
    const images = eventLog
      ? [
          {
            centered: true,
            fluid: true,
            src: eventLog.screenshotUrl,
            svgInfo: {
              box: eventLog.bounding_box || eventLog.boundingBox,
              x: eventLog.coordX || eventLog.coord_x,
              y: eventLog.coordY || eventLog.coord_y,
            },
          },
        ]
      : null

    const screenshot = eventLog && (
      <UnredactedImageViewerWithOption
        clickImageToFullscreen={true}
        dark={false}
        images={images}
        maxHeight='100%'
        maxWidth={300}
        openFullscreenOnLoad={false}
        selectedIndex={0}
        fullscreenEnabled={true}
      />
    )

    if (isDisabledScreenshot(eventLog)) {
      return <DisabledScreenshot iconSize={150} fontSize='13px' iconHeight={65} containerHeight={164} />
    } else {
      return screenshot
    }
  }

  return !node ? (
    <div className={css.emptyMsg}>Select a Node</div>
  ) : (
    <Fragment>
      <div className={`${css.section} ${css.app}`}>
        {application && (
          <ApplicationIcon
            className={css.appIcon}
            name={application.application_name}
            src={application.application_icon_url}
            textLength={2}
          />
        )}
        {roleActionCheck('/process-explorer:edit-process-name', roles) && data.name !== 'End' ? (
          <TextToInput
            textStyle={{ maxWidth: '360px' }}
            key={`text-to-input-${node.id}`}
            name='name'
            onEnter={onSaveName}
            value={data.name}
            tooltipProps={{ title: `Edit: ${data.name}` }}
          />
        ) : (
          <Tooltip placement='bottom-center' title={data.name}>
            <Text style={{ alignSelf: 'center' }} as='span' ellipsis={true}>
              {data.name || '--'}
            </Text>
          </Tooltip>
        )}
      </div>

      <div className={css.section} style={{ borderBottom: 'none' }}>
        <div className={css.statContainer}>
          <Group align='middle' gutter={16} justify='flex-start'>
            <span style={iconContainerStyles}>
              <Icon before={true} icon='node' size={16} />
              {data.value || 0}
            </span>
            <span style={iconContainerStyles}>
              <Icon before={true} icon='split' size={16} />
              {node.percent || '0%'}
            </span>
            <span>
              Node Depth:
              {node.depth}
            </span>
          </Group>

          {node.data.type.trim() !== 'end' && <div className={css.shot}>{renderScreenshot()}</div>}
        </div>
      </div>
    </Fragment>
  )
})

export default ActivityControlPanel
