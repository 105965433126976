import React from 'react'
import { reaction } from 'mobx'

import api from 'lib/Api'
import { UserStateContext } from 'context/UserContext'

import store from 'app/process_explorer/stores/processExplorerStore'
import DecisionNoEdit from 'app/inspectors/DecisionNoEdit'
import EditDecisionForm from './EditDecisionForm'

class EditDecision extends React.Component {
  static contextType = UserStateContext

  constructor(props) {
    super(props)

    this.state = {
      data: {},
      name: '',
      description: '',
      rules: [],
    }
  }

  componentDidMount() {
    this.setNodeInfo()

    this.nodeChangeReaction = reaction(
      () => ({
        path: store.viewingNode,
      }),
      () => {
        this.setNodeInfo()
      }
    )
  }

  componentWillUnmount() {
    this.nodeChangeReaction()
  }

  render() {
    const { name, description, rules } = this.state
    const { isLimited } = this.context

    if (isLimited) {
      return <DecisionNoEdit name={name} description={description} rules={rules} store={store} />
    }
    return (
      <EditDecisionForm
        description={description}
        name={name}
        onSave={this.onSave}
        onChange={this.onChange}
        rules={rules}
        store={store}
      />
    )
  }

  setNodeInfo = async () => {
    const { viewingNode } = store

    if (!viewingNode || store.viewingNode.data.type !== 'decision') return

    const { data } = await api.get(`/graph_nodes/${viewingNode.data.id}`)
    const { rules, name, description } = data.node

    this.setState({
      name: name,
      description: description,
      rules: viewingNode.children.map(c => {
        const rule = rules.find(r => c.data.id === r.id)
        if (!rule) return
        return {
          consequentNodeId: rule.id,
          ruleName: rule.ruleName || '',
          ruleDescription: rule.ruleDescription || '',
          id: `${c.branchLabel}`,
        }
      }),
    })
  }

  onChange = (key, value, index) => {
    const newState = { ...this.state }

    if (typeof index !== 'undefined') {
      newState.rules[index][key] = value
    } else {
      newState[key] = value
    }
    this.setState(newState)
  }

  onSave = async () => {
    const { viewingNode } = store
    const { name, description, rules } = this.state

    const params = {
      name: name,
      description: description,
      rules: rules,
    }
    await api.patch(`/graph_nodes/${viewingNode.data.id}`, params)
  }
}

export default EditDecision
