import { ellipsisCSS } from 'styles/utils'

export const dropdownContainer = () => ({
  left: '-9px',
  maxHeight: '300px',
  position: 'relative',
  top: '18px',
  width: '300px',
})

export const row = () => ({
  alignItems: 'center',
  display: 'flex',
})

export const groupDetails = () => ({
  alignItems: 'center',
  display: 'flex',
  width: '90%',
})

export const groupName = () => ({
  width: '80%',
  ...ellipsisCSS(),
})

export const multi = ({ theme }) => ({
  borderRadius: '100%',
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
  float: 'left',
  height: '11px',
  marginRight: '11px',
  marginTop: theme['default-spacer-xs'],
  verticalAlign: 'middle',
  width: '11px',

  '&.filled': {
    background: theme.white,
  },

  '&:first-child': {
    border: `1px solid ${theme['legacy-color-grey7']}`,
  },
})

export const dropdownText = ({ theme }) => ({
  textAlign: 'left',
  maxWidth: '200px',
  padding: `0 ${theme['default-spacer-sm']}`,
  cursor: 'pointer',
  ...ellipsisCSS(),
})
