//Time.js. -- time related functions, not relating to localization
import { differenceInHours } from 'date-fns'

export const getActive = time => {
  const now = new Date()
  const diff = differenceInHours(now, new Date(time))

  if (diff <= 24) {
    return 'active'
  }

  if (diff <= 72) {
    return 'some'
  }
  return 'inactive'
}

const leadingZeroes = num => {
  if (Math.floor(num).toString().length >= 2) return Math.floor(num).toString()
  return `0${num}`.slice(-2)
}

export const getTimeElapsed = totalSeconds => {
  const hours = Math.trunc(totalSeconds / 3600)
  let myTotalSeconds = totalSeconds % 3600
  const minutes = Math.trunc(myTotalSeconds / 60)
  myTotalSeconds %= 60
  const secs = Math.round(myTotalSeconds)
  return `${leadingZeroes(hours)}:${leadingZeroes(minutes)}:${leadingZeroes(secs)}`
}

export const getTimeElapsedTruncated = totalSeconds => {
  let timeStr = ''
  const hours = Math.floor(totalSeconds / 3600)
  if (hours > 0) {
    timeStr = `${hours}:`
  }
  totalSeconds %= 3600
  const minutes = Math.floor(totalSeconds / 60)

  if (minutes > 9) {
    timeStr = `${timeStr}${minutes}:`
  } else {
    timeStr = `${timeStr}0${minutes}:`
  }

  const seconds = Math.floor(totalSeconds % 60)
  if (seconds > 9 || timeStr === '') {
    timeStr = `${timeStr}${seconds}`
  } else {
    timeStr = `${timeStr}0${seconds}`
  }

  return timeStr
}
