import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import uiStore from 'lib/UiStore'
import { useFieldArray, theme, Element, Typography, Label, Button, Input } from '@fortressiq/fiq-ds'

import ControlledSelect from 'components/form/ControlledSelect'
import constructApplicationOptions from 'components/form/helpers/constructApplicationOptions'
import FieldWrapper from 'components/form/FieldWrapper'

import { useUpdateEffect } from 'react-use'

import { secondarySignatureLevelLabelMap } from '../helpers'

const signatureGroupCSS = () => ({
  background: 'hsla(0, 0%, 94%, 0.5)',
  border: `1px solid ${theme['legacy-color-gray1']}`,
  padding: theme['default-spacer-md'],
  marginBottom: theme['default-spacer-md'],
})

const { Text } = Typography

const secondarySignatureLevelOptions = {
  screen_signature: [
    { label: secondarySignatureLevelLabelMap.none, value: 'none' },
    { label: secondarySignatureLevelLabelMap.field, value: 'field' },
    { label: secondarySignatureLevelLabelMap.value, value: 'value' },
  ],
}

const SignatureBuilder = ({
  control,
  description,
  errors,
  min,
  name,
  primarySignatureLabel,
  register,
  signatureLevel,
  title,
  watch,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${name}.signatures`,
    shouldUnregister: true,
  })

  const showSecondary = signatureLevel === 'screen_signature' && !uiStore?.tenant?.isLite

  const watchedSignature = watch(name)

  const handleAdd = e => {
    e.preventDefault()
    if (showSecondary) {
      append({ primary: '', secondary_signature_level: 'none' })
    } else {
      append({ primary: '' })
    }
  }

  const handleRemove = (e, index) => {
    e.preventDefault()
    remove(index)
  }

  useUpdateEffect(() => {
    // clear array if signature level changes
    remove()
  }, [signatureLevel])

  useEffect(() => {
    // append if min fields required
    if (min && min > fields.length) {
      if (showSecondary) {
        append({ primary: '', secondary_signature_level: 'none' })
      } else {
        append({ primary: '' })
      }
    }
  }, [min, fields, append, showSecondary])

  return (
    <Element as='fieldset' style={signatureGroupCSS}>
      <Label>{title}</Label>
      {description && (
        <Text noMargin={true} style={{ display: 'block', marginBottom: theme['default-spacer-xs'] }}>
          {description}
        </Text>
      )}
      {fields.map((item, index) => {
        const lastItem = index + 1 === fields.length
        // hide remove button if min fields is required
        const hideRemoveButton = min && min === index + 1
        const secondarySignatureLevel = watchedSignature?.signatures?.[index]?.secondary_signature_level
        const arrayName = `${name}.signatures[${index}]`
        return (
          <Element
            key={item.id}
            style={{
              ...signatureGroupCSS(),
              display: 'flex',
              alignItems: 'center',
              marginBottom: lastItem ? 0 : theme['default-spacer-md'],
            }}
          >
            <Element style={{ width: '100%', marginRight: theme['default-spacer-sm'] }}>
              <FieldWrapper error={errors?.signatures?.[index]?.primary}>
                <Label>*{primarySignatureLabel}</Label>
                {signatureLevel === 'application' ? (
                  <ControlledSelect
                    constructData={constructApplicationOptions}
                    constructSelectedValue={true}
                    control={control}
                    isMulti={false}
                    name={`${arrayName}.primary`}
                    placeholder='Select applications'
                    portal={true}
                    rules={{ required: 'This field is required' }}
                    url='/applications/filters'
                    valueKey='id'
                  />
                ) : (
                  <Input
                    {...register(`${arrayName}.primary`, { required: 'This field is required' })}
                    placeholder={`${primarySignatureLabel}`}
                  />
                )}
              </FieldWrapper>
              {showSecondary && (
                <FieldWrapper style={{ marginBottom: 0 }} error={errors?.signatures?.[index]?.secondary}>
                  <Label>Signature Label</Label>
                  <Element style={{ display: 'flex' }}>
                    <ControlledSelect
                      constructSelectedValue={true}
                      control={control}
                      isLoading={false}
                      isMulti={false}
                      name={`${arrayName}.secondary_signature_level`}
                      options={secondarySignatureLevelOptions[signatureLevel]}
                      portal={true}
                      style={{ width: '130px', marginRight: theme['default-spacer-sm'] }}
                      valueKey='value'
                      defaultValue='none'
                    />
                    {secondarySignatureLevel !== 'none' && (
                      <Input
                        {...register(`${arrayName}.secondary`)}
                        containerProps={{ style: { flex: 1 } }}
                        placeholder={secondarySignatureLevelLabelMap[secondarySignatureLevel]}
                      />
                    )}
                  </Element>
                </FieldWrapper>
              )}
            </Element>
            {!hideRemoveButton && <Button icon='remove' onClick={e => handleRemove(e, index)} shape='circle' />}
          </Element>
        )
      })}
      <Button type='link' icon='add' onClick={handleAdd}>
        Add {title}
      </Button>
    </Element>
  )
}

SignatureBuilder.defaultProps = {
  description: undefined,
  errors: undefined,
  min: undefined,
  signatureLevel: undefined,
}

SignatureBuilder.propTypes = {
  control: PropTypes.shape({}).isRequired,
  description: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.shape()),
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  primarySignatureLabel: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  signatureLevel: PropTypes.string,
  title: PropTypes.string.isRequired,
  watch: PropTypes.func.isRequired,
}

export default SignatureBuilder
