//TODO: Refactor this to use Overlay.
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Icon, theme } from '@fortressiq/fiq-ds'

import classNames from 'classnames/bind'

import ClickOutside from 'components/ClickOutside'

import api from 'lib/Api'

import css from './topNavHelpStyle.scss'

@withRouter
export default class TopNavHelp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      opened: false,
    }
  }

  render() {
    const { opened } = this.state
    const { pageHelp } = this.props
    const cx = classNames.bind(css)

    return (
      <div className={css.container}>
        <div className={cx({ back: true, visible: opened })} onClick={() => this.setState({ opened: !opened })}>
          <Icon
            className={cx({ helpIcon: true, helpIconActive: opened })}
            clickable={true}
            color={theme['topnav-border']}
            icon='questioncircle'
            size={18}
          />
        </div>

        {opened && (
          <div className={css.tip}>
            <ClickOutside callback={() => this.setState({ opened: false })}>
              <Icon
                className={cx({ close: true, closePanel: true })}
                clickable={true}
                color={theme['topnav-border']}
                icon='remove'
                onClick={() => this.setState({ opened: false })}
              />

              <div>
                <div className={css.iconBorderOuter}>
                  <Icon fill={theme.white} color={theme['btn-textColor']} icon='questioncircle' size='medium' />
                </div>
                <div className={css.title}> {pageHelp.title} </div>
              </div>

              <div className={css.helptip}>{pageHelp.tip}</div>

              {pageHelp.buttons.map((button, i) => (
                <Button key={i} onClick={e => this.onClick(e, button)} type='primary'>
                  {button.name}
                </Button>
              ))}
            </ClickOutside>
          </div>
        )}
      </div>
    )
  }

  onClick = (e, button) => {
    const { history } = this.state

    if (button.onClick) {
      this[button.onClick]()
    } else {
      history.push(button.to)
    }

    this.setState({ opened: false })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  createProcess = async () => {
    const { history } = this.state

    const { data } = await api.post('/processes', { name: 'New Process' })

    history.push(`/processes/${data.id}`)
  }
}
