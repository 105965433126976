import React, { Fragment, useEffect } from 'react'

import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { Heading } from '@fortressiq/fiq-ds'

import rappid from 'vendor/rappid'

import ControlsBar from './controlsBar/ControlsBar'
import LeftPanel from './leftPanel/LeftPanel'

import './diagram.scss'

import DiagramInspector from './diagramInspector/DiagramInspector'

import { initInspector, initHalo, initLinks } from './DiagramComposerEvents'
import store from './store/DiagramComposerStore'

import { useHeaderDispatch } from '../header/HeaderContext'

import css from './diagramComposerStyle.scss'

const pageTitle = 'Diagram Composer'

const Teaser = ({ id }) => {
  return id !== 'new' ? (
    <span>
      Editing <em>{store.diagramName}</em> process
    </span>
  ) : (
    'Design a process'
  )
}

const DiagramComposer = ({
  match: {
    params: { id },
  },
  location: { state = {} },
}) => {
  const headerDispatch = useHeaderDispatch()
  const diagramData = toJS(store.diagramData)

  useEffect(() => {
    store.initPaper()
    initHalo()
    store.initSelection()
    initLinks()
    initInspector()
  }, [])

  useEffect(() => {
    store.loadData(id)
  }, [id])

  useEffect(() => {
    drawDiagram()
  }, [diagramData])

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: pageTitle,
      heading: (
        <Fragment>
          <Heading className='header-title' level={1}>
            {pageTitle}
          </Heading>
          <Heading className='header-title' level={2}>
            <Teaser id={id} />
          </Heading>
        </Fragment>
      ),
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch, id])

  const autoLayout = () => {
    rappid.layout.DirectedGraph.layout(store.graph, {
      setLinkVertices: true,
      rankDir: 'TB',
      marginX: 100,
      marginY: 100,
    })
  }

  const drawDiagram = () => {
    if (!diagramData) return
    const rects = diagramData.cells.filter(cell => cell.type === 'standard.Rectangle')
    const xPositions = rects.map(cell => cell.position.x)
    const yPositions = rects.map(cell => cell.position.y)

    const allSameX = xPositions.every((val, i, arr) => val === arr[0])
    const allSameY = yPositions.every((val, i, arr) => val === arr[0])
    store.graph.fromJSON(diagramData)

    if (allSameX && allSameY) {
      autoLayout()
    }
  }

  return (
    <Fragment>
      <div id='_DiagramComposer' className={css.page}>
        <LeftPanel />

        <div id='paperBack' className={css.diagramBack}>
          <ControlsBar autoLayout={autoLayout} specId={state.specId} />
          <div id='paperContainer' className={`${css.diagramContainer}`} />
          <DiagramInspector id='inspectorContainer' />
        </div>
      </div>
      <div id='measurePaper' className={css.measurePaper} style={{ height: '11in', width: '8.5in' }} />
    </Fragment>
  )
}

export default observer(DiagramComposer)
