/* eslint-disable import/prefer-default-export */
export const controlLabel = ({ theme }) => ({
  color: theme['legacy-color-dark'],
  marginRight: theme['default-spacer-sm'],
  paddingTop: '2px',
  whiteSpace: 'nowrap',
})

export const topBar2 = ({ theme }) => ({
  background: theme.white,
  color: theme['legacy-color-gray6'],
  padding: 0,
  zIndex: theme['z-base'],
})
