import React from 'react'

import { Button } from '@fortressiq/fiq-ds'
import store from '../stores/processExplorerStore'

const SubProcsControl = ({ disabled }) => (
  <Button onClick={store.setSubprocessMode} icon='models' disabled={disabled}>
    Create Subprocess
  </Button>
)

export default SubProcsControl
