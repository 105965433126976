import React from 'react'

import css from './bar.scss'

const Bar = ({ text, percent, isHidden }) => (
  <div className={`${css.container} ${isHidden ? css.hideDuration : ''}`}>
    <div className={css.bar} style={{ width: `${percent}%` }}>
      &#8203;
    </div>
    <div className={css.text}>{text}</div>
  </div>
)

export default Bar
