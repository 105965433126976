import {
  addDays,
  addHours,
  addMonths,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  format,
  startOfDay,
  startOfHour,
  startOfMonth,
} from 'date-fns'

import { FNS_DATE_FORMAT_SHORT, FNS_TIME_FORMAT_SHORT, DATE_TIME_FORMAT_TWENTYFOUR_HOUR } from 'constants/app-constants'

export const timeOptions = {
  1: { value: 1, label: 'Last 24 Hrs' },
  7: { value: 7, label: 'Last 7 days' },
  30: { value: 30, label: 'Last month' },
  120: { value: 120, label: 'Last 4 months' },
  365: { value: 365, label: 'Last Year' },
}

export const observerActions = {
  SET_STATS: 'SET_STATS',
  SET_OBSERVERS: 'SET_OBSERVERS',
  SET_DAY_STATS: 'SET_DAY_STATS',
  OPTION_CHANGED: 'OPTION_CHANGED',
  NEO_STATUS_CHANGE: 'NEO_STATUS_CHANGE',
}

export const timeSteps = {
  HOUR: 'hour',
  DAY: 'day',
  MONTH: 'month',
}

export const getTimeFormat = step => {
  let timeFormat = FNS_DATE_FORMAT_SHORT
  if (step === timeSteps.HOUR) {
    timeFormat = FNS_TIME_FORMAT_SHORT
  }
  return {
    timeFormat,
  }
}

const addTime = (step, time) => {
  if (step === timeSteps.HOUR) {
    return addHours(time, 1)
  } else if (step === timeSteps.DAY) {
    return addDays(time, 1)
  } else if (step === timeSteps.MONTH) {
    return addMonths(time, 1)
  }
}

export const constructStats = (stats, time = null, step = timeSteps.DAY, total = 120) => {
  const events = []
  const sizes = []
  const { timeFormat } = getTimeFormat(step)
  // if time is not given, use the earliest entry in stats

  if (!time) {
    const now = new Date()
    if (step === timeSteps.HOUR) {
      time = startOfHour(new Date(Object.keys(stats)[0]))
      total = differenceInHours(now, time) + 1
    } else if (step === timeSteps.DAY) {
      time = startOfDay(new Date(Object.keys(stats)[0]))
      total = differenceInDays(now, time) + 1
    } else if (step === timeSteps.MONTH) {
      time = startOfMonth(new Date(Object.keys(stats)[0]))
      total = differenceInMonths(now, time) + 1
    }
  }
  for (let i = 0; i < total; i += 1) {
    const lookUpKey = `${format(time, DATE_TIME_FORMAT_TWENTYFOUR_HOUR)} UTC`
    events.push({
      label: format(time, timeFormat),
      value: stats[lookUpKey] ? stats[lookUpKey].events : 0,
    })
    sizes.push({
      label: format(time, timeFormat),
      value: stats[lookUpKey] ? stats[lookUpKey].bytes : 0,
    })
    time = addTime(step, time)
  }
  return {
    events,
    sizes,
  }
}

export const getFillerForDateArray = (difference, start, step = timeSteps.DAY) => {
  const filler = []
  if (difference > 0) {
    let time = null
    const startTime = new Date(start)
    if (step === timeSteps.HOUR) {
      time = startOfHour(startTime)
    } else if (step === timeSteps.DAY) {
      time = startOfDay(startTime)
    } else if (step === timeSteps.MONTH) {
      time = startOfMonth(startTime)
    }
    const { timeFormat } = getTimeFormat(step)
    for (let i = 0; i < difference; i += 1) {
      filler.unshift({
        time: format(time, timeFormat),
        value: 0,
      })
      time = addTime(step, time)
    }
  }
  return filler
}
