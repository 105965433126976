import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import stringSimilarity from 'string-similarity'

import { routes } from 'app/AppRoutes'
import { useUserState } from 'context/UserContext'
import checkAllowedRoutes from 'lib/checkAllowedRoutes'
import uiStore from 'lib/UiStore'

import css from './notFound.scss'

const NotFound = ({ location }) => {
  const { roles } = useUserState()
  const [allowedRoutes] = checkAllowedRoutes(routes, roles, uiStore.tenant)
  const availablePaths = allowedRoutes.map(({ path }) => path)
  const {
    bestMatch: { target },
  } = stringSimilarity.findBestMatch(location.pathname, availablePaths)

  const [match] = location.pathname.match(/(\d+)$/) || [1]
  const to = target.replace(/\?$/, '').replace(/:id$/, match)
  const possibleRoute = window.location.origin + to

  return (
    <div className={css.notFound}>
      <h2 className={css.fourohfour}>404</h2>
      <p className={css.routeNotFound}>Unknown route</p>
      <p>
        Did you mean <Link to={to}>{possibleRoute}</Link>?
      </p>
    </div>
  )
}

export default withRouter(NotFound)
