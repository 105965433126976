import React from 'react'
import { observer } from 'mobx-react'
import { Button, Input } from '@fortressiq/fiq-ds'

import ButterflyProcessStore from '../stores/ButterflyProcessStore'

import PathDetails from './sections/PathDetails'

import css from './createProcessSectionStyle.scss'

@observer
class CreateProcessSection extends React.Component {
  render() {
    const { name, setName, saveProcess } = this.props
    const { paths, selectionId, validPathsCount } = ButterflyProcessStore
    const cannotSave = validPathsCount === 0 || !name || ButterflyProcessStore.errorMessage

    return (
      <div className={css.container}>
        <div className={css.section}>
          <Input
            className={css.input}
            label='Name'
            value={name}
            onChange={e => setName(e)}
            placeholder='Name this Process'
            onMouseDown={e => e.stopPropagation()}
          />
        </div>
        <div className={css.paths}>
          {Object.keys(paths).map((key, i) => {
            return <PathDetails key={key} selectionId={paths[key].selectionId} pathNumber={i + 1} />
          })}
          {paths[selectionId] && paths[selectionId].pathEnd && !ButterflyProcessStore.creatingNew && (
            <AddNewPathButton />
          )}
          {/* if creating a new path display path creation UI */}
          {ButterflyProcessStore.creatingNew && (
            <PathDetails selectionId={selectionId} pathNumber={Object.keys(paths).length + 1} />
          )}
        </div>
        <div className={css.controlsContainer}>
          <Button onClick={saveProcess} disabled={cannotSave} type='secondary'>
            Save as Model
          </Button>
        </div>
      </div>
    )
  }
}

const AddNewPathButton = () => (
  <div className={css.addPathLine} onClick={() => ButterflyProcessStore.setNewProcess()}>
    <Button className={css.addPathButton} size='small'>
      Add New Path
    </Button>
  </div>
)

export default CreateProcessSection
