import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Element, useModal } from '@fortressiq/fiq-ds'
import throttle from 'lodash/throttle'

import api from 'lib/Api'
import MainLoader from 'components/loaders/MainLoader'
import EventTimeline from 'components/EventTimeline/EventTimeline'

import EventSlide from './EventSlide'
import { keys, scrollTimeout, loadLimit as limit, tween } from './common'
import { eventSliderCSS, screenshotCSS } from './styles'

const { LEFT, RIGHT, ENTER } = keys
const resizeObserver = new ResizeObserver(([{ target }]) => target.dispatchEvent(new CustomEvent('scroll')))

let scrollingTimeout
let isScrolling = false
let slideAnimation = null

export const scrollXToRef = (activeSlide, isAnimated = true) => {
  const { parentElement: slider } = activeSlide
  const scrollLeft = activeSlide.offsetLeft - slider.offsetLeft

  window.cancelAnimationFrame(slideAnimation)
  if (isAnimated) {
    isScrolling = true

    slideAnimation = tween({
      from: slider.scrollLeft,
      to: scrollLeft,
      onUpdate: v => {
        slider.scrollLeft = v
      },
      onComplete: () => {
        slideAnimation = window.cancelAnimationFrame(slideAnimation)
        isScrolling = false
      },
    })
  } else {
    const index = [...slider.children].indexOf(activeSlide)
    slider.scrollLeft = activeSlide.offsetWidth * index
  }
}

const EventGroup = ({ toggleSelectedRow, events: preloadedEvents, eventCount, cycleId }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { addModal } = useModal()
  const eventSliderRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [events, setEvents] = useState(preloadedEvents)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const loadedEventCount = events.length
  const triggerLoadMore = !isLoading && eventCount > loadedEventCount && activeSlideIndex === loadedEventCount - 1

  const closeModal = () => setModalOpen(false)

  const openTimelineModal = eventId => {
    addModal({
      children: <EventTimeline eventId={eventId} />,
      id: 'event-group-timeline-modal',
      onCancel: closeModal,
      disableButtons: true,
      style: { width: '90%' },
    })

    setModalOpen(true)
    toggleSelectedRow()
  }

  const activeSlideIndexFromScroll = () =>
    eventSliderRef.current ? Math.round(eventSliderRef.current.scrollLeft / eventSliderRef.current.clientWidth) : 0

  const loadMoreEvents = useCallback(async () => {
    setIsLoading(true)
    const lastEventId = events[loadedEventCount - 1].id
    const { data } = await api.get(`/event_logs/${lastEventId}/group`, {
      limit,
      offset: loadedEventCount - 1,
      cycleId,
    })
    setEvents([...events, ...data.events])
    setIsLoading(false)
  }, [setEvents, setIsLoading, events, loadedEventCount])

  const getSlideRef = index => eventSliderRef.current.children[index]

  const handlePrevSlide = useCallback(() => {
    const prevSlide = getSlideRef(activeSlideIndexFromScroll() - 1)
    if (prevSlide) {
      const isAnimated = !isScrolling && !slideAnimation
      scrollXToRef(prevSlide, isAnimated)
    }
  }, [])

  const handleNextSlide = useCallback(() => {
    const nextSlide = getSlideRef(activeSlideIndexFromScroll() + 1)
    if (nextSlide) {
      const isAnimated = !isScrolling && !slideAnimation
      scrollXToRef(nextSlide, isAnimated)
    }
  }, [])

  const keyHandlerMap = useMemo(
    () => ({
      [LEFT]: handlePrevSlide,
      [RIGHT]: handleNextSlide,
      [ENTER]: () => toggleSelectedRow(),
    }),
    [handleNextSlide, handlePrevSlide, toggleSelectedRow]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keydownHandler = useCallback(
    throttle(
      e => {
        e.preventDefault()
        if (keyHandlerMap[e.which]) {
          keyHandlerMap[e.which]()
        }
      },
      Math.round(scrollTimeout / 2),
      { leading: false }
    ),
    [keyHandlerMap]
  )

  useEffect(() => {
    const { current: currentRef } = eventSliderRef

    const scrollHandler = () => {
      isScrolling = true
      window.clearTimeout(scrollingTimeout)
      const onScrollStop = () => {
        if (currentRef) {
          const activeIndex = activeSlideIndexFromScroll()
          setActiveSlideIndex(activeIndex)
          scrollXToRef(currentRef.children[activeIndex], true)
          isScrolling = false
        }
      }
      scrollingTimeout = setTimeout(onScrollStop, scrollTimeout)
    }

    if (!modalOpen) {
      currentRef.addEventListener('scroll', scrollHandler, false)
      window.addEventListener('keydown', keydownHandler, true)
      resizeObserver.observe(currentRef)
    }

    if (triggerLoadMore) {
      loadMoreEvents()
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', scrollHandler, false)
        window.removeEventListener('keydown', keydownHandler, true)
        resizeObserver.unobserve(currentRef)
      }
    }
  }, [loadMoreEvents, triggerLoadMore, keydownHandler, modalOpen])

  return (
    <Element style={screenshotCSS} onClick={toggleSelectedRow}>
      {isLoading && <MainLoader style={{ zIndex: 1 }} />}
      <Element className='event-slider' ref={eventSliderRef} style={eventSliderCSS}>
        {events.map((event, slideIndex) => (
          <EventSlide
            key={`event-slide-${event.id}`}
            {...{
              event,
              slideIndex,
              isLoading,
              slideCount: eventCount,
              openTimelineModal,
              isLazy: slideIndex >= activeSlideIndex + 3 || slideIndex <= activeSlideIndex - 3,
            }}
          />
        ))}
      </Element>
    </Element>
  )
}

EventGroup.propTypes = {
  cycleId: PropTypes.string,
  toggleSelectedRow: PropTypes.any,
  events: PropTypes.any,
  eventCount: PropTypes.number,
}

export default EventGroup
