import React, { memo } from 'react'
import { Element, theme } from '@fortressiq/fiq-ds'
import PropTypes from 'prop-types'

import ObserverDetail from '../components/ObserverDetail'

const ProcessOverview = ({
  overview: {
    pathsCount = null,
    steps = null,
    instanceCount = null,
    eventCount = null,
    averageDuration = null,
    totalDuration = null,
    observerCount = null,
  },
}) => (
  <Element
    style={{
      borderBottom: `1px solid ${theme['legacy-color-gray2']}`,
      padding: `${theme['default-spacer-md']} ${theme['default-spacer-md']} ${theme['default-spacer-md']} 20px`,
      width: '100%',
    }}
  >
    <ObserverDetail value={pathsCount} label='paths' />
    <ObserverDetail value={steps} label='steps' />
    <ObserverDetail value={instanceCount} label='instances' />
    <ObserverDetail value={eventCount} label='events' />
    <ObserverDetail value={averageDuration} label='average duration' type='date' />
    <ObserverDetail value={totalDuration} label='total duration' type='date' />
    <ObserverDetail value={observerCount} label='observers' />
  </Element>
)

ProcessOverview.propTypes = {
  overview: PropTypes.shape({
    pathsCount: PropTypes.number,
    steps: PropTypes.number,
    instanceCount: PropTypes.number,
    eventCount: PropTypes.number,
    averageDuration: PropTypes.string,
    totalDuration: PropTypes.string,
    observerCount: PropTypes.number,
  }).isRequired,
}

export default memo(ProcessOverview)
