/* eslint-disable import/prefer-default-export */
import { theme } from '@fortressiq/fiq-ds'

export const backCSS = ({ height, width }) => ({
  backdropFilter: 'blur(5px)',
  background: 'hsla(215.5, 12.6%, 65.9%, 0.3)',
  borderRadius: '2px',
  bottom: '12px',
  cursor: 'pointer',
  display: 'inline-block',
  height: height || '90px',
  left: '78px',
  overflow: 'hidden',
  position: 'fixed',
  width: width || '100px',
  zIndex: '100',
})

export const controlCSS = ({ height, left, top, width }) => ({
  background: theme.white,
  border: `1px solid ${theme.white}`,
  height: height || '10px',
  left: left || '0',
  opacity: '0.8',
  position: 'absolute',
  top: top || '0',
  transition: `all ${theme['animation-speed-fast']} linear`,
  width: width || '10px',
})
