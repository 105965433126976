import React, { useState } from 'react'
import { arrayOf, shape, string, bool, func, object } from 'prop-types'
import { defaultModalConfig, Overlay, theme, useModal, useNotification } from '@fortressiq/fiq-ds'
import { useGetSetState, useUpdateEffect } from 'react-use'

import { FLASH_LEVELS } from 'components/flash/Flash'
import Modal from './Modal'

import exportTypes from './exportTypes'

const ExportButton = ({
  allColumns,
  buttonText,
  defaultColumns,
  defaultExportName,
  options,
  exportType,
  exportCallback: globalExportCallback,
  callbackStore,
}) => {
  const [showing, setShowing] = useState(false)
  const [get, setState] = useGetSetState({
    columns: defaultColumns,
    currentExport: {},
    isExporting: false,
    progress: null,
    name: defaultExportName,
    showColumns: false,
    step: 'Starting Export',
  })
  const { addNotification } = useNotification()
  const { addModal, removeModal } = useModal()

  // If columns is updated, update state:
  useUpdateEffect(() => {
    setState({ ...get(), columns: defaultColumns })
  }, [defaultColumns])

  // If name is updated, update state:
  useUpdateEffect(() => {
    setState({ ...get(), name: defaultExportName })
  }, [defaultExportName])

  let callbackId = null

  const onProgress = ({ progress, step }) => {
    progress = parseFloat(progress)

    if (progress === 100) {
      setState({ ...get(), isExporting: false, progress, step })
      callbackStore.remove(callbackId, onProgress)
      if (globalExportCallback) globalExportCallback()
      return
    }

    setState({ ...get(), progress, step })
  }

  const closeModal = () => removeModal(modalID)

  const exportFile = () => {
    const { exportArgs, exportCallback } = get().currentExport

    closeModal()

    setState({ ...get(), isExporting: true })

    addNotification({
      autoClose: 3000,
      description: `Starting export for "${get().name}".`,
      message: 'Exporting',
      type: FLASH_LEVELS.INFO,
    })

    if (exportType === 'event') callbackId = callbackStore.add(onProgress, undefined, true)

    const params = {
      ...(!!exportArgs && { ...exportArgs }),
      exportName: get().name,
      callbackId,
      ...(exportType === 'event' ? { fields: get().columns } : null),
    }

    exportTypes[exportType](params)
    setState({ ...get(), currentExport: null })

    if (exportType !== 'event') {
      if (exportCallback) exportCallback()
      setState({ ...get(), isExporting: false })
    }
  }

  const modalID = 'export-file-modal'
  const modalZIndex = parseInt(theme['z-modal-above'], 10)

  const exportModalOptions = {
    children: (
      <Modal allColumns={allColumns} closeModal={closeModal} exportFile={exportFile} setState={setState} state={get} />
    ),
    config: { ...defaultModalConfig, interactWithKeyboard: false },
    footer: false,
    header: false,
    id: modalID,
    style: { maxWidth: '500px' },
    zIndex: modalZIndex,
  }

  const myOptions = options.map(({ disabled, onClick, text, showColumns, tooltip }, index) => ({
    disabled,
    onClick: e => {
      setState({
        ...get(),
        currentExport: options[index],
        showColumns: showColumns,
      })
      if (onClick) {
        onClick(e)
      }

      return addModal(exportModalOptions)
    },
    tooltip,
    text,
  }))

  const exportMessage = get().progress ? `${get().step}… ${get().progress}` : 'Exporting...'
  const exportButtonText = get().isExporting ? exportMessage : buttonText
  const trigger = {
    showing: showing,
    setShowing: setShowing,
    text: exportButtonText,
    type: 'default',
  }

  const heights = {
    max: 38 * 3,
  }
  const widths = {
    max: 230,
    min: 230,
    width: 230,
  }

  const overlayZIndex = modalZIndex - 2

  return (
    <Overlay
      dropdownStyle={{ zIndex: modalZIndex - 1 }}
      heights={heights}
      id='export-button-overlay'
      menu={myOptions}
      trigger={trigger}
      widths={widths}
      zIndex={overlayZIndex}
    />
  )
}

ExportButton.propTypes = {
  options: arrayOf(
    shape({
      text: string.isRequired,
      disabled: bool,
      exportCallback: func,
      // eslint-disable-next-line react/forbid-prop-types
      exportArgs: object,
    })
  ).isRequired,
  buttonText: string.isRequired,
  defaultExportName: string,
  exportType: string,
  callbackStore: shape({}).isRequired,
}

ExportButton.defaultProps = {
  defaultExportName: '',
  exportType: 'event',
}

export default ExportButton
