/* eslint-disable import/prefer-default-export */
import { theme } from '@fortressiq/fiq-ds'

import { ellipsisCSS } from 'styles/utils'

const border = `1px solid ${theme['legacy-color-gray1']}`

export const sidebarItemCSS = () => ({
  borderBottom: border,
  padding: `${theme['default-spacer-md']} ${theme['default-spacer-sm']}`,
  position: 'relative',
  '> .fiqds-sortable.is-editing .strategy-scrollable-listitem:not(.is-being-edited) .edit-btn': {
    opacity: 0.3,
    pointerEvents: 'none',
  },
})

export const appHeadingCSS = ({ isDisabled }) => ({
  display: 'block',
  fontSize: theme['font-size-base'],
  margin: 0,
  ...(isDisabled && {
    opacity: '0.3',
  }),
})

export const richSelectOptionCSS = () => ({
  '> div ul div': {
    background: theme['legacy-color-gray1'],
    fontWeight: 600,
    padding: `0 ${theme['default-spacer-md']}`,
  },
  '> div .defaultOption': {
    display: 'none',
  },
  '> div .richOption': {
    display: 'block',
  },
})

export const highlightableCSS = () => ({
  borderRadius: theme['border-radius-base'],
  boxShadow: `0 0 0 0 ${theme['warning-color']}`,
  transition: `box-shadow ${theme['animation-speed-base']} ease-in-out`,
  width: '100%',
  '&.isHighlighted': {
    boxShadow: `0 0 0 2px ${theme['warning-color']}`,
  },
})

export const inputStyle = () => ({
  width: '100%',
})

export const checkboxLabel = () => ({
  color: theme['text-dark'],
  fontSize: theme['font-size-base'],
  fontWeight: '600',
})

export const applicationTitleBoxCSS = () => ({
  alignItems: 'center',
  borderBottom: border,
  display: 'flex',
  height: '75px',
  padding: `2px ${theme['default-spacer-md']}`,
})

export const applicationTitleDescriptionCSS = () => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme['default-spacer-md'],
  ...ellipsisCSS,
})

export const applicationNameTitleCSS = () => ({
  fontSize: '18px',
  lineHeight: '24px',
})

export const screenListContainerCSS = () => ({
  borderLeft: border,
  right: 0,
})

export const overlayTitleCSS = () => ({
  background: theme['contrast-background-color'],
  borderBottom: `1px solid ${theme['border-color-base']}`,
  borderTop: `1px solid ${theme['border-color-base']}`,
  fontSize: '20px',
  margin: `${theme['default-spacer-sm']} 0`,
  padding: theme['default-spacer-sm'],
  position: 'sticky',
  top: 0,
  zIndex: theme['z-overdrive'],
})

export const suggestedWordsCSS = ({ isError, showTable }) => ({
  marginTop: 0,
  opacity: showTable || isError ? 1 : 0.2,
  transition: `opacity ${theme['animation-speed-base']} ease-in-out`,
})
