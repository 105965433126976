const graphTypeDisplayNameMap = {
  butterfly: 'Butterfly',
  default: 'Path',
  flowgraph: 'Flow',
  path: 'Path',
  recipes: 'Recipes',
  report: 'Report',
}

export default graphTypeDisplayNameMap
