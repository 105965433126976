import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { getUserMenu } from 'lib/checkAllowedRoutes'
import uiStore from 'lib/UiStore'

import { useUserState } from 'context/UserContext'
import { Sidebar, MenuItem, SubMenu, SubMenuItem } from '../components/SidebarMenu'

const dashboardIcon = { icon: 'dashboardhomeicon', size: 24, style: { marginTop: 2 } }
const limitedUserRoutes = [{ label: 'Dashboard', icon: dashboardIcon, to: '/client-dashboard' }]

const allRoutes = [
  {
    label: 'Dashboard',
    icon: dashboardIcon,
    to: '/',
  },
  {
    label: 'Data',
    icon: { fill: 'transparent', icon: 'databaseicon', size: 24 },
    subItems: [
      {
        label: 'Observers',
        to: '/observers',
      },
      {
        label: 'Cycles',
        to: '/cycles',
      },
      {
        label: 'Mining Runs',
        to: '/mining',
      },
      {
        label: 'Applications',
        to: '/applications',
      },
      {
        label: 'Web Applications',
        to: '/web-applications',
      },
      {
        label: 'Review',
        to: '/review',
      },
      {
        label: 'Signature Jobs',
        to: '/signature-service-jobs',
      },
      {
        label: 'Status',
        to: '/status',
      },
      {
        label: 'Events',
        to: '/events',
      },
      {
        label: 'Event Log Exports',
        to: '/event-log-exports',
      },
      {
        label: 'Instances Viewer',
        to: '/instances-viewer',
      },
      {
        label: 'Templates',
        to: '/templates',
      },
    ],
  },
  {
    label: 'Visualizations',
    icon: { fill: 'transparent', icon: 'visualizationthick', size: 24 },
    subItems: [
      {
        label: 'Flow',
        to: '/flow-graph',
      },
      {
        label: 'Path',
        to: '/process-explorer',
      },
      {
        label: 'Butterfly',
        to: '/visualizations/butterfly',
      },
    ],
  },
  {
    label: 'Models',
    icon: { fill: 'transparent', icon: 'modelsthick', size: 24 },
    subItems: [
      {
        label: 'PDDs',
        to: '/pdds',
      },
      {
        label: 'Diagram Composer',
        to: '/diagrams/new',
      },
      {
        label: 'Client Dashboard',
        to: '/client-dashboard',
      },
    ],
  },
  {
    label: 'Processes',
    icon: { fill: 'transparent', icon: 'processesthick', size: 24 },
    to: '/processes',
  },
]

const AppSidebar = ({ location: { pathname } }) => {
  const { roles } = useUserState()
  const limitedUser = roles.includes('limited')
  const [collapsedSidebar, setCollapsedSidebar] = useState(true)
  const [activeSubMenu, setActiveSubMenu] = useState()
  const [activeMenuItem, setActiveMenuItem] = useState()
  const [routes, setRoutes] = useState()

  useEffect(() => {
    setRoutes(limitedUser ? limitedUserRoutes : getUserMenu(allRoutes, roles, uiStore.tenant))
  }, [limitedUser, roles])

  useEffect(() => {
    if (routes) {
      const handleIsItemActive = itemPath => {
        if (itemPath === '/') {
          if (pathname === '/') {
            return true
          }
          return false
        } else {
          const regex = new RegExp(`^${itemPath}`, 'i')
          const isActive = regex.test(pathname)
          return isActive
        }
      }
      let isAnyActive = false
      for (let i = 0; i < routes.length; i += 1) {
        const item = routes[i]
        if (item.subItems) {
          for (let j = 0; j < item.subItems.length; j += 1) {
            const subItem = item.subItems[j]
            if (subItem && handleIsItemActive(subItem.to, item.label)) {
              setActiveSubMenu(item.label)
              setActiveMenuItem(subItem.to)
              isAnyActive = true
              break
            }
          }
        } else if (handleIsItemActive(item.to)) {
          setActiveSubMenu()
          setActiveMenuItem(item.to)
          isAnyActive = true
          break
        }
      }
      if (!isAnyActive) {
        setActiveSubMenu()
        setActiveMenuItem()
      }
    }
  }, [pathname, routes])

  return (
    <Sidebar collapsedSidebar={collapsedSidebar} setCollapsedSidebar={setCollapsedSidebar}>
      {routes &&
        routes.length !== 0 &&
        routes.map(item => {
          if (item.subItems) {
            return (
              <SubMenu
                collapsedSidebar={collapsedSidebar}
                key={`nav-${item.label}`}
                isActive={activeSubMenu === item.label}
                item={item}
              >
                {item.subItems.map(subItem => {
                  return (
                    <SubMenuItem
                      collapsedSidebar={collapsedSidebar}
                      key={`nav-${subItem.to}`}
                      isActive={activeMenuItem === subItem.to}
                      item={subItem}
                    />
                  )
                })}
              </SubMenu>
            )
          }
          return (
            <MenuItem
              collapsedSidebar={collapsedSidebar}
              key={`nav-${item.label}`}
              isActive={activeMenuItem === item.to}
              item={item}
            />
          )
        })}
    </Sidebar>
  )
}

export default withRouter(AppSidebar)
