import { useRef, useEffect, useState, useCallback } from 'react'
import { usePrevious } from 'react-use'
import { useLocation } from 'react-router-dom'
import api from 'lib/Api'

export function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const useFetch = (url, options = null, conditions = [true]) => {
  const [data, setData] = useState()
  const [errors, setErrors] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const optionsHash = JSON.stringify([url, options])
  const previousOptionsHash = usePrevious(optionsHash)
  const shouldFetch = previousOptionsHash !== optionsHash && conditions.every(Boolean)

  const fetchData = useCallback(
    async (fetchOptions = {}) => {
      const { method = 'GET', ...restOptions } = { ...fetchOptions, ...options }
      const action = method.toLocaleLowerCase()
      setIsLoading(true)
      setData()
      const response = await api[action](url, restOptions).catch(err => setErrors(err))
      setData(response.data)
      setIsLoading(false)
    },
    [url, options]
  )

  useEffect(() => {
    if (shouldFetch) {
      fetchData()
    } else {
      setIsLoading(false)
    }
  }, [shouldFetch, fetchData])

  return { isLoading, data, errors, setData, reFetch: fetchData }
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

//can be used to work similar to setState from class components
// ex: const [employee, setEmployee] = useMergeState(initObj)
// setEmployee({ name: 'Joe' })
export const useMergeState = initialState => {
  const [state, setState] = useState(initialState)
  const setMergedState = newState => setState(prevState => ({ ...prevState, ...newState }))
  return [state, setMergedState]
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])
  return debouncedValue
}

// Persisted use state with localstorage
// Similar to useState but first arg is key to the value in local storage.
// const [name, setName] = useLocalStorage('name', 'Bob');
// Docs: https://usehooks.com/useLocalStorage/

const clearOnLogoutKey = 'clearOnLogoutKeys'

export const useLocalStorage = (key, initialValue, shouldClearOnLogout = false) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.error(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setPersistedValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error)
    }
  }

  if (shouldClearOnLogout) {
    const item = window.localStorage.getItem(clearOnLogoutKey)

    const clearOnLogoutKeys = item ? JSON.parse(item) : []

    if (!clearOnLogoutKeys.includes(key)) {
      const newArray = [...clearOnLogoutKeys, key]
      window.localStorage.setItem(clearOnLogoutKey, JSON.stringify(newArray))
    }
  }

  return [storedValue, setPersistedValue]
}

export const useApplicationsMap = () => {
  const [applicationsMap, setApplicationsMap] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchApplications = async () => {
      setIsLoading(true)
      const { data = [] } = await api.get('/applications/filters')
      setApplicationsMap(
        data.reduce((acc, cur) => {
          acc[cur.value] = cur.label
          return acc
        }, {})
      )
      setIsLoading(false)
    }
    if (!applicationsMap) {
      fetchApplications()
    }
  }, [applicationsMap])

  return { applicationsMap, isLoading }
}

export const clearUseLocalStorage = () => {
  const item = window.localStorage.getItem(clearOnLogoutKey)
  if (item) {
    const keys = JSON.parse(item)
    keys.forEach(key => {
      window.localStorage.removeItem(key)
    })
    window.localStorage.removeItem(clearOnLogoutKey)
  }
}

export default { useInterval }
