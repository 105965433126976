// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`TenantCreator component should render without throwing an error 1`] = `
<ContextProvider
  value={
    {
      "canViewUnredactedImages": false,
      "email": "",
      "id": "",
      "isAdmin": false,
      "isSuperUser": false,
      "name": "",
      "roles": [
        "superuser",
      ],
    }
  }
>
  <ContextProvider
    value={[Function]}
  >
    <HeaderProvider>
      <TenantCreator />
    </HeaderProvider>
  </ContextProvider>
</ContextProvider>
`;
