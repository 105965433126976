import React, { useCallback, useState, useEffect, useReducer } from 'react'
import { any, arrayOf, func, number, objectOf, oneOfType, shape, string } from 'prop-types'
import { Button, ButtonGroup, Checkbox, Element, get, Group, Popconfirm, Spin, theme } from '@fortressiq/fiq-ds'
import { stringify } from 'query-string'

import api from 'lib/Api'

import ConfigureObserver from 'components/configureObserver/ConfigureObserver'
import { LIST_CATEGORIES, LIST_TYPES } from 'components/configureObserver/Constants'
import { getConfiguredAllowBlockLists } from 'components/configureObserver/utils'
import AppList from './AppList'

import { emptyContainer } from './styles'

import { ADD_APPLICATION, REMOVE_APPLICATION, SET_APPLICAIONS, TOGGLE_APPLICATION_CAPTURE } from './Constants'

const configureFormInit = {
  applicationListAccessType: LIST_TYPES.NONE.val,
  applicationListValue: undefined,
  urlListAccessType: LIST_TYPES.NONE.val,
  urlListValue: undefined,
  screenshotAllowed: true,
  keystrokeAllowed: true,
  clipboardAllowed: true,
  processCaptureSettings: [],
  titleAllowed: true,
}

function getAppHash(processCaptureSettings) {
  const appHash = {}
  processCaptureSettings.forEach(app => {
    appHash[app.name] = {
      screenshotAllowed: app.screenshotAllowed,
      keystrokeAllowed: app.keystrokeAllowed,
      titleAllowed: app.titleAllowed,
    }
  })
  return appHash
}

function eventsReducer(state, action) {
  switch (action.type) {
    case SET_APPLICAIONS: {
      return { processCaptureSettings: [...action.applications] }
    }
    case ADD_APPLICATION: {
      const appHash = getAppHash(state.processCaptureSettings)

      action.values
        .toLowerCase()
        .split(',')
        .filter(app => app)
        .forEach(value => {
          appHash[value.trim()] = {
            screenshotAllowed: appHash[value] ? appHash[value].screenshotAllowed : true,
            keystrokeAllowed: appHash[value] ? appHash[value].keystrokeAllowed : true,
            titleAllowed: appHash[value] ? appHash[value].titleAllowed : true,
          }
        })

      return { ...state, processCaptureSettings: hashToArray(appHash) }
    }
    case REMOVE_APPLICATION: {
      const appHash = getAppHash(state.processCaptureSettings)
      delete appHash[action.value]
      return { ...state, processCaptureSettings: hashToArray(appHash) }
    }
    case TOGGLE_APPLICATION_CAPTURE: {
      const appHash = getAppHash(state.processCaptureSettings)

      appHash[action.name][action.captureType] = action.value
      return { ...state, processCaptureSettings: hashToArray(appHash) }
    }
    default: {
      return state
    }
  }
}

const hashToArray = hash => {
  return Object.keys(hash).map(key => ({
    name: key,
    ...hash[key],
  }))
}

const ConfigureModal = ({
  filter,
  observerCount,
  observerId,
  onOk,
  onCancel,
  windowsUser,
  neoConfigObserverId,
  reFetch,
  tenantObserverSettings,
}) => {
  const [loading, setLoading] = useState(true)
  const [tempValues, setTempValues] = useState(configureFormInit)
  const [originalValues, setOriginalValues] = useState(configureFormInit)

  const [state, dispatch] = useReducer(eventsReducer, { processCaptureSettings: [] })

  const {
    applicationListAccessType,
    applicationListValue,
    urlListAccessType,
    urlListValue,
    screenshotAllowed,
    keystrokeAllowed,
    clipboardAllowed,
    titleAllowed,
    applyToUsers,
  } = tempValues
  const [urlLists, setUrlLists] = useState([])
  const [applicationLists, setApplicationLists] = useState([])

  const observerCountText = observerCount === 1 ? 'Observer' : 'Observers'

  const fetchConfigData = useCallback(async () => {
    const filters = { observers: [observerId] }
    const { data } = await api.get('/observers/neo_config_policies', { filters: filters })
    const first = data[0]?.data?.attributes

    const { url: urlListValueObj, application: applicationListValueObj } = getConfiguredAllowBlockLists(
      first.allowBlockLists
    )

    const newUrlListAccessType = urlListValueObj?.accessType || LIST_TYPES.NONE.val

    const newAppListAccessType = applicationListValueObj?.accessType || LIST_TYPES.NONE.val

    const newFormValues = {
      ...first,
      urlListAccessType: newUrlListAccessType,
      urlListValue: urlListValueObj?.id,
      applicationListAccessType: newAppListAccessType,
      applicationListValue: applicationListValueObj?.id,
      applyToUsers: !!neoConfigObserverId,
    }
    setTempValues(newFormValues)
    setOriginalValues(newFormValues)
    dispatch({ type: SET_APPLICAIONS, applications: newFormValues.processCaptureSettings })
    setLoading(false)
  }, [observerId, neoConfigObserverId])

  const fetchUrlAllowAndBlockLists = useCallback(async () => {
    const urlListResponse = await api.get('/allow_block_lists', { category_type: LIST_CATEGORIES.URL })
    setUrlLists(urlListResponse.data.allowBlockList)

    const applicationListResponse = await api.get('/allow_block_lists', { category_type: LIST_CATEGORIES.APPLICATION })
    setApplicationLists(applicationListResponse.data.allowBlockList)

    if (observerId) fetchConfigData()
    else {
      setTempValues(configureFormInit)
      setLoading(false)
    }
  }, [observerId, fetchConfigData])

  useEffect(() => {
    fetchUrlAllowAndBlockLists()
  }, [fetchUrlAllowAndBlockLists])

  const onChange = (key, value) => {
    const newFormValues = { ...tempValues }
    newFormValues[key] = value
    setTempValues(newFormValues)
  }

  const accessTypeOnChange = (key, type, value) => {
    const listValue = `${type}ListValue`
    const newFormValues = { ...tempValues }
    newFormValues[listValue] = null
    newFormValues[key] = value
    setTempValues(newFormValues)
  }

  const reset = () => {
    dispatch({ type: SET_APPLICAIONS, applications: originalValues?.processCaptureSettings || [] })
    setTempValues(originalValues)
  }

  const handleConfigure = async () => {
    const allowBlockLists = []
    if (urlListValue) allowBlockLists.push({ id: urlListValue, type: LIST_CATEGORIES.URL })
    if (applicationListValue) allowBlockLists.push({ id: applicationListValue, type: LIST_CATEGORIES.APPLICATION })

    const newConfig = {
      clipboardAllowed: clipboardAllowed || false,
      keystrokeAllowed: keystrokeAllowed || false,
      screenshotAllowed: screenshotAllowed || false,
      allowBlockLists,
      windowProcessCaptures: state.processCaptureSettings,
      titleAllowed: titleAllowed || false,
    }

    const filtersParams = { ...filter }
    if (!applyToUsers && observerId) filtersParams.observers = [observerId]
    if (windowsUser && applyToUsers) filtersParams.usernames = [windowsUser]
    const filters = stringify({ filters: JSON.stringify({ ...filtersParams }) })
    await api.put(`/observers/neo_config_policies?${filters}`, newConfig, false)

    if (windowsUser) {
      const encodedWindowUser = encodeURIComponent(windowsUser).replace(/\./g, '%252E')
      if (!applyToUsers) {
        await api.put(`/windows_username/${encodedWindowUser}`, {
          windows_username: encodedWindowUser,
          neo_config_observer_id: null,
        })
      } else if (tenantObserverSettings) {
        await api.put(`/windows_username/${encodedWindowUser}`, {
          windows_username: encodedWindowUser,
          neo_config_observer_id: observerId,
        })
      } else {
        await api.post('/windows_username', {
          windows_username: encodedWindowUser,
          neo_config_observer_id: observerId,
        })
      }
    }

    onOk()
    reFetch()
  }

  const isSubmitDisabled = () => {
    const urlListNotFilled = urlListAccessType !== LIST_TYPES.NONE.val && !urlListValue
    const applicationListNotFilled = applicationListAccessType !== LIST_TYPES.NONE.val && !applicationListValue
    return urlListNotFilled || applicationListNotFilled
  }

  if (loading) {
    return (
      <Spin
        color={theme['gray-tints-0']}
        containerStyle={emptyContainer}
        size={get.numericValues('spacer').lg}
        strokeWidth={2}
      >
        Loading&hellip;
      </Spin>
    )
  }

  return (
    <Element style={{ padding: `0 ${theme['default-spacer-md']} ${theme['default-spacer-xs']}` }}>
      <ConfigureObserver
        screenshotAllowed={screenshotAllowed}
        titleAllowed={titleAllowed}
        keystrokeAllowed={keystrokeAllowed}
        clipboardAllowed={clipboardAllowed}
        urlLists={urlLists}
        urlListValue={urlListValue}
        urlListAccessType={urlListAccessType}
        accessTypeOnChange={accessTypeOnChange}
        applicationLists={applicationLists}
        applicationListValue={applicationListValue}
        applicationListAccessType={applicationListAccessType}
        onChange={onChange}
      />
      <AppList processCaptureSettings={state.processCaptureSettings} dispatch={dispatch} />
      {windowsUser && (
        <Checkbox
          style={{ marginTop: theme['default-spacer-md'] }}
          checked={applyToUsers}
          onCheck={value => onChange('applyToUsers', value)}
        >
          Use configuration for observers owned by this user ({windowsUser})
        </Checkbox>
      )}
      <Group noEndGutter={true} justify='flex-end' style={{ marginTop: theme['default-spacer-lg'] }}>
        <Button type='link' onClick={reset}>
          Reset
        </Button>
        <ButtonGroup>
          <Button onClick={onCancel}>Cancel</Button>
          {observerCount > 1 ? (
            <Popconfirm
              onConfirm={handleConfigure}
              disabled={isSubmitDisabled()}
              title={`This will configure ${observerCount} observers.  Continue?`}
            >
              <Button type='secondary' disabled={isSubmitDisabled()}>
                Configure {observerCount} {observerCountText}
              </Button>
            </Popconfirm>
          ) : (
            <Button type='secondary' onClick={handleConfigure} disabled={isSubmitDisabled()}>
              Configure
            </Button>
          )}
        </ButtonGroup>
      </Group>
    </Element>
  )
}

ConfigureModal.propTypes = {
  filter: shape({
    tags: arrayOf(string),
    observers: arrayOf(string),
  }),
  neoConfigObserverId: number,
  observerCount: number.isRequired,
  observerId: oneOfType([number, string, null, undefined]),
  onOk: func.isRequired,
  onCancel: func.isRequired,
  reFetch: func,
  tenantObserverSettings: oneOfType([objectOf(any), undefined]),
}

ConfigureModal.defaultProps = {
  filter: {},
  neoConfigObserverId: undefined,
  observerId: undefined,
  reFetch: () => null,
}

export default ConfigureModal
