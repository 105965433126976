import React, { useCallback, Fragment, useState } from 'react'
import { arrayOf, number, shape, string } from 'prop-types'

import { Element } from '@fortressiq/fiq-ds'
import { useStore, EdgeLabelRenderer } from 'reactflow'
import intersection from 'lodash/intersection'

import { graphZIndex } from '../styles'

import dfgColors from '../dfgColors'

const getStrokeColor = (isHighlighted, zScore) => {
  if (isHighlighted) {
    return dfgColors.edgeBlue
  } else if (zScore > 1) {
    return dfgColors.edgePink
  } else {
    return dfgColors.edgeGray
  }
}

const getMarkerEnd = (isHighlighted, zScore, isHover) => {
  if (isHover) {
    return 'url(#customHoverArrow)'
  } else if (isHighlighted) {
    return 'url(#customSelectedArrow)'
  } else if (zScore > 1) {
    return 'url(#customZScoreArrow)'
  } else {
    return 'url(#standardArrow)'
  }
}

const getStrokeWidth = zScore => {
  if (zScore > 1) return 3
  return 1
}

const ElkEdge = ({ id, source, target, style, data, highlightedPaths, handleHighlightEdge, edges }) => {
  const [isHover, setIsHover] = useState(false)
  const sourceNode = useStore(useCallback(store => store.nodeInternals.get(source), [source]))
  const targetNode = useStore(useCallback(store => store.nodeInternals.get(target), [target]))
  const isHighlighted = intersection(highlightedPaths, data.pathList).length !== 0

  if (!sourceNode || !targetNode) {
    return null
  }

  const x1 = data?.sections[0]?.startPoint?.x
  const y1 = data?.sections[0]?.startPoint?.y
  const x2 = data?.sections[0]?.endPoint?.x
  const y2 = data?.sections[0]?.endPoint?.y

  const getEdgeData = () => {
    let d = `M${x1},${y1} C`

    data?.sections?.[0]?.bendPoints?.forEach(bend => {
      d += ` ${bend.x},${bend.y}`
    })
    d += ` ${x2},${y2}`

    return d
  }

  const calcStartLabel = () => {
    let tempInFreq = 0
    edges.forEach(edge => {
      if (edge.target === targetNode.id && edge.source !== 'start_node' && edge.target !== 'end_node') {
        tempInFreq += edge.data.frequency
      }
    })
    return targetNode.data.frequency - tempInFreq
  }

  const calcEndLabel = () => {
    let tempOutFreq = 0
    edges.forEach(edge => {
      if (edge.source === sourceNode.id && edge.source !== 'start_node' && edge.target !== 'end_node') {
        tempOutFreq += edge.data.frequency
      }
    })
    return sourceNode.data.frequency - tempOutFreq
  }

  const getLabel = () => {
    if (sourceNode.type === 'startNode') {
      return calcStartLabel()
    }

    if (targetNode.type === 'endNode') {
      return calcEndLabel()
    }
    return data.frequency
  }

  const edgePath = getEdgeData()
  const strokeWidth = getStrokeWidth(data.zScore)
  const strokeColor = isHover ? dfgColors.hoverEdge : getStrokeColor(isHighlighted, data.zScore)
  const markerEnd = getMarkerEnd(isHighlighted, data.zScore, isHover)
  const edgeStyle = {
    stroke: strokeColor,
    strokeWidth: strokeWidth,
    zIndex: graphZIndex.edge,
    ...(data?.type !== 'stepEdge' && { strokeDasharray: '8,8' }),
  }

  const edgeLabelStyles = {
    position: 'absolute',
    transform: `translate(-50%, -50%) translate(${data.label.x}px,${data.label.y}px)`,
    background: dfgColors.edgeLabelBackgroundGray,
    border: `${isHover ? '1px' : 0} solid ${dfgColors.hoverEdge}`,
    padding: '4px',
    borderRadius: '8px',
    fontSize: '12px',
    color: '#000',
    zIndex: isHover ? graphZIndex.overNode : graphZIndex.overEdge,
    pointerEvents: 'auto',
  }

  return (
    <Fragment>
      <path
        d={edgePath}
        stroke='transparent'
        fill='none'
        strokeWidth={10}
        onClick={e => handleHighlightEdge(e, data)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        data-automation-id={`from ${sourceNode.id} to ${targetNode.id}`}
      />
      <path
        markerEnd={markerEnd}
        id={id}
        style={{ ...style, ...edgeStyle }}
        className='react-flow__edge-path'
        d={edgePath}
        onClick={e => handleHighlightEdge(e, data)}
      />
      <EdgeLabelRenderer>
        <Element onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} style={edgeLabelStyles}>
          {getLabel()}
        </Element>
      </EdgeLabelRenderer>
    </Fragment>
  )
}

const sectionShape = shape({
  x: number.isRequired,
  y: number.isRequired,
})

ElkEdge.propTypes = {
  data: shape({
    pathList: arrayOf(string.isRequired).isRequired,
    sections: arrayOf(
      shape({
        endPoint: sectionShape.isRequired,
        id: string.isRequired,
        startPoint: sectionShape.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  highlightedPaths: arrayOf(string).isRequired,
  id: string.isRequired,
  source: string.isRequired,
  target: string.isRequired,
}

export default ElkEdge
