import React, { Fragment, useEffect, useState } from 'react'

import {
  defaultSelectComponents,
  Element,
  Fieldset,
  FormControl,
  Heading,
  helpers,
  JSONEditor,
  Label,
  reactSelectComponents,
  Select,
  Typography,
  theme,
  useFela,
} from '@fortressiq/fiq-ds'

import { useUpdateEffect } from 'react-use'

import { strategyOptions, paramsDetailsMap } from 'lib/SignatureStrategies'

import CheckboxStrategy from './fields/Checkbox'
import Number from './fields/Number'
import RadioGroup from './fields/RadioGroup'
import TextInput from './fields/TextInput'
import TextArea from './fields/TextArea'
import NGram from './nGram'

import { appHeadingCSS, highlightableCSS } from '../styles'
import { strategyParamsMap } from '../utilities'

const { addImportant, customScrollbarCSS } = helpers
const { Text } = Typography

const validateJson = value => {
  if (value) {
    try {
      JSON.parse(value)
      return true
    } catch (e) {
      return false
    }
  }
  return true
}
const sm = theme['default-spacer-sm']

const Form = ({
  additionalFields,
  applicationId,
  currentProvisionalStrategyName,
  errors,
  findingWords,
  id,
  ngramProps,
  provisionalStrategy,
  provisionalStrategyParams,
  register,
  select,
  selectChange,
  selectOptions,
  setFindingWords,
  setValue,
  single,
  trigger,
  unregister,
  getValues,
}) => {
  const { css } = useFela()

  const [options, setOptions] = useState([])

  const hasOptions = options && !!options.length

  useUpdateEffect(() => {
    // unregister fields when options changes
    if (options) {
      const existingParams = Object.keys(getValues(`provisionalStrategy[${id}].params`))
      const newParams = [...options.map(value => value.value), 'name', 'customParams']
      const paramsToRemove = existingParams?.filter(value => !newParams.includes(value))
      paramsToRemove.forEach(param => unregister(`provisionalStrategy[${id}].params.${param}`))
    }
  }, [options])

  const defaultValues = getValues(`provisionalStrategy[${id}].params`)

  useEffect(() => {
    setOptions(strategyParamsMap[currentProvisionalStrategyName])
  }, [currentProvisionalStrategyName])

  const handleChange = (name, value) => {
    setValue(name, value)
    trigger(name)
  }

  const selectProps = {
    className: css(highlightableCSS),
    components: {
      ...defaultSelectComponents,
      Option: props => {
        const {
          data: { description, label },
        } = props

        return (
          <reactSelectComponents.Option {...props} className={css({ cursor: addImportant('pointer') })}>
            <strong className={css({ display: 'block', fontWeight: 600 })}>{label}</strong>
            <Text preset='small'>{description}</Text>
          </reactSelectComponents.Option>
        )
      },
    },
    defaultValue: () => {
      let defaultValue
      strategyOptions.map(o => {
        if (o.value === provisionalStrategy) {
          defaultValue = { label: o.label, value: o.value }
        }
        return null
      })

      return defaultValue
    },
    options: selectOptions,
    placeholder: 'Select a Provisional Strategy',
    ...select,
    menuPlacement: 'bottom',
    onChange: ({ value }) => selectChange(value),
  }

  const jsonEditorChangeHandler = ({ updated_src: updatedSrc }) =>
    setValue(`provisionalStrategy.${id}.params.customParams`, updatedSrc)
  const jsonEditorConfig = {
    import: {
      overlayProps: {
        placement: {
          offset: [8, -16],
          points: ['br', 'tl'],
        },
        portalContainer: 'chainUIPortalContainer',
        zIndex: theme['z-overdrive'],
      },
    },
  }
  const jsonEditorStyle = {
    maxHeight: '120px',
    '& > .react-json-view': {
      backgroundColor: theme.white,
      marginBottom: theme['default-spacer-sm'],
      overflow: 'auto',
      ...customScrollbarCSS({ width: theme['default-spacer-xs'] }),
    },
  }

  const portalContainerCSS = {
    position: 'fixed',
    zIndex: theme['z-modal-above'],
    '&> #provisionalStrategy-jsonEditor-import-overlay': { minWidth: '300px' },
  }

  return (
    <Fragment>
      <Element
        id='chainUIPortalContainer'
        onClick={e => e.preventDefault()}
        style={portalContainerCSS}
        {...ngramProps}
      />
      <FormControl
        errors={errors && errors?.provisionalStrategy}
        helperText='Select a strategy to run for this application.'
        isNotInput={true}
      >
        <Heading level={4} style={appHeadingCSS}>
          Strategy
        </Heading>
        <Select {...selectProps} />
      </FormControl>
      {additionalFields}
      {provisionalStrategyParams && hasOptions && currentProvisionalStrategyName && (
        <Element
          margin={[theme['default-spacer-sm'], 0, 0]}
          style={
            findingWords && {
              overflow: 'auto',
              ...customScrollbarCSS({
                width: sm,
                scrollbarThumb: {
                  borderRadius: sm,
                },
              }),
            }
          }
        >
          <Fieldset
            legend='Provisional Strategy Params'
            legendStyle={{ fontSize: theme['font-size-base'] }}
            style={{
              background: theme[single ? 'field-bg-light' : 'white'],
              '&> .fiqds-checkbox-wrapper': { margin: theme['default-spacer-sm'] },
            }}
          >
            {defaultValues &&
              hasOptions &&
              options.map((option, i) => {
                const details = paramsDetailsMap[option.value]
                const key = `${i}-${details.label}`
                const inputName = `provisionalStrategy.${id}.params.${option.value}`
                const inputErrors = errors?.params?.[option.value]
                switch (details.type) {
                  case 'checkbox':
                    return (
                      <CheckboxStrategy
                        defaultValue={defaultValues[option.value] || 0}
                        handleChange={handleChange}
                        inputName={inputName}
                        key={key}
                        label={details.label}
                        setValue={setValue}
                        name={option.value}
                      />
                    )
                  case 'textarea':
                    return (
                      <TextArea
                        defaultValue={defaultValues[option.value]}
                        description={details.description}
                        errors={inputErrors}
                        handleChange={handleChange}
                        inputName={inputName}
                        key={key}
                        label={details.label}
                        name={option.value}
                        placeholder={details.placeholder}
                        register={register}
                        setValue={setValue}
                        unregister={unregister}
                        validate={value => validateJson(value) || 'Not valid JSON'}
                      />
                    )
                  case 'ngram':
                    return (
                      <Fragment key={key}>
                        <NGram
                          applicationId={applicationId}
                          handleChange={handleChange}
                          inputName={inputName}
                          label={details.label}
                          name={option.value}
                          ngramProps={ngramProps}
                          setFindingWords={setFindingWords}
                          single={single}
                        />
                        <TextArea
                          defaultValue={defaultValues[option.value]}
                          description={details.description}
                          errors={inputErrors}
                          handleChange={handleChange}
                          inputName={inputName}
                          label={null}
                          name={option.value}
                          placeholder={details.placeholder}
                          register={register}
                          setValue={setValue}
                          unregister={unregister}
                          validate={value => validateJson(value) || 'Not valid JSON'}
                        />
                      </Fragment>
                    )
                  case 'text':
                    return (
                      <TextInput
                        defaultValue={defaultValues[option.value]}
                        description={details.description}
                        errors={inputErrors}
                        handleChange={handleChange}
                        inputName={`provisionalStrategy.${id}.params.${option.value}`}
                        key={key}
                        label={details.label}
                        name={option.value}
                        placeholder={details.placeholder}
                        register={register}
                        rules={details && details.rules}
                        setValue={setValue}
                        unregister={unregister}
                        validate={value => validateJson(value) || 'Not valid JSON'}
                      />
                    )
                  case 'number':
                    return (
                      <Number
                        defaultValue={defaultValues[option.value]}
                        description={details.description}
                        errors={inputErrors}
                        handleChange={handleChange}
                        inputName={`provisionalStrategy.${id}.params.${option.value}`}
                        key={key}
                        label={details.label}
                        name={option.value}
                        placeholder={details.placeholder}
                        register={register}
                        rules={details && details.rules}
                        setValue={setValue}
                        type={details.type}
                        unregister={unregister}
                        title={details.title}
                      />
                    )
                  case 'radiogroup':
                    return (
                      <RadioGroup
                        boxes={details.boxes}
                        defaultValue={defaultValues[option.value]}
                        description={details.description}
                        errors={inputErrors}
                        handleChange={handleChange}
                        inputName={`provisionalStrategy.${id}.params.${option.value}`}
                        key={`${i}-${details.label}`}
                        label={details.label}
                        name={option.value}
                        placeholder={details.placeholder}
                        register={register}
                        rules={details && details.rules}
                        setValue={setValue}
                        type={details.type}
                        unregister={unregister}
                      />
                    )
                  default:
                    return null
                }
              })}
          </Fieldset>
          <JSONEditor
            config={jsonEditorConfig}
            collapsed={false}
            id='provisionalStrategy-jsonEditor'
            name='Custom Params'
            onAdd={jsonEditorChangeHandler}
            onDelete={jsonEditorChangeHandler}
            onEdit={jsonEditorChangeHandler}
            src={defaultValues?.customParams}
            style={jsonEditorStyle}
            title={<Label>Custom JSON</Label>}
          />
        </Element>
      )}
    </Fragment>
  )
}

export default Form
