import React from 'react'
import PropTypes from 'prop-types'

import { Menu, MenuItem, Popconfirm, useModal } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

import ActionMenu from 'components/TrinityTable/ActionMenu/ActionMenu'
import IntegrationFormModal from './IntegrationFormModal'

const ActionsMenu = ({ integration, handleReFetch }) => {
  const { addModal, removeModal } = useModal()

  const handleDelete = async () => {
    await api.delete(`/dynamic_integrations/${integration.id}`)
    handleReFetch()
  }

  const handleActivateToggle = async () => {
    const params = {
      is_active: !integration.isActive,
    }
    await api.put(`/dynamic_integrations/${integration.id}`, params)
    handleReFetch()
  }
  const modalID = 'edit-menu-item'

  const menu = (
    <Menu bordered={false} dark={true}>
      <MenuItem
        key={modalID}
        onClick={() =>
          addModal({
            children: (
              <IntegrationFormModal
                handleReFetch={handleReFetch}
                removeModal={() => removeModal(modalID)}
                editValues={integration}
                editId={integration.id}
              />
            ),
            header: 'Edit integration',
            footer: false,
            id: modalID,
            style: { width: '400px' },
          })
        }
      >
        Edit
      </MenuItem>
      <MenuItem key='toggle-active-menu-item' onClick={() => handleActivateToggle()}>
        {integration.isActive ? 'Deactivate' : 'Activate'}
      </MenuItem>
      <MenuItem key='delete-menu-item' onClick={event => event.stopPropagation()}>
        <Popconfirm
          cancelText='No'
          okText='Yes'
          onConfirm={handleDelete}
          placement='left-center'
          title='Are you sure you want to delete this integration?'
        >
          Delete
        </Popconfirm>
      </MenuItem>
    </Menu>
  )
  return <ActionMenu menu={menu} />
}

ActionsMenu.propTypes = {
  handleReFetch: PropTypes.func.isRequired,
  integration: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.bool,
  }).isRequired,
}

export default ActionsMenu
