import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import { Popconfirm, Menu, MenuItem, Typography, useModal, theme } from '@fortressiq/fiq-ds'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import ActionMenu from 'components/TrinityTable/ActionMenu/ActionMenu'
import ExportMenuItem from 'components/ExportButton/ExportMenuItem'
import callbackStore from 'stores/callback'

import api from 'lib/Api'

import uiStore from 'lib/UiStore'
import store from '../diagram_composer/store/DiagramComposerStore'

import OpportunitySubmittedDialogue from './OpportunitySubmittedDialogue'
import CoeForm from './CoeForm/CoeForm'

const opportunitySubmittedModalId = 'opportunity-submitted-modal'
const coeModalId = 'coe-form-modal'

const sharedModalConfig = {
  footer: false,
  config: { interactWithKeyboard: false, destroyOnClose: true },
}

const modalConfig = {
  ...sharedModalConfig,
  header: false,
  style: { height: 'auto', width: '300px' },
}

const coeModalConfig = {
  ...sharedModalConfig,
  header: 'Submit an Opportunity to CoE Manager',
  headerStyle: {
    padding: `${theme['default-spacer-md']} ${theme['default-spacer-md']} 12px ${theme['default-spacer-md']}`,
    alignItems: 'center',
  },
  maskClosable: false,
  style: {
    border: 'none',
    height: 'auto',
    maxHeight: '90vh',
    width: '700px',
    maxWidth: '95%',
    padding: 0,
    background: theme.white,
  },
}

const ActionsContainer = ({
  record: { pddUrl: pddUrlProp, flowchartId, id: specId, name: specName, opportunities },
  handleDeleteSpec,
}) => {
  const pddUrl = pddUrlProp || store.pddUrls[specId]
  const { coeIntakeUrl, coeUsername } = uiStore.tenant
  const { addModal, removeModal } = useModal()

  const handleExport = () => {
    ReactGA.event({
      category: 'PDD',
      action: 'Download',
      label: `Export PDD ${pddUrl} as .docx`,
    })
    return api.get(`/process_specs/${specId}`)
  }

  const exportCallback = data => {
    window.open(data.data.processSpec.pddUrl, '_blank')
  }

  const handleCreateOpportunityKeyWrapper = event => {
    if (event && event.keyCode !== 13) return
    handleCreateOpportunity()
  }

  const handleCreateOpportunity = () => {
    if (opportunities.length) {
      addModal({
        children: (
          <OpportunitySubmittedDialogue
            removeModal={() => removeModal(opportunitySubmittedModalId)}
            createOpportunity={createOpportunity}
          />
        ),
        id: opportunitySubmittedModalId,
        ...modalConfig,
      })
    } else createOpportunity()
  }

  const createOpportunity = async () => {
    removeModal(opportunitySubmittedModalId)
    const { data } = await api.post('/opportunities', { process_spec_id: specId })

    addModal({
      children: (
        <CoeForm specName={specName} autopilotUUID={data.request_uuid} removeModal={() => removeModal(coeModalId)} />
      ),
      id: coeModalId,
      ...coeModalConfig,
    })
  }

  const menu = (
    <Menu bordered={false} dark={true}>
      {pddUrlProp && (
        <ExportMenuItem
          itemText='Export PDD as .docx'
          customExportAjax={handleExport}
          exportName={specName}
          exportCallback={exportCallback}
          callbackStore={callbackStore}
        />
      )}
      {pddUrlProp && coeIntakeUrl && coeUsername && (
        <MenuItem>
          <div onClick={handleCreateOpportunity} onKeyDown={handleCreateOpportunityKeyWrapper}>
            Submit as Opportunity to CoE Manager
          </div>
        </MenuItem>
      )}
      <MenuItem>
        <Link to={{ pathname: `/diagrams/${flowchartId}`, search: `?specId=${specId}` }}>
          <Typography.Text>Open in Diagram Composer</Typography.Text>
        </Link>
      </MenuItem>
      <MenuItem onClick={event => event.stopPropagation()}>
        <Popconfirm placement='left-center' okText='Yes' onConfirm={() => handleDeleteSpec(specId)}>
          Delete
        </Popconfirm>
      </MenuItem>
    </Menu>
  )
  return <ActionMenu menu={menu} />
}

const ActionsContainerWrap = props => <ActionsContainer {...props} />

ActionsContainer.propTypes = {
  record: PropTypes.shape({
    pddUrl: PropTypes.string,
    flowchartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    opportunities: PropTypes.arrayOf(
      PropTypes.shape({
        completedAt: PropTypes.string,
      })
    ),
  }),
  handleDeleteSpec: PropTypes.func,
}

export default observer(ActionsContainerWrap)
