import React from 'react'
import PropTypes from 'prop-types'
import { Overlay } from '@fortressiq/fiq-ds'

import MiningRunForms from './MiningRunForms'

const MiningRunModal = ({
  showFormModal: showing,
  setShowFormModal: setShowing,
  fetchData,
  clonedMiningRun,
  setClonedMiningRun,
}) => {
  const placement = {
    overflow: { adjustX: true, adjustY: true },
    points: ['bl', 'tr'],
    targetOffset: [0, 4],
  }

  const trigger = {
    showing,
    setShowing,
    text: 'New',
    type: 'secondary',
  }

  const heights = {
    min: 'auto',
  }

  return (
    <Overlay
      basic={false}
      config={{ disableOutsideClick: true }}
      content={
        <MiningRunForms
          setShowing={setShowing}
          fetchData={fetchData}
          clonedMiningRun={clonedMiningRun}
          setClonedMiningRun={setClonedMiningRun}
        />
      }
      draggable={true}
      heights={heights}
      id='mining-form-modal'
      noPadding={true}
      placement={placement}
      trigger={trigger}
    />
  )
}

MiningRunModal.defaultProps = {
  clonedMiningRun: undefined,
}

MiningRunModal.propTypes = {
  clonedMiningRun: PropTypes.shape({}),
  fetchData: PropTypes.func.isRequired,
  setClonedMiningRun: PropTypes.func.isRequired,
  setShowFormModal: PropTypes.func.isRequired,
  showFormModal: PropTypes.bool.isRequired,
}

export default MiningRunModal
