import React from 'react'

import ControlsBar from '../ControlsBar'

jest.mock('../../stores/processExplorerStore', () => ({
  setGroupMode: jest.fn,
}))

jest.mock('@fortressiq/fiq-ds-themes/dist/js/graphColors.js', () => [
  'hsl(220.83,32.14%,56.08%)',
  'hsl(355,26.79%,56.08%)',
  'hsl(220.83,32.14%,56.08%)',
  'hsl(355,26.79%,56.08%)',
  'hsl(220.83,32.14%,56.08%)',
  'hsl(355,26.79%,56.08%)',
  'hsl(220.83,32.14%,56.08%)',
  'hsl(355,26.79%,56.08%)',
  'hsl(220.83,32.14%,56.08%)',
  'hsl(355,26.79%,56.08%)',
])

jest.mock('@fortressiq/fiq-ds', () => ({
  Header: ({ props }) => <header {...props} />,
  useFela: () => ({
    css: () => undefined,
  }),
  useModal: () => ({
    addModal: () => undefined,
    removeModal: () => undefined,
  }),
  Typography: {
    Paragraph: ({ props }) => <div {...props} />,
  },
  theme: {
    'z-base-above': 20,
  },
  helpers: {
    noop: () => {},
  },
}))

describe('ControlsBar component', () => {
  it('should render without throwing an error', () => {
    const component = global.shallowWithTheme(
      <ControlsBar
        diagramData={{}}
        currentUser={{}}
        flowData={{}}
        showSaveModal={jest.fn}
        allProcesses={{}}
        graphId={{}}
      />
    )
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
})
