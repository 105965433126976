import React, { useState } from 'react'
import { Overlay } from '@fortressiq/fiq-ds'

import WebApplicationForm from './components/WebApplicationForm'

const CreateWebApplicationModal = ({ applications, dispatch }) => {
  const [showing, setShowing] = useState(false)
  const placement = {
    overflow: { adjustX: true, adjustY: true },
    points: ['br', 'tr'],
    targetOffset: [0, 4],
    useCssTransform: false,
  }

  const trigger = {
    showing,
    setShowing,
    text: 'Create Web Application',
    type: 'default',
  }

  const heights = {
    min: 350,
  }

  const onSubmit = () => {
    setShowing(false)
  }

  return (
    <Overlay
      basic={false}
      closeIcon={true}
      content={<WebApplicationForm applications={applications} onSubmit={onSubmit} dispatch={dispatch} />}
      draggable={true}
      heights={heights}
      id='create-web-application-modal'
      placement={placement}
      trigger={trigger}
    />
  )
}

export default CreateWebApplicationModal
