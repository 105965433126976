import React, { Fragment, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { ApplicationIcon, Element, Empty, get, Group, Tooltip, Typography, useFela } from '@fortressiq/fiq-ds'

import SidePanel from 'components/detailsSidePanel/SidePanel'
import StepsByApplication from 'components/detailsSidePanel/processOverview/StepsByApplication'
import PathStatistics from 'components/detailsSidePanel/pathStatistics/PathStatistics'
import Stats from 'components/detailsSidePanel/processOverview/Stats'
import UnredactedImageViewerWithOption from 'components/image/UnredactedImageViewerWithOption'

import StepStatistics from './details/StepStatistics'

import { empty, processOverview, section, shot } from './styles'

const { Text } = Typography

// A hooks based component wrapped with `timerData` will react to changes automatically
export default observer(({ butterflyProcessStore, applicationData, butterflyData, toggleHideDuration }) => {
  const [showSidePanel, setShowSidePanel] = useState(true)
  const eventLog = butterflyProcessStore.selectedLog
  const toggleSidePanel = () => {
    setShowSidePanel(!showSidePanel)
  }
  const { css } = useFela()

  const images = eventLog
    ? [
        {
          centered: true,
          float: 'none',
          fluid: true,
          src: eventLog.screenshotUrl,
          svgInfo: {
            box: eventLog.bounding_box || eventLog.boundingBox,
            x: eventLog.coordX || eventLog.coord_x,
            y: eventLog.coordY || eventLog.coord_y,
          },
        },
      ]
    : null

  useEffect(() => {
    butterflyProcessStore.clearSelectedLog()
  }, [butterflyData.id])

  return (
    <SidePanel
      defaultActiveKey={['process', 'paths', 'steps', 'details']}
      collapse={!showSidePanel}
      toggleCollapse={toggleSidePanel}
      panels={[
        {
          header: 'Step Detail',
          key: 'details',
          panel:
            eventLog && Object.keys(eventLog).length ? (
              <Fragment>
                <Group gutter={get.numericValues('spacer').sm} className={css(section)}>
                  <Tooltip title={`Application: ${butterflyProcessStore.selectedAppName}`}>
                    <ApplicationIcon
                      src={butterflyProcessStore.selectedAppUrl}
                      name={butterflyProcessStore.selectedAppName}
                      textLength={2}
                    />
                  </Tooltip>
                  <Tooltip title={`Screen Signature: ${eventLog.screen_signature}`}>
                    <Text style={{ width: '300px' }} ellipsis={true}>
                      {eventLog.screen_signature || '--'}
                    </Text>
                  </Tooltip>
                </Group>
                <div className={css(shot)}>
                  <UnredactedImageViewerWithOption
                    clickImageToFullscreen={true}
                    dark={false}
                    images={images}
                    openFullscreenOnLoad={false}
                    selectedIndex={0}
                    fullscreenEnabled={true}
                  />
                </div>
              </Fragment>
            ) : (
              <Element style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Empty className={css(empty)} description='SHIFT-CLICK to Select a Step' />
              </Element>
            ),
        },
        {
          header: 'Process Overview',
          key: 'process',
          panel: (
            <div className={css(processOverview)}>
              <Stats
                steps={butterflyData.totalEvents}
                paths={butterflyData.flowCount}
                instances={butterflyProcessStore.stepsData.totalFrequency}
                averageDuration={butterflyData.averageDuration}
                totalDuration={butterflyData.totalDuration}
              />
              <StepsByApplication
                applications={applicationData.applications}
                total={applicationData.total}
                order={applicationData.order}
              />
            </div>
          ),
        },
        {
          header: 'Path Statistics',
          key: 'paths',
          panel: (
            <PathStatistics
              graphProcessId={butterflyProcessStore.butterflyGraphId}
              graphType='butterfly'
              paths={butterflyData.paths}
              selectedPath={butterflyProcessStore.selectedPath}
              setSelectedPath={(nodes, paths) => {
                butterflyProcessStore.setSelectedPath(nodes, paths)
              }}
              withColor={true}
              disablePathNameEdit={true}
              toggleHideDuration={toggleHideDuration}
            />
          ),
        },
        {
          header: 'Step Statistics',
          key: 'steps',
          panel: <StepStatistics />,
        },
      ]}
    />
  )
})
