//groups store -- to break up mobX stores
import * as d3 from 'd3'
import { action, observable, computed } from 'mobx'
import api from 'lib/Api'
import { graphColorNames } from '@fortressiq/fiq-ds'

class GroupsStore {
  @observable groupsMode = false //in or out of selection process

  @observable editGroupMode = false //in or out of group editing process

  @observable graphProcessId = null

  @observable groupList = [] // list of created groups and suggested groups

  @observable clicks = 0 // counting clicks for selection process

  @observable errorMessage = ''

  @observable activeGroup = {
    startNode: null,
    endNode: null,
    groupId: null,
    groupName: '',
    activeGroupNodeIds: [],
    baseColor: null,
  }

  @computed get isReadyForSave() {
    if (this.activeGroup.groupId) return true
    if (this.activeGroup.activeGroupNodeIds.length > 0 && this.activeGroup.groupName !== '') return true
    return false
  }

  @action
  setGraphProcessId = graphId => {
    this.graphProcessId = graphId
  }

  @action
  fetchGroups = async () => {
    if (!this.graphProcessId) return

    const { data } = await api.get('/graph_groups', { graph_process_id: this.graphProcessId })
    const groupList = data.graphGroups.map(group => ({
      ...group,
      visible: false,
      hideGroup: false,
    }))

    this.setGroupList(groupList)
  }

  @action
  highlightAll = () => {
    const groupedNodeIds = []

    this.groupList.forEach(group => {
      group.visible = true
      group.nodeIds.forEach(nodeId => groupedNodeIds.push(nodeId))
    })

    this.groupList.forEach(group => {
      const showGroup = !group.nodeIds.map(id => groupedNodeIds.includes(id)).includes(true)

      if (showGroup) {
        group.nodeIds.forEach(nodeId => groupedNodeIds.push(nodeId))
      }
    })

    this.groupList = [...this.groupList]
  }

  @action
  closeGroups = () => {
    this.groupsMode = false
    this.editGroupMode = false
    this.clearGroupHighlighting()
    this.errorMessage = ''
  }

  @action
  clearBtn = () => {
    this.groupList = this.groupList.filter(g => g.id !== this.activeGroup.groupId)
    this.errorMessage = ''
    this.clearGroupHighlighting()
    this.activeGroup.baseColor = null
    this.activeGroup.baseColorName = null
  }

  @action
  destroyBtn = async () => {
    await api.delete(`/graph_groups/${this.activeGroup.groupId}`)
    this.editGroupMode = false
    this.errorMessage = ''
    this.groupList = this.groupList.filter(group => group.id !== this.activeGroup.groupId)
    this.clearGroupHighlighting()
    this.fetchSuggestedGroups()
  }

  //clear group highlighting
  @action
  clearGroupHighlighting() {
    d3.selectAll('path.node').each(d => {
      d.inGroup = false // turn of group node highlights
    })
    this.clicks = 0
    this.activeGroup = {
      startNode: null,
      endNode: null,
      groupId: null,
      groupName: '',
      activeGroupNodeIds: [],
      baseColor: null,
      baseColorName: null,
    }
  }

  @action
  saveGroup = async () => {
    api
      .post('/graph_groups', {
        name: this.activeGroup.groupName,
        node_ids: this.activeGroup.activeGroupNodeIds,
        base_color: this.activeGroup.baseColor,
        graph_process_id: this.graphProcessId,
      })
      .then(response => {
        const { data } = response
        this.updateGroupList(data)
      })
      .catch(error => {
        this.errorMessage = error.response.data.error
      })
  }

  @action
  updateGroupList = data => {
    data.graphGroup = { ...data.graphGroup, visible: true, hideGroup: false }

    this.groupList = [...this.groupList, data.graphGroup]

    this.setGroupColors()

    this.clearGroupHighlighting()
    this.groupsMode = false
    this.editGroupMode = false
    this.errorMessage = ''
  }

  updateGroup = async callback => {
    await api.put(`/graph_groups/${this.activeGroup.groupId}`, {
      name: this.activeGroup.groupName,
      group_name: this.activeGroup.groupName,
      base_color: this.activeGroup.baseColor,
    })
    this.updateGroupAction()
    callback()
  }

  @action
  updateGroupAction = () => {
    const group = this.groupList.find(g => g.id === this.activeGroup.groupId)
    group.name = this.activeGroup.groupName
    group.baseColor = this.activeGroup.baseColor
    group.baseColorName = this.activeGroup.baseColorName
    this.groupList = [...this.groupList]
    this.setGroupColors()
  }

  @action
  handleNodeDrop() {
    const { startNode, endNode } = this.activeGroup
    let startFoundInGroup = null
    let endFoundInGroup = null

    if (!startNode || !endNode) return

    if (startNode) {
      startFoundInGroup = this.groupList.find(group => group.nodeIds.includes(startNode.data.id))
    }

    if (endNode) {
      endFoundInGroup = this.groupList.find(group => group.nodeIds.includes(endNode.data.id))
    }

    if (startFoundInGroup || endFoundInGroup) {
      this.setError('Groups cannot overlap.')
      this.clearGroupHighlighting()
      return
    }

    startNode.inGroup = true
    endNode.inGroup = true

    if (endNode.data.depth < startNode.data.depth) {
      this.setError('Start node must come before end node.')
      return
    }
    if (this.searchForConditionals(startNode, endNode)) {
      this.setError('Groups cannot contain decision points.')
      this.clearGroupHighlighting()
      return
    }
    this.setError('')
    this.setGroupFlag(startNode, endNode)
  }

  @action
  changeActiveGroupName = e => {
    this.activeGroup = { ...this.activeGroup, groupName: e.target.value }
  }

  @action
  changeActiveGroupColor = ({ label, value }) => {
    this.activeGroup = { ...this.activeGroup, baseColor: value, baseColorName: label }
  }

  @action
  setGroupFlag(startNode, endNode) {
    if (endNode.data.id === startNode.parent.id || !endNode) {
      return
    }
    endNode.inGroup = true
    this.activeGroup.activeGroupNodeIds.push(endNode.data.id)
    if (endNode.parent) this.setGroupFlag(startNode, endNode.parent)
  }

  //check to see if path contains conditionals
  @action
  searchForConditionals(startNode, endNode) {
    if (!startNode) return false
    while (endNode !== startNode && endNode) {
      if (endNode.children && endNode.children.length > 1) return true
      if (endNode.parent && endNode.parent.children && endNode.parent.children.length > 1) return true
      endNode = endNode.parent
    }
    return false
  }

  //highlight group on graph
  @action
  toggleGroup(groupId) {
    const group = this.groupList.find(g => g.id === groupId)
    group.visible = !group.visible
    this.groupList = [...this.groupList]
  }

  @action
  setError(msg) {
    this.errorMessage = msg
  }

  @action
  setGroupList = groupList => {
    this.groupList = [...groupList]
    this.setGroupColors()
  }

  //needs to handle expanding parts of groups
  //expanding mutliple groups amoungst lots of nodes
  @action
  unhideGroups(nodes) {
    //don't want to update if not needed
    //will infinte loop if conditional not there too!
    //(this will always update due to doing a copy of array unless we flag changes)
    let changed = false
    //O(n) basically going throguh each group and their list of nodes, which never overlap
    this.groupList.forEach(group => {
      let foundGroup = true
      group.nodeIds.forEach(nodeId => {
        if (!nodes[nodeId]) foundGroup = false
      })
      if (foundGroup) {
        if (group.hideGroup === true) changed = true
        group.hideGroup = false
      }
    })

    if (changed) this.groupList = [...this.groupList]
  }

  @action
  hideGroups(nodes) {
    let changed = false
    this.groupList.forEach(group => {
      let foundGroup = true
      group.nodeIds.forEach(nodeId => {
        if (!nodes[nodeId]) foundGroup = false
      })
      if (!foundGroup) {
        if (group.hideGroup === false) changed = true
        group.hideGroup = true
      }
    })
    if (changed) this.groupList = [...this.groupList]
  }

  setGroupColors() {
    this.groupList.forEach(group => {
      if (group.baseColor) {
        const color = graphColorNames.find(graphColor => graphColor.hex === group.baseColor)
        if (color) {
          group.titleColor = `${group.baseColor}`
          group.color = `${color.light}`
          group.baseColorName = color.name
          return
        }
      }
      group.titleColor = this.getColorFromString(group.name, graphColorNames, 'title')
      group.color = this.getColorFromString(group.name, graphColorNames, 'colors')
      group.baseColor = group.titleColor
      group.baseColorName = this.getColorFromString(group.name, graphColorNames, 'name')
    })
  }

  //creates a number hash from the first two characters of a string
  getColorFromString(string, colorMap, type) {
    let numberHash

    if (string.length > 1) {
      numberHash = Math.abs(string.charCodeAt(0) + string.charCodeAt(1))
    } else {
      numberHash = Math.abs(string.charCodeAt(0))
    }

    switch (type) {
      case 'title':
        return colorMap[numberHash % colorMap.length].hex
      case 'colors':
        return colorMap[numberHash % colorMap.length].light
      case 'name':
        return colorMap[numberHash % colorMap.length].name
      default:
        return colorMap[numberHash % colorMap.length].light
    }
  }
}

export default GroupsStore
