import { action, observable } from 'mobx'
import Flash from 'components/flash/Flash'

class SubprocessStore {
  @observable subprocessMode = false

  @observable startSelectionMode = false

  @observable endSelectionMode = false

  @observable startNode = null

  @observable endNodes = []

  @observable includedNodeIds = []

  @observable name = ''

  @observable errorMessage = ''

  @action
  setsubprocessMode(value) {
    this.subprocessMode = value
  }

  @action
  clear() {
    this.startNode = null
    this.endNodes = []
    this.includedNodeIds = []
    this.errorMessage = ''
  }

  @action
  clearSubprocess() {
    this.startNode = null
    this.endNodes = []
    this.includedNodeIds = []
  }

  @action
  removeEndNode(nodeId) {
    this.endNodes = this.endNodes.filter(({ id }) => id !== nodeId)
    this.updateIncluded()
  }

  @action
  removeStartNode() {
    this.startNode = null
    this.updateIncluded()
  }

  @action
  setActiveStartNode(node) {
    if (!node) return
    if (this.endNodes.find(({ id }) => id === node.id)) {
      Flash.warning({ description: `"${node.data.name}" is used as an End Node`, message: 'Warning' })
      return null
    }
    this.startNode = node.data
    if (this.checkIfValid()) {
      this.updateIncluded()
      this.errorMessage = ''
    } else {
      this.errorMessage = 'Invalid subprocess flow'
      this.clearSubprocess()
    }
  }

  @action
  setActiveEndNodes(node) {
    if (!node) return
    const index = this.endNodes.findIndex(endNode => endNode.id === node.data.id)
    if (index !== -1) return

    this.endNodes.push(node.data)
    if (this.checkIfValid()) {
      this.updateIncluded()
      this.errorMessage = ''
    } else {
      this.errorMessage = 'Invalid subprocess flow'
      this.clearSubprocess()
    }
  }

  //checks for valid subprocess paths
  // checks all flows each time, in the case that start node is dragged after end nodes
  checkIfValid() {
    if (!this.startNode) return true
    if (this.endNodes.length === 0) return true
    let valid = true

    this.endNodes.forEach(node => {
      if (node.visited_node_ids.indexOf(this.startNode.id) === -1) {
        valid = false
      }
    })
    return valid
  }

  updateIncluded() {
    if (this.startNode) {
      this.includedNodeIds = [this.startNode.id]
    } else return

    if (this.endNodes.length === 0) return

    this.includedNodeIds = this.endNodes.map(endNode => {
      const visitedNodeIds = endNode.visited_node_ids.toJS()
      return visitedNodeIds.slice(visitedNodeIds.indexOf(this.startNode.id))
    })
  }
}

export default new SubprocessStore()
