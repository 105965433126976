/* eslint-disable  import/prefer-default-export */
import { roleRouteCheck } from 'components/can/Can'

/* Checks if a user has access to view a url */
export const urlCheck = (viewUrl, roles) => {
  if (viewUrl.startsWith('/')) viewUrl = viewUrl.substring(1)
  if (viewUrl.endsWith('/')) viewUrl = viewUrl.substring(0, viewUrl.length - 2)

  //remove query string
  const q = viewUrl.indexOf('?')
  if (q >= 0) {
    viewUrl = viewUrl.substring(0, q)
  }

  const url = viewUrl.split('/')
  // replace any integers with :id or whatever it may be
  // refer to lib/rbac-rules file for common url paths
  const constructedUrl = `/${url
    .map((val, index) => {
      const i = parseInt(val, 10)
      if (isNaN(i)) return val
      if (url[0] === 'applications' && url.length > index + 1 && url[2] === 'screens' && index === 1)
        return ':applicationId'
      if (url[0] === 'applications' && url.length > index + 1 && url[2] === 'screens' && index === 3)
        return ':screenSignature'
      if (url[0] === 'events') return ':observerNames?'
      if (url[0] === 'process-boundary') return ':reportId'
      if (url[0] === 'processes' && url.length > index + 1 && url[index + 1] === 'state_actions' && index === 1)
        return ':processId'
      if (url[0] === 'processes' && url.length > index + 1 && url[index + 1] === 'state_actions' && index === 3)
        return ':state'
      if (url[0] === 'review') {
        if (index === 1 && url.length > 2) return ':observerID'
        if (index === 1 && url.length === 2) return ':observerID?'
        if (index === 2) return ':reviewID'
        if (index === 3) return ':eventAnnotationID'
      }
      if (url[0] === 'screenshot') return ':eventLogId'
      if (
        (url[0] === 'visualizations' && url[1] === 'butterfly') ||
        url[0] === 'process-explorer' ||
        url[0] === 'instances-viewer'
      )
        return ':id?'

      return ':id'
    })
    .join('/')}`

  return roleRouteCheck(constructedUrl, roles)
}
