import React, { useMemo } from 'react'
import { chroma, theme } from '@fortressiq/fiq-ds'
import PropTypes from 'prop-types'

import { getTimeElapsed } from 'lib/Time'

import {
  Charts,
  defaultChartConfigProps,
  FusionCharts,
  FusionTheme,
  ReactFC,
  zeroChartMarginPadding,
} from '../../../viz/index'

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme)

const PathsByDuration = ({ paths }) => {
  const pathData = useMemo(() => {
    const getPathData = data =>
      data.map(path => {
        const averageDuration = Math.round(path.average_duration)
        const timeStr = getTimeElapsed(averageDuration)

        return {
          label: path.name || path.key,
          value: averageDuration,
          valueDisplay: timeStr,
        }
      })

    return getPathData(paths)
  }, [paths])

  const dataSource = {
    chart: {
      ...zeroChartMarginPadding,
      paletteColors: chroma(theme['viz-bar-background-color']).hex(),
      theme: 'fusion',
    },
    data: pathData,
  }

  const chartConfig = {
    ...defaultChartConfigProps,
    height: 300,
    id: 'paths-by-duration-bar',
    type: 'bar2d',
  }

  return <ReactFC {...chartConfig} dataSource={dataSource} />
}

PathsByDuration.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      average_duration: PropTypes.number,
      name: PropTypes.string,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
}

export default PathsByDuration
