import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Element, Table, Label } from '@fortressiq/fiq-ds'

import CaptureButtons from '../subComponents/CaptureButtons'
import AppListInput from './AppListInput'
import { ADD_APPLICATION, REMOVE_APPLICATION, TOGGLE_APPLICATION_CAPTURE } from './Constants'
import { configureAppsContainerCSS, hrCSS, tableContainerCSS, transparentBG } from './styles'

const AppList = ({ processCaptureSettings, dispatch }) => {
  const handleAddList = values => {
    dispatch({ type: ADD_APPLICATION, values })
  }

  const onRemoveWindowsProcess = value => {
    dispatch({ type: REMOVE_APPLICATION, value })
  }

  const toggleCapture = (value, type, name) => {
    dispatch({ type: TOGGLE_APPLICATION_CAPTURE, name, captureType: type, value })
  }

  const columns = [
    {
      Header: 'Application Name',
      accessor: 'name',
      width: 320,
    },
    {
      Header: 'Toggle Capture',
      accessor: 'capture',
      disableSortBy: true,
      minWidth: 160,
      width: 160,
      align: 'right',
      Cell: ({
        row: {
          original: { name, screenshotAllowed, keystrokeAllowed, titleAllowed },
        },
      }) => (
        <CaptureButtons
          name={name}
          keystrokeAllowed={keystrokeAllowed}
          screenshotAllowed={screenshotAllowed}
          titleAllowed={titleAllowed}
          toggleCapture={toggleCapture}
        />
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      disableSortBy: true,
      minWidth: 35,
      width: 35,
      align: 'right',
      Cell: ({
        row: {
          original: { name },
        },
      }) => (
        <Button
          flat={true}
          icon='trash'
          noBorder={true}
          onClick={() => onRemoveWindowsProcess(name)}
          style={{ ...transparentBG, padding: 0, '&:hover': { ...transparentBG } }}
          tooltipProps={{
            title: 'Delete',
          }}
        />
      ),
    },
  ]

  return (
    <Fragment>
      <Element as='hr' style={hrCSS} />
      <Element style={configureAppsContainerCSS}>
        <Label>Configure Applications</Label>
        <AppListInput handleAddList={handleAddList} />
        <Element style={tableContainerCSS}>
          <Table
            columns={columns}
            data={processCaptureSettings}
            disablePagination={true}
            disableResizing={true}
            height={300}
            verticalBorders={false}
          />
        </Element>
      </Element>
    </Fragment>
  )
}

AppList.propTytpes = {
  processCaptureSettings: PropTypes.arrayOf(PropTypes.shape({})),
  dispatch: PropTypes.func.isRequired,
}

export default AppList
