import React from 'react'
import { Handle, Position } from 'reactflow'
import { theme } from '@fortressiq/fiq-ds'
import dfgColors from '../dfgColors'

const nodeStyle = {
  height: '50px',
  width: '50px',
  borderRadius: '100%',
  backgroundColor: dfgColors.endRed,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const stopStyles = {
  width: '15px',
  height: '15px',
  background: theme.white,
}

const EndNode = ({ frequency }) => {
  return (
    <React.Fragment>
      <Handle type='target' position={Position.Top} style={{ opacity: 0 }} />
      <div style={{ textAlign: 'center' }}>
        <div style={nodeStyle}>
          <div style={stopStyles} />
        </div>
        <div style={{ fontWeight: 700, marginTop: theme['default-spacer-sm'] }}>End</div>
        <div>{frequency}</div>
      </div>
      <Handle type='source' id='a' position={Position.Bottom} style={{ opacity: 0 }} />
      <Handle type='source' id='b' position={Position.Bottom} style={{ opacity: 0 }} />
    </React.Fragment>
  )
}

export default EndNode
