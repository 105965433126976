import React from 'react'
import { graphColors, Heading } from '@fortressiq/fiq-ds'

import css from './stepsByApplication.scss'
import ApplicationSegment from './ApplicationSegment'

const StepsByApplication = ({ order, applications, total }) => (
  <section className={css.container}>
    <Heading className={css.heading} level={4}>
      Steps by Application
    </Heading>
    <div className={css.slider}>
      {order.map((item, i) => (
        <ApplicationSegment
          color={graphColors[i % graphColors.length]}
          count={applications[item].count}
          item={item}
          total={total}
          icon={applications[item].icon}
          key={item}
        />
      ))}
    </div>
  </section>
)

export default StepsByApplication
