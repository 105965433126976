import React from 'react'
import PropTypes from 'prop-types'

import { Label, Element, Alert, theme, Typography } from '@fortressiq/fiq-ds'

const { Text } = Typography

const FieldWrapper = ({ align, children, error, helperText, htmlFor, isRequired, label, style: styleProp }) => {
  const style = {
    marginBottom: theme['default-spacer-md'],
    ...(align === 'right' && { display: 'flex', flexDirection: 'column', placeItems: 'flex-end' }),
    ...(styleProp || {}),
  }

  return (
    <Element style={style}>
      {label && (
        <Label htmlFor={htmlFor} isRequired={isRequired}>
          {label}
        </Label>
      )}
      {children}
      {!!error && (
        <Alert
          style={{ margin: `${theme['default-spacer-sm']} 0 0` }}
          icon='error'
          message={error?.message}
          role='alert'
          showIcon={true}
          type='error'
        />
      )}
      {helperText && (
        <Text style={{ fontSize: theme['font-size-sm'], display: 'block', marginTop: theme['default-spacer-xs'] }}>
          {helperText}
        </Text>
      )}
    </Element>
  )
}

FieldWrapper.defaultProps = {
  error: undefined,
  helperText: undefined,
  htmlFor: undefined,
  label: undefined,
  style: {},
}

FieldWrapper.propTypes = {
  error: PropTypes.shape(),
  helperText: PropTypes.string,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.shape(),
}

export default FieldWrapper
