/* eslint-disable no-console */
import React from 'react'
import { DirectUpload } from '@rails/activestorage'
import { Upload } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

const createGraphProcess = ({ id }) => api.post('/graph_processes/create_from_ids', { fileId: id })

const UploadsModal = ({ accept, onComplete }) => {
  const uploadFile = file => {
    const handleComplete = async response => {
      await createGraphProcess(response)
      onComplete()
    }

    const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')

    upload.create((error, response) => {
      if (error) {
        console.error(error)
      } else {
        handleComplete(response)
      }
    })
  }

  return (
    <Upload
      accept={accept}
      bordered={false}
      multiple={false}
      onDrop={e => uploadFile(e[0])}
      showDetails={false}
      showThumbnails={false}
      width='100%'
    />
  )
}

export default UploadsModal
