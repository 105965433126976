import React from 'react'
import classNames from 'classnames/bind'

import css from './controlsStyle.scss'

const cx = classNames.bind(css)

const Minimap = ({ isMinimapEnabled, eventGroupIndexes, getGroupSelectedMeta }) => {
  return (
    <div className={cx(css.minimap, { isMinimapEnabled })}>
      {eventGroupIndexes.map(eventGroupIndex => (
        <div
          className={cx(css.marker, getGroupSelectedMeta(eventGroupIndex))}
          key={`event-group-marker-${eventGroupIndex}`}
        />
      ))}
    </div>
  )
}

export default Minimap
