import { theme } from '@fortressiq/fiq-ds'

const isEnd = d => !!d.data && d.data.type === 'end'

export default ({ svg, links, maxPathWidth, maxWeight, duration, source, selectedPath, linkActions }) => {
  // Update the links...
  const link = svg.selectAll('path.link').data(links, d => d.id)

  // Enter any new links at the parent's previous position.
  const linkEnter = link
    .enter()
    .insert('path', 'g')
    .attr('id', d => `link${d.data.id}`)
    .attr('class', 'link')
    .style('fill', 'none')
    .style('stroke', theme['legacy-ant-border'])
    .style('stroke-width', d =>
      //get percent of largest value, and with that get percent of max path width, return 1 if 0 value
      Math.max(maxPathWidth * (d.data.value / maxWeight), 1)
    )
    .attr('d', () => {
      const o = { x: source.x0, y: source.y0 }
      return diagonal(o, o)
    })

  linkEnter.on('click', d => linkActions(d))

  // UPDATE
  const linkUpdate = linkEnter.merge(link)

  // Transition back to the parent element position
  linkUpdate
    .transition()
    .duration(duration)
    .attr('d', d => diagonal(d, d.parent))
    .style('stroke', d => {
      let selected = false
      if (
        selectedPath.nodes.indexOf(d.data.id) > -1 ||
        (d.data.type === 'decision' &&
          d.data.children.filter(child => selectedPath.nodes.indexOf(child.id) > -1).length > 0)
      ) {
        selected = true
      }
      // TODO: add these colors to a theme or some central location
      return !selected ? theme['legacy-ant-border'] : theme['contrast-blue']
    })

  // Remove any exiting links
  link
    .exit()
    .transition()
    .duration(duration)
    .attr('d', () => {
      const o = { x: source.x, y: source.y }
      return diagonal(o, o)
    })
    .remove()
}

// Creates a curved (diagonal) path from parent to the child nodes
const diagonal = (s, d) => {
  const extraLinkLength = isEnd(s) ? 25 : 50
  const path = `M ${s.y + extraLinkLength} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y + 20} ${d.x}`
  return path
}
