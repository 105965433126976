import React from 'react'

import { Button, combineRules, Element, Tooltip, Icon, Typography, useFela } from '@fortressiq/fiq-ds'

import DisabledScreenshot, { isDisabledScreenshot } from 'components/DisabledScreenshot/DisabledScreenshot'
import UnredactedImageWithOption from 'components/image/UnredactedImageWithOption'
import {
  eventDetailsCSS,
  eventDetailsListCSS,
  eventDetailsRowCSS,
  eventSlideCSS,
  eventSlideImageCSS,
  eventSlideImgWrapCSS,
  eventSlideUnredactedImageContainerCSS,
} from './styles'

const EventSlide = ({
  isLazy = true,
  slideIndex = 0,
  slideCount,
  openTimelineModal,
  event: { screenshotUrl, ...event },
}) => {
  const handleClick = () => openTimelineModal(event.id)
  const lazy = isLazy ? 'lazy' : 'eager'

  const { css } = useFela()

  return (
    <Element id={`event_${slideIndex}`} style={eventSlideCSS}>
      <Element style={eventSlideImgWrapCSS}>
        {isDisabledScreenshot(event) ? (
          <DisabledScreenshot containerHeight='calc(100% - 60px)' dark={true} />
        ) : (
          <UnredactedImageWithOption
            dark={true}
            loading={lazy}
            src={screenshotUrl}
            containerCss={eventSlideUnredactedImageContainerCSS}
            className={css(eventSlideImageCSS)}
          />
        )}
      </Element>

      <Element style={eventDetailsCSS}>
        <Element as='ul' style={combineRules(eventDetailsListCSS, eventDetailsRowCSS)}>
          <li>
            <Typography.Title level={4} style={({ theme: { white } }) => ({ color: white, textAlign: 'left' })}>
              Event {slideIndex + 1} of {slideCount}
            </Typography.Title>
          </li>
          <li>ID: {event.id}</li>
        </Element>
        <Element style={eventDetailsRowCSS}>
          <Tooltip title='View event in timeline'>
            <Button align='center' shape='circle' onClick={handleClick}>
              <Icon icon='syncarrowclock' color='currentColor' />
            </Button>
          </Tooltip>
        </Element>
        <Element as='ul' style={combineRules(eventDetailsListCSS, eventDetailsRowCSS)}>
          <li>Observer: {event.observer || event.observerName}</li>
          <li>Observed At: {new Date(event.observedAt).toLocaleString('en-us')}</li>
        </Element>
      </Element>
    </Element>
  )
}

export default EventSlide
