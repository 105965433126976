/* eslint-disable prefer-destructuring */
import React from 'react'
import { DateTimePickerRange, useController } from '@fortressiq/fiq-ds'
import { bool, shape, string } from 'prop-types'

const ControlledRangePicker = ({ control, defaultValue, id, name, rules, style, ...DatePickerRangeprops }) => {
  const {
    field: { onChange, value, onBlur },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  const rangePickerCSS = {
    display: 'block',
    width: '100%',
    ...style,
  }

  return (
    <DateTimePickerRange
      bordered={true}
      name={name}
      onBlur={onBlur}
      style={rangePickerCSS}
      id={id}
      onChange={onChange}
      placeholder='Select a Date range...'
      showTime={false}
      withPortal={true}
      value={value || []}
      {...DatePickerRangeprops}
    />
  )
}

ControlledRangePicker.defaultProps = {
  rules: undefined,
  defaultValue: undefined,
}

ControlledRangePicker.propTypes = {
  control: shape().isRequired,
  id: string.isRequired,
  name: string.isRequired,
  defaultValue: bool,
  rules: shape(),
}

export default ControlledRangePicker
