import React, { Fragment } from 'react'
import { FormControl, Label, Radio, RadioGroup as DSRadioGroup } from '@fortressiq/fiq-ds'

import { useDefaultFieldValue, useRegisterField } from './hooks'

import { inputStyle } from '../../styles'

const RadioGroup = ({
  defaultValue,
  description,
  errors,
  handleChange,
  inputName,
  label,
  name,
  noMargin,
  register,
  setValue,
  unregister,
  validate,
  boxes,
}) => {
  const registerInput = useRegisterField(inputName, register, unregister, validate)
  useDefaultFieldValue(defaultValue, setValue, inputName, name)

  const onChangeHandler = ({ selectedValue }) => {
    handleChange(registerInput.name, selectedValue)
  }
  if (!registerInput) return null

  return (
    <FormControl errors={errors} helperText={description} style={noMargin && { margin: 0 }}>
      <Fragment>
        <Label
          ID={`application-radiogroup-${inputName}`}
          style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}
        >
          {label}
        </Label>
        <DSRadioGroup
          button={true}
          label={label}
          name={registerInput.name}
          onChange={onChangeHandler}
          selectedValue={defaultValue || boxes[0].value}
          id={`application-radiogroup-${inputName}`}
          ref={registerInput.ref}
          style={inputStyle}
        >
          {boxes.map(box => (
            <Radio key={box.value} value={box.value}>
              {box.label}
            </Radio>
          ))}
        </DSRadioGroup>
      </Fragment>
    </FormControl>
  )
}

export default RadioGroup
