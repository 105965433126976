import React from 'react'
import { number } from 'prop-types'
import { Table, ApplicationIcon, Tooltip, AdvancedCell } from '@fortressiq/fiq-ds'

import { useFetch } from 'lib/hooks'

const TopSignaturesTable = ({ graphProcessId }) => {
  const { data, isLoading } = useFetch(`/graph_processes/${graphProcessId}/top_signatures`, null, [graphProcessId])

  const columns = [
    {
      Header: '',
      accessor: 'displayName',
      width: 30,
      minWidth: 30,
      Cell: ({ value }) => (
        <Tooltip title={value}>
          <ApplicationIcon name={value} size={22} />
        </Tooltip>
      ),
    },
    {
      Header: '',
      accessor: 'screenSignature',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
  ]

  return (
    <Table
      columns={columns}
      data={data?.topSignatures || []}
      disablePagination={true}
      hideHeader={true}
      loading={isLoading}
      striped={true}
      verticalBorders={false}
    />
  )
}

TopSignaturesTable.propTypes = {
  graphProcessId: number.isRequired,
}

export default TopSignaturesTable
