import * as d3 from 'd3'
import store from '../../stores/processExplorerStore'

export default groupsSvg => {
  const groupCanvas = document.getElementById('groupCanvas')

  if (!groupCanvas) return

  groupCanvas.innerHTML = ''

  store.groups.groupList
    .filter(({ visible, hideGroup }) => visible && !hideGroup)
    .forEach(group => {
      renderGroupSVG(group, groupsSvg)
    })
}

function wrap() {
  const self = d3.select(this)
  let textLength = self.node().getComputedTextLength()
  let text = self.text()
  while (textLength > self.attr('width') && text.length > 0) {
    text = text.slice(0, -1)
    self.text(`${text}...`)
    textLength = self.node().getComputedTextLength()
  }
}

const renderGroupSVG = (group, groupsSvg) => {
  let right = 0
  let height = 0
  let top = 0
  const topPadding = 30
  const rightPadding = 20
  const leftPadding = 10
  const nodeWidthApprox = store.baseNodeWidth
  const normalHeight = store.baseNodeHeight
  const collapsedHeight = 150

  // initialize the left coordinate
  const leftId = group.nodeIds[0]
  const leftEle = d3.select(`.nodeId_${leftId}`)
  if (leftEle.empty()) return
  const leftData = leftEle.datum()
  let left = leftData.y - leftPadding

  // calculate group highlight coordinates to get group dimensions
  // must get each node in group-- different node types have different dimensions
  group.nodeIds.forEach(id => {
    const nodeEle = d3.select(`.nodeId_${id}`)
    if (nodeEle.empty()) return
    const nodeData = nodeEle.datum()
    right = Math.max(right, nodeData.y + nodeWidthApprox + rightPadding)
    height = Math.max(height, nodeData.data.activities.length > 1 ? collapsedHeight : normalHeight)
    left = Math.min(left, nodeData.y - leftPadding)
    top = nodeData.x - topPadding * 2
  })

  const groupG = groupsSvg
    .append('g')
    .attr('transform', `translate(${left}, ${top})`)
    .attr('class', `group_${group.id}`)

  groupG
    .append('rect')
    .style('fill', group.color)
    .attr('ry', 10)
    .attr('rx', 10)
    .attr('width', right - left)
    .attr('height', height + 60)

  groupG
    .append('rect')
    .style('fill', group.titleColor)
    .attr('ry', 1)
    .attr('rx', 1)
    .attr('width', right - left)
    .attr('height', 25)
    .on('click', () => {
      store.EditGroup(group)
    })

  groupG
    .append('text')
    .style('fill', '#fff')
    .style('font-size', '12')
    .style('font-weight', '600')
    .attr('width', right - left - 10)
    .attr('x', 10)
    .attr('y', 16)
    .text(group.name.toUpperCase() || '--')
    .on('click', () => {
      store.EditGroup(group)
    })
    .each(wrap)
    .append('title')
    .text(group.name.toUpperCase() || '--')
}
