// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ControlsBar GroupsControl component should render without throwing an error 1`] = `
<Dropdown
  id="process-explorer-groups"
  layer={
    {
      "placement": "bottom-start",
    }
  }
  overlay={
    <Menu
      className={{}}
    >
      <MenuItem>
        Highlight All
      </MenuItem>
    </Menu>
  }
  trigger={
    {
      "type": "click",
    }
  }
>
  <div
    className={{}}
  >
    Display Group(s): 
    None
  </div>
</Dropdown>
`;
