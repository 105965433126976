import React from 'react'
import { FormControl, InputNumber } from '@fortressiq/fiq-ds'

import { useDefaultFieldValue, useRegisterField } from './hooks'

import { inputStyle } from '../../styles'

const Number = ({
  defaultValue,
  description,
  errors,
  handleChange,
  inputName,
  label,
  name,
  noMargin,
  placeholder,
  register,
  rules,
  setValue,
  unregister,
  validate,
  ...inputProps
}) => {
  const registerInput = useRegisterField(inputName, register, unregister, validate)
  useDefaultFieldValue(defaultValue, setValue, inputName, name)

  if (!registerInput) return null

  return (
    <FormControl errors={errors} helperText={description} style={noMargin && { margin: 0 }}>
      <InputNumber
        {...inputProps}
        value={defaultValue}
        id={`application-inputnumber-${inputName}`}
        label={label}
        max={rules && rules.max}
        min={rules && rules.min}
        name={registerInput.name}
        onBlur={registerInput.onBlur}
        setNum={newVal => handleChange(registerInput.name, newVal)}
        placeholder={placeholder || label}
        ref={registerInput.ref}
        step={rules && rules.step}
        style={inputStyle}
      />
    </FormControl>
  )
}

export default Number
