// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`BoundingBox component should render without throwing an error 1`] = `
<div
  className="boundingBox"
  onClick={[Function]}
  onMouseEnter={[Function]}
  onMouseLeave={[Function]}
  style={
    {
      "height": 64.6142870914875,
      "left": 115.42364672707993,
      "top": 61.36231803721199,
      "width": 78.89744529065842,
    }
  }
>
  <div
    className="boundingBoxControl tl"
    data-direction="tl"
  />
  <div
    className="boundingBoxControl tr"
    data-direction="tr"
  />
  <div
    className="boundingBoxControl bl"
    data-direction="bl"
  />
  <div
    className="boundingBoxControl br"
    data-direction="br"
  />
  <div
    className="boundingBoxMove"
    data-direction="move"
  />
  <div
    className="boundingBoxDeleteTrigger"
    onClick={[Function]}
  >
    <Icon
      fill="white"
      icon="colorx"
      size={16}
    />
  </div>
</div>
`;
