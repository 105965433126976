import React from 'react'
import PropTypes from 'prop-types'
import { useFela } from '@fortressiq/fiq-ds'

import EventGroup from './EventGroup'
import InactiveEventGroup from './InactiveEventGroup'

import { screenshotRow } from './styles'

const EventGroupRow = ({
  eventCount,
  eventGroupIndex,
  events,
  isActiveGroup,
  isAdjacentToActiveGroup,
  isSelected,
  toggleSelectedRow,
  cycleId,
}) => {
  const [exemplarEvent] = events
  const { css } = useFela({ isSelected })

  return (
    <div className={css(screenshotRow)}>
      {isActiveGroup ? (
        <EventGroup
          {...{
            eventCount,
            eventGroupIndex,
            events,
            isActiveGroup,
            toggleSelectedRow: () => toggleSelectedRow(eventGroupIndex),
            cycleId: cycleId,
          }}
        />
      ) : (
        <InactiveEventGroup exemplarEvent={exemplarEvent} eventCount={eventCount} isLazy={!isAdjacentToActiveGroup} />
      )}
    </div>
  )
}

EventGroupRow.propTypes = {
  cycleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default EventGroupRow
