import React from 'react'

import localizer from 'lib/Localizer'

import css from './metadataTableStyle.scss'

const MetadataTable = React.memo(({ machine, user, firstSeenAt, lastSeenAt }) => (
  <React.Fragment>
    <div className={css.headers}>
      <div className={css.machine}>Machine ID</div>
      <div className={css.users}>User</div>
      <div className={css.created}>Date Connected</div>
      <div className={css.lastSeen}>Last Dectected</div>
    </div>
    <Row machine={machine} user={user} firstSeenAt={firstSeenAt} lastSeenAt={lastSeenAt} />
  </React.Fragment>
))

const Row = React.memo(({ machine, user, firstSeenAt, lastSeenAt }) => (
  <div className={css.row}>
    <div className={css.machine}>{machine || '--'}</div>
    <div className={css.users}>{user || '--'}</div>
    <div className={css.created}>{localizer.asDate(firstSeenAt) || '--'}</div>
    <div className={css.lastSeen}>{localizer.asDate(lastSeenAt) || '--'}</div>
  </div>
))

export default MetadataTable
