import React from 'react'

import ExportButton from 'components/ExportButton/ExportButton'
import callbackStore from 'stores/callback'

const ExportProcess = ({ graphId, name }) => {
  const options = [
    {
      text: 'Export Process (.csv)',
      exportArgs: { graphId, exportType: 'flowgraph_process', graphType: 'flowgraph' },
      disabled: false,
    },
  ]

  return (
    <ExportButton
      buttonText='Export Process'
      callbackStore={callbackStore}
      defaultExportName={name}
      exportType='graph'
      options={options}
    />
  )
}

export default ExportProcess
