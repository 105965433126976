import { getShape } from '../utils'
import store from '../../stores/processExplorerStore'

export default ({ nodeEnter, nodeUpdate, nodeHeight, collapsedHeight, iconWidth, nodeColor, subprocessMode }) => {
  const { nodeWidth } = store

  nodeEnter
    .append('path')
    .attr('cursor', 'pointer')
    .attr('class', d => `group ${d.inGroup && store.groups.groupsMode ? 'in' : ''}`)
    .attr('d', d => getShape(d, nodeHeight, collapsedHeight, nodeWidth, iconWidth))
    .style('stroke', d => (d.inSubProc && subprocessMode ? nodeColor : 'transparent'))
    .style('fill-opacity', 0.2)
    .style('fill', d => {
      return d.inGroup && store.groups.groupsMode ? '#99ffff' : 'transparent'
    })
    .style('display', d => {
      if (d.data.type === 'start') return 'none'
      return 'block'
    })

  nodeUpdate
    .selectAll('path.group')
    .attr('class', d => `group ${d.inGroup && store.groups.groupsMode ? 'in' : ''}`)
    .style('stroke', d => {
      return d.inGroup && store.groups.groupsMode ? '#99ffff' : 'transparent'
    })
    .style('fill', d => {
      return d.inGroup && store.groups.groupsMode ? '#99ffff' : 'transparent'
    })
}
