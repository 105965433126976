//APIs
// for now, to keep apis clean (not depenedent on front end it serves), we'll have two versions
// the old apis for graph process that dont have json keys that mirror columns and the new (flow graph) which do
import { toAlpha, toHash } from 'lib/String'
import { MAX_NUMBER_CASES } from './Constants'

export const APIS = {
  flowgraph: {
    instances: processId => `/df_graph/instances/${processId}`,
    paths: processId => `/df_graph/path_list/${processId}`,
  },
  butterfly: {
    instances: () => '/screenshot_annotation_events',
    paths: graphProcessId => `/process_explorer/${graphProcessId}`,
  },
  path: {
    instances: () => '/screenshot_annotation_events',
    paths: graphProcessId => `/process_explorer/${graphProcessId}`,
  },
  // we lack consisyency at the moment, path can sometimes be 'graoh_process'
  graph_process: {
    instances: () => '/screenshot_annotation_events',
    paths: graphProcessId => `/process_explorer/${graphProcessId}`,
  },
}

export const getFlowInstances = data => {
  const machineNames = new Set()
  const newInstances = data.map((instance, index) => {
    machineNames.add(instance.observer)
    return {
      ...instance,
      index: index,
      disabled: instance.eventIds.length > MAX_NUMBER_CASES,
      hash: toHash(instance.eventIds.join(',')),
    }
  })

  return {
    machineNames,
    newInstances,
  }
}

export const getGraphInstances = data => {
  const machineNames = new Set()
  const newInstances = data.instances.map((instance, index) => {
    machineNames.add(instance.observer)
    return {
      ...instance,
      index: index,
      disabled: instance.eventIds.length > MAX_NUMBER_CASES,
      hash: toHash(instance.eventIds.join(',')),
    }
  })

  return {
    machineNames,
    newInstances,
  }
}

export const getFlowPaths = data => {
  const paths = data.paths
    .map((path, index) => ({
      ...path,
      id: path.path_id,
      key: toAlpha(index, false),
      name: path.path_label,
      selected: true,
    }))
    .sort((a, b) => b.stats.frequency - a.stats.frequency)

  return paths
}

export const getGraphPaths = data => {
  const paths = data.flow_data
    .map((path, index) => ({
      ...path,
      key: toAlpha(index, false),
      selected: true,
    }))
    .sort((a, b) => b.frequency - a.frequency)

  return paths
}
