import React from 'react'
import { AdvancedCell, DateCell } from '@fortressiq/fiq-ds'

import Actions from './subComponents/Actions'

export const sortKeyMap = {
  id: 'members.id',
  username: 'username',
  firstName: 'members.first_name',
  lastName: 'members.last_name',
  email: 'email',
  roleNames: 'roles.name',
  lastSignInAt: 'last_sign_in_at',
  signInCount: 'sign_in_count',
  confirmedAt: 'confirmed_at',
  createdAt: 'created_at',
  active: 'active',
}

export const modalConfig = {
  footer: false,
  style: { height: 'auto', maxHeight: '650px', width: '600px' },
  config: { interactWithKeyboard: false, destroyOnClose: true },
  maskClosable: false,
}

export const userModalId = 'user-form-modal'
export const kibanaLoginModalId = 'kibana-password-form-modal'

export const sharedColProps = {
  date: { Cell: ({ value }) => <DateCell timestamp={value} /> },
  text: { Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell> },
}

export const actionsCellContent =
  (handleDeleteUser, addUserModal, addKibanaPasswordModal, resetPassword, toggleActiveUser) =>
  ({ row: { original } }) => (
    <Actions
      user={original}
      handleDeleteUser={handleDeleteUser}
      addUserModal={addUserModal}
      addKibanaPasswordModal={addKibanaPasswordModal}
      resetPassword={resetPassword}
      toggleActiveUser={toggleActiveUser}
      modalConfig={modalConfig}
    />
  )
