import React from 'react'

import { Card } from '@fortressiq/fiq-ds'
import css from './metricContainerStyle.scss'

const MetricContainer = ({ onClose, children }) => (
  <Card className={css.metricContainer} style={{ margin: '7.5px' }}>
    {onClose && (
      <div className={css.close} onClick={onClose}>
        &times;
      </div>
    )}
    {children}
  </Card>
)

export default MetricContainer
