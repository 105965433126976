import React, { useMemo } from 'react'
import { chroma, theme } from '@fortressiq/fiq-ds'
import { arrayOf, bool, shape, string } from 'prop-types'

import {
  Charts,
  defaultChartConfigProps,
  defaultDataSourceChartProps,
  FusionCharts,
  FusionTheme,
  ReactFC,
} from '../../../viz/index'

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme)

const category = {}
const inProcessData = {}
const NotInProcessData = {}

const ProcessTime = ({ applications }) => {
  applications.forEach(({ cumulativeSum, displayName, inProcess, runningTotal }) => {
    if (!category[displayName]) {
      category[displayName] = { label: displayName }
      inProcessData[displayName] = { value: inProcess ? cumulativeSum : runningTotal - cumulativeSum }

      NotInProcessData[displayName] = { value: !inProcess ? cumulativeSum : runningTotal - cumulativeSum }
    }
  })

  const getData = d =>
    Object.values(d).map(inProc => ({
      value: Number(inProc?.value),
    }))

  const dataSet = useMemo(
    () => [
      {
        seriesname: 'In Process',
        data: getData(inProcessData),
      },
      { seriesname: 'Not In Process', data: getData(NotInProcessData) },
    ],
    []
  )

  const dataSource = useMemo(
    () => ({
      chart: {
        ...defaultDataSourceChartProps,
        chartTopMargin: '20',
        paletteColors: `${chroma(theme['success-color'])}, ${chroma(theme['error-color'])}`,
        showSum: '1',
        theme: 'fusion',
        useEllipsesWhenOverflow: '1',
        xAxisName: 'Applications',
        yAxisName: 'Events',
      },
      categories: [
        {
          category: Object.values(category).map(c => c),
        },
      ],
      dataset: dataSet,
    }),
    [dataSet]
  )

  const chartConfig = {
    ...defaultChartConfigProps,
    height: 300,
    id: 'process-appication-time',
    type: 'scrollstackedcolumn2d',
  }

  return <ReactFC {...chartConfig} dataSource={dataSource} />
}

ProcessTime.propTypes = {
  applications: arrayOf(
    shape({
      cumulativeSum: string,
      displayName: string,
      inProcess: bool,
      runningTotal: string,
    })
  ).isRequired,
}

export default ProcessTime
