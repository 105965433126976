import { theme } from '@fortressiq/fiq-ds'

export const processCardCSS = () => ({
  borderRadius: theme['border-radius-base'],
  display: 'inline-flex',
  margin: theme['default-spacer-sm'],
})

export const demiProcessCardCSS = () => ({
  flexGrow: '1',
  width: `calc(49% - ${theme['default-spacer-md']})`,
})

export const processCardsWrapperCSS = () => ({
  background: theme['contrast-background-color'],
  display: 'flex',
  flexGrow: '1 1',
  flexWrap: 'wrap',
  padding: theme['default-spacer-xs'],
  paddingRight: theme['default-spacer-sm'],
  position: 'relative',
  width: '100%',
})
