//Rectangle shape definition

export const Rectangle = rappid => {
  rappid.dia.Element.define(
    'standard.Rectangle',
    {
      attrs: {
        body: {
          refWidth: '100%',
          refHeight: '100%',
          strokeWidth: 1,
          stroke: '#8995AD',
          fill: '#DFE3EA',
        },
        label: {
          textVerticalAnchor: 'middle',
          textAnchor: 'middle',
          refX: '50%',
          refY: '50%',
          fontSize: 12,
          fill: '#333333',
        },
      },
    },
    {
      markup: [
        {
          tagName: 'rect',
          selector: 'body',
        },
        {
          tagName: 'text',
          selector: 'label',
        },
      ],
    }
  )
}

export default Rectangle
