import React from 'react'
import PropTypes from 'prop-types'
import Comment from 'components/Comment/Comment'
import { EditableLabel, AdvancedCell } from '@fortressiq/fiq-ds'

const EditableCell = ({ value, rowIndex, columnId, updateCellData, updateData, type }) => {
  const saveData = newValue => {
    updateData(newValue)
    updateCellData(rowIndex, columnId, newValue)
  }

  if (type === 'comment') {
    return <Comment saveComment={saveData} comments={value} />
  }

  return (
    <EditableLabel
      value={value}
      render={AdvancedCell}
      showEditIcon={false}
      onUpdate={({ target: { value: newValue } }) => saveData(newValue)}
    />
  )
}

EditableCell.defaultProps = {
  value: '',
  type: undefined,
}

EditableCell.propTypes = {
  value: PropTypes.string,
  rowIndex: PropTypes.number.isRequired,
  columnId: PropTypes.string.isRequired,
  updateCellData: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  type: PropTypes.string,
}

export default EditableCell
