import React from 'react'
import localizer from 'lib/Localizer'
import { Metric } from 'components/PanelMetrics/PanelMetrics'
import DisabledScreenshot, { isDisabledScreenshot } from 'components/DisabledScreenshot/DisabledScreenshot'
import UnredactedImageViewerWithOption from 'components/image/UnredactedImageViewerWithOption'

import css from './eventDetailsStyle.scss'

const detailColumns = [
  [
    ['Event ID', 'id'],
    ['Observed At', 'observedAt'],
  ],
  [
    ['Signature', 'signature'],
    ['Screen Signature', 'screenSignature'],
  ],
]

const formatters = { observedAt: localizer.asPreciseTime }

const EventDetails = ({ event }) => {
  if (!event) return null
  const images = [
    {
      bordered: false,
      centered: true,
      circular: false,
      disabled: false,
      float: 'none',
      fluid: true,
      hidden: false,
      rounded: false,
      src: event.screenshotUrl,
      svgInfo: {
        box: event.bounding_box || event.boundingBox,
        x: event.coordX || event.coord_x,
        y: event.coordY || event.coord_y,
      },
      spaced: false,
    },
  ]

  return (
    <div className={css.container}>
      <div className={css.detailsContainer}>
        <div className={css.heading}>
          {detailColumns.map((details, columnIndex) => (
            <ul key={`eventDetail-column-${columnIndex}`} className={css.eventDetailsColumn}>
              {details.map(([title, column]) => (
                <li className={css.eventDetail} key={`event-${column}-detail`}>
                  <Metric
                    metric={{ title, value: formatters[column] ? formatters[column](event[column]) : event[column] }}
                  />
                </li>
              ))}
            </ul>
          ))}
        </div>
        <div className={css.imageContainer}>
          {isDisabledScreenshot(event) ? (
            <DisabledScreenshot />
          ) : (
            <UnredactedImageViewerWithOption
              clickImageToFullscreen={true}
              dark={false}
              images={images}
              maxHeight='100%'
              openFullscreenOnLoad={false}
              selectedIndex={0}
              fullscreenEnabled={true}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EventDetails
