import React from 'react'
import PropTypes from 'prop-types'

import { Menu, MenuItem, Popconfirm, useModal } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

import ActionMenu from 'components/TrinityTable/ActionMenu/ActionMenu'

import DomainListForm from './DomainListForm'

const ActionsMenu = ({ domainList, handleReFetch, modalConfig, isInUse }) => {
  const { addModal, removeModal } = useModal()

  const handleDelete = async () => {
    await api.delete(`/allow_block_lists/${domainList.id}`)
    handleReFetch()
  }

  const cloneModalId = 'clone-domain-list-form'
  const editModalId = 'edit-domain-list-form'

  const menu = (
    <Menu bordered={false} dark={true}>
      <MenuItem
        key='edit-menu-item'
        onClick={() =>
          addModal({
            children: (
              <DomainListForm
                handleReFetch={handleReFetch}
                removeModal={() => removeModal(editModalId)}
                editValues={domainList}
                editId={domainList.id}
                isInUse={isInUse}
              />
            ),
            header: 'Edit list',
            id: editModalId,
            ...modalConfig,
          })
        }
      >
        Edit
      </MenuItem>
      <MenuItem
        key='clone-menu-item'
        onClick={() =>
          addModal({
            children: (
              <DomainListForm
                handleReFetch={handleReFetch}
                removeModal={() => removeModal(cloneModalId)}
                editValues={domainList}
              />
            ),
            header: 'Create list',
            id: cloneModalId,
            ...modalConfig,
          })
        }
      >
        Clone
      </MenuItem>
      {!isInUse && (
        <MenuItem key='delete-menu-item'>
          <Popconfirm
            cancelText='No'
            okText='Yes'
            onConfirm={() => {
              handleDelete()
            }}
            placement='left-center'
            title='Are you sure you want to delete this list?'
          >
            Delete
          </Popconfirm>
        </MenuItem>
      )}
    </Menu>
  )
  return <ActionMenu menu={menu} />
}

ActionsMenu.propTypes = {
  domainList: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    accessType: PropTypes.string,
  }).isRequired,
  handleReFetch: PropTypes.func.isRequired,
}

export default ActionsMenu
