import React from 'react'
import classNames from 'classnames/bind'
import { Col, Icon, Row, theme } from '@fortressiq/fiq-ds'

import css from './controlsStyle.scss'

const cx = classNames.bind(css)

const EventGroupNav = ({ eventGroupHeaderText, eventGroupCount, activeGroupIndex, onPrevGroup, onNextGroup }) => {
  const hasGroups = eventGroupCount > 1
  const hasPrevGroup = activeGroupIndex > 0
  const hasNextGroup = activeGroupIndex < eventGroupCount - 1
  return (
    <Row type='flex' justify={hasGroups ? 'space-between' : 'center'} align='middle' className={css.eventGroupNav}>
      {hasGroups && (
        <Col onClick={onPrevGroup}>
          <Icon
            clickable={hasPrevGroup}
            icon={Icon.keys.chevroncircledown}
            rotate={-180}
            size='medium'
            textColor={theme['darker-bg']}
            className={cx({ isDisabled: !hasPrevGroup })}
          />
        </Col>
      )}
      <Col>
        <span>{eventGroupHeaderText}</span>
      </Col>
      {hasGroups && (
        <Col onClick={onNextGroup}>
          <Icon
            clickable={true}
            icon={Icon.keys.chevroncircledown}
            size='medium'
            textColor={theme['darker-bg']}
            className={cx({ isDisabled: !hasNextGroup })}
          />
        </Col>
      )}
    </Row>
  )
}

export default EventGroupNav
