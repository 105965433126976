export const eventListContainerCSS = () => ({
  height: '100%',

  '> div:last-child': {
    borderWidth: 0,
  },
})

export const panelMetricsContainer = ({ theme }) => ({
  borderBottom: `1px solid ${theme['table-border-color']}`,
  height: '50px',
})

export const filtersContainer = () => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
})

export const tableContainer = () => ({
  height: 'calc(100% - 50px)', //the 50px is the metric on top of the table
})
