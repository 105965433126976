import React from 'react'
import { withRouter } from 'react-router-dom'
import { Icon, Button } from '@fortressiq/fiq-ds'
import { generateURLQueryfromRules } from 'components/query_builder/utils'

const RulesExploreButton = ({ application, history }) => {
  const rules = [
    {
      field: 'application_id',
      operator: '=',
      value: application.applicationId,
    },
  ]

  return (
    <Button
      onClick={e => {
        e.preventDefault()
        history.push(`/events?${generateURLQueryfromRules({ rules: rules })}`)
      }}
      size='small'
      tooltipProps={{ title: 'View all the browser events that match this rule.' }}
    >
      <Icon size='nested' icon='search' />
    </Button>
  )
}

export default withRouter(RulesExploreButton)
