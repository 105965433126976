import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Select, useController, theme } from '@fortressiq/fiq-ds'
import api from 'lib/Api'

const ControlledSelect = ({
  constructData,
  constructSelectedValue,
  control,
  dataKey,
  defaultValue,
  isDisabled,
  isLoading: controlledIsLoading,
  isMulti,
  name,
  onChangeCallback,
  options: controlledOptions,
  params,
  placeholder,
  portal,
  rules,
  style,
  styles,
  url,
  valueKey,
}) => {
  const [options, setOptions] = useState(url ? undefined : controlledOptions)
  const [isLoading, setIsLoading] = useState(url ? true : controlledIsLoading)
  const [selectValue, setSelectValue] = useState()

  const { current: currentParams } = useRef(params)

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true)
      const response = await api.get(url, currentParams)
      const data = response[dataKey]
      if (constructData) {
        setOptions(constructData(data))
      } else {
        setOptions(data.map(item => ({ label: item.label, value: item[valueKey] })))
      }
      setIsLoading(false)
    }
    if (!options && url) {
      fetchOptions()
    }
  }, [url, options, constructData, valueKey, currentParams, dataKey])

  const {
    field: { ref, value, onChange, ...inputProps },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  const onChangeHandler = e => {
    if (constructSelectedValue) {
      onChange(e[valueKey])
      if (onChangeCallback) onChangeCallback(e[valueKey])
    } else {
      onChange(e)
      if (onChangeCallback) onChangeCallback(e)
    }
    if (onChangeCallback) {
      onChangeCallback(e)
    }
  }

  useEffect(() => {
    if (constructSelectedValue) {
      if (options?.length && value) {
        const transformedDefaultValues = isMulti
          ? options.filter(option => value.includes(option[valueKey]))
          : options.filter(option => value === option[valueKey])[0]
        setSelectValue(transformedDefaultValues)
      } else {
        setSelectValue(undefined)
      }
    } else {
      setSelectValue(value)
    }
  }, [options, valueKey, value, constructSelectedValue, isMulti])

  const portalProps = portal
    ? {
        menuPortalTarget: document.body,
        menuShouldBlockScroll: true,
      }
    : {}

  const menuStyles = {
    ...(portal && { menuPortal: { zIndex: theme['z-modal-above'] } }),
  }

  return (
    <Select
      onChange={onChangeHandler}
      {...inputProps}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isMulti={isMulti}
      placeholder={placeholder}
      value={selectValue}
      options={options}
      ref={ref}
      style={style}
      styles={{ ...menuStyles, ...styles }}
      {...portalProps}
    />
  )
}

ControlledSelect.defaultProps = {
  constructData: undefined,
  dataKey: 'data',
  isDisabled: false,
  isLoading: false,
  isMulti: true,
  onChangeCallback: undefined,
  options: undefined,
  params: undefined,
  placeholder: 'Select...',
  portal: false,
  rules: undefined,
  style: undefined,
  url: undefined,
  valueKey: 'value',
}

ControlledSelect.propTypes = {
  constructData: PropTypes.func,
  control: PropTypes.shape({}).isRequired,
  dataKey: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  params: PropTypes.shape(),
  placeholder: PropTypes.string,
  portal: PropTypes.bool,
  rules: PropTypes.shape({}),
  style: PropTypes.shape(),
  url: PropTypes.string,
  valueKey: PropTypes.string,
}

export default ControlledSelect
