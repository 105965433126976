/* eslint-disable react/destructuring-assignment */
import React from 'react'
import ReactQueryBuilder from 'react-querybuilder'
import { Button, useFela } from '@fortressiq/fiq-ds'

import {
  actionValues,
  allEventFilterOperators,
  annotationTypeValues,
  applicationTypeValues,
  boolValues,
  controlTypeValues,
  operators,
} from 'constants/event-constants'
import QueryBuilderInput from './components/QueryBuilderInput'
import QueryBuilderSelect from './components/QueryBuilderSelect'
import QueryBuilderRemove from './components/QueryBuilderRemove'
import QueryBuilderDatetime from './components/QueryBuilderDatetime'
import QueryBuilderAutocomplete from './components/QueryBuilderAutocomplete'

import {
  combinatorsCSS,
  fieldsCSS,
  operatorsCSS,
  queryBuilderCSS,
  removeCSS,
  ruleCSS,
  ruleGroupCSS,
  valueCSS,
} from './styles'

const getOperators = field => {
  switch (field) {
    case 'action':
    case 'control_type':
    case 'name':
      return [operators.eq, operators.neq, operators.in, operators.nin, operators.null, operators.nNull]
    case 'screen_signature':
    case 'signature':
      return [
        operators.eq,
        operators.neq,
        operators.in,
        operators.nin,
        operators.null,
        operators.nNull,
        operators.matches,
      ]
    case 'value':
      return [operators.eq, operators.neq, operators.in, operators.nin, operators.matches]
    case 'is_browser':
    case 'annotation_type':
    case 'application_type':
      return [operators.eq, operators.neq]
    case 'application_name':
    case 'machine':
    case 'observer_name':
    case 'user':
      return [operators.eq, operators.neq, operators.in, operators.nin]
    case 'application_id':
    case 'observer_id':
    case 'id':
      return [
        operators.eq,
        operators.neq,
        operators.in,
        operators.nin,
        operators.gt,
        operators.gteq,
        operators.lt,
        operators.lteq,
      ]
    case 'observed_at':
      return [operators.eq, operators.neq, operators.gt, operators.gteq, operators.lt, operators.lteq]
    default:
      return null
  }
}

const getValueEditorType = field => {
  switch (field) {
    case 'action':
    case 'application_type':
    case 'control_type':
    case 'is_browser':
    case 'annotation_type':
      return 'select'
    case 'application_name':
    case 'observer_name':
    case 'user':
    case 'observer_id':
    case 'machine':
    case 'application_id':
      return 'autocomplete'
    case 'observed_at':
      return 'datetime'
    case 'id':
      return 'integer'
    default:
      return 'text'
  }
}

const getValues = field => {
  switch (field) {
    case 'is_browser':
      return boolValues
    case 'application_type':
      return applicationTypeValues
    case 'annotation_type':
      return annotationTypeValues
    case 'action':
      return actionValues
    case 'control_type':
      return controlTypeValues
    default:
      return []
  }
}

const QueryBuilder = ({ setQuery, setIsValid, ...queryBuilderProps }) => {
  const { css } = useFela()
  const controlClassNames = {
    addRule: css(combinatorsCSS),
    addGroup: css(combinatorsCSS),
    combinators: css(combinatorsCSS),
    fields: css(fieldsCSS),
    operators: css(operatorsCSS),
    queryBuilder: css(queryBuilderCSS),
    removeGroup: css(removeCSS),
    removeRule: css(removeCSS),
    rule: css(ruleCSS),
    ruleGroup: css(ruleGroupCSS),
    value: css(valueCSS),
  }

  const controlElements = {
    addRuleAction: AddButton,
    addGroupAction: AddButton,
    combinatorSelector: fiqdsSelect,
    fieldSelector: fiqdsSelect,
    operatorSelector: fiqdsSelect,
    valueEditor: QueryBuilderValueEditor,
    removeGroupAction: QueryBuilderRemove,
    removeRuleAction: QueryBuilderRemove,
  }

  const setValidQuery = rawQuery => {
    if (setIsValid) setIsValid(true) // needs to be here to prime value to true -- validateQuery() will set if false
    if (rawQuery.rules.length !== 0) {
      validateQuery(rawQuery)
    }
    if (setQuery) setQuery(rawQuery)
  }

  const validateQuery = rawQuery => {
    const parentGroupEmpty = rawQuery.combinator && rawQuery.rules[0]?.combinator && rawQuery.rules.length === 1
    if (rawQuery.rules?.length === 0 || parentGroupEmpty) {
      if (setIsValid) setIsValid(false)
    } else if (rawQuery.rules) {
      rawQuery.rules.forEach(rule => {
        if (rule.rules) validateQuery(rule)
      })
    }
  }

  return (
    <span id='queryBuilder-container'>
      <ReactQueryBuilder
        controlClassnames={controlClassNames}
        controlElements={controlElements}
        getOperators={getOperators}
        getValueEditorType={getValueEditorType}
        getValues={getValues}
        operators={allEventFilterOperators}
        {...queryBuilderProps}
        onQueryChange={setValidQuery}
      />
    </span>
  )
}

const AddButton = ({ className, handleOnClick, title }) => (
  <Button className={className} onClick={handleOnClick}>
    {title}
  </Button>
)

const QueryBuilderValueEditor = ({ type, options, values, operator, ...props }) => {
  if (operator === 'null' || operator === 'notNull') return null
  const ValueEditor = valueEditorMap[type] || QueryBuilderInput

  return <ValueEditor options={options || values} isFieldValue={true} type={type} operator={operator} {...props} />
}

const fiqdsSelect = fiqdsSelectProps => {
  return QueryBuilderSelect({
    ...fiqdsSelectProps,
  })
}

const valueEditorMap = {
  select: QueryBuilderSelect,
  datetime: QueryBuilderDatetime,
  autocomplete: QueryBuilderAutocomplete,
}

export default QueryBuilder
