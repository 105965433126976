import api from 'lib/Api'

import { LIST_CATEGORIES } from './Constants'

/* eslint-disable import/prefer-default-export */
export const fetchUrlAllowAndBlockLists = async () => {
  const urlListResponse = await api.get('/allow_block_lists', { category_type: LIST_CATEGORIES.URL })
  const applicationListResponse = await api.get('/allow_block_lists', {
    category_type: LIST_CATEGORIES.APPLICATION,
  })

  return {
    urlLists: urlListResponse.data.allowBlockList,
    applicationLists: applicationListResponse.data.allowBlockList,
  }
}

export const getConfiguredAllowBlockLists = allowBlockLists => {
  const url = allowBlockLists?.find(list => list.categoryType === LIST_CATEGORIES.URL)
  const application = allowBlockLists?.find(list => list.categoryType === LIST_CATEGORIES.APPLICATION)
  return { url, application }
}
