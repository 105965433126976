import React, { useEffect, useState } from 'react'

import { useHeaderDispatch } from 'app/header/HeaderContext'
import { useFetch } from 'lib/hooks'

import TrinityTable from 'components/TrinityTable'

import getProcessesColumns from './getProcessesColumns'

const sortKeyMap = {
  createdAt: 'created_at',
}

const Processes = () => {
  const [reFetch, setReFetch] = useState(0)

  const { data: { integrations = [] } = {}, isLoadingIntegrations } = useFetch('/dynamic_integrations/process/list')

  const columns = getProcessesColumns({ reFetch, setReFetch, integrations, isLoadingIntegrations })

  const headerDispatch = useHeaderDispatch()

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: 'Processes',
      heading: 'Processes',
    })
    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  return (
    <TrinityTable
      columns={columns}
      reFetch={reFetch}
      url='/graph_processes'
      dataKey='graphProcesses'
      tableId='graph-processes-table'
      sortKeyMap={sortKeyMap}
      defaultSortKey={sortKeyMap.createdAt}
      globalFilterOptions={[
        'favorited',
        'recommended',
        'processesCreatedDates',
        'graphProcessTags',
        'miningTypeProcesses',
        'miningRunIds',
        'graphProcessIds',
        'graphProcessNames',
        'applications',
      ]}
    />
  )
}

export default Processes
