import React from 'react'
import PropTypes from 'prop-types'
import { Button, Group, theme } from '@fortressiq/fiq-ds'

const dialogueStyle = {
  padding: `0 ${theme['default-spacer-sm']} ${theme['default-spacer-sm']} ${theme['default-spacer-sm']}`,
}

const OpportunitySubmittedDialogue = ({ removeModal, createOpportunity }) => (
  <div style={dialogueStyle}>
    <h4>Opportunity already exists</h4>
    <p>
      An opportunity for this process already exists. <br />
      Do you want to create a new one?
    </p>
    <Group>
      <Button onClick={removeModal}>Cancel</Button>
      <Button type='secondary' onClick={createOpportunity}>
        Create
      </Button>
    </Group>
  </div>
)

OpportunitySubmittedDialogue.propTypes = {
  removeModal: PropTypes.func,
  createOpportunity: PropTypes.func,
}

export default OpportunitySubmittedDialogue
