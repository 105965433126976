import React from 'react'
import { Card, combineRules } from '@fortressiq/fiq-ds'

import { processCardCSS } from '../styles'

const ProcessDashCard = ({ title, children, loading = false }) => (
  <Card loading={loading} style={combineRules(processCardCSS, { flexGrow: '1', width: '48%' })} title={title}>
    {children}
  </Card>
)

export default ProcessDashCard
