import React, { useState } from 'react'
import { string } from 'prop-types'
import { Card, Dropdown, Icon, useFela } from '@fortressiq/fiq-ds'

import Can from 'components/can/Can'

import { cardCSS, titleCSS, titleRowCSS } from './processViewDashboard/styles'

const DetailCard = ({ title, menu, children }) => {
  const { css, theme } = useFela()
  const [visible, setIsVisible] = useState(false)

  return (
    <Card className={css(cardCSS)} paddedContent={false}>
      <div className={css(titleRowCSS)}>
        <span className={css(titleCSS)}>{title}</span>
        {menu && (
          <Can perform='/client-dashboard/:id:detail-menu'>
            <Dropdown overlay={menu} id={`client-dash-${title}`} trigger={{ type: 'click' }}>
              <Icon clickable={true} color={theme['link-color']} icon='cog' onClick={() => setIsVisible(!visible)} />
            </Dropdown>
          </Can>
        )}
      </div>
      <div className={css({ padding: theme['default-spacer-md'] })}>{children}</div>
    </Card>
  )
}

DetailCard.propTypes = {
  title: string,
}

DetailCard.defaultProps = {
  title: '',
}

export default DetailCard
