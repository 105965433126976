/* eslint-disable import/prefer-default-export */
import { buttonSectionCSS } from 'app/mining/form/styles'

export const cycleButtonSectionCSS = ({ theme }) => ({
  background: theme.white,
  ...buttonSectionCSS({ theme }),
})

export const scrollSectionCSS = () => ({
  flex: '1',
  maxHeight: '482px',
  overflow: 'auto',
  width: '610px',
})

export const filterWrapCSS = ({ theme }) => ({ marginLeft: `-${theme['default-spacer-md']}` })
