import React from 'react'
import { Element } from '@fortressiq/fiq-ds'

// TO DO: move this to design system?
// wasn't happy with how validation /error handling needed to be implemented
// we (front end team) also haven't decided which direction to go there yet :shrugs
const ErrorMsg = ({ msg, children }) => (
  <Element
    style={({ theme }) => ({
      color: theme['error-color'],
      fontSize: theme['font-size-sm'],
      marginTop: theme['default-spacer-sm'],
      lineHeight: '1em',
    })}
  >
    {msg || children}
  </Element>
)

export default ErrorMsg
