import React from 'react'
import { Element, Icon, Menu, MenuItem, Popconfirm, theme, Typography, useFela } from '@fortressiq/fiq-ds'
import { array, func, string } from 'prop-types'

import store from '../../ClientDashboardStore'

import { menuCss } from './styles'

const { Text } = Typography

const AddObjectButton = ({ action, dark, type }) => (
  <MenuItem
    dark={dark}
    onClick={action}
    suffixIcon={{ icon: 'add', size: 12, color: dark ? theme.white : theme.black }}
  >
    {type}
  </MenuItem>
)

const MenuRow = ({ dark, item: { id, displayName, name, type }, onClick }) => {
  const displayText = `${displayName || name} | ${type}`
  const title = `Remove ${displayText} from this Dashboard?`

  return (
    <MenuItem
      key={id}
      onClick={e => {
        // needed to have child button event fire
        e.stopPropagation()
      }}
      tooltip={{
        mouseEnterDelay: 3000,
        placement: 'top-start',
        title: title,
      }}
    >
      <Popconfirm
        icon={false}
        onConfirm={onClick}
        placement='right-center'
        title='Are you sure you want to delete this?'
        trigger='click'
      >
        <Element style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text ellipsis={true}>{displayText}</Text>
          <Icon after={true} clickable={true} color={dark ? theme.white : theme.black} icon='trash' size={12} />
        </Element>
      </Popconfirm>
    </MenuItem>
  )
}

const CardMenu = ({ dark = true, type, list, action, del }) => {
  const { css } = useFela()

  return (
    <Menu bordered={!dark} className={css(menuCss)} dark={dark}>
      <AddObjectButton action={action} type={type} />
      {list.map(item => {
        const onClick = type === 'Asset' ? () => store.removeUpload(item.id) : () => del(item.id)

        return <MenuRow key={item.id} onClick={onClick} item={item} rowType={type} dark={dark} />
      })}
    </Menu>
  )
}

CardMenu.propTypes = {
  action: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  list: array.isRequired,
  type: string.isRequired,
}

export default CardMenu
