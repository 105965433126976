import React from 'react'
import { shape, string } from 'prop-types'
import { Alert } from '@fortressiq/fiq-ds'
import { useLocalStorage } from 'lib/hooks'

const localStorageKey = 'maintenance-banner'

const MaintenanceBanner = ({ maintenance }) => {
  const { title, description } = maintenance
  const [show, setShowing] = useLocalStorage(localStorageKey, true, true)
  if (show) {
    return (
      <Alert
        description={description}
        message={title}
        showIcon={true}
        type='info'
        closable={true}
        onClose={() => setShowing(prev => !prev)}
      />
    )
  }
  return null
}

MaintenanceBanner.propTypes = {
  maintenance: shape({
    title: string,
    description: string,
  }),
}

export default MaintenanceBanner
