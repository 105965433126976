import rappid from 'vendor/rappid.js'

import { Rectangle } from '../shape_defs/Rectangle'

// initialize/define shapes here
Rectangle(rappid)

rappid.shapes.standard.Link.define(
  'app.Link',
  {
    router: {
      name: 'normal',
    },
    connector: {
      name: 'rounded',
    },
    labels: [],
    attrs: {
      line: {
        stroke: '#E1E5EB',
        strokeDasharray: '0',
        strokeWidth: 2,
        fill: 'none',
        sourceMarker: {
          type: 'path',
          d: 'M 0 0 0 0',
          stroke: 'none',
        },
        targetMarker: {
          type: 'path',
          d: 'M8 -5 -2 0 8 5z',
          stroke: 'none',
          fill: '#E1E5EB',
        },
      },
    },
  },
  {
    defaultLabel: {
      attrs: {
        rect: {
          fill: '#ffffff',
          stroke: '#E1E5EB',
          strokeWidth: 1,
          refWidth: 10,
          refHeight: 10,
          refX: -5,
          refY: -5,
        },
      },
    },
  }
)
