import React from 'react'
import { string, number, arrayOf, shape } from 'prop-types'
import { Group, Tag, theme } from '@fortressiq/fiq-ds'
import css from './panelMetrics.scss'

const PanelMetrics = ({ metrics, className = '', style }) => (
  <div className={`${css.container} ${className}`} style={style}>
    <Group>{metrics && metrics.map((metric, index) => <Metric metric={metric} key={`${index}metric`} />)}</Group>
  </div>
)

export const Metric = ({ metric, className = '' }) => (
  <div className={`${css.metric} ${className}`}>
    <span className={css.metricTitle}>{metric.title}</span>
    <MetricTag>{metric.value}</MetricTag>
  </div>
)

const MetricTag = ({ children }) => (
  <Tag color={theme['legacy-color-gray1']} className={css.tag}>
    {children}
  </Tag>
)

PanelMetrics.propTypes = {
  metrics: arrayOf(
    shape({
      title: string,
      metric: number,
    })
  ).isRequired,
}

export default PanelMetrics
