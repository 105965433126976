import React, { useState } from 'react'
import { Overlay, Button, Icon } from '@fortressiq/fiq-ds'

import { addImportant } from 'helpers'
import WebApplicationForm from '../WebApplicationForm'

const RulesEditButton = ({ applications, application, dispatch }) => {
  const [showing, setShowing] = useState(false)
  const placement = {
    overflow: { adjustX: true, adjustY: true },
    points: ['br', 'tr'],
    targetOffset: [0, 10],
    useCssTransform: false,
  }

  const trigger = {
    showing,
    setShowing,
    node: (
      <Button
        onClick={() => setShowing(!showing)}
        size='small'
        style={{
          borderLeft: addImportant(0),
          borderRadius: addImportant(0),
        }}
        tooltipProps={{ title: 'Edit existing rule.' }}
      >
        <Icon size='nested' icon='edit' />
      </Button>
    ),
  }

  const heights = {
    min: 500,
  }

  const config = {
    disableOutsideClick: false,
    trigger: {
      icons: {
        prefix: {
          icon: 'edit',
          size: 'nested',
        },
      },
    },
  }

  const editAction = () => {
    setShowing(false)
  }

  return (
    <Overlay
      basic={false}
      closeIcon={true}
      config={config}
      content={
        <WebApplicationForm
          applications={applications}
          onSubmit={editAction}
          application={application}
          dispatch={dispatch}
        />
      }
      draggable={true}
      heights={heights}
      id={`edit-web-application-modal-${application.id}`}
      placement={placement}
      trigger={trigger}
    />
  )
}

export default RulesEditButton
