import React, { useEffect, useState } from 'react'
import { DateCell, Button, useModal, theme } from '@fortressiq/fiq-ds'

import TrinityTable, { columnWidths } from 'components/TrinityTable'

import { useHeaderDispatch } from 'app/header/HeaderContext'

import { useFetch } from 'lib/hooks'

import ActionsMenu from './subComponents/ActionsMenu'
import ApplicationListForm from './subComponents/ApplicationListForm'

const sortKeyMap = {
  accessType: 'access_type',
  createdAt: 'created_at',
}

const createModalId = 'create-application-list-form'

const modalConfig = {
  footer: false,
  style: { height: 'auto', width: '500px' },
  config: { interactWithKeyboard: false, destroyOnClose: true },
  headerStyle: { background: theme.white },
}

const ApplicationList = () => {
  const [reFetch, setReFetch] = useState(0)
  const { addModal, removeModal } = useModal()

  const { data: applicationsOptions } = useFetch('/applications/filters', {
    field: 'win_process_name',
    app_type: 'windows',
  })

  const handleReFetch = () => {
    setReFetch(reFetch + 1)
  }
  const headerDispatch = useHeaderDispatch()

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      align: 'center',
      Header: 'Type',
      accessor: 'accessType',
      width: 50,
    },
    {
      align: 'right',
      Header: 'Created By',
      accessor: 'createdBy',
      disableSortBy: true,
      width: 150,
      Cell: ({ value: { firstName, lastName } }) => `${firstName} ${lastName}`,
    },
    {
      align: 'right',
      Header: 'Created At',
      accessor: 'createdAt',
      width: columnWidths.date,
      maxWidth: columnWidths.date,
      Cell: ({ value }) => <DateCell timestamp={value} />,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      align: 'center',
      disableSortBy: true,
      width: 30,
      maxWidth: columnWidths.actionMenu,
      Cell: ({ row: { original } }) => (
        <ActionsMenu
          handleReFetch={handleReFetch}
          applicationList={original}
          modalConfig={modalConfig}
          isInUse={original.observers?.length !== 0}
          applicationsOptions={applicationsOptions}
        />
      ),
    },
  ]

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: 'Manage Application List',
      heading: 'Manage Application List',
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  return (
    <TrinityTable
      toolbarActions={[
        <Button
          type='secondary'
          key={createModalId}
          onClick={() =>
            addModal({
              children: (
                <ApplicationListForm
                  handleReFetch={handleReFetch}
                  removeModal={() => removeModal(createModalId)}
                  applicationsOptions={applicationsOptions}
                />
              ),
              header: 'Create List',
              id: createModalId,
              ...modalConfig,
            })
          }
        >
          Create List
        </Button>,
      ]}
      reFetch={reFetch}
      sortKeyMap={sortKeyMap}
      params={{ category_type: 'application' }}
      tableId='application-table'
      columns={columns}
      url='/allow_block_lists'
      dataKey='allowBlockList'
    />
  )
}

export default ApplicationList
