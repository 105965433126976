import React, { useContext } from 'react'
import classnames from 'classnames/bind'
import { Link } from 'react-router-dom'

import { Typography, Button, Icon, Spin } from '@fortressiq/fiq-ds'

import { EventContext, EventProvider } from './EventContext'
import ReviewSidebar from '../Sidebar'
import BoundingBoxTag from './BoundingBoxTag'
import ReviewHeader from '../ReviewHeader'

import BoundingBox from './BoundingBox'
import css from './eventStyle.scss'

const { Paragraph } = Typography

const cx = classnames.bind(css)

const Event = () => {
  const {
    systemBoundingBoxes,
    ratio,
    activeBox,
    newBoundingBox,
    userBoundingBoxes,
    isLoading,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onActiveBox,
    deleteBoundingBox,
    imageRef,
    updateDimensions,
    onSaveAnnotation,
    observerID,
    reviewID,
    screenshotUrl,
    annotationsIds,
    toggleHover,
    currentHover,
    current,
    next,
    prev,
  } = useContext(EventContext)

  return (
    <div className={cx(css.main)}>
      <ReviewHeader currentObserver={observerID} title='Data Review' />
      <div className={cx(css.screenSection)}>
        {isLoading ? (
          <Spin />
        ) : (
          <div
            className={cx(css.drawSection)}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
          >
            {newBoundingBox && (
              <BoundingBox key={newBoundingBox.bounds} bounds={newBoundingBox.bounds} ratio={ratio} active={true} />
            )}
            {userBoundingBoxes &&
              userBoundingBoxes.map((box, i) => (
                <BoundingBox
                  key={box.bounds}
                  bounds={box.bounds}
                  ratio={ratio}
                  i={i}
                  id={box.id}
                  deleteBoundingBox={deleteBoundingBox}
                  onActiveBox={onActiveBox}
                  active={activeBox && activeBox.bounds.toString() === box.bounds.toString()}
                  boxType='userBoundingBox'
                  toggleHover={toggleHover}
                  isHover={currentHover === box.bounds.toString()}
                />
              ))}
            {systemBoundingBoxes &&
              systemBoundingBoxes.map((box, i) => {
                if (box.invalid) return null
                return (
                  <BoundingBox
                    key={box.bounds}
                    bounds={box.bounds}
                    ratio={ratio}
                    i={i}
                    id={box.id}
                    deleteBoundingBox={deleteBoundingBox}
                    onActiveBox={onActiveBox}
                    active={activeBox && activeBox.bounds.toString() === box.bounds.toString()}
                    boxType='systemBoundingBox'
                    toggleHover={toggleHover}
                    isHover={currentHover === box.bounds.toString()}
                  />
                )
              })}
            <img
              className={cx(css.screenshot)}
              id='img'
              ref={imageRef}
              onLoad={() => updateDimensions()}
              src={screenshotUrl}
              alt='screenshot'
            />
          </div>
        )}
      </div>
      <ReviewSidebar>
        <div className={cx(css.sidebarWrap)}>
          <div>
            <div className={cx(css.sidebarSection)}>
              <div className={cx(css.flexBetween)}>
                <p className={cx(css.stepsTitle)}>
                  Events reviewed {annotationsIds.filter(annotation => annotation.is_reviewed).length} /{' '}
                  {annotationsIds.length}
                </p>
                <div>
                  {prev && (
                    <Link to={`/review/${observerID}/${reviewID}/${prev.id}`}>
                      <Icon icon='arrowleft' />
                    </Link>
                  )}
                  {next && (
                    <Link to={`/review/${observerID}/${reviewID}/${next.id}`}>
                      <Icon icon='arrowright' />
                    </Link>
                  )}
                </div>
              </div>
              <div className={cx(css.stepWrap)}>
                {annotationsIds.map((annotation, i) => (
                  <div
                    key={`step-${annotation.id}`}
                    className={cx(css.step, {
                      isReviewed: annotation.is_reviewed,
                      isCurrent: i === current,
                    })}
                  />
                ))}
              </div>
              <h2 className={cx(css.titleLarge)}>What are the words that best describe the purpose of this screen?</h2>
              <Paragraph>
                Process Discovery has identified the words below as being most important to this screen.
              </Paragraph>
              <Paragraph>
                Deselect words below or in the screenshot by clicking their x-icon if they&apos;re incorrect, and draw
                your own boxes around words Process Discovery may have missed by clicking-and-dragging your cursor over
                the screenshot
              </Paragraph>
            </div>
            <div className={cx(css.sidebarSection)}>
              <h2 className={cx(css.titleSmall)}>
                Suggested Words (
                {systemBoundingBoxes && systemBoundingBoxes.length !== 0
                  ? systemBoundingBoxes.filter(box => !box.invalid).length
                  : 0}
                )
              </h2>
              {systemBoundingBoxes &&
                systemBoundingBoxes.map((box, i) => {
                  if (box.invalid) return null
                  return (
                    <BoundingBoxTag
                      key={`tag-${box.bounds}`}
                      deleteBoundingBox={deleteBoundingBox}
                      i={i}
                      box={box}
                      toggleHover={toggleHover}
                      bounds={box.bounds}
                      isHover={currentHover === box.bounds.toString()}
                      boxType='systemBoundingBox'
                    />
                  )
                })}
            </div>
            <div className={cx(css.sidebarSection)}>
              <h2 className={cx(css.titleSmall)}>
                User selections ({userBoundingBoxes && userBoundingBoxes.length !== 0 ? userBoundingBoxes.length : 0})
              </h2>
              <div>
                {userBoundingBoxes &&
                  userBoundingBoxes.map((box, i) => (
                    <BoundingBoxTag
                      key={`tag-${box.bounds}`}
                      deleteBoundingBox={deleteBoundingBox}
                      i={i}
                      box={box}
                      toggleHover={toggleHover}
                      bounds={box.bounds}
                      isHover={currentHover === box.bounds.toString()}
                      boxType='userBoundingBox'
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className={cx(css.sidebarFooter)}>
            <div className={cx(css.flexBetween)}>
              <Button href={`/review/${observerID}`}>Done</Button>
              <Button disabled={isLoading} onClick={() => onSaveAnnotation()} type='secondary'>
                Save{' '}
                {userBoundingBoxes && userBoundingBoxes.length + systemBoundingBoxes.filter(box => !box.invalid).length}{' '}
                Selections
              </Button>
            </div>
          </div>
        </div>
      </ReviewSidebar>
    </div>
  )
}

Event.propTypes = {}

export default props => (
  <EventProvider {...props}>
    <Event {...props} />
  </EventProvider>
)
