import { observable, action, computed } from 'mobx'
import * as d3 from 'd3'
import api from 'lib/Api'
import dom from 'lib/Dom'

import GroupsStore from './GroupsStore'
import subprocessStore from './subprocessStore'
import { getCollapsed } from '../common/util'

class ProcessExplorationStore {
  nodeSizes = {
    normal: { width: 132, height: 45 },
    collapsed: { width: 210, height: 153 },
  }

  groups = new GroupsStore()

  //diagram zoom level expressed as value that would be used in a translation matrix
  //1 = 100%...
  @observable zoom = 1

  //node info
  @observable nodeWidth = this.nodeSizes.normal.width

  @observable nodeHeight = this.nodeSizes.normal.height

  @observable iconWidth = this.nodeSizes.normal.height

  @observable viewingNode = {}

  @observable viewingEventLog = {}

  @observable d3Data = {}

  @observable tab = 'process'

  @observable flowData = []

  @observable flow = []

  @observable flowColor = '#ccc'

  /**** global (as in across screen variables) ****/
  //process context id
  @observable screen = ''

  @observable processName = ''

  @observable graphId = ''

  @observable treeFlow = {}

  @observable selectedPath = {
    nodes: [],
    paths: [],
  }

  @observable collapsed = {}

  @computed
  get baseNodeWidth() {
    return this.nodeWidth + this.iconWidth + 5
  }

  @computed
  get baseNodeHeight() {
    return this.nodeHeight - 10
  }

  @action
  setDefaults = () => {
    this.zoom = 1
    this.nodeWidth = this.nodeSizes.normal.width
    this.nodeHeight = this.nodeSizes.normal.height
    this.iconWidth = this.nodeSizes.normal.height
    this.viewingNode = {}
    this.viewingEventLog = {}
    this.flowData = []
    this.flow = []
    this.flowColor = '#ccc'
    this.groups.groupList = []
    this.treeFlow = {}
  }

  @action
  setSelectedPathByNode = (selectedNode, fromNode = true) => {
    // set selected path to all paths that this node exist on
    const node = selectedNode.data.type === 'decision' ? selectedNode.parent : selectedNode
    const paths = []

    const allCollapsed = getCollapsed(this.collapsed)

    const nodes = this.flowData.reduce((acc, flowData) => {
      const disabled = flowData.path.find(flowPath => allCollapsed[flowPath])
      if (!disabled && flowData.path.indexOf(node.id) > -1) {
        paths.push(flowData.key)
        return [...acc, ...flowData.path]
      }
      return acc
    }, [])
    this.setSelectedPath(nodes, paths, fromNode)
  }

  @action
  setViewingNode = node => {
    if (node === null) {
      this.viewingNode = null
      return
    }

    this.setSelectedPathByNode(node)
    const { value = 1 } = node
    const parentValue = (node.parent ? node.parent.value : value) || value

    this.viewingNode = {
      percent: `${Math.round((value / parentValue) * 100)}%`,
      ...node,
    }
    const { activities } = this.viewingNode.data

    if (activities.length > 0 && activities[0]) {
      return api.get('/event_logs', { activity_id: activities[0].id }).then(response => {
        const [currentEvent] = response.data.events
        this.viewingEventLog = currentEvent
      })
    }
  }

  @action
  showDetails = event => {
    event.stopPropagation()
    this.tab = 'details'
  }

  @action unhideGroups = () => {
    this.groups.unhideGroups(this.getVisibleNodesMap())
  }

  @action hideGroups = () => {
    this.groups.hideGroups(this.getVisibleNodesMap())
  }

  @action
  setZoomAmount = amount => {
    const diagram = document.getElementById('diagramSVGCanvas')
    const graph = document.getElementById('diagramSVG')
    const newZoom = `matrix( ${amount}, 0, 0, ${amount}, 0, 0)`

    diagram.style.webkitTransform = newZoom
    diagram.style.transform = newZoom

    //offset marginTop for diagram as we zoom out
    if (amount < 1) {
      const nheight = graph.getBoundingClientRect().height
      const height = +graph.style.height.replace('px', '')
      const marginTop = `${(50 / nheight) * height}px` //50px is heigh tof pane info
      graph.style.marginTop = marginTop
    }
    this.zoom = amount
  }

  ////////
  //NOTES have tried scaling in d3 too,
  //it seems to move the bounding box (default behavior) can play with this later...
  @action
  setZoomDirection = direction => {
    const diagram = document.getElementById('diagramSVGCanvas')
    const graph = document.getElementById('diagramSVG')

    const zoom = dom.getZoom(diagram)

    let a = zoom[0]
    let d = zoom[1]

    if (direction === 'in') {
      a += 0.1
      d += 0.1
    } else {
      a -= 0.1
      d -= 0.1
      if (a < 0) return
    }

    const newZoom = `matrix( ${a}, 0, 0, ${d}, 0, 0)`

    diagram.style.webkitTransform = newZoom
    diagram.style.transform = newZoom

    //offset marginTop for diagram as we zoom out
    if (a < 1) {
      const domHeight = graph.getBoundingClientRect().height
      const styleHeight = +graph.style.height.replace('px', '')
      const marginTop = `${(50 / domHeight) * styleHeight}px` //50px is height of pane info
      graph.style.marginTop = marginTop
    }
    this.zoom = a
  }

  @action resetModes = () => {
    this.groups.groupsMode = false
    this.groups.editGroupMode = false
    subprocessStore.subprocessMode = false
  }

  @action createGroup = () => {
    this.groups.activeGroup = {
      startNode: null,
      endNode: null,
      groupId: null,
      groupName: '',
      activeGroupNodeIds: [],
      baseColor: null,
    }
    this.groups.groupsMode = true
    this.groups.editGroupMode = false
    subprocessStore.subprocessMode = false
  }

  @action
  EditGroup(group) {
    this.groups.activeGroup = {
      ...group,
      activeGroupNodeIds: [...group.nodeIds],
      groupId: group.id || null,
      groupName: group.name || '',
      baseColor: group.baseColor,
    }
    this.groups.editGroupMode = true
    this.groups.groupsMode = false
    subprocessStore.subprocessMode = false
  }

  @action
  setSubprocessMode = () => {
    this.groups.editGroupMode = false
    this.groups.groupsMode = false
    subprocessStore.subprocessMode = true
  }

  @action
  setTreeFlow = (value, index, type) => {
    this.treeFlow = { value, index, type }
  }

  @action setSelectedPath = (nodes, paths, fromNode = false) => {
    this.selectedPath = { nodes, paths, fromNode }
  }

  @action handlePathnameUpdate = async ({ path, name, graphProcessId }) => {
    await api.put(`/graph_processes/${graphProcessId}/paths/${path.id}`, {
      name: name,
    })
    const newFlowData = [...this.flowData]
    newFlowData.forEach(data => {
      if (data.id === path.id) {
        data.name = name
      }
    })
    this.flowData = newFlowData
  }

  @action setCollapsed = collapsed => {
    // set collpased, adjust selectedPath to remove paths that are collapsed
    this.collapsed = collapsed

    const allCollapsed = getCollapsed(collapsed)

    let nodes = []
    const paths = []
    this.selectedPath.paths.forEach(path => {
      const flow = this.flowData.find(flowData => flowData.key === path)
      const disabled = flow.path.find(flowPath => allCollapsed[flowPath])
      if (!disabled) {
        paths.push(path)
        nodes = [...nodes, ...flow.path]
      }
    })

    this.setSelectedPath(nodes, paths)
  }

  getVisibleNodesMap = () => {
    const visibleNodesMap = {}
    this.getVisibleNodesMapRecursive(this.d3Data, visibleNodesMap)

    return visibleNodesMap
  }

  getVisibleNodesMapRecursive = (node, visibleNodesMap) => {
    visibleNodesMap[node.data.id] = true

    if (node.children) {
      node.children.forEach(child => {
        this.getVisibleNodesMapRecursive(child, visibleNodesMap)
      })
    }
  }

  getPathInfo(nodeId) {
    let pathKey = ''
    let duration = 0
    let pathName = ''
    let pathId = ''
    let hideDuration = false
    this.flowData.forEach(path => {
      if (path.path.find(el => el === nodeId)) {
        pathKey = path.key
        duration = path.average_duration
        pathName = path.name
        pathId = path.id
        hideDuration = path.hideDuration
      }
    })

    return {
      pathKey: pathKey,
      pathDuration: duration,
      pathName: pathName,
      pathId: pathId,
      hideDuration: hideDuration,
    }
  }
}

export default new ProcessExplorationStore()
