import React, { Fragment } from 'react'

import api from 'lib/Api'
import { toAlpha } from 'lib/String'

import { Button, Element, Heading, theme } from '@fortressiq/fiq-ds'

import localStorage from 'lib/Storage'

import Can from 'components/can/Can'

import CreateProcessDialogue from './createProcessDialogue/CreateProcessDialogue'
import ButterflyMainContent from './ButterflyMainContent'
import ButterflyTooltip from './ButterflyTooltip'
import ButterflyProcessStore from './stores/ButterflyProcessStore'
import { HeaderDispatchContext } from '../header/HeaderContext'

import ButterflySelect from './ButterflySelect'

import { getColor } from './util'

import { chartContainerCSS, toggleAppButtonCSS, toolbarCSS } from './styles/index'
import SidePanel from './SidePanel'

import { getFlowDataStats } from '../processes/utils'

class Butterfly extends React.Component {
  static contextType = HeaderDispatchContext

  constructor(props) {
    super(props)
    this.state = {
      showApps: false,
      butterflyData: {},
      applicationData: { applications: {}, total: 0, order: [] },
      graphProcesses: [],
      graphProcessId: props.match.params.id || null,
    }
  }

  async componentDidMount() {
    const { data } = await api.get('/butterflies')
    this.setState({
      graphProcesses: data.graphProcesses.map(process => ({ label: process.chartTitle, value: process.id })),
    })
    const { graphProcessId } = this.state
    if (graphProcessId) {
      this.getData(graphProcessId)
    }
    const { graphProcesses } = this.state

    this.context({
      type: 'set',
      heading: (
        <Fragment>
          <Heading className='header-title' level={1} style={{ marginRight: theme['default-spacer-sm'] }}>
            Butterfly
          </Heading>
          <Can perform='/butterfly:graphProcess_select'>
            <ButterflySelect graphProcesses={graphProcesses} graphProcessId={graphProcessId} />
          </Can>
        </Fragment>
      ),
    })
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props
    if (prevProps.match.params.id !== match.params.id) {
      this.getData(match.params.id)
    }
  }

  componentWillUnmount() {
    this.context({
      type: 'clear',
    })
  }

  render() {
    const { applicationData, butterflyData, showApps } = this.state
    const color = showApps ? theme['success-color'] : theme['legacy-color-gray1']

    return (
      <Fragment>
        <Can perform='/butterfly:create-process'>
          <Element style={toolbarCSS}>
            <Button
              onClick={this.showApps}
              style={toggleAppButtonCSS}
              suffixIcon='colorcheck'
              suffixIconProps={{ fill: color, textColor: color }}
            >
              Display App Icons
            </Button>
            <Button onClick={this.openCreateProcess}>Create a Process</Button>
          </Element>
        </Can>
        <Element style={chartContainerCSS}>
          <ButterflyMainContent butterflyData={butterflyData} showApps={showApps} />
          {Object.keys(butterflyData).length > 0 && (
            <SidePanel
              applicationData={applicationData}
              butterflyData={butterflyData}
              butterflyProcessStore={ButterflyProcessStore}
              toggleHideDuration={this.toggleHideDuration}
            />
          )}
          <Can perform='/butterfly:create-process'>
            <CreateProcessDialogue />
          </Can>
        </Element>
        <ButterflyTooltip />
      </Fragment>
    )
  }

  showApps = () => {
    const { showApps } = this.state
    this.setState({ showApps: !showApps })
  }

  getData = async value => {
    const { data } = await api.get(`/butterflies/${value}`)
    const dataWithIndex = this.transformData(data)
    this.setState({ butterflyData: dataWithIndex, applicationData: this.getApplicationData(dataWithIndex) })
    ButterflyProcessStore.butterflyData = dataWithIndex
    ButterflyProcessStore.butterflyGraphId = value
    ButterflyProcessStore.setStepsData()
    ButterflyProcessStore.clear()
  }

  transformData = data => {
    const dataWithIndex = { ...data }
    dataWithIndex.paths.forEach((path, index) => {
      const nodeList = path.nodeIds

      let hideDuration = false
      const hideDurationsList = localStorage.get('butterfly_hiddenDurations')
      if (hideDurationsList) hideDuration = hideDurationsList[path.id]

      path.average_duration = path.averageDuration
      path.key = toAlpha(index, false)
      path.originalPath = [...path.nodeIds]
      path.path = path.nodeIds
      path.color = getColor(path.id)
      path.hideDuration = hideDuration
      nodeList.forEach((node, nodeColumn, nodeArray) => {
        nodeArray[nodeColumn] = `${node}_${nodeColumn}`
      })
    })
    const { averageDuration, totalDuration } = getFlowDataStats(dataWithIndex.paths.filter(path => !path.hideDuration))
    dataWithIndex.averageDuration = averageDuration
    dataWithIndex.totalDuration = totalDuration

    return dataWithIndex
  }

  toggleHideDuration = id => {
    const { butterflyData } = this.state
    const newPathData = [...butterflyData.paths]

    let currentPath = {}
    const entry = newPathData.find(flow => flow.id === id)

    if (entry) {
      currentPath = { ...entry }
      entry.hideDuration = !entry.hideDuration
    }

    if (Object.keys(currentPath).length === 0) return

    const { averageDuration, totalDuration } = getFlowDataStats(newPathData.filter(path => !path.hideDuration))

    const newFlowData = { ...butterflyData, paths: newPathData, averageDuration, totalDuration }

    this.setState({
      butterflyData: newFlowData,
    })

    const oldHidden = localStorage.get('butterfly_hiddenDurations')
    const newHidden = { ...oldHidden }
    newHidden[id] = !currentPath.hideDuration
    localStorage.set('butterfly_hiddenDurations', newHidden, true)
  }

  getApplicationData = data => {
    const applications = {}
    let total = 0

    const nodeApplications = data.nodes.reduce((acc, node) => {
      acc[node.id] = { application: node.application, icon: node.applicationIconUrl }
      return acc
    }, {})

    const nodes = [
      ...new Set(
        data.paths.reduce(
          (acc, path) =>
            path.nodeIds.reduce((acc2, nodeId) => {
              acc2.push(nodeId)
              return acc2
            }, acc),
          []
        )
      ),
    ]

    nodes.forEach(node => {
      const nodeId = node.split('_')[0]
      const applicationInfo = nodeApplications[nodeId]
      if (applications[applicationInfo.application]) {
        applications[applicationInfo.application].count += 1
      } else {
        applications[applicationInfo.application] = {
          count: 1,
          icon: applicationInfo.icon,
        }
      }
      total += 1
    })

    const order = Object.keys(applications).sort((a, b) => applications[b].count - applications[a].count)

    return { applications, total, order }
  }

  openCreateProcess = () => {
    ButterflyProcessStore.defineProcessMode = true
  }
}

export default Butterfly
