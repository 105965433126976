//Sort.js

const sortTypeMap = {
  string: (a, b) => a.localeCompare(b),
  number: (a, b) => a - b,
  boolean: (a, b) => sortTypeMap.string(a.toString(), b.toString()),
  timestamp: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
}

export const sortBy = (key, valType) => (a, b) => {
  const type = valType || typeof a[key]
  return sortTypeMap[type] && sortTypeMap[type](a[key], b[key])
}

export const compare = (a, b, valType) => {
  const type = valType || typeof a
  return sortTypeMap[type] && sortTypeMap[type](a, b)
}
