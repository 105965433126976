import React from 'react'
import { Button } from '@fortressiq/fiq-ds'

const PlaybackControls = ({ eventCount, playing, setPlaying }) => {
  const player = () => {
    if (eventCount === 0) {
      return
    }

    if (playing) {
      setPlaying(false)
    } else {
      setPlaying(true)
    }
  }
  const icon = playing ? 'pausecircle' : 'playcircle'

  return (
    <div className='fiqds-overlay-container fiqds-overlay-dropdown-container clickable'>
      <div className='fiqds-overlay-trigger'>
        <Button disabled={eventCount === 0} flat={true} icon={icon} onClick={player}>
          Playback
        </Button>
      </div>
    </div>
  )
}

export default PlaybackControls
