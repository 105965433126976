/* eslint-disable import/prefer-default-export */
import { theme } from '@fortressiq/fiq-ds'

export const procExploreCSS = () => ({
  background: theme['legacy-color-gray1'],
  display: 'flex',
  flexFlow: 'column',
  height: 'calc(100vh - 60px)',
  left: 0,
  maxWidth: '100%',
})

export const diagramSectionCSS = ({ groupsMode, subprocessMode }) => ({
  background: theme['legacy-color-gray1'],
  borderTop: '0',
  flex: '1',
  overflow: 'auto',
  position: 'relative',
  width: '100%',
  ...(groupsMode && {
    background: theme['legacy-color-gray10'],
    boxShadow: `inset 0 0 1px 1px ${theme['legacy-color-turquoise-pale2']}`,
  }),
  ...(subprocessMode && {
    background: theme['legacy-color-gray9'],
    boxShadow: `inset 0 0 1px 1px ${theme['legacy-color-gray8']}`,
  }),
  ...((groupsMode || subprocessMode) && {
    transition: '0.2s ease-in-out',
  }),
})

export const emptyContainerCSS = () => ({
  color: 'hsla(0, 0%, 0%, 0.3)',
  marginTop: '18%',
  textAlign: 'center',
  width: '100%',
})
