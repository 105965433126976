import React, { useState } from 'react'
import PropTypes from 'prop-types'
import api from 'lib/Api'
import { ApplicationIcon, Button, Icon, Tooltip, Upload, useFela } from '@fortressiq/fiq-ds'

const ApplicationIconCell = ({
  applicationId,
  displayName,
  imageUrl: imageUrlProp,
  isEditable,
  onChangeFile,
  onDeleteIcon,
  applicationUUID,
}) => {
  const [isHover, setIsHover] = useState(false)
  const [imageUrl, setImageUrl] = useState(imageUrlProp)
  const { css } = useFela()

  const handleDeleteIcon = () => {
    api.delete(`/applications/${applicationId}/delete_icon`, {
      applicationUuid: applicationUUID,
    })
    setImageUrl()
    onDeleteIcon(applicationId)
  }

  const handleChangeFile = async icon => {
    const params = { icon: icon, applicationUuid: applicationUUID }
    const { data } = await api.upload(`/applications/${applicationId}/update_icon`, params, 'PUT')

    setImageUrl(data)
    onChangeFile(icon, applicationId)
  }

  const canDelete = isEditable && imageUrl && isHover
  const icon = (
    <ApplicationIcon
      name={!imageUrl ? displayName : ''}
      size={39}
      src={imageUrl}
      style={{
        ...(isEditable && {
          cursor: 'pointer',
        }),
      }}
    />
  )

  return (
    <div
      className={css({
        display: 'flex',
        height: '100%',
        position: 'relative',
        width: '100%',
      })}
      onClick={e => e.stopPropagation()}
      onMouseEnter={() => imageUrl && setIsHover(true)}
      onMouseLeave={() => imageUrl && setIsHover(false)}
    >
      {isEditable ? (
        <Upload
          accept='image/jpg,image/png'
          multiple={false}
          name='applicationIcon'
          noDrag={true}
          onDrop={e => handleChangeFile(e[0])}
          showThumbnails={false}
        >
          <Tooltip mouseEnterDelay={3000} placement='top-center' title='Upload App Icon'>
            {icon}
          </Tooltip>
        </Upload>
      ) : (
        icon
      )}
      {canDelete && (
        <Button
          className='deleteButton'
          onClick={handleDeleteIcon}
          shape='circle'
          size='small'
          style={{ position: 'absolute', right: '-7px', top: '-7px' }}
          tooltip={true}
          tooltipProps={{
            mouseEnterDelay: 500,
            placement: 'right-center',
            title: 'Delete this icon from this application?',
          }}
        >
          <Icon icon='trash' size={12} />
        </Button>
      )}
    </div>
  )
}

const noop = () => null
ApplicationIconCell.defaultProps = {
  imageUrl: false,
  onDeleteIcon: noop,
  onChangeFile: noop,
  isEditable: true,
}

ApplicationIconCell.propTypes = {
  applicationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  imageUrl: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  onDeleteIcon: PropTypes.func,
  onChangeFile: PropTypes.func,
  isEditable: PropTypes.bool,
}

export default ApplicationIconCell
