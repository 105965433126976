import ROLES from 'constants/roles'
import graphTypeDisplayNameMap from './graphTypeDisplayNameMap'

const filterOptions = {
  favorited: {
    field: 'favorited',
    label: 'Favorited',
    type: 'switch',
    value: '1',
    width: 'auto',
  },
  recommended: {
    field: 'recommended',
    label: 'Recommended',
    type: 'switch',
    value: { from: 70, to: 100 },
    width: 'auto',
    wrapStyle: { marginLeft: '16px' },
  },
  miningRunCreatedDates: {
    field: 'createdDates',
    label: 'Mining Run Created Date',
    type: 'rangePicker',
  },
  processesCreatedDates: {
    field: 'createdDates',
    label: 'Processes Created Date',
    type: 'rangePicker',
  },
  statusCreatedDates: {
    field: 'createdDates',
    label: 'Status Created Date',
    type: 'rangePicker',
  },
  graphProcessTags: {
    field: 'tags',
    label: 'Tags',
    type: 'typeahead',
    url: '/tags/filters',
    params: { taggable_type: ['Graph::Process', 'DfGraph::Process'] },
    mode: 'multiple',
  },
  miningRunTags: {
    field: 'tags',
    label: 'Tags',
    type: 'typeahead',
    url: '/tags/filters',
    params: { taggable_type: 'Mining::Run' },
    mode: 'multiple',
  },
  applicationsTags: {
    field: 'tags',
    label: 'Tags',
    type: 'typeahead',
    url: '/tags/filters',
    params: { taggable_type: 'TenantApplication' },
    mode: 'multiple',
  },
  applicationsTags_service: {
    field: 'tags',
    label: 'Tags',
    type: 'typeahead',
    url: '/tags/filters',
    params: { taggable_type: 'TenantApplication', service: true },
    mode: 'multiple',
  },
  observersTags: {
    field: 'tags',
    label: 'Tags',
    type: 'typeahead',
    url: '/tags/filters',
    params: { taggable_type: 'Observer' },
    mode: 'multiple',
  },
  miningRunIds: {
    field: 'miningRunIds',
    label: 'Mining IDs',
    type: 'typeahead',
    url: '/mining_runs/filters',
    mode: 'multiple',
  },
  graphProcessIds: {
    field: 'graphProcessIds',
    label: 'Process IDs',
    type: 'typeahead',
    url: '/graph_processes/filters',
    mode: 'multiple',
    params: { field_type: 'id' },
    optionsLabelKey: 'value',
  },
  graphProcessNames: {
    field: 'graphProcessNames',
    label: 'Process Names',
    type: 'typeahead',
    url: '/graph_processes/filters',
    mode: 'multiple',
    params: { field_type: 'name' },
    optionsValueKey: 'label',
  },
  applications: {
    field: 'applications',
    label: 'Applications',
    type: 'typeahead',
    url: '/applications/filters',
    mode: 'multiple',
  },
  applicationIds: {
    field: 'applications',
    label: 'Application IDs',
    type: 'typeahead',
    url: '/applications/filters',
    params: { field: 'application_id', order: 'applicationId ASC' },
    mode: 'multiple',
  },
  applications_service: {
    field: 'applications',
    label: 'Applications',
    type: 'typeahead',
    url: '/applications/filters',
    params: { service: true },
    mode: 'multiple',
  },
  applicationIds_service: {
    field: 'applications',
    label: 'Application IDs',
    type: 'typeahead',
    url: '/applications/filters',
    params: { field: 'application_id', order: 'applicationId ASC', service: true },
    mode: 'multiple',
  },
  createdBy: {
    field: 'createdBy',
    label: 'Created By',
    type: 'typeahead',
    url: '/users/filters',
    params: { field: 'mining_run_users' },
    mode: 'multiple',
  },
  observers: {
    field: 'observers',
    label: 'Observers',
    type: 'typeahead',
    url: '/observers/filters',
    mode: 'multiple',
  },
  miningType: {
    field: 'miningType',
    label: 'Mining Type',
    type: 'select',
    options: [
      { label: 'All', value: 'all' },
      { label: graphTypeDisplayNameMap.butterfly, value: 'butterfly' },
      { label: graphTypeDisplayNameMap.flowgraph, value: 'flowgraph' },
      { label: graphTypeDisplayNameMap.path, value: 'path' },
      { label: graphTypeDisplayNameMap.recipes, value: 'recipes' },
      { label: graphTypeDisplayNameMap.report, value: 'report' },
    ],
  },
  miningTypeProcesses: {
    field: 'miningType',
    label: 'Mining Type',
    type: 'select',
    options: [
      { label: 'All', value: 'all' },
      { label: graphTypeDisplayNameMap.butterfly, value: 'butterfly' },
      { label: graphTypeDisplayNameMap.flowgraph, value: 'flowgraph' },
      { label: graphTypeDisplayNameMap.path, value: 'path' },
    ],
  },
  statusFilter: {
    field: 'status',
    label: 'Status',
    type: 'select',
    options: [
      { label: 'All statuses', value: 'all' },
      { label: 'Complete', value: 'complete' },
      { label: 'Pending', value: 'pending' },
      { label: 'Done', value: 'done' },
      { label: 'Imported', value: 'imported' },
      { label: 'Generating mining parameters', value: 'generating mining parameters' },
      { label: 'Error, no paths found', value: 'error, no paths found' },
      {
        label: 'Error, bad filter rules or databse connection',
        value: 'error, bad filter rules or databse connection',
      },
      { label: 'Error, no events found', value: 'error, no events found' },
      { label: 'Mining', value: 'mining' },
      { label: 'Processing', value: 'processing' },
    ],
  },
  jobTypeFilter: {
    field: 'jobs',
    label: 'Jobs',
    type: 'select',
    options: [
      { label: 'All jobs', value: 'all' },
      { label: 'Mining Runs', value: 'mining-runs' },
      { label: 'Web Applications', value: 'web-applications' },
      { label: 'PDDs', value: 'pdds' },
      { label: 'Signature Service Jobs', value: 'signature-service-jobs' },
      { label: 'Event Log Exports', value: 'event-exports' },
      { label: 'Recipes', value: 'recipes' },
    ],
  },
  user: {
    field: 'user',
    label: 'User',
    type: 'typeahead',
    url: '/observers/windows_usernames/filters',
    mode: 'multiple',
  },
  usersEmail: {
    field: 'userEmails',
    label: 'User Email',
    mode: 'multiple',
    params: { field: 'emails' },
    type: 'typeahead',
    url: '/users/filters',
    optionsValueKey: 'label',
  },
  usersUsername: {
    field: 'usernames',
    label: 'Username',
    mode: 'multiple',
    params: { field: 'usernames' },
    type: 'typeahead',
    url: '/users/filters',
    optionsValueKey: 'label',
  },
  roleFilter: {
    field: 'roles',
    label: 'Roles',
    type: 'select',
    options: Object.keys(ROLES).map(roleObj => ({ label: ROLES[roleObj].name, value: roleObj })),
  },
}

const getFilterOptions = array => array.map(option => filterOptions[option])

export default getFilterOptions
