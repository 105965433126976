import React from 'react'
import localizer from 'lib/Localizer'
import { eventFilterFields } from 'constants/event-constants'
import css from './filterBreadcrumbs.scss'

const valueTransforms = {
  observed_at: val => (/Z$/.test(val) ? localizer.asPreciseTime(val) : val),
}

const fieldTransforms = eventFilterFields.reduce((acc, { name, label }) => ({ ...acc, [name]: label }), {})

const RuleTag = ({ id, field, operator, value }) => (
  <div className={css.filterNameTag} key={id}>
    {`${fieldTransforms[field] || field} ${operator} ${valueTransforms[field] ? valueTransforms[field](value) : value}`}
  </div>
)

const recurseRules = (rules, level = 1) =>
  rules.map((rule, index) =>
    rule.rules ? recurseRules(rule.rules, level + 1) : <RuleTag {...rule} key={`rules-${level}-${index}`} />
  )

const FilterBreadcrumbs = ({ query }) => {
  if (!query) return null

  return <div className={css.container}>{query.rules && recurseRules(query.rules)}</div>
}

export default FilterBreadcrumbs
