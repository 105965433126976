import api from 'lib/Api'

const NODE_WIDTH = 216.01
const NODE_HEIGHT = 53.01

export const getGraph = async ({ queryKey }) => {
  const [, { processId, activePaths }] = queryKey
  const params = {
    pathIds: JSON.stringify(activePaths),
    graphType: 'layered',
    partitionStrategy: 'FIXED_START_END',
    nodeWidth: NODE_WIDTH,
    nodeHeight: NODE_HEIGHT,
    layoutOptions: {
      'layered.spacing.edgeNodeBetweenLayers': 20.01,
      'layered.spacing.nodeNodeBetweenLayers': 50.01,
      'spacing.edgeEdge': 30.01,
      'layered.nodePlacement.strategy': 'BRANDES_KOEPF',
      'layered.crossingMinimization.semiInteractive': true,
      'elk.direction': 'DOWN',
      'nodePlacement.favorStraightEdges': true,
      'spacing.nodeNode': 20.01,
      edgeRouting: 'SPLINES',
    },
  }
  const { data } = await api.post(`/df_graph/process_layout/${processId}`, params)
  return data
}

export const getPaths = async ({ queryKey }) => {
  const [, { processId }] = queryKey
  const { data } = await api.get(`/df_graph/path_list/${processId}`)
  return data.paths
}

export const getExemplarEvent = async ({ queryKey }) => {
  const [, { exemplarEventId }] = queryKey
  const { data } = await api.get(`/event_logs/${exemplarEventId}`)
  return data
}

export const getAllProcesses = async () => {
  const { data } = await api.get('/df_graph/process')
  return data
}
