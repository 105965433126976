import { action, observable, runInAction } from 'mobx'

import rappid from 'vendor/rappid.js'

import api from 'lib/Api'

import '../config/config_shapes'
import { selection } from '../config/config_selection'

class DiagramComposerStore {
  @observable paper = null

  @observable graph = null

  @observable container = null

  @observable diagramData = null

  @observable diagramName = null

  @observable processes = []

  @observable loading = true

  @observable viewing = null

  //mobx does not track values nested in objects
  //for temporary editing values of the node in the inspector
  @observable activeLabel = ''

  @observable activeDescription = ''

  @observable activeNumber = ''

  @observable activeRules = []

  @observable generatingPdds = []

  @observable pddUrls = {}

  @action
  addGeneratingPdd(specId) {
    store.generatingPdds = [...new Set([...store.generatingPdds, +specId])]
  }

  @action
  removeGeneratingPdd(specId) {
    store.generatingPdds = store.generatingPdds.filter(id => id !== specId)
  }

  @action
  initSelection() {
    this.clipboard = new rappid.ui.Clipboard()
    this.selection = new rappid.ui.Selection({
      paper: this.paper,
      handles: selection,
    })

    // Initiate selecting when the user grabs the blank area of the paper while the Shift key is pressed.
    // Otherwise, initiate paper pan.
    this.paper.on(
      'blank:pointerdown',
      (evt, x, y) => {
        this.selection.cancelSelection()
        this.paperScroller.startPanning(evt, x, y)
        this.paper.removeTools()
      },
      this
    )
  }

  @action
  async loadData(diagramId) {
    this.loading = true
    if (diagramId === 'new') {
      this.diagramData = { cells: [] }
      this.diagramName = ''
    } else {
      const { data } = await api.get(`/flowcharts/${diagramId}`)
      this.diagramData = data.flowchart.diagramData
      this.diagramName = data.flowchart.name
    }

    const { data: processData } = await api.get('/graph_processes/diagram_composer')
    const processesHash = {}
    processData.forEach(process => {
      process.groups = []
      processesHash[process.id] = process
    })

    const { data: groupData } = await api.get('/graph_groups')
    groupData.graphGroups.forEach(group => {
      if (processesHash[group.graphProcessId]) {
        processesHash[group.graphProcessId].groups.push(group)
      }
    })

    runInAction(() => {
      this.processes = processData
      this.loading = false
    })
  }

  @action
  async saveDiagram(id, name = '') {
    const params = {
      name: name,
      data: this.graph.toJSON(),
    }

    const saveAction = id === 'new' ? api.post('/flowcharts', params) : api.patch(`/flowcharts/${id}`, params)

    const {
      data: { flowchart },
    } = await saveAction
    return flowchart
  }

  @action
  initPaper() {
    // 8.5 x 11 in for paper
    const letterPaper = document.getElementById('measurePaper')
    const container = document.getElementById('paperContainer')
    this.graph = new rappid.dia.Graph()
    this.paper = new rappid.dia.Paper({
      el: container,
      width: letterPaper.clientWidth,
      height: letterPaper.clientHeight,
      model: this.graph,
      defaultLink: new rappid.shapes.app.Link(),
      linkConnectionPoint: rappid.util.shapePerimeterConnectionPoint,
      interactive: { linkMove: false },
    })

    //paper snaplines
    this.snaplines = new rappid.ui.Snaplines({ paper: this.paper })

    //paper scroller/panning
    this.paperScroller = new rappid.ui.PaperScroller({
      paper: this.paper,
      autoResizePaper: true,
      cursor: 'grab',
    })
    document.getElementById('paperBack').append(this.paperScroller.el)
    this.paperScroller.render()
    this.paper.on('blank:pointerdown', this.paperScroller.startPanning)
  }

  @action
  updateViewing(elementView) {
    const element = elementView.model

    if (element.attributes.type === 'app.Link') {
      this.viewing = {}
      return
    }

    this.viewing = element
    this.viewingView = elementView
    this.activeLabel = element.attributes.attrs.label.text
    this.activeDescription = element.attributes.description

    //path labels
    const outboundLinks = this.graph.getConnectedLinks(element, { outbound: true })
    this.activeRules = outboundLinks
  }

  @action
  updateValue(name, value) {
    if (!this.viewing) return

    switch (name) {
      case 'label':
        this.viewing.attr('label/text', rappid.util.breakText(value, { width: 145 }))
        this.activeLabel = value
        break
      case 'description':
        this.viewing.attributes.description = value
        this.activeDescription = value
        break
      case 'rules':
        this.viewing.rules = value.slice(0)
        this.activeRules = value.slice(0)
        break
      default:
        break
    }
  }
}

const store = new DiagramComposerStore()

export default store
