/* eslint-disable import/prefer-default-export */

export const containerCSS = () => ({
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
})

export const mapDiagramContainerCSS = () => ({
  flex: '1',
  overflow: 'hidden',
  position: 'relative',
})

const sharedTransformCSS = {
  transform: 'scale(1)' /* must be here to change zoom using tranform matrix */,
  transformOrigin: '0 0',
}

export const diagramContainerCSS = () => ({
  boxShadow: 'inset 0 0 6px -2px rgba(157, 166, 176, 0.6)',
  height: '100%',
  margin: 'auto',
  minHeight: '100%',
  minWidth: '100%',
  overflow: 'auto',
  position: 'relative',
  ...sharedTransformCSS,
  width: '100%',
})

export const disagramSVGCSS = () => ({
  ...sharedTransformCSS,
})
