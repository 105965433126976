import { getIntersection, mergeAndDeduplicate } from 'lib/ArrayHelpers'

import { rules, liteRules } from './rbac-rules'

const getMergedUserRoutes = (roles, tenantRules) => {
  const routes = mergeAndDeduplicate(roles.map(role => rules[role].routes))
  if (tenantRules) {
    return getIntersection(routes, tenantRules.routes)
  }
  return routes
}

export const getUserMenu = (routes, roles, tenant) => {
  let tenantRules = null
  if (tenant.isLite) {
    tenantRules = liteRules
  }
  const mergedUserRoutes = getMergedUserRoutes(roles, tenantRules)

  return routes
    .map(route => {
      if (route.subItems) {
        const subItems = route.subItems.filter(subRoute => mergedUserRoutes.includes(subRoute.to) && subRoute)
        return subItems.length !== 0 && { ...route, subItems }
      }
      return mergedUserRoutes.includes(route.to) && route
    })
    .filter(Boolean)
}

const checkAllowedRoutes = (routes, roles, tenant) => {
  let tenantRules = null
  if (tenant.isLite) {
    tenantRules = liteRules
  }
  const mergedUserRoutes = getMergedUserRoutes(roles, tenantRules)

  const routesWithPermission = routes.reduce(
    (obj, route) => {
      if (mergedUserRoutes.includes(route.path)) {
        obj.allow = [...obj.allow, route]
      } else {
        obj.denied = [...obj.denied, route]
      }
      return obj
    },
    { allow: [], denied: [] }
  )

  return [routesWithPermission.allow, routesWithPermission.denied]
}

export default checkAllowedRoutes
