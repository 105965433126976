/* eslint-disable import/prefer-default-export */
export const menuCss = ({ theme }) => ({
  color: theme.white,
  maxWidth: '500px',
  minWidth: '300px',
  zIndex: theme['z-below-modal'],
  '> ul > li > .fiqds-menu-item-content': {
    color: theme.white,
  },
})
