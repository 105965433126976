//DOM functions to replace Jquery?

class DOM {
  windowHeight = () => {
    const w = window
    const d = document
    const e = d.documentElement
    const g = d.getElementsByTagName('body')[0]
    const y = w.innerHeight || e.clientHeight || g.clientHeight

    return y
  }

  windowWidth = () => {
    const w = window
    const d = document
    const e = d.documentElement
    const g = d.getElementsByTagName('body')[0]
    const x = w.innerWidth || e.clientWidth || g.clientWidth

    return x
  }

  scrollTop = el => {
    if (el) return el.scrollTop
    else return window.pageYOffset || document.documentElement.scrollTop
  }

  scrollLeft = el => {
    if (el) return el.scrollLeft
    else return window.pageXOffset || document.documentElement.scrollLeft
  }

  getZoom = el => {
    const st = window.getComputedStyle(el, null)
    const zoom =
      st.getPropertyValue('-webkit-transform') ||
      st.getPropertyValue('-moz-transform') ||
      st.getPropertyValue('-ms-transform') ||
      st.getPropertyValue('-o-transform') ||
      st.getPropertyValue('transform') ||
      null

    if (zoom === null) {
      // eslint-disable-next-line no-alert
      window.alert('This browser does not support this feature!')
      return
    }

    const zoomvals = zoom.replace(/[^1-9.,]/g, '')
    const toks = zoomvals.split(',')

    //css transform matrix = (a, b, c, d, tx , ty)
    //+ to convert to number
    const a = +toks[0]
    const d = +toks[3]

    return [a, d]
  }
}

export default new DOM()
