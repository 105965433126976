import React from 'react'
import PropTypes from 'prop-types'

import { Button, Icon, theme, DateCell, useFela } from '@fortressiq/fiq-ds'

import { giftCSS } from '../styles/index.js'

const FirstDetected = ({ status, firstSeenAt }) => {
  const { css } = useFela()
  if (status !== 'new') {
    return firstSeenAt ? <DateCell timestamp={firstSeenAt} /> : '--'
  }
  if (status === 'new') {
    return (
      <Button type='primary'>
        <Icon before={true} color={theme.white} icon='gift' className={css(giftCSS)} size='nested' />
        Review Now
      </Button>
    )
  }
}

FirstDetected.propTypes = {
  status: PropTypes.string.isRequired,
  firstSeenAt: PropTypes.string.isRequired,
}

export default FirstDetected
