import React, { memo, useEffect, useState } from 'react'
import { Overlay } from '@fortressiq/fiq-ds'

import { MAX_NUMBER_CASES } from '../Constants'

export default memo(({ instances, instanceIndex, onInstanceChange, disabled }) => {
  const [showing, setShowing] = useState(false)
  const [sort, setSort] = useState('EVENTS_DESC')
  const [menu, setMenu] = useState([])

  useEffect(() => {
    setMenu(
      instances
        .sort((a, b) => {
          if (sort === 'EVENTS_DESC') {
            return b.eventIds.length - a.eventIds.length
          } else if (sort === 'EVENTS_ASC') {
            return a.eventIds.length - b.eventIds.length
          } else if (sort === 'INSTANCE_DESC') {
            return b.index - a.index
          } else {
            return a.index - b.index
          }
        })
        .map(instance => ({
          text: `Instance ${instance.index + 1} of ${instances.length}`,
          events: instance.eventIds.length,
          disabled: instance.disabled,
          onClick: () => {
            onInstanceChange(instance.index)
            setShowing(false)
          },
          ...(instance.disabled ? { tooltip: { title: `Exceeds limit of ${MAX_NUMBER_CASES}` } } : {}),
        }))
    )
  }, [instances, sort, onInstanceChange])

  const sortOrder = column => {
    if (column === 'instance' && (sort === 'INSTANCE_ASC' || sort === 'INSTANCE_DESC')) {
      return sort === 'INSTANCE_ASC' ? 'asc' : 'desc'
    }
    if (column === 'events' && (sort === 'EVENTS_ASC' || sort === 'EVENTS_DESC')) {
      return sort === 'EVENTS_ASC' ? 'asc' : 'desc'
    }
    return false
  }

  const columns = [
    {
      icon: 'sort',
      iconProps: {
        status: sortOrder('instance'),
      },
      onClick: () => setSort(sort === 'INSTANCE_ASC' ? 'INSTANCE_DESC' : 'INSTANCE_ASC'),
      title: 'Instance',
    },
    {
      icon: 'sort',
      iconProps: {
        status: sortOrder('events'),
      },
      onClick: () => setSort(sort === 'EVENTS_ASC' ? 'EVENTS_DESC' : 'EVENTS_ASC'),
      title: 'Events',
      width: '50px',
    },
  ]

  const trigger = {
    setShowing: setShowing,
    showing: showing,
    text: `Instance ${!disabled ? `${instanceIndex + 1} of ${instances.length}` : ''}`,
  }

  const config = {
    content: { style: { marginTop: '14px' } },
    disableOutsideClick: false,
    dropdown: {
      closeOnRowClick: true,
      showArrow: false,
    },
    trigger: {
      icons: {
        prefix: {
          icon: 'programmingkeyboard',
          size: 18,
        },
      },
    },
  }
  const widths = { max: 262, min: 262, width: 262 }

  const heights = {
    height: 35,
    max: 35 * 5,
  }

  const virtual = {
    columns: columns,
    data: menu,
    height: menu.length < 5 ? heights.height * menu.length : heights.height * 5,
    itemCount: menu.length,
    itemSize: heights.height,
    width: '100%',
  }

  return (
    <Overlay
      config={config}
      disabled={disabled}
      heights={heights}
      id='instances-filter'
      menu={menu}
      trigger={trigger}
      virtual={virtual}
      widths={widths}
    />
  )
})
