import { observable, action } from 'mobx'
import BatchStore from './BatchStore'

export default class TankStore {
  @observable batches = []

  @action
  update = message => {
    const [batchId, event, data] = message

    let batch = this.batches.find(b => b.id === batchId)
    if (!batch) {
      batch = new BatchStore(batchId)
      this.batches = [...this.batches, batch]
    }

    batch.update(event, data)
  }
}
