import React, { useMemo } from 'react'
import { chroma, graphColors, theme } from '@fortressiq/fiq-ds'

import { defaultChartConfigProps, FusionCharts, FusionTheme, ReactFC, Widgets } from '../../viz/index'

ReactFC.fcRoot(FusionCharts, Widgets, FusionTheme)

const SparkChart = ({ data, height = undefined, id, type = 'sparkline', width = '99%' }) => {
  const lineColor = chroma(graphColors[1]).hex()
  const dataSource = useMemo(
    () => ({
      chart: {
        canvasPadding: '0',
        chartLeftMargin: '0',
        chartTopMargin: '0',
        chartRightMargin: '0',
        chartBottomMargin: '0',
        drawAnchors: '0',
        lineColor: lineColor,
        lineThickness: '2',
        lineAlpha: '100',
        highColor: lineColor,
        lowColor: chroma(theme['error-color']).hex(),
        setAdaptiveYMin: true,
        theme: 'fusion',
      },
      dataset: [{ data: data }],
    }),
    [data, lineColor]
  )

  const chartConfig = useMemo(
    () => ({
      ...defaultChartConfigProps,
      height: height || '220',
      id: id,
      type: type,
      width: width,
    }),
    [height, id, type, width]
  )

  return <ReactFC {...chartConfig} dataSource={dataSource} />
}

export default SparkChart
