import { prettyColor } from '@fortressiq/fiq-ds'

//ApplictionIconSVG
// expects the appplication names to exist in d.data.applications[applicationToShow].application_name
// expects the application image url to exist at d.data.applications[applicationToShow].application_icon_url

// multiple names due to the fact that an element in any set of data bound may have multiple applications
// applicationToShow is which name to display,
//   it can be a Number representation or an ananymous function which takes the d3 object as a parameter
//   (should resemble the current d3 methods)

/********
 * Usage:
 *   ApplicationIconD3({
 *     iconContainer: nodeObj,
 *     applicationIndex: index, // d => d.index...
 *.    radius: radius
 *   })
 ********/

const ApplicationIconD3 = props => {
  const { iconContainer, applicationIndex, radius } = props

  /**************************************/
  /* icon when no icon image is defined */
  iconContainer
    .filter(d => {
      const myApplicationIndex = isInteger(applicationIndex) ? applicationIndex : applicationIndex(d)
      return (
        d.data.applications &&
        d.data.applications.length > 0 &&
        (!d.data.applications[myApplicationIndex].application_icon_url ||
          d.data.applications[myApplicationIndex].application_icon_url === '')
      )
    })
    .append('circle')
    .attr('r', radius)
    .style('fill', d => {
      const myApplicationIndex = isInteger(applicationIndex) ? applicationIndex : applicationIndex(d)
      return d.data.applications && d.data.applications.length > 0
        ? prettyColor(d.data.applications[myApplicationIndex].application_name)
        : prettyColor('default')
    })
  iconContainer
    .filter(d => {
      const myApplicationIndex = isInteger(applicationIndex) ? applicationIndex : applicationIndex(d)
      return (
        d.data.applications &&
        d.data.applications.length > 0 &&
        (!d.data.applications[myApplicationIndex].application_icon_url ||
          d.data.applications[myApplicationIndex].application_icon_url === '')
      )
    })
    .append('text')
    .style('text-anchor', 'middle')
    .style('dominant-baseline', 'central')
    .attr('fill', '#fff')
    .style('font-size', `${radius - 2}px`)
    .text(d => {
      const myApplicationIndex = isInteger(applicationIndex) ? applicationIndex : applicationIndex(d)
      return d.data.applications &&
        d.data.applications.length > 0 &&
        d.data.applications[myApplicationIndex].application_name
        ? d.data.applications[myApplicationIndex].application_name.substring(0, 2).toUpperCase()
        : ''
    })

  /***********************************/
  /* icon when icon image is defined */
  iconContainer
    .filter(d => {
      const myApplicationIndex = isInteger(applicationIndex) ? applicationIndex : applicationIndex(d)
      return (
        d.data.applications &&
        d.data.applications.length > 0 &&
        d.data.applications[myApplicationIndex].application_icon_url
      )
    })
    .append('svg:image')
    .attr('xlink:href', d => {
      const myApplicationIndex = isInteger(applicationIndex) ? applicationIndex : applicationIndex(d)
      return d.data.applications && d.data.applications.length > 0
        ? d.data.applications[myApplicationIndex].application_icon_url
        : null
    })
    .attr('class', 'appIconImage')
    .attr('width', radius * 2)
    .attr('height', radius * 2)
    .attr('x', -radius)
    .attr('y', -radius)
    .attr('clip-path', d => `url(#iconClipObj${d.id})`)

  iconContainer
    .filter(d => {
      const myApplicationIndex = isInteger(applicationIndex) ? applicationIndex : applicationIndex(d)
      return (
        d.data.applications &&
        d.data.applications.length > 0 &&
        d.data.applications[myApplicationIndex].application_icon_url
      )
    })
    .append('circle')
    .attr('r', radius)
    .attr('stroke', '#a1acc1')
    .style('fill', 'transparent')

  iconContainer
    .filter(d => {
      const myApplicationIndex = isInteger(applicationIndex) ? applicationIndex : applicationIndex(d)
      return (
        d.data.applications &&
        d.data.applications.length > 0 &&
        d.data.applications[myApplicationIndex].application_icon_url
      )
    })
    .append('clipPath')
    .attr('id', d => `iconClipObj${d.id}`)
    .call(selection => {
      selection.append('circle').attr('r', radius)
    })
}

// returns boolean: is the index parameter sent in an integer?
const isInteger = input => {
  const integerRepresentation = parseInt(input, 10)
  return integerRepresentation || integerRepresentation === 0
}

export default ApplicationIconD3
