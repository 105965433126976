// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`PddProgressMessage should render without throwing an error 1`] = `
<Fragment>
  <Memo(Title)
    level={5}
  >
    Generating PDD
  </Memo(Title)>
  <Memo(Image)
    alt="Step One"
    bordered={true}
    className="stepThumb"
    src="data:image/jpeg;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjQwIiB3aWR0aD0iNDMuMDA3Ij4KICAgIDxwYXRoIGNsYXNzPSJicmFuZC1pY29uIiBmaWxsPSIjRjA1NzQyIiBkPSJNMzIuNzUgMHY0LjUxOWgtNi4xMThWMEgxNi4zNzV2NC41MTloLTYuMTE4VjBIMHY0MGg0My4wMDdWMHoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgogICAgPHBhdGggZD0iTTMxLjU3NiAyMC40OWMwLTEuMDExLS4xMzgtMS45MjItLjQxNi0yLjczMS0uMjc3LS44MS0uNjc2LTEuNDk2LTEuMTk1LTIuMDU4YTUuMTU4IDUuMTU4IDAgMDAtMS45MDktMS4yOTJjLS43NTItLjMtMS42MDktLjQ1LTIuNTctLjQ1LS45NyAwLTEuODM0LjE1LTIuNTkxLjQ1LS43NTcuMy0xLjM5Ni43My0xLjkxNiAxLjI5MnMtLjkxOCAxLjI0OC0xLjE5NSAyLjA1OGMtLjI3OC44MS0uNDE2IDEuNzItLjQxNiAyLjczMSAwIDEuMDIuMTM4IDEuOTM2LjQxNiAyLjc0NS4yNzcuODEuNjc1IDEuNDk2IDEuMTk1IDIuMDU4LjUyLjU2MSAxLjE1OS45OSAxLjkxNiAxLjI4NC43NTcuMjk2IDEuNjIyLjQ0MyAyLjU5Mi40NDMuOTYgMCAxLjgxNy0uMTQ3IDIuNTctLjQ0M2E1LjA4MyA1LjA4MyAwIDAwMS45MDgtMS4yODRjLjUyLS41NjIuOTE4LTEuMjQ4IDEuMTk1LTIuMDU4LjI3OC0uODEuNDE2LTEuNzI1LjQxNi0yLjc0NXptNS4xMzkgMGMwIC44OC0uMDk3IDEuNzI1LS4yOSAyLjUzNS0uMTkzLjgxLS40NyAxLjU3LS44MzEgMi4yODJhOS44MDEgOS44MDEgMCAwMS0zLjE0MSAzLjU5NWw1LjQ4IDUuNjU5aC00LjEzYTUuOTk4IDUuOTk4IDAgMDEtMS42MTgtLjIwM2MtLjQ4NS0uMTM2LS45MTYtLjQxLTEuMjkyLS44MjJsLTIuNjg4LTIuODVjLS40MzYuMDgzLS44NzkuMTUtMS4zMy4xOTYtLjQ1LjA0Ny0uOTEzLjA3LTEuMzg4LjA3LTEuNjgzIDAtMy4yMTgtLjI2Ny00LjYwNC0uOC0xLjM4Ny0uNTM0LTIuNTcyLTEuMjcxLTMuNTU3LTIuMjEyYTkuODU5IDkuODU5IDAgMDEtMi4yODgtMy4zMjFjLS41NC0xLjI3NC0uODA5LTIuNjUtLjgwOS00LjEyOSAwLTEuNDguMjctMi44NTYuODEtNC4xMjlhOS43ODcgOS43ODcgMCAwMTIuMjg3LTMuMzE0Yy45ODUtLjkzNiAyLjE3LTEuNjcxIDMuNTU3LTIuMjA1IDEuMzg2LS41MzMgMi45Mi0uOCA0LjYwNC0uOCAxLjY4MyAwIDMuMjE1LjI2OSA0LjU5Ni44MDcgMS4zODIuNTM5IDIuNTYyIDEuMjc2IDMuNTQzIDIuMjEyYTkuODM2IDkuODM2IDAgMDEyLjI4IDMuMzE0Yy41NCAxLjI3NC44MDkgMi42NDUuODA5IDQuMTE1ek02LjI5OCAzMC43MjdoNS4wNXYtMjAuNDZoLTUuMDV6IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg=="
    width={80}
  />
  <Memo(Text)>
    Step One
  </Memo(Text)>
</Fragment>
`;
