const clearOnLogoutKeys = {}
const localStorageCache = {}
const localStorage = {
  set: (key, value, shouldClearOnLogout = true) => {
    localStorageCache[key] = value
    window.localStorage.setItem(key, JSON.stringify(value))
    if (shouldClearOnLogout) {
      clearOnLogoutKeys[key] = true
    }
  },

  get: key => localStorageCache[key] || JSON.parse(window.localStorage.getItem(key)),

  // should only be called when logging out
  clearOnLogout: () =>
    Object.keys(clearOnLogoutKeys).forEach(key => {
      delete localStorageCache[key]
      window.localStorage.removeItem(key)
    }),
}

export default localStorage
