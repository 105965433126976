import React from 'react'
import Draggable from 'react-draggable'
import { Icon } from '@fortressiq/fiq-ds'
import { observer } from 'mobx-react'

import NewGroupForm from './NewGroupForm'
import EditGroupForm from './EditGroupForm'

import css from './groupsModalStyle.scss'

import store from '../stores/processExplorerStore'

const GroupsModal = () => {
  const { activeGroup } = store.groups
  const { groupId } = activeGroup

  return (
    <Draggable>
      <div className={`${css.container}`}>
        <div className={css.leave} onClick={store.groups.closeGroups} role='button' tabIndex={0}>
          <Icon icon='remove' />
        </div>
        {groupId === null ? <NewGroupForm /> : <EditGroupForm />}
      </div>
    </Draggable>
  )
}

export default observer(GroupsModal)
