import React from 'react'
import { observer } from 'mobx-react'
import { Typography, Label, theme } from '@fortressiq/fiq-ds'

import TextToInput from 'components/inputs/TextToInput'

const { Text } = Typography

@observer
class EditDiagramNode extends React.Component {
  render() {
    const { number, name, description, onEnter } = this.props
    return (
      <React.Fragment>
        <Text>{number || '--'}</Text>
        <Label style={{ marginTop: theme['default-spacer-sm'] }}>Label</Label>
        <TextToInput value={name} onEnter={onEnter} name='label' />
        <Label style={{ marginTop: theme['default-spacer-md'] }}>Description</Label>
        <TextToInput value={description} emptyText='No description provided.' name='description' onEnter={onEnter} />
      </React.Fragment>
    )
  }
}

export default EditDiagramNode
