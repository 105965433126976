import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Menu, MenuItem } from '@fortressiq/fiq-ds'
import SidePanel from 'components/detailsSidePanel/SidePanel'
import PathStatistics from 'components/detailsSidePanel/pathStatistics/PathStatistics'
import MiningRun from 'components/detailsSidePanel/miningRun/MiningRun'
import StepsByApplication from 'components/detailsSidePanel/processOverview/StepsByApplication'
import Stats from 'components/detailsSidePanel/processOverview/Stats'

import NodeInspector from '../details/stepDetails/NodeInspector'
import css from './processExploration.scss'
import { getCollapsed } from '../common/util'

export default observer(
  ({
    applicationData,
    flowData,
    graphProcessId,
    minedRun,
    node,
    store,
    flowDataStats: { instanceCount, averageDuration, totalDuration },
    tree,
    graphProcess,
    toggleHideDuration,
  }) => {
    const [sidePanelActiveKey, setSidePanelActiveKey] = useState(['steps', 'mining', 'process', 'path'])
    const [showSidePanel, setShowSidePanel] = useState(true)
    const { selectedPath, viewingNode, collapsed } = store

    const toggleSidePanel = () => {
      setShowSidePanel(!showSidePanel)
    }

    const addAllChildren = () => tree.addAllChildren(node)

    const toggleRequired = () => tree.toggleRequired(node)

    const highlightSimilar = () => tree.highlightSimilar(node)

    const menu = (
      <Menu bordered={false} dark={true}>
        <MenuItem disabled={!viewingNode.hiddenChildren} onClick={addAllChildren}>
          Expand All
        </MenuItem>
        <MenuItem disabled={!viewingNode.children} onClick={() => tree.collapseAllChildren(node)}>
          Collapse All Children
        </MenuItem>
        <MenuItem onClick={toggleRequired}>Toggle Required</MenuItem>
        <MenuItem onClick={highlightSimilar}>Highlight Similar Events</MenuItem>
      </Menu>
    )

    const sidePanels = [
      {
        header: 'Step Details',
        key: 'steps',
        panel: (
          <NodeInspector
            menu={menu}
            onCollapse={() => tree.collapseNode(node)}
            onRemove={() => tree.removeOneChild(node)}
            onExpand={() => tree.addOneChild(node)}
          />
        ),
      },
      {
        header: 'Process Overview',
        key: 'process',
        panel: (
          <div className={css.processOverview}>
            <Stats
              steps={applicationData.stepTotal}
              paths={flowData.length}
              instances={instanceCount}
              averageDuration={averageDuration}
              totalDuration={totalDuration}
            />
            <StepsByApplication
              applications={applicationData.applications}
              total={applicationData.total}
              order={applicationData.order}
            />
          </div>
        ),
      },
      {
        header: 'Path Statistics',
        key: 'path',
        panel: (
          <PathStatistics
            handlePathnameUpdate={store.handlePathnameUpdate}
            graphProcessId={graphProcessId}
            graphType={graphProcess.graph_type}
            paths={flowData}
            selectedPath={selectedPath}
            collapsed={getCollapsed(collapsed)}
            setSelectedPath={(selectedPaths, selectedKeys) => store.setSelectedPath(selectedPaths, selectedKeys)}
            showPathLink={graphProcess && graphProcess.signature_level !== 'field'}
            toggleHideDuration={toggleHideDuration}
          />
        ),
      },
      minedRun && {
        header: 'Mining Run Details',
        key: 'mining',
        panel: <MiningRun id={minedRun.id} date={minedRun.created_at} parameters={minedRun.readable_filter_rules} />,
      },
    ].filter(Boolean)

    return (
      <SidePanel
        openKeys={sidePanelActiveKey}
        collapse={!showSidePanel}
        onChange={setSidePanelActiveKey}
        panels={sidePanels}
        toggleCollapse={toggleSidePanel}
      />
    )
  }
)
