/* eslint-disable import/prefer-default-export */

export const dashboardHeaderTitleCSS = ({ theme }) => ({
  fontSize: '20px !important',
  marginTop: theme['default-spacer-sm'],
})

export const overlayTriggerCSS = ({ theme }) => ({
  width: '90px',
  position: 'absolute',
  right: `-${theme['default-spacer-md']}`,
  top: '12px',
})
