import React, { createContext, useContext, useReducer } from 'react'

const HeaderStateContext = createContext()
const HeaderDispatchContext = createContext()

function headerReducer(state, action) {
  switch (action.type) {
    case 'set': {
      const newStateItems = { ...state, ...action }
      delete newStateItems.type
      return newStateItems
    }
    case 'clear': {
      return { heading: ' ', noHelmet: false, title: ' ', toolbar: null, toolbarActions: null }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function HeaderProvider({ children }) {
  const [state, dispatch] = useReducer(headerReducer, { title: ' ', toolbar: null })
  return (
    <HeaderStateContext.Provider value={state}>
      <HeaderDispatchContext.Provider value={dispatch}>{children}</HeaderDispatchContext.Provider>
    </HeaderStateContext.Provider>
  )
}

function useHeaderState() {
  const context = useContext(HeaderStateContext)
  if (context === undefined) {
    throw new Error('useHeaderState must be used within a HeaderProvider')
  }
  return context
}

function useHeaderDispatch() {
  const context = React.useContext(HeaderDispatchContext)
  if (context === undefined) {
    throw new Error('useHeaderDispatch must be used within a HeaderProvider')
  }
  return context
}

export { HeaderDispatchContext, HeaderProvider, useHeaderState, useHeaderDispatch }
