//utility functions
class Utils {
  generateLabel = text => {
    return {
      markup: [
        {
          tagName: 'rect',
          selector: 'body',
        },
        {
          tagName: 'text',
          selector: 'label',
        },
      ],
      attrs: {
        body: {
          fill: '#f7f8fa',
          stroke: '#f7f8fa',
        },
        label: {
          fontSize: 11,
          text: text,
          fill: '#555',
        },
      },
      position: {
        distance: 0.5, //distance on the edge
        offset: {
          x: 5,
          y: -5,
        },
      },
    }
  }
}

export default new Utils()
