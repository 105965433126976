export const paramsDetailsMap = {
  axis: {
    label: 'Axis',
    description: '0 - for horizontal tabs, 1 - for vertical tabs.',
    type: 'number',
    rules: { min: 0, max: 1 },
  },
  black_white_override: {
    label: 'Ignore Blacks and Whites',
    description: 'Ignores near-black and near-white pixels when evaluating color.',
    type: 'checkbox',
  },
  color: {
    label: 'Color',
    description:
      'A list representing RGB color of the tab. This could be determined by using built in mac application called Digital Color Meter and hovering over tab of interest. Ex: [110, 110, 112].',
    type: 'text',
    placeholder: 'example: [110, 110, 112]',
  },
  ngrams: {
    label: 'Exact Phrases',
    description: 'Consider an object only when its exact phrase is found in the allow list.',
    type: 'checkbox',
  },
  overwrite_stop_words: {
    label: 'Overwrite Stop Words',
    description:
      'Default: false. Definition: To be used in conjunction with the stop_words variable. If false we use both the keywords found with our “threshold” variable and ones defined in our “stop_words” variable as words to remove from provisional signature.',
    type: 'checkbox',
  },
  regex_list: {
    label: 'Regex List',
    description:
      'A list of regex rules and their desired signature. For example: [{"regex_rule": "desired_signature"}].',
    type: 'textarea',
    placeholder: 'example: [{"regex_rule": "desired_signature"}]',
  },
  rgb_delta: {
    label: 'RGB Delta',
    description:
      'Because a lot of applications use gradient based colors for tabs/controls. The rgb values for the color are defined by a range. This parameter essentially covers the range of R, G, and B colors to be identified. Ex: [5, 5, 10] i.e R = 105 – 115, G = 105 – 115 and B = 102 – 122.',
    type: 'text',
    placeholder: 'Example: [5, 5, 10]',
  },
  scrub_numbers: {
    label: 'Scrub Numbers',
    description: 'Remove numbers from screen signatures.',
    type: 'checkbox',
  },
  scrub_punctuation: {
    label: 'Scrub Punctuation',
    description: 'Remove punctuation from screen signatures.',
    type: 'checkbox',
  },
  stop_words: {
    label: 'Stop Words',
    description:
      'List of words you would like excluded. (must be lowercased). Additional words you would like to be removed from a provisional signature. Example: ["these","will","all","be","removed"].',
    type: 'textarea',
    placeholder: 'Example: ["these","will","all","be","removed"]',
  },
  stopwords: {
    label: 'Stop Words',
    description:
      'A list of words that you don’t want to be found as tabs. A lot of applications have similar colors for the Tab (of interest) and buttons. Hence passing this parameter would prevent buttons from being found as tabs.',
    type: 'text',
  },
  stop_words_only: {
    label: 'Stop Words Only',
    description:
      'To be used in conjunction with the stop_words variable. If checked, the model will use the stop words list only and disregard the whitelist.',
    type: 'checkbox',
  },
  target: {
    label: 'Target',
    description: 'Choose to append Field or Control Key.',
    type: 'radiogroup',
    boxes: [
      { label: 'Field', value: 'field' },
      { label: 'Control Key', value: 'control key' },
    ],
  },
  threshold: {
    label: 'Threshold',
    description:
      'The percentile of keywords to drop.  For example, if threshold = 0.4, this will drop the bottom 40% of keywords found by count.',
    type: 'number',
    title: '',
    rules: { min: 0, max: 1, step: '.01' },
  },
  use_bg_color: {
    label: 'Use Background Color',
    description: 'Used to determine active tab based off background color instead of text color.',
    type: 'checkbox',
  },
  use_url: {
    label: 'Use URL',
    description: 'Attempt to extract url from page and use that to determine screen signature.',
    type: 'checkbox',
  },
  use_ocr_text: {
    label: 'Use OCR Text',
    description: '',
    type: 'checkbox',
  },
  whitelist: {
    label: 'Allow List',
    description:
      'List of words you would like included (case sensitive). Opposite of stop_words, these are words you want included in your provisional signatures. Example: ["these","will","all","be","included"].',
    type: 'ngram',
    placeholder: 'Example: ["these","will","all","be","included"]',
  },
  allow_list: {
    label: 'Allow List',
    description:
      'List of words you would like included (case sensitive). Opposite of stop_words, these are words you want included in your provisional signatures. Example: ["these","will","all","be","included"].',
    type: 'ngram',
    placeholder: 'Example: ["these","will","all","be","included"]',
  },
}

export const strategyOptions = [
  {
    label: 'Text - Window Title Top Keywords',
    tag: 'text',
    value: 'title_keywords',
    description: 'Keywords in title and screen.',
    params: [
      { value: 'whitelist' },
      { value: 'stop_words' },
      { value: 'overwrite_stop_words' },
      { value: 'stop_words_only' },
      { value: 'use_ocr_text' },
      { value: 'use_url' },
      { value: 'scrub_numbers' },
      { value: 'scrub_punctuation' },
      { value: 'threshold' },
    ],
  },
  {
    label: 'Text - Regex',
    tag: 'text',
    value: 'regex',
    description: 'Regex against text.',
    params: [{ value: 'use_ocr_text' }, { value: 'regex_list' }],
  },
  {
    label: 'Image - Label Color',
    tag: 'image',
    value: 'colored_tab',
    description: 'Distinct colors for the labels/tabs.',
    params: [
      { value: 'color' },
      { value: 'black_white_override' },
      { value: 'rgb_delta' },
      { value: 'stopwords' },
      { value: 'whitelist' },
      { value: 'ngrams' },
    ],
  },
  {
    label: 'Image Text - Active Tab',
    tag: 'imageText',
    value: 'active_tab',
    description: 'Assigns a signature based off the active tab on a page.',
    params: [{ value: 'whitelist' }, { value: 'use_bg_color' }, { value: 'axis' }],
  },
  {
    label: 'Image - Hashing',
    tag: 'image',
    value: 'dhash_dbscan',
    description: 'Convert the screens into hashes and group similar hashes together',
    params: [],
  },
  {
    label: 'Hybrid - Chained',
    tag: 'hybrid',
    value: 'chained',
    description: 'Chains multiple strategies together.',
    params: [],
    selectable: -1,
  },
  {
    label: 'Field Append',
    tag: 'hybrid',
    value: 'field_append',
    description: 'Target field or control key to be used as a screen signature.',
    params: [{ value: 'allow_list' }, { value: 'target' }, { value: 'scrub_punctuation' }],
    selectable: 1,
  },
]
