import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Select, useController, Button, Table, useFela, theme } from '@fortressiq/fiq-ds'
import FieldWrapper from 'components/form/FieldWrapper'

import {
  applicationSelectWrapperCSS,
  applicationSelectCSS,
  tableWrapperCSS,
} from '../../domainList/subComponents/styles'

const tableContainerCSS = { maxHeight: '40vh', display: 'flex' }
const tableWrapperInnerCSS = { flex: 1, overflow: 'auto' }

const ControlledApplicationInput = ({ control, name, error, clearErrors, applicationsOptions }) => {
  const [localValue, setLocalValue] = useState()

  const {
    field: { value, onChange, ref },
  } = useController({
    name,
    control,
    rules: { validate: v => v?.length || 'Add at least one application' },
  })

  const { css } = useFela()

  const onRemoveApplication = application => {
    const updatedApplication = value.filter(oldApplication => oldApplication !== application)
    onChange(updatedApplication)
  }

  const onSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    onChange([...value, ...localValue.map(({ label }) => label)])
    // clear values after submit
    setLocalValue(null)
  }

  const onSelectChange = e => {
    setLocalValue(e)
    if (error) {
      clearErrors('applications')
    }
  }

  return (
    <Fragment>
      <FieldWrapper htmlFor='application' label='Application' error={error}>
        <div className={css(applicationSelectWrapperCSS)}>
          <Select
            className={css(applicationSelectCSS)}
            creatable={true}
            isMulti={true}
            menuPortalTarget={document.body}
            menuShouldBlockScroll={true}
            onChange={onSelectChange}
            options={applicationsOptions}
            placeholder='Select Applications'
            value={localValue}
            ref={ref}
            styles={{ menuPortal: { zIndex: theme['z-modal-above'] } }}
          />
          <Button disabled={!localValue} onClick={onSubmit}>
            Add
          </Button>
        </div>
      </FieldWrapper>
      <div className={css(tableWrapperCSS, tableContainerCSS)}>
        <div className={css(tableWrapperInnerCSS)}>
          <Table
            data={value ? value.map(application => ({ application })) : []}
            disablePagination={true}
            disableResizing={true}
            verticalBorders={false}
            columns={[
              {
                Header: 'Application',
                accessor: 'application',
                width: 380,
              },
              {
                Header: '',
                accessor: 'action',
                disableSortBy: true,
                minWidth: 60,
                width: 60,
                align: 'right',
                Cell: ({
                  row: {
                    original: { application },
                  },
                }) => <Button icon='trash' onClick={() => onRemoveApplication(application)} />,
              },
            ]}
          />
        </div>
      </div>
    </Fragment>
  )
}

ControlledApplicationInput.defaultProps = {
  applicationsOptions: undefined,
}

ControlledApplicationInput.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({}).isRequired,
  clearErrors: PropTypes.func.isRequired,
  applicationsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
}

export default ControlledApplicationInput
