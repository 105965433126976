import React from 'react'
import PropTypes from 'prop-types'
import { useFetch } from 'lib/hooks'
import Localizer from 'lib/Localizer'
import RunDetails from './RunDetails'

const RunDetailsWrapper = ({ miningRunId, isLoading: isProcessLoading }) => {
  const { data = { applications: [] }, isLoading: isMiningLoading } = useFetch(`/mining_runs/${miningRunId}`, null, [
    miningRunId,
  ])
  const runDetails = {
    ...data,
    createdAt: Localizer.asDate(data.createdAt),
    graphProcessCount: data.graphProcessCount,
    applicationCount: data.applications.length,
    type: data.miningType,
  }
  const isLoading = isProcessLoading || isMiningLoading
  return <RunDetails runDetails={runDetails} isLoading={isLoading} />
}

RunDetailsWrapper.defaultProps = {
  miningRunId: null,
}

RunDetailsWrapper.propTypes = {
  miningRunId: PropTypes.number,
  isLoading: PropTypes.bool,
}

export default RunDetailsWrapper
