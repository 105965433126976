/* eslint-disable react/jsx-indent */
import React from 'react'
import PropTypes from 'prop-types'
import { RadioGroup, Radio, useController } from '@fortressiq/fiq-ds'

const ControlledRadioGroup = ({ control, name, options, rules, button, style, children, isDisabled, onChange }) => {
  const {
    field: { value, onChange: rhfOnChange },
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <RadioGroup
      button={button}
      aria-label={name}
      name={name}
      onChange={e => {
        if (onChange) onChange(e.selectedValue, value)
        rhfOnChange(e.selectedValue)
      }}
      selectedValue={value}
      style={style}
    >
      {options
        ? options.map(({ label, value: v }) => (
            <Radio key={`radio-${name}-${v}`} value={v} isDisabled={isDisabled}>
              {label}
            </Radio>
          ))
        : children}
    </RadioGroup>
  )
}

ControlledRadioGroup.defaultProps = {
  rules: undefined,
  button: false,
  style: undefined,
  isDisabled: false,
}

ControlledRadioGroup.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  rules: PropTypes.shape({}),
  button: PropTypes.bool,
  style: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
}

export default ControlledRadioGroup
