import React from 'react'
import { observer } from 'mobx-react'

import localizer from 'lib/Localizer'

import DashRow from './DashRow'
import EventStatsCard from '../cards/EventStatsCard'
import MetricContainer from '../cards/MetricContainer'
import {
  Charts,
  defaultChartConfigProps,
  defaultDataSourceChartProps,
  FusionCharts,
  FusionTheme,
  PowerCharts,
  ReactFC,
} from '../../../components/viz/index'

import css from '../styles.scss'

import DashboardStore from '../dashboardStore'

ReactFC.fcRoot(FusionCharts, PowerCharts, Charts, FusionTheme)
@observer
class EventStats extends React.Component {
  render() {
    const { openModal, eventStats, events, latestRuns } = this.props

    const recentEventsData = events.map(({ day, total }) => ({ label: day, value: total }))
    const recentEventsDataSource = {
      chart: {
        ...defaultDataSourceChartProps,
        caption: 'Event Activity',
        subCaption: 'Last 7 days',
      },
      data: recentEventsData,
    }
    const chartConfigProps = {
      ...defaultChartConfigProps,
      id: 'dashboard-observers-event-activity',
      type: 'area2d',
    }

    return (
      <DashRow title='Observers &amp; Runs' type='Events' openModal={openModal}>
        {DashboardStore.eventsVis.upcoming && (
          <EventStatsCard eventStats={eventStats} onClose={() => DashboardStore.removeVis('upcoming', 'events')} />
        )}

        {DashboardStore.eventsVis.observed && (
          <MetricContainer onClose={() => DashboardStore.removeVis('observed', 'events')}>
            <ReactFC {...chartConfigProps} dataSource={recentEventsDataSource} />
          </MetricContainer>
        )}

        {DashboardStore.eventsVis.latestRuns && (
          <MetricContainer onClose={() => DashboardStore.removeVis('latestRuns', 'events')}>
            <h3 className={css.h3}>Latest Runs</h3>

            <div className={`${css.latestRow} ${css.headers}`}>
              <div>ID</div>
              <div>DATE</div>
              <div>STATUS</div>
              <div># OF PROCESSES</div>
            </div>

            {latestRuns.map((item, i) => {
              return (
                <div className={`${css.latestRow} ${css.table}`} key={i}>
                  <div>{item[0]}</div>
                  <div>{localizer.asDateTime(item[1])}</div>
                  <div className={css[`status${item[2]}`]}>{item[2]}</div>
                  <div>{item[3]}</div>
                </div>
              )
            })}
          </MetricContainer>
        )}
      </DashRow>
    )
  }
}

export default EventStats
