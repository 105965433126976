import { action, observable, computed } from 'mobx'
import api from 'lib/Api'

class ClientDashboardStore {
  @observable currentCard = null

  @observable isLoading = true

  @observable dashboardCards = new Map()

  @computed get dashboardCardsArray() {
    return Array.from(this.dashboardCards.values()).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
  }

  @observable visList = []

  @observable tags = []

  @observable uploadsList = []

  @action
  removeVis = async visId => {
    const visIndex = this.visList.findIndex(vis => vis.id === visId)
    this.visList.splice(visIndex, 1)
    await api.delete(`/dashboard_cards/${this.currentCard.id}/remove_visualization`, { graphId: visId })

    //we need to update the vis list and the aaplication's list
    //TODO will consider performance at another time
    return this.fetchCard(this.currentCard.id)
  }

  @action
  addVis = async visData => {
    const { data } = await api.post(`/dashboard_cards/${this.currentCard.id}/add_visualization`, visData)
    this.visList.push(data)

    //we need to update the vis list and the aaplication's list
    //TODO will consider performance at another time
    this.fetchCard(this.currentCard.id)
    return data
  }

  @action
  updateUploadsList = async file => {
    if (file) {
      this.uploadsList = [...new Set([file, ...this.uploadsList])]
      return this.uploadsList
    }
    const { data } = await api.get(`/dashboard_cards/${this.currentCard.id}`)
    this.uploadsList = data.dashboardCard.files
    return data.dashboardCard.files
  }

  @action
  removeUpload = async uploadId => {
    this.uploadsList = this.uploadsList.filter(file => file.id !== uploadId)
    const { data } = await api.delete(`/dashboard_cards/${this.currentCard.id}/delete_file`, {
      file_id: uploadId,
    })

    this.uploadsList = data.dashboardCard.files

    return this.uploadsList
  }

  @action
  attachUpload = async file => {
    const { data } = await api.post(`/dashboard_cards/${this.currentCard.id}/attach_file`, {
      fileId: file.id,
    })
    this.updateUploadsList(data)
    return data
  }

  @action
  setCurrentCard = (cardId, cardData) => {
    const prevCard = this.currentCard
    this.currentCard = cardData || this.dashboardCards.get(cardId)
    return [this.currentCard, prevCard]
  }

  @action
  addDashboardCard = async cardData => {
    const {
      data: { dashboardCard },
    } = await api.post('/dashboard_cards', cardData)

    this.dashboardCards.set(dashboardCard.id, dashboardCard)

    return dashboardCard
  }

  @action
  fetchTags = async () => {
    const { data } = await api.get('/tags/filters', { taggable_type: 'DashboardCard' })
    this.tags = data
    return this.tags
  }

  @action
  fetchCards = async () => {
    const { data } = await api.get('/dashboard_cards')
    if (data.dashboardCards.length) {
      data.dashboardCards.forEach(card => {
        this.dashboardCards.set(card.id, { ...card })
      })
    }
    this.isLoading = false
    return this.dashboardCardsArray
  }

  @action
  fetchCard = async cardId => {
    const { data } = await api.get(`/dashboard_cards/${cardId}`)
    this.currentCard = data.dashboardCard
    this.visList = data.dashboardCard.graphs
    this.uploadsList = data.dashboardCard.files
  }

  @action
  deleteCard = async cardId => {
    await api.delete(`/dashboard_cards/${cardId}`)
    this.fetchTags()
    this.dashboardCards.delete(cardId)
  }

  @action
  fetchGraphProcesses = visType => {
    return api.get('/graph_processes/search', { vis_type: visType })
  }
}

export default new ClientDashboardStore()
