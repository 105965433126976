import React from 'react'
import { bool, func } from 'prop-types'

import Checkmark from './images/checkmark.svg'

import css from './bigGrayCheckbox.scss'

/****************
 * large checkbox component
 * It is an individual checkbox, not an HTML form checkbox
 * (for example, at the time of creation, it is used in a Table component as one of the cells)
 * selection happens withthe onClick prop, this just component just applies the visual aspect
 ******************/
const BigGrayCheckbox = ({ onClick, selected, data }) => {
  const onClickHandler = e => {
    onClick(e, selected, data)
  }

  if (selected) {
    return (
      <div className={css.selected} onClick={onClickHandler}>
        <img src={Checkmark} alt='x' />
      </div>
    )
  }

  return <div className={css.notSelected} onClick={onClickHandler} />
}

BigGrayCheckbox.propTypes = {
  selected: bool.isRequired,
  onClick: func.isRequired,
}

export default BigGrayCheckbox
