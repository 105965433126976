import { observable, action } from 'mobx'

//id is assigned to the drop zones
//this id should pair up with a drag target id
//when dragging draggingNode and draggingId need to be set

//in the d3 code's drag and drop handlers,
// developers will need to set:
//   draggingNode:  we are currently dragging
//   draggingType: this is the type of target/source that is being dragged (types.js)
//   draggingDetails: (optional) is data attatched to the dragged object
//   draggingNodeX and draggingNodeY: are the drag coordinates

// droppedSources can be referrenced to see what sources have been dropped
class SVGDropZoneStore {
  @observable draggingNode = false

  @observable draggingType = ''

  @observable draggingDetails = {}

  @observable draggingNodeX = 0

  @observable draggingNodeY = 0

  @observable inDropZone = new Map()

  @action
  clearZone = key => {
    this.inDropZone.delete(key)
    this.draggingNode = false
    this.draggingType = ''
    this.draggingDetails = {}
  }
}

export default new SVGDropZoneStore()
