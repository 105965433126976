import { sortBy } from 'lib/Sort'
import {
  ADD_WEB_APPLICATION,
  SET_CLAIMING_EVENTS,
  GOT_APPLICATIONS_WITH_COUNTS,
  RESET_APPLICATIONS,
  EVENTS_UPDATE,
  APPLICATION_DELETED,
  EDIT_APPLICATION,
  COUNT_UPDATE,
  UPDATE_INFO,
} from './Actions'

export const initialReducerState = Object.freeze({
  applications: [],
  updateClaimedFlag: false,
  claimingEvents: new Set(), //of applicationIDs
  editingApplication: null,
  updated: false,
})

const createApplicationRow = webApplication => {
  return {
    ...webApplication,
    pattern: webApplication.titlePattern ? webApplication.titlePattern : webApplication.urlPattern,
    loggedEvents: webApplication.loggedEvents || 0,
    type: webApplication.titlePattern ? 'Title' : 'URL',
  }
}

// removes appID if exist and adds appID if it doesn't from claimingEvents
const updateClaimingEvents = (appId, claimingEvents) => {
  const newClaimingEvents = new Set(claimingEvents)
  if (newClaimingEvents.has(appId)) newClaimingEvents.delete(appId)
  else newClaimingEvents.add(appId)

  return newClaimingEvents
}

export const reducer = (state, action) => {
  switch (action.type) {
    case RESET_APPLICATIONS: {
      return {
        ...state,
        applications: [...initialReducerState.applications],
      }
    }
    case GOT_APPLICATIONS_WITH_COUNTS: {
      const { applications } = action
      return {
        ...state,
        applications: applications.map(createApplicationRow) || [],
      }
    }
    case APPLICATION_DELETED: {
      const applications = state.applications.filter(application => application.id !== action.application.id)
      return {
        ...state,
        applications: applications,
        claimingEvents: updateClaimingEvents(action.application.id, state.claimingEvents),
        updateClaimedFlag: !state.updateClaimedFlag,
      }
    }
    case ADD_WEB_APPLICATION: {
      const { application } = action
      const newApplications = state.applications

      newApplications.push(createApplicationRow(application, []))
      return {
        ...state,
        claimingEvents: updateClaimingEvents(application.id, state.claimingEvents),
        updateClaimedFlag: !state.updateClaimedFlag,
        applications: newApplications.sort(sortBy('displayName')),
      }
    }
    case COUNT_UPDATE: {
      const applications = state.applications.map(application => {
        // find application in action.counts
        return {
          ...application,
          loggedEvents:
            action.counts[application.id] === null ? application.loggedEvents : action.counts[application.id],
        }
      })
      return {
        ...state,
        applications,
      }
    }
    case EVENTS_UPDATE: {
      const { application, loggedEvents, firstSeenAt } = action
      const newApplications = state.applications || []

      const index = newApplications.findIndex(({ id }) => id === application.id)
      if (index === -1) return state
      state.applications[index].loggedEvents = loggedEvents
      state.applications[index].firstSeenAt = firstSeenAt
      return {
        ...state,
        claimingEvents: updateClaimingEvents(state.applications[index].id, state.claimingEvents),
        updateClaimedFlag: !state.updateClaimedFlag,
        applications: newApplications,
      }
    }
    case SET_CLAIMING_EVENTS: {
      /* sets claimingEvents set */
      return {
        ...state,
        claimingEvents: updateClaimingEvents(action.appId, state.claimingEvents),
      }
    }
    case EDIT_APPLICATION: {
      const newApplications = state.applications
      const index = newApplications.findIndex(app => app.id === action.application.id)
      if (index === -1) return state
      newApplications[index] = createApplicationRow(action.application, [])
      return {
        ...state,
        applications: newApplications,
        editingApplication: null,
        updateClaimedFlag: !state.updateClaimedFlag,
      }
    }
    case UPDATE_INFO: {
      return {
        ...state,
        updated: !state.updated,
      }
    }
    default:
      return state
  }
}
