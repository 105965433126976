import React from 'react'
import { useFela } from '@fortressiq/fiq-ds'

import {
  metaTextCSS,
  metaLabelCSS,
  metaValueCSS,
  metaValueFilterWrapCSS,
  metaValueFilterCombinatorCSS,
} from './miningRunDetailsStyles'

const SECONDARY_SIG_LEVEL_MAP = {
  field: 'Field',
  value: 'Control Key',
  none: 'None',
}

const NestedSignaturesDetails = ({ mustIncludes }) => {
  const { css } = useFela()

  return (
    <div className={css(metaValueFilterWrapCSS)}>
      {mustIncludes.condition && <p className={css(metaValueFilterCombinatorCSS)}>{mustIncludes.condition}</p>}
      {mustIncludes.signatures &&
        mustIncludes.signatures.map((sig, i) => (
          <div key={`sig-${sig.value}-${i}`}>
            <div>
              <p className={css(metaTextCSS)}>
                <span className={css(metaLabelCSS)}>{sig.primary || ''} </span>
                <span className={css(metaLabelCSS)}>{sig.secondary ? `: ${sig.secondary}` : ''} </span>
                <span className={css(metaValueCSS)}>
                  {sig.secondary_signature_level ? `(${SECONDARY_SIG_LEVEL_MAP[sig.secondary_signature_level]})` : ''}
                </span>
              </p>
            </div>
          </div>
        ))}
      {mustIncludes.nested_signatures &&
        mustIncludes.nested_signatures.map((sig, i) => (
          <div key={`sig-${i}`}>
            <NestedSignaturesDetails mustIncludes={sig} />
          </div>
        ))}
    </div>
  )
}

export default NestedSignaturesDetails
