import React, { Fragment, useEffect, useCallback, useState } from 'react'
import { AdvancedCell, Badge, Button, defaultModalConfig, useFela, useModal, Select } from '@fortressiq/fiq-ds'
import { Link } from 'react-router-dom'
import filesize from 'filesize'
import { parse } from 'query-string'

import { useFetch } from 'lib/hooks'
import Tags from 'components/tags/Tags'

import Can, { roleActionCheck } from 'components/can/Can'
import TrinityTable from 'components/TrinityTable'

import { useUserState } from 'context/UserContext'

import { useHeaderDispatch } from 'app/header/HeaderContext'

import DataMetricsCards from './subComponents/DataMetricsCards'
import downloadCSV from './common/downloadCSV'
import { constructStats, timeOptions } from './common/Util'

import ActionsMenu from './subComponents/ActionsMenu'
import FirstDetected from './subComponents/FirstDetected'
import LastDetected from './subComponents/LastDetected'
import MiniSparkByte from './subComponents/MiniSparkByte'
import MiniSparkEvent from './subComponents/MiniSparkEvent'
import ConfigureModal from './ConfigureModal'

import { overviewContainerCSS } from './styles/index.js'

const sortKeyMap = {
  firstSeenAt: 'first_seen_at',
  lastSeenAt: 'last_seen_at',
  user: 'windows_username',
}

const observersModalConfig = {
  config: {
    ...defaultModalConfig,
    interactWithKeyboard: {
      closeOnEnter: false,
      closeOnEscape: false,
    },
  },
  footer: false,
  style: { maxWidth: '80%', width: '640px' },
  bodyStyle: { maxHeight: '80vh', overflow: 'scroll' },
}

const Observers = ({ location: { search } }) => {
  const [tenantObserverSettings, setTenantObserverSettings] = useState({})
  const [statsRange, setStatsRange] = useState(timeOptions[120].value)
  const [observerCount, setObserverCount] = useState(0)
  const [reFetch, setRefetch] = useState(0)
  const { addModal, removeModal } = useModal()
  const { roles } = useUserState()
  const modalID = 'configure-observers-modal'
  const { css } = useFela()
  const configureObserversText = `Configure ${observerCount} ${observerCount === 1 ? 'Observer' : 'Observers'}`

  const { data: { integrations = [] } = {}, isLoadingIntegrations } = useFetch('/dynamic_integrations/observer/list')

  const constructData = useCallback(
    data => {
      setTenantObserverSettings(data.tenantObserverSettings)
      return data.observers.map(item => {
        const statsByDay = item.statsByDay ? item.statsByDay : {}
        const observerStats = constructStats(statsByDay)
        return {
          ...item,
          dataCollected: { data: observerStats.sizes, total: filesize(item.stats.bytes) },
          eventsCollected: { data: observerStats.events, total: item.stats.events },
        }
      })
    },
    [setTenantObserverSettings]
  )

  const headerDispatch = useHeaderDispatch()
  useEffect(() => {
    const timeRangeOptions = Object.values(timeOptions)
    const selectOptions = []
    timeRangeOptions.map(t => selectOptions.push(t))
    let selectedOption

    timeRangeOptions.map(t => {
      if (t.value === statsRange) {
        selectedOption = t
      }

      return null
    })

    headerDispatch({
      type: 'set',
      heading: 'Observer Activity',
      title: 'Observer Activity',
      toolbarActions: (
        <Select
          defaultValue={selectedOption}
          key='stats-range-picker'
          onChange={({ value }) => setStatsRange(value)}
          options={selectOptions}
          style={{ width: '200px' }}
        />
      ),
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
      removeModal(modalID)
    }
  }, [headerDispatch, statsRange, modalID, removeModal])

  const openConfigModal = () => {
    let observersFilter = {}

    if (parse(search)?.globalFilter) {
      observersFilter = JSON.parse(parse(search).globalFilter)
    }
    addModal({
      children: (
        <ConfigureModal
          filter={observersFilter}
          observerCount={observerCount}
          observerId={null}
          onOk={removeModal}
          onCancel={removeModal}
        />
      ),
      ...observersModalConfig,
      header: configureObserversText,
      id: modalID,
    })
  }

  const getObserverCount = data => setObserverCount(data.totalCount)

  const sharedChartCellProps = {
    disableResizing: true,
    disableSortBy: true,
    cellStyle: { alignItems: 'flex-start', flexDirection: 'column' },
    width: 216,
  }
  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
      width: 70,
      minWidth: 70,
      Cell: ({ value }) => (
        <AdvancedCell copyTextOverride={value}>
          <Link to={`/observers/${value}/`}>{value}</Link>
        </AdvancedCell>
      ),
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({
        value,
        row: {
          original: { id },
        },
      }) => (
        <AdvancedCell copyTextOverride={value}>
          <Link to={`/observers/${id}/`}>{value}</Link>
        </AdvancedCell>
      ),
    },
    {
      Header: 'Machine',
      accessor: 'machine',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
    {
      accessor: 'user',
      Header: 'User',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
    {
      Header: 'Tags',
      accessor: 'tags',
      disableSortBy: true,
      Cell: ({
        value: tags,
        row: {
          original: { id },
        },
      }) => <Tags key={`tags-${id}`} taggableType='observer' taggableId={id} tags={tags} />,
    },
    {
      Header: 'Date Connected',
      accessor: 'firstSeenAt',
      Cell: ({
        value,
        row: {
          original: { status },
        },
      }) => FirstDetected({ firstSeenAt: value, status: status }),
    },
    {
      Header: 'Last Detected',
      accessor: 'lastSeenAt',
      Cell: ({ value }) => <LastDetected lastSeenAt={value} />,
    },
    {
      Header: 'Total Data Collected',
      accessor: 'dataCollected',
      Cell: ({ row: { id }, value }) => (
        <MiniSparkByte data={value.data} id={`min-total-collected-sparkline-${id}`} total={value.total} />
      ),
      ...sharedChartCellProps,
    },
    {
      Header: 'Total Events',
      accessor: 'eventsCollected',
      Cell: ({
        row: {
          id,
          original: { name },
        },
        value,
      }) => (
        <MiniSparkEvent
          observerName={name}
          data={value.data}
          id={`mini-total-events-sparkline-${id}`}
          total={value.total}
        />
      ),
      ...sharedChartCellProps,
    },
    {
      Header: 'Recording',
      accessor: 'neoActive',
      width: 120,
      disableSortBy: true,
      Cell: ({ value: neoActive }) =>
        neoActive ? (
          <Fragment>
            <Badge status='success' />
            Enabled
          </Fragment>
        ) : (
          <Fragment>
            <Badge status='default' />
            Disabled
          </Fragment>
        ),
    },
    ...(roleActionCheck('/observer:configure-neo', roles)
      ? [
          {
            Header: 'Actions',
            accessor: 'actions',
            width: 75,
            align: 'center',
            disableSortBy: true,
            cellStyle: { overflow: 'visible' },
            Cell: ({
              row: {
                index: rowIndex,
                original: { id, neoActive, user, name },
              },
              updateCellData,
            }) => (
              <ActionsMenu
                id={id}
                integrations={integrations}
                isLoadingIntegrations={isLoadingIntegrations}
                name={name}
                neoActive={neoActive}
                observersModalConfig={observersModalConfig}
                reFetch={() => setRefetch(reFetch + 1)}
                rowIndex={rowIndex}
                setTenantObserverSettings={setTenantObserverSettings}
                tenantObserverSettings={tenantObserverSettings}
                updateCellData={updateCellData}
                user={user}
              />
            ),
          },
        ]
      : []),
  ]

  return (
    <Fragment>
      <div className={css(overviewContainerCSS)}>
        <DataMetricsCards statsRange={statsRange} />
      </div>
      <TrinityTable
        toolbarActions={[
          <Can key='configure-observers' perform='/observer:configure-neo'>
            <Button disabled={observerCount < 1} icon='pencil' onClick={openConfigModal}>
              {configureObserversText}
            </Button>
          </Can>,
          <Button key='download-csv' icon='downloadbutton' onClick={() => downloadCSV(search)} type='secondary'>
            Download CSV
          </Button>,
        ]}
        reFetch={reFetch}
        getTableData={getObserverCount}
        globalFilterOptions={['observersTags', 'observers', 'user']}
        sortKeyMap={sortKeyMap}
        columns={columns}
        url='/observers'
        constructData={constructData}
        tableId='observers-table'
      />
    </Fragment>
  )
}

Observers.propTypes = {}

export default Observers
