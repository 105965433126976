/* eslint-disable import/prefer-default-export */

export const fixedHeaderCSS = ({ theme }) => ({
  background: theme.white,
  position: 'sticky',
  top: 0,
  zIndex: theme['z-base-above'],
})

export const fixedHeaderRowCSS = () => ({
  position: 'relative',
  width: '100%',
})
