/* eslint-disable import/no-cycle */
import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useGetSetState } from 'react-use'

import api from 'lib/Api'
import uiStore from 'lib/UiStore'

import LoadingContainer from 'components/LoadingContainer'
import IntegrationsDropdown from 'components/IntegrationsDropdown'
import NotFound from 'components/404/NotFound'

import { useHeaderDispatch } from '../../header/HeaderContext'
import CreateTenantButton from './CreateTenantButton'
import ManageAccount from './ManageAccount'
import ManageCredits from './ManageCredits/ManageCredits'
import TenantEditorNavigation from './TenantEditorNavigation'
import { AGGREGATION_STRATEGIES } from './constants'

const TenantEditor = ({ id = uiStore.tenant.id }) => {
  const [isLoading, setIsLoading] = useState(true)
  const headerDispatch = useHeaderDispatch()
  const [get, setState] = useGetSetState({
    aggregationFrequency: AGGREGATION_STRATEGIES[0].value,
    isActive: false,
    name: '',
    uuid: '',
    appUrl: '',
    smtpHost: '',
    smtpPort: '',
    smtpUsername: '',
    smtpPassword: '',
    emailFromAddress: '',
    privacySettings: uiStore.tenant.privacySettings,
    coeIntakeUrl: '',
  })
  const { id: tenantId = id, page } = useParams()

  useEffect(() => {
    async function fetchTenant() {
      const { data } = await api.get(`/tenants/${tenantId}`)
      const { name } = data

      setState(data)
      headerDispatch({
        type: 'set',
        heading: `${name} Settings`,
        title: `Tenants: ${name}`,
        toolbarActions: [
          <CreateTenantButton key='create-tenant-button' />,
          <IntegrationsDropdown key='event-integrations' integrationType='tenant' objectId={tenantId} />,
        ],
      })
      setIsLoading(false)
    }

    if (page === 'credits' || page === 'account' || page === 'edit' || page === undefined) {
      fetchTenant()
      return () => {
        headerDispatch({
          type: 'clear',
        })
      }
    }
  }, [tenantId, headerDispatch, page, setState])

  let content = null
  switch (page) {
    case 'credits':
      content = (
        <LoadingContainer loading={isLoading}>
          <ManageCredits />
        </LoadingContainer>
      )
      break
    case 'account':
    case 'edit':
    case undefined:
      content = (
        <LoadingContainer loading={isLoading}>
          <ManageAccount
            tenantId={tenantId}
            defaultTenant={{ ...get() }}
            setDefaultTenant={setState}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            allowAllSettings={page === 'account'}
          />
        </LoadingContainer>
      )
      break
    default:
      return <NotFound />
  }

  return (
    <Fragment>
      <TenantEditorNavigation id={tenantId} page={!page || page === 'edit' ? 'account' : page} />
      {content}
    </Fragment>
  )
}

TenantEditor.propTypes = {
  id: PropTypes.number,
}

export default TenantEditor
