/* eslint-disable import/prefer-default-export */
import { theme } from '@fortressiq/fiq-ds'

export const flowControlContainerCSS = () => ({
  background: theme?.white,
  borderBottomLeftRadius: theme['border-radius-base'],
  borderBottomRightRadius: theme['border-radius-base'],
  boxShadow: '1px 0 6px 0 hsla(0, 0%, 19.6%, 0.1)',
  left: `-${theme['default-spacer-sm']}`,
  padding: theme['default-spacer-md'],
  position: 'absolute',
  top: '5px',
  width: '320px',
})

export const flowControlSliderCSS = () => ({
  flex: '1',
  margin: `${theme['default-spacer-md']} ${theme['default-spacer-sm']} 0 0`,
  padding: `${theme['default-spacer-xs']} 0`,
})
