import renderApplicationIcon from 'components/applicationIcon/ApplicationIconD3.js'
import { theme } from '@fortressiq/fiq-ds'

import css from '../../processExploration.scss'

import { ACTIONS } from '../../common/constants'

import store from '../../../stores/processExplorerStore'

export default ({ nodeEnter, iconWidth }) => {
  const node = nodeEnter
    .append('g')
    .attr('class', 'activityNodeGroup')
    .attr('transform', 'translate(0, 0)')
    .style('display', d => {
      return d.data.activities.length < 2 ? 'block' : 'none'
    })

  // application avatar
  const appIconContainer = node
    .append('g')
    .attr('class', d => `appIconG nodeId_${d.data.id}`)
    .attr('transform', 'translate(25, 22.5)')
    .style('display', d => {
      if (d.data.type === 'start') return 'none'
      return 'block'
    })

  renderApplicationIcon({
    iconContainer: appIconContainer,
    applicationIndex: 0,
    radius: 18,
  })

  const controlGroup = node
    .append('g')
    .attr('class', 'controls')
    .style('display', d => {
      if (d.data.type === 'start') return 'none'
      return 'block'
    })

  // Add labels for the nodes
  const activityName = controlGroup
    .append('foreignObject')
    .attr('width', store.nodeWidth)
    .attr('height', d => {
      return d.data.type !== 'collapse' ? 120 : 30
    })
    .attr('x', 50)
    .attr('y', 1)
    .attr('class', 'eventFO')

  activityName
    .append('xhtml:div')
    .attr('class', `eventFOhtml ${css.eventName}`)
    .html(d => {
      return d.isRequired === true
        ? `<span class='${css.required}'>*</span> <span>${d.data.name || '--'}</span>`
        : d.data.name || '--'
    })

  // /******* action icon ******/
  const actionIcon = node
    .append('g')
    .attr('class', 'actionIconG')
    .attr('transform', 'translate(58, 30.5)')
    .style('display', d => {
      if (d.data.type === 'start' || d.data.type === 'end') return 'none'
      return 'block'
    })

  actionIcon.append('circle').attr('r', 7).style('fill', theme['legacy-color-gray5'])

  actionIcon
    .append('text')
    .attr('class', 'actionText')
    .attr('fill', '#fff')
    .style('font-size', '8px')
    .style('font-weight', '700')
    .attr('x', -3.5)
    .attr('y', 2.5)
    .text(d => {
      return d.data.activities.length > 0 && d.data.activities[0].action ? ACTIONS[d.data.activities[0].action] : '--'
    })

  /*** frequency group ***/
  const freqGroup = controlGroup.append('g')

  freqGroup
    .append('rect')
    .attr('class', 'nodeFreq')
    .attr('width', '50')
    .attr('height', '17')
    .attr('rx', '9')
    .attr('ry', '9')
    .attr('x', 49)
    .attr('y', 22)
    .style('stroke', theme['legacy-color-gray3'])
    .style('fill', theme['legacy-color-gray3'])

  freqGroup
    .append('text')
    .attr('x', 70)
    .attr('y', 35)
    .style('fill', '#fff')
    .style('font-size', '10')
    .text(d => d.data.value)

  //******* viewing indicator ******/
  controlGroup
    .append('circle')
    .attr('class', 'viewingDot')
    .attr('r', 4)
    .attr('cx', store.nodeWidth + (iconWidth - (iconWidth - store.nodeSizes.normal.height) * 2))
    .attr('cy', 12)
    .style('fill', d => {
      return store.viewingNode && store.viewingNode.id === d.id ? '#000' : 'transparent'
    })
}
