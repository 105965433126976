import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { combineRules, Select, useFela } from '@fortressiq/fiq-ds'
import { useMap, usePrevious, useUpdateEffect } from 'react-use'
import classNames from 'classnames/bind'

import { multipleOperators } from 'constants/event-constants'

const QueryBuilderSelect = ({
  className = '',
  options: optionsProp,
  title,
  handleOnChange: onChange,
  value: valueProp,
  operator,
  isFieldValue,
  style,
  isLoading,
  ...props
}) => {
  const options = optionsProp?.map(({ label, name, value }) => ({ label, value: name || value }))
  const [currentValue, { setAll }] = useMap(undefined)
  const mode = isFieldValue && multipleOperators.includes(operator) ? 'multiple' : null
  const isMulti = mode === 'multiple'

  const { css, theme } = useFela()

  const prevIsMulti = usePrevious(mode)

  const optionsLength = options?.length

  useEffect(() => {
    if (!isLoading && optionsLength) {
      if (isMulti) {
        if (valueProp === '') {
          setAll([])
        } else {
          const selectedArr = valueProp?.split(',')
          setAll(options.filter(option => selectedArr.includes(option.value)))
        }
      } else {
        setAll(options.filter(option => valueProp === option.value)[0])
      }
    }
    // using optionsLength instead of options as dependency to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProp, setAll, isMulti, optionsLength, isFieldValue, isLoading, mode])

  const handleOnChange = useCallback(
    e => {
      setAll(e)
      if (isMulti && isFieldValue) {
        const stringVals = e.map(({ value: v }) => v)
        onChange(stringVals.join())
      } else {
        onChange(e.value)
      }
    },
    [isMulti, onChange, setAll, isFieldValue]
  )

  useUpdateEffect(() => {
    if (!isMulti && prevIsMulti && currentValue?.length && currentValue?.length !== 1) {
      const firstSelected = currentValue[0]
      onChange(firstSelected.value)
    }
  }, [mode])

  const classes = classNames(
    className,
    css(combineRules({ marginBottom: theme['default-spacer-sm'], minWidth: '127px' }, style))
  )

  const portalProps = {
    menuPortalTarget: document.body,
    menuShouldBlockScroll: true,
  }

  return (
    <Select
      className={classes}
      isLoading={isLoading}
      isMulti={isMulti}
      onChange={handleOnChange}
      options={options}
      placeholder={title}
      value={currentValue}
      {...props}
      {...portalProps}
      styles={{
        ...props?.styles,
        menu: { zIndex: theme['z-modal-above'], ...props?.styles?.menu },
        menuPortal: { zIndex: theme['z-modal-above'], ...props?.styles?.menuPortal },
      }}
    />
  )
}

QueryBuilderSelect.defaultProps = {
  disabled: false,
  isFieldValue: false,
  isLoading: false,
  value: '',
}

QueryBuilderSelect.propTypes = {
  disabled: PropTypes.bool,
  handleOnChange: PropTypes.func.isRequired,
  isFieldValue: PropTypes.bool,
  isLoading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default QueryBuilderSelect
