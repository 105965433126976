import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import { Element, IconToggleArrow, theme, Typography } from '@fortressiq/fiq-ds'

import AddVisCard from './AddVisCard'

const { Title } = Typography

@observer
class DashRow extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      opened: true,
    }
  }

  render() {
    const { opened } = this.state
    const { children, title, type } = this.props
    return (
      <Element style={{ width: '100%' }}>
        <Fragment>
          <Title
            level={5}
            style={{
              color: theme['legacy-dash-gray'],
              display: 'inline-block',
              fontSize: theme['font-size-base'],
              letterSpacing: '1px',
              marginLeft: theme['default-spacer-sm'],
              marginRight: theme['default-spacer-xs'],
              position: 'relative',
              textTransform: 'uppercase',
            }}
          >
            {title}
          </Title>
          <AddVisCard type={type} />
          <IconToggleArrow
            opened={opened}
            onClick={() => this.setState({ opened: !opened })}
            style={{ position: 'relative', top: theme['default-spacer-xs'] }}
          />
        </Fragment>
        {opened && (
          <Element
            style={{
              alignItems: 'stretch',
              animationDuration: '0.5s',
              animationName: {
                '0%': {
                  opacity: 0,
                },
                '100%:': {
                  opacity: 1,
                },
              },
              animationTimingFunction: 'ease-in-out',
              display: 'flex',
              flexDirection: 'row',
              flexGrow: '1',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            }}
          >
            {children}
          </Element>
        )}
      </Element>
    )
  }
}

export default DashRow
