import React, { useEffect, memo, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import { DateCell, AdvancedCell, Button } from '@fortressiq/fiq-ds'

import EditableCell from 'components/TrinityTable/EditableCell'

import { useFetch } from 'lib/hooks'
import uiStore from 'lib/UiStore'
import callbackStore from 'stores/callback'

import api from 'lib/Api'
import Tags from 'components/tags/Tags'
import TrinityTable, { columnWidths } from 'components/TrinityTable'

import StatusBadge from 'components/StatusBadge/StatusBadge'
import { generateURLQueryfromRules } from 'components/query_builder/utils'

import ApplicationIconCell from './ApplicationIconCell'
import ActionsMenu from './ActionsMenu'

import { useHeaderDispatch } from '../header/HeaderContext'

const defaultName = 'NULL'

const isLite = uiStore?.tenant?.isLite

const EventSearchLink = ({ processName, count }) => {
  const query = generateURLQueryfromRules({
    rules: [
      {
        field: 'application_name',
        value: processName,
        operator: '=',
      },
    ],
  })

  return count ? (
    <Link
      onClick={event => {
        event.stopPropagation()
      }}
      to={`/events?${query}`}
    >
      {count}
    </Link>
  ) : (
    '0'
  )
}

const sortKeyMap = {
  displayName: 'displayName',
  id: 'application.id',
  firstSeenAt: 'firstSeenAt',
  winProcessName: 'application.winProcessName',
  pattern: 'urlPattern,titlePattern',
  loggedEvents: 'loggedEvents',
}

const callbackId = 'startStopRankingsProgress'

const ApplicationRegistry = () => {
  const headerDispatch = useHeaderDispatch()
  const [reFetch, setReFetch] = useState(1)

  const handleReFetch = () => {
    setReFetch(reFetch + 1)
  }

  const { data: { integrations = [] } = {}, isLoadingIntegrations } = useFetch('/dynamic_integrations/application/list')

  const tableRef = useRef(null)

  useEffect(() => {
    const pageTitle = 'Application Registry'
    headerDispatch({
      type: 'set',
      title: pageTitle,
      heading: pageTitle,
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  useEffect(() => {
    const onReportProgress = ({ applicationId, status }) => {
      tableRef.current.updateRowData('applicationId', applicationId, { reportStatus: status })
    }
    callbackStore.add(onReportProgress, callbackId, true)
    return () => {
      callbackStore.remove(callbackId)
    }
  }, [])

  const columns = [
    {
      accessor: 'iconUrl',
      align: 'center',
      disableSortBy: true,
      Header: 'Icon',
      minWidth: 56,
      width: 56,
      Cell: ({
        value: iconUrl,
        row: {
          original: { id, displayName, applicationId },
        },
      }) => (
        <ApplicationIconCell
          key={id}
          applicationId={applicationId}
          imageUrl={iconUrl}
          displayName={displayName || defaultName}
          applicationUUID={id}
        />
      ),
    },
    {
      accessor: 'id',
      align: 'center',
      Header: 'ID',
      minWidth: columnWidths.id,
      width: columnWidths.id,
      Cell: ({
        value,
        row: {
          original: { applicationId },
        },
      }) => (isLite ? value : <Link to={`/applications/${value}`}>{applicationId}</Link>),
    },
    {
      Header: 'Display Name',
      accessor: 'displayName',
      Cell: ({
        value,
        row: {
          index,
          original: { id, applicationId },
        },
        column: { id: columnId },
        updateCellData,
      }) => (
        <EditableCell
          key={`name-${id}`}
          value={value}
          id={id}
          columnId={columnId}
          rowIndex={index}
          updateCellData={updateCellData}
          updateData={newValue =>
            api.put(`/applications/${id}`, {
              application_id: applicationId,
              applicationUuid: id,
              display_name: newValue,
            })
          }
        />
      ),
    },
    {
      Header: 'Window Process Name',
      accessor: 'winProcessName',
      width: 190,
      Cell: ({ value }) => (value ? <AdvancedCell>{value}</AdvancedCell> : '--'),
    },
    {
      Header: 'Tags',
      accessor: 'tags',
      disableSortBy: true,
      Cell: ({
        value,
        row: {
          original: { id },
        },
      }) => <Tags key={`tags-${id}`} taggableType='application' taggableId={id} tags={value} />,
    },
    {
      Header: 'First Seen',
      accessor: 'firstSeenAt',
      Cell: ({ value }) => <DateCell timestamp={value} />,
    },
    {
      Header: 'Events',
      accessor: 'loggedEvents',
      width: 70,
      Cell: ({
        value,
        row: {
          original: { displayName },
        },
      }) => <EventSearchLink processName={displayName} count={value} />,
    },
    {
      Header: 'Pattern',
      accessor: 'pattern',
      Cell: ({
        row: {
          original: { titlePattern, urlPattern },
        },
      }) => (urlPattern || titlePattern ? <AdvancedCell>{urlPattern || titlePattern}</AdvancedCell> : '--'),
    },
    ...(!isLite
      ? [
          {
            Header: 'Boundary Status',
            accessor: 'reportStatus',
            align: 'center',
            disableSortBy: true,
            width: 120,
            Cell: ({ value }) => (value ? <StatusBadge status={value} /> : '--'),
          },
        ]
      : []),
    {
      align: 'center',
      Header: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      width: 70,
      Cell: ({ row: { original } }) => (
        <ActionsMenu
          key={`action-menu-${original.id}`}
          applicationId={original.applicationId}
          reportId={original.reportMiningRunId}
          processBoundaryStatus={original.reportStatus}
          integrations={integrations}
          isLoadingIntegrations={isLoadingIntegrations}
          handleReFetch={handleReFetch}
        />
      ),
    },
  ]

  return (
    <TrinityTable
      reFetch={reFetch}
      globalFilterOptions={['applications_service', 'applicationIds_service', 'applicationsTags_service']}
      columns={columns}
      url='/applications'
      dataKey='applications'
      sortKeyMap={sortKeyMap}
      ref={tableRef}
      toolbarActions={[
        <Button key='web-application-link' href='/web-applications' type='secondary'>
          Define New Web Application
        </Button>,
      ]}
      tableId='applications-table'
    />
  )
}

export default memo(ApplicationRegistry)
