import React from 'react'
import { Element, useFela } from '@fortressiq/fiq-ds'

const Main = ({ className = '', noPadding, ...props }) => {
  const { css, theme } = useFela()

  const classes = `${css({
    padding: noPadding ? 0 : theme['default-spacer-lg'],
  })}${className ? ` ${className}` : ''}`

  return <Element className={classes} {...props} />
}

export default Main
