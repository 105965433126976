import React from 'react'
import { Button, Icon } from '@fortressiq/fiq-ds'

const RulesRefreshButton = ({ onClick, claimingEvents, application: { id } }) => {
  const isSpinning = () => claimingEvents.has(id)

  return (
    <Button onClick={onClick} size='small' tooltipProps={{ title: 'Claim additional events using this rule.' }}>
      <Icon size='nested' icon='refresh' spin={isSpinning()} />
    </Button>
  )
}
export default RulesRefreshButton
