/* eslint-disable import/prefer-default-export */
import api from 'lib/Api'

import User from 'lib/UiStore'

const fetchProgram = async () => {
  const { appName, entId, programId, region } = User.shibumi
  const { data } = await api.post('/tenants/coe_graphql', {
    appName,
    entId,
    programId,
    region,
    apiName: 'query_program',
  })
  return data
}

const fetchDropdowns = async () => {
  const { appName, entId, programId, region } = User.shibumi
  const { data } = await api.post('tenants/coe_graphql', {
    appName,
    entId,
    programId,
    region,
    apiName: 'query_dropdowns',
  })
  return data
}

export const fetchFormQuestions = async () => {
  const programsPromise = fetchProgram()
  const dropdownsPromise = fetchDropdowns()
  const results = await Promise.all([programsPromise, dropdownsPromise])
  return results
}

const sendForm = async (form, autopilotUUID) => {
  const { appName, entId, programId, region } = User.shibumi
  const attributes = Object.entries(form)
    .map(([key, value]) => {
      // the following are not accepted attributes, but exist in the form...
      if (key === 'pdd_name') return
      if (key === 'Workstream_Path__c') return

      // expects a percentage value
      if (key === 'of_Tasks_that_can_be_Automated__c') {
        value = (value / 100).toString()
      }

      const attribute = {}
      attribute.attribute = key
      attribute.value = value
      return attribute
    })
    .filter(item => item)

  attributes.push({ attribute: 'PD_UUID__c', value: autopilotUUID })

  const { data } = await api.post('tenants/coe_graphql', {
    appName,
    entId,
    programId,
    region,
    workstreamId: form.Workstream_Path__c,
    parameters: JSON.stringify(attributes),
    apiName: 'submit_form',
  })
  return data
}

const sendPDD = async (opportunityData, autopilotUUID) => {
  const { data } = await api.post('/opportunities/upload', {
    AutoPilot_UUID: autopilotUUID,
    Opportunity_ID: opportunityData?.data?.createWorkItem?.id,
  })
  return data
}

export const postCoeIntakeForm = async (form, autopilotUUID) => {
  let response
  sendForm(form, autopilotUUID).then(data => {
    response = sendPDD(data, autopilotUUID)
  })
  return response
}
