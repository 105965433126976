import React, { Fragment } from 'react'
import keydown from 'react-keydown'

import debounce from 'lodash/debounce'

import { observer } from 'mobx-react'
import ReactGA from 'react-ga'

import qs from 'qs'

import { Button, Element, Heading, Toolbar, SplitPane } from '@fortressiq/fiq-ds'

import BigGrayCheckbox from 'components/toggles/BigGrayCheckbox'
import { FLASH_LEVELS } from 'components/flash/Flash'
import VirtualizedTable from 'components/table/VirtualizedTable'
import Spinner from 'components/loaders/Spinner'
import { isOneOf } from 'components/user/util'

import api from 'lib/Api'

import { useUserState } from 'context/UserContext'

import Can, { roleActionCheck } from 'components/can/Can'
import DisabledScreenshot, { isDisabledScreenshot } from 'components/DisabledScreenshot/DisabledScreenshot'
import flashHOC from 'components/flash/flashHOC'
import UnredactedImageViewerWithOption from 'components/image/UnredactedImageViewerWithOption'

import AnnotationTopBar from './AnnotationTopBar'
import ExportDropdown from './ExportDropdown'
import EventFilter from './controls/EventFilter'
import PlaybackControls from './controls/PlaybackControls'
import EventDetailBar from './EventDetailBar'
import ProcessControls from './ProcessControls'
import ProcessModal from './ProcessModal'
import SignatureDetailsBar from './SignatureDetailsBar'

import { HeaderDispatchContext } from '../header/HeaderContext'

import css from './instancesViewerStyle.scss'
import InstancesFilter from './controls/InstancesFilter'
import MachineFilter from './controls/MachineFilter'
import PathFilter from './controls/PathFilter'
import AnnotateButton from './controls/AnnotateButton'

import { observedAtRenderer } from '../events/EventColumns'

import { APIS, getFlowInstances, getGraphInstances, getFlowPaths, getGraphPaths } from './dataUtils'

const signatureRenderer = data => {
  const { cellData } = data
  return (
    <Element as='span' style={{ whiteSpace: 'pre' }}>
      {cellData}
    </Element>
  )
}

const headerVars = ['graphProcessId', 'fetchingProcesses', 'graphOptions']
const toolbarVars = ['graphOptions', 'machines', 'paths', 'instances', 'instanceIndex', 'playing', 'events']

@observer
class InstancesViewer extends React.Component {
  static contextType = HeaderDispatchContext

  constructor(props) {
    super(props)
    this.debouncedGetEvents = null
    this.leftColRef = React.createRef()
    this.screenRef = React.createRef()

    const {
      location: { search },
      roles,
    } = this.props

    const params = qs.parse(search, { ignoreQueryPrefix: true })
    const { graphType } = params

    this.state = {
      events: [],
      selectedEventIndex: 0,
      showProcessModal: false,
      processInstances: [],
      selectedInstanceOption: null,
      eventsInProcess: {},
      fetchingTable: false,
      eventsSelectedCount: 0,
      showAnnotationTools: false,
      rightColSize: '100%',
      screenHeight: '100%',
      playing: false,
      instances: [],
      instanceIndex: null,
      fetchingProcesses: false,
      machines: [],
      graphOptions: [],
      paths: [],
      allInstances: [],
      graphType: graphType || 'path',
    }

    this.columns = [
      { width: 40, visible: true, dataKey: 'sequence', header: 'Order' },
      { width: 80, visible: true, dataKey: 'title', header: 'Title' },
      { width: 100, visible: true, dataKey: 'application', header: 'App' },
      { width: 80, visible: true, dataKey: 'action', header: 'Action' },
      isOneOf(roles, ['admin', 'superuser']) && {
        width: 100,
        visible: true,
        dataKey: 'value',
        header: 'Value',
      },
      { width: 80, visible: false, dataKey: 'machine', header: 'Machine' },
      { width: 100, visible: false, dataKey: 'observedAt', header: 'Observed At', cellRenderer: observedAtRenderer },
      {
        width: 320,
        visible: true,
        dataKey: 'observer',
        header: 'Observer Name',
      },
      { width: 40, visible: true, dataKey: 'annotationType', header: 'Ann' },
      { width: 100, visible: false, dataKey: 'screen', header: 'Screen' },
      { width: 130, visible: true, dataKey: 'field', header: 'Field' },
      {
        width: 320,
        visible: false,
        dataKey: 'signature',
        header: 'Signature',
        cellRenderer: signatureRenderer,
      },
      {
        width: 320,
        visible: false,
        dataKey: 'screenSignature',
        header: 'Screen Signature',
        cellRenderer: signatureRenderer,
      },
      {
        width: 50,
        dataKey: 'eventsInProcess',
        visible: false,
        cellRenderer: this.cellRenderer,
        menuName: 'Include in Process',
        columnType: VirtualizedTable.columnType.action,
        headerRenderer: header => this.eventsInProcessHeader(header),
      },
    ].filter(Boolean)
  }

  componentDidMount() {
    const {
      match: {
        params: { id: graphProcessId },
      },
      location: { search },
      roles,
    } = this.props

    const params = qs.parse(search, { ignoreQueryPrefix: true })
    const { instanceId, graphType } = params

    this.setState({ graphType: graphType })
    this.setHeaderContext()
    this.setToolbarContext()

    // don't fetch if user doesn't have permission
    if (roleActionCheck('/instances-viewer:navigate-all-instances', roles)) this.fetchProcesses()

    if (graphProcessId) {
      this.fetchInstances(instanceId, true)
      this.fetchPaths()
    }
    window.addEventListener('resize', this.setScreenshotDimensions)
    this.setScreenshotDimensions()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: { id: graphProcessId },
      },
      location: { search },
    } = this.props

    const params = qs.parse(search, { ignoreQueryPrefix: true })
    const oldParams = qs.parse(prevProps.location.search, {
      ignoreQueryPrefix: true,
    })
    const { pathId, instanceId, graphType } = params

    if (prevState.graphType !== graphType && graphType) {
      this.setState({
        graphType: graphType,
      })
    }

    if (graphProcessId !== prevProps.match.params.id) {
      this.fetchInstances(instanceId, true)
      this.fetchPaths()
    } else if (pathId !== oldParams.pathId) {
      this.fetchInstances(instanceId, false)
    }

    const shouldUpdateHeader = headerVars.some(key => {
      const { [key]: val } = this.state
      return val !== prevState[key]
    })
    if (shouldUpdateHeader) {
      this.setHeaderContext()
    }

    const shouldUpdateToolbar = toolbarVars.some(key => {
      const { [key]: val } = this.state
      return val !== prevState[key]
    })
    if (shouldUpdateToolbar) {
      this.setToolbarContext()
    }
  }

  componentWillUnmount() {
    this.context({
      type: 'clear',
    })
    window.removeEventListener('resize', this.setScreenshotDimensions)
  }

  render() {
    const {
      events,
      selectedEventIndex,
      selectedEvent,
      showProcessModal,
      processInstances,
      fetchingTable,
      showAnnotationTools,
      rightColSize,
      screenHeight,
    } = this.state
    const splitPaneStyle = { position: 'static' }
    const images = selectedEvent
      ? [
          {
            centered: true,
            float: 'none',
            fluid: true,
            height: screenHeight,
            src: selectedEvent.screenshotUrl,
            svgInfo: {
              box: selectedEvent.bounding_box || selectedEvent.boundingBox,
              x: selectedEvent.coordX || selectedEvent.coord_x,
              y: selectedEvent.coordY || selectedEvent.coord_y,
            },
          },
        ]
      : null

    const screenshot = isDisabledScreenshot(selectedEvent) ? (
      <DisabledScreenshot />
    ) : (
      <UnredactedImageViewerWithOption
        clickImageToFullscreen={true}
        dark={false}
        images={images}
        maxHeight='100%'
        onLoad={this.playNext}
        openFullscreenOnLoad={false}
        selectedIndex={0}
        fullscreenEnabled={true}
        style={{
          alignItems: 'center',
          width: '100%',
        }}
      />
    )

    return (
      <div className={css.mainContainer} tabIndex={-1}>
        <SplitPane primary='first' split='vertical' style={splitPaneStyle} onChange={this.setRightPanelSize}>
          <div className={css.mainLeft} ref={this.leftColRef}>
            {fetchingTable && (
              <div className={css.tableLoaderContainer}>
                <Spinner />
              </div>
            )}
            <VirtualizedTable
              data={events}
              onRowClick={e => this.updateSelectedEventIndex(e.index)}
              selectedEventIndex={selectedEventIndex}
              columns={this.columns}
              tableContainerClassName={css.eventTable}
            />
            <ProcessControls
              instances={processInstances}
              addInstanceEnabled={this.instanceIsAnnotated()}
              addInstanceClick={this.addInstance}
              viewClick={() => {
                ReactGA.event({
                  category: 'Instances Viewer',
                  action: 'View Process Details',
                })
                this.setState({ showProcessModal: !showProcessModal })
              }}
              modalContent={
                <ProcessModal
                  instances={processInstances}
                  removeInstance={this.removeInstance}
                  saveProcess={this.saveProcess}
                />
              }
            />
          </div>

          <div className={css.mainRight}>
            <div className={css.rightTop}>
              <AnnotationTopBar
                selectedEvent={selectedEvent}
                selectNextScreenshot={this.selectNextScreenshot}
                selectPriorScreenshot={this.selectPriorScreenshot}
                updateSelectedEvent={this.updateSelectedEvent}
                showAnnotationTools={showAnnotationTools}
              />
            </div>
            <div className={css.rightMiddle} ref={this.screenRef}>
              {events.length === 0 ? (
                <Fragment>No events found, select a Process&hellip;</Fragment>
              ) : (
                <div className={css.screenshotContainer}>{selectedEvent && screenshot}</div>
              )}
            </div>
            <SignatureDetailsBar event={selectedEvent} parentWidth={rightColSize} />
            <EventDetailBar event={selectedEvent} parentWidth={rightColSize} />
          </div>
        </SplitPane>
      </div>
    )
  }

  onInstanceChange = async (newInstance, newInstances) => {
    let { instances } = this.state
    const { graphType } = this.state
    if (newInstances) {
      instances = newInstances
    }
    const instance = instances.find(i => i.index === newInstance)
    if (!instance) return

    const { data } = await api.post('/events/details', {
      event_ids: instance.eventIds,
    })

    const {
      match: {
        params: { id: graphProcessId },
      },
      history,
    } = this.props

    const { pathId } = this.state
    let search = '?'
    if (pathId) {
      search = `${search}pathId=${pathId}&`
    }
    search = `${search}instanceId=${instance.hash}&graphType=${graphType}`
    history.push({
      pathname: `/instances-viewer/${graphProcessId}`,
      search,
    })
    this.setState({ instanceIndex: newInstance })
    this.eventListChange(data.events, { value: newInstance.sequence })
  }

  toggleMachine = machine => {
    const { machines, allInstances } = this.state
    const newMachines = machines.map(m => {
      if (machine.name === m.name) {
        return {
          ...m,
          selected: !m.selected,
        }
      }
      return m
    })

    this.setState({ machines: newMachines })
    this.setDisplayInstances(allInstances, newMachines)
  }

  togglePath = pathInfo => {
    const {
      match: {
        params: { id: graphProcessId },
      },
      history,
    } = this.props

    const { pathId, graphType } = this.state

    let search = `?pathId=${pathInfo.id}&graphType=${graphType}`
    if (pathInfo.id === +pathId) {
      search = `?graphType=${graphType}`
    }

    history.push({
      pathname: `/instances-viewer/${graphProcessId}`,
      search,
    })
  }

  fetchProcesses = async () => {
    this.setState({ fetchingProcesses: true })
    const { data } = await api.get('/graph_processes/filters')
    this.setState({ fetchingProcesses: false, graphOptions: data, graphType: data.type })
  }

  fetchInstances = async (instanceId, updateMachines = true) => {
    const { machines, graphType } = this.state
    const {
      match: {
        params: { id: graphProcessId },
      },
      location: { search },
    } = this.props

    const params = qs.parse(search, { ignoreQueryPrefix: true })
    const { pathId } = params

    if (!graphProcessId) return
    this.setState({ fetchingTable: true, pathId })

    const args = {
      graphProcessId,
      pathId,
      limit: 3000,
    }

    const { data } = await api.get(APIS[graphType].instances(graphProcessId), args)

    const { machineNames, newInstances } = graphType === 'flowgraph' ? getFlowInstances(data) : getGraphInstances(data)

    const machinesArray = Array.from(machineNames).map(machineName => ({
      name: machineName,
      selected: updateMachines ? true : machines.find(machine => machine.name === machineName)?.selected,
    }))

    this.setState({
      allInstances: newInstances,
      instances: newInstances,
      fetchingTable: false,
      machines: machinesArray,
    })
    if (updateMachines) {
      this.setInstance(newInstances, instanceId)
    } else {
      this.setDisplayInstances(newInstances, machinesArray, instanceId)
    }
  }

  setInstance = (instances, sInstance) => {
    if (sInstance) {
      const id = parseInt(sInstance, 10)
      const index = instances.findIndex(instance => instance.hash === id && !instance.disabled)
      if (index !== -1) {
        this.onInstanceChange(index, instances)
        return
      }
    }
    const firstInstanceIndex = instances.findIndex(instance => !instance.disabled)
    if (firstInstanceIndex !== -1) {
      this.onInstanceChange(firstInstanceIndex, instances)
    } else {
      // no enabled instances, so reset state to blanks
      this.setState({
        events: [],
        eventsSelectedCount: 0,
        selectedEventIndex: 0,
        selectedInstanceOption: null,
        eventsInProcess: {},
      })
    }
  }

  setDisplayInstances = (allInstances, newMachines, instanceId) => {
    let { machines } = this.state
    if (newMachines) {
      machines = newMachines
    }
    // cross reference with machines
    const selectedMachines = machines.reduce((acc, machine) => {
      if (machine.selected) {
        acc.push(machine.name)
      }
      return acc
    }, [])
    const instances = allInstances.filter(instance => selectedMachines.includes(instance.observer))
    this.setState({ instances })

    this.setInstance(instances, instanceId)
  }

  fetchPaths = async () => {
    const { graphType } = this.state
    const {
      match: {
        params: { id: graphProcessId },
      },
    } = this.props

    const { data } = await api.get(APIS[graphType].paths(graphProcessId))
    const pathsData = graphType === 'flowgraph' ? getFlowPaths(data) : getGraphPaths(data)
    this.setState({
      paths: pathsData,
    })
  }

  setHeaderContext = () => {
    const { fetchingProcesses, graphOptions } = this.state
    const {
      match: {
        params: { id: graphProcessId },
      },
    } = this.props

    this.context({
      type: 'set',
      title: 'View Instances',
      heading: (
        <Fragment>
          <Heading className='header-title' level={1}>
            View Instances
          </Heading>
          <Can perform='/instances-viewer:navigate-all-instances'>
            <EventFilter
              clearInstances={this.clearInstances}
              eventListChange={this.eventListChange}
              fetchingProcesses={fetchingProcesses}
              graphOptions={graphOptions}
              graphProcessId={graphProcessId}
              setGraphProcessId={this.setGraphProcessId}
              setGraphType={currentGraphType => this.setState({ graphType: currentGraphType })}
            />
          </Can>
        </Fragment>
      ),
    })
  }

  setToolbarContext = () => {
    const { paths, machines, playing, events, instances, instanceIndex, graphOptions, pathId, graphType } = this.state
    const {
      match: {
        params: { id: graphProcessId },
      },
    } = this.props

    const graphProcess = graphOptions.find(({ value }) => value === +graphProcessId)
    const hasInstances = !!instances.length

    this.context({
      type: 'set',
      toolbar: (
        <Toolbar style={{ '> .fiqds-row': { flexFlow: 'nowrap' } }}>
          <MachineFilter machines={machines} toggleMachine={this.toggleMachine} />
          <PathFilter selectedPathId={pathId && +pathId} paths={paths} togglePath={this.togglePath} />
          <InstancesFilter
            instances={instances}
            instanceIndex={instanceIndex}
            onInstanceChange={this.onInstanceChange}
            disabled={!hasInstances}
          />
          <Can perform='/instances-viewer:annotate'>
            <AnnotateButton onToggleAnnotationTools={this.toggleAnnotationTools} />
          </Can>
          <PlaybackControls
            setPlaying={isPlaying => this.setState({ playing: isPlaying }, this.playNext)}
            playing={playing}
            eventCount={events.length}
          />
        </Toolbar>
      ),
      toolbarActions: !!(graphProcess && hasInstances && graphType !== 'flowgraph') && (
        <ExportDropdown
          graphId={graphProcess.value}
          name={graphProcess.label}
          instanceIndexes={[instanceIndex]}
          pathId={pathId}
        />
      ),
      title: 'View Instances',
    })
  }

  setGraphProcessId = graphProcessId => {
    const { history } = this.props
    history.push({
      pathname: `/instances-viewer/${graphProcessId}`,
    })
  }

  setScreenshotDimensions = debounce(() => {
    this.setRightPanelSize()
    this.setScreenHeight()
  }, 50)

  setRightPanelSize = () => {
    // uses the left column because react-split-pane resizes the left column
    // and lets the browser render the right
    const leftWidth = this.leftColRef.current.getBoundingClientRect().width
    const marginAndSlidingVBar = 64 + 8
    const rightWidth = `100vw - ${marginAndSlidingVBar}px - ${leftWidth}px`
    this.setState({ rightColSize: rightWidth })
  }

  setScreenHeight = () => {
    const { height } = this.screenRef.current.getBoundingClientRect()
    this.setState({ screenHeight: `${height}px` })
  }

  toggleAnnotationTools = (evt, toggled) => {
    this.setState({ showAnnotationTools: toggled })
  }

  toggleIncludeInProcess = (e, selected, rowData) => {
    const { eventsInProcess } = this.state
    eventsInProcess[rowData.rowData.id] = !selected

    const newCount = Object.values(eventsInProcess).reduce((acc, cur) => acc + Number(cur), 0)

    this.setState({
      eventsSelectedCount: newCount,
    })
  }

  toggleIncludeAllEvents = () => {
    const { eventsSelectedCount, eventsInProcess, events } = this.state
    const tempEventsInProcess = { ...eventsInProcess }
    let newCount = events.length

    if (eventsSelectedCount === events.length) {
      Object.keys(eventsInProcess).forEach(event => {
        tempEventsInProcess[event] = false
      })
      newCount = 0
    } else {
      events.forEach(event => {
        tempEventsInProcess[event.id] = true
      })
    }

    this.setState({
      eventsSelectedCount: newCount,
      eventsInProcess: tempEventsInProcess,
    })
  }

  cellRenderer = rowData => {
    const { eventsInProcess } = this.state
    return (
      <BigGrayCheckbox
        selected={eventsInProcess[rowData.rowData.id]}
        onClick={this.toggleIncludeInProcess}
        data={rowData}
      />
    )
  }

  eventsInProcessHeader = () => {
    const { eventsSelectedCount, events } = this.state

    return (
      <div className={css.checkboxHeader}>
        <BigGrayCheckbox selected={eventsSelectedCount === events.length} onClick={this.toggleIncludeAllEvents} />
      </div>
    )
  }

  removeInstance = instance => {
    const { processInstances } = this.state
    this.setState({
      processInstances: processInstances.filter(i => i.sequence !== instance.sequence),
    })
  }

  eventListChange = (events, selectedInstanceOption) => {
    const eventsInProcess = {}
    events.forEach(event => {
      eventsInProcess[event.id] = true
    })

    this.setState({
      events: events,
      eventsSelectedCount: events.length,
      selectedEventIndex: 0,
      selectedInstanceOption: selectedInstanceOption,
      eventsInProcess: eventsInProcess,
    })

    //setState is an async action and
    //will not nessearily update prior to the async getSelectedEventDetails
    const currentSelectedEvent = events[0]
    this.getSelectedEventDetails(currentSelectedEvent)
  }

  playNext = () => {
    const { playing, selectedEventIndex, events } = this.state
    const eventCount = events.length

    if (playing) {
      if (selectedEventIndex === eventCount - 1) {
        this.setState({ playing: false })
      } else {
        setTimeout(() => this.selectNextScreenshot(), 800)
      }
    }
  }

  selectNextScreenshot = () => {
    const { selectedEventIndex, events } = this.state
    this.updateSelectedEventIndex((selectedEventIndex + 1) % events.length, true)
  }

  selectPriorScreenshot = () => {
    const { selectedEventIndex, events } = this.state
    if (selectedEventIndex === 0) {
      this.updateSelectedEventIndex(events.length - 1, true)
    } else {
      this.updateSelectedEventIndex(selectedEventIndex - 1, true)
    }
  }

  /* eslint-disable react/no-unused-class-component-methods */
  @keydown('RIGHT')
  // eslint-disable-next-line react/no-unused-class-component-methods
  initR() {
    this.selectNextScreenshot()
  }

  @keydown('LEFT')
  // eslint-disable-next-line react/no-unused-class-component-methods
  initL() {
    this.selectPriorScreenshot()
  }
  /* eslint-enable react/no-unused-class-component-methods */

  updateSelectedEventIndex = (index, delay = false) => {
    const { events } = this.state

    const newSelectedEventIndex = Math.max(Math.min(index, events.length), 0)
    this.setState(
      {
        selectedEventIndex: newSelectedEventIndex,
      },
      () => this.getSelectedEventDetails(events[newSelectedEventIndex], delay)
    )
  }

  getSelectedEventDetails = (currentSelectedEvent, delay = false) => {
    if (!currentSelectedEvent) return
    if (this.debouncedGetEvents) this.debouncedGetEvents.cancel()

    //clear event information to provide feedback for users that something is changing
    this.setState({
      selectedEvent: null,
    })
    const getEvents = async () => {
      const { data } = await api.get(`/event_logs/${currentSelectedEvent.id}`)
      this.setState({
        selectedEvent: { ...currentSelectedEvent, ...data.event },
      })
    }
    if (delay) {
      this.debouncedGetEvents = debounce(getEvents, 200)
      this.debouncedGetEvents()
    } else {
      getEvents()
    }
  }

  getEventsInInstanceData = () => {
    const { events, eventsInProcess } = this.state

    const eventsInInstance = []
    events.forEach(event => {
      if (eventsInProcess[event.id]) eventsInInstance.push(event)
    })

    return eventsInInstance
  }

  addInstance = () => {
    const { events, selectedInstanceOption, processInstances } = this.state
    if (events.length === 0) return

    const eventsInInstance = this.getEventsInInstanceData()

    const filteredProcessInstances = processInstances.filter(obj => {
      return obj.sequence !== selectedInstanceOption.value
    })

    const newInstance = {
      events: eventsInInstance,
      sequence: selectedInstanceOption.value,
      label: selectedInstanceOption.label,
    }
    const newProcessInstances = [...filteredProcessInstances, newInstance].sort((a, b) =>
      a.sequence > b.sequence ? 1 : -1
    )

    this.setState({
      processInstances: newProcessInstances,
    })

    ReactGA.event({
      category: 'Instancs Viewer',
      action: 'Save Instance to Process',
      value: selectedInstanceOption.label,
    })
  }

  instanceIsAnnotated = () => {
    const { events, eventsInProcess } = this.state
    if (events.length === 0) return false
    const selectedEvents = events.filter(event => eventsInProcess[event.id])
    return !!selectedEvents.length && selectedEvents.every(event => event.field)
  }

  saveProcess = async name => {
    const { addNotification } = this.props
    const { processInstances } = this.state
    const params = {
      name: name,
      pathEvents: processInstances.map(i =>
        i.events.map(({ id, controlType, field }) => ({
          id,
          control_type: controlType,
          field,
        }))
      ),
    }
    const { data } = await api.post('/graph_processes', params)
    this.setState({ showProcessModal: false })

    ReactGA.event({
      category: 'Instances Viewer',
      action: 'Save Process',
      value: name,
    })

    addNotification({
      cta: (
        <Button type='link' href={`/process-explorer/${data.graphProcess.id}`}>
          Open {name}
        </Button>
      ),
      description: 'Process saved successfully.',
      type: FLASH_LEVELS.INFO,
    })
  }

  clearInstances = () => {
    this.setState({ processInstances: [] })
  }

  updateSelectedEvent = event => {
    const { events } = this.state

    //this state of selectedEvent can change in the middle of processes the change, we need to explicitly
    //change the state for the event sent
    const eventToChangeIndex = events.findIndex(ev => ev.id === event.id)
    if (eventToChangeIndex === -1) return
    const newEventData = { ...events[eventToChangeIndex], ...event }
    events[eventToChangeIndex] = newEventData
    this.setState({
      selectedEvent: newEventData,
    })
  }
}

export { InstancesViewer }

// temporary container to get roles from user context, since these class components can't get more than one context
// at the moment
export default flashHOC(props => {
  const { roles } = useUserState()
  return <InstancesViewer {...props} roles={roles} addNotification={props.addNotification} />
})
