import React from 'react'
import { func, number } from 'prop-types'
import { Button } from '@fortressiq/fiq-ds'

const FormFooter = ({ currentPage, setFormPage, pagesLength, handleSubmit }) => {
  return (
    <div
      style={{
        padding: '0',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {currentPage !== 0 && (
        <Button type='link' onClick={() => setFormPage(currentPage - 1)} htmlType='button'>
          Back
        </Button>
      )}

      {currentPage !== pagesLength - 1 ? (
        <Button onClick={() => setFormPage(currentPage + 1)} htmlType='button'>
          Next
        </Button>
      ) : (
        <Button type='secondary' htmlType='submit' onClick={handleSubmit}>
          Submit
        </Button>
      )}
    </div>
  )
}

FormFooter.propTypes = {
  currentPage: number,
  setFormPage: func,
  pagesLength: number,
  handleSubmit: func,
}

export default FormFooter
