import React from 'react'
import PropTypes from 'prop-types'
import { useController } from '@fortressiq/fiq-ds'
import QueryBuilder from 'components/query_builder/QueryBuilder'

const ControlledQueryBuilder = ({ control, name, fields, rules, setIsValid }) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    rules,
  })

  return <QueryBuilder query={value} fields={fields} setQuery={onChange} setIsValid={setIsValid} />
}

ControlledQueryBuilder.defaultProps = {
  rules: undefined,
}

ControlledQueryBuilder.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  rules: PropTypes.shape({}),
}

export default ControlledQueryBuilder
