import React from 'react'
import { shallow } from 'enzyme'
import { Button } from '@fortressiq/fiq-ds'
import api from 'lib/Api'

import ApplicationIconCell from '../ApplicationIconCell'

jest.mock('lib/Api')

jest.mock('@fortressiq/fiq-ds', () => ({
  ApplicationIcon: ({ props }) => <div {...props} />,
  Button: ({ props }) => <button type='button' {...props} />,
  Icon: ({ props }) => <div {...props} />,
  Tooltip: ({ props }) => <div {...props} />,
  Upload: ({ props }) => <div {...props} />,
  useFela: () => ({
    css: () => undefined,
  }),
}))

const defaultProps = Object.freeze({
  applicationId: 1,
  imageUrl: 'test/url.png',
  displayName: 'FortressIQ',
  applicationUUID: 'bb8308a2-22a3-4592-9738-34570e3bae14',
})

describe('ApplicationIconCell component', () => {
  it('should render without throwing an error', () => {
    const component = shallow(<ApplicationIconCell {...defaultProps} />)
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
  it('should display the delete icon on hover', () => {
    const component = shallow(<ApplicationIconCell {...defaultProps} />)

    expect(component.find('.deleteButton').length).toBe(0)
    component.simulate('mouseenter')
    expect(component.find('.deleteButton').length).toBe(1)
  })
  it('should call onDeleteIcon when delete icon button is clicked', () => {
    const handleDeleteIcon = jest.fn()
    const component = shallow(<ApplicationIconCell {...defaultProps} onDeleteIcon={handleDeleteIcon} />)
    component.simulate('mouseenter')
    component.find(Button).simulate('click')
    expect(api.delete).toHaveBeenCalledWith(`/applications/${defaultProps.applicationId}/delete_icon`, {
      applicationUuid: 'bb8308a2-22a3-4592-9738-34570e3bae14',
    })
    expect(handleDeleteIcon).toHaveBeenCalledWith(defaultProps.applicationId)
  })
})
