import React, { Fragment, useState } from 'react'

import { Button, useModal, Typography, Group } from '@fortressiq/fiq-ds'
import FilterBreadcrumbs from 'components/filterBreadcrumbs/FilterBreadcrumbs'
import api from 'lib/Api'

const { Paragraph } = Typography

const modalID = 'anonymize-modal'

const EventDeleteConfirm = ({ filter, eventCount, setRefetch }) => {
  const { addModal, removeModal } = useModal()
  const [isLoading, setIsLoading] = useState(false)

  const counterDisplay = `(${eventCount})`
  const isDisabled = !filter || !eventCount

  const handleDelete = async () => {
    setIsLoading(true)
    await api.delete('/events/destroy_many', { filter })
    removeModal(modalID)
    setRefetch(prevState => prevState + 1)
    setIsLoading(false)
  }

  return (
    <Button
      type='primary'
      disabled={isDisabled}
      key={modalID}
      onClick={() =>
        addModal({
          children: (
            <Fragment>
              <Paragraph>
                Are you sure you want to permanently anonymize <strong className='colorRed'>{eventCount}</strong>{' '}
                events?
              </Paragraph>
              <Paragraph>
                Anonymizing these events will delete related images and OCR text. It is irreversible and may affect
                existing Mining Runs and Processes.
              </Paragraph>
              <Paragraph>The following filters will be used to anonymize the events:</Paragraph>
              <FilterBreadcrumbs query={filter} />
            </Fragment>
          ),
          header: 'Anonymize Events',
          footerStyle: { justifyContent: 'flex-end' },
          footer: (
            <Group>
              <Button type='default' onClick={() => removeModal(modalID)}>
                Cancel
              </Button>
              <Button type='primary' disabled={isLoading} onClick={() => handleDelete()}>
                Anonymize {eventCount} {eventCount === 1 ? 'event' : 'events'}
              </Button>
            </Group>
          ),
          id: modalID,
          style: { width: '600px' },
        })
      }
    >
      Anonymize events {!isDisabled ? counterDisplay : ''}
    </Button>
  )
}

export default EventDeleteConfirm
