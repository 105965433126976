import React from 'react'

const Marker = ({ id, color }) => (
  <marker
    className='react-flow__arrowhead'
    id={id}
    markerHeight={25}
    markerUnits='userSpaceOnUse'
    markerWidth={25}
    orient='auto-start-reverse'
    refX='0'
    refY='0'
    viewBox='-10 -10 20 20'
  >
    <polyline
      stroke={color}
      strokeWidth={1}
      strokeLinecap='round'
      strokeLinejoin='round'
      fill={color}
      points='-5,-4 0,0 -5,4 -5,-4'
    />
  </marker>
)

export default Marker
