import React from 'react'
import { observer } from 'mobx-react'
import { Button, Row, Col, theme, Spin } from '@fortressiq/fiq-ds'

import store from '../ClientDashboardStore'

const tagButtonStyle = active => ({
  background: active ? theme['nav-btn-selected-bg'] : theme.white,
  ...(active && {
    borderColor: theme['contrast-blue'],
    '&:hover': {
      borderColor: theme['contrast-blue'],
      background: theme['nav-btn-selected-bg'],
    },
    '&:focus': {
      borderColor: theme['contrast-blue'],
    },
  }),
})

const TagsFilter = observer(({ filterTags, setFilterTags }) => {
  const onChange = tag => {
    if (filterTags.includes(tag)) {
      setFilterTags(filterTags.filter(t => t !== tag))
    } else {
      setFilterTags([...filterTags, tag])
    }
  }

  return (
    <Row type='flex' style={{ marginBottom: theme['default-spacer-lg'] }}>
      {store.isLoading && store.tags.length === 0 && <Spin>Loading&hellip;</Spin>}
      {!store.isLoading && store.tags.length === 0 && 'No Tags Currently'}
      {store.tags.map(tag => {
        const active = filterTags.includes(tag.value)
        const { label, value } = tag

        return (
          <Col key={`tag-button-${tag.value}`}>
            <Button flat='true' style={tagButtonStyle(active)} onClick={() => onChange(value)}>
              {label}
            </Button>
          </Col>
        )
      })}
    </Row>
  )
})

export default TagsFilter
