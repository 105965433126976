export const BASE_DATE_FORMAT = 'YYYYMMDD'

export const BASE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_TIME_FORMAT_TWELVE_HOUR = 'yyyy-MM-dd hh:mm aa'
export const DATE_TIME_FORMAT_TWENTYFOUR_HOUR = 'yyyy-MM-dd HH:mm:ss'
export const DATETIME_FORMAT = 'mm/dd/yyyy, hh:mm:ss a'

export const DATE_FORMAT_SHORT = 'MMM dd'
export const TIME_FORMAT_SHORT = 'hh:mm A'

export const DATE_FORMAT_MEDIUM = 'YYYY-MM-DD'
export const DS_DATE_FORMAT_MEDIUM = 'yyyy-MM-dd'

export const FNS_DATE_FORMAT_SHORT = 'MMM dd'
export const FNS_TIME_FORMAT_SHORT = 'hh:mm a'

export default module.exports
