import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, useFela, theme, chroma } from '@fortressiq/fiq-ds'
import { Charts, defaultChartConfigProps, FusionCharts, FusionTheme, PowerCharts, ReactFC } from 'components/viz/index'

import { chartWrapperCSS } from './styles'

import { cardTitleStyle } from './constants'

ReactFC.fcRoot(FusionCharts, PowerCharts, Charts, FusionTheme)

const CreditsChart = ({ chartData, loading }) => {
  const { css } = useFela()
  const [creditChartData, setCreditChartData] = useState([])
  const [creditChartLabels, setCreditChartLabels] = useState([])

  useEffect(() => {
    const data = []
    const labels = []
    const barColor = chroma(theme['wine-tints-0']).hex()
    Object.keys(chartData).forEach(key => {
      labels.push({ label: key })
      data.push({
        value: chartData[key],
        color: barColor,
      })
    })
    setCreditChartLabels(labels)
    setCreditChartData(data)
  }, [chartData])

  const dataSource = {
    chart: {
      showHoverEffect: '1',
      theme: 'fusion',
    },
    categories: [{ category: creditChartLabels }],
    dataset: [{ data: creditChartData }],
  }

  const chartConfig = {
    ...defaultChartConfigProps,
    dataFormat: 'json',
    dataSource: dataSource,
    height: '84%',
    id: 'viz-credit-counts',
    type: 'scrollcolumn2d',
  }

  return (
    <Card
      id='creditsChartCard'
      className={css(chartWrapperCSS)}
      headStyle={cardTitleStyle}
      loading={loading}
      title='Credit Consumption'
    >
      <ReactFC {...chartConfig} className={css({ marginTop: theme['default-spacer-md'] })} />
    </Card>
  )
}

CreditsChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.number).isRequired,
  loading: PropTypes.bool.isRequired,
}

export default memo(CreditsChart)
