import { theme } from '@fortressiq/fiq-ds'

const baseGray = theme['ui-line-default']

export const flowTemplate = () => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: `${theme['default-spacer-md']} auto`,
  padding: '0 20px',
  width: '100%',

  '&::before': {
    background: baseGray,
    content: '""',
    height: theme['default-spacer-md'],
    position: 'absolute',
    width: '80%',
    zIndex: theme['z-below'],
  },
})

export const dropNode = () => ({
  background: theme.white,
  border: `1px solid ${baseGray}`,
  borderRadius: `${theme['default-spacer-md']}`,
  color: `${theme['gray-tints-11']}`,
  fontSize: theme['font-size-sm'],
  height: theme['default-spacer-lg'],
  lineHeight: theme['default-spacer-lg'],
  marginBottom: theme['default-spacer-xs'],
  padding: `0 ${theme['default-spacer-md']}`,
  position: 'relative',
  textAlign: 'left',
  verticalAlign: 'middle',
  width: '120px',

  '&:last-child': {
    marginBottom: '0',
  },

  i: {
    position: 'absolute',
    right: theme['default-spacer-sm'],
    top: theme['default-spacer-sm'],
  },
})

export const numOfNodes = () => ({
  background: theme.white,
  border: `3px solid ${baseGray}`,
  borderRadius: theme['default-spacer-md'],
  fontSize: theme['font-size-sm'],
  fontStyle: 'italic',
  padding: `${theme['default-spacer-xs']} ${theme['default-spacer-md']}`,
})

export const connected = () => ({
  background: theme['blue-tints-3'],
  height: '2px',
  left: '165px',
  position: 'absolute',
  transition: '0.2s ease',
  width: '269px',
  zIndex: theme['z-below'],
})

export const error = () => ({
  color: theme['error-color'],
  fontSize: theme['font-size-xs'],
  fontStyle: 'italic',
  marginTop: theme['default-spacer-xs'],
  minHeight: theme['default-spacer-md'],
  padding: `0 20px ${theme['default-spacer-xs']} 23px`,
})

export const bottomsection = () => ({
  borderTop: `1px solid ${baseGray}`,
  padding: theme['default-spacer-sm'],
})

export const nodeName = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '90%',
}

export const subprocessNameInput = () => ({
  flexGrow: 1,

  '& > span': {
    flexGrow: 1,
  },
})
