import React, { useMemo } from 'react'
import { chroma, theme } from '@fortressiq/fiq-ds'
import PropTypes from 'prop-types'

import {
  Charts,
  defaultChartConfigProps,
  FusionCharts,
  FusionTheme,
  ReactFC,
  zeroChartMarginPadding,
} from '../../../viz/index'

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme)

const PathsByInstances = ({ paths }) => {
  const pathData = useMemo(() => {
    const getPathData = data => {
      return data.map(path => {
        return {
          label: path.name || path.key,
          value: path.frequency,
        }
      })
    }
    return getPathData(paths)
  }, [paths])

  const dataSource = {
    chart: {
      ...zeroChartMarginPadding,
      paletteColors: chroma(theme['viz-bar-background-color']).hex(),
      theme: 'fusion',
    },
    data: pathData,
  }

  const chartConfig = {
    ...defaultChartConfigProps,
    height: 300,
    id: 'paths-by-instances-bar',
    type: 'bar2d',
  }

  return <ReactFC {...chartConfig} dataSource={dataSource} />
}

PathsByInstances.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      frequency: PropTypes.number,
      name: PropTypes.string,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
}

export default PathsByInstances
