import store from '../../stores/processExplorerStore'

export default ({ flowSvg, flow, iconWidth, nodeHeight, collapsedHeight, duration, nodeActions }) => {
  const { nodeWidth } = store

  const nodes = flowSvg.selectAll('.flowrect').data(flow, d => d.id)
  const nEnter = nodes
    .enter()
    .append('rect')
    .attr('class', 'flowrect')
    .attr('width', nodeWidth + iconWidth + 15)
    .attr('rx', '6')
    .attr('ry', '6')
    .attr('cursor', 'pointer')
    .attr('height', d => {
      return d.data.type === 'collapse' || d.data.activities.length > 1 ? collapsedHeight + 10 : nodeHeight
    })
    .attr('transform', d => {
      if (typeof d.x === 'undefined') return
      return `translate(${d.y}, ${d.x - 25})`
    })
    .style('fill', d => {
      return d.color || '#ccc'
    })
    .style('fill-opacity', 0.3)
    .style('display', d => {
      if (d.data.type === 'start' || d.data.type === 'end' || typeof d.x === 'undefined') return 'none'
      return 'block'
    })

  const nUpdate = nEnter.merge(nodes)

  // Transition to the proper position for the node
  nUpdate
    .transition()
    .duration(0)
    .attr('width', nodeWidth + iconWidth + 15)
    .attr('height', d => {
      return d.data.type === 'collapse' || d.data.activities.length > 1 ? collapsedHeight + 10 : nodeHeight
    })
    .attr('transform', d => {
      if (typeof d.x === 'undefined') return
      return `translate(${d.y},${d.x - 25})`
    })
    .style('display', d => {
      if (d.data.type === 'start' || typeof d.x === 'undefined') return 'none'
      return 'block'
    })
    .style('fill', d => {
      return d.color || '#ccc'
    })

  nUpdate
    .selectAll('.flowrect')
    .attr('width', nodeWidth + iconWidth + 15)
    .attr('height', nodeHeight)
    .style('fill', d => {
      return d.color || '#ccc'
    })
    .style('display', d => {
      if (d.data.type === 'start' || typeof d.x === 'undefined') return 'none'
      return 'block'
    })

  nEnter.on('click', nodeActions)

  const nExit = nodes.exit()

  nExit.transition().duration(duration).style('opacity', 0).remove()
}
