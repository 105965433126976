import React from 'react'

import { Table } from '@fortressiq/fiq-ds'

import Can from 'components/can/Can'

import { rules, routes } from 'lib/rbac-rules'

const Roles = () => {
  const roles = Object.keys(rules)

  const columns = roles.map(role => {
    return {
      Header: role,
      accessor: role,
    }
  })

  const data = routes.map(route => {
    const row = roles.reduce((acc, curr) => ({ ...acc, [curr]: rules[curr].routes.includes(route) ? route : null }), {})
    return row
  })

  return (
    <div>
      <Can perform='test'>
        <p>test</p>
      </Can>
      <Table data={data} columns={columns} initialState={{ pageSize: 50 }} />
    </div>
  )
}

export default Roles
