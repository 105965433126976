import React, { createContext, useContext, useReducer } from 'react'

const UserStateContext = createContext()
const UserDispatchContext = createContext()

function parseRoles(roleNamesStr) {
  const roleNames = [...new Set(roleNamesStr.split(', '))]
  const roles = [...roleNames]
  roleNames.forEach(role => {
    const roleKey = `is${role[0].toUpperCase()}${role.slice(1)}`
    roles[roleKey] = true
  })
  return roles
}

function userReducer(state, action) {
  switch (action.type) {
    case 'set': {
      const roles = parseRoles(action.roleNames)
      return {
        email: action.email,
        id: action.id,
        isAdmin: action.isAdmin,
        isSuperUser: action.isSuperUser,
        canViewUnredactedImages: action.canViewUnredactedImages,
        roles,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function UserProvider({ init, children }) {
  const initialState = {
    ...init,
  }
  if (initialState.roleNames) {
    initialState.roles = parseRoles(init.roleNames)
    delete initialState.roleNames
  }
  const [state, dispatch] = useReducer(userReducer, {
    email: '',
    id: '',
    name: '',
    isAdmin: false,
    isSuperUser: false,
    canViewUnredactedImages: false,
    roles: [],
    ...initialState,
  })
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

function useUserState() {
  const context = useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return context
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }
  return context
}

export { UserDispatchContext, UserStateContext, UserProvider, useUserState, useUserDispatch }
