import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Table, theme, Element, Select, Checkbox, Tooltip, Icon } from '@fortressiq/fiq-ds'
import { getTimeElapsed } from 'lib/Time'
import dfgColors from '../dfgColors'

import {
  pathsDataPropTypes,
  activePathsPropTypes,
  highlightedPathsPropTypes,
  handleHighlightPathPropTypes,
  handlePathSelectPropTypes,
  processIdPropTypes,
} from '../commonPropTypes'

const durationOptions = [
  { label: 'Average Duration', value: 'average' },
  { label: 'Median Time', value: 'median' },
  { label: 'Total Duration', value: 'total' },
]

const constructPathsData = data => {
  return data.map(path => ({
    id: path.path_id,
    uuid: path.path_uuid,
    label: path.path_label,
    frequency: path.stats.frequency,
    averageDurationString: getTimeElapsed(path.stats.mean_duration),
    medianDurationString: getTimeElapsed(path.stats.median_duration),
    totalDurationString: getTimeElapsed(path.stats.duration),
    durationString: getTimeElapsed(path.stats.mean_duration),
  }))
}

const PathStatistics = ({
  activePaths,
  handleHighlightPath,
  handlePathSelect,
  highlightedPaths,
  isLoadingPaths,
  pathsData,
  processId,
}) => {
  const [durationType, setDurationType] = useState(durationOptions[0])
  const [constructedPathsData, setConstructedPathsData] = useState([])

  useEffect(() => {
    if (!isLoadingPaths && pathsData) {
      setConstructedPathsData(constructPathsData(pathsData))
    }
  }, [pathsData, isLoadingPaths])

  const changeDurationType = ({ label, value }) => {
    const newData = constructedPathsData?.map(row => ({
      ...row,
      durationString: row[`${value}DurationString`],
    }))
    setConstructedPathsData(newData)
    setDurationType({ label, value })
  }

  const rowSelection = {
    selectedRows: highlightedPaths,
    selectedRowKey: 'uuid',
    selectedRowColor: dfgColors.nodeFillBlue,
  }

  const columns = [
    {
      Header: '',
      accessor: 'id',
      width: 15,
      minWidth: 15,
      maxWidth: 15,
      Cell: ({
        row: {
          original: { id, uuid },
        },
      }) => (
        <Checkbox
          checked={activePaths?.some(path => path.pathUuid === uuid)}
          checkedBackgroundColor={dfgColors.checkboxBlue}
          onCheck={e => handlePathSelect(e, id, uuid)}
        />
      ),
    },
    {
      Header: 'Path',
      accessor: 'label',
      width: 80,
      minWidth: 80,
    },
    {
      Header: 'Instances',
      accessor: 'frequency',
      width: 70,
      minWidth: 70,
    },
    {
      Header: (
        <Select
          defaultValue={durationType}
          menuPortalTarget={document.body}
          onChange={e => changeDurationType(e)}
          options={durationOptions}
          size='small'
          style={{ width: '130px' }}
          styles={{
            menu: {
              zIndex: theme['z-modal-below'],
            },
          }}
        />
      ),
      accessor: 'durationString',
      disableTooltip: true,
      isNotText: true,
      padding: '3px 0 0',
      width: 130,
      minWidth: 130,
    },
    {
      Header: '',
      accessor: 'link',
      width: 20,
      minWidth: 20,
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <Link to={`/instances-viewer/${processId}?pathId=${id}&graphType=flowgraph`}>
          <Tooltip mouseEnterDelay={1000} placement='left-center' title='View in Instances Viewer'>
            <Icon icon='instances' />
          </Tooltip>
        </Link>
      ),
    },
  ].filter(Boolean)
  return (
    <Element style={{ margin: `-${theme['default-spacer-sm']}` }}>
      <Table
        columns={columns}
        data={constructedPathsData}
        disablePagination={true}
        disableResizing={true}
        disableSortBy={true}
        loading={!constructedPathsData || isLoadingPaths}
        onRowClick={handleHighlightPath}
        rowSelection={rowSelection}
        verticalBorders={false}
      />
    </Element>
  )
}

PathStatistics.propTypes = {
  activePaths: activePathsPropTypes,
  handleHighlightPath: handleHighlightPathPropTypes,
  handlePathSelect: handlePathSelectPropTypes,
  highlightedPaths: highlightedPathsPropTypes,
  pathsData: pathsDataPropTypes,
  processId: processIdPropTypes,
}

export default PathStatistics
