import React from 'react'
import { observer } from 'mobx-react'
import { Icon } from '@fortressiq/fiq-ds'

import sourceTypes from 'components/dragAndDrop/sourceTypes'
import SVGDropZone from 'components/dragAndDrop/SVGDropZone'

import ButterflyProcessStore from '../../stores/ButterflyProcessStore'

import css from './pathDetailsStyle.scss'

/**************
 * path details and ceration, due to the nature of the ceation UX
 * the UX shows part of the path information if available
 * if not there is a drag and drop field
 *
 ******/
@observer
class PathDetails extends React.Component {
  render() {
    const { selectionId, pathNumber } = this.props
    const { paths, pathsInformation, removePath } = ButterflyProcessStore
    const DropZoneHeight = 42

    return (
      <div className={css.section}>
        <div className={css.titleSection}>
          <label className={css.label}>Path {pathNumber} </label>
          {paths[selectionId] && (paths[selectionId].pathEnd || paths[selectionId].pathStart) && (
            <div className={css.titleSectionRight}>
              <Icon className={css.remove} icon='remove' onClick={() => removePath(selectionId)} size='nested' />
            </div>
          )}
        </div>

        {pathsInformation[selectionId] && pathsInformation[selectionId][0].length === 0 && (
          <div className={css.error}>! no path(s) found </div>
        )}

        {paths[selectionId] && paths[selectionId].pathStart && (
          <NodeListing
            type='START'
            app={paths[selectionId].pathStart.application.substring(0, 2)}
            name={paths[selectionId].pathStart.name}
          />
        )}

        {(!paths[selectionId] || !paths[selectionId].pathStart) && (
          <SVGDropZone
            height={DropZoneHeight}
            id={`butterflyProcStart_${selectionId}`}
            sourceTypes={sourceTypes.BUTTERFLYNODE}
            data={paths[selectionId]}
            defaultDisplay='Drag and drop an event to start a path'
            className={css.dropzone}
          />
        )}

        {paths[selectionId] && paths[selectionId].pathEnd && (
          <NodeListing
            type='END'
            app={paths[selectionId].pathEnd.application.substring(0, 2)}
            name={paths[selectionId].pathEnd.name}
          />
        )}

        {paths[selectionId] && !paths[selectionId].pathEnd && (
          <SVGDropZone
            height={DropZoneHeight}
            id={`butterflyProcEnd_${selectionId}`}
            sourceTypes={sourceTypes.BUTTERFLYNODE}
            data={paths[selectionId].pathEnd}
            defaultDisplay='Drag and drop an event to end a path'
            className={css.dropzoneEnd}
          />
        )}
      </div>
    )
  }
}

const NodeListing = ({ app, name, type }) => (
  <div className={css.nodeListing}>
    <div className={css.type}>{type}</div>
    <div className={css.app}>{app || '--'}</div>
    <div className={css.name}>{name || '--'}</div>
  </div>
)

export default PathDetails
