import React, { Fragment, useEffect, useMemo, useCallback } from 'react'
import intersection from 'lodash/intersection'

import ReactFlow, { MiniMap, Controls, useReactFlow } from 'reactflow'

import { useQuery } from '@tanstack/react-query'
import { Element } from '@fortressiq/fiq-ds'

import Marker from './Marker'

import StartNode from './nodeTypes/StartNode'
import EndNode from './nodeTypes/EndNode'
import StepNode from './nodeTypes/StepNode'
import ElkEdge from './edgeTypes/ElkEdge'
import Sidebar from './Sidebar/Sidebar'
import cacheSettings from './cacheSettings'
import dfgColors from './dfgColors'
import { getGraph, getPaths } from './api'
import 'reactflow/dist/style.css'

import { arrowStyles, canvasControls, containerStyles, miniMap, reactFlowStyles } from './styles'

const transformGraphData = data => {
  const nodes = data.graph.children.map(node => ({
    ...node,
    ariaLabel: `Node ${node.label}`,
    data: { id: node.id, ...node.data },
    type: node.data.type,
    position: { x: node.x, y: node.y },
  }))
  const edges = data.graph.edges.map(edge => ({
    id: edge.id,
    source: edge.sources[0],
    target: edge.targets[0],
    label: `${edge.data.frequency}`,
    type: 'elkEdge',
    data: {
      ...edge.data,
      sections: edge.sections,
      label: edge.edgeLabel,
    },
  }))

  return {
    nodes,
    edges,
    stats: data.path_stats,
  }
}

const Graph = ({
  processId,
  activePaths,
  setActivePaths,
  highlightedPaths,
  setHighlightedPaths,
  selectedStep,
  setSelectedStep,
}) => {
  const reactFlowInstance = useReactFlow()

  const { isLoading: isLoadingPaths, data: pathsData } = useQuery({
    queryKey: ['flow-graph', { processId }],
    queryFn: getPaths,
    ...cacheSettings,
  })

  const { data: graphData } = useQuery({
    queryKey: ['graph', { processId, activePaths: activePaths?.map(path => path.pathId) }],
    queryFn: getGraph,
    // disabled as long as activePaths is empty
    enabled: activePaths.length !== 0,
    keepPreviousData: true,
    ...cacheSettings,
    select: transformGraphData,
  })

  useEffect(() => {
    reactFlowInstance.fitView()
  }, [graphData, reactFlowInstance])

  const handlePathSelect = (selectValue, pathId, pathUuid) => {
    if (selectValue) {
      setActivePaths(prevProps => [...prevProps, { pathId, pathUuid }])
    } else {
      setActivePaths(prevProps => prevProps.filter(path => path.pathUuid !== pathUuid))
      // if path is highlighted removed remove highlight
      if (highlightedPaths.includes(pathUuid)) {
        setHighlightedPaths(highlightedPaths.filter(path => path !== pathUuid))
      }
    }
  }

  const handleSelectStep = useCallback(
    (event, data) => {
      if (selectedStep?.id !== data.id) {
        setSelectedStep(data)
      }
      const newHighlightedPaths = intersection(
        data.pathList,
        activePaths.map(path => path.pathUuid)
      )
      setHighlightedPaths(newHighlightedPaths)
    },
    [activePaths, selectedStep]
  )

  const handleHighlightPath = ({ uuid }) => {
    if (!activePaths?.some(path => path.pathUuid === uuid)) return null
    setHighlightedPaths(
      highlightedPaths.includes(uuid) ? highlightedPaths.filter(i => i !== uuid) : [...highlightedPaths, uuid]
    )
  }

  const handleHighlightEdge = useCallback(
    (e, data) => {
      const activePathsUuids = activePaths.map(path => path.pathUuid)
      const pathsToHighlight = intersection(activePathsUuids, data.pathList)
      setHighlightedPaths(pathsToHighlight)
    },
    [activePaths]
  )

  const nodeTypes = useMemo(
    () => ({
      stepNode: nodeProps =>
        StepNode({
          ...nodeProps,
          selectedStep,
          handleSelectStep,
          highlightedPaths,
        }),
      startNode: nodeProps =>
        StartNode({
          ...nodeProps,
          frequency: graphData?.stats.frequency,
        }),
      endNode: nodeProps =>
        EndNode({
          ...nodeProps,
          frequency: graphData?.stats.frequency,
        }),
    }),
    [selectedStep, highlightedPaths, handleSelectStep, graphData]
  )

  const edgeTypes = useMemo(
    () => ({
      elkEdge: edgeProps =>
        ElkEdge({
          ...edgeProps,
          highlightedPaths,
          handleHighlightEdge,
          activePaths,
          edges: graphData?.edges,
        }),
    }),
    [highlightedPaths, handleHighlightEdge, activePaths, graphData]
  )

  return (
    <Fragment>
      <svg style={arrowStyles}>
        <defs>
          <Marker id='standardArrow' color={dfgColors.edgeGray} />
          <Marker id='customHoverArrow' color={dfgColors.hoverEdge} />
          <Marker id='customSelectedArrow' color={dfgColors.edgeBlue} />
          <Marker id='customZScoreArrow' color={dfgColors.edgePink} />
        </defs>
      </svg>

      <Element style={containerStyles}>
        <ReactFlow
          edges={graphData?.edges}
          edgeTypes={edgeTypes}
          fitView={true}
          minZoom={0}
          nodes={graphData?.nodes}
          nodesConnectable={false}
          nodesDraggable={false}
          nodeTypes={nodeTypes}
          proOptions={{ hideAttribution: true }}
          style={reactFlowStyles}
        >
          <Controls showInteractive={false} position='top-right' style={canvasControls} />
          <MiniMap position='bottom-left' style={miniMap} />
        </ReactFlow>
        <Sidebar
          activePaths={activePaths}
          processId={processId}
          handleHighlightPath={handleHighlightPath}
          handlePathSelect={handlePathSelect}
          highlightedPaths={highlightedPaths}
          isLoadingPaths={isLoadingPaths}
          pathsData={pathsData}
          graphStats={graphData?.stats}
          selectedStep={selectedStep}
        />
      </Element>
    </Fragment>
  )
}

export default Graph
