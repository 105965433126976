import { theme } from '@fortressiq/fiq-ds'

export const filterContainer = () => ({
  background: theme.white,
  border: `1px solid ${theme['antd-card-border']}`,
  borderRadius: `0 0 ${theme['default-spacer-sm']} ${theme['default-spacer-sm']}`,
  boxShadow: `0 ${theme['default-spacer-xs']} ${theme['default-spacer-xs']} ${theme['btn-borderAlpha']}`,
  maxWidth: '100vw',
  overflow: 'auto',
  padding: theme['default-spacer-sm'],
  position: 'absolute',
  top: '100%',
  width: '560px',
  maxHeight: '60vh',
})

export const applyButton = () => ({
  margin: `${theme['default-spacer-sm']} 0 0 ${theme['default-spacer-sm']}`,
})

export const cyclesWrap = () => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: theme['default-spacer-sm'],
  position: 'relative',
  zIndex: theme['z-base-above'],
})
