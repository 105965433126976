import React, { useContext, useEffect } from 'react'

import css from './eventTimeline.scss'
import { Context } from './Context'
import Slide from './Slide'

const Slider = () => {
  const { setActive, eventSliderRef, events } = useContext(Context)

  useEffect(() => {
    let isScrolling
    const sliderRef = eventSliderRef.current
    const scrollHandler = () => {
      window.clearTimeout(isScrolling)
      // derive active slide index from scroll position
      const onScrollStop = () => setActive(Math.round(sliderRef.scrollLeft / sliderRef.offsetWidth))
      isScrolling = setTimeout(onScrollStop, 150)
    }
    sliderRef.addEventListener('scroll', scrollHandler, false)

    return () => sliderRef.removeEventListener('scroll', scrollHandler, false)
  }, [eventSliderRef, setActive])

  return (
    <div className={css.slider} ref={eventSliderRef}>
      {events.map((event, slideIndex) => {
        return (
          <Slide
            key={`event-timeline-slide-${event.id}`}
            {...{
              event,
              slideIndex,
            }}
          />
        )
      })}
    </div>
  )
}

export default Slider
