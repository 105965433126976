import React from 'react'
import { UserProvider } from 'context/UserContext'

import ManageAccount from '../ManageAccount'

const defaultTenant = {
  aggregationFrequency: '',
  appUrl: '',
  coeIntakeUrl: '',
  coeUserName: '',
  createdAt: '',
  emailFromAddress: '',
  id: 123,
  isActive: true,
  isLite: false,
  name: '',
  smtpHost: '',
  smtpPassword: '',
  smtpPort: '',
  smtpUsername: '',
  updatedAt: '',
  uuid: '',
}

const manageAccountProps = {
  defaultTenant: defaultTenant,
  tenantId: '1',
  isLoading: false,
  setIsLoading: () => {},
  setDefaultTenant: () => {},
  allowAllSettings: true,
}

jest.mock('@fortressiq/fiq-ds', () => {
  return {
    useNotification: () => ({
      addNotification: () => {},
    }),
    Typography: { Paragraph: ({ props }) => <div {...props} /> },
    theme: { 'default-spacer-md': '16px' },
    useModal: () => ({
      addModal: () => undefined,
      removeModal: () => undefined,
    }),
    Element: props => <div {...props} />,
  }
})

describe('ManageAccount component', () => {
  beforeEach(() => {})
  it('should render without throwing an error', () => {
    const component = global.mountWithTheme(
      <UserProvider init={{ roleNames: 'superuser' }}>
        <ManageAccount {...manageAccountProps} />
      </UserProvider>
    )
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
})
