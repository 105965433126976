import React from 'react'
import { FormControl, TextArea as TextAreaDS } from '@fortressiq/fiq-ds'

import { useRegisterField } from './hooks'

const TextArea = ({
  defaultValue,
  description,
  errors,
  handleChange,
  inputName,
  label,
  name,
  noMargin,
  placeholder,
  register,
  unregister,
  validate,
}) => {
  const registerInput = useRegisterField(inputName, register, unregister, validate)

  const onChangeHandler = e => {
    registerInput.onChange(e)

    handleChange(e.target.name, e.target.value)
  }

  if (!registerInput) return null

  return (
    <FormControl errors={errors} helperText={description} style={noMargin && { margin: 0 }}>
      <TextAreaDS
        defaultValue={defaultValue !== undefined ? String(defaultValue) : ''}
        id={`application-textarea-${inputName}`}
        label={label}
        name={registerInput.name}
        onBlur={registerInput.onBlur}
        onChange={onChangeHandler}
        placeholder={placeholder || label}
        ref={registerInput.ref}
      />
    </FormControl>
  )
}

export default TextArea
