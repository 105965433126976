import React from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import { Element } from '@fortressiq/fiq-ds'

import MiniMap from 'components/mini_map/MiniMap'
import { UserStateContext } from 'context/UserContext'

import store from '../stores/processExplorerStore'
import subprocessStore from '../stores/subprocessStore'

import SidePanel from './SidePanel'
import ProcessExplorerTree from './ProcessExplorerTree'

import ZoomControls from '../zoomControls/ZoomControls'

import { containerCSS, diagramContainerCSS, disagramSVGCSS, mapDiagramContainerCSS } from './styles'

/******
 * Component for process mining/exploration page
 * instantiates a processTree obj which is classic js object and written with d3 , using d3 patterns
 * functions passed in from parent component are passed in to processTree constructor and bound there
 * also acts as an intemediary and updates the tree when props change.,,,
 * need to see if there is a clean way to call update from mobx?..(or forego mobX?..)
 *******/
@observer
class ProcessExplorationDiagram extends React.Component {
  static contextType = UserStateContext

  constructor(props) {
    super(props)

    this.state = {
      tree: {},
      node: {},
    }
  }

  componentDidMount() {
    //click out side handler for menus
    this.createTree()
  }

  componentDidUpdate(prevProps) {
    const { diagramData } = this.props
    if (prevProps.diagramData !== diagramData) {
      this.createTree()
    }
  }

  componentWillUnmount() {
    this.graphReaction()
    this.subprocessReaction()
    this.selectPathReaction()
    this.updatePathNameReaction()
    this.treeFlowReaction()
  }

  render() {
    const { node, tree } = this.state

    const { flowData, flowDataStats, minedRun, applicationData, graphProcess, toggleHideDuration } = this.props
    const { isAdmin } = this.context

    return (
      <Element style={containerCSS}>
        <Element id='mapAndDiagramContainer' style={mapDiagramContainerCSS}>
          <Element id='diagramContainer' style={diagramContainerCSS}>
            <Element id='diagramSVGCanvas' style={disagramSVGCSS} />
          </Element>
          <ZoomControls />
          <MiniMap mapOf='diagramContainer' viewport='diagramContainer' zoom={store.zoom} />
        </Element>

        <SidePanel
          applicationData={applicationData}
          flowData={flowData}
          graphProcessId={store.graphId}
          isAdmin={isAdmin}
          minedRun={minedRun}
          node={node}
          store={store}
          flowDataStats={flowDataStats}
          toggleHideDuration={toggleHideDuration}
          tree={tree}
          graphProcess={graphProcess}
        />
      </Element>
    )
  }

  createTree = () => {
    const { diagramData, history, flowData } = this.props

    const newTreeProps = {
      data: diagramData,
      history: history,
      flowData: flowData,
      selectNode: this.selectNode,
      clearActivityPanel: this.clearActivityPanel,
    }
    const tree = new ProcessExplorerTree(newTreeProps)

    this.graphReaction = reaction(
      () => {
        return {
          flow: store.flow,
          groupMode: store.groups.groupsMode,
          editGroupMode: store.groups.editGroupMode,
          groupList: store.groups.groupList,
          activeGroup: store.groups.activeGroup,
          viewingNode: store.viewingNode,
          activeGroupNodeIdsLength: store.groups.activeGroup.activeGroupNodeIds.length,
        }
      },
      () => {
        tree.update()
      }
    )

    this.selectPathReaction = reaction(
      () => store.selectedPath,
      path => {
        if (!path.fromNode) {
          tree.update()
        }
      }
    )

    this.updatePathNameReaction = reaction(
      () => {
        return {
          flowData: store.flowData,
        }
      },
      () => {
        tree.update()
      }
    )

    this.treeFlowReaction = reaction(
      () => ({ value: store.treeFlow.value, index: store.treeFlow.index, type: store.treeFlow.type }),
      ({ value, index, type }) => {
        tree.setFlows(value, index, type)
      }
    )

    this.subprocessReaction = reaction(
      () => {
        return {
          includedNodeIds: subprocessStore.includedNodeIds,
          subprocessMode: subprocessStore.subprocessMode,
        }
      },
      () => {
        this.markSubprocessNodesInD3Data(store.d3Data)
        tree.update()
      }
    )
    this.setState({ tree: tree })
  }

  markSubprocessNodesInD3Data(d3Node) {
    d3Node.data.isInSubprocess = subprocessStore.includedNodeIds.includes(d3Node.data.id)

    if (!d3Node.children) return

    d3Node.children.forEach(child => {
      this.markSubprocessNodesInD3Data(child)
    })
  }

  clearActivityPanel() {
    store.viewingNode = {}
  }

  selectNode = d => {
    this.setState({ node: d })
  }
}

export default ProcessExplorationDiagram
