export const toolbarCSS = ({ theme }) => ({
  alignItems: 'center',
  background: theme.white,
  borderBottom: `1px solid ${theme['table-border-color']}`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme['default-spacer-sm']} ${theme['default-spacer-md']}`,
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: theme['z-base-above'],
})

export const detailsListCSS = () => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  overflow: 'hidden',
})

export const emptyWrapperCSS = ({ theme }) => ({
  paddingTop: theme['default-spacer-md'],
  width: '100%',
})

export const loadingWrapperCSS = ({ theme }) => ({
  paddingTop: theme['default-spacer-lg'],
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

export const wrapperCSS = () => ({
  display: 'flex',
  flexFlow: 'column',
  height: '100%',
})

export const innerWrapperCSS = () => ({
  flex: 1,
  display: 'flex',
  overflow: 'hidden',
})

export const sidebarCSS = ({ theme, isSidebarOpen }) => ({
  background: theme.white,
  borderRight: isSidebarOpen ? `1px solid ${theme['table-border-color']}` : 'none',
  height: '100%',
  position: 'relative',
  width: isSidebarOpen ? '520px' : '0px',
})

export const galleryWrapperCSS = ({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  height: '100%',
  overflow: 'auto',
  padding: theme['default-spacer-sm'],
  width: '100%',
})

export const galleryImageCSS = ({ theme }) => ({
  justifyContent: 'center',
  margin: theme['default-spacer-sm'],
  width: `calc(50% - ${theme['default-spacer-md']})`,
})

export const sidebarToggleCSS = ({ theme }) => ({
  alignItems: 'center',
  background: theme.white,
  borderBottom: `1px solid ${theme['table-border-color']}`,
  borderBottomRightRadius: theme['border-radius-base'],
  borderRight: `1px solid ${theme['table-border-color']}`,
  cursor: 'pointer',
  display: 'flex',
  justifycontent: 'center',
  padding: theme['default-spacer-sm'],
  position: 'absolute',
  right: '-38px',
  top: 0,
  zIndex: theme['z-base-above'],
})
