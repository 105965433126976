const statusMap = {
  complete: 'success',
  success: 'success',
  done: 'success',
  imported: 'success',
  pending: 'processing',
  mining: 'processing',
  processing: 'processing',
  'generating mining parameters': 'processing',
  'error, no paths found': 'error',
  'error, pod evicted': 'error',
  'error, bad filter rules or databse connection': 'error',
  'error, no events found': 'error',
  error: 'error',
}

const getStatusColor = status => {
  return statusMap[status] || 'default'
}

export default getStatusColor
