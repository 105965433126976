/* eslint-disable import/prefer-default-export */
import { chroma, helpers, theme } from '@fortressiq/fiq-ds'

const { customScrollbarCSS, dropShadowCSS, formatString } = helpers

const animSlow = theme['animation-speed-slow']
const boxBorder = `1px solid ${theme['ui-line-default']}`
const md = theme['default-spacer-md']
const sm = theme['default-spacer-sm']
const xs = theme['default-spacer-xs']
const brb = theme['border-radius-base']
const fbl = theme['field-bg-light']
const sc = theme['success-color']
const scBG = chroma.mix(fbl, sc, 0.25).css('hsl')
const scBGLite = chroma.mix(fbl, sc, 0.1)
const fblLite = chroma(fbl).brighten(0.1)

export const stratVisibleBox = ({ highlight }) => ({
  background: fbl,
  border: boxBorder,
  ...(highlight && {
    backgroundColor: scBG,
    borderColor: sc,
    outlineColor: sc,
  }),
  borderRadius: brb,
  margin: `${xs} ${xs} 0`,
  outline: 'transparent solid 3px',
  padding: '0',
  transition: `all ${animSlow} ease-in-out`,
})

export const tagCSS = { marginLeft: sm, placeSelf: 'center' }
export const pCSS = { margin: `0 ${sm} ${sm}` }

export const paramsContainer = ({ highlight }) => {
  const bg = highlight ? scBGLite : fblLite

  return {
    background: bg.css('hsl'),
    borderBottomLeftRadius: brb,
    borderBottomRightRadius: brb,
    borderTop: boxBorder,
    height: 37,
    padding: `${xs} ${xs} 0`,
    transition: `all ${animSlow} ease-in-out`,
  }
}

export const overlayTriggerNodeContainer = {
  display: 'grid',
  gap: '0',
  gridTemplateColumns: '1fr 35px',
  position: 'relative',
  width: '100%',
}

export const fullHeight = {
  alignItems: 'flex-end',
  height: '100%',
  width: '100%',
}
export const flexStyles = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}
export const defaultFlexStyles = {
  ...flexStyles,
}
export const fullHeightFlex = {
  ...defaultFlexStyles,
  ...fullHeight,
}

export const strategyTitle = { display: 'flex', margin: sm }

export const paramsGroupStyle = ({ width }) => ({
  height: theme['default-spacer-lg'],
  flexWrap: 'nowrap',
  overflow: 'auto',
  paddingBottom: xs,
  paddingLeft: 2,
  maxWidth: formatString(width - 60),
  ...customScrollbarCSS({ scrollbarThumb: { marginTop: xs } }),
})
export const paramsColStyles = { justifyContent: 'flex-start', width: 'auto' }

export const buttonGroup = {
  alignItems: 'flex-end',
  bottom: sm,
  right: `-${sm}`,
  position: 'absolute',
  '> span:first-child > .fiqds-button': {
    borderBottomLeftRadius: brb,
    borderTopLeftRadius: brb,
  },
}

export const strategyBoxHeight = 104

export const strategyContainerCSS = {
  alignItems: 'flex-end',
  alignSelf: 'baseline',
  display: 'flex',
  minHeight: formatString(strategyBoxHeight),
  width: '100%',
  position: 'relative',
  '&+ .strategy-scrollable-listitem': {
    marginTop: xs,
  },
}

export const strategyFormContainer = ({ findingWords }) => ({
  background: fbl,
  border: `1px solid ${theme['ui-line-default']}`,
  borderRadius: sm,
  bottom: md,
  height: 'auto',
  left: '75px',
  maxHeight: '80vh',
  maxWidth: 440,
  minHeight: 300,
  minWidth: 320,
  padding: `${sm} ${md}`,
  position: 'fixed',
  ...dropShadowCSS(),
  ...(!findingWords && {
    overflow: 'auto',
    ...customScrollbarCSS({
      width: sm,
      scrollbarThumb: {
        borderRadius: sm,
      },
    }),
  }),
})
export const strategyFormCloseIcon = {
  position: 'absolute',
  right: xs,
  top: sm,
}
export const saveBtn = { marginBottom: sm }

export const fieldset = {
  backgroundColor: theme['field-bg-light'],
  marginTop: md,
}
