import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import uiStore from 'lib/UiStore'
import {
  useFieldArray,
  useController,
  theme,
  Element,
  Typography,
  Label,
  Button,
  Input,
  Select,
} from '@fortressiq/fiq-ds'

import ControlledSelect from 'components/form/ControlledSelect'
import constructApplicationOptions from 'components/form/helpers/constructApplicationOptions'
import FieldWrapper from 'components/form/FieldWrapper'

import { secondarySignatureLevelLabelMap } from '../../helpers'

const { Text } = Typography

const secondarySignatureLevelOptions = {
  screen_signature: [
    { label: secondarySignatureLevelLabelMap.none, value: 'none' },
    { label: secondarySignatureLevelLabelMap.field, value: 'field' },
    { label: secondarySignatureLevelLabelMap.value, value: 'value' },
  ],
}

const SignaturesForm = ({
  groupsEnabled,
  setMustIncludesSignature,
  min,
  name,
  showSecondary,
  signatureLevel,
  title,
  primarySignatureLabel,
  group,
  signatureGroups,
  index,
  item,
  addGroup,
  addSignature,
  onChangeSignature,
  removeGroup,
  removeSignature,
  nestedPath,
  control,
}) => {
  const hideRemoveButton = min && min === index + 1
  const secondarySignatureLevel = item?.secondary_signature_level || 'none'
  const arrayName = `${name}.signatures[${index}]`

  const constructedValue = () => {
    const value = item.secondary_signature_level || 'none'
    return secondarySignatureLevelOptions.screen_signature.find(val => {
      return val.value === value
    })
  }

  return (
    <Element
      key={item.id}
      style={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme['ui-line-default']} `,
        padding: `${theme['default-spacer-md']} 0`,
      }}
    >
      <Element style={{ width: '100%', marginRight: theme['default-spacer-sm'] }}>
        <FieldWrapper>
          <Label>*{primarySignatureLabel}</Label>
          {signatureLevel === 'application' ? (
            <ControlledSelect
              constructData={constructApplicationOptions}
              constructSelectedValue={true}
              control={control}
              isMulti={false}
              name={`${arrayName}.primary`}
              placeholder='Select applications'
              portal={true}
              rules={{ required: 'This field is required' }}
              url='/applications/filters'
              valueKey='id'
              onChangeCallback={value => onChangeSignature(value, nestedPath, 'primary', index)}
            />
          ) : (
            <Input
              onChange={e => onChangeSignature(e.target.value, nestedPath, 'primary', index)}
              value={item.primary}
              placeholder={`${primarySignatureLabel}`}
            />
          )}
        </FieldWrapper>
        {showSecondary && (
          <FieldWrapper style={{ marginBottom: 0 }}>
            <Label>Signature Label</Label>
            <Element style={{ display: 'flex' }}>
              <Select
                isLoading={false}
                isMulti={false}
                name={`${arrayName}.secondary_signature_level`}
                options={secondarySignatureLevelOptions[signatureLevel]}
                portal={true}
                style={{ width: '130px', marginRight: theme['default-spacer-sm'] }}
                valueKey='value'
                value={constructedValue()}
                defaultValue={secondarySignatureLevelOptions[signatureLevel][0]}
                onChange={e => onChangeSignature(e.value, nestedPath, 'secondary_signature_level', index)}
              />
              {secondarySignatureLevel !== 'none' && (
                <Input
                  name={`${arrayName}.secondary`}
                  containerProps={{ style: { flex: 1 } }}
                  placeholder={secondarySignatureLevelLabelMap[secondarySignatureLevel]}
                  value={item.secondary}
                  onChange={e => onChangeSignature(e.target.value, nestedPath, 'secondary', index)}
                />
              )}
            </Element>
          </FieldWrapper>
        )}
      </Element>
      {!hideRemoveButton && (
        <Button icon='remove' onClick={e => removeSignature(e, nestedPath, index)} shape='circle' />
      )}
    </Element>
  )
}
export default SignaturesForm
