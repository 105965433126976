import React from 'react'
import { number, string, object, array, oneOfType } from 'prop-types'
import { observer } from 'mobx-react'
import store from './SVGDropZoneStore'

import css from './svgDropZone.scss'

/***
 * A component meant to be a drop target for an SVG ---
 * This works by tracking data and utilizing mouse events
 * The sourceTypes.js has an enumeration of drag object "sourceTypes"
 * This helps the component know if the drop source is meant for this target
 * the target has hover indicators
 * 'id' is required in the React code, this differs from sourceType in that this ids a particular drop zone
 *
 * Developers aiming to use this:
 *   must set:
 *   dragging state (draggingNode) and dragging sourceType (draggingType)
 *   in the svg's dragStart and drop handlers in order for this component
 *   to understand what is being dragged and that there is in fact a drag occurring
 *   (draggingX, draggingY)  can be set for drag source coordinates.
 *   In the drop handler, you can tell which target has what by 'id'
 *
 * props data -- ties to the data of the drag source
 * Once this is populated, there is a successful drop.
 * The dropped item rendered is in this.props.children or prop 'defaultDisplay'
 *
 * Component has a default style of a gray box with a dashed border
 ***/
@observer
class SVGDropZone extends React.Component {
  static propTypes = {
    id: string.isRequired,
    data: oneOfType([number, string, object, array]),
    width: number,
    height: number,
    defaultDisplay: oneOfType([number, string, object]),
    sourceType: string.isRequired,
    className: string,
  }

  static defaultProps = {
    data: null,
    width: null,
    height: null,
    defaultDisplay: '',
    className: '',
  }

  constructor(props) {
    super(props)

    this.state = { hovering: false }
  }

  componentWillUnmount() {
    const { id } = this.props
    store.clearZone(id)
  }

  render() {
    const { children, className, data, defaultDisplay, height, minHeight, type, width } = this.props
    const { hovering } = this.state

    return (
      <div
        className={`${css.dropBox} ${className || ''} ${type === store.draggingType ? css.hover : ''}`}
        style={{
          width: width ? `${width}px` : '100%',
          minHeight: `${minHeight}px` || 'initial',
          height: height ? `${height}px` : 'auto',
          lineHeight: `${height ? height - 22 : minHeight - 26}px`,
          background: hovering ? '#ddd' : '#eee',
        }}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onFocus={this.onMouseOver}
        onBlur={this.onMouseOut}
      >
        {data ? children : defaultDisplay}
      </div>
    )
  }

  onMouseOver = e => {
    const { id, sourceType } = this.props
    store.inDropZone.set(id, true)
    if (sourceType === store.draggingType && store.draggingNode) this.setState({ hovering: true })
  }

  onMouseOut = e => {
    e.stopPropagation()
    const { id } = this.props
    store.inDropZone.set(id, false)
    this.setState({ hovering: false })
  }
}

export default SVGDropZone
