import React, { useEffect, Fragment, useState } from 'react'
import { ReactFlowProvider } from 'reactflow'
import { useHeaderDispatch } from 'app/header/HeaderContext'
import { Element, Empty, Typography, Heading } from '@fortressiq/fiq-ds'
import { useQuery } from '@tanstack/react-query'

import Graph from './Graph'
import ProcessSelect from './ProcessSelect'
import cacheSettings from './cacheSettings'

import { getAllProcesses, getPaths } from './api'

import ExportProcess from './controls/ExportProcess'

import dfgColors from './dfgColors'

const DfGraph = ({ processId }) => {
  const headerDispatch = useHeaderDispatch()
  const [activePaths, setActivePaths] = useState([])
  const [highlightedPaths, setHighlightedPaths] = useState([])
  const [selectedStep, setSelectedStep] = useState(null)
  const [pathName, setPathName] = useState('')

  const { data: allProcesses, isLoading: isLoadingAllProcesses } = useQuery({
    queryKey: ['flowgraph-processes'],
    queryFn: getAllProcesses,
    keepPreviousData: true,
  })

  const {
    isLoading: isLoadingPaths,
    data: pathsData,
    isError,
  } = useQuery({
    queryKey: ['flow-graph', { processId }],
    enabled: !!processId,
    queryFn: getPaths,
    ...cacheSettings,
  })

  useEffect(() => {
    // will be triggered when loading new graph and if user deselect all active paths
    if (!isLoadingPaths && !isError && pathsData?.length !== 0 && activePaths.length === 0) {
      setActivePaths([{ pathId: pathsData[0].path_id, pathUuid: pathsData[0].path_uuid }])
    }
  }, [pathsData, isLoadingPaths, isError, activePaths.length])

  useEffect(() => {
    if (!isLoadingAllProcesses && allProcesses?.length !== 0) {
      setPathName(allProcesses.find(process => process.value.toString() === processId)?.label)
    }
  }, [allProcesses, processId, isLoadingAllProcesses])

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: 'Flow Graph',
      heading: (
        <Fragment>
          <Heading
            className='header-title'
            level={1}
            style={({ theme }) => ({ marginRight: `${theme['default-spacer-sm']} !important` })}
          >
            Explore a Process
          </Heading>
          <ProcessSelect allProcesses={allProcesses} isLoading={isLoadingAllProcesses} processId={processId} />
        </Fragment>
      ),
      toolbarActions:
        processId && !isError ? <ExportProcess key='export_process' graphId={processId} name={pathName} /> : null,
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch, allProcesses, isLoadingAllProcesses, processId, pathName, isError])

  const containerStyles = {
    background: dfgColors.backgroundGray,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  if (!processId)
    return (
      <Element style={containerStyles}>
        <Empty description={<Typography.Title level={4}>No Process selected</Typography.Title>} showImage={true} />
      </Element>
    )

  if (isError) {
    return (
      <Element style={containerStyles}>
        <Empty description={<Typography.Title level={4}>Flow Graph with ID {processId} not found</Typography.Title>} />
      </Element>
    )
  }
  return (
    <ReactFlowProvider>
      <Graph
        processId={processId}
        isLoadingPaths={isLoadingPaths}
        pathsData={pathsData}
        activePaths={activePaths}
        setActivePaths={setActivePaths}
        highlightedPaths={highlightedPaths}
        setHighlightedPaths={setHighlightedPaths}
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
      />
    </ReactFlowProvider>
  )
}

// Reset state when processId is changed by updating key
const DfGraphWrapper = ({
  match: {
    params: { processId },
  },
}) => <DfGraph processId={processId} key={`graph${processId}`} />

export default DfGraphWrapper
