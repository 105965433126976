import React from 'react'
import { Card, defaultDivergentScale, theme, useFela } from '@fortressiq/fiq-ds'

import {
  Charts,
  defaultChartConfigProps,
  defaultDataSourceChartProps,
  FusionCharts,
  FusionTheme,
  PowerCharts,
  ReactFC,
} from '../../viz/index'

ReactFC.fcRoot(FusionCharts, PowerCharts, Charts, FusionTheme)

const PieMetricCard = ({ title, observerEventTotals }) => {
  const { css } = useFela()
  const noOfEvents = observerEventTotals.length
  const data = {
    chart: {
      ...defaultDataSourceChartProps,
      caption: `${noOfEvents} ${title}`,
      paletteColors: String(defaultDivergentScale.colors(noOfEvents)),
      pieRadius: '70%',
      showLabels: '0',
      showLegend: noOfEvents > 3 ? '0' : '1',
      showPercentInToolTip: '1',
      showPercentValues: '0',
      theme: 'fusion',
    },
    data: observerEventTotals,
  }

  const chartConfig = {
    ...defaultChartConfigProps,
    height: 220,
    id: 'observers-event-totals',
    type: 'doughnut2d',
  }

  return (
    <Card paddedContent={false} style={{ padding: 0 }}>
      <ReactFC {...chartConfig} dataSource={data} className={css({ marginTop: theme['default-spacer-md'] })} />
    </Card>
  )
}

export default PieMetricCard
