import React, { useEffect, useState } from 'react'
import { arrayOf, shape, number, string } from 'prop-types'

import { CopyToClipboardButton, Overlay, useFela } from '@fortressiq/fiq-ds'
import { useHistory } from 'react-router-dom'

import api from 'lib/Api'

const widths = { max: 262, min: 262, width: 262 }
const heights = {
  height: 35,
  max: 35 * 5,
}

const config = {
  disableOutsideClick: false,
  dropdown: {
    closeOnRowClick: true,
    showArrow: false,
  },
}

const ObserversOverlay = ({ observerIds }) => {
  const [menu, setMenu] = useState([])
  const [showObserverList, setShowObserverList] = useState(false)
  const [observers, setObservers] = useState([])
  const [currentObserverName, setCurrentObserverName] = useState('')
  const { css, theme } = useFela()

  const observerIdsArray = observerIds ? observerIds.split(',') : []

  const history = useHistory()

  useEffect(() => {
    const getObservers = async () => {
      const { data } = await api.get('/observers')
      setObservers(data.observers)
      const defaultName = data.observers.find(o => o.id === parseInt(observerIdsArray[0], 10))?.name || 'All'
      setCurrentObserverName(defaultName)
    }
    getObservers()
  }, [])

  useEffect(() => {
    setMenu([
      {
        icon: !observerIds ? 'check' : null,
        onClick: () => {
          setCurrentObserverName('All')
          history.push(`/events${history.location.search}`)
        },
        text: 'All',
      },
      ...observers
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, {
            senstivity: 'base',
          })
        )
        .map(obs => ({
          icon: observerIdsArray.find(o => parseInt(o, 10) === obs.id) ? 'check' : null,
          onClick: () => {
            setCurrentObserverName(obs.name)
            history.push(`/events/${obs.id}${history.location.search}`)
          },
          text: obs.name,
        })),
    ])
  }, [history, observers, observerIds])

  const trigger = {
    setShowing: setShowObserverList,
    showing: showObserverList,
    text: `Observer: ${currentObserverName}`,
  }

  return (
    <div
      className={css({
        display: 'flex',
        marginLeft: theme['default-spacer-sm'],
        marginTop: theme['default-spacer-sm'],
      })}
    >
      <Overlay
        config={config}
        heights={heights}
        id='observer-filter'
        menu={menu}
        portal={false}
        trigger={trigger}
        widths={widths}
      />
      {observerIds && <CopyToClipboardButton buttonProps={{ type: 'link' }} textToCopy={currentObserverName} />}
    </div>
  )
}

ObserversOverlay.propTypes = {
  observerIds: arrayOf(
    shape({
      id: number,
      name: string,
    })
  ),
}

export default ObserversOverlay
