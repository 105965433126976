import { helpers, theme } from '@fortressiq/fiq-ds'

const { addPXSuffix } = helpers

const backgroundLight = `repeating-linear-gradient(45deg, ${theme.white}, ${theme['field-bg-dark']} 1px, ${theme.white} 0, ${theme.white} ${theme['default-spacer-xs']})`
const backgroundDark = `repeating-linear-gradient(45deg, ${theme['gray-tints-14']}, ${theme['gray-tints-6']} 1px, ${theme['gray-tints-14']} 0, ${theme['gray-tints-14']} ${theme['default-spacer-xs']})`
const borderLight = `2px solid ${theme['gray-tints-11']}`
const borderDark = `2px solid ${theme['ui-line-default']}`

export const containerBackground = ({ dark, containerHeight }) => {
  const backgroundCSS = dark ? backgroundDark : backgroundLight
  const borderCSS = dark ? borderDark : borderLight

  return {
    alignItems: 'center',
    background: backgroundCSS,
    border: borderCSS,
    display: 'flex',
    flexDirection: 'column',
    height: addPXSuffix(containerHeight),
    justifyContent: 'center',
    overflow: 'hidden',
    padding: theme['default-spacer-md'],
    position: 'relative',
    width: '100%',
  }
}

export const textContainer = ({ dark, fontSize }) => ({
  color: dark ? theme.white : theme['text-dark'],
  fontSize: fontSize,
  fontWeight: '600',
  marginTop: theme['default-spacer-sm'],
  textAlign: 'center',
})
