import React, { Fragment, memo } from 'react'

import { Button, Collapse, Icon, useFela } from '@fortressiq/fiq-ds'

import { controlsCSS, panelsCSS, toggleCSS } from './styles'

const { Panel } = Collapse

const SidePanel = ({ activeKey, collapse, defaultActiveKey, onChange, openKeys, panels, toggleCollapse }) => {
  const { css, theme } = useFela({ collapse })

  let sidePanelProps = {}
  if (onChange && activeKey) {
    sidePanelProps = {
      onChange,
      defaultActiveKy: activeKey,
    }
  }

  return (
    <Fragment>
      <div className={css(toggleCSS)}>
        <Button
          onClick={toggleCollapse}
          style={{
            borderColor: theme['dividing-line'],
            borderRadius: `${theme['border-radius-base']} 0 0 ${theme['border-radius-base']}`,
            borderRightColor: 'transparent',
            padding: theme['default-spacer-sm'],
          }}
          type='ghost'
        >
          <Icon color={theme.black} icon={`arrow${collapse ? 'left' : 'right'}`} size='nested' />
        </Button>
      </div>
      {!!panels && (
        <div className={css(controlsCSS)}>
          <Collapse
            bordered={false}
            expandIconPosition='right'
            openKeys={openKeys || defaultActiveKey}
            {...sidePanelProps}
          >
            {panels.map(({ header, key, panel }) => (
              <Panel
                bordered={false}
                className={css(panelsCSS)}
                header={header}
                headerStyle={{ borderLeftColor: 'transparent !important' }}
                key={key}
                panelKey={key}
              >
                {panel}
              </Panel>
            ))}
          </Collapse>
        </div>
      )}
    </Fragment>
  )
}

export default memo(SidePanel)
