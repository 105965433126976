// TODO: Respect user timezones.

class Localizer {
  constructor(locale = 'en-US') {
    this.locale = locale
  }

  setLocale(locale) {
    this.locale = locale
  }

  asBoolean(input) {
    return input ? 'Yes' : 'No'
  }

  asCurrency(input, currency = 'USD') {
    if (isEmpty(input)) return ''
    const output = Number(input)

    const formatter = new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    return formatter.format(output)
  }

  asDate(input) {
    if (isEmpty(input)) return ''
    const output = new Date(input)

    const formatter = new Intl.DateTimeFormat(this.locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })

    return formatter.format(output)
  }

  asShortDate(input) {
    if (isEmpty(input)) return ''
    const output = new Date(input)

    const formatter = new Intl.DateTimeFormat(this.locale, {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    })

    return formatter.format(output)
  }

  asShortestDate(input) {
    if (isEmpty(input)) return ''
    const output = new Date(input)

    const formatter = new Intl.DateTimeFormat(this.locale, {
      month: 'short',
      day: 'numeric',
    })

    return formatter.format(output)
  }

  asDateTime(input) {
    if (isEmpty(input)) return ''
    const output = new Date(input)

    const formatter = new Intl.DateTimeFormat(this.locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })

    return formatter.format(output)
  }

  asShortDateTime(input) {
    if (isEmpty(input)) return ''
    const output = new Date(input)

    const formatter = new Intl.DateTimeFormat(this.locale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })

    return formatter.format(output)
  }

  asShortestDateTime(input) {
    if (isEmpty(input)) return ''
    const output = new Date(input)

    const formatter = new Intl.DateTimeFormat(this.locale, {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })

    return formatter.format(output)
  }

  asPreciseTime(input) {
    if (isEmpty(input)) return ''
    const output = new Date(input)

    const formatter = new Intl.DateTimeFormat(this.locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })

    return formatter.format(output)
  }

  asTime(input) {
    if (isEmpty(input)) return ''
    const output = new Date(input)

    const formatter = new Intl.DateTimeFormat(this.locale, {
      hour: 'numeric',
      minute: 'numeric',
    })

    return formatter.format(output)
  }

  asDecimal(input) {
    if (isEmpty(input)) return ''
    const output = Number(input)

    const formatter = new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return formatter.format(output)
  }

  asInteger(input) {
    if (isEmpty(input)) return ''
    const output = Number(input)

    const formatter = new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    return formatter.format(output)
  }

  asCount(input) {
    return `${input}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
}

const isEmpty = input => input === null || input === undefined || input === ''

export default new Localizer()
