import PropTypes from 'prop-types'
import { theme } from '@fortressiq/fiq-ds'

export const cardTitleStyle = {
  height: '36px',
}

export const filtersInit = () => {
  const today = new Date()
  const defaultStartDate = new Date()
  defaultStartDate.setDate(today.getDate() - 14)

  return {
    start_day: defaultStartDate.toLocaleDateString('en-CA'),
    end_day: today.toLocaleDateString('en-CA'),
    windows_username: [],
  }
}

export const creditsDataProp = PropTypes.arrayOf(
  PropTypes.shape({
    credit: PropTypes.number,
    event_log_count: PropTypes.number,
    observed_at_day: PropTypes.string,
    tenant_id: PropTypes.number,
    windows_username: PropTypes.string,
  })
)

export const filtersProp = PropTypes.shape({
  start_day: PropTypes.string,
  end_day: PropTypes.string,
  /* eslint-disable-next-line react/forbid-prop-types */
  windows_username: PropTypes.arrayOf(PropTypes.object),
})

export const colorThemes = {
  blue: {
    card: theme['blue-tints-13'],
    icon: theme['blue-tints-10'],
  },
  green: {
    card: theme['green-tints-13'],
    icon: theme['green-tints-10'],
  },
}
