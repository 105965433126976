import React, { Fragment, useState, useEffect } from 'react'
import api from 'lib/Api'
import { Button, Input, Select, theme } from '@fortressiq/fiq-ds'
import controlTypeOptions from 'lib/controlTypeOptions'

const ENTER = 13

const AnnotationControls = ({ selectedEvent, updateSelectedEvent }) => {
  const [fieldValue, setFieldValue] = useState()
  const [controlTypeValue, setControlTypeValue] = useState()

  useEffect(() => {
    if (selectedEvent) {
      setFieldValue(selectedEvent.field || '')
      setControlTypeValue(selectedEvent.controlType || null)
    }
  }, [selectedEvent])

  const updateEvent = async () => {
    const { data } = await api.patch(`/event_logs/${selectedEvent.id}`, {
      field: fieldValue,
      control_type: controlTypeValue,
      annotation_type: 'user',
    })
    updateSelectedEvent(data.event)
  }

  const keyHandlerMap = { [ENTER]: updateEvent }

  const handleKeyDown = ({ which, keyCode = which }) => keyHandlerMap[keyCode] && keyHandlerMap[keyCode]()

  const onFieldChange = e => setFieldValue(e.target.value)

  const onControlTypeChange = e => {
    // e is not defined when clearing the select
    if (!e) {
      setControlTypeValue(null)
    } else {
      const controlType = typeof e.value === 'undefined' ? null : e.value
      setControlTypeValue(controlType)
    }
  }

  const value = controlTypeOptions.find(option => option.value === controlTypeValue) || null

  return (
    <Fragment>
      <Select
        dark={true}
        isClearable={!!controlTypeValue}
        onChange={onControlTypeChange}
        options={controlTypeOptions}
        placeholder='Control Type'
        size='small'
        style={{ minWidth: '150px' }}
        styles={{
          menuPortal: { zIndex: theme['z-modal-above'] },
        }}
        value={value}
        menuPortalTarget={document.body}
      />

      <Input dark={true} onChange={onFieldChange} value={fieldValue} onKeyDown={handleKeyDown} size='small' />

      <Button onClick={updateEvent} size='small' tabIndex='0' type='secondary'>
        Update
      </Button>
    </Fragment>
  )
}

export default AnnotationControls
