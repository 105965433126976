import React from 'react'
import PropTypes from 'prop-types'

import { Switch, useController } from '@fortressiq/fiq-ds'

const ControlledSwitch = ({ control, name, rules, defaultValue }) => {
  const {
    field: { onChange, ref, value, ...inputProps },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: defaultValue,
  })

  return (
    <Switch
      {...inputProps}
      checked={value}
      defaultChecked={value}
      onSwitch={({ SWITCH_STATE }) => onChange(SWITCH_STATE)}
      ref={ref}
    />
  )
}

ControlledSwitch.defaultProps = {
  rules: undefined,
  defaultValue: true,
}

ControlledSwitch.propTypes = {
  control: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  rules: PropTypes.shape(),
}

export default ControlledSwitch
