/**
 * Custom Hook
 * Validate value (text) of the input
 * @param {String} disableOnTyping if true switch showing messages to form level/context
 * * In this case use errorMessageSubmit namespace from the return
 * @return {Object}
 */
import { useState } from 'react'

const useInputValidation = disableOnTyping => {
  const configuration = {
    validate: {
      rules: {
        emptyState: ['', 'Please, set a Name for the process!'],
        minLength: [2, 'Name should be at least 3 characters!'],
      },
    },
  }

  const [nameState, setNameState] = useState('')
  const [nameErrorState, setNameErrorState] = useState('')
  const [nameErrorSubmitState, setNameErrorSubmitState] = useState('')

  const isValid = true
  const { emptyState, minLength } = configuration.validate.rules
  const [emptyStateValue, emptyStateError] = emptyState
  const [minLengthVal, minLengthError] = minLength

  const onChangeName = e => {
    setNameState(e.target.value)
    validationLogic(e.target.value)
  }

  const onSubmitForm = () => {
    if (nameState === emptyStateValue) {
      setNameErrorSubmitState(emptyStateError)
    } else {
      setNameErrorSubmitState(nameErrorState)
    }
  }

  // Show/hide and styling purposes
  let inputIsValid
  if (nameErrorState === '' && nameState) inputIsValid = true

  const validationLogic = name => {
    if (name.trim() === '' && isValid) {
      setNameErrorState(emptyStateError)
      return !isValid
    }

    if (name.length <= minLengthVal && isValid) {
      setNameErrorState(minLengthError)
      return !isValid
    }

    setNameErrorState('')
    return true
  }

  return {
    inputValue: nameState,
    onChangeInputValue: onChangeName,
    onSubmitForm,
    errorMsg: disableOnTyping ? null : nameErrorState,
    errorMessageSubmit: nameErrorSubmitState,
    inputIsValid,
    isValid: validationLogic,
  }
}

export default useInputValidation
