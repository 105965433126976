import React from 'react'

/**************************************
 * Wrapper Component that detects a click outside
 * props:  callback -- function to execute on clicking outside
 *         styles -- style this component
 *         className -- add style by className
 *         children -- this.props.children (this is a wrapper component)
 ****************************************/
class ClickOutside extends React.Component {
  constructor(props) {
    super(props)
    this.element = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('click', this.onClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClick, false)
  }

  render() {
    const { styles, className, children } = this.props

    return (
      <div style={styles} className={className} ref={this.element}>
        {children}
      </div>
    )
  }

  onClick = e => {
    const { callback } = this.props
    if (this.element.current && !this.element.current.contains(e.target)) {
      callback()
    }
  }
}

export default ClickOutside
