import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Table, useFela, theme, Icon, AdvancedCell } from '@fortressiq/fiq-ds'

import { sidebarCSS, sidebarToggleCSS } from './styles'

const Sidebar = ({ changeStep, step, toggleSignature, startStopRankings }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const { css } = useFela({ isSidebarOpen })

  const data = startStopRankings.map((ranking, i) => ({ index: i, ...ranking }))

  const columns = [
    {
      accessor: 'rank',
      align: 'center',
      Header: 'Rank',
      width: 20,
    },
    {
      accessor: 'screen_signature',
      Header: 'Signature',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
    {
      accessor: 'type',
      align: 'center',
      Header: 'Type',
      width: 20,
    },
    {
      accessor: 'user_selected',
      align: 'center',
      Cell: ({
        value,
        row: {
          original: { index },
        },
      }) => (
        <Checkbox checked={value} id={`${index}-checkbox`} onCheck={() => toggleSignature(index + 1)} size='small' />
      ),
      Header: 'Selected',
      width: 50,
    },
  ]

  const onRowClickHandler = useCallback(
    ({ index }) => {
      changeStep(index + 1)
    },
    [changeStep]
  )

  const rowSelection = useMemo(
    () => ({
      selectedRows: [step - 1],
      selectedRowKey: 'index',
    }),
    [step]
  )

  const containerConfig = {
    as: 'div',
    style: {
      borderRight: `1px solid ${theme['antd-card-border']}`,
      height: '100%',
      minWidth: '520px',
      overflow: 'auto',
      '> div .fiqds-table-thead': {
        background: theme.white,
        position: 'sticky',
        top: 0,
        zIndex: theme['z-base-above'],
      },
    },
  }

  const tableStyles = {
    table: { tr: { cursor: 'pointer', userSelect: 'none' } },
  }

  return (
    <div className={css(sidebarCSS)}>
      {isSidebarOpen && (
        <Table
          columns={columns}
          container={containerConfig}
          data={data}
          disablePagination={true}
          disableSortBy={true}
          id='process-boundary-signature-table'
          onRowClick={onRowClickHandler}
          rowSelection={rowSelection}
          styles={tableStyles}
        />
      )}
      <div className={css(sidebarToggleCSS)} onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        <Icon icon={`arrow${isSidebarOpen ? 'left' : 'right'}`} size={20} />
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  changeStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  toggleSignature: PropTypes.func.isRequired,
  startStopRankings: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.number,
      screen_signature: PropTypes.string,
      type: PropTypes.oneOf(['start', 'end']),
      user_selected: PropTypes.bool,
    })
  ).isRequired,
}

export default Sidebar
