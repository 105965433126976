import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useQuery } from '@tanstack/react-query'

import { Element, Card, combineRules } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import { getTimeElapsed } from 'lib/Time'
import Localizer from 'lib/Localizer'

import Loader from 'components/loaders/MainLoader'
import PathsByDuration from 'components/detailsSidePanel/pathStatistics/viz/PathsByDuration'
import PathsByInstances from 'components/detailsSidePanel/pathStatistics/viz/PathsByInstances'

import ProcessHeader from './sections/ProcessHeader'
import Overview from './sections/Overview'
import RunDetails from './sections/RunDetails'
import UserMetadata from './sections/UserMetadata'

import { processCardsWrapperCSS, demiProcessCardCSS, processCardCSS } from './styles/index'

export const getProcess = async ({ queryKey }) => {
  const [, { processId }] = queryKey
  const { data } = await api.get(`/df_graph/process_summary/${processId}`)
  return data
}

const transformProcessData = data => {
  return {
    overview: {
      pathsCount: data.process_info.paths,
      steps: data.process_info.steps,
      instanceCount: data.process_info.instances,
      eventCount: data.process_info.events,
      averageDuration: getTimeElapsed(data.process_info.average_duration),
      totalDuration: getTimeElapsed(data.process_info.total_duration),
      observerCount: data.process_info.observers.length,
    },
    process: {
      favorited: data.process_info.favorited,
      graphType: 'flowgraph',
      id: data.process_info.id,
      name: data.process_info.process_name,
      updatedAt: data.process_info.updated_at,
    },
    runDetails: {
      miningParams: data.mining_params,
      id: data.mining_run_detail.mined_run_id,
      createdAt: Localizer.asDate(data.mining_run_detail.mined_run_created_at),
      type: data.mining_run_detail.mining_type,
      miningType: data.mining_run_detail.mining_type,
      graphProcessCount: data.mining_run_detail.process_count,
      applicationCount: data.mining_run_detail.applications,
      filterRules: data.mining_run_detail.filter_rules,
    },
    userMetaData: {
      id: data.process_info.id,
      notes: data.process_info.notes,
      tags: data.process_info.tags,
      graphType: 'flowGraph',
    },
    pathsData: data.paths_by_duration_instance
      .sort((a, b) => a.path_label.toLowerCase() < b.path_label.toLowerCase())
      .map(path => ({
        average_duration: path.mean_duration,
        frequency: path.instance_count,
        name: path.path_label,
        key: path.id,
      })),
  }
}
const FlowGraph = ({ id }) => {
  const processQueryKey = ['flow-process', { processId: id }]

  const { data, isLoading, refetch } = useQuery({
    queryKey: processQueryKey,
    queryFn: getProcess,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 10,
    select: transformProcessData,
  })

  const updateProcess = () => {
    // could be optimized with optimistic updates later
    refetch()
  }
  if (isLoading) return <Loader />
  return (
    <Fragment>
      <ProcessHeader process={data.process} updateProcess={updateProcess} />
      <Overview overview={data.overview} />
      <Element style={processCardsWrapperCSS}>
        <RunDetails runDetails={data.runDetails} isLoading={isLoading} />
        <UserMetadata userMetaData={data.userMetaData} updateProcess={updateProcess} />
        <Card style={combineRules(processCardCSS, demiProcessCardCSS)} title='Paths By Duration'>
          <PathsByDuration paths={data.pathsData} />
        </Card>
        <Card style={combineRules(processCardCSS, demiProcessCardCSS)} title='Paths by Instances'>
          <PathsByInstances paths={data.pathsData} />
        </Card>
      </Element>
    </Fragment>
  )
}

FlowGraph.propTypes = {
  id: PropTypes.string.isRequired,
}

export default FlowGraph
