import React, { useEffect, useState } from 'react'
import { Button, Image, useFela } from '@fortressiq/fiq-ds'

import { useUserState } from 'context/UserContext'
import { imageContainerCss } from './styles'

const UnredactedImageWithOption = ({ src, containerCss, ...imageProps }) => {
  const currentUser = useUserState()
  const { canViewUnredactedImages } = currentUser
  const [showingUnredacted, setShowingUnredacted] = useState(false)
  const [imageToShow, setImageToShow] = useState(src)

  const { css } = useFela()

  useEffect(() => {
    setShowingUnredacted(false)
    setImageToShow(src)
  }, [src])

  if (!canViewUnredactedImages || !src || src?.length === 0) return <Image src={src} {...imageProps} />
  else
    return (
      <div className={css(containerCss, imageContainerCss)}>
        <Image src={imageToShow} {...imageProps} />
        <Button
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            if (showingUnredacted) {
              setImageToShow(src)
              setShowingUnredacted(false)
            } else {
              setImageToShow(`${src}?unredacted=1`)
              setShowingUnredacted(true)
            }
          }}
        >
          Show {showingUnredacted ? 'redacted' : 'unredacted'} Image
        </Button>
      </div>
    )
}

export default UnredactedImageWithOption
