import React, { useState } from 'react'
import { defaultDivergentScale, Element } from '@fortressiq/fiq-ds'
import useDimensions from 'react-cool-dimensions'
import PropTypes from 'prop-types'

import {
  Charts,
  defaultChartConfigProps,
  FusionCharts,
  FusionTheme,
  PowerCharts,
  ReactFC,
  zeroChartMarginPadding,
} from '../../../viz/index'

ReactFC.fcRoot(FusionCharts, PowerCharts, Charts, FusionTheme)

const StepsByApplication = ({ order, applications }) => {
  const [vizHeight, setHeight] = useState(300)
  const [vizWidth, setWidth] = useState(386)
  const { observe } = useDimensions({
    onResize: ({ height, width }) => {
      setHeight(height)
      setWidth(width)
    },
  })

  const containerProps = { ref: observe, style: { display: 'flex', height: '100%', alignItems: 'center' } }
  const data = order.map(item => ({
    label: item,
    value: applications[item].count,
  }))
  const noOfEvents = data.length
  const dataSource = {
    chart: {
      animateClockwise: '1',
      ...zeroChartMarginPadding,
      legendAllowDrag: '1',
      legendPosition: 'right-top',
      legendRows: 5,
      paletteColors: String(defaultDivergentScale.colors(noOfEvents)),
      pieRadius: '98%',
      showLabels: '0',
      showLegend: '1',
      showPercentInToolTip: '1',
      showPercentValues: '0',
      showValues: '0',
      theme: 'fusion',
    },
    data: data,
  }

  const chartConfig = {
    ...defaultChartConfigProps,
    height: vizHeight || '220',
    id: 'steps-by-app-donut',
    type: 'doughnut2d',
    width: vizWidth,
  }

  return (
    <Element {...containerProps}>
      <ReactFC {...chartConfig} dataSource={dataSource} />
    </Element>
  )
}

StepsByApplication.propTypes = {
  order: PropTypes.arrayOf(PropTypes.string).isRequired,
  applications: PropTypes.shape({
    count: PropTypes.number,
  }).isRequired,
}

export default StepsByApplication
