/* eslint-disable import/prefer-default-export */
import { theme } from '@fortressiq/fiq-ds'

export const hrCSS = () => ({
  borderColor: theme['ui-line-default'],
  borderStyle: 'solid',
  marginBottom: theme['default-spacer-lg'],
})

export const configureAppsContainerCSS = () => ({
  background: theme['field-bg-dark'],
  border: `1px solid ${theme['antd-card-border']}`,
  borderRadius: theme['border-radius-base'],
  padding: theme['default-spacer-md'],
})

export const tableContainerCSS = () => ({
  background: theme.white,
  borderRadius: theme['border-radius-base'],
  height: '150px',
  overflow: 'auto',
  marginTop: theme['default-spacer-md'],
})

export const transparentBG = {
  background: 'transparent',
}

export const emptyContainer = {
  alignItems: 'center',
  display: 'flex',
  height: '680px',
  justifyContent: 'center',
  maxHeight: '80vh',
}

export const errorCSS = () => ({
  color: theme['color-red'],
  fontSize: theme['font-size-xs'],
  margin: `${theme['default-spacer-xs']} 0`,
  '&:last-child': { marginBottom: '0' },
})
