import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Overlay } from '@fortressiq/fiq-ds'

import { useFetch } from 'lib/hooks'

import handleIntegration from 'app/admin/integrations/helpers/handleIntegration'

const IntegrationsDropdown = ({ objectId, disabled, integrationType }) => {
  const [showing, setShowing] = useState(false)

  const { data: { integrations = [] } = {} } = useFetch(`/dynamic_integrations/${integrationType}/list`)

  const options = integrations.map(integration => ({
    disabled: disabled,
    onClick: () => handleIntegration({ integrationId: integration.id, objectId: objectId }),
    text: integration.title,
  }))

  const trigger = {
    showing: showing,
    setShowing: setShowing,
    text: 'Integrations',
    type: 'default',
  }

  const heights = {
    max: 35 * 2,
  }
  const widths = {
    max: 200,
    min: 200,
    width: 200,
  }

  if (integrations.length === 0) return null
  return <Overlay heights={heights} id='integration-button-overlay' menu={options} trigger={trigger} widths={widths} />
}

export default IntegrationsDropdown

IntegrationsDropdown.defaultProps = {
  disabled: false,
}

IntegrationsDropdown.propTypes = {
  integrationType: PropTypes.oneOf(['application', 'cycle', 'event', 'mining_run', 'observer', 'process', 'tenant'])
    .isRequired,
  disabled: PropTypes.bool,
  objectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
}
