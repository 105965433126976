import { shallow } from 'enzyme'
import React from 'react'

import { mockGraphColors } from '../../../../lib/test_helpers/mockGraphColors.js'
import PlaybackControls from '../PlaybackControls'

jest.mock('@fortressiq/fiq-ds-themes/dist/js/graphColors.js', () => mockGraphColors)

describe('PlaybackControls Component', () => {
  it('should render without throwing an error', () => {
    const component = shallow(<PlaybackControls />)
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
})
