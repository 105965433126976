import React from 'react'
import { Label, theme, Element, Typography } from '@fortressiq/fiq-ds'

import TextToInput from 'components/inputs/TextToInput'

const pathRowStyle = {
  border: `1px solid ${theme['legacy-color-gray2']}`,
  marginBottom: theme['default-spacer-sm'],
  padding: theme['default-spacer-sm'],
}

const { Text } = Typography

class EditDecisionInPlace extends React.Component {
  render() {
    const { name, rules, onEnter } = this.props

    return (
      <React.Fragment>
        <Element style={{ marginBottom: theme['default-spacer-sm'] }}>
          <Label>Conditional</Label>
          <TextToInput name='label' value={name} emptyText='Empty' onEnter={onEnter} />
        </Element>
        {rules && <Text>Paths</Text>}
        {rules &&
          rules.map((rule, i) => {
            const labels = rule.labels()
            const target = rule.getTargetElement()
            return (
              <Element style={pathRowStyle} key={i}>
                <Label>{target ? target.attr('label/text') : '--'}</Label>
                <TextToInput
                  name={`rule_${i}`}
                  value={labels.length > 0 ? labels[0].attrs.label.text : ''}
                  emptyText='Empty label'
                  onEnter={(ruleName, value) => this.onEnter(ruleName, value, rule)}
                />
              </Element>
            )
          })}
      </React.Fragment>
    )
  }

  onEnter = (name, value, rule) => {
    const { generateLabel, onEnter, rules } = this.props

    rule.removeLabel(0)
    rule.appendLabel(generateLabel(value))
    onEnter('rules', rules)
  }
}

export default EditDecisionInPlace
