/* eslint-disable import/prefer-default-export */

// permissions stored in binary [title][clipboard][keystroke][screenshots]
// uses 32767 to denote capture all
export const getObserverPermissions = neoStatusCode => {
  const permissions = decimalToBinary(neoStatusCode)
  return {
    title: permissions[0] === '1',
    clipboard: permissions[1] === '1',
    keystroke: permissions[2] === '1',
    screenshot: permissions[3] === '1',
  }
}

const decimalToBinary = decimal => {
  return Number(decimal).toString(2).padStart(4, '0')
}
