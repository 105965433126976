import React from 'react'
import { observer } from 'mobx-react'
import { Dropdown, graphColors, Menu, MenuItem, Icon, useFela } from '@fortressiq/fiq-ds'

import store from '../stores/processExplorerStore'

import { dropdownContainer, dropdownText, groupDetails, groupName, multi, row } from './styles/groupsControl'

const GroupsControlBase = props => {
  const { disabled } = props
  const { css } = useFela()

  const menu = (
    <Menu className={css(dropdownContainer)}>
      <MenuItem key='highlight_all' onClick={store.groups.highlightAll}>
        Highlight All
      </MenuItem>
      {store.groups.groupList.map((group, index) => {
        const borderColor = group.titleColor || graphColors[index % graphColors.length]
        const style = { border: `1px solid ${borderColor}` }

        if (group.visible) style.background = borderColor

        return (
          <MenuItem key={index} onClick={() => store.groups.toggleGroup(group.id)}>
            <div className={css(row)}>
              <div className={css(groupDetails)}>
                <span className={css(multi)} style={style} />
                <div className={css(groupName)}>{group.name}</div>
              </div>
              {group.visible && <Icon icon='check' size={11} />}
            </div>
          </MenuItem>
        )
      })}
    </Menu>
  )

  const visibleGroups = store.groups.groupList.reduce((acc, group) => {
    if (group.visible) {
      acc = `${acc === '' ? '' : `${acc},`} ${group.name}`
    }
    return acc
  }, '')

  return (
    <Dropdown
      trigger={{ type: 'click' }}
      disabled={disabled}
      overlay={menu}
      id='process-explorer-groups'
      layer={{ placement: 'bottom-start' }}
    >
      <div className={css(dropdownText)}>Display Group(s): {visibleGroups || 'None'}</div>
    </Dropdown>
  )
}

export default observer(GroupsControlBase)
