import React, { useState, useEffect } from 'react'
import { DateTimePicker } from '@fortressiq/fiq-ds'

const QueryBuilderDatetime = ({ className, handleOnChange, value: v }) => {
  const defaultDate = v ? new Date(v) : new Date()
  const [value, setValue] = useState(defaultDate)

  //set default value
  useEffect(() => {
    const isoStringVal = new Date(defaultDate).toISOString()
    handleOnChange(isoStringVal)
  }, [])

  const handleChange = val => {
    const { start } = val
    const isoStringVal = new Date(start).toISOString()
    setValue(start)
    handleOnChange(isoStringVal)
  }

  return <DateTimePicker className={className} onChange={handleChange} placeholder='Select a Date...' value={value} />
}

export default QueryBuilderDatetime
