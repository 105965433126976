import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Typography } from '@fortressiq/fiq-ds'
import getStatusColor from 'lib/statusMap'

const { Text } = Typography

const StatusBadge = ({ status, statusMessage }) => (
  <Text ellipsis={true}>
    <Badge status={getStatusColor(status)} />
    {statusMessage || status}
  </Text>
)

StatusBadge.defaultProps = {
  status: undefined,
  statusMessage: undefined,
}
StatusBadge.propTypes = {
  status: PropTypes.string,
  statusMessage: PropTypes.string,
}

export default StatusBadge
