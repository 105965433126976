import joint from 'vendor/rappid.js'

import api from 'lib/Api'
import pddGenProgress from 'app/pdds/PddGenToast'

import store from '../store/DiagramComposerStore'

export const pddGenProgressCallbackId = 'pddGenProgress'

const renderIframe = document.createElement('iframe')

// Wrap jointjs toPNG callback in an ES6 promise.
const toPNG = paper => {
  return new Promise(resolve => {
    paper.toPNG(png => resolve(png))
  })
}

const rasterize = diagramData => {
  const graph = new joint.dia.Graph()
  const paper = new joint.dia.Paper({
    el: renderIframe.contentDocument.body,
    width: 600,
    height: 600,
    model: graph,
    defaultLink: new joint.shapes.app.Link(),
    linkConnectionPoint: joint.util.shapePerimeterConnectionPoint,
    interactive: false,
  })

  graph.fromJSON(diagramData)
  joint.layout.DirectedGraph.layout(graph, {
    setLinkVertices: true,
    rankDir: 'TB',
    marginX: 100,
    marginY: 100,
  })

  return toPNG(paper)
}

const rasterizeGraphs = graphData => Promise.all(Object.values(graphData).map(rasterize))

const getGraphsData = cells =>
  cells.reduce(async (acc, cell) => {
    if (cell.processId) {
      const {
        data: { flowchart },
      } = await api.get(`/flowcharts/new?graph_process_id=${cell.processId}`)
      acc[cell.processId] = flowchart.diagramData
    }
    return acc
  }, {})

const generatePdd = async ({ flowchartId, specId, includeFullImages }) => {
  store.addGeneratingPdd(specId)
  document.body.appendChild(renderIframe)

  store.saveDiagram(flowchartId, store.diagramName)

  const diagramData = store.graph.toJSON()

  // 1. Collect data
  pddGenProgress({ step: 'Collecting Data' })
  const graphsData = await getGraphsData(diagramData.cells)

  // 2. Rasterize the overview diagram.
  pddGenProgress({ step: 'Rasterizing Overview' })
  const overview = await rasterize(diagramData)

  // 3. Rasterize all processes in the overview diagram.
  pddGenProgress({ step: 'Rasterizing Graphs' })
  const graphs = await rasterizeGraphs(graphsData)

  document.body.removeChild(renderIframe)

  // 4. Create the ProcessSpec (PDD).
  return api.post('/process_specs', {
    flowchartId,
    overview,
    graphs,
    callbackId: pddGenProgressCallbackId,
    includeFullImages,
  })
}

export default generatePdd
