import React, { useState } from 'react'
import { func } from 'prop-types'
import { Input } from '@fortressiq/fiq-ds'

const AppListInput = ({ handleAddList }) => {
  const [appListString, setAppListString] = useState('')

  const addApps = ({ target: { value } }) => {
    handleAddList(appListString || value)
    setAppListString('')
  }

  return (
    <Input
      button={true}
      buttonProps={{
        children: 'Add',
        disabled: appListString === '',
        onClick: addApps,
        type: 'secondary',
      }}
      onChange={({ target: { value } }) => setAppListString(value)}
      onEnter={e => addApps(e)}
      id='configObservers-addApplications'
      placeholder='Add Applications (separated by comma)'
      style={{ width: '100%' }}
      value={appListString}
    />
  )
}

AppListInput.propTypes = {
  handleAddList: func.isRequired,
}

export default AppListInput
