import fork from 'images/process_explore/percentFork.svg'

import renderApplicationIcon from 'components/applicationIcon/ApplicationIconD3.js'
import ActionRenderer from './sections/ActionListRenderer'

import css from '../../processExploration.scss'

import { ACTIONS } from '../../common/constants'

import store from '../../../stores/processExplorerStore'

export default ({ nodeEnter, iconWidth }) => {
  const node = nodeEnter
    .append('g')
    .attr('class', 'collapsedNodeGroup')
    .attr('transform', 'translate(0, 0)')
    .style('display', d => {
      return d.data.type === 'collapse' || d.data.activities.length > 1 ? 'block' : 'none'
    })

  const controlGroup = node
    .append('g')
    .attr('class', 'controls')
    .style('display', d => {
      if (d.data.type === 'start' || d.data.type === 'end') return 'none'
      return 'block'
    })

  //******* viewing indicator ******/
  controlGroup
    .append('circle')
    .attr('class', 'viewingDot')
    .attr('r', 4)
    .attr('cx', store.nodeWidth + (iconWidth - (iconWidth - store.nodeSizes.normal.height) * 2))
    .attr('cy', 12)
    .style('fill', d => {
      return store.viewingNode && store.viewingNode.id === d.id ? '#000' : 'transparent'
    })

  node.append('text').attr('font-size', '10px').attr('font-weight', '600').attr('x', 12).attr('y', 20).text('COLLAPSE')

  // Add labels for the nodes
  const fo = controlGroup
    .append('foreignObject')
    .attr('class', 'nodeName')
    .attr('width', store.nodeWidth)
    .attr('height', 30)
    .attr('x', 12)
    .attr('y', 22)
    .attr('class', 'eventFO')

  fo.append('xhtml:div')
    .attr('class', `eventFOhtml ${css.eventName}`)
    .html(d => {
      return d.isRequired === true
        ? `<span class='${css.required}'>*</span> <span>${d.data.name || '--'}</span>`
        : d.data.name || '--'
    })

  /*** frequency group ***/
  const freqGroup = controlGroup.append('g')

  freqGroup
    .append('rect')
    .attr('width', '15')
    .attr('height', '7')
    .attr('rx', '1')
    .attr('ry', '1')
    .attr('x', 13)
    .attr('y', 50)
    .style('stroke', '#2E3440')
    .style('fill', 'none')

  freqGroup
    .append('text')
    .attr('x', 35)
    .attr('y', 56)
    .attr('width', store.nodeWidth)
    .style('fill', '#2e3440')
    .style('font-size', '10')
    .text(d => d.data.value)

  /*** percent group ***/
  const percentGroup = controlGroup.append('g').attr('class', 'percentGroup')

  percentGroup
    .append('svg:image')
    .attr('xlink:href', fork)
    .attr('width', '16')
    .attr('height', '10')
    .attr('x', 70)
    .attr('y', 47)

  percentGroup
    .append('text')
    .attr('x', 92)
    .attr('y', 56)
    .style('fill', '#2e3440')
    .style('font-size', '10')
    .attr('class', 'percentOutShown')
    .text('100%')

  ActionRenderer({ nodeEnter, nodeWidth: store.nodeWidth, iconWidth })

  // /******* action icon ******/
  const actionIcon = node
    .append('g')
    .attr('class', 'actionIconG')
    .style('display', d => {
      if (d.data.type === 'start' || d.data.type === 'end') return 'none'
      return 'block'
    })

  actionIcon
    .append('rect')
    .attr('height', 16)
    .attr('width', 26)
    .attr('rx', 3)
    .attr('ry', 3)
    .attr('x', 194)
    .attr('y', 80)
    .style('fill', '#565678')

  actionIcon
    .append('text')
    .attr('class', 'actionIcon')
    .attr('fill', '#fff')
    .style('font-size', '8px')
    .style('font-weight', '700')
    .attr('x', 199)
    .attr('y', 91)
    .text(d => {
      return d.data.activities.length > 0 && d.data.activities[0].action ? ACTIONS[d.data.activities[0].action] : '--'
    })

  const appIconContainer = node
    .append('g')
    .attr('class', d => `CappIconG nodeId_${d.data.id}`)
    .attr('transform', `translate(${store.nodeWidth + 47}, 84)`)
    .style('display', d => {
      if (d.data.type === 'start' || d.data.type === 'end') return 'none'
      return 'block'
    })

  renderApplicationIcon({
    iconContainer: appIconContainer,
    applicationIndex: 0,
    radius: 13,
  })
}
