import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, useController } from '@fortressiq/fiq-ds'

const ControlledCheckbox = ({ children, control, name, rules, defaultValue, style }) => {
  const {
    field: { onChange, ref, value, ...inputProps },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: defaultValue,
  })
  return (
    <Checkbox
      {...inputProps}
      checked={value}
      defaultChecked={value}
      inputRef={ref}
      onCheck={e => {
        onChange(e)
      }}
      style={style}
    >
      {children}
    </Checkbox>
  )
}

ControlledCheckbox.defaultProps = {
  rules: undefined,
  defaultValue: true,
}

ControlledCheckbox.propTypes = {
  control: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  rules: PropTypes.shape(),
}

export default ControlledCheckbox
