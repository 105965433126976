import React from 'react'
import { useHistory } from 'react-router-dom'

import ExportButton from 'components/ExportButton/ExportButton'
import callbackStore from 'stores/callback'

const ExportDropdown = ({ graphId, name, instanceIndexes, pathId }) => {
  const history = useHistory()

  const exportCallback = () => {
    history.push('/event-log-exports')
  }

  const options = [
    {
      text: 'Export Table (.csv)',
      exportType: 'graph',
      exportCallback: exportCallback,
      exportArgs: { graphId, instanceIndexes, exportType: 'events', pathId },
      disabled: false,
    },
    {
      text: 'Export Screenshots (.jpeg)',
      exportType: 'graph',
      exportArgs: { graphId, instanceIndexes, exportType: 'images', pathId },
      exportCallback: exportCallback,
      disabled: false,
    },
  ]

  return (
    <ExportButton
      buttonText='Export Instances as...'
      callbackStore={callbackStore}
      defaultExportName={name}
      exportType='graph'
      options={options}
    />
  )
}

export default ExportDropdown
