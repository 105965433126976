// TODO: Further refactor to use Overlay without any modifications.
import React, { memo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { combineRules, Header, Heading, Overlay, ApplicationIcon as UserAvatar, useFela } from '@fortressiq/fiq-ds'

import Can, { roleRouteCheck } from 'components/can/Can'
import { useUserState } from 'context/UserContext'

import api from 'lib/Api'
import localStorage from 'lib/Storage'
import uiStore from 'lib/UiStore'
import { clearUseLocalStorage } from 'lib/hooks'

import TopNavHelp from '../topNavHelp/TopNavHelp'
import { findHelpTip } from '../topNavHelp/helpTips'
import { useHeaderState } from './HeaderContext'

import {
  dropdownContainerCSS,
  dropdownItemCSS,
  menuLinkCSS,
  signOutCSS,
  tenantNameCSS,
  tenantWrapCSS,
} from './styles/index'

const routes = [
  {
    title: 'Manage Account',
    to: '/admin/account',
  },
  {
    title: 'Manage Users',
    to: '/admin/users',
  },
  {
    title: 'Support',
    to: '/support',
  },
]

const superUserRoutes = [
  {
    title: 'Sidekiq',
    to: '/admin/sidekiq',
  },
  {
    title: 'Tank',
    to: '/admin/tank',
  },
  {
    title: 'Tenants',
    to: '/admin/tenants',
  },
]

const zIndexForAvatarDropdown = 2042
const AppHeader = () => {
  const { email, name, roles } = useUserState()
  const [showing, setShowing] = useState(false)
  const { pathname } = useLocation()
  const pageHelp = findHelpTip(pathname)
  const onLogout = async () => {
    await api.delete('/users/sign_out')
    localStorage.clearOnLogout()
    clearUseLocalStorage()
    location = '/users/sign_in'
  }
  const { heading, title, toolbar, toolbarActions } = useHeaderState()
  const { css } = useFela()

  const placement = {
    offset: [24, 11],
    points: ['tr', 'bc'],
  }
  const trigger = {
    node: (
      <UserAvatar name={name || email} onClick={() => setShowing(!showing)} size={30} style={{ cursor: ' pointer' }} />
    ),
    showing: showing,
    setShowing: setShowing,
    width: '30px',
  }
  const widths = {
    max: 140,
    width: 140,
  }
  const config = {
    content: {
      style: {
        alignItems: 'flex-start',
        borderTopLeftRadius: '0 !important',
        boxShadow: '-2px 2px 6px hsla(0, 0%, 0%, 0.05)',
        maxHeight: 'auto',
        '> article > nav': {
          marginTop: '0',
        },
        position: 'absolute',
      },
    },
    dropdown: {
      showArrow: false,
    },
  }
  const onClick = () => setShowing(!showing)

  const listItemTextClasses = classNames([css(menuLinkCSS), 'fiqds-overlay-dropdown-menuitem-text'])
  const listItemClasses = classNames('drodpown-menu-item', css(combineRules(dropdownItemCSS, menuLinkCSS)))

  const menu = (
    <nav className={classNames('fiqds-overlay-dropdown', css(dropdownContainerCSS))}>
      {routes.map(item => (
        <Can route={item.to} key={item.to}>
          <div className={listItemClasses} key={`admin-nav-${item.to}`}>
            <Link onClick={onClick} to={item.to} className={listItemTextClasses}>
              {item.title}
            </Link>
          </div>
        </Can>
      ))}
      {superUserRoutes.map(item => (
        <Can route={item.to} key={item.to}>
          <div className={listItemClasses} key={`admin-nav-${item.to}`}>
            <Link onClick={onClick} to={item.to} className={listItemTextClasses}>
              {item.title}
            </Link>
          </div>
        </Can>
      ))}
      <Can route='/admin/integrations'>
        <div className={listItemClasses} key='admin-nav-/admin/integrations'>
          <Link onClick={onClick} to='/admin/integrations' className={listItemTextClasses}>
            Integrations
          </Link>
        </div>
      </Can>
      <Can route='/admin/domain-list'>
        <div className={listItemClasses} key='admin-nav-/admin/domain-list'>
          <Link onClick={onClick} to='/admin/domain-list' className={listItemTextClasses}>
            Domain List
          </Link>
        </div>
      </Can>
      <Can route='/admin/application-list'>
        <div className={listItemClasses} key='admin-nav-/admin/application-list'>
          <Link onClick={onClick} to='/admin/application-list' className={listItemTextClasses}>
            Application List
          </Link>
        </div>
      </Can>
      <div onClick={onLogout} className={classNames(listItemClasses, css(signOutCSS))} key='signout'>
        Sign Out
      </div>
    </nav>
  )
  const headerOverlayID = 'header-avatar-dropdown'

  return (
    <Header
      metaTitle={title ? `${title} - Process Discovery` : null}
      title={
        typeof heading === 'string' ? (
          <Heading className='header-title' level={1}>
            {heading}
          </Heading>
        ) : (
          heading
        )
      }
      toolbar={toolbar}
      toolbarActions={toolbarActions}
      titleActions={[
        <div className={css(tenantWrapCSS)} key='tenant'>
          {roleRouteCheck('/admin/account', roles) ? (
            <Link className={css(tenantNameCSS)} to='/admin/account'>
              {uiStore.tenant.name || '--'}
            </Link>
          ) : (
            <span className={css(tenantNameCSS)}>{uiStore.tenant.name || '--'}</span>
          )}
        </div>,
        !!pageHelp && <TopNavHelp key='help' pageHelp={pageHelp} />,
        <Overlay
          basic={true}
          closeIcon={false}
          config={config}
          content={menu}
          id={headerOverlayID}
          key={headerOverlayID}
          noPadding={true}
          placement={placement}
          trigger={trigger}
          widths={widths}
          zIndex={zIndexForAvatarDropdown}
        />,
      ]}
    />
  )
}

export default memo(AppHeader)
