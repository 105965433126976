//constants for process explorer

export const ACTIONS = {
  MOUSE_CLICK: 'CLK',
  mouseClick: 'CLK',
  mouse: 'CLK',
  keyboard: 'KEY',
  keyPress: 'KEY',
  KEY_PRESS: 'KEY',
  INPUT_TEXT: 'TXT',
  inputText: 'TXT',
}

export default { ACTIONS }
