export const amountZoom = ({ theme }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  fontSize: theme['default-spacer-sm'],
  flexDirection: 'column',
})

export const chevron = () => ({
  marginTop: '3px',
})

export const zoomButton = ({ theme }) => ({
  cursor: 'pointer',

  '&:first-child': {
    paddingTop: '10px',
  },

  '&:active': {
    background: theme['btn-flat-border'],
    opacity: '0.8',
    transition: '0.2s ease',
  },
})

export const zoomContainer = ({ theme }) => ({
  background: theme.white,
  borderRadius: '20px',
  boxShadow: '0 0 6px 0 rgba(157, 166, 176, 0.6)',
  position: 'absolute',
  right: '20px',
  top: '15px',
  userSelect: 'none',
  width: theme['default-spacer-lg'],
  zIndex: theme['z-modal-below'] - 1,

  '& > div': {
    alignItems: 'center',
    borderBottom: `1px solid ${theme['btn-flat-border']}`,
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme['default-spacer-sm']} 0`,

    '&:last-child': {
      border: 'none',
      paddingTop: theme['default-spacer-sm'],
    },
  },

  '& .fiqds-overlay-content': {
    right: '-6px !important',
    top: '36px !important',
    left: 'initial !important',
    position: 'absolute',
  },
})
