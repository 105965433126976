import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ApplicationIcon, Button, Element, Heading, Input, theme, useModal, useNotification } from '@fortressiq/fiq-ds'
import { string, number, shape, func } from 'prop-types'

import api from 'lib/Api'

import { addPXSuffix } from 'helpers'
import { useHeaderDispatch } from '../../header/HeaderContext'
import { headerColumnCSS, screenViewerHeaderCSS } from './styles'

const errorStyle = {
  bottom: 0,
  left: theme['default-spacer-lg'],
  position: 'absolute',
  fontSize: '10px',
  color: theme['error-color'],
}

const ScreenViewerHeader = ({ screenSignature, application, screen = {}, setScreen }) => {
  const headerDispatch = useHeaderDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const [screenData, setScreenData] = useState(screen)
  const { addNotification } = useNotification()
  const [nameError, setNameError] = useState('')
  const { addModal } = useModal()

  const saveScreenData = async () => {
    setIsSaving(true)

    const { data } = await api.patch(`/applications/${application.tenantApplicationId}/screens/${screenSignature}`, {
      applicationUuid: application.tenantApplicationId,
      ...screenData,
    })

    setScreen(screenData)
    setIsSaving(false)

    addNotification({
      description: `${data.screen.name ? data.screen.name : 'Screen'} has been saved!`,
      message: 'Saved',
      type: 'success',
      autoClose: 3000,
    })
  }

  const updateScreenData = ({ target: { value, name } }) => {
    setScreenData({ ...screenData, [name]: value })
    if (name === 'name' && value.trim() === '') setNameError('Please enter a screen name')
    if (name === 'name' && value.trim() !== '') setNameError('')
  }

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: 'Screen Viewer',
      heading: 'Signature Screen Viewer',
    })

    setScreenData(screen)

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch, screen])

  const formValid = screenData.name && screenData.name.trim() !== ''

  const handleClear = async () => {
    setIsSaving(true)
    await api.delete(`/applications/${application.id}/screens/${screenSignature}`, {
      applicationUuid: application.tenantApplicationId,
    })
    setIsSaving(false)
    const newScreenData = { ...screenData, name: null, confidence: null }
    setScreenData(newScreenData)
    setScreen(newScreenData)

    addNotification({
      description: 'Screen name and confidence were cleared!',
      message: 'Clear Screen',
      type: 'success',
      autoClose: 3000,
    })
  }

  const appIconSize = 39
  const bottomAlignButtons = { placeSelf: 'flex-end' }

  return (
    <Element as='header' style={screenViewerHeaderCSS}>
      <Element
        style={{
          ...headerColumnCSS,
          flex: 1,
          '> *': { marginRight: theme['default-spacer-sm'], placeSelf: 'center' },
        }}
      >
        <ApplicationIcon
          src={application.iconUrl}
          name={application.displayName}
          size={appIconSize}
          style={{ minWidth: addPXSuffix(appIconSize) }}
        />
        <div>
          <Heading level={4} style={{ marginBottom: 0 }}>
            <Link to={`/applications/${application.tenantApplicationId}`}>{application.displayName}</Link>
          </Heading>
          <Element as='span' style={{ wordBreak: 'break-all' }}>
            Signature: {screenData.signature}
          </Element>
        </div>
      </Element>
      <Element style={{ ...headerColumnCSS, borderLeft: `1px solid ${theme['antd-card-border']}` }}>
        <Element style={{ display: 'flex' }}>
          <Element style={{ flexDirection: 'column', marginRight: theme['default-spacer-sm'], position: 'relative' }}>
            <Input label='Screen Name:' name='name' value={screenData.name || ''} onChange={updateScreenData} />
            <Element style={errorStyle}>{nameError}</Element>
          </Element>
          <Input
            label='Confidence:'
            name='confidence'
            value={screenData.confidence || ''}
            onChange={updateScreenData}
          />
          <Button
            disabled={!formValid}
            loading={isSaving}
            onClick={saveScreenData}
            style={{ ...bottomAlignButtons, marginLeft: theme['default-spacer-lg'] }}
            type='secondary'
          >
            Update Screen
          </Button>
          <Button
            disabled={screen.name === null && screen.confidence === null}
            loading={isSaving}
            onClick={() =>
              addModal({
                children: 'Are you sure you want to clear the screen name and confidence?',
                config: { okButton: { children: 'Clear', type: 'danger' } },
                header: 'Clear Screen?',
                id: 'event-group-clear-screen-modal',
                onOk: handleClear,
                style: { maxWidth: '400px' },
              })
            }
            style={{ ...bottomAlignButtons, marginLeft: theme['default-spacer-sm'] }}
            type='danger'
          >
            Clear Screen
          </Button>
        </Element>
      </Element>
    </Element>
  )
}

ScreenViewerHeader.propTypes = {
  screenSignature: string.isRequired,
  application: shape({ id: number, iconUrl: string, displayName: string }).isRequired,
  screen: shape({ name: string, confidence: number }),
  setScreen: func.isRequired,
}

ScreenViewerHeader.defaultProps = {
  screen: {},
}

export default ScreenViewerHeader
