import React from 'react'
import { Card } from '@fortressiq/fiq-ds'
import css from './dashCardStyle.scss'

/*************************************************
 * a Card component for Dashboard
 * meant to be a card styled specifically for the dashboard
 * (without semantic UI's styling)
 **************************************************/
const DashCard = ({ title, children, style, onClose }) => (
  <Card style={{ margin: '8px', ...style }} className={css.dashCard}>
    {onClose && (
      <div className={css.close} onClick={onClose}>
        {/* TO DO -- use a design System <Icon /> when there is one available that looks correct */}
        &times;
      </div>
    )}
    <div className={css.title}>{title}</div>
    <div className={css.contents}>{children}</div>
  </Card>
)

export default DashCard
