import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { Button, Group, Input, Tag, Tooltip, useFela } from '@fortressiq/fiq-ds'
import sourceTypes from 'components/dragAndDrop/sourceTypes'

import SVGDropZone from 'components/dragAndDrop/SVGDropZone'

import subprocessStore from '../stores/subprocessStore'

import {
  flowTemplate,
  dropNode,
  numOfNodes,
  connected,
  error,
  bottomsection,
  nodeName,
  subprocessNameInput,
} from '../styles/subprocessModal.js'

const CreateSubprocessDialogue = ({ name, saveSubprocess, isSaving, clear, setName }) => {
  const { startNode: start, endNodes: end, includedNodeIds } = subprocessStore
  const { css } = useFela()
  const canSave = [subprocessStore.startNode, name, !subprocessStore.errorMessage].every(Boolean)
  const DropZoneWidth = 145
  const DropZoneHeight = 55
  const stepCount = includedNodeIds.length
  const hasEndNodes = !!end.length

  return (
    <Fragment>
      <div className={css(flowTemplate)}>
        <SVGDropZone
          text='Starting Step'
          width={DropZoneWidth}
          minHeight={DropZoneHeight}
          id='subprocessStart'
          sourceTypes={sourceTypes.NODE}
          data={start}
          default='Start Node'
        >
          {start && (
            <Tooltip title={start.name}>
              <Tag
                className={css(dropNode)}
                closable={true}
                onClose={() => {
                  subprocessStore.removeStartNode()
                }}
                size='large'
              >
                <span className={css(nodeName)}>{start.name}</span>
              </Tag>
            </Tooltip>
          )}
        </SVGDropZone>
        <div className={css(numOfNodes)}>{stepCount ? `${stepCount} step(s)` : '# of nodes'}</div>
        <SVGDropZone
          text='Ending Step'
          width={DropZoneWidth}
          minHeight={DropZoneHeight}
          id='subprocessEnd'
          sourceTypes={sourceTypes.NODE}
          data={hasEndNodes ? end : null}
          default='End Node(s)'
        >
          {hasEndNodes &&
            end.map((node, i) => (
              <Tooltip title={node.name} key={`tooltip_${node.name}`}>
                <Tag
                  key={i}
                  className={css(dropNode)}
                  closable={true}
                  onClose={() => {
                    subprocessStore.removeEndNode(node.id)
                  }}
                  size='large'
                >
                  <span className={css(nodeName)}>{node.name}</span>
                </Tag>
              </Tooltip>
            ))}
        </SVGDropZone>
        {!!start && hasEndNodes && <div className={css(connected)} />}
      </div>

      {!!subprocessStore.errorMessage && <div className={css(error)}>{subprocessStore.errorMessage}</div>}
      <Group className={css(bottomsection)}>
        <Input
          placeholder='Subprocess Name'
          value={name}
          onChange={setName}
          tabIndex={0}
          onMouseDown={e => e.stopPropagation()}
          className={css(subprocessNameInput)}
        />
        <Button onClick={saveSubprocess} loading={isSaving} disabled={!canSave}>
          {isSaving ? 'Saving' : 'Save'}
        </Button>
        <Button onClick={clear}>Clear</Button>
      </Group>
    </Fragment>
  )
}

export default observer(CreateSubprocessDialogue)
