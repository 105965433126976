import React, { useEffect, useState, useMemo } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Col, Row, Select, useFela } from '@fortressiq/fiq-ds'

import RangeInputSlider from 'components/rangeInputSlider/RangeInputSlider'

import store from '../stores/processExplorerStore'
import { flowControlContainerCSS, flowControlSliderCSS } from './styles/flowControl'

const FlowsControl = observer(({ flowFilterType, setFlowFilterType, flowData, diagramData }) => {
  const [sortedFlowData, setSortedFlowData] = useState([])
  const [sortedFlowFreq, setSortedFlowFreq] = useState([])
  const { css } = useFela()

  useEffect(() => {
    store.flowData = flowData
    setSortedFlowData(flowData ? toJS(flowData).sort((a, b) => b.percent - a.percent) : [])
  }, [flowData])

  useEffect(() => {
    const getFlowFrequencies = (firstNode, node, flowFreq) => {
      const total = firstNode.value

      if (node.type === 'end') {
        flowFreq.push({ percent: ((node.value / total) * 100).toFixed(2) })
      }

      if (node.children) {
        node.children.forEach(child => getFlowFrequencies(firstNode, child, flowFreq))
      }
      if (node.hiddenChildren) {
        node.hiddenChildren.forEach(child => getFlowFrequencies(firstNode, child, flowFreq))
      }
    }

    const flowFreq = []
    getFlowFrequencies(diagramData, diagramData, flowFreq)
    setSortedFlowFreq(flowFreq ? flowFreq.sort((a, b) => b.percent - a.percent) : [])
  }, [diagramData])

  const style = { width: '150px' }

  const onChange = ({ value }) => {
    setFlowFilterType(value)
    store.setTreeFlow(100, store.flowData.length - 1, 'reset')
  }

  const onSlide = (value, index) => store.setTreeFlow(value, index, flowFilterType)

  const selectOptions = useMemo(
    () => [
      { label: 'Duration', value: 'duration' },
      { label: 'Instances', value: 'frequency' },
    ],
    []
  )

  const getDefaultValue = useMemo(() => {
    let defaultValue

    selectOptions.map(({ label, value }) => {
      if (value === flowFilterType) {
        defaultValue = { label, value }
      }

      return null
    })

    return defaultValue
  }, [flowFilterType, selectOptions])

  return (
    <div className={css(flowControlContainerCSS)}>
      <Row noEndGutter={true} noOfCols={2} type='grid'>
        <Col>Display Paths by:</Col>
        <Col justify='flex-end'>
          <Select
            onChange={onChange}
            options={selectOptions}
            size='small'
            style={style}
            defaultValue={getDefaultValue}
          />
        </Col>
      </Row>
      <div className={css(flowControlSliderCSS)}>
        {flowFilterType === 'duration' && (
          <RangeInputSlider values={sortedFlowData} dataKey='percent' maxWidth={320} onSlide={onSlide} width={null} />
        )}
        {flowFilterType === 'frequency' && (
          <RangeInputSlider values={sortedFlowFreq} dataKey='percent' maxWidth={320} onSlide={onSlide} width={null} />
        )}
      </div>
    </div>
  )
})

export default FlowsControl
