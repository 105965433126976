/* eslint-disable react/jsx-curly-newline */
import React, { Fragment, memo, useState, useEffect } from 'react'
import { string, shape } from 'prop-types'
import KeyDown, { Keys } from 'react-keydown'
import debounce from 'lodash/debounce'
import { Link } from 'react-router-dom'
import qs from 'qs'
import { Button, Element, Group, Heading, useModal } from '@fortressiq/fiq-ds'
import { useMount } from 'react-use'

import uiStore from 'lib/UiStore'
import api from 'lib/Api'
import Can from 'components/can/Can'
import IntegrationsDropdown from 'components/IntegrationsDropdown'

import { eventFilterFields } from 'constants/event-constants'
import QueryBuilderDropdown from 'components/query_builder/queryBuilderDropdown/QueryBuilderDropdown'
import FilteredEvents from './FilteredEvents'
import EventFilter from './EventFilter'
import UploadsModal from './UploadsModal/UploadsModal'
import { useHeaderDispatch } from '../header/HeaderContext'
import Export from './Export'
import ObserversOverlay from './ObserversOverlay'
import EventDeleteConfirm from './EventDeleteConfirm'
import { fetchEventCount } from './fetchEventCount'
import { getColumns } from './EventColumns'
import { addPXSuffix } from '../../helpers/index'

const { LEFT, RIGHT, ENTER } = Keys

const mainStyle = { height: 'calc(100vh - 109px)', position: 'relative' }
const pageTitle = 'Events'

const isLite = uiStore?.tenant?.isLite
const columns = getColumns(isLite)

const queryWithObserver = (observerIds, filter) => {
  const rules = [
    ...(observerIds
      ? [
          {
            id: 'observers',
            field: 'observer_id',
            operator: 'in',
            value: observerIds,
          },
        ]
      : []),
    ...(filter ? [{ ...filter }] : []),
  ]
  return {
    rules,
    combinator: 'and',
  }
}

const Events = ({
  location,
  keydown: { event: keyDownEvent },
  match: {
    params: { observerIds },
  },
}) => {
  const [events, setEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(false)
  const [{ filter }, setFilterOrigin] = useState(qs.parse(location.search, { ignoreQueryPrefix: true }))
  const [eventCount, setEventCount] = useState(0)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedEventIndex, setSelectedEventIndex] = useState(0)
  const [reFetch, setRefetch] = useState(0)
  const [visibleCols, setVisibleCols] = useState([])
  const { addModal, removeModal } = useModal()
  const headerDispatch = useHeaderDispatch()
  const modalID = 'events-import-to-graph-modal'

  useMount(() => setVisibleCols(columns))

  useEffect(() => {
    const { filter: newFilter } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    // TEMP: do not allow for start and end signatures
    if (newFilter?.rules) {
      newFilter.rules = newFilter.rules.filter(
        rule => rule.field !== 'start_screen_signature' && rule.field !== 'end_screen_signature'
      )
    }
    setFilterOrigin({ filter: newFilter })

    // bail unless filter has been set by useEffect
    if (newFilter === 0) return
    setEventsLoading(true)
    async function fetchData() {
      setEventCount(0)
      setEvents([])
      setEventCount(await fetchEventCount(queryWithObserver(observerIds, newFilter)))
      setSelectedEventIndex(0)
      setEventsLoading(false)
    }
    fetchData()
  }, [location.search, observerIds, reFetch])

  useEffect(() => {
    if (selectedEventIndex <= events.length) {
      setSelectedEvent(events[selectedEventIndex])
    }
  }, [selectedEventIndex, events])

  useEffect(() => {
    const keyHandlerMap = {
      [LEFT]: () => {
        const newIndex = selectedEventIndex === 0 ? eventCount - 1 : selectedEventIndex - 1
        setSelectedEventIndex(newIndex)
      },
      [RIGHT]: () => {
        const newIndex = selectedEventIndex === eventCount - 1 ? 0 : selectedEventIndex + 1
        setSelectedEventIndex(newIndex)
      },
      [ENTER]: () => {
        const viewScreenshot = window.open(`/screenshot/${events[selectedEventIndex].id}`, '_blank')
        viewScreenshot.focus()
      },
    }

    if (keyDownEvent && keyHandlerMap[keyDownEvent.which]) {
      keyHandlerMap[keyDownEvent.which]()
    }
  }, [keyDownEvent, setSelectedEventIndex, selectedEventIndex, events, eventCount])

  useEffect(() => {
    let fields = eventFilterFields
    if (isLite) {
      fields = fields.filter(field => field.name !== 'field' && field.name !== 'control_type')
    }
    headerDispatch({
      type: 'set',
      title: pageTitle,
      heading: (
        <Fragment>
          <Heading className='header-title' level={1}>
            {pageTitle}
          </Heading>
          <Heading className='header-title' level={2}>
            Explore events.
          </Heading>
        </Fragment>
      ),
      toolbar: <ObserversOverlay observerIds={observerIds} />,
      toolbarActions: (
        <Element
          style={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
        >
          <EventFilter filter={filter} eventCount={eventCount} key='event-filter' />
          <Group>
            <QueryBuilderDropdown
              align='right'
              fields={fields}
              isPersisted={true}
              noGroups={true}
              positionY={8}
              query={filter}
              cycles={true}
            />
            <Button
              onClick={() => {
                const closeModal = () => removeModal(modalID)

                addModal({
                  children: <UploadsModal accept='.csv,.zip' onComplete={closeModal} />,
                  footer: (
                    <Fragment>
                      After successful import, your newly created procceses will be visible&nbsp;
                      <Link onClick={closeModal} to='/processes'>
                        here
                      </Link>
                      .
                    </Fragment>
                  ),
                  header: 'Upload a CSV or ZIP file with Event Log IDs',
                  id: modalID,
                  style: { width: addPXSuffix(460) },
                })
              }}
            >
              Import to Graph Process
            </Button>
            <Export columns={visibleCols} filter={queryWithObserver(observerIds, filter)} eventCount={eventCount} />
            <IntegrationsDropdown
              integrationType='event'
              objectId={queryWithObserver(observerIds, filter)}
              disabled={eventCount === 0}
            />
            <Can perform='/events:delete'>
              <EventDeleteConfirm filter={filter} eventCount={eventCount} setRefetch={setRefetch} />
            </Can>
          </Group>
        </Element>
      ),
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch, eventCount, filter, observerIds, addModal, removeModal, visibleCols])

  const loadMoreEvents = debounce(
    ({ startIndex, stopIndex }) =>
      api
        .get('/events', {
          offset: startIndex,
          limit: stopIndex - startIndex + 1,
          filter: queryWithObserver(observerIds, filter),
        })
        .then(({ data }) => {
          data.data.forEach((event, index) => {
            event = { ...event.attributes }
            events[index + startIndex] = event
          })
          setEvents([...events])
          if (!selectedEventIndex) {
            setSelectedEventIndex(0)
          }
        }),
    300
  )

  return (
    <Element style={mainStyle}>
      <FilteredEvents
        filter={queryWithObserver(observerIds, filter)}
        events={events}
        eventsLoading={eventsLoading}
        eventCount={eventCount}
        setEvents={setEvents}
        selectedEventIndex={selectedEventIndex}
        setSelectedEventIndex={setSelectedEventIndex}
        selectedEvent={selectedEvent}
        setVisibleCols={setVisibleCols}
        loadMoreEvents={loadMoreEvents}
      />
    </Element>
  )
}

Events.propTypes = {
  location: string,
  keydown: string,
  match: shape({
    params: shape({
      observerIds: string,
    }),
  }),
}

export default memo(KeyDown([LEFT, RIGHT, ENTER])(Events))
