import React from 'react'
import { Button, Element, theme, useModal } from '@fortressiq/fiq-ds'

const SaveBtn = ({ addInstanceEnabled, addInstanceClick }) => (
  <Button disabled={!addInstanceEnabled} onClick={addInstanceClick} type='secondary'>
    Save Instance to Process
  </Button>
)

const ProcessControls = ({ modalContent, addInstanceEnabled, addInstanceClick, instances }) => {
  const { addModal } = useModal()

  return (
    <Element
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '53px',
        justifyContent: 'flex-end',
        paddingRight: theme['default-spacer-sm'],
      }}
    >
      {instances.length > 0 && (
        <Button
          onClick={() =>
            addModal({
              children: modalContent,
              footer: false,
              header: 'Save Instance to Process',
              id: 'instances-viewer-process-details-modal',
              style: { minWidth: '400px', width: '55%' },
            })
          }
          style={{ marginRight: theme['default-spacer-sm'] }}
        >
          View Process Details
        </Button>
      )}
      <SaveBtn addInstanceEnabled={addInstanceEnabled} addInstanceClick={addInstanceClick} />
    </Element>
  )
}

export default ProcessControls
