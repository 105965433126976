import React from 'react'
import rappid from 'vendor/rappid.js'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'

import ProcessMenuItem from './ProcessMenuItem'
import DecisionMenuItem from './DecisionMenuItem'
import store from '../store/DiagramComposerStore'

import css from './leftPanelStyle.scss'

@withRouter
@observer
export default class LeftPanel extends React.Component {
  render() {
    const { loading, processes } = store

    return (
      <div id='leftPanel' className={css.leftPanel}>
        <div className={css.panelHeader}>
          <div className={css.title}>Browse Processes & Groups</div>
          <div className={css.stepsTitle}>Steps</div>
        </div>
        <div className={css.processMenuItemList}>
          {!loading &&
            processes.map(process => (
              <ProcessMenuItem
                key={process.id}
                processClick={() => this.drawProcessShape(process)}
                groupClick={group => this.drawGroupShape(group)}
                process={process}
              />
            ))}
        </div>
        <div className={css.panelHeader}>Other Elements</div>
        <DecisionMenuItem onClick={() => this.drawDiamond()} />
      </div>
    )
  }

  drawProcessShape = process => {
    const processNameWithLineBreaks = rappid.util.breakText(process.name, { width: 100, height: 100 })
    const textLabel = `A\n${processNameWithLineBreaks}`
    const homePlate = new rappid.shapes.standard.Polygon({
      id: process.id,
      position: { x: 100, y: 100 },
      size: { width: 100, height: 100 },
      processId: process.id,
      nodeType: 'homeplate',
      description: '',
    })
    homePlate.attr('label/text', textLabel)
    homePlate.attr('body/refPoints', '0,0 100,0 100,50 50,100 0,50')
    homePlate.attr('body/stroke-width', '1')
    homePlate.attr('body/stroke', '#8995AD')
    homePlate.attr('label/font-size', 12)
    homePlate.addTo(store.graph)
  }

  drawGroupShape = group => {
    const labelText = rappid.util.breakText(group.name || 'Empty', { width: 145 })
    const rect = new rappid.shapes.standard.Rectangle({
      position: { x: 10, y: 10 },
      size: { width: 145, height: 80 },
      groupId: group.id,
      nodeType: 'group',
      description: '',
    })
    rect.attr('label/text', labelText)
    rect.addTo(store.graph)
  }

  drawDiamond = () => {
    const diamond = new rappid.shapes.standard.Polygon({
      nodeType: 'decision',
      description: '',
      rules: [],
    })
    diamond.resize(140, 80)
    diamond.position(10, 10)
    diamond.attr('label/text', 'Empty')
    diamond.attr('body/refPoints', '50,0 100,50 50,100 0,50')
    diamond.attr('body/stroke-width', '1')
    diamond.attr('body/stroke', '#8995AD')
    diamond.attr('label/font-size', 12)
    diamond.addTo(store.graph)
  }
}
