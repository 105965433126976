import React from 'react'
import PropTypes from 'prop-types'

import qs from 'qs'
import { Menu, MenuItem, Popconfirm } from '@fortressiq/fiq-ds'
import { Link } from 'react-router-dom'
import ExportMenuItem from 'components/ExportButton/ExportMenuItem'
import ActionMenu from 'components/TrinityTable/ActionMenu/ActionMenu'
import handleIntegration from 'app/admin/integrations/helpers/handleIntegration'
import callbackStore from 'stores/callback'

import api from 'lib/Api'

const ActionsMenu = ({ cycle, handleEdit, handleClone, reFetch, integrations, isLoadingIntegrations }) => {
  const getArgs = () => {
    const dateRange = cycle.filterRules.rules
      .reduce((acc, { field, value }) => {
        if (field === 'observed_at') {
          acc.push(value.replace(/\..+/, ''))
        }
        return acc
      }, [])
      .join('-')

    return {
      filter: cycle.filterRules,
      exportType: 'cycle_events',
      exportName: `Cycle: ${cycle.name} - ${dateRange}`,
      record: { id: cycle.id, type: 'cycle' },
    }
  }

  const handleDelete = async id => {
    await api.delete(`/cycles/${id}`)
    reFetch()
  }

  const exportCount = cycle.exportIds.length
  const exportsUrl =
    !!exportCount && `/event-log-exports?${cycle.exportIds.map(exportId => `ids=${exportId}`).join('&')}`

  const menu = (
    <Menu bordered={false} dark={true}>
      <MenuItem key='events-menu-item'>
        <Link to={{ pathname: '/events', search: qs.stringify({ filter: cycle.filterRules }) }}>Browse Events</Link>
      </MenuItem>
      <MenuItem key='clone-menu-item' onClick={() => handleClone(cycle)}>
        Clone
      </MenuItem>
      <MenuItem key='edit-menu-item' onClick={() => handleEdit(cycle)}>
        Edit
      </MenuItem>
      {exportsUrl && (
        <MenuItem key='link-view-exports'>
          <Link to={exportsUrl}>View Exports ({exportCount})</Link>
        </MenuItem>
      )}
      <ExportMenuItem
        callbackStore={callbackStore}
        exportCallback={reFetch}
        exportArgs={getArgs()}
        itemText='Export Cycle as .csv'
        modalMaxWidth={550}
        showColumns={true}
      />
      <MenuItem key='delete-menu-item' onClick={event => event.stopPropagation()}>
        <Popconfirm
          cancelText='No'
          okText='Yes'
          onConfirm={() => {
            handleDelete(cycle.id)
          }}
          placement='left-center'
          title='Are you sure you want to delete this cycle?'
        >
          Delete
        </Popconfirm>
      </MenuItem>
      {!isLoadingIntegrations &&
        integrations.map(integration => (
          <MenuItem
            key={`integration-${integration.id}`}
            onClick={() => handleIntegration({ integrationId: integration.id, objectId: cycle.id })}
          >
            {integration.title}
          </MenuItem>
        ))}
    </Menu>
  )

  return <ActionMenu menu={menu} />
}

ActionsMenu.defaultProps = {
  isLoadingIntegrations: undefined,
  integrations: undefined,
}

ActionsMenu.propTypes = {
  cycle: PropTypes.shape().isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleClone: PropTypes.func.isRequired,
  reFetch: PropTypes.func.isRequired,
  isLoadingIntegrations: PropTypes.bool,
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ),
}

export default ActionsMenu
