import React, { Fragment, useReducer, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Divider, Heading, Select } from '@fortressiq/fiq-ds'

import { sub, startOfHour } from 'date-fns'

import api from 'lib/Api'
import IntegrationsDropdown from 'components/IntegrationsDropdown'

import LoadingContainer from 'components/LoadingContainer'

import EventsChart from './sections/EventsChart'
import MetadataTable from './sections/MetadataTable'

import { useHeaderDispatch } from '../../header/HeaderContext'

import css from './observerViewerStyle.scss'
import { timeOptions, constructStats, timeSteps, getFillerForDateArray } from '../common/Util'

const observerActions = {
  SET_STATS: 'SET_STATS',
  SET_DAY_STATS: 'SET_DAY_STATS',
  SET_OBSERVER: 'SET_OBSERVER',
  OPTION_CHANGED: 'OPTION_CHANGED',
  DATA_CHANGED: 'DATA_CHANGED',
}

const initialObserverReducerState = {
  events: {},
  sizes: {},
  selectedOption: timeOptions[30],
  loading: true,
  stats: {},
  dayStats: null,
  kinds: [],
  kind: '',
  name: '',
  observer: {},
}

const getStep = {
  day: { days: 1 },
  hour: { hours: 1 },
  month: { months: 1 },
}

const observerReducer = (state, action) => {
  switch (action.type) {
    case observerActions.SET_DAY_STATS: {
      const { events: dayEvents, sizes: daySizes } = constructStats(
        action.stats,
        sub(startOfHour(new Date()), { hours: 23 }),
        timeSteps.HOUR,
        24
      )

      const events = {
        ...state.events,
        [timeOptions[1].value]: dayEvents,
      }

      const sizes = {
        ...state.sizes,
        [timeOptions[1].value]: daySizes,
      }
      return {
        ...state,
        events,
        sizes,
        dayStats: action.stats,
      }
    }
    case observerActions.SET_STATS: {
      const { events: allEvents, sizes: allSizes } = constructStats(action.stats)

      let periodEvents = allEvents.slice(-1 * state.selectedOption.value)
      let periodSizes = allSizes.slice(-1 * state.selectedOption.value)

      const difference = state.selectedOption.value - periodEvents.length
      if (periodEvents.length < state.selectedOption.value) {
        const [startDate] = Object.keys(action.stats)
        const { DAY: step } = timeSteps
        const start = sub(new Date(startDate), getStep[step])
        const filler = getFillerForDateArray(difference, start, step)
        periodEvents = [...filler, ...periodEvents]
        periodSizes = [...filler, ...periodSizes]
      }

      const events = {
        all: allEvents,
        [state.selectedOption.value]: periodEvents,
      }

      const sizes = {
        all: allSizes,
        [state.selectedOption.value]: periodSizes,
      }

      return {
        ...state,
        events,
        sizes,
        stats: action.stats,
      }
    }
    case observerActions.SET_OBSERVER: {
      const { name } = action.observer

      const kind = action.observer.enums.kinds.find(o => action.observer.kind === o.value)

      const { kinds: kindOptions } = action.observer.enums

      return {
        ...state,
        observer: action.observer,
        name,
        kind,
        kindOptions,
        loading: false,
      }
    }
    case observerActions.OPTION_CHANGED:
      if (action.option.value !== state.selectedOption.value) {
        let periodEvents = state.events[action.option.value]
        let periodSizes = state.sizes[action.option.value]
        if (!periodEvents) {
          periodEvents = state.events.all.slice(-1 * action.option.value)
          periodSizes = state.sizes.all.slice(-1 * action.option.value)

          const difference = action.option.value - periodEvents.length
          if (difference > 0) {
            const filler = getFillerForDateArray(difference, Object.keys(state.stats)[0], timeSteps.DAY)
            periodEvents = [...filler, ...periodEvents]
            periodSizes = [...filler, ...periodSizes]
          }
          return {
            ...state,
            selectedOption: action.option,
            events: {
              ...state.events,
              [action.option.value]: periodEvents,
            },
            sizes: {
              ...state.sizes,
              [action.option.value]: periodSizes,
            },
          }
        }
        return {
          ...state,
          selectedOption: action.option,
        }
      }
      return state
    case observerActions.DATA_CHANGED:
      return {
        ...state,
        [action.data.name]: action.data.value,
      }
    default:
      return state
  }
}

const ObserverView = withRouter(
  React.memo(
    ({
      match: {
        params: { id },
      },
    }) => {
      const [state, dispatch] = useReducer(observerReducer, initialObserverReducerState)

      const headerDispatch = useHeaderDispatch()
      const timeRangeOptions = Object.values(timeOptions)

      useEffect(() => {
        async function fetchData() {
          const { data } = await api.get(`/observers/${id}`)
          dispatch({ type: observerActions.SET_OBSERVER, observer: data })
          dispatch({ type: observerActions.SET_STATS, stats: data.stats })
        }

        fetchData()
      }, [id])

      const onSelectChange = async ({ value }) => {
        if (value === 1) {
          const { data } = await api.get(`/observers/${id}/stats`, {
            step: timeSteps.HOUR,
            range: 24,
            end_date: 'now',
          })
          dispatch({ type: observerActions.OPTION_CHANGED, option: timeOptions[value] })
          dispatch({ type: observerActions.SET_DAY_STATS, stats: data.stats })
        } else {
          dispatch({ type: observerActions.OPTION_CHANGED, option: timeOptions[value] })
        }
      }

      useEffect(() => {
        const selectOptions = []
        timeRangeOptions.map(t => selectOptions.push(t))
        let selectedOption

        timeRangeOptions.map(t => {
          if (t === state.selectedOption) {
            selectedOption = t
          }

          return null
        })

        headerDispatch({
          type: 'set',
          title: 'Observers',
          heading: state.name,
          toolbarActions: [
            <Select
              defaultValue={selectedOption}
              key='stats-range-picker'
              onChange={onSelectChange}
              options={selectOptions}
              style={{ width: '200px' }}
            />,
            <IntegrationsDropdown key='observer-integrations' integrationType='observer' objectId={id} />,
          ],
        })

        return () => {
          headerDispatch({
            type: 'clear',
          })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [headerDispatch, state.name, state.selectedOption.name, timeRangeOptions, id])

      return (
        <LoadingContainer loading={state.loading}>
          <div className={css.observerViewer}>
            <div className={css.overviewContainer}>
              <EventsChart
                recentEventCounts={state.events[state.selectedOption.value] || []}
                recentSizeCounts={state.sizes[state.selectedOption.value] || []}
              />
            </div>
            <div className='appSubSection'>
              <SectionHeader title='Detected Metadata' />
              <MetadataTable
                machine={state.observer.machine}
                user={state.observer.user}
                firstSeenAt={state.observer.firstSeenAt}
                lastSeenAt={state.observer.lastSeenAt}
              />
            </div>
          </div>
        </LoadingContainer>
      )
    }
  )
)

const SectionHeader = React.memo(({ title, buttons }) => (
  <Fragment>
    <div className={`${css.sectHeader} ${css.flexRow}`}>
      <Heading level={4}>{title}</Heading>
      <div>{buttons}</div>
    </div>
    <Divider />
  </Fragment>
))

export default ObserverView
