import React, { memo, useCallback, useState } from 'react'
import { Select } from '@fortressiq/fiq-ds'
import { useUpdateEffect } from 'react-use'
import PropTypes from 'prop-types'

const EventFilter = memo(
  ({
    clearInstances,
    eventListChange,
    fetchingProcesses,
    graphOptions,
    graphProcessId,
    setGraphProcessId,
    setGraphType,
  }) => {
    const [defaultValue, setDefaultValue] = useState()
    const handleProcessGraphChange = useCallback(
      data => {
        setDefaultValue(data)
        clearInstances()
        eventListChange([], { value: 0 })
        setGraphProcessId(data.value)
        setGraphType(data.type)
      },
      [clearInstances, eventListChange, setGraphProcessId]
    )

    useUpdateEffect(() => {
      const selected = graphOptions.find(({ value }) => value === +graphProcessId)
      if (selected) {
        setDefaultValue(selected)
      }
    }, [graphOptions.length])

    const selectStyle = {
      width: '250px',
    }

    return (
      <Select
        isLoading={fetchingProcesses}
        onChange={handleProcessGraphChange}
        options={graphOptions}
        placeholder='Select a Process...'
        style={selectStyle}
        value={defaultValue}
      />
    )
  }
)

EventFilter.propTypes = {
  clearInstances: PropTypes.func.isRequired,
  eventListChange: PropTypes.func.isRequired,
  fetchingProcesses: PropTypes.bool.isRequired,
  graphOptions: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })).isRequired,
  graphProcessId: PropTypes.string,
  setGraphProcessId: PropTypes.func.isRequired,
}

EventFilter.defaultProps = {
  graphProcessId: undefined,
}

export default EventFilter
