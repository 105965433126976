const controlTypeOptions = [
  { key: 0, label: 'Button', value: 'button' },
  { key: 1, label: 'Checkbox', value: 'checkbox' },
  { key: 2, label: 'Icon', value: 'icon' },
  { key: 3, label: 'List Box', value: 'list_box' },
  { key: 4, label: 'Radio Button', value: 'radio_button' },
  { key: 5, label: 'Tab', value: 'tab' },
  { key: 6, label: 'Table', value: 'table' },
  { key: 7, label: 'Text Field', value: 'text_field' },
]

export default controlTypeOptions
