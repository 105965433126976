import React, { memo, useCallback, useState } from 'react'
import { AdvancedCell, DateCell, Element, Table, theme } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'

import PanelMetrics from 'components/PanelMetrics/PanelMetrics'
import PanelTitle from './components/PanelTitle'

import ApplicationsTableActions from './components/ApplicationsTableActions'

import { tableContainerConfig } from './styles/index'

import { GOT_APPLICATIONS_WITH_COUNTS } from './reducers/Actions'

const sortKeyMap = {
  displayName: 'displayName',
  id: 'application.id',
  firstSeenAt: 'firstSeenAt',
  winProcessName: 'application.winProcessName',
  pattern: 'urlPattern,titlePattern',
  applicationId: 'application.id',
}

const ApplicationList = ({ url, createAction, dispatch, state, claimingEvents }) => {
  const getEventCount = eventList => {
    if (!eventList || eventList.length === 0) return 0
    return eventList.reduce((acc, { loggedEvents = 0 }) => acc + loggedEvents, 0)
  }

  const [pageCount, setPageCount] = useState(0)
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(true)

  const columns = [
    {
      Header: 'ID',
      accessor: 'applicationId',
      minWidth: 50,
      width: 100,
    },
    {
      Header: 'Application Name',
      accessor: 'displayName',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
      minWidth: 50,
      width: 150,
    },
    {
      Header: 'Pattern',
      accessor: 'pattern',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
      minWidth: 50,
      width: 150,
    },
    {
      Header: 'Type',
      accessor: 'type',
      disableSortBy: true,
      Cell: ({ value }) => <AdvancedCell>{value || '--'}</AdvancedCell>,
      minWidth: 80,
      width: 80,
    },
    {
      Header: 'Events',
      accessor: 'loggedEvents',
      Cell: ({ value }) => <AdvancedCell>{value || '0'}</AdvancedCell>,
      minWidth: 80,
      width: 80,
    },
    {
      Header: 'First Seen',
      accessor: 'firstSeenAt',
      Cell: ({ value }) => <DateCell timestamp={value} />,
      minWidth: 100,
      width: 100,
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: ({ value }) => <DateCell timestamp={value} />,
      minWidth: 100,
      width: 100,
    },
    {
      Header: 'Actions',
      accessor: 'application',
      width: 110,
      maxWidth: 110,
      minWidth: 110,
      disableSortBy: true,
      Cell: ({ row: { original: application } }) => (
        <ApplicationsTableActions
          application={application}
          applications={state.applications}
          dispatch={dispatch}
          claimingEvents={claimingEvents}
        />
      ),
    },
  ]

  const [persistedState, setPersistedState] = useQueryParams({
    pageSize: withDefault(NumberParam, 10),
    pageIndex: withDefault(NumberParam, 0),
    sortKey: StringParam,
    sortOrder: StringParam,
  })

  const initialState = {
    ...persistedState,
    ...(persistedState.sortKey
      ? {
          sortBy: [
            {
              id: persistedState.sortKey,
              desc: persistedState.sortOrder === 'desc',
            },
          ],
        }
      : {}),
  }

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy, filters = {}, globalFilter }) => {
      const newState = {
        pageSize: pageSize,
        pageIndex: pageIndex,
        ...(sortBy[0]
          ? { sortKey: sortBy[0].id, sortOrder: sortBy[0]?.desc ? 'desc' : 'asc' }
          : { sortKey: undefined, sortOrder: undefined }),
      }
      if (JSON.stringify(persistedState) !== JSON.stringify(newState)) {
        setPersistedState(newState)
      }
      const fetchingData = async () => {
        setLoading(true)
        const sortKey = sortBy[0]?.id ? sortBy[0].id : 'id'
        const convertedSortKey = sortKeyMap ? sortKeyMap[sortKey] || sortKey : sortKey
        const sortOrder = !sortBy[0] || sortBy[0]?.desc ? 'desc' : 'asc'

        const fetchParams = {
          pageSize: pageSize,
          page: pageIndex + 1,
          sortBy: convertedSortKey,
          sortOrder: sortOrder,
          appType: 'WEB',
        }

        const { data } = await api.get(url, fetchParams)

        setTotal(data.totalElements)
        setPageCount(Math.ceil(data.totalElements / pageSize))
        setLoading(false)

        dispatch({
          type: GOT_APPLICATIONS_WITH_COUNTS,
          applications: data.webApplications,
        })
      }
      fetchingData()
    },
    [url, sortKeyMap, persistedState, state.updated]
  )

  return (
    <Element style={{ maxHeight: 'calc(100vh - 59px)', overflow: 'auto', position: 'relative' }}>
      <PanelTitle title='Existing Rules' actions={createAction} />
      <PanelMetrics
        metrics={[
          { title: 'Rules', value: total },
          { title: 'Events Count', value: getEventCount(state.applications) },
        ]}
        style={{ background: theme['legacy-color-gray'] }}
      />
      <Table
        container={tableContainerConfig}
        columns={columns}
        data={state.applications || []}
        loading={loading}
        striped={true}
        fetchData={fetchData}
        initialState={initialState}
        disablePagination={false}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        pageCount={pageCount}
        controlledTable={true}
        total={total}
      />
    </Element>
  )
}

export default memo(ApplicationList)
