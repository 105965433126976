import store from '../stores/processExplorerStore'

//util functions that are shared acoss components in process explorer
export const getShape = (d, nodeHeight, collapsedHeight, nodeWidth, iconWidth) => {
  let h = nodeHeight - 10
  if (d.data.type === 'collapse' || d.data.activities.length > 1) h = collapsedHeight
  if (d.data.type === 'start') {
    return 'm-20,25 a25,25 0 1,0 50,0 a25,25 0 1,0 -50,0z'
  }
  if (d.data.type === 'decision') {
    return 'M0,25 L36,59 L72,25 L36,-9Z'
  }
  if (d.data.type === 'end') {
    return `M4,0
          h ${nodeWidth + iconWidth - 20}
          a4 4 0 0 1 4 4
          v ${h}
          a4 4 0 0 1 -4 4
          h ${-(nodeWidth + iconWidth - 20)}
          a4 4 0 0 1 -4 -4
          v ${-h}
          a4 4 0 0 1 4 -4
          z`
  }
  return `M4,0
          h ${store.baseNodeWidth}
          a4 4 0 0 1 4 4
          v ${h}
          a4 4 0 0 1 -4 4
          h -${store.baseNodeWidth}
          a4 4 0 0 1 -4 -4
          v ${-h}
          a4 4 0 0 1 4 -4
          z`
}

export default getShape
