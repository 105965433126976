import api from 'lib/Api'
import { strategyOptions, paramsDetailsMap } from 'lib/SignatureStrategies'

export const cloneStrategy = async (jobId, applicationId, cycleId) => {
  await api.put(`/signature_service_jobs/${jobId}/application/${applicationId}/cycle/${cycleId}/provisional`)
}

export const createStrategy = async (newStrategy, newParams, applicationId, cycleId) => {
  const params = {
    provisional_screen_strategy: newStrategy,
    provisional_screen_strategy_params: newParams,
  }
  await api.post(`/signature_service_jobs/application/${applicationId}/cycle/${cycleId}/provisional`, params)
}

export const strategyOptionsMap = strategyOptions.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {})
export const strategyParamsMap = strategyOptions.reduce((acc, { value, params }) => ({ ...acc, [value]: params }), {})

export const CHAIN_STRATEGY = 'chained'

const jsonFields = ['textarea', 'ngram', 'text']
export const isJsonValue = field => jsonFields.includes(paramsDetailsMap[field].type)
