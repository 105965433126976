import React from 'react'
import { Link } from 'react-router-dom'
import { ApplicationIconsList, DateCell, Group } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import { getTimeElapsed } from 'lib/Time'
import graphTypeDisplayNameMap from 'lib/graphTypeDisplayNameMap'

import EditableCell from 'components/TrinityTable/EditableCell'
import { columnWidths } from 'components/TrinityTable'

import Tags from 'components/tags/Tags'
import FavoriteToggle from 'components/FavoriteToggle/FavoriteToggle'

import RatingTag from './RatingTag'
import ActionsMenu from './ActionsMenu'

const getProcessesColumns = ({
  reFetch = undefined,
  setReFetch = undefined,
  isLoadingIntegrations = undefined,
  integrations = undefined,
}) => [
  {
    Header: 'ID',
    accessor: 'id',
    minWidth: 125,
    width: 125,
    Cell: ({ value, row }) => (
      <Group>
        <FavoriteToggle
          key={`favorite-${value}`}
          id={value}
          type={row.original.graphType === 'flowgraph' ? 'flowGraph' : 'graphProcess'}
          favorited={row.original.favorited}
        />
        <span>{value}</span>
        {row.original.autoRating && <RatingTag rating={parseFloat(row.original.autoRating)} />}
      </Group>
    ),
  },
  {
    accessor: 'miningRunId',
    disableSortBy: true,
    Header: 'Mining Run',
    width: 80,
    Cell: ({ value }) => <Link to={`/mining?globalFilter={"miningRunIds":["${value}"]}`}>{value}</Link>,
  },
  {
    align: 'center',
    accessor: 'graphType',
    disableSortBy: true,
    Header: 'Mining Type',
    width: 92,
    Cell: ({ value }) => graphTypeDisplayNameMap[value],
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({
      value,
      row: {
        index,
        original: { id, graphType },
      },
      column: { id: columnId },
      updateCellData,
    }) => (
      <EditableCell
        key={`name-${id}`}
        value={value}
        id={id}
        columnId={columnId}
        rowIndex={index}
        updateCellData={updateCellData}
        updateData={newValue =>
          api.put(graphType === 'flowgraph' ? `/df_graph/${id}` : `/graph_processes/${id}`, {
            name: newValue,
          })
        }
      />
    ),
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    disableSortBy: true,
    Cell: ({ value, row }) => (
      <Tags
        key={`tags-${row.original.id}`}
        taggableType={row.original.graphType === 'flowgraph' ? 'flowGraph' : 'graphProcess'}
        taggableId={row.original.id}
        tags={value}
      />
    ),
  },
  {
    Header: 'Comment',
    accessor: 'notes',
    disableSortBy: true,
    Cell: ({
      value,
      row: {
        index,
        original: { id, graphType },
      },
      column: { id: columnId },
      updateCellData,
    }) => (
      <EditableCell
        type='comment'
        value={value}
        id={id}
        columnId={columnId}
        rowIndex={index}
        updateCellData={updateCellData}
        updateData={newValue =>
          api.put(graphType === 'flowgraph' ? `/df_graph/${id}` : `/graph_processes/${id}`, {
            notes: newValue,
          })
        }
      />
    ),
  },
  {
    accessor: 'createdAt',
    Header: 'Created At',
    width: 80,
    Cell: ({ value }) => <DateCell timestamp={value} />,
  },
  {
    Header: 'Applications',
    accessor: 'applications',
    width: 90,
    disableSortBy: true,
    Cell: ({ value }) => {
      return <ApplicationIconsList list={value} size={24} />
    },
  },
  {
    align: 'center',
    accessor: 'totalFrequency',
    disableSortBy: true,
    Header: 'Total Frequency',
    width: 92,
  },
  {
    align: 'center',
    accessor: 'stepCount',
    disableSortBy: true,
    Header: 'Step Count',
    minWidth: 76,
    width: 76,
  },
  {
    align: 'center',
    accessor: 'totalDuration',
    disableSortBy: true,
    Header: 'Total Duration',
    width: 80,
    Cell: ({ value }) => getTimeElapsed(value),
  },
  {
    Header: 'Actions',
    accessor: 'eventLogExportIds',
    disableSortBy: true,
    width: columnWidths.actionMenu,
    align: 'center',
    Cell: ({ value, row }) => (
      <ActionsMenu
        graphId={row.original.id}
        processName={row.original.name}
        exportIds={value}
        reFetch={() => setReFetch(reFetch + 1)}
        graphType={row.original.graphType}
        isLoadingIntegrations={isLoadingIntegrations}
        integrations={integrations}
      />
    ),
  },
]

export default getProcessesColumns
