import React, { Fragment } from 'react'
import { string, arrayOf, shape, func } from 'prop-types'
import { Button, Group, Icon, Typography, theme } from '@fortressiq/fiq-ds'

const { Title } = Typography

/*
 *  Panel Title, takes props:
 *     title: string = title that gets displayed
 *     actions: array of objects { icon, text, onClick } = buttons to the right
 */
const PanelTitle = ({ title, actions, children }) => (
  <Group
    justify='space-between'
    noEndGutter={true}
    style={{
      background: theme.white,
      borderBottom: `1px solid ${theme['antd-card-border']}`,
      padding: `${theme['default-spacer-sm']} ${theme['default-spacer-sm']} ${theme['default-spacer-sm']} ${theme['default-spacer-md']}`,
    }}
  >
    <Title noMargin={true} level={5}>
      {title}
    </Title>
    <Group>
      {children}
      {actions &&
        actions.map((action, index) => {
          if (action.component) return <Fragment key={`${index}action`}>{action.component}</Fragment>

          return (
            <Button key={`${index}action`} onClick={action.onClick}>
              {action.icon && <Icon icon={action.icon} />}
              {action.text}
            </Button>
          )
        })}
    </Group>
  </Group>
)

PanelTitle.propTypes = {
  title: string.isRequired,
  actions: arrayOf(
    shape({
      icon: string,
      text: string,
      onClick: func,
    })
  ),
}

PanelTitle.defaultProps = {
  actions: null,
}

export default PanelTitle
