import React from 'react'
import { shallow } from 'enzyme'
import { Tag } from '@fortressiq/fiq-ds'
import BoundingBoxTag from '../BoundingBoxTag'

const defaultProps = Object.freeze({
  deleteBoundingBox: jest.fn(),
  i: 0,
  box: {
    bounds: '[517.2403354863588, 274.97888749638486, 870.7982261…]',
  },
  toggleHover: jest.fn(),
  bounds: [517.2403354863588, 274.97888749638486, 870.7982261640798, 564.5306083100356],
  isHover: false,
  boxType: 'userBoundingBox',
})

describe('BoundingBoxTag component', () => {
  it('should render without throwing an error', () => {
    const component = shallow(<BoundingBoxTag {...defaultProps} />)
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
  it('should call toggleHover on mouseOver and mouseLeave', () => {
    const component = shallow(<BoundingBoxTag {...defaultProps} />)
    component.find(Tag).first().simulate('mouseenter')
    expect(defaultProps.toggleHover).toHaveBeenCalledTimes(1)
    component.find(Tag).first().simulate('mouseleave')
    expect(defaultProps.toggleHover).toHaveBeenCalledTimes(2)
  })
})
