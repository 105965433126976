import React, { useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

/* eslint-disable react/require-default-props */
const defaultProps = {
  onFocus: noop,
  onBlur: noop,
}

const WindowFocusHandler = ({ onFocus, onBlur, ...props } = defaultProps) => {
  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  })

  //eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment {...props} />
}

WindowFocusHandler.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default WindowFocusHandler
