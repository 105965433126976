import React, { useState } from 'react'
import Draggable from 'react-draggable'
import api from 'lib/Api'

import { Icon } from '@fortressiq/fiq-ds'

import subprocessStore from '../stores/subprocessStore'

import css from './subprocessModalStyle.scss'

import CreateSubprocessDialogue from './CreateSubprocessDialogue'
import SavedSubprocessDialogue from './SavedSubprocessDialogue'

const SubprocessModal = ({ graphId, ...props }) => {
  const [name, setName] = useState('')
  const [savedSubprocessId, setSavedSubprocessId] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const onExit = () => {
    subprocessStore.clear()
    subprocessStore.subprocessMode = false
  }

  const saveSubprocess = async () => {
    const { includedNodeIds, startNode } = subprocessStore
    setIsSaving(true)
    const { data } = await api.post('/graph_subprocesses', {
      name: name,
      startNodeId: startNode.id,
      includedNodeIds: includedNodeIds,
      sourceGraphProcessId: graphId,
    })

    setSavedSubprocessId(data.graphProcessId)
    subprocessStore.clear()
    setIsSaving(false)
  }
  return (
    <Draggable>
      <div className={`${css.container}`}>
        <div className={css.topsection}>
          <div className={css.leave} onClick={onExit} role='button' tabIndex={-1}>
            <Icon icon='remove' />
          </div>
          <div className={css.title}>Create a Subprocess</div>
        </div>
        {savedSubprocessId ? (
          <SavedSubprocessDialogue savedSubprocessId={savedSubprocessId} />
        ) : (
          <CreateSubprocessDialogue
            {...props}
            isSaving={isSaving}
            name={name}
            setName={e => setName(e.target.value)}
            saveSubprocess={saveSubprocess}
            clear={() => {
              subprocessStore.clear()
              setName('')
            }}
          />
        )}
      </div>
    </Draggable>
  )
}

export default SubprocessModal
