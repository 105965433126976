import { helpers, theme } from '@fortressiq/fiq-ds'

const { customScrollbarCSS } = helpers

export const defaultBorder = ['1px', 'solid', theme['field-bg-dark']]
export const pad = theme['default-spacer-xs']

export const buttonGroupStyle = {
  display: 'block',
  margin: `${pad} 0`,
  textAlign: 'right',
}

export const historyDropdownContainerStyle = {
  maxHeight: '240px',
  minWidth: '400px',
  overflow: 'auto',
  ...customScrollbarCSS({ width: pad }),
}

export const historyOptionStyle = {
  backgroundColor: theme['field-bg-light'],
  borderRadius: theme['border-radius-base'],
  width: `calc(100% - ${pad})`,
  '&:first-child': { marginTop: 0 },
  '&:last-child': { marginBottom: 0 },
}
export const defaultPadding = [pad, pad, 0, pad]
export const defaultMargin = [pad, 0, theme['default-spacer-sm']]
export const textContainerStyle = { display: 'flex', justifyContent: 'space-between' }
export const historyDropdownContainerMargin = [pad, '2px', pad, pad]
