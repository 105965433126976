import React, { useState } from 'react'
import { Overlay } from '@fortressiq/fiq-ds'

const miningFilterMap = {
  all: 'All mining runs',
  only_favorites: 'Only favorites',
  only_recipes: 'Only recipes',
  exclude_recipes: 'Exclude recipes',
}

const MiningFilter = ({ miningFilter, setMiningFilter, pagination, setPagination }) => {
  const [selected, setSelected] = useState(0)
  const [showing, setShowing] = useState(false)
  const [miningFilterText, setMiningFilterText] = useState(
    miningFilter ? miningFilterMap[miningFilter] : 'All mining runs'
  )

  const heights = {
    max: 35 * 5,
  }
  const widths = {
    max: 200,
    min: 200,
    width: 200,
  }
  const config = {
    disableOutsideClick: false,
  }

  const trigger = {
    button: {
      dropdown: true,
    },
    showing: showing,
    setShowing: setShowing,
    text: miningFilterText,
    type: 'default',
  }

  const menu = Object.entries(miningFilterMap).map(([key, value], i) => {
    return {
      selected: selected === i,
      text: value,
      onClick: () => {
        if (value !== 'all') {
          setMiningFilter(key, 'push')
        } else {
          setMiningFilter()
        }
        setPagination({ currentPage: 1, pageSize: pagination.pageSize })
        setMiningFilterText(value)
        setSelected(i)
        setShowing(false)
      },
    }
  })

  return (
    <Overlay
      id='overlay-mining-filter'
      menu={menu}
      trigger={trigger}
      config={config}
      heights={heights}
      widths={widths}
    />
  )
}

export default MiningFilter
