//content for help tips in top navigation

//regex due to the fact that urls can be dynamic (ex /diagrams/#{id})
const helpTips = {
  '^/$': {
    title: 'Welcome to Process Discovery!',
    tip: 'This dashboard provides helpful tips for getting started, as well as an overview of your account activity. Click the button below to jump right into the latest business processes detected by Process Discovery.',
    buttons: [{ name: 'Explore Detected Processes', to: '/processes' }],
  },
  '^/pdds.*$': {
    title: 'Create and Edit Process Models',
    tip: 'Process Models combine AI-detected processes with business knowledge to give you a more complete description of any business process. Download an existing process model, or open the Diagram Composer to begin creating a new model from scratch.',
    buttons: [{ name: 'Create a New Model', to: '/diagrams/new' }],
  },
  '^/transform.*$': {
    title: 'Explore Detected Processes',
    tip: 'Detected Processes are those that have been automatically discovered by Process Discovery’s AI. Open them in the process explorer to review the process step by step, and add business knowledge by grouping related activities.',
    buttons: [],
  },
  '^/processes.*$': {
    title: 'Describe a Business Process',
    tip: 'Gather information about business processes so you can go back and record them later. Describe a process to start writing out steps manually.',
    buttons: [
      {
        name: 'Describe a Process',
        onClick: 'createProcess',
      },
    ],
  },
  '^/process-explorer.*$': {
    title: 'Explore a Detected Process and Create a Process Model',
    tip: 'Inspect the individual steps of a detected process, remove noise, and add business knowledge by grouping steps that are part of the same part of a flow. Once you’ve refined the process, save it as a Process Model for use in diagramming and developing.',
    buttons: [],
  },
  '^/diagrams.*$': {
    title: 'Diagram Processes to Create a Developer Doc',
    tip: 'Add grouped steps or entire processes to the canvas, then connect them to diagram the complete process. When you’re done, download it as a .docx template you can add further business context to.',
    buttons: [],
  },
  '^/admin/users.*$': {
    title: 'Invite a Teammate',
    tip: ' Invite a new teammate to Process Discovery, and set their permissions. They’ll be able to edit their attributes once they sign in.',
    buttons: [{ name: 'Invite a Teammate', to: '/admin/users/new' }],
  },
  '^/observers/.*$': {
    title: 'Observer Activity',
    tip: 'Select a timeframe to display a range of event and data activity for this observer. Please note that options other than "Last 24 Hrs" are delayed.',
    buttons: [],
  },
}

const helpTipKeys = Object.keys(helpTips)

/**
 * Find the helptip
 * @param {String} path
 * @returns {Object|Null} helptip object or null
 */
export const findHelpTip = path => {
  const pageHelpKey = helpTipKeys.find(key => path.match(new RegExp(key))) || null
  return pageHelpKey && helpTips[pageHelpKey]
}

export default helpTips
