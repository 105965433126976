import React, { useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import { createConsumer } from '@rails/actioncable'

const noop = () => null
const { Provider, Consumer } = createContext()

export const ActionCableProvider = ({ cable: cableProp, url, ...props }) => {
  const cable = cableProp || createConsumer(url)
  useEffect(() => cable.disconnect, [cable])
  return <Provider value={{ cable }} {...props} />
}

ActionCableProvider.displayName = 'ActionCableProvider'
ActionCableProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  cable: PropTypes.object,
  url: PropTypes.string.isRequired,
}
ActionCableProvider.defaultProps = {
  // eslint-disable-next-line react/forbid-prop-types
  cable: null,
}

export const ActionCableController = ({
  cable,
  channel,
  onReceived: received,
  onInitialized: initialized,
  onConnected: connected,
  onDisconnected: disconnected,
  onRejected: rejected,
  children = null,
}) => {
  const subscriptionConfig = {
    received,
    initialized,
    connected,
    disconnected,
    rejected,
  }

  const subscription = cable.subscriptions.create(channel, subscriptionConfig)
  useEffect(() => () => cable.subscriptions.remove(subscription), [cable, subscription])

  return children
}

ActionCableController.displayName = 'ActionCableController'
ActionCableController.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  cable: PropTypes.object.isRequired,
  onReceived: PropTypes.func,
  onInitialized: PropTypes.func,
  onConnected: PropTypes.func,
  onDisconnected: PropTypes.func,
  onRejected: PropTypes.func,
}
ActionCableController.defaultProps = {
  onReceived: noop,
  onInitialized: noop,
  onConnected: noop,
  onDisconnected: noop,
  onRejected: noop,
  children: null,
}

export const ActionCableConsumer = React.forwardRef((props, ref) => {
  return (
    <Consumer>
      {({ cable }) => (
        <ActionCableController
          {...{
            cable,
            ref,
            ...props,
          }}
        />
      )}
    </Consumer>
  )
})

ActionCableConsumer.displayName = 'ActionCableConsumer'
ActionCableConsumer.propTypes = {
  onReceived: PropTypes.func,
  onInitialized: PropTypes.func,
  onConnected: PropTypes.func,
  onDisconnected: PropTypes.func,
  onRejected: PropTypes.func,
}
ActionCableConsumer.defaultProps = {
  onReceived: noop,
  onInitialized: noop,
  onConnected: noop,
  onDisconnected: noop,
  onRejected: noop,
}
