import React from 'react'
import { Element, theme } from '@fortressiq/fiq-ds'

const Metric = ({ children }) => (
  <Element
    style={{
      background: theme.white,
      border: `1px solid ${theme['antd-card-border']}`,
      height: '400px',
      margin: `${theme['default-spacer-md']} auto 0`,
      padding: `${theme['default-spacer-md']} ${theme['default-spacer-sm']} 0`,
      position: 'relative',
      width: '90%',
    }}
  >
    {children}
  </Element>
)

export default Metric
