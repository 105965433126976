import React from 'react'
import { useQuery } from 'lib/hooks'

import FlowGraph from './FlowGraph'
import GraphProcess from './GraphProcess'

const ProcessView = ({
  match: {
    params: { id },
  },
}) => {
  const query = useQuery()
  const processType = query.get('type') || 'graphProcess'
  if (processType === 'flowgraph') {
    return <FlowGraph id={id} />
  } else {
    return <GraphProcess id={id} />
  }
}

export default ProcessView
