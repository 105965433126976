import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useForm, theme, Element } from '@fortressiq/fiq-ds'
import { useUpdateEffect } from 'react-use'

import api from 'lib/Api'

import getMiningParamsReport from 'lib/getMiningParamsReport'

import ControlledQueryBuilder from 'components/form/ControlledQueryBuilder'
import AdvancedMiningParamFields from './subComponents/AdvancedMiningParamFields'

import FormFooter from './subComponents/FormFooter'

import { generateReportParams } from './helpers'

export const queryFields = [
  { label: 'Application', name: 'application_name' },
  { label: 'Application ID', name: 'application_id' },
  { label: 'ID', name: 'id' },
  { label: 'Observed At', name: 'observed_at', defaultValue: new Date().toISOString() },
  { label: 'Observer ID', name: 'observer_id' },
  { label: 'Observer Name', name: 'observer_name' },
  { label: 'Machine', name: 'machine' },
  { label: 'Detail Signature', name: 'signature' },
  { label: 'Screen Signature', name: 'screen_signature' },
  { label: 'Screen Name', name: 'name' },
  { label: 'Action', name: 'action' },
  { label: 'Field', name: 'field' },
  { label: 'Control Type', name: 'control_type' },
  { label: 'Annotation Type', name: 'annotation_type' },
  { label: 'User', name: 'user' },
]

export const rowProps = {
  noOfCols: 2,
  noEndGutter: true,
  style: { marginBottom: theme['default-spacer-sm'] },
  type: 'grid',
}

const ReportForm = ({ defaultValues, fetchData, setShowing }) => {
  const [isValidQuery, setIsValidQuery] = useState(true)
  const getDefaultValues = defaultValues
    ? { filterRules: defaultValues?.filterRules, miningParams: defaultValues?.miningParams }
    : undefined

  const { reset, watch, handleSubmit, control } = useForm({
    defaultValues: getDefaultValues,
  })

  useUpdateEffect(() => {
    reset({
      filterRules: defaultValues?.filterRules,
      miningParams: { ...formValues.miningParams, ...defaultValues?.miningParams },
    })
  }, [defaultValues])

  const formValues = watch()

  const onSubmitHandler = async e => {
    await api.post('/process-boundary/start', generateReportParams(e))
    fetchData()
    setShowing(false)
  }

  return (
    <Element>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Element
          style={{
            marginTop: `-${theme['default-spacer-sm']}`,
            padding: theme['default-spacer-md'],
            maxHeight: '60vh',
            overflow: 'scroll',
            position: 'relative',
          }}
        >
          <Element style={{ marginBottom: theme['default-spacer-md'] }}>
            <ControlledQueryBuilder
              control={control}
              setIsValid={setIsValidQuery}
              fields={queryFields}
              name='filterRules'
            />
          </Element>
          <AdvancedMiningParamFields
            control={control}
            advancedMiningParams={getMiningParamsReport()}
            formValues={formValues}
          />
        </Element>
        <FormFooter formValues={formValues?.filterRules} onCancel={() => setShowing(false)} isValid={isValidQuery} />
      </form>
    </Element>
  )
}

ReportForm.defaultProps = {
  defaultValues: undefined,
}

ReportForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  fetchData: PropTypes.func.isRequired,
  setShowing: PropTypes.func.isRequired,
}

export default ReportForm
