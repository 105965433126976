/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { bool, number, object } from 'prop-types'
import { Element, SplitPane, theme } from '@fortressiq/fiq-ds'

import MainLoader from 'components/loaders/MainLoader'
import EventList from './EventList'
import EventDetails from './EventDetails'

const emptyStyle = {
  fontStyle: 'italic',
  marginTop: '30px',
  marginLeft: '30px',
  color: theme['topnav-border'],
}

const FilteredEvents = ({ eventsLoading, eventCount, selectedEvent, ...props }) => {
  if (eventsLoading) return <MainLoader />

  if (!eventsLoading && eventCount === 0) {
    return <Element style={emptyStyle}> No Events Found</Element>
  }

  return (
    <SplitPane minSize={250} maxSize={1200} primary='first' split='vertical'>
      <EventList eventsLoading={eventsLoading} eventCount={eventCount} selectedEvent={selectedEvent} {...props} />
      <EventDetails event={selectedEvent} />
    </SplitPane>
  )
}

FilteredEvents.propTypes = {
  eventsLoading: bool.isRequired,
  eventCount: number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedEvent: object,
}

FilteredEvents.defaultProps = {
  selectedEvent: null,
}

export default FilteredEvents
