/* eslint-disable no-bitwise */
export const toAlpha = (depth, useNumeral = true) => {
  const letterVal = depth % 26
  let letterupper = String.fromCharCode(65 + letterVal)

  // if more than 26, do AA or A1, depending on useNumeral
  if (useNumeral) {
    if (depth > 25) {
      const quotient = Math.floor(Math.max(depth / 26, 1))
      letterupper += quotient
    }
  } else {
    const cap = Math.floor(depth / 26)
    for (let i = 0; i < cap; i += 1) {
      letterupper = `${letterupper}${letterupper.charAt(0)}`
    }
  }
  return letterupper
}

export const toHash = str => {
  let hash = 0
  if (str.length === 0) return hash
  for (let i = 0; i < str.length; i += 1) {
    const chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return hash
}
