import React from 'react'
import { observer } from 'mobx-react'

import Inspector from 'components/inspector/Inspector'
import EditDecisionInPlace from 'app/inspectors/EditDecisionInPlace'
import EditDiagramNode from 'app/inspectors/EditDiagramNode'

import Utils from '../utils'

import store from '../store/DiagramComposerStore'

import css from './diagramInspectorStyle.scss'

@observer
class DiagramInspector extends React.Component {
  render() {
    const { attributes } = store.viewing || {}

    return (
      <Inspector header='Details' showDetails={true} className={css.inspector}>
        <div className={css.container}>
          {attributes ? this.renderContents() : <span className={css.none}>Select a node to view details</span>}
        </div>
      </Inspector>
    )
  }

  onEnter = (name, value) => {
    store.updateValue(name, value)
  }

  renderContents = () => {
    const { attributes } = store.viewing || {}

    switch (attributes.nodeType) {
      case 'decision':
        return (
          <EditDecisionInPlace
            key={`node-${attributes.id}`}
            name={store.activeLabel}
            description={store.activeDescription}
            rules={store.activeRules}
            onEnter={this.onEnter}
            store={store}
            generateLabel={Utils.generateLabel}
          />
        )
      default:
        return (
          <EditDiagramNode
            key={`node-${attributes.id}`}
            number={store.activeNumber}
            name={store.activeLabel}
            description={store.activeDescription}
            store={store}
            onEnter={this.onEnter}
          />
        )
    }
  }
}

export default DiagramInspector
