import React, { Fragment } from 'react'
import { Heading } from '@fortressiq/fiq-ds'
import Can from 'components/can/Can'
import ProcessSelect from './ProcessSelect'

const ProcessExplorerHeader = graphProcessId => (
  <Fragment>
    <Heading
      className='header-title'
      level={1}
      style={({ theme }) => ({ marginRight: `${theme['default-spacer-sm']} !important` })}
    >
      Explore a Process
    </Heading>
    <Can perform='/process-explorer:navigate-all-processes'>
      <ProcessSelect graphProcessId={graphProcessId} />
    </Can>
  </Fragment>
)

export default ProcessExplorerHeader
