import qs from 'qs'
import shortid from 'shortid'

//generates url query string from single rule group
/* eslint-disable-next-line import/prefer-default-export */
export const generateURLQueryfromRules = ({ combinator = 'and', rules = [] }) => {
  const rulesWithIds = rules.map(rule => ({
    id: `r_${rule.field}`,
    ...rule,
  }))

  return qs.stringify(
    {
      filter: {
        combinator: combinator,
        id: shortid.generate(),
        not: 'false',
        rules: rulesWithIds,
      },
    },
    { encode: false }
  )
}
