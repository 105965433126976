import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFela } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

import { useHeaderDispatch } from 'app/header/HeaderContext'
import Loader from 'components/loaders/MainLoader'

import Gallery from './Gallery'
import Sidebar from './Sidebar'
import Toolbar from './Toolbar'

import { wrapperCSS, innerWrapperCSS } from './styles'

const pageTitle = 'Process Boundary Detection'

const ProcessBoundary = ({
  match: {
    params: { reportId },
  },
}) => {
  const headerDispatch = useHeaderDispatch()
  const [data, setData] = useState([])
  const [imagesHash, setImagesHash] = useState({})
  const [stepLength, setStepLength] = useState(0)
  const [isLoadingSignatures, setIsLoadingSignatures] = useState(true)
  const [isLoadingImages, setIsLoadingImages] = useState(true)
  const [currentSignature, setCurrentSignature] = useState()
  const [step, setStep] = useState(1)
  const { css } = useFela()

  const toggleSignature = async selectedStep => {
    // make a copy of the signatures
    const items = [...data.start_stop_rankings]
    // get current signature from the array based on current step
    const item = items[selectedStep - 1]
    // toggle current signature selected state
    item.user_selected = !item.user_selected
    // Replace current signature with the updated signature
    items[selectedStep - 1] = item
    // Update local state
    setData(prevState => ({ ...prevState, start_stop_rankings: items }))
    // make an array of user selected signatures
    const newScreenSignatures = items
      .filter(sig => !!sig.user_selected)
      .map(sig => ({ screen_signature: sig.screen_signature, type: sig.type }))
    await api.put(`/process-boundary/select-signatures/${reportId}`, {
      screen_signatures: newScreenSignatures,
    })
  }

  useEffect(() => {
    const fetchImages = async () => {
      setIsLoadingImages(true)
      const { data: newImages } = await api.get('/process-boundary/screenshots', {
        type: currentSignature.type,
        report_mining_run_id: reportId,
        screen_signature: currentSignature.screen_signature,
      })
      setImagesHash(prevState => ({ ...prevState, [currentSignature.screen_signature]: newImages }))
      setIsLoadingImages(false)
    }
    // fetch new images based on current signature
    // don't refetch if images is already in imagesHash state
    if (currentSignature && !(currentSignature.screen_signature in imagesHash)) {
      fetchImages()
    }
  }, [currentSignature, imagesHash, reportId])

  useEffect(() => {
    const fetchProcessBoundary = async () => {
      const { data: newData } = await api.get(`/process-boundary/show/${reportId}`)
      setCurrentSignature(newData?.start_stop_rankings?.[0])
      setData(newData)
      setStepLength(newData.start_stop_rankings.length)
      setIsLoadingSignatures(false)
    }
    fetchProcessBoundary()
    headerDispatch({
      type: 'set',
      heading: pageTitle,
      title: pageTitle,
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch, reportId])

  const changeStep = nextStep => {
    setStep(nextStep)
    setCurrentSignature(data?.start_stop_rankings?.[nextStep - 1])
  }

  const canPreviousPage = step <= 1
  const canNextPage = step >= stepLength
  const currentImages = imagesHash[currentSignature?.screen_signature] || []

  if (isLoadingSignatures) {
    return <Loader />
  }

  return (
    <div className={css(wrapperCSS)}>
      <Toolbar
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        changeStep={changeStep}
        currentSignature={currentSignature}
        isLoadingSignatures={isLoadingSignatures}
        step={step}
        stepLength={stepLength}
        toggleSignature={toggleSignature}
        startStopRankings={data.start_stop_rankings}
        miningParams={data.report_mining_run.mining_params}
        filterRules={data.report_mining_run.filter_rules}
      />
      <div className={css(innerWrapperCSS)}>
        <Sidebar
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          changeStep={changeStep}
          currentSignature={currentSignature}
          isLoadingSignatures={isLoadingSignatures}
          step={step}
          stepLength={stepLength}
          toggleSignature={toggleSignature}
          startStopRankings={data.start_stop_rankings}
          miningParams={data.report_mining_run.mining_params}
          filterRules={data.report_mining_run.filter_rules}
        />
        <Gallery currentImages={currentImages} isLoadingImages={isLoadingImages} />
      </div>
    </div>
  )
}

ProcessBoundary.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default ProcessBoundary
