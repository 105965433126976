/* eslint-disable react/no-unstable-nested-components */
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Heading, useModal, useNotification } from '@fortressiq/fiq-ds'
import { useMount } from 'react-use'

import api from 'lib/Api'
import uiStore from 'lib/UiStore'
import ROLES from 'constants/roles'

import TrinityTable from 'components/TrinityTable'
import { useHeaderDispatch } from '../../header/HeaderContext'
import UserEditor from './subComponents/UserEditor'

import KibanaPassword from './subComponents/KibanaPassword'

import {
  actionsCellContent,
  kibanaLoginModalId,
  modalConfig,
  sharedColProps,
  sortKeyMap,
  userModalId,
} from './constants'

const Users = () => {
  const tenantId = uiStore.tenant.id
  const headerDispatch = useHeaderDispatch()
  const { addNotification } = useNotification()
  const [reFetch, setRefetch] = useState(0)
  const [isPegEnabled, setIsPegEnabled] = useState(false)
  const { addModal, removeModal } = useModal()

  useMount(() => {
    async function fetchTenant() {
      const { data } = await api.get(`/tenants/${tenantId}`)

      setIsPegEnabled(!!data.privacySettings.settings.minio.active)
    }
    fetchTenant()
  })

  const handleReFetch = () => setRefetch(reFetch + 1)

  const handleDeleteUser = async userId => {
    await api.delete(`/users/${userId}`)
    handleReFetch()
  }

  const resetPassword = async userId => {
    await api.put(`/users/${userId}/reset_password`)

    addNotification({
      description: 'User will have to change their password on next login.',
      message: 'Note',
      type: 'info',
    })
  }

  const toggleActiveUser = async ({ userId, active }) => {
    await api.put(`/users/${userId}`, { active })
    handleReFetch()
  }

  const addUserModal = ({ currentUser }) => {
    const displayName = currentUser
      ? `${currentUser.firstName} ${currentUser.lastName}`.trim() || currentUser.email
      : undefined
    addModal({
      children: (
        <UserEditor
          handleReFetch={handleReFetch}
          removeModal={() => removeModal(userModalId)}
          currentUser={currentUser}
          isPegEnabled={isPegEnabled}
        />
      ),
      header: currentUser ? `Editing ${displayName}` : 'Create New User',
      id: userModalId,
      ...modalConfig,
    })
  }

  const addKibanaPasswordModal = ({ currentUserId }) => {
    addModal({
      children: <KibanaPassword userId={currentUserId} removeModal={() => removeModal(kibanaLoginModalId)} />,
      header: 'Set Event Reporting Password',
      id: kibanaLoginModalId,
      ...modalConfig,
    })
  }

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: 'Users',
      heading: (
        <Fragment>
          <Heading className='header-title' level={1}>
            Manage Users
          </Heading>
          <Heading className='header-title' level={2}>
            Invite teammates and manage permissions
          </Heading>
        </Fragment>
      ),
    })
    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  const columns = [
    {
      Header: 'Username',
      accessor: 'username',
      ...sharedColProps.text,
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      ...sharedColProps.text,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      ...sharedColProps.text,
    },
    {
      Header: 'Email',
      accessor: 'email',
      ...sharedColProps.text,
    },
    {
      Header: 'Roles',
      accessor: 'roleNames',
      Cell: ({ value }) => (ROLES[value]?.name ? ROLES[value].name : value),
    },
    {
      Header: 'Last Sign-In',
      accessor: 'lastSignInAt',
      ...sharedColProps.date,
    },
    {
      Header: 'Sign-Ins',
      accessor: 'signInCount',
      align: 'right',
      width: 90,
    },
    {
      Header: 'Confirmed At',
      accessor: 'confirmedAt',
      ...sharedColProps.date,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      ...sharedColProps.date,
    },
    {
      Header: 'Active',
      accessor: 'active',
      width: 80,
      Cell: ({ value }) => value.toString(),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      align: 'center',
      disableSortBy: true,
      width: 64,
      Cell: actionsCellContent(handleDeleteUser, addUserModal, addKibanaPasswordModal, resetPassword, toggleActiveUser),
    },
  ]

  return (
    <TrinityTable
      columns={columns}
      dataKey='users'
      globalFilterOptions={['usersEmail', 'usersUsername', 'roleFilter']}
      key='users-table'
      reFetch={reFetch}
      sortKeyMap={sortKeyMap}
      tableId='users-table'
      toolbarActions={[
        <Button key='create-user-link' onClick={addUserModal} type='secondary'>
          Create User
        </Button>,
      ]}
      url='/users'
    />
  )
}

export default Users
