import { shallow } from 'enzyme'
import React from 'react'

import { QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params'
import { Route } from 'react-router-dom'

import ApplicationList from '../ApplicationList'

jest.mock('lib/Api')

const queryStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
}

describe('ApplicationList (Web Applications) component', () => {
  it('should render without throwing an error', () => {
    const component = shallow(
      <QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
        <ApplicationList />
      </QueryParamProvider>
    )
    expect(() => component).not.toThrow()
  })
})
