import { prettyColor } from '@fortressiq/fiq-ds'

import { getTimeElapsed } from 'lib/Time'

/**
 * @typedef {Object} FlowDataStats
 * @property {number} instanceCount - The total number of instances for the process
 * @property {string} totalDuration - The total duration for the process
 * @property {string} averageDuration - The average duration for the process
 */

/**
 *
 * @param {Array} flowData - graph_process.flow_data
 * @returns {FlowDataStats}
 */
export const getFlowDataStats = flowData => {
  const { totalDuration, totalInstances } = flowData.reduce(
    (acc, { frequency, average_duration: averageDuration }) => {
      acc.totalInstances += frequency
      acc.totalDuration += Math.round(averageDuration) * frequency
      return acc
    },
    {
      totalInstances: 0,
      totalDuration: 0,
    }
  )
  return {
    instanceCount: totalInstances,
    totalDuration: getTimeElapsed(totalDuration),
    averageDuration: getTimeElapsed(totalDuration / totalInstances || 0),
  }
}

/**
 * @typedef {Object} InstanceStats
 * @property {number} eventCount - The total number of events for the process
 * @property {Array} eventIds - List of event ids for the process
 * @property {number} observerCount - The total number of observers for the process
 * @property {Array} observerCount - List of observers for the process
 */

/**
 *
 * @param {Array} instances - graph_process instances
 * @returns {InstanceStats}
 */
export const getInstanceStats = instances => {
  const { eventIds, observers } = instances.reduce(
    (acc, instance) => {
      acc.observers.add(instance.observer)
      acc.eventIds.push(...instance.eventIds)
      return acc
    },
    { eventIds: [], observers: new Set() }
  )

  return {
    eventCount: eventIds.length,
    eventIds,
    observerCount: observers.size,
    observers: Array.from(observers),
  }
}

const letterIndexes = [...Array(26).keys()]
export const numberToColor = num => prettyColor(String.fromCharCode(97 + letterIndexes[num % letterIndexes.length]))
