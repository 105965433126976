import renderApplicationIcon from 'components/applicationIcon/ApplicationIconD3.js'

import css from '../../../processExploration.scss'

/***************
 *
 *  actions listing for collapsed nodes
 *
 **************/
export default ({ nodeEnter, nodeWidth, iconWidth }) => {
  const actionList = nodeEnter
    .append('g')
    .attr('class', 'actionList')
    .attr('transform', 'translate(0,0)')
    .style('display', d => {
      return d.data.type === 'collapse' || d.data.type === 'horizontal' || d.data.type === 'vertical' ? 'block' : 'none'
    })

  /// /*** first action ***/
  actionList
    .append('rect')
    .attr('width', nodeWidth + iconWidth + 15)
    .attr('height', 32)
    .attr('y', 68)
    .style('fill', 'transparent')
    .style('stroke', '#8995AD')

  const firstActivityFO = actionList
    .append('foreignObject')
    .attr('class', 'nodeName')
    .attr('width', nodeWidth)
    .attr('height', d => {
      return d.data.type !== 'collapse' ? 120 : 30
    })
    .attr('x', 10)
    .attr('y', 73)
    .attr('class', 'eventFO')

  firstActivityFO
    .append('xhtml:div')
    .attr('class', `eventFOhtml ${css.eventlistName}`)
    .html(d => {
      return `${'--'}`
    })

  /// /*** mid Actions... ***/
  const midSection = actionList.append('g')

  midSection
    .append('rect')
    .attr('width', nodeWidth + iconWidth + 15)
    .attr('height', 30)
    .attr('y', 100)
    .style('fill', 'transparent')
    .style('stroke', '#8995AD')

  midSection.append('text').attr('y', 112).attr('x', 12).style('font-size', '25px').style('fill', '#ccc').text('.')
  midSection.append('text').attr('y', 117).attr('x', 12).style('font-size', '25px').style('fill', '#ccc').text('.')
  midSection.append('text').attr('y', 122).attr('x', 12).style('font-size', '25px').style('fill', '#ccc').text('.')
  midSection
    .append('text')
    .attr('y', 119)
    .attr('x', 26)
    .style('font-size', '12px')
    .style('fill', '#ccc')
    .style('font-style', 'italic')
    .text(d => `${d.data.activities.length - 2} events`)

  const lastActivityFO = actionList
    .append('foreignObject')
    .attr('class', 'nodeName')
    .attr('width', nodeWidth)
    .attr('height', d => {
      return d.data.type !== 'collapse' ? 120 : 30
    })
    .attr('x', 10)
    .attr('y', 135)
    .attr('class', 'eventFO')

  lastActivityFO
    .append('xhtml:div')
    .attr('class', `eventFOhtml ${css.eventlistName}`)
    .html(d => {
      if (d.data.activities && d.data.activities.length > 1)
        return `${d.data.activities[d.data.activities.length - 1].name}`
      else {
        return '--'
      }
    })

  // /******* app icon ******/
  const appIconContainer = actionList
    .append('g')
    .attr('class', d => `CappIconGLast nodeId_${d.data.id}`)
    .attr('transform', `translate(${nodeWidth + 47}, 147)`)
    .style('display', d => {
      if (d.data.type === 'start' || d.data.type === 'end') return 'none'
      return 'block'
    })

  renderApplicationIcon({
    iconContainer: appIconContainer,
    applicationIndex: d => d.data.activities.length - 1,
    radius: 13,
  })
}
