import React from 'react'
import { Element } from '@fortressiq/fiq-ds'

import EventSlide from './EventSlide'

import { eventSliderCSS, screenshotCSS } from './styles'

const InactiveEventGroup = ({ exemplarEvent, eventCount, isLazy }) => (
  <Element style={screenshotCSS}>
    <Element className='event-slider' style={eventSliderCSS}>
      <EventSlide
        key={`event-slide-${exemplarEvent.id}`}
        {...{
          event: exemplarEvent,
          slideCount: eventCount,
          isLazy,
        }}
      />
    </Element>
  </Element>
)

export default InactiveEventGroup
