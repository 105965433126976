import React, { Fragment } from 'react'
import { Element, Empty, theme } from '@fortressiq/fiq-ds'
import { useQuery } from '@tanstack/react-query'
import UnredactedImageViewerWithOption from 'components/image/UnredactedImageViewerWithOption'

import { getExemplarEvent } from '../api'
import cacheSettings from '../cacheSettings'
import dfgColors from '../dfgColors'

const statStyles = {
  fontSize: '10px',
  margin: '0 8px 4px 4px',
}

const Stat = ({ label, value }) => (
  <div style={statStyles}>
    {label}: {value || '--'}{' '}
  </div>
)

const emptyStyles = { padding: '8px 0' }

const screenshotStyles = {
  border: `1px solid ${dfgColors.screenshotBorderGray}`,
  borderRadius: '4px',
  margin: `${theme['default-spacer-sm']} 0`,
  minHeight: '150px',
  overflow: 'hidden',
  padding: '2px',
}

const transformExemplarEvent = data => {
  return data.event
    ? [
        {
          centered: true,
          fluid: true,
          src: data.event.screenshotUrl,
          svgInfo: {
            box: data.event.bounding_box || data.event.boundingBox,
            x: data.event.coordX || data.event.coord_x,
            y: data.event.coordY || data.event.coord_y,
          },
        },
      ]
    : null
}

const StepDetails = ({ selectedStep, isLoadingPaths }) => {
  const { data: images } = useQuery({
    queryKey: ['exemplarEvent', { exemplarEventId: selectedStep?.exemplarEventId }],
    queryFn: getExemplarEvent,
    // disabled as long as selectedStep is empty
    enabled: !!selectedStep?.exemplarEventId && !isLoadingPaths,
    keepPreviousData: true,
    select: transformExemplarEvent,
    ...cacheSettings,
  })

  if (!selectedStep) return <Element style={emptyStyles}>Click on a step to see details...</Element>
  return (
    <Fragment>
      <Stat label='Screen Name' value={selectedStep.name} />
      <Stat label='Frequency' value={selectedStep.frequency} />
      <Element style={screenshotStyles}>
        {!!selectedStep?.exemplarEventId && images ? (
          <UnredactedImageViewerWithOption
            clickImageToFullscreen={true}
            dark={false}
            images={images}
            maxHeight={200}
            maxWidth={300}
            openFullscreenOnLoad={false}
            selectedIndex={0}
            fullscreenEnabled={true}
          />
        ) : (
          <Empty style={{ width: '100%' }} />
        )}
      </Element>
    </Fragment>
  )
}

export default StepDetails
