import { arrayOf, shape, number, string, func, bool } from 'prop-types'

export const pathsDataPropTypes = arrayOf(
  shape({
    default_display: bool.isRequired,
    path_id: number.isRequired,
    path_label: string.isRequired,
    path_uuid: string.isRequired,
    stats: shape({
      duration: number.isRequired,
      frequency: number.isRequired,
      mean_duration: number.isRequired,
      median_duration: number.isRequired,
    }).isRequired,
  })
)

export const activePathsPropTypes = arrayOf(
  shape({
    pathId: number.isRequired,
    pathUuid: string.isRequired,
  })
).isRequired

export const highlightedPathsPropTypes = arrayOf(string).isRequired

export const handleHighlightPathPropTypes = func.isRequired

export const handlePathSelectPropTypes = func.isRequired

export const processIdPropTypes = string.isRequired

export const graphStatsPropTypes = shape({
  total_duration: number.isRequired,
  frequency: number.isRequired,
  id: number.isRequired,
  mean_duration: number.isRequired,
  median_duration: number.isRequired,
  steps: number.isRequired,
})

export const selectedStepsPropTypes = shape({
  applicationId: number.isRequired,
  endOf: arrayOf(string).isRequired,
  frequency: number.isRequired,
  id: string.isRequired,
  name: string.isRequired,
  pathList: arrayOf(string).isRequired,
  startOf: arrayOf(string).isRequired,
  type: string.isRequired,
})
