import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'

import { Typography, useModal } from '@fortressiq/fiq-ds'

import startCase from 'lodash/startCase'
import localizer from 'lib/Localizer'
import { useUserState } from 'context/UserContext'

import CardListing from '../CardListing'
import DetailCard from '../../DetailCard'
import CardMenu from '../Menus/CardMenu'

import store from '../../ClientDashboardStore'
import VisualizationModal from '../VisualizationModal/VisualizationModal'
import { urlCheck } from '../utilities/Utilities'

const VisualizationList = ({ visList }) => {
  const { roles } = useUserState()
  return (
    <Fragment>
      {visList
        .filter(({ viewUrl }) => {
          return urlCheck(viewUrl, roles)
        })
        .map(({ createdBy, ...vis }, index) => {
          return (
            <CardListing
              title={startCase(vis.type)}
              name={vis.displayName || vis.name}
              details={[
                `Paths ${vis.pathCount}`,
                `Events ${vis.eventCount}`,
                `Created ${localizer.asShortestDateTime(vis.createdAt)}`,
                createdBy && `by ${createdBy}`,
              ]}
              key={`vis-type-listing-${vis.type}-${index}`}
              viewUrl={vis.viewUrl.startsWith('/') ? vis.viewUrl : `/${vis.viewUrl}`}
            />
          )
        })}
    </Fragment>
  )
}

const VisualizationCard = ({ onOk }) => {
  const { addModal, removeModal } = useModal()
  const modalID = 'client-dashboard-add-viz-modal'

  return (
    <DetailCard
      title='Visualizations'
      menu={CardMenu({
        action: () =>
          addModal({
            children: <VisualizationModal modalID={modalID} onOk={onOk} removeModal={removeModal} />,
            config: { interactWithKeyboard: false },
            footer: false,
            header: 'Add New Visualization',
            id: modalID,
            style: { maxWidth: '600px' },
            bodyStyle: { overflow: 'visible' },
          }),
        del: store.removeVis,
        list: store.visList,
        type: 'Visualization',
      })}
    >
      {store.visList.length ? (
        <VisualizationList visList={store.visList} />
      ) : (
        <Typography.Text disabled={true}>No Visualizations Added</Typography.Text>
      )}
    </DetailCard>
  )
}

export default withRouter(observer(VisualizationCard))
