import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import sourceTypes from 'components/dragAndDrop/sourceTypes'
import { Button } from '@fortressiq/fiq-ds'

import SVGDropZone from 'components/dragAndDrop/SVGDropZone'

import GroupForm from './common/GroupForm'

import css from './groupsModalStyle.scss'

import store from '../stores/processExplorerStore'

const NewGroupForm = () => {
  const { activeGroup } = store.groups
  const start = activeGroup.startNode
  const end = activeGroup.endNode
  const steps = activeGroup.activeGroupNodeIds.length
  return (
    <Fragment>
      <div className={css.topsection}>
        <div className={css.title}>Create a Group</div>
        <div className={css.flowTemplate}>
          <SVGDropZone
            width={145}
            height={55}
            id='groupStart'
            sourceTypes={sourceTypes.NODE}
            data={start}
            defaultDisplay='Start Node'
          >
            {start && <div className={css.dropNode}>{start.data.name}</div>}
          </SVGDropZone>
          <div className={css.numOfNodes}>{steps ? `${steps} step(s)` : '# of steps'}</div>
          <SVGDropZone
            width={145}
            height={55}
            id='groupEnd'
            sourceTypes={sourceTypes.NODE}
            data={end}
            defaultDisplay='End Node(s)'
          >
            {end && <div className={css.dropNode}>{end.data.name}</div>}
          </SVGDropZone>
          {steps > 0 && <div className={css.connected} />}
        </div>
        <div className={css.error}>{store.groups.errorMessage}</div>
      </div>

      <div className={css.bottomsection}>
        <GroupForm />
        <Button onClick={store.groups.saveGroup} disabled={!store.groups.isReadyForSave}>
          Save
        </Button>
        <Button onClick={store.groups.clearBtn}>Clear</Button>
      </div>
    </Fragment>
  )
}

export default observer(NewGroupForm)
