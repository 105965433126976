export const getCollapsed = collapsed =>
  // get list of collapsed paths from collapsed;
  // if a decision node is collapsed, then use its parent
  Object.keys(collapsed).reduce((acc, nodeId) => {
    if (collapsed[nodeId].collapsed) {
      if (collapsed[nodeId].node.data.type === 'decision') {
        acc[collapsed[nodeId].node.parent.id] = true
      } else {
        acc[nodeId] = true
      }
    }
    return acc
  }, {})

export default { getCollapsed }
