import React from 'react'

import { Button } from '@fortressiq/fiq-ds'

import shortid from 'shortid'
import Flash from 'components/flash/Flash'

const miningRunImportSuccess = ({ miningRunId, processCount, processTypeName }) => {
  const messageText = [
    `Completed importing ${processCount}`,
    `${processTypeName} Process${processCount > 1 ? 'es' : ''}`,
    `for Mining Run ${miningRunId}`,
  ].join(' ')
  const id = shortid.generate()
  Flash.success(
    {
      description: messageText,
      message: 'Mining Run Finished',
      cta: (
        <Button type='link' href={`/mining?globalFilter={"miningRunIds":["${miningRunId}"]}`} size='small'>
          View Mining Run
        </Button>
      ),
    },
    { duration: 0, key: id }
  )
}

export default miningRunImportSuccess
