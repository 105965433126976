import shortid from 'shortid'

import pddGenProgress from 'app/pdds/PddGenToast'
import { eventLogExportSuccess } from 'app/admin/event_log_exports/EventLogExportList'
import { miningRunImportSuccess } from 'app/mining/ImportSuccess'
import processBoundaryToast from 'app/ProcessBoundary/processBoundaryToast'

const callbackStore = {
  persistedCallbacks: {
    eventLogExportSuccess: [eventLogExportSuccess],
    pddGenProgress: [pddGenProgress],
    miningRunImportSuccess: [miningRunImportSuccess],
    startStopRankingsDoneToast: [processBoundaryToast],
  },

  callbacks: {},

  add: (cb, cbId = shortid.generate(), isPersisted = false) => {
    const cbStack = isPersisted ? 'persistedCallbacks' : 'callbacks'
    callbackStore[cbStack][cbId] = callbackStore[cbStack][cbId] ? [...callbackStore[cbStack][cbId], cb] : [cb]
    return cbId
  },

  remove: (cbId, cb) => {
    const cbStacks = ['callbacks', 'persistedCallbacks']
    cbStacks.forEach(cbStack => {
      const { [cbId]: cbs, ...restCallbacks } = callbackStore[cbStack]
      if (cb && cbs) {
        restCallbacks[cbId] = cbs.filter(c => c !== cb)
      }
      callbackStore[cbStack] = restCallbacks
    })
  },

  call: (cbId, args) => {
    const cbs = callbackStore.callbacks[cbId] || callbackStore.persistedCallbacks[cbId]

    if (cbs) {
      cbs.forEach(cb => {
        cb(args, cbId)
        if (!callbackStore.persistedCallbacks[cbId]) {
          callbackStore.remove(cbId, cb)
        }
      })
    }
  },
}

export default callbackStore
