import React from 'react'
import { combineRules, Alert, Icon, theme, Tooltip, useFela, useModal } from '@fortressiq/fiq-ds'

import DashboardStore from '../dashboardStore'

const AddVisCard = ({ type }) => {
  const { addModal, removeModal } = useModal()
  const modalID = 'dashboard-add-vis-modal'
  const { css } = useFela()

  const list = Object.keys(DashboardStore[`${type.toLowerCase()}Vis`])
    .map(item => {
      return DashboardStore[`${type.toLowerCase()}Vis`][item] === true ? null : item
    })
    .filter(item => item !== null)

  const rowCSS = ({ ...extraCSS }) =>
    css(
      combineRules(
        { ...extraCSS },
        {
          alignItems: 'center',
          display: 'flex',
          flex: '1',
          justifyContent: 'space-between',
          transition: 'background-color 0.3s ease-in-out',
          willChange: 'background-color',
        }
      )
    )

  const addModalHandler = () =>
    addModal({
      children: (
        <div className={css({ backgroundColor: theme.white, display: 'flex', padding: theme['default-spacer-xs'] })}>
          {!list || list.length === 0 ? (
            <Alert
              message='There are no visualizations available.'
              style={{ display: 'inline-flex', margin: '0 auto' }}
              type='warning'
            />
          ) : (
            list.map((item, index) => (
              <Tooltip
                config={{ triggerOffset: 14 }}
                key={index}
                placement='top-center'
                title={`Add ${item} to Dashboard?`}
              >
                <div
                  className={rowCSS({
                    cursor: 'pointer',
                    padding: theme['default-spacer-xs'],
                    '&:hover': {
                      backgroundColor: theme['nav-btn-selected-bg'],
                    },
                  })}
                  onClick={() => {
                    DashboardStore.addVis(item, type)

                    removeModal(modalID)
                  }}
                >
                  <Icon icon='barchart' />
                  <span>{item}</span>
                  <Icon icon='circleplus' />
                </div>
              </Tooltip>
            ))
          )}
        </div>
      ),
      header: 'Add Visualization',
      id: modalID,
      style: { width: '400px' },
    })

  return (
    <Icon
      before={true}
      clickable={true}
      icon='circleplus'
      onClick={addModalHandler}
      style={{ display: 'inline-block', position: 'relative', top: theme['default-spacer-xs'] }}
    />
  )
}

export default AddVisCard
