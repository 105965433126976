import React from 'react'
import { Tag } from '@fortressiq/fiq-ds'
import css from './eventStyle.scss'

const BoundingBoxTag = ({ i, box, deleteBoundingBox, bounds, toggleHover, isHover, boxType }) => {
  return (
    <div className={css.boundingBoxTag}>
      <Tag
        style={{ marginRight: '8px' }}
        color={isHover && 'blue'}
        onMouseEnter={() => toggleHover(bounds)}
        onMouseLeave={() => toggleHover()}
        closable={true}
        onClose={() => deleteBoundingBox(i, boxType)}
      >
        {box.text ? box.text : i + 1}
      </Tag>
    </div>
  )
}

BoundingBoxTag.propTypes = {}

export default BoundingBoxTag
