import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'

import { useController, Input, Table, useFela, Button } from '@fortressiq/fiq-ds'

import FieldWrapper from 'components/form/FieldWrapper'
import { tableWrapperCSS } from './styles'

const tableContainerCSS = { maxHeight: '40vh', display: 'flex' }
const tableWrapperInnerCSS = { flex: 1, overflow: 'auto' }

const ControlledDomainInput = ({
  control,
  name,
  error,
  clearErrors,
  isRequired,
  setError,
  ...controlledDomainInput
}) => {
  const { css } = useFela()
  const [localValue, setLocalValue] = useState('')

  const {
    field: { value, onChange, ref },
  } = useController({
    name,
    control,
    rules: { validate: v => v?.length || 'Add at least one domain' },
  })

  const onRemoveDomain = domain => {
    const updatedDomains = value.filter(oldDomains => oldDomains !== domain)
    onChange(updatedDomains)
  }

  const onSubmit = () => {
    if (localValue === '') {
      setError('domains', {
        type: 'manual',
        message: "Domain can't be blank",
      })
    } else {
      const newDomains = localValue
        .split(',')
        .map(string => string.trim())
        .filter(item => item !== '')
      onChange([...new Set([...value, ...newDomains])])
      setLocalValue('')
      clearErrors('domains')
    }
  }

  return (
    <Fragment>
      <FieldWrapper htmlFor='domain' isRequired={isRequired} label='Domain' error={error}>
        <Input
          button={true}
          buttonProps={{
            children: 'Add',
            onClick: e => {
              e.preventDefault()
              onSubmit()
            },
            type: 'secondary',
          }}
          name='domain'
          onChange={e => {
            setLocalValue(e.target.value)
            if (error) {
              clearErrors('domains')
            }
          }}
          onEnter={onSubmit}
          onEnterDependencies={[localValue, JSON.stringify(value)]}
          ref={ref}
          value={localValue}
          {...controlledDomainInput}
        />
      </FieldWrapper>

      <div className={css(tableWrapperCSS, tableContainerCSS)}>
        <div className={css(tableWrapperInnerCSS)}>
          <Table
            data={value ? value.map(domain => ({ domain: domain })) : []}
            disablePagination={true}
            disableResizing={true}
            verticalBorders={false}
            columns={[
              {
                Header: 'Domain',
                accessor: 'domain',
                width: 380,
              },
              {
                Header: '',
                accessor: 'action',
                disableSortBy: true,
                minWidth: 60,
                width: 60,
                align: 'right',
                Cell: ({
                  row: {
                    original: { domain },
                  },
                }) => <Button icon='trash' onClick={() => onRemoveDomain(domain)} />,
              },
            ]}
          />
        </div>
      </div>
    </Fragment>
  )
}

ControlledDomainInput.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({}).isRequired,
  clearErrors: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
}

export default ControlledDomainInput
