import React, { memo, useState } from 'react'
import { Overlay } from '@fortressiq/fiq-ds'

export default memo(({ machines, toggleMachine }) => {
  const [showing, setShowing] = useState(false)

  const menu = machines.map(machine => ({
    onClick: () => {
      toggleMachine(machine)
    },
    style: { position: 'relative' },
    text: machine.name,
    icon: machine.selected ? 'check' : null,
  }))

  const { visibleMachines, selectedMachinesCount } = machines.reduce(
    (acc, machine) => {
      if (machine.selected) {
        return {
          visibleMachines: `${acc.visibleMachines === '' ? '' : `${acc.visibleMachines},`} ${machine.name}`,
          selectedMachinesCount: acc.selectedMachinesCount + 1,
        }
      }
      return acc
    },
    { visibleMachines: '', selectedMachinesCount: 0 }
  )

  const trigger = {
    showing: showing,
    setShowing: setShowing,
    text: `Observers${machines.length > 0 ? `: ${selectedMachinesCount} ${visibleMachines}` : ''}`,
  }

  const config = {
    content: { style: { marginTop: '14px' } },
    disableOutsideClick: true,
    dropdown: {
      closeOnRowClick: true,
      showArrow: false,
    },
    trigger: {
      icons: {
        prefix: {
          icon: 'desktopcomputer',
          size: 18,
        },
      },
    },
  }
  const widths = { max: 262, min: 262, width: 262 }

  const heights = {
    height: 35,
    max: 35 * 5,
  }

  return <Overlay config={config} heights={heights} id='machine-filter' menu={menu} trigger={trigger} widths={widths} />
})
