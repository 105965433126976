import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Tooltip, Button, Icon, theme } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

import css from './favoriteToggle.scss'

const routeMap = {
  graphProcess: 'graph_processes',
  flowGraph: 'df_graph',
  miningRun: 'mining_runs',
}

const FavoriteToggle = ({ id, type, size, favorited, onChangeFavorite, className, buttonProps, tooltip = true }) => {
  const [isFavorited, setIsFavorited] = useState(favorited)
  const starColor = isFavorited ? theme['legacy-color-yellow'] : theme['legacy-color-gray1']

  const handleClick = async () => {
    if (routeMap[type]) {
      setIsFavorited(!favorited)
      const { data } = await api.post(`/${routeMap[type]}/${id}/favorite`)
      setIsFavorited(data)
      onChangeFavorite(data)
    }
  }

  const Wrap = buttonProps ? props => <Button {...buttonProps} {...props} /> : 'div'
  const icon = <Icon clickable={true} icon='star' size={size || 24} color={starColor} fill={starColor} />

  return (
    <Wrap className={classNames(css.favoriteWrap, className)} onClick={handleClick}>
      {tooltip ? (
        <Tooltip placement='right-center' title='Favorite'>
          {icon}
        </Tooltip>
      ) : (
        icon
      )}
    </Wrap>
  )
}

FavoriteToggle.defaultProps = {
  onChangeFavorite: () => null,
  buttonProps: null,
}

FavoriteToggle.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  favorited: PropTypes.bool.isRequired,
  onChangeFavorite: PropTypes.func,
  buttonProps: PropTypes.shape({ type: PropTypes.string }),
}

export default FavoriteToggle
