import React, { useState, useEffect } from 'react'
import { Button, Checkbox, FullscreenPage, HiddenInput, ThemeProvider, theme } from '@fortressiq/fiq-ds'

import Markdown from 'react-markdown'
import { caaMarkdown } from './caaMarkdown'

const CaaAgreement = props => {
  const [tokens, setTokens] = useState({})
  const [{ scrollTop, offsetHeight, scrollHeight }, setScrollData] = useState({
    scrollTop: 0,
    offsetHeight: 0,
    scrollHeight: 1,
  })
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    const authTokenElement = document.getElementById('fiq:authenticity_token')
    const authInfo = JSON.parse(authTokenElement.content)

    setTokens(authInfo)
  }, [])

  const getScrollPosition = e => {
    setScrollData({
      scrollTop: e.target.scrollTop,
      offsetHeight: e.target.offsetHeight,
      scrollHeight: e.target.scrollHeight,
    })
  }

  const handleHaveRead = e => {
    setChecked(e)
  }

  const isScrolledBottom = scrollTop + offsetHeight >= scrollHeight
  const acceptIsEnabled = isScrolledBottom && checked

  const pageContent = [
    <div key='caa-page'>
      <p>
        To continue your first time login, scroll through the agreement completely to enable the checkbox and agree to
        our terms and conditions. You can bookmark the{' '}
        <a
          href='https://www.automationanywhere.com/legal/cloud-automation-agreement'
          target='_blank'
          rel='noopener noreferrer'
        >
          Cloud Automation Agreement
        </a>{' '}
        for your records.
      </p>
      <div
        style={{
          overflowY: 'scroll',
          padding: theme['default-spacer-md'],
          background: theme.white,
          border: `1px solid ${theme['border-color-base']}`,
          borderRadius: theme['default-spacer-xs'],
          maxHeight: '40vh',
          marginBottom: theme['default-spacer-md'],
        }}
        onScroll={getScrollPosition}
      >
        <Markdown>{caaMarkdown}</Markdown>
      </div>
    </div>,
    <Checkbox key='caa_checkbox' onCheck={handleHaveRead} disabled={!isScrolledBottom}>
      I have read the Terms and Conditions of the Cloud Automation Agreement and I accept them.
    </Checkbox>,
    <div
      key='caa_submit'
      style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: theme['default-spacer-sm'] }}
    >
      <Button disabled={!acceptIsEnabled} htmlType='submit' key='submitCaa' type='default'>
        Accept and Log In
      </Button>
    </div>,
  ]

  const formSettings = {
    acceptCharset: 'UTF-8',
    action: '/tenant_caa_agreements',
    hiddenFields: (
      <HiddenInput
        fields={[
          { name: 'uft8', value: '✓' },
          { name: tokens.name, value: tokens.token },
        ]}
      />
    ),
    method: 'post',
  }

  return (
    <FullscreenPage content={pageContent} contentWidth='700px' form={formSettings} title='Cloud Automation Agreement' />
  )
}

const CAA = props => {
  return (
    <ThemeProvider theme={theme}>
      <CaaAgreement {...props} />
    </ThemeProvider>
  )
}

export default CAA
