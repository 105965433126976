/* eslint-disable import/prefer-default-export*/
import api from 'lib/Api'

const noneEmptyStringFields = ['application_id', 'id', 'observer_id', 'observed_at']

const isFilterInvalid = rules => {
  let invalid = false
  rules.forEach(rule => {
    if (noneEmptyStringFields.includes(rule.field) && !rule.value) {
      invalid = true
      return
    }
    if (rule.rules) {
      isFilterInvalid(rule.rules)
    }
  })
  return invalid
}

export const fetchEventCount = async filter => {
  if (isFilterInvalid(filter.rules)) {
    return 0
  }

  const { data } = await api.get('/event_count', { filter })
  return data?.eventCount || 0
}
