import React from 'react'
import { bool, oneOfType, number, string } from 'prop-types'
import { Icon, Tooltip, theme, useFela } from '@fortressiq/fiq-ds'

import { getObserverPermissions } from 'lib/NeoUtils'

import { containerBackground, textContainer } from './styles'

export const isDisabledScreenshot = event => {
  if (!event) return false
  if (typeof event.neoCaptureStatusCode === 'undefined' || event.neoCaptureStatusCode === null) return false
  return !getObserverPermissions(event.neoCaptureStatusCode).screenshot
}

const DisabledScreenshot = ({ dark = false, iconSize, fontSize, iconHeight, containerHeight }) => {
  const { css } = useFela({ dark, containerHeight, fontSize })

  return (
    <Tooltip title='Screenshot capture disabled for Observer.'>
      <div className={css(containerBackground)}>
        <Icon icon='noimage' size={iconSize} height={iconHeight} />
        <div className={css(textContainer)}>Screenshots Disabled</div>
      </div>
    </Tooltip>
  )
}

DisabledScreenshot.propTypes = {
  dark: bool,
  iconSize: oneOfType([number, string]),
  fontSize: oneOfType([number, string]),
  iconHeight: oneOfType([number, string]),
  containerHeight: oneOfType([number, string]),
}

DisabledScreenshot.defaultProps = {
  dark: false,
  iconSize: 'full',
  fontSize: theme['font-size-large'],
  iconHeight: undefined,
  containerHeight: '100%',
}

export default DisabledScreenshot
