import React, { useEffect, useState } from 'react'
import kebabCase from 'lodash/kebabCase'
import { Button, Input, Popconfirm, Tooltip, useNotification } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

const operatorMap = {
  '<': 'lt',
  '<=': 'lteq',
  '>': 'gt',
  '>=': 'gteq',
  '~': 'matches',
  '=': 'eq',
}

const generateDefaultExportName = ({ rules = [], combinator } = {}) => {
  const defaultName = rules
    .reduce(
      (acc, { field, value, operator }) => [...acc, field, operatorMap[operator] || operator, value, combinator],
      []
    )
    .join('-')
  return kebabCase(defaultName.replace(new RegExp(`-${combinator}$`), ''))
}

const EventExportConfirm = ({ filter = {} }) => {
  const [isDownloadPending, setIsDownloadPending] = useState(false)
  const [isOk, setIsOk] = useState(false)
  const [defaultName, setDefaultName] = useState(generateDefaultExportName(filter))
  const [exportName = defaultName, setExportName] = useState()
  const isDisabled = !defaultName
  const { addNotification } = useNotification()

  const onExportClick = async () => {
    addNotification({
      description: 'Your download is being prepared, it will be available for download shortly.',
      message: 'Preparing',
      type: 'info',
    })
    const args = { filter, exportType: 'csv_search_results', exportName }
    setIsDownloadPending(true)
    await api.post('/event_log_exports', args)
    setExportName(undefined)
    setIsDownloadPending(false)
  }

  const handleNameChange = ({ target: { value } }) => setExportName(value)
  const handleKeyDown = evt => {
    if ((evt.which || evt.keyCode) === 13) {
      setIsOk(true)
    }
  }

  useEffect(() => {
    setDefaultName(generateDefaultExportName(filter))
  }, [filter])

  const confirm = (
    <Popconfirm
      cancelText='Cancel'
      disabled={isDisabled}
      icon={null}
      okText='Export'
      onConfirm={onExportClick}
      placement='bottom-start'
      title={
        <Input
          label='Export Name'
          autoFocus={true}
          onChange={handleNameChange}
          onKeyDown={handleKeyDown}
          defaultValue={exportName}
        />
      }
      okDisabled={(!defaultName && !exportName) || isDownloadPending}
      okState={[isOk, setIsOk]}
    >
      <Button disabled={isDisabled || isDownloadPending} loading={isDownloadPending}>
        {isDownloadPending ? 'Preparing download' : 'Export'}
      </Button>
    </Popconfirm>
  )

  return isDisabled ? (
    <Tooltip title='Set a filter to enable Export' placement='right-center'>
      {confirm}
    </Tooltip>
  ) : (
    confirm
  )
}

export default EventExportConfirm
