export const tableWrap = ({ theme }) => ({
  background: theme['legacy-color-gray'],
  borderBottom: `1px solid ${theme['table-border-color']}`,
  padding: theme['default-spacer-md'],
})

export const tableRow = theme => ({
  background: `${theme.white} !important`,
})

export const notExpandable = () => ({
  '> td > div[role="button"]': {
    display: 'none',
  },
})

export const processesTable = ({ theme }) => ({
  background: theme.white,
  border: `1px solid ${theme['table-border-color']}`,
})
