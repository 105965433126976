import React from 'react'
import { Button } from '@fortressiq/fiq-ds'

import Can from 'components/can/Can'

const CreateTenantButton = () => (
  <Can perform='/admin/tenant/create'>
    <Button href='/admin/tenants/new' type='secondary'>
      Create Tenant
    </Button>
  </Can>
)

export default CreateTenantButton
