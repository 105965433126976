import React, { useState, useEffect } from 'react'
import { Button, defaultOverlayConfig, Element, Label, Overlay, theme } from '@fortressiq/fiq-ds'

import NGramOverlay from './Overlay'

const NGram = ({ applicationId, handleChange, inputName, label, name, ngramProps, setFindingWords, single }) => {
  const [showing, setShowing] = useState(false)

  useEffect(() => {
    // setFindingWords is only used in chain
    if (setFindingWords) {
      setFindingWords(showing)
    }
  }, [showing, setFindingWords])

  const handleUpdateValue = val => {
    handleChange(inputName, val)
  }

  const overlayConfig = {
    ...defaultOverlayConfig,
    content: {
      props: { ...ngramProps },
      style: {
        border: `1px solid ${theme['border-color-base']}`,
        boxShadow: `0 ${theme['default-spacer-xs']} ${theme['default-spacer-sm']} hsla(0, 0%, 0%, 0.2)`,
        position: 'absolute',
        '> div': { overflow: 'auto' },
      },
    },
    contentContainer: { props: { ...ngramProps } },
    ...(ngramProps && {
      disableOutsideClick: true,
    }),
  }
  const overlayPlacement = {
    overflow: { adjustX: true, adjustY: true },
    points: single ? ['cl', 'cr'] : ['cr', 'cl'],
    targetOffset: [-25, 0],
  }
  const overlayStyle = {
    display: 'inline-block',
    '> .fiqds-overlay-trigger > div': { display: 'flex', justifyContent: 'flex-end' },
  }
  const overlayTrigger = {
    node: <Button size='small'>Find Words</Button>,
    setShowing: setShowing,
    showing: showing,
    width: 87,
  }
  const overlayHeights = { min: 200, max: 700 }
  const overlayWidths = { width: !single ? 650 : '100%', max: 650 }
  const containerStyle = { alignItems: 'center', display: 'flex', justifyContent: 'space-between' }
  const overlayID = `ngram-${name}-overlay`

  return (
    <Element style={containerStyle}>
      <Label ID={`application-textarea-${inputName}`} style={{ display: 'inline-block' }}>
        {label}
      </Label>
      <Overlay
        config={overlayConfig}
        content={
          <NGramOverlay
            applicationId={applicationId}
            id={`ngram-${name}-overlay-content`}
            ngramProps={ngramProps}
            setShowing={setShowing}
            setFindingWords={setFindingWords}
            handleUpdateValue={handleUpdateValue}
          />
        }
        draggable={single}
        heights={overlayHeights}
        id={overlayID}
        noPadding={true}
        placement={overlayPlacement}
        portal={!single}
        portalContainer={!!ngramProps && 'chainUIPortalContainer'}
        scrollable={false}
        style={overlayStyle}
        trigger={overlayTrigger}
        widths={overlayWidths}
        zIndex={theme['z-modal-above']}
      />
    </Element>
  )
}

export default NGram
