import React from 'react'

import { Checkbox, Col } from '@fortressiq/fiq-ds'
import { ControlBlock, ControlRow } from './ControlHelpers'

const ScreenViewerSettings = ({ isMinimapEnabled, setIsMinimapEnabled }) => (
  <ControlBlock>
    <ControlRow>
      <Col span={{ xs: 12, sm: 12, md: 12 }}>
        <Checkbox checked={isMinimapEnabled} onCheck={() => setIsMinimapEnabled(!isMinimapEnabled)}>
          Enable Selection Minimap
        </Checkbox>
      </Col>
    </ControlRow>
  </ControlBlock>
)

export default ScreenViewerSettings
