//EndNode.js
import { theme } from '@fortressiq/fiq-ds'

import endIconImage from './images/delete-2.svg'

import css from '../../processExploration.scss'

import store from '../../../stores/processExplorerStore'

export default ({ nodeEnter }) => {
  const node = nodeEnter
    .append('g')
    .attr('class', 'activityNodeGroup')
    .attr('transform', 'translate(0, 0)')
    .style('display', d => {
      return d.data.activities.length < 2 ? 'block' : 'none'
    })

  // end node avatar/icon
  const appIconContainer = node
    .append('g')
    .attr('class', d => `appIconG nodeId_${d.data.id}`)
    .attr('transform', 'translate(24, 22)')
    .style('display', d => {
      if (d.data.type === 'start') return 'none'
      return 'block'
    })

  appIconContainer.append('circle').attr('r', 18).attr('stroke-width', 2).style('fill', 'rgb(247, 216, 210)')

  appIconContainer
    .append('image')
    .attr('xlink:href', endIconImage)
    .attr('x', '-12.85')
    .attr('y', '-12.75')
    .attr('width', 25)
    .attr('height', 25)

  const controlGroup = node
    .append('g')
    .attr('class', 'controls')
    .style('display', d => {
      if (d.data.type === 'start') return 'none'
      return 'block'
    })

  // Add labels for the nodes
  controlGroup
    .append('text')
    .attr('width', store.nodeWidth)
    .attr('height', 30)
    .attr('x', 50)
    .attr('y', 17)
    .attr('class', 'endNode')
    .style('font-size', '11px')
    .style('fill', theme['fiq-red'])
    .text(d => d.pathName || d.pathKey)

  /*** frequency group ***/
  const freqGroup = controlGroup.append('g')

  const freqGroupFO = freqGroup
    .append('foreignObject')
    .attr('x', 49)
    .attr('y', 21)
    .attr('width', store.nodeWidth - 30)
    .attr('height', store.nodeHeight - 20)

  freqGroupFO.append('xhtml:div').html(d => {
    return `<div class='${css.endNodeDetails}'>${d.data.value} <span class='${
      css.divide
    }'> | </span> <span class='nodeDurationInfo'>${d.hideDuration ? '--' : d.pathDuration}</span> </div>`
  })

  //******* viewing indicator ******/
  controlGroup
    .append('circle')
    .attr('class', 'viewingDot')
    .attr('r', 4)
    .attr('cx', store.nodeWidth + 20)
    .attr('cy', 12)
    .style('fill', d => {
      return store.viewingNode && store.viewingNode.id === d.id ? '#000' : 'transparent'
    })
}
