import React, { Fragment, useEffect } from 'react'

import { Heading } from '@fortressiq/fiq-ds'

import { useHeaderDispatch } from 'app/header/HeaderContext'
import ObserverSelect from './ObserverSelect'

const ReviewHeader = ({ currentObserver, title }) => {
  const headerDispatch = useHeaderDispatch()

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: title,
      heading: (
        <Fragment>
          {title && (
            <Heading
              className='header-title'
              level={1}
              style={({ theme }) => ({ marginRight: `${theme['default-spacer-sm']} !important` })}
            >
              {title}
            </Heading>
          )}
          <ObserverSelect currentObserver={currentObserver} />
        </Fragment>
      ),
    })
  }, [headerDispatch, title, currentObserver])

  return null
}
export default ReviewHeader
