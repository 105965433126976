import React, { Fragment } from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import { Empty, Typography } from '@fortressiq/fiq-ds'

import Dom from 'lib/Dom'

import ButterflyChart from './ButterflyChart'
import ButterflyProcessStore from './stores/ButterflyProcessStore'

import css from './butterflyMainContentStyle.scss'

const defaultMargins = { top: 70, right: 150, bottom: 90, left: 90 }
const pulloutMargins = { top: 70, right: 650, bottom: 90, left: 90 }

const chartWidth = Math.max(Dom.windowWidth() - 250, 1200)
const chartHeight = Math.max(Dom.windowHeight() - 350, 600)

@observer
class ButterflyMainContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      winWidth: chartWidth,
      winHeight: chartHeight,
      margins: defaultMargins,
    }

    window.addEventListener('resize', () => this.setContentDimensions())
  }

  componentDidMount() {
    reaction(
      () => {
        return {
          defineProcessMode: ButterflyProcessStore.defineProcessMode,
        }
      },
      () => this.setContentDimensions()
    )
  }

  render() {
    const { butterflyData, showApps } = this.props
    const { winWidth, winHeight, margins } = this.state

    return (
      <div id='ButterflyMainContent' className={css.contents}>
        {Object.keys(butterflyData).length > 0 ? (
          <ButterflyChart
            width={winWidth}
            height={winHeight}
            data={butterflyData}
            showApps={showApps}
            margins={margins}
          />
        ) : (
          <div className={`${css.empty} ${css.center}`}>
            <Empty
              showImage={true}
              description={
                <Fragment>
                  <Typography.Title level={4}>No Process selected</Typography.Title>
                  <Typography.Paragraph>Select a Process from the dropdown in upper right.</Typography.Paragraph>
                </Fragment>
              }
            />
          </div>
        )}
      </div>
    )
  }

  setContentDimensions() {
    let margin = defaultMargins
    if (ButterflyProcessStore.defineProcessMode) margin = pulloutMargins

    this.setState({
      winWidth: chartWidth,
      winHeight: chartHeight,
      margins: margin,
    })
  }
}

export default ButterflyMainContent
