import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'

import ReactGA from 'react-ga'

import { useForm, useModal, Button, Group, theme, Element, Col, Row } from '@fortressiq/fiq-ds'
import { useUpdateEffect } from 'react-use'

import api from 'lib/Api'

import getMiningParamsPath from 'lib/getMiningParamsPath'
import getMiningParamsButterfly from 'lib/getMiningParamsButterfly'
import getMiningParamsFlowGraph from 'lib/getMiningParamsFlowGraph'
import graphTypeDisplayNameMap from 'lib/graphTypeDisplayNameMap'

import FieldWrapper from 'components/form/FieldWrapper'
import ControlledRadioGroup from 'components/form/ControlledRadioGroup'
import ControlledQueryBuilder from 'components/form/ControlledQueryBuilder'
import MustIncludesGroupsModal from './subComponents/MustIncludesGroupsModal'
import NestedSignaturesBuilder, {
  signaturesMustHaveDefault,
} from './subComponents/NestedSignaturesBuilder/NestedSignaturesBuilder'
import AdvancedMiningParamFields from './subComponents/AdvancedMiningParamFields'

import FormFooter from './subComponents/FormFooter'
import SignatureBuilder from './subComponents/SignatureBuilder'

import { generateAdvancedParams, generateAdvancedDefaultValues, signatureLevelLabelMap } from './helpers'

import { formWrapperCSS } from './styles'

export const queryFields = [
  { label: 'Application', name: 'application_name' },
  { label: 'Application ID', name: 'application_id' },
  { label: 'ID', name: 'id' },
  { label: 'Observed At', name: 'observed_at', defaultValue: new Date().toISOString() },
  { label: 'Observer ID', name: 'observer_id' },
  { label: 'Observer Name', name: 'observer_name' },
  { label: 'Machine', name: 'machine' },
  { label: 'Detail Signature', name: 'signature' },
  { label: 'Screen Signature', name: 'screen_signature' },
  { label: 'Screen Name', name: 'name' },
  { label: 'Action', name: 'action' },
  { label: 'Field', name: 'field' },
  { label: 'Control Type', name: 'control_type' },
  { label: 'Annotation Type', name: 'annotation_type' },
  { label: 'User', name: 'user' },
]

export const rowProps = {
  noOfCols: 2,
  noEndGutter: true,
  style: { marginBottom: theme['default-spacer-sm'], alignItems: 'flex-start' },
  type: 'grid',
}

const initialValues = {
  miningType: 'flowgraph',
  miningParams: {
    signature_level: 'screen_signature',
  },
}

const signatureLevelOptions = [
  { label: signatureLevelLabelMap.application, value: 'application' },
  { label: signatureLevelLabelMap.screen_signature, value: 'screen_signature' },
]

const miningTypeOptions = [
  { label: graphTypeDisplayNameMap.flowgraph, value: 'flowgraph' },
  { label: graphTypeDisplayNameMap.default, value: 'default' },
  { label: graphTypeDisplayNameMap.butterfly, value: 'butterfly' },
]

const modalId = 'mustIncludesGroupsModal'
const modalConfig = {
  style: { height: 'auto', width: '500px', background: theme.white },
  config: { interactWithKeyboard: true, destroyOnClose: true },
  headerStyle: { background: theme.white },
}

const AdvancedForm = ({ defaultValues, fetchData, setShowing, fromReport }) => {
  const [isValidQuery, setIsValidQuery] = useState(true)
  const getDefaultValues = generateAdvancedDefaultValues({ initialValues, defaultValues })
  const { addModal, removeModal } = useModal()
  const { control, register, reset, watch, handleSubmit, formState, setValue } = useForm({
    defaultValues: getDefaultValues,
    shouldUnregister: true,
  })

  // keep nested must includes outside of react-hook-form which doesnt support nested structures well
  // must maintain state ourselves
  // remounting sometimes disappears the nested structure (ex "clone" mining run feature)
  const [mustIncludesSignature, setMustIncludesSignature] = useState(
    getDefaultValues.miningParams.must_include_signature
  )
  const formValues = watch()

  // Reset defaultValues when cycle is selected
  useUpdateEffect(() => {
    reset({
      ...getDefaultValues,
      miningParams: {
        ...formValues.miningParams,
        ...getDefaultValues.miningParams,
      },
    })
  }, [defaultValues])

  const {
    miningParams: { must_include_signature: mustIncludesSignatureWatched, signature_level: signatureLevel },
    miningType,
  } = formValues

  const screenOrEventText = signatureLevel === 'screen' ? 'screens' : 'events'

  const miningTypeLabel = signatureLevelLabelMap[signatureLevel]

  const miningTypeLabelLowerCase = miningTypeLabel.toLowerCase()

  const advancedMiningParams = {
    default: getMiningParamsPath(miningTypeLabelLowerCase),
    path: getMiningParamsPath(miningTypeLabelLowerCase),
    butterfly: getMiningParamsButterfly(screenOrEventText, miningTypeLabelLowerCase),
    flowgraph: getMiningParamsFlowGraph(miningTypeLabelLowerCase),
  }

  useUpdateEffect(() => {
    setMustIncludesSignature(undefined)
  }, [signatureLevel])

  // can track old value this way versus useEffect
  // miningType and signatureLevel onChange call this
  const checkCompatibleMustIncludes = (newValue, oldValue) => {
    const groupsEnabled = newValue === 'flowgraph' && signatureLevel === 'screen_signature'

    // if switching to flow (groups are being enabled), we don't need to do anything
    if (groupsEnabled) {
      return
    }

    const isCompatible = isSignatureCompatible()
    if (mustIncludesSignature && isCompatible) {
      const newMustIncludes = cloneDeep(signaturesMustHaveDefault)
      newMustIncludes.signatures = transformMustIncludes()
      setMustIncludesSignature(newMustIncludes)
    }

    //only display if chaning miningType and miningType is 'path' or 'butterfly'
    if (
      mustIncludesSignature &&
      !isCompatible &&
      (newValue === 'path' || newValue === 'default' || newValue === 'butterfly')
    ) {
      renderMustIncludesGroupModal(newValue, oldValue)
    }
  }

  const renderMustIncludesGroupModal = (graphType, oldGraphType) => {
    addModal({
      children: <MustIncludesGroupsModal graphType={oldGraphType} newGraphType={graphType} />,
      footerStyle: { justifyContent: 'flex-end' },
      footer: (
        <Group>
          <Button type='primary' onClick={deleteMustIncludes}>
            Yes
          </Button>
          <Button type='default' onClick={() => onCancelChangeGraphType(oldGraphType)}>
            Cancel
          </Button>
        </Group>
      ),
      header: 'Compatible Must Include Configurations',
      id: modalId,
      ...modalConfig,
    })
  }

  const onCancelChangeGraphType = oldGraphType => {
    setValue('miningType', oldGraphType)
    removeModal(modalId)
  }

  const deleteMustIncludes = () => {
    setMustIncludesSignature(undefined)
    removeModal(modalId)
  }

  const transformMustIncludes = () => {
    const newSignatures = []
    function flattenSignatures(sigList) {
      newSignatures.push(...sigList.signatures)
      if (sigList.nested_signatures) {
        sigList.nested_signatures.forEach(list => {
          flattenSignatures(list)
        })
      }
    }
    flattenSignatures(mustIncludesSignature)
    return newSignatures
  }

  const isSignatureCompatible = () => {
    if (!mustIncludesSignature) return true

    let compatible = true
    function noOrs(nest) {
      if (nest.condition === 'or') {
        compatible = false
        return
      }
      if (nest.nested_signatures === undefined || nest.nested_signatures.length === 0) return
      nest.nested_signatures.forEach(nested => {
        noOrs(nested)
      })
    }
    noOrs(mustIncludesSignature)
    return compatible
  }

  // check if must have signatures is valid, if not we can't submit the form
  const isMustIncludesValid = () => {
    let isValid = true

    if (signatureLevel === 'application' && mustIncludesSignature) {
      console.log(mustIncludesSignature)
      mustIncludesSignature.signatures?.forEach(app => {
        if (!app) isValid = false
      })
      return isValid
    }

    function getIsValid(group) {
      group?.signatures?.forEach(sig => {
        if (!sig.primary) {
          isValid = false
        }
        if (sig.secondary_signature_level && sig.secondary_signature_level !== 'none' && !sig.secondary) {
          isValid = false
        }
      })
      group?.nested_signatures?.forEach(nGroup => getIsValid(nGroup))
    }

    getIsValid(mustIncludesSignature)
    setIsValidQuery(isValid)
    return isValid
  }

  const onSubmitHandler = async e => {
    if (!isMustIncludesValid()) return

    ReactGA.event({
      category: 'Mining Run',
      action: 'Created New Mining Run',
      value: e.miningType,
    })

    await api.post('/mining_runs', generateAdvancedParams(e, mustIncludesSignature))
    fetchData()
    setShowing(false)
  }

  return (
    <Element>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Element style={formWrapperCSS}>
          <Row {...rowProps}>
            <Col justify='flex-start'>
              <FieldWrapper
                htmlFor='miningParams.signature_level'
                label='Mining Level'
                error={formState.errors.signature_level}
                style={{ width: '350px' }}
              >
                <ControlledRadioGroup
                  isDisabled={fromReport}
                  control={control}
                  name='miningParams.signature_level'
                  onChange={checkCompatibleMustIncludes}
                  options={signatureLevelOptions}
                />
              </FieldWrapper>
            </Col>

            <Col>
              <FieldWrapper htmlFor='miningType' label='Mining Type' error={formState.errors.miningType}>
                <ControlledRadioGroup
                  isDisabled={fromReport}
                  control={control}
                  name='miningType'
                  onChange={checkCompatibleMustIncludes}
                  options={miningTypeOptions}
                />
              </FieldWrapper>
            </Col>
          </Row>
          <Element style={{ marginBottom: theme['default-spacer-md'] }}>
            <ControlledQueryBuilder
              control={control}
              fields={queryFields}
              name='filterRules'
              setIsValid={setIsValidQuery}
            />
          </Element>
          {miningType === 'butterfly' ? (
            <SignatureBuilder
              control={control}
              description={`Add multiple Center ${miningTypeLabel}s to specify multiple paths.`}
              errors={formState.errors?.miningParams?.butterfly_signatures}
              min={1}
              name='miningParams.butterfly_signatures'
              primarySignatureLabel={miningTypeLabel}
              register={register}
              signatureLevel={signatureLevel}
              title={`Center ${miningTypeLabel}`}
              watch={watch}
            />
          ) : (
            <Fragment>
              <SignatureBuilder
                watch={watch}
                control={control}
                description={`Add multiple Start ${miningTypeLabel}s to specify multiple paths.`}
                errors={formState.errors?.miningParams?.start_signatures}
                name='miningParams.start_signatures'
                primarySignatureLabel={miningTypeLabel}
                register={register}
                signatureLevel={signatureLevel}
                title={`Start ${miningTypeLabel}`}
              />
              <SignatureBuilder
                watch={watch}
                control={control}
                description={`Add multiple End ${miningTypeLabel}s to specify multiple paths.`}
                errors={formState.errors?.miningParams?.end_signatures}
                name='miningParams.end_signatures'
                primarySignatureLabel={miningTypeLabel}
                register={register}
                signatureLevel={signatureLevel}
                title={`End ${miningTypeLabel}`}
              />
            </Fragment>
          )}
          <NestedSignaturesBuilder
            control={control}
            description={`Only include paths with specified ${miningTypeLabel}s.`}
            groupsEnabled={miningType === 'flowgraph' && signatureLevel === 'screen_signature'}
            name='miningParams.must_include_signature'
            primarySignatureLabel={miningTypeLabel}
            register={register}
            signatureLevel={signatureLevel}
            title={`Must Include ${miningTypeLabel}`}
            setMustIncludesSignature={setMustIncludesSignature}
            mustIncludesSignature={mustIncludesSignature}
            watch={watch}
            isValidQuery={isValidQuery}
            setIsValidQuery={setIsValidQuery}
          />
          <AdvancedMiningParamFields
            control={control}
            advancedMiningParams={advancedMiningParams[miningType]}
            formValues={formValues}
            errors={formState.errors}
          />
        </Element>
        <FormFooter
          formValues={formValues.filterRules}
          errors={formState.errors}
          onCancel={() => setShowing(false)}
          isValid={isValidQuery}
        />
      </form>
    </Element>
  )
}

AdvancedForm.defaultProps = {
  defaultValues: undefined,
}

AdvancedForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  fetchData: PropTypes.func.isRequired,
  setShowing: PropTypes.func.isRequired,
}

export default AdvancedForm
