import React, { Fragment, memo, useEffect, useReducer } from 'react'
import { Heading, SplitPane, Element } from '@fortressiq/fiq-ds'

import ApplicationList from './ApplicationList'
import TitleGroupList from './TitleGroupList'

import { useHeaderDispatch } from '../header/HeaderContext'
import CreateWebApplicationModal from './CreateWebApplicationModal'

import { initialReducerState, reducer } from './reducers/WebApplicationsReducer'

import { RESET_APPLICATIONS } from './reducers/Actions'

import { splitPaneStyle } from './styles/index'

const pageTitle = 'Web Applications'

const WebApplications = () => {
  const headerDispatch = useHeaderDispatch()
  const [state, dispatch] = useReducer(reducer, initialReducerState)
  /*
   * Header update
   */
  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: pageTitle,
      heading: (
        <Fragment>
          <Heading className='header-title' level={1}>
            {pageTitle}
          </Heading>
          <Heading className='header-title' level={2}>
            Explore web applications.
          </Heading>
        </Fragment>
      ),
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  // initial population of applications
  useEffect(() => {
    dispatch({ type: RESET_APPLICATIONS })
  }, [])

  const splitPaneWrapperStyle = { height: '100%' }
  // TODO: Map this to (to be) defined min viewport size and not an arbitrary number:
  const minimumDefaultReadableWidth = 600
  const maximumDefaultReadableWidth = 1200

  return (
    <Element style={splitPaneWrapperStyle}>
      <SplitPane
        className='fiq-webapps-splitpane'
        defaultSize='60%'
        minSize={minimumDefaultReadableWidth}
        maxSize={maximumDefaultReadableWidth}
        style={splitPaneStyle}
      >
        <ApplicationList
          applications={state.applications}
          createAction={[
            {
              component: <CreateWebApplicationModal applications={state.applications} dispatch={dispatch} />,
            },
          ]}
          dispatch={dispatch}
          state={state}
          url='/web_applications'
          claimingEvents={state.claimingEvents}
        />
        <TitleGroupList updateClaimedFlag={state.updateClaimedFlag} />
      </SplitPane>
    </Element>
  )
}

export default memo(WebApplications)
