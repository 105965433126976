import React from 'react'
import { Icon } from '@fortressiq/fiq-ds'

const QueryBuilderRemove = ({ className, handleOnClick }) => (
  <div onClick={handleOnClick} className={className}>
    <Icon clickable={true} icon='remove' size='small' />
  </div>
)

export default QueryBuilderRemove
