import React, { forwardRef, Fragment, useEffect, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Dropdown,
  Element,
  get,
  Group,
  mergeRefs,
  Popconfirm,
  Tag,
  theme,
  Tooltip,
  Typography,
} from '@fortressiq/fiq-ds'
import { useMeasure, useMount } from 'react-use'

import { strategyOptions, paramsDetailsMap } from 'lib/SignatureStrategies'

import TextInput from './fields/TextInput'
import Form from './Form'
import {
  buttonGroup,
  overlayTriggerNodeContainer,
  paramsColStyles,
  paramsContainer,
  paramsGroupStyle,
  pCSS,
  strategyContainerCSS,
  strategyFormContainer,
  strategyTitle,
  stratVisibleBox,
  tagCSS,
} from './styles'
import { strategyParamsMap, isJsonValue } from '../utilities'

const { Paragraph, Title } = Typography
const { md, xs } = get.numericValues('spacer')

const Chain = forwardRef(
  (
    {
      applicationId,
      defaultApplyTo,
      defaultValues,
      errors,
      getValues,
      id,
      indexBeingEdited,
      register,
      removeAt,
      setIsEditingChain,
      setValue,
      trigger,
      unregister,
      watch,
    },
    ref
  ) => {
    const { params: defaultProvisionalStrategyParams, name: defaultProvisionalStrategy } = defaultValues

    const [provisionalStrategyParams, setProvisionalStrategyParams] = useState(false)
    const [provisionalStrategy, setProvisionalStrategy] = useState(defaultProvisionalStrategy)
    const [select, setSelect] = useState()
    const [highlight, setHighlight] = useState(false)
    const [findingWords, setFindingWords] = useState(false)

    const [options, setOptions] = useState([])

    const [measureRef, { width }] = useMeasure()
    const chainRef = mergeRefs(ref, measureRef)

    const watchSelectedStrategy = watch(`provisionalStrategy.${id}.params.name`, provisionalStrategy)
    const watchProvisionalStrategyFormParams = watch(`provisionalStrategy.${id}.params`)

    const applyToName = `provisionalStrategy.${id}.applyTo`

    const currentApplyTo = getValues(applyToName)

    useMount(() => {
      if (indexBeingEdited === id) {
        setIsEditingChain({ editing: id })
      }
    })

    useEffect(() => {
      setSelect(register(`provisionalStrategy.${id}.params.name`, { required: 'Provisional Strategy is required' }))
      return () => {
        unregister(`provisionalStrategy.${id}.params.name`)
      }
    }, [])

    useEffect(() => {
      if (provisionalStrategy) {
        setValue(`provisionalStrategy.${id}.params.name`, provisionalStrategy)
        trigger()
      }
    }, [provisionalStrategy])

    useEffect(() => {
      if (defaultProvisionalStrategyParams) {
        setProvisionalStrategyParams(defaultProvisionalStrategyParams)
      }
    }, [defaultProvisionalStrategyParams])

    useEffect(() => {
      const fieldKeys = options && options.length ? options.map(option => option.value) : []
      if (typeof provisionalStrategyParams !== 'string') {
        const customParams = Object.entries(provisionalStrategyParams).reduce((acc, [key, val]) => {
          if (!fieldKeys.includes(key)) {
            acc[key] = val
          } else {
            setValue(`provisionalStrategy.${id}.params.${key}`, isJsonValue(key) ? JSON.stringify(val) : val)
          }
          return acc
        }, {})
        setValue(`provisionalStrategy.${id}.params.customParams`, customParams)
        setValue(applyToName, defaultApplyTo)
      }
    }, [provisionalStrategyParams, options, defaultApplyTo, applyToName])

    useEffect(() => {
      setOptions(strategyParamsMap[watchSelectedStrategy])
    }, [watchSelectedStrategy])

    const handleChange = (name, value) => {
      setValue(name, value)
      trigger(name)
    }

    const selectChange = val => {
      setValue(`provisionalStrategy.${id}.params.name`, val)
      setProvisionalStrategy(val)

      if (val === defaultProvisionalStrategy && defaultProvisionalStrategyParams) {
        setProvisionalStrategyParams(defaultProvisionalStrategyParams)
      } else {
        setProvisionalStrategyParams({})
      }

      trigger()
    }

    const isBeingEdited =
      findingWords === true ? findingWords : indexBeingEdited.current !== null && indexBeingEdited?.current === id

    if (!select) return null

    const selectOptions = strategyOptions.filter(strat => strat.selectable === undefined || strat.selectable >= 0)

    const params = {
      ...watchProvisionalStrategyFormParams,
    }
    let thisParams = {}
    if (options) {
      options.forEach(option => {
        if (params[option.value]) {
          thisParams[option.value] = params[option.value]
        }
      })

      if (watchProvisionalStrategyFormParams?.customParams) {
        thisParams = { ...thisParams, ...watchProvisionalStrategyFormParams.customParams }
      }
    }
    const opts = thisParams ? Object.keys(thisParams) : []
    const maxTooltipLength = 300
    const tooltipProps = {
      config: {
        triggerOffset: md,
      },
      placement: 'top-start',
      style: { boxSizing: 'content-box', maxWidth: '240px' },
    }
    const sharedGroupProps = {
      colWidth: 'auto',
      gutter: xs,
      noEndGutter: true,
      type: 'grid',
    }
    const sharedTooltipProps = {
      mouseEnterDelay: 1000,
      mouseLeaveDelay: 200,
    }
    const dropdownContent = props => (
      <Element className='strat-form' style={strategyFormContainer({ findingWords })} {...props}>
        <Form
          additionalFields={
            <TextInput
              defaultValue={defaultApplyTo}
              errors={errors?.params?.applyTo}
              handleChange={handleChange}
              inputName={applyToName}
              label='Provisional Signatures to Apply Strategy To'
              name='applyTo'
              placeholder='All'
              register={register}
              setValue={setValue}
              unregister={unregister}
            />
          }
          applicationId={applicationId}
          currentProvisionalStrategyName={watchSelectedStrategy}
          errors={errors}
          findingWords={findingWords}
          id={id}
          ngramProps={props}
          provisionalStrategy={provisionalStrategy}
          provisionalStrategyParams={provisionalStrategyParams}
          register={register}
          select={select}
          selectChange={selectChange}
          selectOptions={selectOptions}
          setFindingWords={setFindingWords}
          setValue={setValue}
          trigger={trigger}
          unregister={unregister}
          getValues={getValues}
        />
      </Element>
    )
    const dropdownTriggerProps = { type: 'click' }

    const strategy = strategyOptions.find(option => option.value === watchSelectedStrategy)

    const dropdownLayerProps = {
      auto: false,
      closeOnOverlayClick: false,
      containerOffset: -100,
      placement: 'left-start',
      snap: false,
      triggerOffset: -60,
    }
    return (
      <Element
        className={!!indexBeingEdited?.current === id ? 'is-being-edited' : undefined}
        style={strategyContainerCSS}
      >
        <Element style={overlayTriggerNodeContainer}>
          <Element ref={chainRef} style={stratVisibleBox({ highlight })}>
            <Title level={5} style={strategyTitle} uppercase={true}>
              {strategy?.label}
              {!!currentApplyTo && (
                <Tooltip title='Provisional Signatures to Apply Strategy To.'>
                  <Tag color={theme.white} size='small' style={tagCSS}>
                    {currentApplyTo}
                  </Tag>
                </Tooltip>
              )}
            </Title>
            {!!strategy?.description && <Paragraph style={pCSS}>{strategy.description}</Paragraph>}
            <Element style={paramsContainer({ highlight })}>
              {!!thisParams && opts.length > 0 && (
                <Group
                  align='center'
                  colStyles={paramsColStyles}
                  {...sharedGroupProps}
                  noOfCols={opts.length}
                  style={paramsGroupStyle({ width })}
                  type='flex'
                >
                  {opts?.map((o, i) => {
                    const tooltipText = String(thisParams[o])
                    const noTooltipNeeded = tooltipText === '1' || tooltipText === '"1"' || tooltipText === 'true'
                    const title =
                      tooltipText.length > maxTooltipLength
                        ? `${tooltipText.substring(0, maxTooltipLength)}...`
                        : tooltipText

                    const Container = noTooltipNeeded ? Element : Tooltip
                    const optionContainerProps = {
                      ...(!noTooltipNeeded && {
                        title: title,
                        ...tooltipProps,
                      }),
                    }

                    const tagColor = theme[noTooltipNeeded ? 'field-bg-dark' : 'nav-btn-selected-bg']
                    const tagStyle = { cursor: noTooltipNeeded ? 'default' : 'context-menu', whiteSpace: 'nowrap' }
                    const tagText = paramsDetailsMap[o]?.label || o

                    return (
                      <Fragment key={i}>
                        <Container {...optionContainerProps}>
                          <Tag color={tagColor} size='small' style={tagStyle}>
                            {tagText}
                          </Tag>
                        </Container>
                      </Fragment>
                    )
                  })}
                </Group>
              )}
            </Element>

            <ButtonGroup size='small' style={buttonGroup}>
              <Popconfirm
                cancelText='Cancel'
                closeOnOutsideClick={true}
                okText='Delete'
                okType='danger'
                onConfirm={() => removeAt(id)}
                title='Delete Strategy?'
              >
                <Button
                  icon='trash'
                  prefixIconProps={{ color: theme.black, fill: theme.white }}
                  size='small'
                  tooltipProps={{
                    ...sharedTooltipProps,
                    placement: 'right-end',
                    title: 'Delete Strategy?',
                  }}
                  type='danger'
                />
              </Popconfirm>
              <Dropdown
                layer={dropdownLayerProps}
                overlay={dropdownContent}
                onClose={() => setHighlight(false)}
                onMount={() => setHighlight(isBeingEdited)}
                onOpen={() => {
                  setHighlight(true)
                }}
                onOutsideClick={() => {
                  if (!findingWords) {
                    setIsEditingChain({ editing: false })
                    setHighlight(false)
                  }
                }}
                trigger={dropdownTriggerProps}
                visible={isBeingEdited}
              >
                <Button
                  className='edit-btn'
                  icon='pencil'
                  onClick={() => {
                    setIsEditingChain({ editing: id })
                    setHighlight(true)
                  }}
                  size='small'
                  tooltipProps={indexBeingEdited?.current !== id && { ...sharedTooltipProps, title: 'Edit Strategy.' }}
                />
              </Dropdown>
            </ButtonGroup>
          </Element>
        </Element>
      </Element>
    )
  }
)

export default Chain
