import React from 'react'
import css from './decisionNoEditStyle.scss'

/******************
 * Inspector type: edit decision
 * UI for a decision in inspector that is not editable
 *
 ******************/

const DecisionNoEdit = props => {
  const { name, description, rules, store } = props
  return (
    <div className={css.container}>
      <div className={`${css.pathsTitle} ${css.section}`}>Conditional:</div>
      <div className={`${css.flex}`}>
        <div className={css.graphlabel}>{store.viewingNode.labelNum || '?'}</div>
        <span> {name || ' No Node Name'} </span>
      </div>
      <div className={css.description}>{description}</div>

      <div className={`${css.pathsTitle} ${css.section}`}>Paths:</div>
      {rules &&
        rules.length > 0 &&
        rules.map((item, i) => (
          <React.Fragment key={i}>
            <div className={css.flex}>
              <div className={css.graphlabel}>{item.id}</div>
              <div>{item.ruleName || ' Empty Path Label'}</div>
            </div>
            <div className={css.description}>{item.ruleDescription}</div>
          </React.Fragment>
        ))}
    </div>
  )
}

export default DecisionNoEdit
