import React from 'react'
import CirclePlus from 'images/circlePlus.svg'

import css from './menuItem.scss'

const GroupRow = ({ group, groupClick }) => {
  const { id, name, nodeIds } = group
  return (
    <div className={css.row} onClick={() => groupClick(id)}>
      <div className={css.shape}>
        <svg width={20} height={13}>
          <path d='M0 0 H 20 V 20 H 0 L 0 0' fill='#8995ad' stroke='transparent' />
        </svg>
      </div>
      <div className={css.name}>{name}</div>
      <div className={css.steps}>{nodeIds.length}</div>
      <div className={css.add}>
        <img className={css.addIcon} src={CirclePlus} />
      </div>
    </div>
  )
}
export default GroupRow
