import React from 'react'
import { observer } from 'mobx-react'
import { Button, theme, Typography, useModal } from '@fortressiq/fiq-ds'
import localizer from 'lib/Localizer'

import { addPXSuffix } from 'helpers'
import CardListing from '../CardListing'
import DetailCard from '../../DetailCard'
import CardMenu from '../Menus/CardMenu'
import UploadsModal from '../UploadsModal/UploadsModal'

import store from '../../ClientDashboardStore'

const titleMap = {
  'application/zip': 'Zip Archive',
}

const UploadsCard = observer(() => {
  const visibleUploads = store.uploadsList
  const { addModal, removeModal } = useModal()
  const modalID = 'client-dashboard-add-upload-modal'

  return (
    <DetailCard
      title='Assets'
      menu={CardMenu({
        action: () =>
          addModal({
            bodyStyle: {
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            },
            children: (
              <UploadsModal
                closeModal={() => removeModal(modalID)}
                onDropAccepted={store.attachUpload}
                onRemove={store.removeUpload}
              />
            ),
            footer: false,
            header: 'Add Uploads to Process',
            id: modalID,
            maskStyle: {
              zIndex: theme['z-modal-above'],
            },
            style: { width: addPXSuffix(300), zIndex: theme['z-overdrive'] },
          }),
        list: visibleUploads,
        type: 'Asset',
      })}
    >
      {!visibleUploads.length && <Typography.Text disabled={true}>No Assets Added</Typography.Text>}

      {visibleUploads &&
        visibleUploads.map(({ createdBy, ...upload }, index) => {
          const [uploadType] = upload.contentType.split('/')

          return (
            <CardListing
              key={`${index}_card_upload`}
              title={titleMap[upload.contentType] || uploadType}
              name={upload.name}
              buttonComponent={() => DownloadButton(upload)}
              details={[
                upload.filesize,
                `Created ${localizer.asShortestDateTime(upload.createdAt)}`,
                createdBy && `by ${createdBy}`,
              ]}
            />
          )
        })}
    </DetailCard>
  )
})

const DownloadButton = ({ fileName, fileSrc }) => (
  <Button href={fileSrc} download={fileName} target='_blank'>
    Download
  </Button>
)

export default UploadsCard
