import React, { Fragment } from 'react'
import { Heading, useFela } from '@fortressiq/fiq-ds'
import { string } from 'prop-types'

import { appHeadingCSS } from '../styles'
import { strategyOptionsMap } from '../utilities'

const Promoted = ({ strategySlug }) => {
  const { css } = useFela()
  return (
    <Fragment>
      <Heading className={css(appHeadingCSS)} level={4}>
        Promoted Strategy
      </Heading>
      {strategySlug ? strategyOptionsMap[strategySlug] : 'none'}
    </Fragment>
  )
}

Promoted.defaultProps = {
  strategySlug: undefined,
}

Promoted.propTypes = {
  strategySlug: string,
}

export default Promoted
