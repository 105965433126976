import React from 'react'
import { Select } from '@fortressiq/fiq-ds'
import { useHistory } from 'react-router-dom'

import { useFetch } from 'lib/hooks'

const ObserverSelect = ({ currentObserver }) => {
  const history = useHistory()
  const { data: observers = [], isLoading } = useFetch('/observers/filters')

  const onChange = ({ value: updatedValue }) => {
    history.push(`/review/${updatedValue}`)
  }

  return (
    <Select
      isLoading={isLoading}
      menuPortalTarget={document.body}
      onChange={onChange}
      options={observers}
      placeholder='Select an Observer'
      style={{ maxWidth: '100%', width: '250px' }}
      value={currentObserver && observers?.find(option => option.value.toString() === currentObserver.toString())}
    />
  )
}

export default ObserverSelect
