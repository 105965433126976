import React from 'react'
import { Select } from '@fortressiq/fiq-ds'
import { useList, useMap, useMount, useUpdateEffect } from 'react-use'
import { useHistory } from 'react-router-dom'

const ButterflySelect = ({ graphProcesses, graphProcessId }) => {
  const [defaultValue, { setAll }] = useMap({})
  const [selectOptions, { push }] = useList([])
  const history = useHistory()
  const onChange = e => {
    if (e.value !== defaultValue.value) {
      setAll(e)
      history.push(`/visualizations/butterfly/${e.value}`)
    }
  }

  const getSelectOptions = () =>
    graphProcesses.map(process => {
      if (process.value === Number(graphProcessId)) {
        setAll(process)
      }
      return push(process)
    })

  useMount(() => getSelectOptions())
  useUpdateEffect(() => getSelectOptions(), [graphProcesses, graphProcessId])

  return (
    <Select
      onChange={onChange}
      placeholder='Select a Process...'
      style={{ width: '300px' }}
      value={defaultValue}
      options={selectOptions}
    />
  )
}

export default ButterflySelect
