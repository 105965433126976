import React from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import { Element, theme } from '@fortressiq/fiq-ds'

import ButterflyRenderer from './ButterflyRenderer.js'

import DraggingNode from './createProcessDialogue/DraggingNode'
import ButterflyProcessStore from './stores/ButterflyProcessStore'

@observer
class ButterflyChart extends React.Component {
  componentDidMount() {
    this.renderChart()

    reaction(
      () => {
        return {
          pathsCount: ButterflyProcessStore.pathCount,
          selectedPath: ButterflyProcessStore.selectedPath,
          selectedSteps: ButterflyProcessStore.selectedSteps,
          selectedLog: ButterflyProcessStore.selectedLog,
        }
      },
      () => this.renderChart()
    )
  }

  componentDidUpdate(nextProps) {
    const { data } = this.props
    const { data: nextPropsData } = nextProps
    this.renderChart()
    if (nextPropsData !== data) {
      ButterflyProcessStore.defineProcessMode = false
    }
  }

  render() {
    const { height } = this.props

    return (
      <Element id='butterflyChart' style={{ height: `${height - height * 0.21}px` }}>
        <Element id='butterflyDragContainer' style={{ position: 'absolute', zIndex: theme['z-overdrive'] }}>
          <DraggingNode />
        </Element>
      </Element>
    )
  }

  renderChart = () => {
    const { width, height, showApps, data, margins } = this.props
    ButterflyRenderer.render({
      width: width,
      height: height,
      data: data,
      showApps: showApps,
      margins: margins,
    })
  }
}

export default ButterflyChart
