import { toJS } from 'mobx'
import store from '../../stores/processExplorerStore'

export default ({ nodeEnter, nodeUpdate }) => {
  const viewingNode = toJS(store.viewingNode)

  const pathLabel = nodeEnter
    .append('g')
    .attr('id', d => `label${d.data.id}`)
    .attr('class', 'pathLabel')
    .attr('width', 60)
    .attr('height', 15)
    .attr('transform', () => {
      const offsetX = -25
      const offsetY = store.nodeHeight / 2 + 5
      return `translate(${offsetX}, ${offsetY})`
    })
    .style('display', d => {
      return viewingNode &&
        viewingNode.data &&
        d.parent &&
        d.parent.data.children &&
        d.parent.data.children.length > 1 &&
        viewingNode.data.id === d.parent.data.id
        ? 'block'
        : 'none'
    })

  pathLabel
    .append('text')
    .attr('fill', '#797979')
    .style('font-size', '11px')
    .text(d => d.branchLabel)

  nodeUpdate.selectAll('.pathLabel').style('display', d => {
    return viewingNode &&
      viewingNode.data &&
      d.parent &&
      d.parent.data.children &&
      d.parent.data.children.length > 1 &&
      viewingNode.data.id === d.parent.data.id
      ? 'block'
      : 'none'
  })
}
