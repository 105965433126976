//TODO will be moved to DS
import React from 'react'
import { Link } from 'react-router-dom'
import { useFela } from '@fortressiq/fiq-ds'
import { bool, shape, string } from 'prop-types'

import { activeAccent, subMenuItem } from './styles'

const SubMenuItem = ({ item: { to, label }, isActive }) => {
  const { css } = useFela({ isActive })

  return (
    <Link className={css(subMenuItem)} data-id={`nav-${to}`} key={`nav-${to}`} to={to}>
      <span className={css(activeAccent)} />
      <span>{label}</span>
    </Link>
  )
}

SubMenuItem.propTypes = {
  item: shape({
    to: string.isRequired,
    label: string.isRequired,
  }).isRequired,
  isActive: bool.isRequired,
}

export default SubMenuItem
