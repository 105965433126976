/* eslint-disable import/prefer-default-export */
import { theme } from '@fortressiq/fiq-ds'

export const detailCSS = () => ({
  display: 'inline-block',
  marginRight: '92px',
  marginTop: theme['default-spacer-sm'],
  minWidth: '70px',
})

export const valueCSS = () => ({
  color: theme['text-light'],
  fontSize: '30px',
  lineHeight: '42px',
  verticalAlign: 'bottom',
})

export const titlesCSS = () => ({
  color: theme['text-default'],
  fontSize: theme['font-size-sm'],
  fontWeight: 600,
  marginLeft: '2px',
})
