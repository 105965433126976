import React, { useState } from 'react'
import { Menu, MenuItem, Icon, Overlay, useFela } from '@fortressiq/fiq-ds'

import store from '../stores/processExplorerStore'

import { amountZoom, chevron, zoomButton, zoomContainer } from './styles/index'

const ZOOM_AMOUNTS = [2, 1, 0.75, 0.5, 0.25]

const ZoomControls = () => {
  const [showing, setShowing] = useState(false)
  const { css, renderer, theme } = useFela()

  // Enables onDrag visulizations for Process Explorer:
  renderer.renderStatic({ left: 0, position: 'absolute', top: 0, zIndex: theme['z-overdrive'] }, '#dragContainer')

  const trigger = {
    node: (
      <div className={css(amountZoom)}>
        {`${parseInt(store.zoom * 100, 10)}%`}
        <Icon className={css(chevron)} icon='chevron' size={10} />
      </div>
    ),
    showing,
    setShowing,
  }

  const zoomMenu = (
    <Menu>
      {ZOOM_AMOUNTS.map((amt, i) => {
        const itemProps = {
          onClick: () => store.setZoomAmount(amt),
          key: `${amt}-${i}`,
          suffixIcon: store.zoom === amt ? { fill: theme['success-color'], icon: 'check', size: 11 } : undefined,
        }
        return <MenuItem {...itemProps} key={`zoom-${amt}`}>{`${parseInt(amt * 100, 10)}%`}</MenuItem>
      })}
    </Menu>
  )

  return (
    <div className={css(zoomContainer)}>
      <div className={css(zoomButton)} onClick={() => store.setZoomDirection('in')}>
        <Icon icon='zoomin' />
      </div>
      <div className={css(zoomButton)} onClick={() => store.setZoomDirection('out')}>
        <Icon icon='zoomout' />
      </div>
      <Overlay
        widths={{ width: 100 }}
        content={zoomMenu}
        closeIcon={false}
        trigger={trigger}
        id='zoom-controls-process-explorer'
      />
    </div>
  )
}

export default ZoomControls
