import React from 'react'
import { useFela, Element } from '@fortressiq/fiq-ds'

import {
  metaTextCSS,
  metaLabelCSS,
  metaValueCSS,
  metaValueFilterWrapCSS,
  metaValueFilterCombinatorCSS,
  metaTitlesCSS,
} from './miningRunDetailsStyles'

import NestedSignatureDetails from './NestedSignatureDetails'

import { recipeMap } from '../form/RecipesForm'

import { signatureLevelLabelMap, secondarySignatureLevelLabelMap } from '../form/helpers'

export const Filter = ({ filterRules, extraLines = undefined }) => {
  const { css } = useFela()

  if (!filterRules.rules) return null
  return (
    <div className={css(metaValueFilterWrapCSS)}>
      {filterRules.combinator && <p className={css(metaValueFilterCombinatorCSS)}>{filterRules.combinator}</p>}
      {filterRules.rules
        .filter(rule => !rule.rules)
        .map((rule, i) => (
          <div key={`rule-${rule.value}-${i}`}>
            {rule.field && (
              <div>
                <p className={css(metaTextCSS)}>
                  <span className={css(metaLabelCSS)}>
                    {rule.field.replace('_', ' ')} {rule.operator}{' '}
                  </span>
                  <span className={css(metaValueCSS)}>{rule.value || "''"}</span>
                </p>
              </div>
            )}
          </div>
        ))}
      {filterRules.rules
        .filter(rule => !!rule.rules)
        .map((rule, i) => (
          <div key={`rule-${i}`}>{rule.rules && <Filter filterRules={rule} />}</div>
        ))}
      {extraLines &&
        extraLines.map((item, i) => (
          <div key={`item-${item.value}-${i}`}>
            <p className={css(metaTextCSS)}>
              <span className={css(metaLabelCSS)}>
                {item.title}
                {' = '}
              </span>
              <span className={css(metaValueCSS)}>{item.value}</span>
            </p>
          </div>
        ))}
    </div>
  )
}

const MetaItem = ({ itemLabel, itemValue, filterRules, mustIncludes }) => {
  const { css } = useFela()

  if (mustIncludes) {
    return (
      <React.Fragment>
        <div className={css(metaTitlesCSS)}>Must Includes Signatures:</div>
        <Element style={{ marginLeft: '-16px' }}>
          <NestedSignatureDetails mustIncludes={mustIncludes} />
        </Element>
      </React.Fragment>
    )
  }

  if (filterRules) {
    return (
      <React.Fragment>
        <div className={css(metaTitlesCSS)}>Filter:</div>
        <Element style={{ marginLeft: '-16px' }}>
          <Filter filterRules={filterRules} />
        </Element>
      </React.Fragment>
    )
  }

  return (
    <p className={css(metaTextCSS)}>
      <span className={css(metaLabelCSS)}>{itemLabel}: </span>
      <span className={css(metaValueCSS)}>{itemValue}</span>
    </p>
  )
}

const MiningRunDetails = ({ miningRun, miningParamsDefaults, applicationsMap }) => {
  const isApplicationSignature = miningRun.miningParams.signature_level === 'application'
  const signaturesString = ({ signatures }) => {
    if (!signatures || signatures.length === 0) {
      return null
    }
    return signatures
      .map(({ primary, secondary_signature_level: secondarySignatureLevel, secondary }) => {
        const primaryString = isApplicationSignature && applicationsMap ? applicationsMap[primary] : primary
        return secondary
          ? `${primaryString}:${secondary}(${secondarySignatureLevelLabelMap[secondarySignatureLevel]})`
          : primaryString
      })
      ?.join(', ')
  }

  const {
    butterfly_count_after: butterflyCountAfter,
    butterfly_count_before: butterflyCountBefore,
    butterfly_signatures: butterflySignatures,
    end_signatures: endSignatures,
    must_include_signature: mustIncludeSignature,
    signature_level: signatureLevel,
    start_signatures: startSignatures,
  } = miningRun.miningParams

  const signatureLevelLabel = signatureLevelLabelMap[signatureLevel]

  const details = [
    { label: 'Mining Level', value: signatureLevelLabel },
    ...(miningRun.recipe ? [{ label: 'Recipe', value: recipeMap[miningRun.recipe.recipe_type] }] : []),
    ...(startSignatures?.signatures?.length
      ? [{ label: `Start ${signatureLevelLabel}s`, value: signaturesString(startSignatures) }]
      : []),
    ...(endSignatures?.signatures?.length
      ? [{ label: `End ${signatureLevelLabel}s`, value: signaturesString(endSignatures) }]
      : []),
    ...(butterflySignatures?.signatures?.length
      ? [
          { label: `Center ${signatureLevelLabel}s`, value: signaturesString(butterflySignatures) },
          { label: 'Events before', value: butterflyCountBefore },
          { label: 'Events after', value: butterflyCountAfter },
        ]
      : []),
  ]
  const itemsToHide = ['butterfly_signatures', 'butterfly_count_before', 'butterfly_count_after']

  const miningParamsDetails = Object.keys(miningParamsDefaults).reduce((acc, key) => {
    // display only mining params that are not equal to default value
    if (
      miningRun.miningParams[key] &&
      miningParamsDefaults[key].value.toString() !== miningRun.miningParams[key].toString() &&
      !itemsToHide.includes(key)
    ) {
      return [
        ...acc,
        {
          label: miningParamsDefaults[key].label,
          value:
            key === 'path_filter_must_include' && miningRun.miningParams[key]
              ? miningRun.miningParams[key].join(', ')
              : miningRun.miningParams[key],
        },
      ]
    }
    return acc
  }, [])

  return (
    <div>
      {details.map(({ label, value }, i) => (
        <MetaItem key={`detail-${i}`} itemLabel={label} itemValue={value} />
      ))}
      {mustIncludeSignature?.signatures?.length || mustIncludeSignature?.nested_signatures?.length ? (
        <MetaItem itemLabel={`Must Include ${signatureLevelLabel}s`} mustIncludes={mustIncludeSignature} />
      ) : null}
      {miningRun.filterRules?.rules && miningRun.filterRules.rules.length !== 0 && (
        <MetaItem itemLabel='Filter' filterRules={miningRun.filterRules} />
      )}
      {miningParamsDefaults &&
        miningParamsDetails.map(({ label, value }, i) => (
          <MetaItem key={`advanced-detail-${i}`} itemLabel={label} itemValue={value} />
        ))}
    </div>
  )
}

MiningRunDetails.propTypes = {}

export default MiningRunDetails
