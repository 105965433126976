import { observable, action, toJS } from 'mobx'

class ButterflyProcessStore {
  butterflyData = null

  @observable errorMessage = ''

  /*** path definitions when creating ***/
  @observable creatingNew = false //are we going to create a new path

  //this should display current path index we
  //"in selection phase", show us that we are working on this
  @observable selectionId = 0

  @observable pathCount = 0 //shows current number of complete paths that have been selected

  validPathsCount = 0 //the current number of valid paths

  @observable defineProcessMode = false

  @observable paths = {} //paths formed by the node end points

  @observable pathsInformation = {} //the actual path information that gets stored and referenced

  flatPathsInformation = [] //this is the format we want ot send to the server

  @observable d3HighlightData = new Map()

  // path highlighting from path statistics. other highlight is from create a process
  @observable selectedPath = {
    nodes: [],
    paths: [],
  }

  @observable stepsData = {}

  @observable selectedSteps = []

  @observable selectedLog = {}

  @action
  setStepsData() {
    let totalFrequency = 0
    const stepsMap = {}

    if (!this.butterflyData) return
    const { nodes } = this.butterflyData
    const { paths } = this.butterflyData

    paths.forEach(path => {
      path.nodeIds.forEach(nodeId => {
        const nodeIdRaw = +nodeId.replace(/_\d+$/g, '')

        let signature = ''
        const currentNode = nodes.find(node => {
          return node.id === nodeIdRaw
        })
        if (currentNode) signature = currentNode.signature

        if (stepsMap[nodeIdRaw]) {
          stepsMap[nodeIdRaw].frequency += path.frequency
        } else {
          stepsMap[nodeIdRaw] = {}
          stepsMap[nodeIdRaw].signature = signature
          stepsMap[nodeIdRaw].nodeId = nodeIdRaw
          stepsMap[nodeIdRaw].frequency = path.frequency
        }
        totalFrequency += path.frequency
      })
    })

    const tableData = Object.keys(stepsMap).map(key => ({
      key: key,
      signature: stepsMap[key].signature,
      frequency: stepsMap[key].frequency,
      //TODO have not built out the feature to specify sreen annotator contents
      examplesURL: '/instances-viewer',
    }))
    this.stepsData = { stepsMap, tableData, totalFrequency }
  }

  setSelectedLog = record => {
    const { data, exemplar_event: exemplarEvent, key } = record
    this.selectedAppUrl = data?.applications[0]?.application_icon_url
    this.selectedAppName = data?.applications[0]?.application_name
    this.selectedFreq = this.stepsData?.stepsMap[key]?.frequency

    this.selectedLog = { ...exemplarEvent, nodeId: +key }
  }

  @action
  setSelectedSteps = record => {
    const selectedStepsNew = [...this.selectedSteps]
    const keyIndex = selectedStepsNew.findIndex(key => key === record.key)

    if (keyIndex === -1) {
      selectedStepsNew.push(record.key)
    } else {
      selectedStepsNew.splice(keyIndex, 1)
    }

    if (record.isShift) {
      this.setSelectedLog(record)
    }

    if (!record.isShift) this.selectedSteps = selectedStepsNew
  }

  @action
  clearSelectedLog = () => {
    this.selectedLog = {}
  }

  @action
  setNewProcess = () => {
    this.creatingNew = true
    this.selectionId += 1
  }

  @action
  startPath = d => {
    if (!this.creatingNew) return
    this.paths[this.selectionId] = {
      pathStart: d,
      pathEnd: null,
      pathSignatures: [],
      selectionId: this.selectionId,
    }
    this.paths = { ...this.paths }
    this.creatingNew = false
  }

  @action
  endPath = d => {
    const path = this.paths[this.selectionId]
    path.pathEnd = d
    path.signatures = this.getPaths(path)
    this.paths = { ...this.paths }
    this.pathCount += 1
  }

  @action
  getPaths = path => {
    const rawNodeData = this.butterflyData.nodes
    const rawPathData = this.butterflyData.paths

    //this regex matches the string at the end of the input with an underscore and one plus numbers
    const indexRegex = /_\d+$/g

    const startNodeInfo = rawNodeData.find(node => {
      return node.signature === path.pathStart.name.replace(indexRegex, '')
    })
    const endNodeInfo = rawNodeData.find(node => {
      return node.signature === path.pathEnd.name.replace(indexRegex, '')
    })

    const startIndex = +path.pathStart.name.substring(path.pathStart.name.lastIndexOf('_') + 1)
    const endIndex = +path.pathEnd.name.substring(path.pathEnd.name.lastIndexOf('_') + 1)

    const startNodeId = `${startNodeInfo.id}_${startIndex}`
    const endNodeId = `${endNodeInfo.id}_${endIndex}`
    const relevantPaths = rawPathData.filter(rawPath => {
      return rawPath.nodeIds.find(id => id === startNodeId) && rawPath.nodeIds.find(id => id === endNodeId)
    })
    let results = relevantPaths.map(relevantPath => {
      const nodes = relevantPath.nodeIds.slice(startIndex, +endIndex + 1)
      const signatures = nodes.map(node => {
        if (!this.d3HighlightData.get(node)) {
          this.d3HighlightData.set(node, {
            pathIds: [],
            pathNumber: this.selectionId,
          })
        }
        this.d3HighlightData.get(node).pathIds.push(+relevantPath.id)

        //store the full node information into a structure for highlighting in d3
        const nodeInfo = rawNodeData.find(rawNode => {
          const nodeId = node.split('_')[0]
          return +rawNode.id === +nodeId
        })
        return nodeInfo.signature
      })
      return signatures
    })

    if (startIndex > endIndex) {
      results = []
    }
    if (!this.pathsInformation[this.selectionId]) {
      this.pathsInformation[this.selectionId] = []
    }

    //this is what we use to reference paths in the UI
    this.pathsInformation[this.selectionId].push(results)

    this.setValidPathsCount()
    // this if what we send to the server
    this.flattenPathInformation(results)
  }

  flattenPathInformation = results => {
    //TO DO, remove duplicates
    // results.sort((a, b) => a[0] < b[0])
    results.forEach(result => this.flatPathsInformation.push(result))
  }

  @action
  removePath = selectionId => {
    delete this.paths[selectionId]
    delete this.pathsInformation[selectionId]

    const highlightToRemove = []
    Object.keys(toJS(this.d3HighlightData)).forEach(key => {
      if (this.d3HighlightData.get(key).pathNumber === selectionId) highlightToRemove.push(key)
    })

    highlightToRemove.forEach(toRemove => {
      this.d3HighlightData.delete(toRemove)
    })

    this.flatPathsInformation.splice(selectionId, 1)
    this.paths = { ...this.paths }
    this.pathsInformation = { ...this.pathsInformation }
    this.setNewProcess()
    this.pathCount -= 1
    this.setValidPathsCount()
  }

  @action
  clear = () => {
    this.errorMessage = ''
    this.selectionId = 0
    this.creatingNew = true
    this.paths = {}
    this.pathsInformation = {}
    this.flatPathsInformation = []
    this.d3HighlightData.clear()
    this.pathCount = 0
    this.validPathsCount = 0
    this.selectedPath = {
      nodes: [],
      paths: [],
    }
    this.selectedSteps = []
  }

  setValidPathsCount = () => {
    this.validPathsCount = Object.keys(this.pathsInformation).filter(pathkey => {
      return this.pathsInformation[pathkey][0].length > 0
    }).length
  }

  @action setSelectedPath(nodes, paths) {
    this.selectedPath = { nodes, paths }
  }
}

export default new ButterflyProcessStore()
