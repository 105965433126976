import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

import { get, useForm, theme, Element, Row, Label } from '@fortressiq/fiq-ds'
import { useUpdateEffect } from 'react-use'

import api from 'lib/Api'

import constructApplicationOptions from 'components/form/helpers/constructApplicationOptions'

import FieldWrapper from 'components/form/FieldWrapper'
import ControlledRangePicker from 'components/form/ControlledRangePicker'
import AsyncSelect from 'components/form/AsyncSelect'
import ControlledRadioGroup from 'components/form/ControlledRadioGroup'
import ControlledSelect from 'components/form/ControlledSelect'
import { DATE_TIME_FORMAT_TWENTYFOUR_HOUR } from 'constants/app-constants'
import FormFooter from './subComponents/FormFooter'
import SignatureBuilder from './subComponents/SignatureBuilder'

import { generateBasicParams, generateBasicDefaultValues, signatureLevelLabelMap } from './helpers'

import { formWrapperCSS } from './styles'

const rowProps = {
  gutter: get.numericValues('spacer', 'md'),
  noOfCols: 2,
  type: 'grid',
  align: 'center',
}

const radioRules = [
  { value: 'all', label: 'All' },
  { value: 'in', label: 'Include' },
  { value: 'not in', label: 'Exclude' },
]

const initialValues = {
  observerRule: 'all',
  applicationRule: 'all',
  signatureLevel: 'screen_signature',
}

const BasicForm = ({ defaultValues, fetchData, setShowing }) => {
  const getDefaultValues = generateBasicDefaultValues({ initialValues, defaultValues })

  const { register, reset, watch, setValue, handleSubmit, control, formState } = useForm({
    defaultValues: getDefaultValues,
  })

  // Reset defaultValues when cycle is selected
  useUpdateEffect(() => {
    reset(getDefaultValues)
  }, [defaultValues])

  const formValues = watch()

  useEffect(() => {
    // if observer rule is all, clear observers
    if (formValues.observerRule === 'all') {
      setValue('observers', [])
    }
    if (formValues.applicationRule === 'all') {
      setValue('applications', [])
    }
  }, [formValues.observerRule, formValues.applicationRule, setValue])

  const handleObserverTagChange = async ({ target: { value } }) => {
    const tags = value.map(items => items.value)
    if (tags.length) {
      const {
        data: { observers },
      } = await api.get('/observers', { filters: { tags } })
      setValue(
        'observers',
        observers.map(observer => ({ label: observer.name, value: observer.name })),
        { shouldDirty: true }
      )
    } else {
      setValue('observers', [], { shouldDirty: true })
    }
  }

  const handleApplicationsTagChange = async ({ target: { value } }) => {
    const tags = value.map(items => items.value)
    if (tags.length) {
      const {
        data: { applications },
      } = await api.get('/applications', { filters: { tags }, per_page: 1000 })
      setValue(
        'applications',
        applications.map(application => {
          const label = application.screenStrategy
            ? `${application.displayName} (unstructured)`
            : application.displayName
          return { label: label, value: application.displayName }
        }),
        { shouldDirty: true }
      )
    } else {
      setValue('applications', [], { shouldDirty: true })
    }
  }

  const onSubmitHandler = async e => {
    ReactGA.event({
      category: 'Mining Run',
      action: 'Created New Basic Mining Run',
      value: e.miningType,
    })
    await api.post('/mining_runs', generateBasicParams(e))
    fetchData()
    setShowing(false)
  }

  const miningTypeLabel = signatureLevelLabelMap[formValues.signatureLevel]

  return (
    <Element>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Element style={formWrapperCSS}>
          <FieldWrapper htmlFor='observedAtRange' label='Observed At' error={formState.errors.observedAtRange}>
            <ControlledRangePicker
              control={control}
              id='observedAtRange'
              name='observedAtRange'
              dateFormat={DATE_TIME_FORMAT_TWENTYFOUR_HOUR}
              showTime={true}
            />
          </FieldWrapper>
          <fieldset>
            <Label>Observers</Label>
            <Row {...rowProps} style={{ marginBottom: theme['default-spacer-sm'] }}>
              <ControlledRadioGroup control={control} name='observerRule' options={radioRules} />
              <AsyncSelect
                controlled={false}
                isDisabled={formValues.observerRule === 'all'}
                name='observerTags'
                onChange={handleObserverTagChange}
                params={{ taggable_type: 'Observer' }}
                placeholder='Observers from tag'
                portal={true}
                style={{ width: '100%' }}
                url='/tags/filters'
              />
            </Row>
            <FieldWrapper htmlFor='observers' error={formState.errors.observers}>
              <ControlledSelect
                name='observers'
                url='/observers/filters'
                isDisabled={formValues.observerRule === 'all'}
                control={control}
                valueKey='label'
                placeholder='Select observers'
                portal={true}
              />
            </FieldWrapper>
          </fieldset>
          <fieldset>
            <Label>Applications</Label>
            <Row {...rowProps} style={{ marginBottom: theme['default-spacer-sm'] }}>
              <ControlledRadioGroup
                control={control}
                name='applicationRule'
                options={radioRules}
                rules={{ required: 'This field is required' }}
              />
              <AsyncSelect
                controlled={false}
                isDisabled={formValues.applicationRule === 'all'}
                name='applicationTags'
                onChange={handleApplicationsTagChange}
                params={{ taggable_type: 'TenantApplication' }}
                placeholder='Applications from tag'
                portal={true}
                style={{ width: '100%' }}
                url='/tags/filters?service=true'
              />
            </Row>
            <FieldWrapper htmlFor='applications' error={formState.errors.applications}>
              <ControlledSelect
                name='applications'
                url='/applications/filters'
                isDisabled={formValues.applicationRule === 'all'}
                control={control}
                placeholder='Select applications'
                constructData={constructApplicationOptions}
                portal={true}
              />
            </FieldWrapper>
          </fieldset>
          <SignatureBuilder
            control={control}
            description={`Add multiple Start ${miningTypeLabel}s to specify multiple paths.`}
            errors={formState.errors?.startSignatures}
            name='startSignatures'
            primarySignatureLabel={miningTypeLabel}
            register={register}
            signatureLevel={formValues.signatureLevel}
            title={`Start ${miningTypeLabel}`}
            watch={watch}
          />
          <SignatureBuilder
            control={control}
            description={`Add multiple End ${miningTypeLabel}s to specify multiple paths.`}
            errors={formState.errors?.endSignatures}
            name='endSignatures'
            primarySignatureLabel={miningTypeLabel}
            register={register}
            signatureLevel={formValues.signatureLevel}
            title={`End ${miningTypeLabel}`}
            watch={watch}
          />
        </Element>
        <FormFooter formValues={formValues} onCancel={() => setShowing(false)} />
      </form>
    </Element>
  )
}

BasicForm.defaultProps = {
  defaultValues: undefined,
}

BasicForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  fetchData: PropTypes.func.isRequired,
  setShowing: PropTypes.func.isRequired,
}

export default BasicForm
