import { shallow } from 'enzyme'
import React from 'react'

import ExploreButton from '../RulesExploreButton'

const application = { id: 123, name: 'Application Name' }

describe('ExploreButton (Web Applications) component', () => {
  it('should render without throwing an error', () => {
    const component = shallow(<ExploreButton application={application} />)
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
})
