import React from 'react'
import PropTypes from 'prop-types'
import { Element, Label, Switch, Tooltip, theme } from '@fortressiq/fiq-ds'

const SwitchSection = ({ checked, disabled, tooltip, id, label, onSwitch, style }) => {
  const sharedSwitchProps = {
    checked,
    disabled,
    id,
    inline: true,
    onSwitch,
  }

  return (
    <Element style={{ alignItems: 'center', display: 'flex', margin: `${theme['default-spacer-md']} 0`, ...style }}>
      <Label htmlFor={id} style={{ marginRight: theme['default-spacer-sm'], paddingBottom: 0 }}>
        {label}
      </Label>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <Switch {...sharedSwitchProps} />
        </Tooltip>
      ) : (
        <Switch {...sharedSwitchProps} />
      )}
    </Element>
  )
}

SwitchSection.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  onSwitch: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
}

SwitchSection.defaultProps = {
  disabled: false,
  tooltip: undefined,
  id: '',
  style: undefined,
}

export default SwitchSection
