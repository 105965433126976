import React, { useState } from 'react'
import { Input, InputNumber } from '@fortressiq/fiq-ds'

const handleInput = ({ target }) => {
  target.value = `${target.value}`.replace(/[^\d-\\.,]+/, '')
}
const MultiNumberInput = props => <Input onInput={handleInput} {...props} />

const inputTypeMap = {
  text: Input,
  integer: props => <InputNumber min='0' {...props} />,
  multiInteger: MultiNumberInput,
}

const multiValueOperators = ['in', 'not in']
const multiValueNumber = (type, operator) => type === 'integer' && multiValueOperators.includes(operator)

const QueryBuilderInput = ({ className, field, value, handleOnChange, type = 'text', operator }) => {
  const InputComponent = multiValueNumber(type, operator) ? inputTypeMap.multiInteger : inputTypeMap[type]
  const [trimmedInput, setTrimmedInput] = useState(value)

  const customHandleOnChange = inputValue => {
    setTrimmedInput(inputValue)
    handleOnChange(inputValue)
  }

  const handleChange = event => {
    const val = typeof event === 'object' ? event.target.value : event
    customHandleOnChange(val)
  }

  return (
    <div className={className}>
      <InputComponent
        name={field}
        value={trimmedInput}
        onChange={handleChange}
        onBlur={event => customHandleOnChange(event.currentTarget.value)}
      />
    </div>
  )
}

export default QueryBuilderInput
