import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { useFela } from '@fortressiq/fiq-ds'

import LoadingContainer from 'components/LoadingContainer'
import Main from 'components/main/Main'
import api from 'lib/Api'

import DashboardHeader from './DashboardHeader'
import AppsCard from './AppsCard/AppsCard'
import VisualizationCard from './VisualizationCard/VisualizationCard'
import UploadsCard from './UploadsCard/UploadsCard'

import store from '../ClientDashboardStore'
import { useHeaderDispatch } from '../../header/HeaderContext'
import { containerCss, cardContainerCss } from './styles/dashboard'

const pageTitle = 'Client Dashboard \u2014 Process Details'

const Dashboard = observer(
  ({
    match: {
      params: { id: cardId },
    },
  }) => {
    const { css } = useFela()
    const { currentCard } = store
    const headerDispatch = useHeaderDispatch()

    useEffect(() => {
      store.fetchCard(cardId)
    }, [cardId])

    useEffect(() => {
      headerDispatch({
        type: 'set',
        title: pageTitle,
        heading: pageTitle,
      })

      return () => {
        headerDispatch({
          type: 'clear',
        })
      }
    }, [headerDispatch])

    const updateCard = async details => {
      const {
        data: { dashboardCard },
      } = await api.patch(`/dashboard_cards/${currentCard.id}`, details)
      store.setCurrentCard(currentCard.id, dashboardCard)
    }

    const handleOk = newVisData => store.addVis(newVisData)

    return (
      <LoadingContainer loading={!currentCard}>
        <DashboardHeader
          id={currentCard ? currentCard.id : null}
          name={currentCard ? currentCard.name : '--'}
          tags={currentCard?.tags}
          note={currentCard?.notes}
          update={updateCard}
        />

        <Main className={css(containerCss)}>
          <div className={css(cardContainerCss)}>
            <VisualizationCard onOk={handleOk} />
            <UploadsCard />
            <AppsCard />
          </div>
        </Main>
      </LoadingContainer>
    )
  }
)

export default withRouter(Dashboard)
