import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { useFela } from '@fortressiq/fiq-ds'

import { navigationContainer, activeStyles } from './styles'

const TenantEditorNavigation = ({ id, page }) => {
  const { css } = useFela()
  const { pathname } = useLocation()
  let baseUrl = '/admin/'
  if (pathname.startsWith('/admin/tenants')) {
    baseUrl = `/admin/tenants/${id}/`
  }

  const LINKS = {
    account: { to: `${baseUrl}account`, text: 'Manage Account' },
  }

  return (
    <div className={css(navigationContainer)}>
      {Object.entries(LINKS).map(([key, { to, text }]) => (
        <Link key={key} to={to} className={page === key ? css(activeStyles) : null}>
          {text}
        </Link>
      ))}
    </div>
  )
}

TenantEditorNavigation.propTypes = {
  id: PropTypes.number.isRequired,
  page: PropTypes.string.isRequired,
}

export default TenantEditorNavigation
