// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Sidebar component should render without throwing an error 1`] = `
<div
  className="testclass"
>
  <h1>
    Test
  </h1>
</div>
`;
