//graph colors here for now versus design system
const dfgColors = {
  backgroundGray: '#eeeeee',
  backgroundGrayDark: '#E0E0E0',
  checkboxBlue: '#4E6B8C',
  dashedEdgeGray: '#8A869E',
  edgeGray: '#A9A9A9',
  edgeBlue: '#2276D9',
  edgePink: '#BD44AB',
  edgeLabelBackgroundGray: '#f5f5f5',
  nodeFillBlue: '#CDE5F5',
  nodeBorderBlue: '#2276D9',
  nodeBorderGray: '#E0E0E0',
  startGreen: '#9BA837',
  endRed: '#E34426',
  appPink: '#B44055',
  screenshotBorderGray: '#949494',
  hoverEdge: '#333333',
}

export default dfgColors
