import React from 'react'
import PropTypes from 'prop-types'
import { ButtonGroup } from '@fortressiq/fiq-ds'
import CallbackStore from 'stores/callback'
import api from 'lib/Api'

import RulesDeleteButton from './tableButtons/RulesDeleteButton'
import RulesEditButton from './tableButtons/RulesEditButton'
import RulesExploreButton from './tableButtons/RulesExploreButton'
import RulesRefreshButton from './tableButtons/RulesRefreshButton'

import { EVENTS_UPDATE, SET_CLAIMING_EVENTS, APPLICATION_DELETED, UPDATE_INFO } from '../reducers/Actions'

const Actions = ({ applications, application, claimingEvents, dispatch }) => {
  const onClickRefresh = () => {
    const callback = ({ claimedEvents: loggedEvents, firstSeenAt }) => {
      dispatch({ type: EVENTS_UPDATE, application: application, loggedEvents, firstSeenAt })
    }
    const callbackId = CallbackStore.add(callback)
    api.put('web_applications/update_browser_events', {
      application_id: application.applicationId,
      callback_id: callbackId,
      applicationUuid: application.id,
    })
    dispatch({ type: SET_CLAIMING_EVENTS, appId: application.applicationId })
  }

  const handleDelete = async () => {
    const callback = () => {
      dispatch({ type: APPLICATION_DELETED, application: application })
    }

    const callbackId = CallbackStore.add(callback)
    await api.delete(`web_applications/${application.applicationId}`, {
      callback_id: callbackId,
      applicationUuid: application.id,
    })
    dispatch({ type: SET_CLAIMING_EVENTS, appId: application.applicationId })
    dispatch({ type: UPDATE_INFO })
  }

  return (
    <ButtonGroup>
      <RulesExploreButton application={application} history={history} />
      <RulesEditButton applications={applications} application={application} dispatch={dispatch} />
      <RulesRefreshButton onClick={onClickRefresh} application={application} claimingEvents={claimingEvents} />
      <RulesDeleteButton application={application} handleDelete={handleDelete} />
    </ButtonGroup>
  )
}

Actions.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default Actions
