import React from 'react'
import numAbbr from 'number-abbreviate'
import { Card, useFela } from '@fortressiq/fiq-ds'

import SparkChart from './SparkChart'

const MetricCardBigText = ({ data, id, label, subLabel, value }) => {
  const { css, theme } = useFela()

  return (
    <Card
      bodyStyle={{ display: 'flex', height: '100%', minHeight: '180px', position: 'relative', width: '100%' }}
      style={{ height: '238px' }}
    >
      <div
        className={css({
          height: '100%',
          left: '0',
          opacity: '0.9',
          position: 'absolute',
          top: '0',
          width: '100%',
        })}
      >
        <SparkChart data={data} id={id} />
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          pointerEvents: 'none',
          position: 'relative',
        })}
      >
        <div className={css({ fontSize: '16px', paddingBottom: '20px' })}>{label}</div>
        <div className={css({ display: 'flex', flexDirection: 'column', marginBottom: '5px' })}>
          <div
            className={css({ fontSize: '50px', fontWeight: '100', height: '100%', lineHeight: '30px', width: '100%' })}
          >
            {numAbbr(value, 1) || '0'}
          </div>
          <div
            className={css({
              color: `${theme['legacy-color-gray3']}`,
              fontSize: '11px',
              fontWeight: '500',
              textTransform: 'capitalize',
            })}
          >
            {subLabel}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default MetricCardBigText
