import React from 'react'
import ReactGA from 'react-ga'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ActionCableProvider } from 'components/actioncable-provider'
import { Provider } from 'mobx-react'
import ttiPolyfill from 'tti-polyfill'
import { QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params'
import { useFela, ThemeProvider, NotificationProvider, ModalProvider, theme } from '@fortressiq/fiq-ds'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import uiStore from 'lib/UiStore'

import Flash from 'components/flash/Flash'
import MaintenanceBanner from 'components/MaintenanceBanner'

import UserNotifications from 'app/user_notifications/UserNotifications'

import AppRoutes from './AppRoutes'
import AppSidebar from './AppSidebar'
import { EventsProvider } from './events/EventContext'
import { HeaderProvider } from './header/HeaderContext'
import { UserProvider } from '../context/UserContext'
import Header from './header/Header'

import { appContentCSS, appGridCSS, rightColCSS } from './AppStyles'

ReactGA.initialize(document.getElementById('google:analytics_key').content, {
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
})

ttiPolyfill.getFirstConsistentlyInteractive().then(tti => {
  ReactGA.timing({
    category: 'Load Performace',
    variable: 'Time to Interactive',
    label: window.location.pathname,
    value: Math.round(tti),
  })
})

const callback = list => {
  list.getEntries().forEach(entry => {
    if (entry.entryType === 'navigation') {
      ReactGA.timing({
        category: 'Load Performace',
        variable: 'Server Latency',
        label: window.location.pathname,
        value: Math.round(entry.responseStart - entry.requestStart),
      })
    } else if (entry.entryType === 'paint') {
      ReactGA.timing({
        category: 'Paint',
        variable: entry.name,
        label: window.location.pathname,
        value: Math.round(entry.startTime),
      })
    } else if (entry.name.includes('App') && entry.duration > 0) {
      ReactGA.timing({
        category: 'App Render Performace',
        variable: entry.name,
        label: window.location.pathname,
        value: Math.round(entry.duration),
      })
    }
  })
}

const queryClient = new QueryClient()

const observer = new PerformanceObserver(callback)
observer.observe({
  entryTypes: ['navigation', 'paint', 'mark', 'measure', 'longtask'],
})

const queryStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
}

const alignNotificationsBesideSidebar = { left: '54px' }

const App = ({ config, user, tenant, system: { maintenance } }) => {
  uiStore.config = config
  uiStore.setTenantData(tenant)
  ReactGA.set({ dimension1: tenant.id, dimension2: tenant.name, dimension3: user.id })

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <NotificationProvider align={alignNotificationsBesideSidebar}>
          <UserProvider init={user}>
            <ActionCableProvider url={`${uiStore.config.pubsubUrl}?user_id=${user.id}`}>
              <HelmetProvider>
                <Provider uiStore={uiStore}>
                  <Router>
                    <QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
                      <ModalProvider>
                        <AppContents
                          maintenance={maintenance}
                          tenantId={tenant?.uuid}
                          userId={user?.id}
                          userRoleName={user?.roleNames}
                        />
                      </ModalProvider>
                    </QueryParamProvider>
                  </Router>
                </Provider>
              </HelmetProvider>
            </ActionCableProvider>
          </UserProvider>
        </NotificationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const AppContents = ({ maintenance, tenantId, userId, userRoleName }) => {
  const { css, renderer } = useFela()

  // Ensure the div#layers has a z-index above most UI so notifications/tooltips/etc position above content
  // with few edge cases needing z-index tweaks.
  renderer.renderStatic({ zIndex: theme['z-modal-above'] }, '#layers')

  // Setup pendo analytics:
  const dev = GIT_BRANCH.substring(0, GIT_BRANCH.length - 1) || 'development'
  const buildNumber = GIT_SHA || dev
  const versionNumber = document.getElementById('fiq:app_version').getAttribute('content') || dev
  const { locale: language } = new Intl.DateTimeFormat().resolvedOptions()

  // Setup pendo object and shared props for account/visitor:
  const sharedProps = {
    buildNumber,
    edition: 'process-discovery-platform',
    features: [],
    selfHelp: undefined,
    versionNumber,
  }
  // Send data to pendo:
  pendo.initialize({
    account: {
      id: tenantId,
      ...sharedProps,
    },
    visitor: {
      id: `${userId}:${tenantId}`,
      language,
      licenses: 'PROCESSDISCOVERYANALYZER',
      permissions: `processdiscovery:${userRoleName}`,
      ...sharedProps,
    },
  })

  return (
    <div className={css(appGridCSS)}>
      <AppSidebar />
      <div className={css(rightColCSS)}>
        <HeaderProvider>
          <EventsProvider>
            {maintenance && <MaintenanceBanner maintenance={maintenance} />}
            <Header />
            <main className={css(appContentCSS)}>
              <Flash />
              <AppRoutes />
            </main>
            <UserNotifications />
          </EventsProvider>
        </HeaderProvider>
      </div>
    </div>
  )
}

export default App
