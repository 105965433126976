import React, { useState } from 'react'
import { Icon, Typography, useFela } from '@fortressiq/fiq-ds'
import localStorage from 'lib/Storage'

import {
  eventGroupDrawerIconWrapCSS,
  eventGroupsHeaderTitleCSS,
  screenControlsDrawerCSS,
  screenControlsDrawerInnerCSS,
  screenshotRowIcon,
} from './styles'

const drawerLocalStorageKey = 'isScreenViewerDrawerOpen'

const ScreenViewerDrawer = ({ children, isMinimapEnabled }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(localStorage.get(drawerLocalStorageKey))
  const toggleIsDrawerOpen = () => {
    localStorage.set(drawerLocalStorageKey, !isDrawerOpen)
    setIsDrawerOpen(!isDrawerOpen)
  }
  const { css } = useFela({ isDrawerOpen, isMinimapEnabled })

  return (
    <div className={css(screenControlsDrawerCSS)}>
      <div className={css(eventGroupDrawerIconWrapCSS)} onClick={toggleIsDrawerOpen}>
        <Icon style={screenshotRowIcon} icon='cog' size={25} />
      </div>
      <Typography.Text style={eventGroupsHeaderTitleCSS}>
        Move Event Groups
        <Icon
          after={true}
          clickable={true}
          icon='remove'
          onClick={toggleIsDrawerOpen}
          size={16}
          style={screenshotRowIcon}
        />
      </Typography.Text>
      <div className={css(screenControlsDrawerInnerCSS)}>{children}</div>
    </div>
  )
}

export default ScreenViewerDrawer
