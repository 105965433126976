import React, { Fragment } from 'react'
import { number, string, shape, arrayOf } from 'prop-types'
import { Handle, Position } from 'reactflow'

import { ApplicationIcon, Element, theme } from '@fortressiq/fiq-ds'
import { ellipsisCSS } from 'styles/utils'

import { graphZIndex } from '../styles'

import { selectedStepsPropTypes } from '../commonPropTypes'
import dfgColors from '../dfgColors'

const textStyle = {
  ...ellipsisCSS(),
  width: '150px',
}

const StepNode = ({ id, data, selectedStep, highlightedPaths, handleSelectStep }) => {
  const selected = id === selectedStep?.id
  let outlined = false

  data.pathList.forEach(pathId => {
    if (highlightedPaths.includes(pathId)) {
      outlined = true
    }
  })

  const nodeStyle = {
    border: selected || outlined ? `2px solid ${dfgColors.nodeBorderBlue}` : `1px solid ${dfgColors.nodeBorderGray}`,
    padding: theme['default-spacer-sm'],
    width: '216px',
    height: '53px',
    borderRadius: theme['default-spacer-sm'],
    backgroundColor: selected ? dfgColors.nodeFillBlue : theme.white,
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.2) 2px 2px 3px 1px',
    zIndex: graphZIndex.node,
  }

  const frequencyStyle = {
    fontWeight: 700,
  }

  return (
    <Fragment>
      <Handle type='target' position={Position.Top} style={{ opacity: 0 }} />
      <Element style={nodeStyle} onClick={e => handleSelectStep(e, data)}>
        <ApplicationIcon
          name={data.applicationName || '--'}
          size={30}
          src={data.applicationIconUrl}
          style={{ marginRight: theme['default-spacer-sm'] }}
        />
        <div>
          <Element style={textStyle}>{data.name}</Element>
          <Element style={frequencyStyle}>{data.frequency}</Element>
        </div>
      </Element>
      <Handle type='source' id='a' position={Position.Bottom} style={{ opacity: 0 }} />
    </Fragment>
  )
}

StepNode.propTypes = {
  data: shape({
    applicationId: number.isRequired,
    endOf: arrayOf(string).isRequired,
    frequency: number.isRequired,
    id: string.isRequired,
    name: string.isRequired,
    pathList: arrayOf(string.isRequired).isRequired,
    startOf: arrayOf(string).isRequired,
    type: string.isRequired,
  }).isRequired,
  highlightedPaths: arrayOf(arrayOf).isRequired,
  id: string.isRequired,
  selectedStep: selectedStepsPropTypes,
}

export default StepNode
