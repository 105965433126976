import React, { useEffect } from 'react'
import qs from 'qs'

import { useHeaderDispatch } from 'app/header/HeaderContext'
import Loader from 'components/loaders/MainLoader'
import { useFetch } from 'lib/hooks'

import ProcessExplorer from './ProcessExplorer'
import ProcessExplorerHeader from './ProcessExplorerHeader'

const ProcessExplorerContainer = ({
  location,
  match: {
    params: { id },
  },
  ...restProps
}) => {
  const headerDispatch = useHeaderDispatch()
  const graphProcessId = id || qs.parse(location.search, { ignoreQueryPrefix: true }).graph_process_id
  const { data: processData, isLoading } = useFetch(`/process_explorer/${graphProcessId}`)
  const headerTitle = [processData?.graphProcess?.name, 'Process Explorer'].join(' - ')

  useEffect(() => {
    headerDispatch({
      type: 'set',
      heading: ProcessExplorerHeader(graphProcessId),
      title: headerTitle,
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerDispatch, headerTitle])

  const props = {
    location,
    graphProcessId,
    ...processData,
    ...restProps,
  }

  return isLoading ? <Loader /> : <ProcessExplorer {...props} />
}

export default ProcessExplorerContainer
