import React from 'react'
import PropTypes from 'prop-types'

import { Menu, MenuItem, Popconfirm, useModal } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

import ActionMenu from 'components/TrinityTable/ActionMenu/ActionMenu'

import ApplicationListForm from './ApplicationListForm'

const ActionsMenu = ({ applicationList, handleReFetch, modalConfig, isInUse, applicationsOptions }) => {
  const { addModal, removeModal } = useModal()

  const handleDelete = async () => {
    await api.delete(`/allow_block_lists/${applicationList.id}`)
    handleReFetch()
  }

  const cloneModalId = 'clone-application-list-form'
  const editModalId = 'edit-application-list-form'

  const menu = (
    <Menu bordered={false} dark={true}>
      <MenuItem
        key='edit-menu-item'
        onClick={() =>
          addModal({
            children: (
              <ApplicationListForm
                handleReFetch={handleReFetch}
                removeModal={() => removeModal(editModalId)}
                editValues={applicationList}
                editId={applicationList.id}
                isInUse={isInUse}
                applicationsOptions={applicationsOptions}
              />
            ),
            header: 'Edit list',
            id: editModalId,
            ...modalConfig,
          })
        }
      >
        Edit
      </MenuItem>
      <MenuItem
        key='clone-menu-item'
        onClick={() =>
          addModal({
            children: (
              <ApplicationListForm
                handleReFetch={handleReFetch}
                removeModal={() => removeModal(cloneModalId)}
                editValues={applicationList}
                applicationsOptions={applicationsOptions}
              />
            ),
            header: 'Create list',
            id: cloneModalId,
            ...modalConfig,
          })
        }
      >
        Clone
      </MenuItem>
      {!isInUse && (
        <MenuItem key='delete-menu-item'>
          <div onClick={e => e.stopPropagation()}>
            <Popconfirm
              cancelText='No'
              okText='Yes'
              onConfirm={() => {
                handleDelete()
              }}
              placement='left-center'
              title='Are you sure you want to delete this list?'
            >
              Delete
            </Popconfirm>
          </div>
        </MenuItem>
      )}
    </Menu>
  )
  return <ActionMenu menu={menu} />
}

ActionsMenu.defaultProps = {
  applicationsOptions: undefined,
}

ActionsMenu.propTypes = {
  applicationsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  applicationList: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    accessType: PropTypes.string,
  }).isRequired,
  handleReFetch: PropTypes.func.isRequired,
  isInUse: PropTypes.bool.isRequired,
}

export default ActionsMenu
