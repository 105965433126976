import React, { useEffect, memo } from 'react'
import { Link } from 'react-router-dom'

import { ApplicationIcon, DateCell, Menu, MenuItem } from '@fortressiq/fiq-ds'

import TrinityTable from 'components/TrinityTable'
import ActionMenu from 'components/TrinityTable/ActionMenu/ActionMenu'

import { useHeaderDispatch } from 'app/header/HeaderContext'

import StatusBadge from 'components/StatusBadge/StatusBadge'

const Status = () => {
  const headerDispatch = useHeaderDispatch()

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: 'Status',
      heading: 'Status',
    })
    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  const columns = [
    {
      accessor: 'id',
      align: 'center',
      Header: 'ID',
      width: 40,
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value }) => <strong>{value}</strong>,
    },
    {
      accessor: 'createdAt',
      align: 'center',
      Header: 'Created At',
      width: 50,
      Cell: ({ value }) => <DateCell timestamp={value} />,
    },
    {
      Header: 'Detail',
      accessor: 'detail',
    },
    {
      accessor: 'createdBy',
      align: 'center',
      Header: 'Created By',
      width: 50,
      Cell: ({ value }) => (value ? <ApplicationIcon name={value} size={30} /> : '-'),
    },
    {
      accessor: 'status',
      align: 'center',
      Header: 'Status',
      width: 50,
      Cell: ({ value }) => <StatusBadge status={value?.message ? value.message : value || 'not defined'} />,
    },
    {
      align: 'center',
      accessor: 'actions',
      disableSortBy: true,
      Header: 'Actions',
      width: 24,
      Cell: ({
        value: actions = [],
        row: {
          original: { type },
        },
      }) => {
        const menu = (
          <Menu bordered={false} dark={true}>
            {type === 'Mining Run' && (
              <MenuItem key='view-mining-runs'>
                <Link to='/mining'>View Mining Runs</Link>
              </MenuItem>
            )}
            {type === 'Recipe' && (
              <MenuItem key='view-mining-runs'>
                <Link to='/mining'>View Mining Runs</Link>
              </MenuItem>
            )}
            {['Event Log html', 'Event Log csv'].includes(type) && (
              <MenuItem key='event-log-exports'>
                <Link to='/event-log-exports'>View Event Exports</Link>
              </MenuItem>
            )}
            {type === 'PDD' && (
              <MenuItem key='view-pdds'>
                <Link to='/pdds'>View PDDs</Link>
              </MenuItem>
            )}
            {type === 'Web Application' && (
              <MenuItem key='view-web-applications'>
                <Link to='/web-applications'>View Web Applications</Link>
              </MenuItem>
            )}
            {actions.map(({ url, title }) => {
              if (!url) {
                return null
              }
              const key = `link-${url}`
              const link = url.startsWith('http') ? (
                <MenuItem key={`item-${key}`} onClick={() => window.open(url)}>
                  {title}
                </MenuItem>
              ) : (
                <MenuItem key={`item-${key}`}>
                  <Link to={url}>{title}</Link>
                </MenuItem>
              )
              return link
            })}
          </Menu>
        )
        return <ActionMenu menu={menu} />
      },
    },
  ]

  return (
    <TrinityTable
      columns={columns}
      url='job_status'
      tableId='job-status-table'
      controlledTable={false}
      manualGlobalFilter={true}
      globalFilterOptions={['statusCreatedDates', 'statusFilter', 'jobTypeFilter']}
    />
  )
}

export default memo(Status)
