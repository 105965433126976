import React, { useCallback } from 'react'
import { Button, ButtonGroup, Input, theme, useFela, useForm } from '@fortressiq/fiq-ds'
import ControlledSelect from 'components/form/ControlledSelect'
import FieldWrapper from 'components/form/FieldWrapper'

const visualizationTypes = [
  {
    value: 'path',
    label: 'Path',
  },
  {
    value: 'butterfly',
    label: 'Butterfly',
  },
  {
    value: 'fromUrl',
    label: 'From URL',
  },
]

const VisualizationModal = ({ modalID, onOk, removeModal }) => {
  const { register, control, handleSubmit, formState, watch } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true, // Will unregister conditional fields
  })

  const { css } = useFela()

  const watchFields = watch()

  // Show display name last when all other fields are filled
  const showDisplayName = !!watchFields?.graphIdPath || !!watchFields?.graphIdButterfly || !!watchFields?.url
  const showGraphIdButterfly = watchFields?.type?.value === 'butterfly'
  const showGraphIdPath = watchFields?.type?.value === 'path'
  const showFromUrl = watchFields?.type?.value === 'fromUrl'

  const onSubmit = async formValues => {
    const graphId = formValues?.graphIdButterfly?.value || formValues?.graphIdPath?.value
    const visData = {
      graphId: graphId,
      displayName: formValues?.displayName,
      url: formValues?.url,
    }
    onOk(visData)
    removeModal(modalID)
  }

  const constructGraphSelectOptions = useCallback(data => {
    return data?.graphProcesses.map(item => ({ label: item.text, value: item.value }))
  }, [])

  return (
    <form className={css({ padding: theme['default-spacer-sm'] })} onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper isRequired={true} error={formState?.errors?.type} label='Type'>
        <ControlledSelect
          control={control}
          isMulti={false}
          name='type'
          options={visualizationTypes}
          portal={true}
          rules={{ required: 'This field is required' }}
        />
      </FieldWrapper>

      {showGraphIdButterfly && (
        <FieldWrapper isRequired={true} error={formState?.errors?.graphIdButterfly} label='Graph ID'>
          <ControlledSelect
            control={control}
            isMulti={false}
            name='graphIdButterfly'
            url='/graph_processes/search'
            params={{ vis_type: watchFields?.type?.value }}
            options={visualizationTypes}
            portal={true}
            rules={{ required: 'This field is required' }}
            constructData={constructGraphSelectOptions}
          />
        </FieldWrapper>
      )}

      {showGraphIdPath && (
        <FieldWrapper isRequired={true} error={formState?.errors?.graphIdPath} label='Graph ID'>
          <ControlledSelect
            control={control}
            isMulti={false}
            name='graphIdPath'
            url='/graph_processes/search'
            params={{ vis_type: 'path' }}
            options={visualizationTypes}
            portal={true}
            rules={{ required: 'This field is required' }}
            constructData={constructGraphSelectOptions}
          />
        </FieldWrapper>
      )}

      {showFromUrl && (
        <FieldWrapper isRequired={true} error={formState?.errors?.url} label='Visualization Url'>
          <Input
            {...register('url', {
              required: 'This field is required',
              pattern: {
                value: /^[a-z0-9_.\-~!*'();:@&=+$,/?#[\]%\\\\]+$/gi,
                message: 'Valid URL is required.',
              },
            })}
          />
        </FieldWrapper>
      )}

      {showDisplayName && (
        <FieldWrapper isRequired={true} error={formState?.errors?.url} label='Display Name'>
          <Input
            {...register('displayName', {
              required: 'This field is required',
            })}
          />
        </FieldWrapper>
      )}
      <ButtonGroup style={{ display: 'block', marginTop: theme['default-spacer-md'], textAlign: 'right' }}>
        <Button
          as='span'
          onClick={() => {
            removeModal(modalID)
          }}
        >
          Cancel
        </Button>
        <Button disabled={!formState.isValid} type='secondary'>
          Add Visualization
        </Button>
      </ButtonGroup>
    </form>
  )
}

export default VisualizationModal
