import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, EditableLabel, Element, Icon, Select, Spin, Table, theme, Tooltip } from '@fortressiq/fiq-ds'
import { useMount } from 'react-use'
import { isEqual } from 'lodash'

import { getTimeElapsed } from 'lib/Time'

import Bar from './Bar'

const options = [
  { label: 'Average Duration', value: 'average' },
  { label: 'Median Time', value: 'median' },
  { label: 'Total Duration', value: 'total' },
]

const PathStatistics = ({
  graphProcessId,
  graphType,
  collapsed = [],
  paths,
  selectedPath,
  setSelectedPath,
  withColor = false,
  disablePathNameEdit = false,
  showPathLink = true,
  handlePathnameUpdate,
  toggleHideDuration,
}) => {
  const [durationType, setDurationType] = useState(options[0])
  const [pathData, setPathData] = useState({ data: undefined, totalSeconds: undefined, totalFrequency: undefined })

  useMount(() => {
    let totalPathSeconds = 0
    let totalPathFrequency = 0

    const getPathData = d =>
      d.map(path => {
        const averageDuration = Math.round(path.average_duration)
        const medianDuration = Math.round(path.median)
        const totalDuration = Math.round(averageDuration * path.frequency)
        const averageTimeStr = getTimeElapsed(averageDuration)
        const medianTimeStr = getTimeElapsed(medianDuration)
        const totalTimeStr = getTimeElapsed(totalDuration)

        totalPathSeconds += averageDuration
        totalPathFrequency += path.frequency

        return {
          ...path,
          name: path.name || path.key,
          average_duration: averageDuration,
          median_duration: medianDuration,
          total_duration: totalDuration,
          average_duration_string: averageTimeStr,
          median_duration_string: medianTimeStr,
          total_duration_string: totalTimeStr,
          duration_string: averageTimeStr,
          duration_value: averageDuration,
        }
      })

    const updatedData = getPathData(paths)

    if (!isEqual(updatedData, pathData.data)) {
      setPathData({
        data: updatedData,
        totalSeconds: totalPathSeconds,
        totalFrequency: totalPathFrequency,
      })
    }
  })

  const updateCellData = (rowIndex, columnId, value) => {
    const newData = [...pathData.data]
    newData[rowIndex][columnId] = value
    setPathData({ ...pathData, data: [...newData] })
  }

  const changeDurationType = ({ label, value }) => {
    const newData = pathData.data.map(row => ({
      ...row,
      duration_string: row[`${value}_duration_string`],
      duration_value: row[`${value}_duration`],
    }))
    setPathData({ ...pathData, data: [...newData] })
    setDurationType({ label, value })
  }

  const columns = [
    ...(withColor
      ? [
          {
            Header: '',
            accessor: 'color',
            width: 20,
            minWidth: 20,
            Cell: ({
              value: text,
              row: {
                original: { key },
              },
            }) => (
              <Element
                style={{
                  width: '10px',
                  opacity: selectedPath.paths.includes(key) ? 0.7 : 0.2,
                  backgroundColor: text,
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
              >
                &nbsp;
              </Element>
            ),
          },
        ]
      : []),
    {
      Header: 'Path',
      accessor: 'name',
      width: 80,
      minWidth: 80,
      Cell: ({ value: name, row: { original } }) => (
        <div onClick={e => e.stopPropagation()}>
          <EditableLabel
            style={{ width: '80px' }}
            value={name}
            showEditIcon={false}
            onUpdate={({ target: { value } }) => handlePathnameUpdate({ path: original, name: value, graphProcessId })}
            disabled={disablePathNameEdit}
          />
        </div>
      ),
    },
    {
      Header: 'Instances',
      accessor: 'frequency',
      width: 70,
      minWidth: 70,
      Cell: ({
        value,
        row: {
          original: { frequency },
        },
      }) => <Bar percent={(frequency / pathData.totalFrequency) * 100} text={value} />,
    },
    {
      Header: (
        <Select
          defaultValue={durationType}
          menuPortalTarget={document.body}
          onChange={e => changeDurationType(e)}
          options={options}
          size='small'
          style={{ width: '130px' }}
          styles={{
            menu: {
              zIndex: theme['z-modal-below'],
            },
          }}
        />
      ),
      accessor: 'duration_string',
      disableTooltip: true,
      isNotText: true,
      padding: '3px 0 0',
      width: 130,
      minWidth: 130,
      Cell: ({
        value,
        row: {
          original: { duration_value: durationValue, hideDuration },
        },
      }) => <Bar percent={(durationValue / pathData.totalSeconds) * 100} text={value} isHidden={hideDuration} />,
    },
    {
      Header: <Icon icon='alarmclock' size={12} style={{ left: '3px', position: 'relative', top: '3px' }} />,
      accessor: 'hideDuration',
      width: 20,
      minWidth: 20,
      Cell: ({
        value: hideDuration,
        row: {
          index: rowIndex,
          original: { id },
        },
        column: { id: columnId },
      }) => (
        <div onClick={e => e.stopPropagation()}>
          <Tooltip
            config={{ triggerOffset: 12 }}
            mouseEnterDelay={1000}
            title='Show Duration Infomation'
            placement='left-center'
          >
            <Checkbox
              checked={!hideDuration}
              onCheck={() => {
                updateCellData(rowIndex, columnId, !hideDuration)
                toggleHideDuration(id)
              }}
            />
          </Tooltip>
        </div>
      ),
    },
    showPathLink && {
      Header: '',
      accessor: 'id',
      width: 20,
      minWidth: 20,
      Cell: ({ value: id }) => (
        <Link to={`/instances-viewer/${graphProcessId}?pathId=${id}&graphType=${graphType}`}>
          <Tooltip mouseEnterDelay={1000} placement='left-center' title='View in Instances Viewer'>
            <Icon icon='instances' />
          </Tooltip>
        </Link>
      ),
    },
  ].filter(Boolean)

  const handleOnRowClick = record => {
    if (selectedPath.paths.includes(record.key)) {
      // remove from row selection
      const selectedKeys = selectedPath.paths.filter(row => record.key !== row)
      const selectedPaths = pathData.data.reduce((acc, path) => {
        if (selectedKeys.includes(path.key)) {
          acc = [...acc, ...path.path]
        }
        return acc
      }, [])
      setSelectedPath(selectedPaths, selectedKeys)
    } else {
      // add to row selection if the path isn't disabled
      const disabled = record.path.find(path => collapsed[path])
      if (!disabled) {
        const selectedKeys = [...selectedPath.paths, record.key]
        setSelectedPath([...selectedPath.nodes, ...record.path], selectedKeys)
      }
    }
  }

  const rowSelection = {
    selectedRows: selectedPath.paths,
    selectedRowKey: 'key',
    selectedRowColor: theme['table-row-select-background'],
  }

  return pathData?.data ? (
    <Table
      columns={columns}
      data={pathData.data}
      disablePagination={true}
      disableResizing={true}
      disableSortBy={true}
      loading={!pathData?.data}
      onRowClick={record => handleOnRowClick(record)}
      rowSelection={rowSelection}
      style={{ margin: `-${theme['default-spacer-sm']}` }}
      verticalBorders={false}
    />
  ) : (
    <Spin />
  )
}

export default PathStatistics
