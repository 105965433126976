import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import {
  Button,
  Col,
  Element,
  Group,
  Heading,
  Icon,
  Input,
  Row,
  SwitchView,
  TextArea,
  theme,
  Tooltip,
  Typography,
  useFela,
} from '@fortressiq/fiq-ds'

import UiStore from 'lib/UiStore'
import FixedHeader from 'components/headers/FixedHeader'
import Can from 'components/can/Can'
import Tags from 'components/tags/Tags'

import {
  headerContainerCSS,
  headerH1CSS,
  headerLinkCSS,
  notesWrapperCSS,
  removeButtonCSS,
  tagsWrapperCSS,
} from './styles/dashboard'

import store from '../ClientDashboardStore'

const { Paragraph, Text } = Typography

const DashboardHeader = ({ id, name, tags, note, update }) => {
  const { css } = useFela()
  const [formName, setFormName] = useState(name)
  const [formNote, setFormNote] = useState(note)
  const [showInput, setShowInput] = useState(false)
  const [showInputNotes, setShowInputNotes] = useState(false)
  const history = useHistory()
  const removeCardHandler = () => {
    history.push('/client-dashboard')
    store.deleteCard(store.currentCard.id)
    store.fetchTags()
  }
  const dashboardTitle = `${UiStore.tenant.name} Dashboard`

  const inputRef = useRef(null)
  const inputNotesRef = useRef(null)

  useEffect(() => {
    // If editing
    if (showInput) {
      // Wait for the switch animation to finish
      setTimeout(() => {
        // Focus input
        inputRef.current.focus()
        // Select input contents
        inputRef.current.select()
      }, 500)
    }
  }, [showInput])

  const updateName = e => {
    e.preventDefault()
    update({
      name: formName,
    })
    setShowInput(false)
  }

  const saveNote = e => {
    e.preventDefault()
    update({
      notes: formNote,
    })
    setShowInputNotes(false)
  }

  const handleChangeFormName = e => {
    setFormName(e.target.value)
  }

  const handleChangeNote = e => {
    setFormNote(e.target.value)
  }

  const iconSize = 14

  const editNameForm = (
    <form onSubmit={updateName}>
      <Group align='middle' gutter={4} justify='flex-start' style={{ marginTop: theme['default-spacer-sm'] }}>
        <Input ref={inputRef} type='text' value={formName} onChange={handleChangeFormName} size='small' />
        <Tooltip placement='right-center' title='Save Name'>
          <Icon
            after={true}
            clickable={true}
            color={theme['link-color']}
            icon='save'
            onClick={updateName}
            size={iconSize}
          />
        </Tooltip>
      </Group>
    </form>
  )

  const doShowNoteForm = () => {
    setShowInputNotes(true)
    // Wait for the switch animation to finish
    setTimeout(() => {
      // Focus input
      inputNotesRef.current.focus()
    }, 500)
  }

  const DashboardHeaderName = (
    <Heading level={1} style={headerH1CSS}>
      {name}
      <Can perform='/client-dashboard/:id:edit-name'>
        <Tooltip placement='right-center' title='Edit Name'>
          <Icon
            after={true}
            clickable={true}
            color={theme['link-color']}
            icon='edit'
            onClick={() => setShowInput(true)}
            size={iconSize}
          />
        </Tooltip>
      </Can>
    </Heading>
  )

  const Notes = (
    <Element className={css({ display: 'flex' })}>
      <Tooltip placement='right-center' title='Set Note'>
        <Icon
          after={true}
          clickable={true}
          className={css({ alignSelf: 'flex-start', marginTop: '4px' })}
          color={theme['link-color']}
          icon='edit'
          onClick={doShowNoteForm}
          size={iconSize}
        />
      </Tooltip>
      <Paragraph className={css({ margin: '0 16px' })}>{note}</Paragraph>
    </Element>
  )

  const editNote = (
    <form onSubmit={saveNote}>
      <Row type='flex' className={css({ flexFlow: 'row' })} align='flex-start' noOfCols={12}>
        <Col span={11}>
          <TextArea
            ref={inputNotesRef}
            value={formNote}
            onChange={handleChangeNote}
            containerProps={{ style: { width: '100%' } }}
            style={{ resize: 'none' }}
          />
        </Col>
        <Col>
          <Tooltip placement='right-center' title='Save Note'>
            <Icon
              after={true}
              clickable={true}
              color={theme['link-color']}
              icon='save'
              onClick={saveNote}
              size={iconSize}
            />
          </Tooltip>
        </Col>
      </Row>
    </form>
  )

  return (
    <FixedHeader helmetTitle='Process Name'>
      <Helmet defer={false}>
        <title>
          {name} - {dashboardTitle} - Process Discovery
        </title>
      </Helmet>
      <Element style={headerContainerCSS}>
        <Element style={headerLinkCSS}>
          <Link className='backToIndex' to='/client-dashboard'>
            {dashboardTitle}
          </Link>
          <SwitchView defaultView={DashboardHeaderName} switchTo={editNameForm} switchView={showInput} />
        </Element>
        <Can perform='/client-dashboard/:id:delete-process-card'>
          <RemoveCardButton onClick={removeCardHandler} />
        </Can>
      </Element>
      {tags && (
        <Element style={tagsWrapperCSS}>
          <Row type='flex' style={{ flexFlow: 'row' }} align='flex-start'>
            <Col>
              <Text>Tags:</Text>
            </Col>
            <Col>
              <Tags
                key={`tags-${id}`}
                taggableType='dashboardCard'
                taggableId={id}
                tags={tags}
                onTagsUpdate={() => store.fetchCards()}
              />
            </Col>
          </Row>
        </Element>
      )}
      <Element style={notesWrapperCSS}>
        <Row type='flex' className={css({ flexFlow: 'row' })} align='flex-start'>
          <Col>
            <Text>Notes:</Text>
          </Col>
          <Col>
            <SwitchView defaultView={Notes} switchTo={editNote} switchView={showInputNotes} />
          </Col>
        </Row>
      </Element>
    </FixedHeader>
  )
}

const RemoveCardButton = ({ onClick }) => (
  <Button onClick={onClick} className={removeButtonCSS}>
    Remove
  </Button>
)

DashboardHeader.propTypes = {
  note: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
}

export default withRouter(DashboardHeader)
