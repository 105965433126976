import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Element, Input, Upload, useFela, useNotification } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import { FLASH_LEVELS } from 'components/flash/Flash'
import Main from 'components/main/Main'
import { halfPageFormCSS } from 'components/form/styles/index'

import { useHeaderDispatch } from '../header/HeaderContext'

const pageTitle = 'Create New Template'

const TemplateCreator = () => {
  const [label, setLabel] = useState('')
  const [application, setApplication] = useState('')
  const [image, setImage] = useState(null)
  const headerDispatch = useHeaderDispatch()
  const history = useHistory()
  const { css, theme } = useFela()
  const { addNotification } = useNotification()

  useEffect(() => {
    headerDispatch({
      type: 'set',
      heading: pageTitle,
      title: pageTitle,
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  })

  const onSubmit = async () => {
    const params = { label, application, image }

    await api.upload('/template_images', params)
    history.push('/templates')
    addNotification({
      message: 'New Template Success',
      description: `"${label}" was added successfully.`,
      type: FLASH_LEVELS.SUCCESS,
    })
  }

  const onLabelChange = ({ target: { value } }) => setLabel(value)
  const onApplicationChange = ({ target: { value } }) => setApplication(value)

  const sharedInputProps = {
    style: { marginBottom: theme['default-spacer-sm'] },
  }

  return (
    <Main className={css(halfPageFormCSS)}>
      <Input label='Label' name='label' value={label} type='label' onChange={onLabelChange} {...sharedInputProps} />
      <Input
        label='Application ID'
        name='application'
        onChange={onApplicationChange}
        type='application'
        value={application}
        {...sharedInputProps}
      />
      <Element
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          lineHeight: '48px',
          marginTop: theme['default-spacer-sm'],
        }}
      >
        <Upload
          accept='image/png'
          multiple={false}
          onDrop={e => setImage(e[0])}
          thumbnailContainerProps={{ style: { width: 'auto' } }}
        >
          <Button name='templateIcon' icon='uploadbutton' prefixIconProps={{ size: 'small' }}>
            Choose Image File
          </Button>
        </Upload>
      </Element>
      <Button onClick={onSubmit} style={{ float: 'right' }} type='primary'>
        Create New Template
      </Button>
    </Main>
  )
}

export default TemplateCreator
