import React from 'react'
import { Button, Icon, Popconfirm } from '@fortressiq/fiq-ds'

const RulesDeleteButton = ({ application, handleDelete }) => {
  return (
    <Popconfirm
      cancelText='No'
      okText='Yes'
      onConfirm={handleDelete}
      placement='left-center'
      title={`Delete application ${application.displayName}?`}
    >
      <Button
        size='small'
        tooltipProps={{
          title: 'Delete existing rule and application.',
        }}
      >
        <Icon size='nested' icon='trash' />
      </Button>
    </Popconfirm>
  )
}

export default RulesDeleteButton
