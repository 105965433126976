import React, { useEffect } from 'react'
import Proptypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'

const TrackedRoute = props => {
  const {
    location: {
      pathname,
      pathname: { page },
    },
    redirect,
  } = props

  useEffect(() => {
    ReactGA.set({ page })
    ReactGA.pageview(page)
  }, [pathname, page])

  if (redirect)
    return (
      <Route {...props}>
        <Redirect {...props} to={redirect} />
      </Route>
    )

  return <Route {...props} />
}

TrackedRoute.propTypes = {
  location: Proptypes.string,
  pathname: Proptypes.string,
  page: Proptypes.string,
  redirect: Proptypes.string,
}

export default TrackedRoute
