import React from 'react'
import { shallow } from 'enzyme'
import BoundingBox from '../BoundingBox'

const defaultProps = Object.freeze({
  bounds: [517.2403354863588, 274.97888749638486, 870.7982261640798, 564.5306083100356],
  ratio: 0.22315283400809716,
  i: 0,
  deleteBoundingBox: jest.fn(),
  onActiveBox: jest.fn(),
  active: false,
  boxType: 'userBoundingBox',
  toggleHover: jest.fn(),
  isHover: false,
})

describe('BoundingBox component', () => {
  it('should render without throwing an error', () => {
    const component = shallow(<BoundingBox {...defaultProps} />)
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
  it('should call onActiveBox on click', () => {
    const output = shallow(<BoundingBox {...defaultProps} />)
    output.simulate('click')
    expect(defaultProps.onActiveBox).toHaveBeenCalled()
  })
  it('should call deleteBoundingBox on click', () => {
    const component = shallow(<BoundingBox {...defaultProps} />)
    component.find('.boundingBoxDeleteTrigger').first().simulate('click')
    expect(defaultProps.deleteBoundingBox).toHaveBeenCalled()
  })
  it('should call toggleHover on mouseOver and mouseLeave', () => {
    const component = shallow(<BoundingBox {...defaultProps} />)
    component.simulate('mouseenter')
    expect(defaultProps.toggleHover).toHaveBeenCalledTimes(1)
    component.simulate('mouseleave')
    expect(defaultProps.toggleHover).toHaveBeenCalledTimes(2)
  })
})
