/* eslint-disable import/prefer-default-export */
import { theme } from '@fortressiq/fiq-ds'

export const containerCSS = () => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  width: '100%',
})

export const cellCSS = () => ({
  color: 'hsl(217.5, 3.6%, 56.9%)',
  margin: theme['default-spacer-xs'],
})

export const showCSS = ({ showAnnotationTools }) => ({
  ...(showAnnotationTools
    ? {
        display: 'grid',
        gap: `0 ${theme['default-spacer-xs']}`,
        gridTemplateColumns: '2fr 1fr 4fr 1fr 2fr',
      }
    : { display: 'flex' }),
})

export const controlCSS = next => ({
  alignItems: 'center',
  color: theme.white,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: `flex-${next ? 'end' : 'start'}`,
})

export const controlLabelCSS = next => ({
  alignSelf: 'flex-end',
  display: 'inline-flex',
  [`padding${next ? 'Right' : 'Left'}`]: theme['default-spacer-sm'],
})
