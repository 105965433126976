import { addImportant } from 'helpers'

export const containerCss = ({ theme }) => ({
  background: theme['legacy-color-gray'],
  minHeight: '100vh',
  padding: theme['default-spacer-md'],
  width: '100%',
})

export const cardContainerCss = ({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: `-${theme['default-spacer-sm']}`,

  '& > div': {
    margin: theme['default-spacer-sm'],
  },
})

export const tagsWrapperCSS = ({ theme }) => ({
  background: theme.white,
  padding: `${theme['default-spacer-sm']} ${theme['default-spacer-md']} ${theme['default-spacer-xs']}`,
  borderTop: `1px solid ${theme['antd-card-border']}`,
  borderBottom: `1px solid ${theme['antd-card-border']}`,
})

export const notesWrapperCSS = ({ theme }) => ({
  background: theme.white,
  padding: `${theme['default-spacer-sm']} ${theme['default-spacer-md']} ${theme['default-spacer-xs']}`,
  borderTop: 0,
  borderBottom: `1px solid ${theme['antd-card-border']}`,
})

export const headerContainerCSS = ({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: `${theme['default-spacer-sm']} ${theme['default-spacer-sm']} 0`,
})

export const headerH1CSS = ({ theme }) => ({
  fontSize: addImportant('23px'),
  lineHeight: '22px',
  marginTop: theme['default-spacer-sm'],
})

export const headerLinkCSS = ({ theme }) => {
  const stateCSS = {
    color: theme.black,
    textDecoration: 'underline',
  }

  return {
    alignSelf: 'flex-start',
    '> a.backToIndex': {
      color: theme['text-color'],
      cursor: 'pointer',
      fontSize: '12px',
      fontWeight: '500',
      textDecoration: 'underline',
      '&:active': stateCSS,
      '&:focus': stateCSS,
      '&:hover': stateCSS,
    },
  }
}

export const removeButtonCSS = () => ({
  position: 'relative',
  top: '-7px',
})
