import React from 'react'
import { observer } from 'mobx-react'

import SVGDropZoneStore from 'components/dragAndDrop/SVGDropZoneStore'

import css from './draggingNodeStyle.scss'

@observer
class DraggingNode extends React.Component {
  render() {
    return SVGDropZoneStore.draggingNode ? (
      <div className={css.target} style={{ left: SVGDropZoneStore.draggingNodeX, top: SVGDropZoneStore.draggingNodeY }}>
        {SVGDropZoneStore.draggingDetails.data ? SVGDropZoneStore.draggingDetails.data.name : 'Activity'}
      </div>
    ) : null
  }
}

export default DraggingNode
