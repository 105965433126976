import { shallow } from 'enzyme'
import React from 'react'

import Details from '../Details'

const details = ['First Detail', 'Second Detail']

describe('Details component', () => {
  it('should render without throwing an error', () => {
    const component = shallow(<Details details={details} />)
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
  it('should filter falsey values', () => {
    const component = shallow(<Details details={[...details, null, undefined, false]} />)
    expect(component.text()).toBe('First Detail | Second Detail')
  })
})
