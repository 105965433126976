import React from 'react'
import { Button } from '@fortressiq/fiq-ds'

import uiStore from 'lib/UiStore'

import { creditsDataProp } from './constants'

const DownloadCreditsButton = ({ creditsData }) => {
  function saveFile(data, filename) {
    if (!data) return
    const blob = data.constructor !== Blob ? new Blob([data], { type: 'application/octet-stream' }) : data
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename)
      return
    }
    const lnk = document.createElement('a')
    const url = window.URL
    lnk.download = filename || 'untitled'
    lnk.href = url.createObjectURL(blob)
    lnk.dispatchEvent(new MouseEvent('click'))
    setTimeout(url.revokeObjectURL.bind(url, lnk.href))
  }

  const downloadCSV = async () => {
    const keys = ['observed_at_day', 'windows_username', 'credit', 'event_log_count']
    const columnHeaders = ['Date', 'Windows Username', 'Credits', 'Events']

    const replacer = (key, value) => (value === null ? '' : value)
    let csv = [...creditsData].map(row => {
      return keys
        .map(fieldName => {
          return JSON.stringify(row[fieldName], replacer)
        })
        .join(',')
    })
    csv.unshift(columnHeaders.join(','))
    csv = csv.join('\r\n')
    const blobData = new Blob([csv])
    const today = new Date()
    const dateTime = today.toISOString()

    saveFile(blobData, `${dateTime}_${uiStore.tenant.name}_credits-usage.csv`)
  }

  return (
    <Button type='secondary' icon='downloadbutton' onClick={downloadCSV}>
      Download CSV
    </Button>
  )
}

DownloadCreditsButton.propTypes = {
  creditsData: creditsDataProp.isRequired,
}

export default DownloadCreditsButton
