import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonGroup, Element, Input, theme, useModal } from '@fortressiq/fiq-ds'
import { useGetSetState } from 'react-use'
import { useHistory } from 'react-router-dom'

import api from 'lib/Api'
import Can from 'components/can/Can'
import FavoriteToggle from 'components/FavoriteToggle/FavoriteToggle'
import IntegrationsDropdown from 'components/IntegrationsDropdown'

const ToolbarActions = ({ process, updateProcess }) => {
  const history = useHistory()
  const { addModal } = useModal()
  const [get, setState] = useGetSetState({ name: process.name })
  const { id, favorited, graphType } = process

  const renameProcess = async () => {
    if (graphType === 'path') {
      const { data } = await api.put(`/graph_processes/${id}`, { name: get().name })
      updateProcess(data.process)
    }
    if (graphType === 'flowgraph') {
      await api.put(graphType === 'flowgraph' ? `/df_graph/${id}` : `/graph_processes/${id}`, {
        name: get().name,
      })
      updateProcess()
    }
  }

  const handleNameChange = ({ target: { value } }) => setState({ name: value })

  const handleDelete = async () => {
    if (graphType === 'path') {
      const { data } = await api.delete(`/graph_processes/${id}`)
      history.push(data.redirect)
    }
    if (graphType === 'flowgraph') {
      await api.delete(`/df_graph/${id}`)
      history.push('/processes')
    }
  }

  const modalStyle = { maxWidth: '400px' }

  const visualizeUrl = graphType === 'flowgraph' ? `/flow-graph/${id}` : `/process-explorer/${id}`
  const instancesUrl = `/instances-viewer/${id}?graphType=${graphType}`

  return (
    <ButtonGroup>
      <FavoriteToggle
        id={id}
        tooltip={false}
        type={graphType === 'flowgraph' ? 'flowGraph' : graphType}
        favorited={favorited}
        buttonProps={{ tooltip: true, tooltipProps: { title: 'Toggle process as favorite' } }}
      />
      <Can perform='/processes/:id:delete'>
        <Button
          onClick={() =>
            addModal({
              children: (
                <Fragment>
                  Are you sure you want to delete this process:
                  <Element
                    as='p'
                    style={{ fontWeight: '600', marginTop: theme['default-spacer-sm'], wordBreak: 'break-all' }}
                  >
                    {get().name}
                  </Element>
                </Fragment>
              ),
              config: {
                okButton: {
                  children: 'Delete',
                  type: 'danger',
                },
              },
              header: 'Delete Process',
              id: 'process-delete-modal',
              onOk: handleDelete,
              style: modalStyle,
            })
          }
          suffixIcon='trash'
          suffixIconProps={{ size: 'nested' }}
          tooltipProps={{ title: 'Delete process' }}
        >
          Delete
        </Button>
      </Can>
      <Button
        onClick={() =>
          addModal({
            children: (
              <Input
                defaultValue={get().name}
                id='rename'
                label='Process Name'
                placeholder='Process Name…'
                onChange={handleNameChange}
              />
            ),
            header: 'Rename Process',
            id: 'process-rename-modal',
            onOk: renameProcess,
            style: modalStyle,
          })
        }
        tooltipProps={{ title: 'Rename process' }}
      >
        Rename
      </Button>
      <Button href={instancesUrl} tooltipProps={{ title: 'View instances in Instances Viewer' }}>
        Instances
      </Button>
      <Button href={visualizeUrl} tooltipProps={{ title: 'View Process' }}>
        Visualize
      </Button>
      <IntegrationsDropdown key='process-integrations' integrationType='process' objectId={id} />
    </ButtonGroup>
  )
}

ToolbarActions.propTypes = {
  process: PropTypes.shape({
    favorited: PropTypes.bool,
    graphType: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    updatedAt: PropTypes.string,
  }).isRequired,
}

export default ToolbarActions
