import { helpers, theme } from '@fortressiq/fiq-ds'

const { addImportant } = helpers

export const formWrapperCSS = () => ({
  marginTop: `-${theme['default-spacer-sm']}`,
  padding: theme['default-spacer-md'],
  maxHeight: '60vh',
  overflow: 'scroll',
  position: 'relative',
})

export const titleSectionCSS = () => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme['table-border-color']}`,
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 0,
  padding: theme['default-spacer-md'],
})

export const buttonSectionCSS = () => ({
  borderTop: `1px solid ${theme['table-border-color']}`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme['default-spacer-md'],
  width: '100%',
})

export const formBackgroundCSS = () => ({
  background: theme['field-bg-light'],
  borderRadius: '2px',
  padding: theme['default-spacer-md'],
})

export const formContainerCSS = () => ({
  display: 'flex',
  flexDirection: 'column',
  width: '640px',
})

export const verticalRadioGroupCSS = () => ({ display: addImportant('block') })
export const verticalRadioItemCSS = () => ({ display: addImportant('block'), whiteSpace: addImportant('normal') })

export const scrollSectionCSS = () => ({
  flex: '1',
  marginBottom: `-${theme['default-spacer-md']}`,
  marginTop: `-${theme['default-spacer-sm']}`,
  maxHeight: '50vh',
  overflow: 'auto',
  width: '100%',
})

export const formBGCSS = () => ({
  background: theme['field-bg-light'],
  borderRadius: theme['default-spacer-sm'],
  padding: theme['default-spacer-md'],
})

export const signatureGroupCSS = () => ({
  background: 'hsla(0, 0%, 94%, 0.5)',
  border: `1px solid ${theme['legacy-color-gray1']}`,
  padding: theme['default-spacer-md'],
})

export const signatureItemCSS = () => ({ display: 'flex', marginTop: theme['default-spacer-xs'] })

export const signatureItemMarginCSS = () => ({ marginBottom: theme['default-spacer-md'] })

export const signatureInputsCSS = () => ({
  flex: '1',
})

export const removeButtonCSS = () => ({ marginLeft: theme['default-spacer-sm'], marginTop: '19px' })

export const menuItemCSS = () => ({
  background: theme['field-bg-light'],
  padding: theme['default-spacer-xs'],
})

export const observerGroupCSS = () => ({
  background: 'hsla(0, 0%, 94%, 0.5)',
  border: `1px solid ${theme['legacy-color-gray1']}`,
  padding: theme['default-spacer-md'],
  marginTop: theme['default-spacer-md'],
  marginBottom: theme['default-spacer-md'],
})
