const ROLES = {
  superuser: {
    description: 'The user has full access to the application (everything an administrator can do as well as tenant).',
    label: 'Super User',
    name: 'Super User',
    value: 'superuser',
  },
  admin: {
    description:
      'The user has full access to the application (everything a Power user can do as well as administration pages, such as "Mail Settings" and "User Management").',
    label: 'Administrator',
    name: 'Administrator',
    value: 'admin',
  },
  power: {
    description:
      'The user can access all non-administration pages and abilities (such as the ability to create "Mining Runs", "Cycles" and "Web Applications", manage "Application Signatures" and "Observers" and explore "Processes", and "Data Review").',
    label: 'Power',
    name: 'Power',
    value: 'power',
  },
  user: {
    description:
      'The user can access all pages (such as the "Observers", "Instance Viewer", "Process Explorer" and "Events" pages and able to create "Mining Runs") that aren`t used to classify and edit data.',
    label: 'Discovery',
    name: 'Discovery',
    value: 'user',
  },
  virtual_interviewer: {
    description: 'The user can only access "Data Review".',
    label: 'Data Review',
    name: 'Data Review',
    value: 'virtual_interviewer',
  },
  limited: {
    description: ' The user can only access the "Client Dashboard" and assets attached by a higher permission user.',
    label: 'Limited',
    name: 'Limited',
    value: 'limited',
  },
}

export default ROLES
