import React from 'react'
import PropTypes from 'prop-types'
import { Tag, Tooltip } from '@fortressiq/fiq-ds'

const RatingTag = ({ rating }) => {
  if (rating < 70) return null
  return (
    <Tooltip placement='top-center' title={rating.toFixed(2)}>
      <Tag color='blue'>{rating >= 85 ? 'Highly Recommended' : 'Recommended'}</Tag>
    </Tooltip>
  )
}

RatingTag.propTypes = {
  rating: PropTypes.number.isRequired,
}

export default RatingTag
