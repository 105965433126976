import React from 'react'

import { Element, theme, Typography } from '@fortressiq/fiq-ds'
import { getTimeElapsed } from 'lib/Time'
import { graphStatsPropTypes } from '../commonPropTypes'

const { Text } = Typography

const rowCSS = {
  display: 'flex',
  padding: `${theme['default-spacer-md']} 0`,
}

const columnCSS = {
  borderLeft: `1px solid ${theme['legacy-color-gray1']}`,
  flex: 1,
  padding: `${theme['default-spacer-xs']} 0`,
  textAlign: 'center',
  '&:first-child': {
    borderLeft: 0,
  },
}

const headerCSS = {
  display: 'block',
  fontSize: '14px',
  fontWeight: 500,
  marginBottom: theme['default-spacer-sm'],
}

const numberCSS = {
  color: theme['table-th-color'],
  display: 'block',
  fontSize: '10px',
  fontWeight: 500,
}

const PathsOverview = ({ graphStats }) => {
  const stats = [
    {
      title: 'Instances',
      key: 'stats-instances',
      count: graphStats?.frequency || 0,
    },
    {
      title: 'Total duration',
      key: 'stats-total-duration',
      count: getTimeElapsed(graphStats?.total_duration || 0),
    },
    {
      title: 'Mean duration',
      key: 'stats-mean-duration',
      count: getTimeElapsed(graphStats?.mean_duration || 0),
    },
    {
      title: 'Median duration',
      key: 'stats-median-duration',
      count: getTimeElapsed(graphStats?.median_duration || 0),
    },
  ]

  return (
    <Element style={rowCSS}>
      {stats.map(({ title, count, key }) => (
        <Element style={columnCSS} key={key}>
          <Text style={headerCSS}>{count}</Text>
          <Text style={numberCSS}>{title}</Text>
        </Element>
      ))}
    </Element>
  )
}

PathsOverview.propTypes = {
  graphStats: graphStatsPropTypes,
}

export default PathsOverview
