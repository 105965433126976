import React from 'react'

import ObserverSelect from '../ObserverSelect'

jest.mock('react-router-dom', () => ({
  useHistory: () => ({ push: jest.fn() }),
}))

jest.mock('lib/hooks', () => ({
  useFetch: () => ({
    data: [
      { value: 1, label: 'name1', type: 'observer' },
      { value: 2, label: 'name2', type: 'observer' },
    ],
  }),
}))

const defaultProps = Object.freeze({ currentObserver: '1' })

describe('ObserverSelect component', () => {
  it('should render without throwing an error', () => {
    const component = global.shallowWithTheme(<ObserverSelect {...defaultProps} />)
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
})
