import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Element, Tab, Tabs, Typography } from '@fortressiq/fiq-ds'
import { useUnmount } from 'react-use'

import api from 'lib/Api'

import uiStore from 'lib/UiStore'
import AsyncSelect from 'components/form/AsyncSelect'

import BasicForm from './BasicForm'
import AdvancedForm from './AdvancedForm'
import ReportForm from './ReportForm'

import { checkIsAdvanced } from './helpers'

import { titleSectionCSS } from './styles'

const { Title } = Typography

const tabStyle = { minHeight: '150px' }

const TABS = { BASIC: 1, ADVANCED: 2, REPORT: 3, RECIPES: 4 }

const isLite = uiStore?.tenant?.isLite

const MiningRunForms = ({ setShowing, fetchData, clonedMiningRun, setClonedMiningRun }) => {
  const fromReport = clonedMiningRun?.fromReport
  const isAdvanced = clonedMiningRun && (checkIsAdvanced(clonedMiningRun) || fromReport)
  const isReport = clonedMiningRun && clonedMiningRun.miningType === 'report'

  const defaultActiveTab = () => {
    if (clonedMiningRun) {
      if (isLite) return TABS.BASIC
      if (!isReport && isAdvanced) {
        return TABS.ADVANCED
      }
      if (isReport) {
        return TABS.REPORT
      }
    }
    return TABS.BASIC
  }

  const [activeTab, setActiveTab] = useState(defaultActiveTab)
  const [defaultValues, setDefaultValues] = useState(clonedMiningRun)

  const onCycleChange = async e => {
    const { data } = await api.get(`/cycles/${e.target.value.value}`)
    setDefaultValues(data.cycle)
  }

  // clear default values when closing modal
  useUnmount(() => {
    if (clonedMiningRun) {
      setClonedMiningRun(undefined)
    }
  })

  return (
    <Element style={{ width: '620px' }}>
      <Element style={titleSectionCSS}>
        <Title level={5} noMargin={true}>
          Create a Mining Run
        </Title>
        <AsyncSelect
          url='/cycles/filters'
          placeholder='Cycles'
          onChange={onCycleChange}
          name='cycle'
          style={{ width: '200px' }}
          isMulti={false}
        />
      </Element>
      <Tabs
        noPadding={true}
        headerBorder={true}
        size='large'
        activeIndex={activeTab}
        handleChange={e => setActiveTab(e)}
      >
        <Tab style={tabStyle} tab='Basic' disabled={isReport || fromReport}>
          {activeTab === TABS.BASIC && (
            <BasicForm defaultValues={defaultValues} fetchData={fetchData} setShowing={setShowing} />
          )}
        </Tab>
        {!isLite && (
          <Tab style={tabStyle} tab='Advanced' disabled={isReport}>
            {activeTab === TABS.ADVANCED && (
              <AdvancedForm
                defaultValues={defaultValues}
                fetchData={fetchData}
                setShowing={setShowing}
                fromReport={fromReport}
              />
            )}
          </Tab>
        )}
        {!isLite && (
          <Tab style={tabStyle} tab='Report' disabled={fromReport}>
            {activeTab === TABS.REPORT && (
              <ReportForm defaultValues={defaultValues} fetchData={fetchData} setShowing={setShowing} />
            )}
          </Tab>
        )}
      </Tabs>
    </Element>
  )
}

MiningRunForms.defaultProps = {
  clonedMiningRun: undefined,
}
MiningRunForms.propTypes = {
  clonedMiningRun: PropTypes.shape({}),
  fetchData: PropTypes.func.isRequired,
  setShowing: PropTypes.func.isRequired,
}

export default MiningRunForms
