import React, { useState } from 'react'
import qs from 'qs'
import { number, object, string, arrayOf, func, shape, bool } from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import {
  Button,
  combineRules,
  defaultDropdownLayerProps,
  Dropdown,
  Element,
  get,
  theme,
  useFela,
} from '@fortressiq/fiq-ds'

import api from 'lib/Api'

// TODO: move cycles select or don't do cycles here.
// components should not be importing from app. this is a one way trip
// import CyclesSelect from 'app/mining/formComponents/CyclesSelect'
import AsyncSelect from 'components/form/AsyncSelect'

import QueryBuilder from '../QueryBuilder'
import { filterContainer, cyclesWrap, applyButton } from './styles/index.js'

/******
 * A dropdown filtering component that wraps QueryBuilder
 * props:
 *   QueryBuilder props ( fields, onQueryChange)
 *   align: left align the menu or right align (default left)
 *   positionX: pixels margin from the 'align' position (default 0)
 *   positionY: pixels from the filter button bottom
 ******/
const QueryBuilderDropdown = ({
  align,
  buttonStyle,
  dropdownStyle,
  query: queryProp,
  onQueryChange,
  positionX,
  positionY,
  isPersisted,
  cycles,
  ...props
}) => {
  const location = useLocation()
  const history = useHistory()
  const { filter, ...restQueryParams } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [query, setQuery] = useState({ rules: [], combinator: 'and', ...(queryProp || filter) })
  const [isValidQuery, setIsValidQuery] = useState(true)
  const [visible, setIsVisible] = useState(false)
  const { css } = useFela()

  const applyFilter = () => {
    if (isPersisted) {
      const params = { ...restQueryParams }
      if (query.rules.length) {
        params.filter = query
      }
      const encoded = qs.stringify(params, { encodeValuesOnly: true })
      history.push([location.pathname, encoded].join('?'))
    }
    onQueryChange(query)
    setIsVisible(false)
  }

  const onCycleChange = async e => {
    const { data } = await api.get(`/cycles/${e.target.value.value}`)
    // TEMP: do not allow for start and end signatures
    if (data.cycle.filterRules.rules) {
      data.cycle.filterRules.rules = data.cycle.filterRules.rules.filter(
        rule => rule.field !== 'start_screen_signature' && rule.field !== 'end_screen_signature'
      )
    }
    setQuery(data.cycle.filterRules)
  }

  const prefixIconProps = { size: 14 }

  return (
    <Dropdown
      layer={{
        ...defaultDropdownLayerProps,
        closeOnOverlayClick: false,
        placement: 'bottom-start',
        triggerOffset: get.numericValues('spacer').sm,
        style: { zIndex: theme['z-modal-base'] },
      }}
      trigger={{ type: 'click' }}
      visible={visible}
      onOpen={() => setIsVisible(true)}
      onClose={() => setIsVisible(false)}
      overlay={overlayProps => (
        <Element className={css(combineRules(filterContainer, dropdownStyle))} {...overlayProps}>
          <div className={css(cyclesWrap)}>
            <AsyncSelect
              isMulti={false}
              name='cycle'
              onChange={onCycleChange}
              placeholder='Cycles'
              style={{ width: '200px' }}
              portal={true}
              url='/cycles/filters'
            />
          </div>
          <QueryBuilder setQuery={setQuery} query={query} {...props} setIsValid={setIsValidQuery} />

          <Element
            style={{
              alignItems: 'flex-end',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button className={css(applyButton)} type='secondary' onClick={applyFilter} disabled={!isValidQuery}>
              Apply
            </Button>
          </Element>
        </Element>
      )}
    >
      <Button
        className={css(combineRules(buttonStyle, { marginLeft: theme['default-spacer-sm'] }))}
        icon='filter'
        prefixIconProps={prefixIconProps}
        suffixIcon='arrowdown'
      >
        Filter
      </Button>
    </Dropdown>
  )
}

QueryBuilderDropdown.propTypes = {
  align: string,
  positionX: number,
  positionY: number,
  isPersisted: bool,
  fields: arrayOf(
    shape({
      name: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  onQueryChange: func,
  noGroups: bool,
  // eslint-disable-next-line react/forbid-prop-types
  buttonStyle: object,
  // eslint-disable-next-line react/forbid-prop-types
  dropdownStyle: object,
}

QueryBuilderDropdown.defaultProps = {
  align: 'left',
  positionX: 0,
  positionY: 0,
  noGroups: false,
  isPersisted: false,
  buttonStyle: null,
  onQueryChange: () => null,
  dropdownStyle: null,
}

export default QueryBuilderDropdown
