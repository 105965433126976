import React from 'react'

import Sidebar from '../Sidebar'

const className = 'testclass'
const defaultProps = Object.freeze({ className: 'testclass' })

jest.mock('@fortressiq/fiq-ds', () => {
  return {
    useFela: () => ({
      css: () => {},
      theme: () => ({
        white: '#fff',
      }),
    }),
  }
})

describe('Sidebar component', () => {
  it('should render without throwing an error', () => {
    const component = global.shallowWithTheme(
      <Sidebar {...defaultProps}>
        <h1>Test</h1>
      </Sidebar>
    )
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })

  it('should render with provided className', () => {
    const component = global.shallowWithTheme(
      <Sidebar {...defaultProps}>
        <h1>Test</h1>
      </Sidebar>
    )
    expect(component.hasClass(className)).toBe(true)
  })
})
