import React from 'react'
import PropTypes from 'prop-types'

import { Button, Group, useFela, Select, Typography, Checkbox, theme } from '@fortressiq/fiq-ds'
import { toolbarCSS, detailsListCSS } from './styles/index.js'

import FormOverlay from './FormOverlay'

const { Text } = Typography

const Toolbar = ({
  canNextPage,
  canPreviousPage,
  changeStep,
  currentSignature: { rank, screen_signature: screenSignature, user_selected: userSelected, type },
  step,
  stepLength,
  toggleSignature,
  startStopRankings,
  miningParams,
  filterRules,
}) => {
  const { css } = useFela()

  const selectOptions = Array.from({ length: stepLength }, (v, i) => i + 1).map(num => ({ label: num, value: num }))

  return (
    <div className={css(toolbarCSS)}>
      <ul className={css(detailsListCSS)}>
        <Text as='li' ellipsis={true}>
          Signature: {screenSignature}
        </Text>
        <li>Type: {type}</li>
        <li>Rank: {rank}</li>
      </ul>
      <Group
        style={{
          alignItems: 'center',
          flexShrink: 0,
        }}
        noEndGutter={true}
      >
        <Group>
          <span>Signature</span>
          <Select
            options={selectOptions}
            onChange={({ value }) => changeStep(Number(value))}
            size='small'
            value={selectOptions.find(option => option.value === step)}
          />
          <span>of {stepLength}</span>
        </Group>
        <Button size='small' onClick={() => changeStep(step - 1)} disabled={canPreviousPage}>
          {'<'}
        </Button>
        <Button size='small' onClick={() => changeStep(step + 1)} disabled={canNextPage}>
          {'>'}
        </Button>
        <Checkbox
          style={{ marginLeft: theme['default-spacer-sm'] }}
          checked={userSelected}
          onCheck={() => toggleSignature(step)}
        >
          Use {type} signature
        </Checkbox>
        <FormOverlay startStopRankings={startStopRankings} miningParams={miningParams} filterRules={filterRules} />
      </Group>
    </div>
  )
}

Toolbar.propTypes = {
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  changeStep: PropTypes.func.isRequired,
  currentSignature: PropTypes.shape({
    screen_signature: PropTypes.string,
    type: PropTypes.oneOf(['start', 'end']),
    rank: PropTypes.number,
    user_selected: PropTypes.bool,
  }).isRequired,
  filterRules: PropTypes.shape().isRequired,
  miningParams: PropTypes.shape().isRequired,
  startStopRankings: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.number,
      screen_signature: PropTypes.string,
      type: PropTypes.oneOf(['start', 'end']),
      user_selected: PropTypes.bool,
    })
  ).isRequired,
  step: PropTypes.number.isRequired,
  stepLength: PropTypes.number.isRequired,
  toggleSignature: PropTypes.func.isRequired,
}

export default Toolbar
