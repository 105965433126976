export const detailsColCss = ({ theme }) => ({
  maxWidth: '250px',
  paddingRight: theme['default-spacer-md'],
})

export const nameCss = ({ theme }) => ({
  fontSize: theme['font-size-base'],
  lineHeight: '14px',
  marginBottom: theme['default-spacer-xs'],
  marginRight: theme['default-spacer-md'],
  maxWidth: '173px',
  wordBreak: 'break-all',
})

export const flexRowCss = ({ theme }) => ({
  borderBottom: `1px solid ${theme['legacy-color-gray2']}`,
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme['default-spacer-md'],
  padding: `${theme['default-spacer-xs']} 0 ${theme['default-spacer-sm']} 0`,

  '&:first-child': {
    paddingTop: '0',
  },

  '&:last-child': {
    borderBottom: '0',
  },
})
