import React from 'react'

import { mockGraphColors } from '../../../../lib/test_helpers/mockGraphColors.js'
import GroupsControlBase from '../GroupsControl'

jest.mock('../../stores/processExplorerStore', () => ({
  groups: {
    groupList: [],
    toggleGroup: jest.fn,
  },
}))

jest.mock('@fortressiq/fiq-ds', () => {
  return {
    useFela: () => ({
      css: () => ({}),
    }),
    theme: () => ({}),
    Dropdown: () => ({}),
    Menu: () => ({}),
    MenuItem: () => ({}),
  }
})

jest.mock('@fortressiq/fiq-ds-themes/dist/js/graphColors.js', () => mockGraphColors)

describe('ControlsBar GroupsControl component', () => {
  it('should render without throwing an error', () => {
    const component = global.shallowWithTheme(<GroupsControlBase currentUser={{ isLimited: false }} />)
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
})
