import { theme } from '@fortressiq/fiq-ds'

const fauxColorArray = [
  theme['navy-tints-7'],
  theme['wine-tints-7'],
  theme['green-tints-9'],
  theme['teal-tints-1'],
  theme['orange-tints-5'],
  theme['yellow-tints-0'],
  theme['jade-tints-4'],
  theme['red-tints-7'],
  theme['blue-tints-0'],
  theme['purple-tints-8'],
]

const fauxColorObject = [
  {
    name: 'Blue',
    hex: theme['navy-tints-3'],
    light: theme['navy-tints-0'],
  },
  {
    name: 'Salmon',
    hex: theme['legacy-color-salmon'],
    light: theme['legacy-color-salmon-light'],
  },
  {
    name: 'Green',
    hex: theme['green-tints-13'],
    light: theme['green-tints-13'],
  },
  {
    name: 'Turquoise',
    hex: theme['legacy-color-turquoise'],
    light: theme['legacy-color-turquoise-pale'],
  },
  {
    name: 'Orange',
    hex: theme['orange-tints-8'],
    light: theme['orange-tints-2'],
  },
  {
    name: 'Yellow',
    hex: theme['yellow-tints-5'],
    light: theme['yellow-tints-0'],
  },
  {
    name: 'Light Green',
    hex: theme['jade-tints-8'],
    light: theme['jade-tints-1'],
  },
  {
    name: 'Red',
    hex: theme['red-tints-7'],
    light: theme['red-tints-1'],
  },
  {
    name: 'Pale Turquoise',
    hex: theme['teal-tints-5'],
    light: theme['teal-tints-1'],
  },
  {
    name: 'Violet',
    hex: theme['purple-tints-13'],
    light: theme['purple-tints-3'],
  },
]
// eslint-disable-next-line import/prefer-default-export
export const mockGraphColors = fauxColorArray

export const graphColors = fauxColorArray

export const graphColorsLight = fauxColorArray

export const graphColorNames = () => fauxColorObject
