import React from 'react'
import { Typography, Spin, SwitchView, Statistic, theme, useFela } from '@fortressiq/fiq-ds'

import { detailCSS, titlesCSS, valueCSS } from './styles'

const { Title } = Typography

const ObserverDetail = ({ label, type, value }) => {
  const { css } = useFela()

  return (
    <div className={css(detailCSS)}>
      <SwitchView
        defaultView={<Spin color={theme['legacy-color-gray1']} size={30} />}
        switchTo={
          <Statistic
            isNumericString={type !== ' string'}
            style={{ ...valueCSS(), ...(type === 'date' && { minWidth: '135px' }) }}
            value={value}
          />
        }
        switchView={value !== null}
      />
      <Title level={5} style={titlesCSS} uppercase={true}>
        {label || ' '}
      </Title>
    </div>
  )
}

export default ObserverDetail
