import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Can from 'components/can/Can'

import TankMonitor from './tank/TankMonitor'
import TenantCreator from './tenants/TenantCreator'
import TenantEditor from './tenants/TenantEditor'
import TenantList from './tenants/TenantList'
import UserList from './users'
import DomainList from './domainList'
import ApplicationList from './applicationList'
import Integrations from './integrations/Integrations'

// @todo: move routes to a single config
export const adminRoutes = [
  {
    path: '/admin/sidekiq',
    component: () => {
      window.location.assign('/admin/sidekiq')
      return null
    },
  },
  { path: '/admin/tank', component: TankMonitor },
  { path: '/admin/tenants', component: TenantList },
  { path: '/admin/tenants/new', component: TenantCreator },
  { path: '/admin/users', component: UserList },
  { path: '/admin/application-list', component: ApplicationList },
  { path: '/admin/domain-list', component: DomainList },
  { path: '/admin/integrations', component: Integrations },
  { path: '/admin/tenants/:id/:page?', component: TenantEditor },
  { path: '/admin/:page?', component: TenantEditor },
]

const Admin = () => (
  <Switch>
    {adminRoutes.map(({ component, roles, ...routeProps }) => {
      const Component = component

      return (
        <Route {...routeProps} key={routeProps.path} exact={true}>
          <Can route={routeProps.path}>
            <Component />
          </Can>
        </Route>
      )
    })}
  </Switch>
)

export default Admin
