import React from 'react'
import { Element, theme } from '@fortressiq/fiq-ds'

const GRAPH_TYPE_LABELS = {
  flowgraph: 'Flow',
  path: 'Path',
  default: 'Path',
  butterfly: 'Butterfly',
}

const MustIncludesGroupsModal = ({ graphType, newGraphType }) => {
  return (
    <Element style={{ width: '95%', padding: `${theme['default-spacer-xs']} 0` }}>
      <div>
        This &quot;Must Include&quot; configuration is not compatible with the &quot;
        {GRAPH_TYPE_LABELS[newGraphType]}&quot; Mining Type because it includes an &quot;OR&quot; condition.
      </div>
      <p>If you proceed, the existing &quot;Must Include&quot; configuration will be deleted.</p>
      <p>Do you want to proceed with using a &quot;{GRAPH_TYPE_LABELS[newGraphType]}&quot; Mining Type? </p>
    </Element>
  )
}

export default MustIncludesGroupsModal
