import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Element, Table, useFela, theme } from '@fortressiq/fiq-ds'

import { tableCard, tableWrapperCSS } from './styles'
import { cardTitleStyle } from './constants'

const UserBreakdownTable = ({ loading, userBreakdownData, userBreakdownExpandHash }) => {
  const { css } = useFela()

  const renderDataBreakout = props => {
    return (
      <Element style={{ background: theme['legacy-color-gray'], padding: theme['default-spacer-md'] }}>
        <Element
          style={{ border: `1px solid ${theme['antd-card-border']}`, background: theme.white, overflow: 'hidden' }}
        >
          <Table
            columns={[
              {
                Header: 'Date',
                accessor: 'observed_at_day',
                width: 100,
              },
              {
                Header: 'Credits',
                accessor: 'credit',
                width: 75,
              },
              {
                Header: 'Event Count',
                accessor: 'event_log_count',
              },
            ]}
            data={userBreakdownExpandHash[props.row.original.user]}
            disablePagination={true}
            disableResizing={true}
            verticalBorders={false}
          />
        </Element>
      </Element>
    )
  }

  return (
    <div className={css(tableWrapperCSS)}>
      <Card className={css(tableCard)} headStyle={cardTitleStyle} loading={loading} title='User Breakdown'>
        <Table
          columns={[
            {
              accessor: 'id',
              disableSortBy: true,
              width: 20,
              Cell: ({ value, row: { isExpanded, getToggleRowExpandedProps } }) => (
                <Button
                  size='small'
                  flat={true}
                  {...getToggleRowExpandedProps()}
                  style={{ background: isExpanded ? theme['field-bg-dark'] : 'initial', width: '26px' }}
                >
                  {isExpanded ? '-' : '+'}
                </Button>
              ),
            },
            {
              Header: 'User',
              accessor: 'user',
            },
            {
              Header: 'Credits',
              accessor: 'creditCount',
              width: 90,
            },
            {
              Header: 'Event Count',
              accessor: 'eventCount',
              width: 90,
            },
          ]}
          data={userBreakdownData}
          disablePagination={true}
          disableResizing={true}
          renderRowSubComponent={renderDataBreakout}
        />
      </Card>
    </div>
  )
}

UserBreakdownTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  userBreakdownData: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.string,
      creditCount: PropTypes.number,
      eventCount: PropTypes.number,
    })
  ).isRequired,
  userBreakdownExpandHash: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        observed_at_day: PropTypes.string,
        credits: PropTypes.number,
        event_log_count: PropTypes.number,
      })
    )
  ).isRequired,
}

export default UserBreakdownTable
