import React from 'react'
import { Button } from '@fortressiq/fiq-ds'

const AnnotateButton = ({ onToggleAnnotationTools: toggleAnnotationTools }) => (
  <div className='fiqds-overlay-container fiqds-overlay-dropdown-container clickable'>
    <div className='fiqds-overlay-trigger'>
      <Button icon='pencil' flat={true} toggle={true} onToggle={toggleAnnotationTools}>
        Annotate
      </Button>
    </div>
  </div>
)

export default AnnotateButton
