import React, { Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom'

import uiStore from 'lib/UiStore'
import { useUserState } from 'context/UserContext'

import { Alert } from '@fortressiq/fiq-ds'

const NoAccess = ({ children, location }) => {
  const { name, roles } = useUserState()

  const homeLink = (() => {
    if (roles.includes('virtual_interviewer')) return '/review'
    if (roles.includes('limited')) return '/client-dashboard'
    else return '/'
  })()

  if (location.pathname === '/') {
    return <Redirect to={homeLink} />
  }

  return (
    <Alert
      banner={false}
      closable={false}
      icon='lock'
      message={
        children || (
          <Fragment>
            Sorry {name}, but you don&apos;t have access to the page <code>{location.pathname}</code>. Please{' '}
            <a href={`mailto: ${uiStore.tenant.contactEmail}`}>contact your administrator</a> if you think this is an
            error.<Link to={homeLink}>Return</Link>
          </Fragment>
        )
      }
      showIcon={true}
      style={{ margin: '0 auto', marginTop: '72px', width: 'fit-content' }}
      type='lock'
    />
  )
}

export default NoAccess
