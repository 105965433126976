import React from 'react'
import { helpers, Input } from '@fortressiq/fiq-ds'
import { useGetSet } from 'react-use'
import PropTypes from 'prop-types'

const NewProcessForm = ({ saveNew, removeModal, modalID }) => {
  const [get, setState] = useGetSet('')
  const handleSubmit = () => {
    const value = get()
    if (value !== '') {
      saveNew(value)
      setState('')
      removeModal(modalID)
    }
  }
  return (
    <Input
      autoFocus={true}
      button={true}
      buttonProps={{
        children: 'Add',
        disabled: get() === '',
        onClick: e => {
          e.preventDefault()
          handleSubmit()
        },
        type: 'secondary',
      }}
      onChange={({ target: { value } }) => setState(value)}
      onEnter={() => handleSubmit()}
      placeholder='Process Name'
    />
  )
}

NewProcessForm.propTypes = {
  saveNew: PropTypes.func,
  removeModal: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
}

NewProcessForm.defaultProps = {
  saveNew: helpers.noop,
}

export default NewProcessForm
