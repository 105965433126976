import newTabImage from 'images/newTab.svg'

export const handles = [
  {
    name: 'remove',
    position: 'nw',
    events: { pointerdown: 'removeElement' },
    attrs: {
      '.handle': {
        'data-tooltip-class-name': 'small',
        'data-tooltip': 'Click to remove the object',
        'data-tooltip-position': 'right',
        'data-tooltip-padding': 15,
      },
    },
  },
  {
    name: 'unlink',
    position: 'w',
    events: { pointerdown: 'unlinkElement' },
    attrs: {
      '.handle': {
        'data-tooltip-class-name': 'small',
        'data-tooltip': 'Click to break all connections to other objects',
        'data-tooltip-position': 'right',
        'data-tooltip-padding': 15,
      },
    },
  },
  {
    name: 'link',
    position: 'e',
    events: { pointerdown: 'startLinking', pointermove: 'doLink', pointerup: 'stopLinking' },
    attrs: {
      '.handle': {
        'data-tooltip-class-name': 'small',
        'data-tooltip': 'Click and drag to connect the object',
        'data-tooltip-position': 'left',
        'data-tooltip-padding': 15,
      },
    },
  },
  {
    name: 'rotate',
    position: 'sw',
    events: { pointerdown: 'startRotating', pointermove: 'doRotate', pointerup: 'stopBatch' },
    attrs: {
      '.handle': {
        'data-tooltip-class-name': 'small',
        'data-tooltip': 'Click and drag to rotate the object',
        'data-tooltip-position': 'right',
        'data-tooltip-padding': 15,
      },
    },
  },
  {
    name: 'openInNewTab',
    position: 'ne',
    icon: newTabImage,
    attrs: {
      '.handle': {
        'data-tooltip-class-name': 'small',
        'data-tooltip': 'Click to view subprocess or event group',
        'data-tooltip-position': 'right',
        'data-tooltip-padding': 15,
      },
    },
  },
]

export default handles
