import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import uiStore from 'lib/UiStore'
import {
  useFieldArray,
  useController,
  theme,
  Element,
  Typography,
  Label,
  Button,
  Icon,
  Input,
  Select,
} from '@fortressiq/fiq-ds'

import ControlledSelect from 'components/form/ControlledSelect'
import constructApplicationOptions from 'components/form/helpers/constructApplicationOptions'
import FieldWrapper from 'components/form/FieldWrapper'

import { useUpdateEffect } from 'react-use'
import SignatureForm from './SignatureForm'

import { secondarySignatureLevelLabelMap } from '../../helpers'

const signatureGroupCSS = () => ({
  background: 'hsla(0, 0%, 94%, 0.5)',
  border: `1px solid ${theme['ui-line-default']}`,
  padding: theme['default-spacer-md'],
  margin: `${theme['default-spacer-md']} 0`,
})

const { Text } = Typography

const andOrOptions = [
  { label: 'AND', value: 'and' },
  { label: 'OR', value: 'or' },
]

const getAndOrValue = val => {
  return andOrOptions.find(option => option.value === val)
}

const RecursiveSignaturesGroup = ({
  groupsEnabled,
  // setValue,
  setMustIncludesSignature,
  min,
  name,
  showSecondary,
  signatureLevel,
  title,
  primarySignatureLabel,
  group,
  signatureGroups,
  control,
  addGroup,
  addSignature,
  onChangeSignature,
  removeGroup,
  removeSignature,
  onSelectAndOr,
  nestedPath,
}) => {
  return (
    <Element
      style={{
        ...signatureGroupCSS(),
      }}
    >
      <Element style={{ display: 'flex' }}>
        <Select
          options={andOrOptions}
          defaultValue={andOrOptions[0]}
          isMulti={false}
          name='group_condition'
          placeholder='Select Conditional'
          portal={true}
          style={{
            width: '220px',
            marginRight: theme['default-spacer-sm'],
            marginBottom: theme['default-spacer-md'],
          }}
          onChange={e => onSelectAndOr(e.value, nestedPath)}
          value={group ? getAndOrValue(group.condition) : undefined}
          isDisabled={!groupsEnabled}
        />
        <Button onClick={e => addGroup(e, nestedPath)} disabled={!groupsEnabled}>
          Add Group
        </Button>
        {(group?.signatures?.length > 0 || group?.nested_signatures?.length > 0) && (
          <Button
            onClick={e => removeGroup(e, nestedPath)}
            noBorder={true}
            flat={true}
            shape='circle'
            style={{ background: 'none', top: '3px', left: '3px', position: 'relative' }}
          >
            <Icon clickable={true} icon='remove' size='small' />
          </Button>
        )}
      </Element>

      {group?.signatures?.map((item, index) => (
        <SignatureForm
          key={`signature_${index}`}
          setVsetMustIncludesSignaturealue={setMustIncludesSignature}
          groupsEnabled={groupsEnabled}
          min={min}
          name={name}
          showSecondary={showSecondary}
          signatureLevel={signatureLevel}
          title={title}
          primarySignatureLabel={primarySignatureLabel}
          control={control}
          item={item}
          index={index}
          addGroup={addGroup}
          addSignature={addSignature}
          onChangeSignature={onChangeSignature}
          removeGroup={removeGroup}
          removeSignature={removeSignature}
          onSelectAndOr={onSelectAndOr}
          nestedPath={nestedPath}
        />
      ))}
      <Button
        type='link'
        icon='add'
        onClick={e => addSignature(e, nestedPath)}
        style={{ paddingLeft: 0, marginBottom: theme['default-spacer-md'] }}
      >
        Add {title}
      </Button>
      {group?.nested_signatures?.map((sigGroup, index) => (
        <RecursiveSignaturesGroup
          key={`group_${index}`}
          setMustIncludesSignature={setMustIncludesSignature}
          groupsEnabled={groupsEnabled}
          min={min}
          name={name}
          showSecondary={showSecondary}
          signatureLevel={signatureLevel}
          title={title}
          primarySignatureLabel={primarySignatureLabel}
          group={sigGroup}
          control={control}
          addGroup={addGroup}
          addSignature={addSignature}
          onChangeSignature={onChangeSignature}
          removeGroup={removeGroup}
          removeSignature={removeSignature}
          onSelectAndOr={onSelectAndOr}
          nestedPath={`${nestedPath}${index}`}
        />
      ))}
    </Element>
  )
}
export default RecursiveSignaturesGroup
