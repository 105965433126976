import React, { useState, useEffect, Fragment, useCallback, memo, forwardRef, useRef, useImperativeHandle } from 'react'
import { useQueryParams, withDefault, JsonParam, NumberParam, StringParam } from 'use-query-params'
import PropTypes from 'prop-types'
import { Table, Header, useFela, Button } from '@fortressiq/fiq-ds'
import api from 'lib/Api'
import getFilterOptions from 'lib/getFilterOptions'
import { useLocalStorage } from 'lib/hooks'

import FiltersControls from './FiltersControls/FiltersControls'
import HideColumnControls from './HideColumnControls/HideColumnControls'

import stickyToolbarCSS from './styles/index.js'

export const columnWidths = {
  date: 130,
  actionMenu: 64,
  id: 50,
}

const localStorageKey = '1'

const TrinityTable = forwardRef(
  (
    {
      columns,
      constructData,
      controlledTable,
      dataKey,
      defaultSortKey,
      disableHideColumns,
      disablePagination,
      disablePersistedState,
      disableSortBy,
      getTableData,
      globalFilterOptions,
      isDependencyLoading,
      manualGlobalFilter,
      onLoaded,
      params,
      reFetch,
      renderRowSubComponent,
      sortKeyMap,
      tableId,
      toolbarActions,
      url,
    },
    ref
  ) => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [pageCount, setPageCount] = useState(0)
    const [total, setTotal] = useState(0)

    const tableRef = useRef(null)

    const { css } = useFela()

    const [persistedColumnWidths, setPersistedColumnWidths] = useLocalStorage(
      `${tableId}-column-widths-${localStorageKey}`,
      {}
    )
    const [persistedHiddenColumns, setPersistedHiddenColumns] = useLocalStorage(
      `${tableId}-hidden-columns-${localStorageKey}`,
      []
    )

    const [persistedState, setPersistedState] = useQueryParams(
      disablePersistedState
        ? {}
        : {
            pageSize: withDefault(NumberParam, 10),
            pageIndex: withDefault(NumberParam, 0),
            sortKey: StringParam,
            sortOrder: StringParam,
          }
    )

    const [controlledState, setControlledState] = useQueryParams(
      disablePersistedState
        ? {}
        : {
            globalFilter: JsonParam,
          }
    )

    const initialState = disablePersistedState
      ? undefined
      : {
          ...persistedState,
          ...(persistedState.sortKey
            ? {
                sortBy: [
                  {
                    id: persistedState.sortKey,
                    desc: persistedState.sortOrder === 'desc',
                  },
                ],
              }
            : {}),
          columnResizing: {
            columnWidths: persistedColumnWidths,
          },
        }

    const handleFilterChange = filters => {
      tableRef.current.gotoPage(0)
      setControlledState({ ...controlledState, globalFilter: { ...filters } })
    }

    useEffect(() => {
      async function fetchJobs() {
        setIsLoading(true)
        const fetchParams = {
          filters: { ...controlledState?.globalFilter },
          ...params,
        }
        const { data: newData } = await api.get(url, fetchParams)
        setData(dataKey ? newData[dataKey] : newData)
        setIsLoading(false)
      }
      if (!controlledTable) {
        fetchJobs()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, dataKey, controlledTable, controlledState.globalFilter])

    useEffect(() => {
      if (!isLoading && onLoaded) onLoaded()
    }, [isLoading, onLoaded])

    const fetchData = useCallback(
      ({ pageSize, pageIndex, sortBy, filters = {}, globalFilter }) => {
        if (!disablePersistedState) {
          const newState = {
            pageSize: pageSize,
            pageIndex: pageIndex,
            ...(sortBy[0]
              ? { sortKey: sortBy[0].id, sortOrder: sortBy[0]?.desc ? 'desc' : 'asc' }
              : { sortKey: undefined, sortOrder: undefined }),
          }
          if (JSON.stringify(persistedState) !== JSON.stringify(newState)) {
            setPersistedState(newState)
          }
        }
        const fetchingData = async () => {
          setIsLoading(true)
          const sortKey = sortBy[0]?.id ? sortBy[0].id : defaultSortKey
          const convertedSortKey = sortKeyMap ? sortKeyMap[sortKey] || sortKey : sortKey

          const sortOrder = !sortBy[0] || sortBy[0]?.desc ? 'desc' : 'asc'
          const order = Array.isArray(convertedSortKey)
            ? convertedSortKey.join(` ${sortOrder}, `).concat(` ${sortOrder}`)
            : `${convertedSortKey} ${sortOrder}`

          const fetchParams = {
            per_page: pageSize,
            page: pageIndex + 1,
            order,
            filters: { ...filters, ...globalFilter },
            ...params,
          }
          const { data: newData } = await api.get(url, fetchParams)
          let dataArray = dataKey ? newData[dataKey] : newData

          // If filtering by email, gotta do it frontend:
          if (fetchParams?.filters?.email) {
            dataArray = dataArray
              .map(d => {
                const multipleIDs = fetchParams?.filters?.email?.length > 1

                if (multipleIDs) {
                  return fetchParams?.filters?.email.includes(d?.id) ? d : undefined
                } else {
                  return fetchParams?.filters?.email === d?.id ? d : undefined
                }
              })
              .filter(Boolean)
          }

          setData(constructData ? constructData(dataArray) : dataArray)
          setTotal(newData.totalCount ? newData.totalCount : newData.length)
          setPageCount(Math.ceil(newData.totalCount / pageSize))
          setIsLoading(false)
          if (getTableData) getTableData(newData)
        }
        if (controlledTable && !isDependencyLoading) {
          fetchingData()
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        constructData,
        controlledTable,
        dataKey,
        defaultSortKey,
        disablePersistedState,
        isDependencyLoading,
        JSON.stringify(params),
        persistedState,
        setPersistedState,
        sortKeyMap,
        url,
      ]
    )

    const handlePersistedResize = useCallback(
      columnResizing => {
        setPersistedColumnWidths(columnResizing.columnWidths)
      },
      [setPersistedColumnWidths]
    )

    const resetResizing = () => {
      setPersistedColumnWidths([])
      tableRef.current.resetResizing()
    }

    const updateCellData = (rowIndex, columnId, value) => {
      setData(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            }
          }
          return row
        })
      )
    }

    const updateRowData = (searchKey, searchValue, newValues) => {
      const rowIndex = data.findIndex(i => i[searchKey] === searchValue)
      setData(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              ...newValues,
            }
          }
          return row
        })
      )
    }

    useImperativeHandle(ref, () => {
      return {
        updateRowData: updateRowData,
      }
    })

    const toolbarActionsHeader = []
    if (!disablePersistedState) {
      toolbarActionsHeader.push(
        <Button
          disabled={Object.keys(persistedColumnWidths).length === 0}
          key='reset-column-width'
          onClick={resetResizing}
        >
          Reset Resizing
        </Button>
      )
    }

    if (!disableHideColumns) {
      toolbarActionsHeader.push(
        <HideColumnControls
          key='table-columns'
          columns={columns}
          persistedHiddenColumns={persistedHiddenColumns}
          setPersistedHiddenColumns={setPersistedHiddenColumns}
          resetResizing={resetResizing}
        />
      )
    }

    if (globalFilterOptions) {
      toolbarActionsHeader.push(
        <FiltersControls
          key='table-filter'
          controlledState={controlledState}
          handleFilterChange={handleFilterChange}
          globalFilterOptions={getFilterOptions(globalFilterOptions)}
        />
      )
    }

    return (
      <Fragment>
        {(!disableHideColumns || !!globalFilterOptions || !!toolbarActions) && (
          <Header
            className={css(stickyToolbarCSS)}
            toolbarActions={[...toolbarActionsHeader, ...(toolbarActions || [])]}
          />
        )}
        <Table
          columns={columns}
          controlledState={
            disablePersistedState ? undefined : { ...controlledState, hiddenColumns: persistedHiddenColumns }
          }
          manualGlobalFilter={manualGlobalFilter}
          controlledTable={controlledTable}
          data={data}
          disablePagination={disablePagination}
          disableSortBy={disableSortBy}
          fetchData={fetchData}
          handlePersistedResize={disablePersistedState ? undefined : handlePersistedResize}
          initialState={disablePersistedState ? undefined : initialState}
          loading={isLoading}
          pageCount={pageCount}
          ref={tableRef}
          reFetch={reFetch} // Triggers refetch if changed from parent, example when deleting a row
          renderRowSubComponent={renderRowSubComponent}
          total={total}
          updateCellData={updateCellData}
        />
      </Fragment>
    )
  }
)

TrinityTable.defaultProps = {
  controlledTable: true,
  dataKey: undefined,
  defaultSortKey: 'id',
  disableHideColumns: false,
  disablePersistedState: false,
  disableSortBy: false,
  globalFilterOptions: undefined,
  isDependencyLoading: false,
  manualGlobalFilter: false,
  params: undefined,
  renderRowSubComponent: undefined,
  sortKeyMap: undefined,
}

TrinityTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  controlledTable: PropTypes.bool,
  dataKey: PropTypes.string,
  defaultSortKey: PropTypes.string,
  disableHideColumns: PropTypes.bool,
  disablePersistedState: PropTypes.bool,
  disableSortBy: PropTypes.bool,
  globalFilterOptions: PropTypes.arrayOf(PropTypes.string),
  isDependencyLoading: PropTypes.bool,
  manualGlobalFilter: PropTypes.bool,
  params: PropTypes.shape({}),
  renderRowSubComponent: PropTypes.func,
  sortKeyMap: PropTypes.shape({}),
  tableId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default memo(TrinityTable)
