import { observable, action, computed } from 'mobx'

export default class BatchStore {
  @observable startedAt = null

  @observable endedAt = null

  @observable failed = 0

  @observable succeeded = 0

  @observable total = 0

  @observable observers = 0

  constructor(id) {
    this.id = id
  }

  @action
  update(event, data) {
    switch (event) {
      case 'aggregator-started':
        this.startedAt = data.timestamp
        break
      case 'aggregator-processing':
        this.total = data.total
        break
      case 'aggregator-finished':
        this.endedAt = data.timestamp
        break
      case 'logs-processed':
        this.failed += data.failed
        this.succeeded += data.processed
        break
      case 'observer-updated':
        this.observers += 1
        break
      default:
        window.console.warn(`TankMonitor: Unknown event type: ${event}`)
    }
  }

  @computed get completed() {
    return this.endedAt !== null
  }

  @computed get progress() {
    if (this.total === 0) {
      return 100
    } else {
      return Math.floor(((this.failed + this.succeeded) / this.total) * 100)
    }
  }
}
