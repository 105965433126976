import React from 'react'
import { observer } from 'mobx-react'
import { Select, theme } from '@fortressiq/fiq-ds'
import { useList, useMap, useUpdateEffect } from 'react-use'

import { useHistory } from 'react-router-dom'

import { useFetch } from 'lib/hooks'

const ProcessSelect = ({ graphProcessId }) => {
  const { data: allProcesses = [], isLoading } = useFetch('/process_explorer')
  const [defaultValue, { setAll }] = useMap({})
  const [selectOptions, { push }] = useList([])

  const history = useHistory()
  const onChange = ({ label, value }) => {
    history.push(`/process-explorer/${value}`)
    setAll({ label, value })
  }
  const selectProps = {
    onChange: onChange,
    placeholder: 'Select a Process',
    style: {
      width: '60%',
    },
    styles: { menu: { zIndex: theme['z-modal-below'] } },
  }

  useUpdateEffect(() => {
    if (!isLoading) {
      allProcesses.map(process => {
        const thisProcess = { label: process.name, value: Number(process.id) }
        if (thisProcess.value === Number(graphProcessId)) {
          setAll(thisProcess)
        }

        return push(thisProcess)
      })
    }
  }, [allProcesses, isLoading])

  return <Select {...selectProps} value={defaultValue} isLoading={isLoading} options={selectOptions} />
}

export default observer(ProcessSelect)
