import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Overlay, useFela, Checkbox } from '@fortressiq/fiq-ds'

import { wrapperCSS, itemCSS } from './styles/index.js'

const HideColumnControls = ({ columns, persistedHiddenColumns, setPersistedHiddenColumns, resetResizing }) => {
  const [showing, setShowing] = useState(false)

  const { css } = useFela()

  const add = accessor => {
    resetResizing()
    setPersistedHiddenColumns([...persistedHiddenColumns, accessor])
  }
  const remove = accessor => {
    resetResizing()
    setPersistedHiddenColumns([...persistedHiddenColumns.filter(item => item !== accessor)])
  }

  const placement = {
    overflow: { adjustX: true, adjustY: true },
    points: ['br', 'tr'],
    targetOffset: [0, 4],
    useCssTransform: false,
  }

  const trigger = {
    showing,
    setShowing,
    text: `Columns (${persistedHiddenColumns.length})`,
    type: 'default',
  }

  const heights = {
    min: 'auto',
  }
  return (
    <Overlay
      key='table-hide-show-overlay'
      config={{ disableOutsideClick: true }}
      closeIcon={true}
      basic={true}
      heights={heights}
      id='table-hide-show-overlay'
      placement={placement}
      trigger={trigger}
      draggable={true}
      content={
        <div className={css(wrapperCSS)}>
          {columns.map(column => {
            const checked = !persistedHiddenColumns?.includes(column.accessor)
            return (
              <div key={column.accessor} className={css(itemCSS)}>
                <Checkbox
                  id={`checkbox-${column.accessor}`}
                  onCheck={() => (checked ? add(column.accessor) : remove(column.accessor))}
                  checked={checked}
                >
                  {column.Header}
                </Checkbox>
              </div>
            )
          })}
        </div>
      }
    />
  )
}

HideColumnControls.defaultProps = {
  controlledState: undefined,
}

HideColumnControls.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      Header: PropTypes.string,
    })
  ).isRequired,
  controlledState: PropTypes.shape({
    hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  }),
  setPersistedHiddenColumns: PropTypes.func.isRequired,
}

export default HideColumnControls
