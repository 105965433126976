// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ObserverSelect component should render without throwing an error 1`] = `
<Memo(ForwardRef)
  menuPortalTarget={<body />}
  onChange={[Function]}
  options={
    [
      {
        "label": "name1",
        "type": "observer",
        "value": 1,
      },
      {
        "label": "name2",
        "type": "observer",
        "value": 2,
      },
    ]
  }
  placeholder="Select an Observer"
  style={
    {
      "maxWidth": "100%",
      "width": "250px",
    }
  }
  value={
    {
      "label": "name1",
      "type": "observer",
      "value": 1,
    }
  }
/>
`;
