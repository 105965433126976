import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Heading, Icon, useNotification } from '@fortressiq/fiq-ds'
import shortid from 'shortid'

import Draggable from 'react-draggable'
import api from 'lib/Api'

import { FLASH_LEVELS } from 'components/flash/Flash'

import ButterflyProcessStore from '../stores/ButterflyProcessStore'

import css from './createProcessDialogueStyle.scss'

import CreateProcessSection from './CreateProcessSection'

const CreateProcessDialogue = () => {
  const { addNotification } = useNotification()

  const [name, setName] = useState('')
  const [savedProcessId, setSavedProcessId] = useState(null)

  useEffect(() => {
    ButterflyProcessStore.clear()
  }, [])

  const closeCreateProcess = () => {
    ButterflyProcessStore.defineProcessMode = false
    ButterflyProcessStore.clear()
  }

  const setNameFromInput = e => {
    setName(e.target.value)
  }

  const saveProcess = async () => {
    const params = {
      name,
      pathSignatures: ButterflyProcessStore.flatPathsInformation,
    }
    const { data } = await api.post('/graph_processes', params)
    setSavedProcessId(data.graphProcess.id)
    ButterflyProcessStore.clear()

    addNotification({
      cta: (
        <Button href={`/process-explorer/${data.graphProcess.id}`} type='link'>
          Visit Subprocess
        </Button>
      ),
      description: 'Subprocess created successfully!',
      key: shortid.generate(),
      type: FLASH_LEVELS.SUCCESS,
    })
  }

  return ButterflyProcessStore.defineProcessMode ? (
    <CreateProcessDialogueOpened
      name={name}
      savedProcessId={savedProcessId}
      setName={setNameFromInput}
      closeCreateProcess={closeCreateProcess}
      saveProcess={saveProcess}
    />
  ) : null
}

const CreateProcessDialogueOpened = ({ closeCreateProcess, name, saveProcess, setName }) => (
  <Draggable>
    <div className={`${css.container}`}>
      <TopSection closeCreateProcess={closeCreateProcess} />
      <CreateProcessSection name={name} setName={setName} saveProcess={saveProcess} />
    </div>
  </Draggable>
)

const TopSection = ({ closeCreateProcess }) => (
  <div className={css.topsection}>
    <div className={css.leave} onClick={closeCreateProcess} role='button' tabIndex={-1}>
      <Icon icon='remove' />
    </div>
    <Heading level={2} className={css.title}>
      Create a Process Model
    </Heading>
  </div>
)

export default observer(CreateProcessDialogue)
