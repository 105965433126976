import { CLEAR_UNCLAIMED, FETCH_UNCLAIMED, TOGGLE_GROUP_TYPE } from './Actions'

import { RULE_TYPES } from '../Constants'

export const unclaimedInit = {
  unclaimedEvents: null,
  groupType: RULE_TYPES.title,
}

const reducer = (state, action) => {
  const { payload } = action
  switch (action.type) {
    case CLEAR_UNCLAIMED: {
      if (state.unclaimedEvents !== null)
        return {
          ...state,
          unclaimedEvents: null,
        }
      else {
        return state
      }
    }
    case FETCH_UNCLAIMED: {
      return {
        ...state,
        unclaimedEvents: payload.unclaimedEvents,
      }
    }
    case TOGGLE_GROUP_TYPE:
      return { ...state, groupType: payload }
    default:
      throw new Error()
  }
}

export default reducer
