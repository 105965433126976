import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import SparkChart from 'components/cards/public/SparkChart'
import { useFela } from '@fortressiq/fiq-ds'

import { sparkTextCSS } from '../styles/index.js'

const MiniSparkByte = ({ data, id, total }) => {
  const { css } = useFela()

  return (
    <Fragment>
      <div className={css(sparkTextCSS)}>{total}</div>
      <SparkChart id={id} width={200} height={50} data={data} />
    </Fragment>
  )
}

MiniSparkByte.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      total: PropTypes.number,
    })
  ).isRequired,
  total: PropTypes.string.isRequired,
}
export default MiniSparkByte
