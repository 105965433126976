import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ApplicationIcon,
  Button,
  Checkbox,
  Element,
  Empty,
  Group,
  Heading,
  Icon,
  Tooltip,
  Typography,
  useFela,
  useModal,
  useNotification,
} from '@fortressiq/fiq-ds'

import ScreenList from 'app/screens/list/ScreenList'
import { ellipsisCSS } from 'styles/utils'

import api from 'lib/Api'
import { useLocalStorage } from 'lib/hooks'

import AsyncSelect from 'components/form/AsyncSelect'
import Provisional from './provisional'
import History from './strategy/History'
import Promoted from './strategy/Promoted'

import { useHeaderDispatch } from '../../header/HeaderContext'

import {
  applicationTitleBoxCSS,
  applicationTitleDescriptionCSS,
  applicationNameTitleCSS,
  screenListContainerCSS,
  sidebarItemCSS,
  appHeadingCSS,
} from './styles'

import { CHAIN_STRATEGY } from './utilities'

const { Paragraph, Title } = Typography

const title = 'Application Strategy'

export default ({
  match: {
    params: { appId, cycleId },
  },
}) => {
  const { css, theme } = useFela()

  const headerDispatch = useHeaderDispatch()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [application, setApplication] = useState(null)
  const [jobId, setJobId] = useState(null)
  const [lastPromoted, setLastPromoted] = useState(null)
  const [signatureServiceJobsList, setSignatureServiceJobsList] = useState(null)
  const [modalIsOpen, setModalOpen] = useState(false)
  const [provisionalStrategyParams, setProvisionalStrategyParams] = useState()
  const { addNotification } = useNotification()
  const { addModal, removeModal } = useModal()
  const [applicationId, setApplicationId] = useState(appId)

  const [persistedCycleId, setPersistedCycleId] = useLocalStorage(`persisted-cycle-${appId}`, cycleId, true)

  let { current: mergeSignatures } = useRef()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const { data: applicationData } = await api.get(`/applications/${appId}`, { applicationUuid: appId })
      const id = applicationData?.application?.applicationId || appId
      setApplicationId(applicationData.application.id)
      if (cycleId) {
        setPersistedCycleId(cycleId)
        const { data } = await api.get('/signature_service_jobs', {
          applicationId: id,
          cycle_id: cycleId,
          per_page: 100,
        })

        const current = data?.signatureServiceJobs.find(
          job => job?.application?.id?.toString() === appId && job?.cycleId?.toString() === cycleId
        )
        setLastPromoted(
          data?.signatureServiceJobs.find(
            job =>
              job.jobType === 'promotion' &&
              job?.application?.id?.toString() === appId &&
              job?.cycleId?.toString() === cycleId
          )
        )
        setSignatureServiceJobsList(data?.signatureServiceJobs)

        setJobId(current?.id)

        mergeSignatures = current?.params?.merge_signatures

        const { strategySlug = undefined, params = {} } = current || {}
        formatAndSetParams(strategySlug, typeof params === 'string' ? JSON.parse(params) : params)
      }
      setApplication(applicationData?.application)
      setIsLoading(false)
    }

    if (!cycleId && persistedCycleId) {
      history.replace(`/applications/${applicationId}/${persistedCycleId}`)
    } else {
      fetchData()
    }

    headerDispatch({
      type: 'set',
      title: title,
      heading: title,
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [appId, headerDispatch, cycleId])

  const formatAndSetParams = (strategy, params) => {
    let customParams = { params: { 0: {} } }
    if (strategy !== CHAIN_STRATEGY) {
      const { start_date: startDate, end_date: endDate, limit, ...rest } = params
      customParams = {
        start_date: startDate,
        end_date: endDate,
        limit: limit,
        params: {
          0: {
            apply_to: 'all',
            strategy: {
              name: strategy,
              params: { ...rest },
            },
          },
        },
      }
    } else if (params && Object.keys(params)?.length > 0) {
      customParams = params
    }
    setProvisionalStrategyParams(customParams)
  }

  const onMergeSignatureChange = e => {
    mergeSignatures = e
  }

  const modalID = 'promote-signatures-modal'
  const promoteSignatures = async () => {
    setModalOpen(false)
    removeModal(modalID)
    addNotification({
      description: 'Signature promotion is currently processing.',
      message: 'In Progress',
      type: 'info',
    })
    await api.post(`/signature_service_jobs/${jobId}/application/${applicationId}/cycle/${cycleId}/promotion`, {
      merge_signatures: mergeSignatures,
    })

    history.push('/signature-service-jobs')
  }
  const handlePromoteClick = () => {
    setModalOpen(true)

    return addModal({
      afterClose: () => setModalOpen(false),
      bodyStyle: { padding: '0' },
      children: (
        <Fragment>
          <Paragraph align='center' style={{ margin: theme['default-spacer-sm'] }}>
            Are you sure you want to update screen signatures for all events&nbsp;from&nbsp;
            <strong>{application?.displayName}</strong>?
          </Paragraph>
          <Group
            align='center'
            noEndGutter={true}
            justify='end'
            style={{
              borderTop: `1px solid ${theme['dividing-line']}`,
              padding: theme['default-spacer-sm'],
            }}
          >
            <Checkbox
              checked={mergeSignatures}
              name='merge_signatures'
              onCheck={onMergeSignatureChange}
              style={{ marginLeft: 'auto' }}
              value='1'
            >
              Merge Signatures
            </Checkbox>
            <Button onClick={promoteSignatures} type='primary'>
              Promote
            </Button>
          </Group>
        </Fragment>
      ),
      footer: false,
      header: `Promote ${application?.displayName}?`,
      id: modalID,
      style: { height: 'auto', width: '400px' },
    })
  }

  return (
    application && (
      <Element style={{ display: 'flex', minHeight: '100%' }}>
        <Element style={{ background: theme.white, width: '33.33%', minWidth: '300px' }}>
          <div className={css(applicationTitleBoxCSS)}>
            <ApplicationIcon src={application?.iconUrl} name={application?.displayName} size={39} />
            <div className={css(applicationTitleDescriptionCSS)}>
              <Tooltip mouseEnterDelay={500} placement='top-start' title={application?.displayName}>
                <Title ellipsis={true} style={applicationNameTitleCSS}>
                  {application?.displayName}
                </Title>
              </Tooltip>
              {!!application?.winProcessName && (
                <Tooltip
                  config={{ triggerOffset: 12 }}
                  mouseEnterDelay={500}
                  placement='right-start'
                  title={`Windows Process Name: ${application?.winProcessName}`}
                >
                  <small className={css({ ...ellipsisCSS })}>Windows Process Name: {application?.winProcessName}</small>
                </Tooltip>
              )}
            </div>
          </div>
          <Element style={sidebarItemCSS}>
            <Heading style={appHeadingCSS} level={4}>
              Cycle
            </Heading>
            <AsyncSelect
              url='/cycles/filters'
              placeholder='Select Cycle'
              onChange={({
                target: {
                  value: { value },
                },
              }) => history.push(`/applications/${appId}/${value}`)}
              name='cycle'
              style={{ width: '200px' }}
              isMulti={false}
              value={cycleId}
            />
          </Element>
          {!cycleId && (
            <Empty
              style={{ paddingTop: theme['default-spacer-lg'] }}
              customImage={<Icon icon='alerttriangle' size='medium' />}
              description='No cycle selected.'
            />
          )}
          {cycleId && !isLoading && (
            <Fragment>
              <Group
                align='flex-start'
                noOfCols={2}
                colStyles={{ flexDirection: 'column' }}
                justify='space-between'
                noEndGutter={true}
                style={sidebarItemCSS}
                type='flex'
              >
                <Promoted strategySlug={lastPromoted?.strategySlug} />
                <History
                  appId={applicationId}
                  cycleId={cycleId}
                  formatAndSetParams={formatAndSetParams}
                  handlePromoteClick={handlePromoteClick}
                  modalIsOpen={modalIsOpen}
                  signatureServiceJobsList={signatureServiceJobsList}
                />
              </Group>
              <Provisional
                appId={applicationId}
                currentProvisionalStrategyParams={provisionalStrategyParams}
                cycleId={cycleId}
                handlePromoteClick={handlePromoteClick}
                isLoading={isLoading}
                mergeSignaturesDefault={mergeSignatures}
              />
            </Fragment>
          )}
        </Element>

        <Element className={css(screenListContainerCSS)} style={{ flex: 1 }}>
          <ScreenList appId={appId} cycleId={cycleId} />
        </Element>
      </Element>
    )
  )
}
