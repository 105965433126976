export const LIST_TYPES = {
  NONE: { val: 'none', text: 'None' },
  ALLOW: { val: 'allow', text: 'Allow' },
  BLOCK: { val: 'block', text: 'Block' },
}

export const LIST_CATEGORIES = {
  APPLICATION: 'application',
  URL: 'url',
}
