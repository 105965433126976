export const wrapperCSS = ({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '400px',
  padding: theme['default-spacer-sm'],
})

export const itemCSS = ({ theme }) => ({
  width: '50%',
  padding: theme['default-spacer-sm'],
})
