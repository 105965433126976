import React from 'react'
import { withRouter } from 'react-router-dom'

import { Button, Card, ApplicationIcon, get, Group, Typography, Row, Col, theme, useFela } from '@fortressiq/fiq-ds'
import Details from 'app/client_dashboard/Details'
import localizer from 'lib/Localizer'
import Tags from 'components/tags/Tags'

import store from '../../ClientDashboardStore'

import { content, nameContainer, plusApp, title } from './styles'

const { Text } = Typography

const ApplicationIcons = ({ applications }) => {
  const [pre, post] = [applications.slice(0, 2), applications.slice(2, applications.length)]
  const { css } = useFela()

  return (
    <Group gutter={get.numericValues('spacer').xs}>
      {!!pre.length &&
        pre.map((app, index) => <ApplicationIcon key={`app-icon-${index}`} name={app.display_name} size={32} />)}
      {!!post.length && <span className={css(plusApp)}>+ {post.length}</span>}
    </Group>
  )
}

const DashboardCard = ({
  id,
  cardType,
  name,
  updatedAt,
  updatedBy,
  applications,
  history,
  tags,
  deactivateDeletedTag,
}) => {
  const { css } = useFela()

  return (
    <Card
      actions={[
        <Button
          key='card-action'
          onClick={() => {
            store.currentCard = null
            history.push(`/client-dashboard/${id}`)
          }}
          size='small'
        >
          View Details
        </Button>,
      ]}
      actionsStyle={{
        column: {
          justifyContent: 'flex-end',
        },
      }}
      style={{
        minHeight: '200px',
        width: '320px',
      }}
    >
      <div className={css(content)}>
        <span className={css(title)}>{cardType}</span>
        <div className={css(nameContainer)}>{name}</div>
        <Details details={[updatedAt && `Last Updated ${localizer.asShortestDateTime(updatedAt)}`]} />
        <Details details={[updatedBy && `By ${updatedBy}`]} />
        <Row
          type='flex'
          style={{ flexFlow: 'row', marginTop: theme['default-spacer-sm'], minHeight: '22px' }}
          align='flex-start'
        >
          <Col>
            <Text>Tags:</Text>
          </Col>
          <Col>
            <Tags
              key={`tags-${id}`}
              taggableType='dashboardCard'
              taggableId={id}
              tags={tags}
              onTagsUpdate={async e => {
                await store.fetchCards()
                await store.fetchTags()
                if (!e) {
                  deactivateDeletedTag()
                }
              }}
            />
          </Col>
        </Row>
        <Group gutter={3} style={{ margin: `${theme['default-spacer-sm']} 0` }}>
          <ApplicationIcons applications={applications || []} />
        </Group>
      </div>
    </Card>
  )
}

DashboardCard.defaultProps = {
  applications: [],
  cardType: 'Process',
}

export default withRouter(DashboardCard)
