import React, { Fragment, useState } from 'react'
import { useGetSet } from 'react-use'

import { Button, ButtonGroup, Element, Input, Label, Select, theme } from '@fortressiq/fiq-ds'
import { cardTypes } from './constants'
import store from '../ClientDashboardStore'

const AddProcessModal = ({ removeModal, modalID }) => {
  const [isSaving, setSaveState] = useState(false)
  const [getIsvalidForm, setIsValidForm] = useGetSet(false)

  const selectOptions = cardTypes.map(type => ({ value: type, label: type }))

  const defaultState = { type: cardTypes[0], name: '' }

  const [get, set] = useGetSet(defaultState)

  const handleCancel = () => {
    set(defaultState)
    removeModal(modalID)
  }

  const handleOk = () => {
    if (getIsvalidForm()) {
      const newData = get()
      setSaveState(true)

      store
        .addDashboardCard(newData)
        .then(set(defaultState))
        .finally(() => setSaveState(false))
      removeModal(modalID)
    }
  }

  const checkValidForm = () => {
    const form = get()
    if (form.type && form.name) {
      setIsValidForm(true)
      return
    }
    setIsValidForm(false)
  }

  return (
    <Fragment>
      <Label htmlFor='type'>Card Type</Label>
      <Select
        defaultValue={selectOptions[0]}
        name='type'
        onChange={({ value }) => {
          set({ ...get(), type: value })
          checkValidForm()
        }}
        options={selectOptions}
        placeholder='Select a Card Type...'
        style={{ marginBottom: theme['default-spacer-sm'] }}
      />
      <Input
        autoFocus={true}
        label='Card Name'
        name='name'
        onChange={({ target: { value } }) => {
          set({ ...get(), name: value })
          checkValidForm()
        }}
        onEnter={handleOk}
      />
      <Element as='span' style={{ marginBottom: theme['default-spacer-xs'], marginTop: theme['default-spacer-lg'] }}>
        <ButtonGroup>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type='secondary' loading={isSaving} onClick={handleOk} disabled={!getIsvalidForm()}>
            Add Process
          </Button>
        </ButtonGroup>
      </Element>
    </Fragment>
  )
}

export default AddProcessModal
