// TODO: Move this to a modal.
import React from 'react'
import { Button, Input, TextArea } from '@fortressiq/fiq-ds'

import css from './editDecisionFormStyle.scss'

/******************
 * Inspector type: edit decision
 * pretty much just the UI for editing a decision in inspector
 *
 ******************/
const EditDecisionForm = ({ onSave, onChange, name, description, rules, store, style }) => (
  <div className={css.container} style={style}>
    <div className={`${css.flex} ${css.section}`}>
      <div className={css.graphlabel}>{store.viewingNode.labelNum || '?'}</div>
      <label htmlFor='decisionLabel'>Decision Point Label</label>
    </div>
    <Input
      className={css.inputFull}
      id='decisionLabel'
      onChange={e => onChange('name', e.target.value)}
      placeholder='Custom Decision label'
      value={name}
    />

    <TextArea
      onChange={e => onChange('description', e.target.value)}
      placeholder='Optional Description'
      value={description}
    />
    <SaveButton onSave={onSave} />

    <div className={`${css.pathsTitle} ${css.section}`}>Paths:</div>

    {rules &&
      rules.length > 0 &&
      rules.map((item, i) => (
        <div key={i} style={{ marginBottom: '30px' }}>
          <div className={css.flex}>
            <div className={css.graphlabel}>{item.id}</div>
            <label htmlFor={`path${i}`}>label</label>
            <Input
              id={`path${i}`}
              placeholder='Custom Path Label'
              value={item.ruleName}
              onChange={e => onChange('ruleName', e.target.value, i)}
            />
          </div>
          <TextArea
            placeholder='Optional Description'
            value={item.ruleDescription}
            onChange={e => onChange('ruleDescription', e.target.value, i)}
          />
          <SaveButton onSave={onSave} />
        </div>
      ))}
  </div>
)

const SaveButton = ({ onSave }) => (
  <div className={css.saveContainer}>
    <Button className={css.save} onClick={onSave}>
      Save
    </Button>
  </div>
)

export default EditDecisionForm
