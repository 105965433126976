import React, { useEffect, useReducer, memo, useState } from 'react'
import {
  AdvancedCell,
  Button,
  CopyToClipboardButton,
  DateTimePickerRange,
  Element,
  Group,
  Input,
  Overlay,
  Table,
  theme,
} from '@fortressiq/fiq-ds'
import { sub } from 'date-fns'

import { DS_DATE_FORMAT_MEDIUM } from 'constants/app-constants'

import api from 'lib/Api'
import PanelMetrics from 'components/PanelMetrics/PanelMetrics'
import { RULE_TYPES } from './Constants'
import PanelTitle from './components/PanelTitle'
import UnclaimedExploreLink from './components/tableButtons/UnclaimedExploreLink'

import reducer, { unclaimedInit } from './reducers/UnclaimedEventsReducer'
import { CLEAR_UNCLAIMED, FETCH_UNCLAIMED, TOGGLE_GROUP_TYPE } from './reducers/Actions'

import { tableContainerConfig } from './styles/index'

const groupTypeTitleMap = {
  title: 'Window Title',
  url: 'URL',
}

const isValidRegExp = val => {
  let isValid = false
  try {
    isValid = new RegExp(val)
  } catch (e) {
    isValid = false
  }
  return !!isValid
}

const Search = ({ onFilter, onClear, groupType }) => {
  const [showing, setShowing] = useState(false)
  const [val, setVal] = useState('')
  const groupTypeLower = groupType.toLowerCase()
  const handleClear = () => {
    onClear()
  }
  const handleKeyDown = ({ which }) => which === 13
  const handleChange = ({ target: { value } }) => {
    if (isValidRegExp(value)) {
      onFilter(value, groupTypeLower)
    }
    setVal(value)
  }

  const disabled = !isValidRegExp(val)
  return (
    <Overlay
      content={
        <Group>
          <Input
            placeholder={`Filter ${groupType} Groups`}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            allowClear={true}
            onClear={handleClear}
          />
          <CopyToClipboardButton
            textToCopy={val}
            buttonProps={{ disabled, validation: [[!disabled, `"${val}" is an invalid pattern`]] }}
          />
        </Group>
      }
      placement={{
        overflow: { adjustX: true, adjustY: true },
        points: ['br', 'tr'],
        targetOffset: [0, 4],
      }}
      draggable={true}
      id='browser-events-search-dropdown'
      trigger={{
        showing: showing,
        setShowing: setShowing,
        node: <Button icon='search' />,
      }}
    />
  )
}

const getColumns = ({ groupType }) => {
  const groupTypeLower = groupType.toLowerCase()
  return [
    {
      Header: groupTypeTitleMap[groupTypeLower],
      accessor: groupTypeLower,
      width: 400,
      Cell: ({ value }) => <AdvancedCell>{value || '-'}</AdvancedCell>,
    },
    {
      Header: 'Events',
      width: 100,
      minWidth: 100,
      align: 'right',
      accessor: 'count',
      Cell: ({
        row: {
          original: { title, url, count },
        },
      }) => <UnclaimedExploreLink groupType={groupType} title={title} url={url} count={count} />,
    },
  ]
}

const defaultDate = { from: sub(new Date(), { months: 3 }), to: new Date() }

const formatFilter = (isoFromDate, isoToDate) => ({
  rules: [
    {
      id: 'g-9KKC3tL611oS-j3r-ZXsr',
      rules: [
        {
          id: 'r-3G2uexORegtga5lyrnE3s',
          field: 'observed_at',
          value: isoFromDate,
          operator: '>=',
        },
        {
          id: 'r-VlZPRj1hdBzaXCpV0W92S',
          field: 'observed_at',
          value: isoToDate,
          operator: '<=',
        },
      ],
      combinator: 'and',
      not: 'false',
    },
  ],
  combinator: 'and',
})

const TitleGroupList = ({ updateClaimedFlag }) => {
  const [allUnclaimedEvents, setAllUnclaimedEvents] = useState([])
  const [dateRange, setDateRange] = useState(defaultDate)
  const [filter, setFilter] = useState(formatFilter(defaultDate.from, defaultDate.to))
  const [isLoading, setIsLoading] = useState(true)
  const [{ unclaimedEvents, groupType }, dispatch] = useReducer(reducer, unclaimedInit)
  const [dates, setDates] = useState(() => {
    const from = dateRange.from ? new Date(dateRange.from) : new Date()
    const to = dateRange.to ? new Date(dateRange.to) : new Date()

    return [from, to]
  })

  const setUnclaimedEvents = events =>
    dispatch({
      type: FETCH_UNCLAIMED,
      payload: {
        unclaimedEvents: events,
      },
    })

  useEffect(() => {
    ;(async () => {
      dispatch({
        type: CLEAR_UNCLAIMED,
      })
      setIsLoading(true)
      const url = `/web_applications/${groupType === RULE_TYPES.url ? 'url' : 'title'}_groups`
      const { data } = await api.get(url, { filter: filter })
      setUnclaimedEvents(data.unclaimedEvents)
      setAllUnclaimedEvents(data.unclaimedEvents)
      setIsLoading(false)
    })()
  }, [groupType, updateClaimedFlag, filter])

  const getEventCount = eventList => {
    if (!eventList || eventList.length === 0) return 0
    return eventList.reduce((acc, { count }) => acc + count, 0)
  }

  const toggleGroupBy = () => {
    if (groupType === RULE_TYPES.title)
      dispatch({
        type: TOGGLE_GROUP_TYPE,
        payload: RULE_TYPES.url,
      })
    else
      dispatch({
        type: TOGGLE_GROUP_TYPE,
        payload: RULE_TYPES.title,
      })
  }

  const onFilter = (filterRegexp, attr) => {
    const filteredTerms = allUnclaimedEvents.filter(record => new RegExp(filterRegexp).test(record[attr]))
    setUnclaimedEvents(filteredTerms)
  }

  const onDateChange = ([start, end]) => {
    const isNotEmpty = start && end
    const isoFromDate = start && start.toISOString()
    const isoToDate = end && end.toISOString()
    if (isNotEmpty) {
      setDateRange({ from: isoFromDate, to: isoToDate })
      setFilter(formatFilter(isoFromDate, isoToDate))
    } else {
      setFilter(null)
      setDateRange(defaultDate)
    }
  }

  const onClear = () => setUnclaimedEvents(allUnclaimedEvents)

  return (
    <Element style={{ background: theme['legacy-color-gray'], overflow: 'auto', maxHeight: 'calc(100vh - 59px)' }}>
      <PanelTitle
        title='Unclaimed Browser Events'
        actions={[{ text: `Grouped by: ${groupType}`, onClick: toggleGroupBy }]}
      >
        <DateTimePickerRange
          bordered={true}
          dateFormat={DS_DATE_FORMAT_MEDIUM}
          id='unclaimed-events-datepicker'
          onChange={ds => {
            setDates(ds)
            onDateChange(ds)
          }}
          style={{ border: 'none' }}
          styles={{ inputStyle: { width: '140px' } }}
          value={dates}
          showTime={false}
        />
        <Search onFilter={onFilter} onClear={onClear} groupType={groupType} />
      </PanelTitle>
      <PanelMetrics
        metrics={[
          { title: 'Groups', value: unclaimedEvents ? unclaimedEvents.length : 0 },
          { title: 'Events Count', value: getEventCount(unclaimedEvents) },
        ]}
      />
      <Table
        container={tableContainerConfig}
        columns={getColumns({ groupType, onFilter, onClear })}
        data={unclaimedEvents || []}
        loading={isLoading}
        striped={true}
        initialState={{ pageSize: 50 }}
        pageSizeOptions={[50, 100]}
      />
    </Element>
  )
}

export default memo(TitleGroupList)
