//AppCircleRenderer
import ApplicationIconD3 from 'components/applicationIcon/ApplicationIconD3'

const AppCircleRenderer = initProps => {
  const { node } = initProps

  const appCircles = node.append('g')

  appCircles.append('path').attr('d', 'M27,0 L27,-21 z').attr('stroke', '#555').attr('fill', 'transparent')

  appCircles.append('circle').attr('cx', 27).attr('cy', -28).attr('r', 20).style('fill', '#fff').style('stroke', '#555')

  const appIcon = appCircles.append('g').attr('transform', 'translate(27, -28 )')

  ApplicationIconD3({
    iconContainer: appIcon,
    applicationIndex: 0,
    radius: 15,
  })
}

export default AppCircleRenderer
