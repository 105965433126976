/* eslint "react-hooks/exhaustive-deps": 0 */ // --> OFF
import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { Element, helpers } from '@fortressiq/fiq-ds'
import { string, number } from 'prop-types'

import { backCSS, controlCSS } from './styles'

const { addPXSuffix } = helpers

const MiniMap = ({ maxHeight, maxWidth, zoom, mapOf, viewport }) => {
  const controlRef = useRef(null)
  const backRef = useRef(null)

  const [fullwidth, setFullWidth] = useState(100)
  const [fullheight, setFullHeight] = useState(90)
  const [viewWidth, setViewWidth] = useState(10) //viewport inside scroll
  const [viewHeight, setViewHeight] = useState(10)
  const [mapScale, setMapScale] = useState(0.09)
  const [controlXY, setControlXY] = useState({ x: 0, y: 0 })
  const [loaded, setLoaded] = useState(false)
  const [viewportEl, setViewport] = useState(null)

  const setBoxInfo = useCallback(() => {
    const el = document.getElementById(mapOf)
    const vel = document.getElementById(viewport)

    if (!el || !vel) {
      setLoaded(false)
      return false
    }

    setViewport(vel)
    setLoaded(true)
    const newMapScale = Math.min(maxHeight / el.scrollHeight, maxWidth / el.scrollWidth)
    setFullWidth(el.scrollWidth * newMapScale)
    setFullHeight(el.scrollHeight * newMapScale)
    setViewWidth(el.scrollWidth * newMapScale * (vel.offsetWidth / (el.scrollWidth * zoom)))
    setViewHeight(el.scrollHeight * newMapScale * (vel.offsetHeight / (el.scrollHeight * zoom)))
    setMapScale(newMapScale)
    return true
  })

  const scrollHandler = useCallback(
    e => {
      if (!loaded) {
        if (!setBoxInfo()) return
      }
      if (controlRef.current) {
        const x = e.target.scrollLeft * mapScale
        const y = e.target.scrollTop * mapScale

        setControlXY({ x: x, y: y })
      }
    },
    [mapScale]
  )

  const keyHandler = useCallback(
    e => {
      if (!loaded) {
        if (!setBoxInfo()) return
      }
      const rect = e.target.getBoundingClientRect()
      const top = +controlRef.current.style.top.replace('px', '')
      const left = +controlRef.current.style.left.replace('px', '')

      //left
      if (e.key === 'ArrowLeft') {
        let ncoord = left - 2
        if (ncoord < 0) ncoord = 0
        viewportEl.scrollLeft = ncoord / mapScale
      }

      //up
      if (e.key === 'ArrowUp') {
        let ncoord = top - 2
        if (ncoord < 0) ncoord = 0
        viewportEl.scrollTop = ncoord / mapScale
      }

      //right
      if (e.key === 'ArrowRight') {
        let ncoord = left + 2
        if (ncoord > rect.right) ncoord = rect.right
        viewportEl.scrollLeft = ncoord / mapScale
      }

      //down
      if (e.key === 'ArrowDown') {
        let ncoord = top + 2
        if (ncoord > rect.top) ncoord = rect.top
        viewportEl.scrollTop = ncoord / mapScale
      }
    },
    [mapScale]
  )

  const dragControlHandler = useCallback(
    e => {
      if (!loaded) {
        if (!setBoxInfo()) return
      }
      const rect = backRef.current.getBoundingClientRect()
      let x = e.clientX - rect.left
      let y = e.clientY - rect.top

      //give bounds -- push back if bounds greater than 'back' edges
      if (x < 0) x = 0
      if (y < 0) y = 0
      if (x > rect.right) x = Math.floor(rect.right - controlRef.current.offsetWidth)
      if (y > rect.bottom - rect.top) y = Math.floor(rect.bottom - rect.top - controlRef.current.offsetHeight)

      setControlXY({ x: x, y: y })

      viewportEl.scrollTop = y / mapScale
      viewportEl.scrollLeft = x / mapScale
    },
    [mapScale]
  )

  const clickPanHandler = useCallback(
    e => {
      if (e.target === controlRef.current) return
      if (!loaded) {
        if (!setBoxInfo()) return
      }

      const rect = e.target.getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top

      setControlXY({ x: x, y: y })

      viewportEl.scrollTop = y / mapScale
      viewportEl.scrollLeft = x / mapScale
    },
    [mapScale]
  )

  useEffect(() => {
    setBoxInfo()
  }, [])

  useEffect(() => {
    const vel = document.getElementById(viewport)

    vel.addEventListener('scroll', scrollHandler)
    window.addEventListener('resize', setBoxInfo)

    return () => {
      vel.removeEventListener('scroll', scrollHandler)
      window.removeEventListener('resize', setBoxInfo)
    }
  }, [loaded])

  useEffect(() => {
    //minimap should size when zoom changed
    setBoxInfo()
  }, [zoom])

  const controlStyles = useMemo(() => {
    let computedStyles = {
      height: viewHeight,
      left: controlXY.x,
      top: controlXY.y,
      width: viewWidth,
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(computedStyles)) {
      computedStyles = { ...computedStyles, [key]: addPXSuffix(Math.floor(value)) }
    }

    return computedStyles
  }, [controlXY, viewHeight, viewWidth])

  return (
    <Element
      height={addPXSuffix(fullheight)}
      id='process-explorere-minimap'
      onClick={clickPanHandler}
      onKeyDown={keyHandler}
      ref={backRef}
      style={backCSS}
      tabIndex='-1'
      width={addPXSuffix(fullwidth)}
    >
      <Element draggable={true} onDragEnd={dragControlHandler} ref={controlRef} style={controlCSS(controlStyles)} />
    </Element>
  )
}

MiniMap.propTypes = {
  mapOf: string.isRequired,
  viewport: string.isRequired,
  zoom: number,
  maxWidth: number,
  maxHeight: number,
}

MiniMap.defaultProps = {
  zoom: 1,
  maxWidth: 100,
  maxHeight: 100,
}

export default MiniMap
