import React, { Fragment, useState } from 'react'
import { Checkbox, Col, Group, Icon, Input, Label, Row, theme, Typography } from '@fortressiq/fiq-ds'
import { useUpdateEffect } from 'react-use'

import uiStore from 'lib/UiStore'
import { getColumns } from '../../app/events/EventColumns'

export default ({ allColumns, closeModal, exportFile, setState, state, title }) => {
  const isLite = uiStore?.tenant?.isLite
  // Allow ability to change checkboxes
  const colArray = allColumns || getColumns(isLite)
  const [disabled, setDisabled] = useState(state()?.name.length === 0)
  const inputId = 'export-name-input'

  const handleNameChange = ({ target: { value } }) => {
    setDisabled(value.length === 0)
    setState({ ...state(), name: value })
  }
  const handleCheck = (e, dataKey) => {
    if (+e === 0) {
      setState({ ...state(), columns: state().columns.filter(c => c !== dataKey) })
    } else {
      setState({ ...state(), columns: [...state().columns, dataKey] })
    }
  }
  const onSubmit = () => {
    exportFile()
  }

  useUpdateEffect(() => setDisabled(state()?.exportName?.length === 0 || state()?.name?.length === 0), [state().name])

  return (
    <Fragment>
      {(!!state()?.currentExport?.text || title) && (
        <Row
          as='header'
          columns={[{ span: 11 }, { span: 1 }]}
          margin={[theme['default-spacer-xs'], 0, theme['default-spacer-xs']]}
          noOfCols={12}
          type='grid'
        >
          <Col>
            <Typography.Title level={3} style={{ fontSize: '22px' }}>
              {state()?.currentExport?.text || title}
            </Typography.Title>
          </Col>
          <Col
            style={{
              alignSelf: 'flex-start',
              justifySelf: 'flex-end',
              maxWidth: '40px',
              paddingTop: theme['default-spacer-sm'],
            }}
          >
            <Icon
              className='fiqds-modal-closeIcon'
              clickable={true}
              color={theme.black}
              icon='colorx'
              onClick={closeModal}
              size={16}
              style={{ marginTop: `-${theme['default-spacer-sm']}` }}
            />
          </Col>
        </Row>
      )}
      {state()?.showColumns && state().columns !== undefined && (
        <Group
          align='center'
          colStyles={{ padding: theme['default-spacer-sm'] }}
          gutter={16}
          justify='flex-start'
          noOfCols={12}
          span={4}
          style={{ marginBottom: theme['default-spacer-sm'] }}
          type='flex'
        >
          {colArray.map(({ dataKey, header }, i) => (
            <Checkbox
              checked={state()?.columns?.indexOf(dataKey) > -1}
              key={`check-${i}`}
              onCheck={e => {
                handleCheck(e, dataKey)
              }}
            >
              {header}
            </Checkbox>
          ))}
        </Group>
      )}
      <Label htmlFor={inputId}>Export Name</Label>
      <Input
        autoFocus={true}
        button={true}
        buttonProps={{
          block: false,
          children: 'Export',
          disabled: disabled,
          onClick: onSubmit,
          type: 'primary',
        }}
        containerProps={{ style: { alignItems: 'flex-end' } }}
        defaultValue={state()?.exportName || state()?.name}
        id={inputId}
        onChange={handleNameChange}
        onEnter={onSubmit}
        placeholder='Name of export'
        style={{ marginTop: theme['default-spacer-sm'] }}
      />
    </Fragment>
  )
}
