import React, { useMemo } from 'react'
import filesize from 'filesize'
import sum from 'lodash/sum'
import round from 'lodash/round'

import Metric from '../../common/Metric'
import {
  Charts,
  defaultDataSourceChartProps,
  FusionCharts,
  FusionTheme,
  PowerCharts,
  ReactFC,
} from '../../../../components/viz/index'

ReactFC.fcRoot(FusionCharts, PowerCharts, Charts, FusionTheme)

const EventsChart = React.memo(({ recentEventCounts, recentSizeCounts }) => {
  const eventCountsTotal = () => (recentEventCounts ? sum(recentEventCounts.map(i => parseInt(i.value, 10))) : 0)

  const memoSumDataCounts = useMemo(() => {
    const sumDataCounts = () =>
      filesize(round(sum(recentSizeCounts.map(i => parseFloat(i.value))), 2), { fullform: true }).split(' ')

    return sumDataCounts()
  }, [recentSizeCounts])

  const recentEventsData = recentEventCounts.map(({ value }) => ({ value }))
  const recentEventsCategories = recentEventCounts.map(({ label }) => ({ label }))
  const recentEventsDataSource = {
    chart: {
      ...defaultDataSourceChartProps,
      caption: 'Event Activity',
      subCaption: `${recentEventCounts ? eventCountsTotal() : 0} Events`,
    },
    categories: [{ category: recentEventsCategories }],
    dataset: [{ data: recentEventsData }],
  }

  const recentSizeCountsData = recentSizeCounts.map(({ value }) => ({ value }))
  const recentSizeCountsCategories = recentSizeCounts.map(({ label }) => ({ label }))
  const recentSizeCountsDataSource = {
    chart: {
      ...defaultDataSourceChartProps,
      caption: 'Data Activity',
      subCaption: `${recentSizeCounts ? memoSumDataCounts[0] : 0} Total Bytes`,
    },
    categories: [{ category: recentSizeCountsCategories }],
    dataset: [{ data: recentSizeCountsData }],
  }

  const sharedChartConfigProps = {
    dataFormat: 'json',
    height: 362,
    width: '100%',
  }
  const recentEventsChartConfig = {
    id: 'observer-recent-event-counts',
    type: 'scrollarea2d',
    ...sharedChartConfigProps,
  }
  const recentSizeCountsChartConfig = {
    id: 'observer-recent-size-counts',
    type: 'scrollcolumn2d',
    ...sharedChartConfigProps,
  }

  return (
    <div className='container'>
      <Metric>
        <ReactFC {...recentEventsChartConfig} dataSource={recentEventsDataSource} />
      </Metric>
      <Metric>
        <ReactFC {...recentSizeCountsChartConfig} dataSource={recentSizeCountsDataSource} />
      </Metric>
    </div>
  )
})

export default EventsChart
