import React from 'react'
import { Input, InputNumber, Select, theme, Element, Checkbox, useController } from '@fortressiq/fiq-ds'

import PropTypes from 'prop-types'

import FieldWrapper from 'components/form/FieldWrapper'

const FIELD_TYPES = {
  NUMBER: 'number',
  SELECT: 'select',
  ARRAY: 'array',
  CHECKBOX: 'checkbox',
}

const Field = ({ item, control }) => {
  const {
    field: { onChange, ref, value, ...inputProps },
  } = useController({
    name: `miningParams.${item.key}`,
    control,
    defaultValue: item?.default,
    rules: item.validators,
    // this will unregister conditional fields
    shouldUnregister: true,
  })

  switch (item.type) {
    case FIELD_TYPES.NUMBER:
      return (
        <InputNumber
          {...inputProps}
          ref={ref}
          max={item.rules?.max || undefined}
          min={item.rules?.min || undefined}
          name={item.key}
          setNum={onChange}
          value={value}
        />
      )
    case FIELD_TYPES.SELECT:
      return (
        <Select
          {...inputProps}
          ref={ref}
          isMulti={false}
          onChange={e => onChange(e.value)}
          options={item.options}
          styles={{ menuPortal: { zIndex: theme['z-modal-above'] } }}
          value={item.options.filter(option => option.value === value)}
          menuPortalTarget={document.body}
          menuShouldBlockScroll={true}
        />
      )
    case FIELD_TYPES.ARRAY:
      return <Input {...inputProps} ref={ref} name={item.key} onChange={onChange} value={value} />
    case FIELD_TYPES.CHECKBOX:
      return (
        <Checkbox
          {...inputProps}
          inputRef={ref}
          onCheck={checked => {
            onChange(checked ? 1 : 0)
          }}
          checked={value}
        >
          {item.label}
        </Checkbox>
      )
    default:
      return `${item.type} not found`
  }
}

const AdvancedMiningParamFields = ({
  advancedMiningParams,
  formValues,
  control,
  errors,
  checkCompatibleMustIncludes,
}) => (
  <Element style={{ marginTop: theme['default-spacer-md'] }}>
    {advancedMiningParams.map(item => {
      if (item.dependency && formValues.miningParams[item.dependency.key] !== item.dependency.value) {
        return null
      }
      const itemLabel = item.validators ? `*${item.label}` : item.label
      return (
        <FieldWrapper
          key={`form-item-${item.key}`}
          helperText={item.description}
          htmlFor='observedAtRange'
          label={item.type === 'checkbox' ? undefined : itemLabel}
          error={errors?.miningParams?.[item.key]}
        >
          <Field item={item} control={control} />
        </FieldWrapper>
      )
    })}
  </Element>
)

AdvancedMiningParamFields.defaultProps = {
  errors: undefined,
}

AdvancedMiningParamFields.propTypes = {
  advancedMiningParams: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}),
  checkCompatibleMustIncludes: PropTypes.func,
}

export default AdvancedMiningParamFields
