import React from 'react'
import { Button, Group, Icon, theme } from '@fortressiq/fiq-ds'

const CaptureButtons = ({ name, toggleCapture, keystrokeAllowed, screenshotAllowed, titleAllowed }) => {
  const CaptureButton = ({ icon, title, captureType, toggleOn }) => (
    <Button
      icon={icon}
      prefixIconProps={{
        color: toggleOn ? theme['success-color'] : theme['legacy-color-gray2'],
        style: { marginRight: 0 },
      }}
      flat={true}
      noBorder={true}
      tooltipProps={{
        title: title,
      }}
      shape='round'
      size='small'
      type='link'
      onClick={() => toggleCapture(!toggleOn, captureType, name)}
    >
      {!toggleOn && (
        <Icon
          icon='remove'
          color={theme['legacy-color-gray3']}
          size={15}
          style={{ position: 'absolute', left: '10px', top: '5px' }}
        />
      )}
    </Button>
  )

  const getButtonTitle = (isOn, string) => {
    return isOn ? `${string}: On` : `${string}: Off`
  }

  return (
    <Group gutter={0}>
      <CaptureButton
        icon='paginatefilterpicture'
        title={getButtonTitle(screenshotAllowed, 'Capture Screenshots')}
        toggleCapture={toggleCapture}
        toggleOn={screenshotAllowed}
        captureType='screenshotAllowed'
      />
      <CaptureButton
        icon='typing'
        title={getButtonTitle(keystrokeAllowed, 'Capture Keystrokes')}
        toggleCapture={toggleCapture}
        toggleOn={keystrokeAllowed}
        captureType='keystrokeAllowed'
      />
      <CaptureButton
        icon='appwindowtype'
        title={getButtonTitle(titleAllowed, 'Capture Titles')}
        toggleCapture={toggleCapture}
        toggleOn={titleAllowed}
        captureType='titleAllowed'
      />
    </Group>
  )
}

export default CaptureButtons
