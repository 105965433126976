import React from 'react'
import qs from 'qs'
import { useUserState } from 'context/UserContext'

import { rules, liteRules } from 'lib/rbac-rules'

import { mergeAndDeduplicate, getIntersection } from 'lib/ArrayHelpers'

import uiStore from 'lib/UiStore'

const isDevelopment = process.env.NODE_ENV === 'development'

const isLite = uiStore?.tenant?.isLite

export const liteTenantActions = key => liteRules.tenantActions[key]

export const roleCheck = (perform, ability, currentUserRoles, tenantRules) => {
  const mergedAbilities = mergeAndDeduplicate(currentUserRoles.map(role => rules[role][ability]))
  if (tenantRules) {
    return getIntersection(mergedAbilities, tenantRules[ability]).includes(perform)
  }
  return mergedAbilities.includes(perform)
}

export const roleActionCheck = (perform, roles) => {
  let tenantRules = null
  if (isLite) {
    tenantRules = liteRules
  }
  return roleCheck(perform, 'actions', roles, tenantRules)
}
export const roleRouteCheck = (perform, roles) => {
  let tenantRules = null
  if (isLite) {
    tenantRules = liteRules
  }
  return roleCheck(perform, 'routes', roles, tenantRules)
}

const Can = ({ perform = null, route = null, children }) => {
  const { roles } = useUserState()
  const canPerform = perform ? roleActionCheck(perform, roles) : true
  const canAccess = route ? roleRouteCheck(route, roles) : true
  const access = canPerform && canAccess
  const { debugCan: debug } = qs.parse(location.search, { ignoreQueryPrefix: true })
  if (isDevelopment && debug && !access) {
    if (!canAccess) {
      console.debug(`Cant access ${route} with role ${roles}`)
    }
    if (!canPerform) {
      console.debug(`Cant perform ${perform} with role ${roles}`)
    }
  }
  if (access) {
    return children
  }
  return null
}

export default Can
