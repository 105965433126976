import React from 'react'
import { UserProvider } from 'context/UserContext'

import CreateTenantButton from '../CreateTenantButton'

describe('CreateTenantButton component', () => {
  beforeEach(() => {})
  it('should render without throwing an error', () => {
    const component = global.mountWithTheme(
      <UserProvider init={{ roleNames: 'superuser' }}>
        <CreateTenantButton />
      </UserProvider>
    )
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })

  it('should NOT render', () => {
    const component = global.mountWithTheme(
      <UserProvider init={{ roleNames: 'limited' }}>
        <CreateTenantButton />
      </UserProvider>
    )
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
})
