import React from 'react'
import classNames from 'classnames/bind'

import { Row } from '@fortressiq/fiq-ds'

import css from './controlsStyle.scss'

const cx = classNames.bind(css)

export const ControlBlock = ({ className, ...props }) => <div className={cx(css.controlBlock, className)} {...props} />
export const ControlRow = ({ className, ...props }) => (
  <Row type='grid' noOfCols={12} className={cx(css.controlRow, className)} {...props} />
)
