import React, { useState } from 'react'
import { ApplicationIcon, Tooltip } from '@fortressiq/fiq-ds'

import css from './stepsByApplication.scss'

const ApplicationSegment = ({ color, count, icon, item, total }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <div
      className={css.applicationContainer}
      style={{
        width: `${(count / total) * 100}%`,
      }}
      onMouseEnter={() => {
        setShowTooltip(true)
      }}
      onMouseLeave={() => {
        setShowTooltip(false)
      }}
    >
      <div
        key={item}
        className={css.segment}
        style={{
          background: color,
        }}
      />
      <div className={css.description}>
        <Tooltip placement='top-center' defaultVisible={showTooltip} title={`${item}: ${count}`} key={item}>
          <ApplicationIcon
            style={{
              // TODO: add this color to fiq-ds theme
              border: '1px solid #a1acc1',
              boxSizing: 'content-box',
            }}
            src={icon}
            name={item}
            size={20}
            textLength={2}
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default ApplicationSegment
