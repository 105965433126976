//ButterflyLinkRenderer
import { getColor } from './util'

import ButterflyProcessStore from './stores/ButterflyProcessStore'

const ButterflyLinkRenderer = initProps => {
  const { svg, links, path } = initProps

  // add in the links
  const link = svg
    .append('g')
    .selectAll('.link')
    .data(links)
    .enter()
    .append('path')
    .attr('id', d => {
      return `id_${d.linkID}`
    })
    .attr('class', 'link')
    .attr('d', path)
    .style('opacity', d => {
      const { d3HighlightData, selectedPath } = ButterflyProcessStore
      const isHighlighted =
        (d3HighlightData.get(d.target.id) &&
          d3HighlightData.get(d.source.id) &&
          d3HighlightData.get(d.target.id).pathIds.find(linkIDs => linkIDs === d.pathID)) ||
        selectedPath.paths.includes(d.pathKey)

      return isHighlighted ? '.7' : '.2'
    })
    .style('fill', 'transparent')
    .style('stroke-width', d => {
      return Math.max(2, d.dy)
    })
    .style('stroke', d => {
      return getColor(d.pathID)
    })
    .sort((a, b) => {
      return b.dy - a.dy
    })

  //duration text
  svg
    .selectAll('.durationText')
    .data(links)
    .enter()
    .filter(d => {
      return d.endLink
    })
    .append('text')
    .attr('class', 'durationText')
    .attr('fill', 'transparent')
    .append('textPath')
    .style('fill', '#777')
    .style('text-anchor', 'end')
    .style('font-size', '11px')
    .attr('startOffset', '85%')
    .attr('xlink:href', d => {
      return `#id_${d.linkID}`
    })
    .text(d => {
      return `Path ${d.pathKey}`
    })

  svg.selectAll('.link').style('stroke', d => {
    return getColor(d.pathID)
  })

  return link
}

export default ButterflyLinkRenderer
