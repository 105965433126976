/* eslint-disable import/prefer-default-export */
import React from 'react'
import localizer from 'lib/Localizer'
import { Element, Tooltip } from '@fortressiq/fiq-ds'

import { getObserverPermissions } from 'lib/NeoUtils'

export const observedAtRenderer = data => {
  const { cellData } = data
  return cellData && localizer.asPreciseTime(Date.parse(cellData))
}

const signatureRenderer = data => {
  const { cellData } = data
  return (
    <Element as='span' style={{ whiteSpace: 'pre' }}>
      {cellData}
    </Element>
  )
}

const titleRenderer = data => {
  const { cellData, rowData } = data

  if (
    typeof rowData.neoCaptureStatusCode !== 'undefined' &&
    rowData.neoCaptureStatusCode !== null &&
    !getObserverPermissions(rowData.neoCaptureStatusCode).title
  ) {
    return <Tooltip title='Title capture disabled for Observer.'>DISABLED</Tooltip>
  }
  return cellData
}

export const getColumns = isLite => [
  { width: 120, visible: true, dataKey: 'id', header: 'Id' },
  {
    width: 200,
    visible: true,
    dataKey: 'observedAtIso8601',
    header: 'Observed At',
    cellRenderer: observedAtRenderer,
  },
  { width: 210, visible: true, dataKey: 'applicationName', header: 'Application' },
  { width: 120, visible: false, dataKey: 'applicationId', header: 'Application Id' },
  { width: 210, visible: true, dataKey: 'title', header: 'Title', cellRenderer: titleRenderer },
  { width: 180, visible: true, dataKey: 'url', header: 'Url' },
  {
    width: 145,
    visible: false,
    dataKey: 'observerId',
    header: 'Observer Id',
  },
  { width: 210, visible: true, dataKey: 'observerName', header: 'Observer Name' },
  { width: 210, visible: false, dataKey: 'machine', header: 'Machine' },
  { width: 210, visible: false, dataKey: 'user', header: 'User' },
  ...(!isLite ? [{ width: 210, visible: true, dataKey: 'field', header: 'Field' }] : []),
  {
    width: 320,
    visible: false,
    dataKey: 'signature',
    header: 'Signature',
    cellRenderer: signatureRenderer,
  },
  { width: 130, visible: false, dataKey: 'value', header: 'Control Key' },
  { width: 100, visible: false, dataKey: 'action', header: 'Action' },
  {
    width: 320,
    visible: true,
    dataKey: 'screenSignature',
    header: 'Screen Signature',
    cellRenderer: signatureRenderer,
  },
  ...(!isLite
    ? [
        {
          width: 120,
          visible: false,
          dataKey: 'controlType',
          header: 'Control Type',
        },
      ]
    : []),
  {
    width: 130,
    visible: false,
    dataKey: 'coords',
    header: 'Coords',
  },
  {
    width: 130,
    visible: false,
    dataKey: 'tenantId',
    header: 'Tenant Id',
  },
]
