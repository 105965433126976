import React from 'react'
import { useFela } from '@fortressiq/fiq-ds'
import { useFetch } from 'lib/hooks'

import TrinityTable from 'components/TrinityTable'
import { tableWrap, processesTable } from './styles/index.js'

import getProcessesColumns from '../processes/getProcessesColumns'

const Processes = ({ miningRunId, reFetchMiningRuns, setReFetchMiningRuns }) => {
  const { css } = useFela()
  const { data: { integrations = [] } = {}, isLoadingIntegrations } = useFetch('/dynamic_integrations/process/list')

  const columns = getProcessesColumns({
    reFetch: reFetchMiningRuns,
    setReFetch: setReFetchMiningRuns,
    integrations,
    isLoadingIntegrations,
  }).filter(column => !['createdAt', 'miningRunId'].includes(column.accessor))

  return (
    <div className={css(tableWrap)}>
      <div className={css(processesTable)}>
        <TrinityTable
          columns={columns}
          reFetch={reFetchMiningRuns}
          url={`/mining_runs/${miningRunId}/graph_processes`}
          dataKey='graphProcesses'
          tableId='mining-graph-processes-table'
          disableSortBy={true}
          disableHideColumns={true}
          disablePersistedState={true}
          disablePagination={true}
          controlledState={{ hiddenColumns: ['miningRunId'] }}
        />
      </div>
    </div>
  )
}

export default Processes
