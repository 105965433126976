import React, { useState } from 'react'
import { Button, Input, Group, useForm, theme, Typography, Element } from '@fortressiq/fiq-ds'
import { func, string, number, oneOfType } from 'prop-types'

import api from 'lib/Api'

import FieldWrapper from 'components/form/FieldWrapper'

const { Paragraph } = Typography

const footerStyling = {
  margin: `0 -${theme['default-spacer-sm']}`,
  borderTop: `1px solid ${theme['dividing-line']}`,
  padding: `${theme['default-spacer-sm']} ${theme['default-spacer-sm']} 0`,
}

const KibanaPassword = ({ userId, removeModal }) => {
  const [isSaving, setIsSaving] = useState(false)
  const { register, watch, handleSubmit, formState } = useForm()

  const watchPassword = watch('password')

  const validatePassword = passwordString => {
    if (passwordString.length < 9) {
      return 'Password needs to be at least 10 characters'
    }
    if (!/(?=.*[a-z])/.test(passwordString)) {
      return 'Password needs to contain at least one lower case letter'
    }
    if (!/(?=.*[A-Z])/.test(passwordString)) {
      return 'Password needs to contain at least one upper case letter'
    }
    if (!/\d/.test(passwordString)) {
      return 'Password needs to contain at least one number'
    }
    if (!/(?=.*[!@#$%^&*()_+|~\-=`{}[\]:";'<>?,.])/.test(passwordString)) {
      return 'Password needs to contain at least one special character'
    }
    return true
  }

  const validateConfirmPassword = passwordString => (passwordString !== watchPassword ? 'Passwords do not match' : true)

  const onSubmitHandler = async formValues => {
    setIsSaving(true)
    await api.post('/users/reset_kibana_user_password', { password: formValues.password, user_id: `${userId}` })
    setIsSaving(false)
    removeModal()
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Paragraph>Make sure your password:</Paragraph>
      <ul>
        <li>Is at least 10 characters</li>
        <li>Contains a combination of upper and lower case characters</li>
        <li>Contains digits and special characters</li>
      </ul>
      <FieldWrapper htxmlFor='password' label='Password' error={formState.errors.password}>
        <Input
          autoComplete='off'
          block={true}
          prefixIcon='keyhole'
          type='password'
          {...register('password', {
            required: 'Field is required',
            validate: validatePassword,
          })}
        />
      </FieldWrapper>
      <FieldWrapper htmlFor='password' label='Confirm Password' error={formState.errors.confirmPassword}>
        <Input
          autoComplete='off'
          block={true}
          placeholder='Password confirmation'
          prefixIcon='keyhole'
          type='password'
          {...register('confirmPassword', {
            required: 'Field is required',
            validate: validateConfirmPassword,
          })}
        />
      </FieldWrapper>
      <Element style={footerStyling}>
        <Group noEndGutter={true} justify='flex-end'>
          <Button
            onClick={e => {
              e.preventDefault()
              removeModal()
            }}
          >
            Cancel
          </Button>
          <Button disabled={isSaving} loading={isSaving} type='primary'>
            Set Password
          </Button>
        </Group>
      </Element>
    </form>
  )
}

KibanaPassword.propTypes = {
  removeModal: func.isRequired,
  userId: oneOfType([string, number]).isRequired,
}

export default KibanaPassword
