import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Element } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

import ScreenViewerHeader from './ScreenViewerHeader'
import EventGroups from './EventGroups'
import { screenshotsContainerCSS, viewerLayoutCSS } from './styles'

const ScreenViewer = ({
  match: {
    params: { applicationId, screenSignature, cycleId },
  },
}) => {
  const [isLoading, setIsLoading] = useState({})
  const [application, setApplication] = useState({})
  const [screen, setScreen] = useState({})
  const [eventGroups, setEventGroups] = useState([])
  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`/applications/${applicationId}/screens/${screenSignature}`, {
        applicationUuid: applicationId,
        cycle_id: cycleId,
      })
      if (!data.eventGroups.length) {
        history.push(`/applications/${applicationId}`) // handle dead links
      }
      setEventGroups(data.eventGroups)
      setScreen(data.screen)
      setApplication(data.application)
      setIsLoading(false)
    }

    fetchData()
  }, [applicationId, screenSignature, history])

  return (
    <Element style={viewerLayoutCSS}>
      <ScreenViewerHeader {...{ screenSignature, application, screen }} setScreen={setScreen} />
      <Element style={screenshotsContainerCSS}>
        <EventGroups
          {...{
            isLoading,
            applicationId: application.id,
            screen,
            eventGroups,
            setEventGroups,
            tenantApplicationId: application.tenantApplicationId,
            cycleId: cycleId,
          }}
        />
      </Element>
    </Element>
  )
}

ScreenViewer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      applicationId: PropTypes.string,
      screenSignature: PropTypes.string,
      cycleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
}

export default ScreenViewer
