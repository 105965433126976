import React, { useState } from 'react'
import kebabCase from 'lodash/kebabCase'
import { useUpdateEffect } from 'react-use'

import ExportButton from 'components/ExportButton/ExportButton'
import callbackStore from 'stores/callback'

import { eventExportLimit } from 'constants/event-constants'

const operatorMap = {
  '<': 'lt',
  '<=': 'lteq',
  '>': 'gt',
  '>=': 'gteq',
  '~': 'matches',
  '=': 'eq',
}

const disabledMessages = {
  exceed: `Exports may not exceed ${eventExportLimit} events`,
  empty: 'There are no Events to Export',
}

const generateDefaultExportName = ({ rules = [], combinator } = {}) => {
  const defaultName = rules
    .reduce((acc, { rules: rules2, combinator: combinator2, field, value, operator }) => {
      if (rules2) {
        return [...acc, generateDefaultExportName({ rules: rules2, combinator: combinator2 })]
      }
      return [...acc, field, operatorMap[operator] || operator, value, combinator]
    }, [])
    .join('-')
  return kebabCase(defaultName.replace(new RegExp(`-${combinator}$`), ''))
}

const Export = ({ columns, filter, eventCount }) => {
  const disabled = eventCount > eventExportLimit || eventCount === 0
  const [defaultName, setDefaultName] = useState(generateDefaultExportName(filter))

  const sharedTooltipProps = {
    config: { triggerOffset: 16 },
  }

  const options = [
    {
      text: 'Events as Table (.csv)',
      exportArgs: { filter, exportType: 'csv_search_results' },
      disabled: !defaultName,
      ...(!defaultName && {
        tooltip: {
          ...sharedTooltipProps,
          placement: 'top-center',
          title: 'Set a filter to enable Export',
        },
      }),
      showColumns: true,
    },
    {
      text: 'Events as Screenshots (.jpeg)',
      exportArgs: { filter, exportType: 'html_search_results' },
      disabled,
      ...(disabled && {
        tooltip: {
          ...sharedTooltipProps,
          placement: 'bottom-center',
          title: eventCount === 0 ? disabledMessages.empty : disabledMessages.exceed,
        },
      }),
    },
    {
      text: 'Copy Paste Report (.csv)',
      exportArgs: { filter, exportType: 'copy_paste_report' },
      disabled: !defaultName,
      ...(!defaultName && {
        tooltip: {
          ...sharedTooltipProps,
          placement: 'top-center',
          title: 'Set a filter to enable Export',
        },
      }),
      showColumns: false,
    },
  ]

  useUpdateEffect(() => setDefaultName(generateDefaultExportName(filter)), [filter])

  return (
    <ExportButton
      buttonText='Export...'
      callbackStore={callbackStore}
      defaultColumns={columns.filter(c => c.visible).map(vc => vc.dataKey)}
      defaultExportName={defaultName}
      options={options}
    />
  )
}

export default Export
