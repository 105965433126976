/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { graphColors, Element, Tooltip } from '@fortressiq/fiq-ds'

import ClickOutside from 'components/ClickOutside'

import { segmentCSS, sliderCSS, thumbContainerCSS, thumbCSS } from './styles/index'

/*******************
 * Component for a fixed unit (percents) input slider
 *******************/
export default class RangeInputSlider extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.number,
    values: PropTypes.array.isRequired,
    width: PropTypes.number,
    dataKey: PropTypes.string.isRequired,
    onSlide: PropTypes.func,
    maxWidth: PropTypes.number,
  }

  static defaultProps = {
    defaultValue: 100,
    width: 200,
    onSlide: null,
  }

  constructor(props) {
    super(props)
    this.slider = React.createRef()
    this.thumb = React.createRef()

    this.state = {
      value: props.defaultValue,
      numValues: [],
      position: 100,
      active: false,
    }

    this.containerRef = React.createRef()
  }

  componentDidMount() {
    this.calculateValues()
    window.addEventListener('resize', this.calculateValues)
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props
    if (values !== prevProps.values) {
      this.calculateValues()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateValues)
  }

  render() {
    const { value } = this.state
    const { values, dataKey, className, width, maxWidth } = this.props

    return (
      <ClickOutside callback={this.mouseUp}>
        <Element
          className={className}
          ref={this.containerRef}
          style={{
            position: 'relative',
            width: width ? `${width}px` : '100%',
            maxWidth: maxWidth ? `${maxWidth}px` : null,
          }}
        >
          <Element
            onClick={e => this.onSlide(e)}
            onMouseMove={e => this.mouseMove(e)}
            onMouseUp={e => this.mouseUp(e)}
            ref={this.slider}
            style={sliderCSS}
          >
            {values.length > 0 ? (
              values.map((item, i) => (
                <Element
                  key={i}
                  style={{
                    ...segmentCSS,
                    width: `${item[dataKey]}%`,
                    background: graphColors[i % graphColors.length],
                  }}
                />
              ))
            ) : (
              <Element style={{ ...segmentCSS, width: '100%', background: graphColors[0] }} />
            )}
          </Element>
          <Element style={thumbContainerCSS}>
            <Tooltip title={`${value}%`}>
              <Element
                ref={this.thumb}
                style={{ ...thumbCSS, left: `${value - 2}%` }}
                onMouseDown={e => this.mouseDown(e)}
                onMouseUp={e => this.mouseUp(e)}
              />
            </Tooltip>
          </Element>
        </Element>
      </ClickOutside>
    )
  }

  calculateValues = () => {
    const { values, dataKey } = this.props
    const width = this.containerRef.current.offsetWidth
    let numValues = []
    numValues = values.map(v => Math.floor(width * (+v[dataKey] / 100)))
    numValues.forEach((val, i) => {
      if (i > 0) numValues[i] += numValues[i - 1]
    })

    this.setState({
      numValues: numValues,
      position: this.slider.current.getBoundingClientRect().x + this.slider.current.getBoundingClientRect().width,
    })
  }

  mouseDown = () => {
    this.setState({ active: true })
  }

  mouseMove = e => {
    const { active } = this.state
    if (!active) return
    this.onSlide(e)
  }

  mouseUp = () => {
    this.setState({ active: false })
  }

  onSlide = e => {
    e.stopPropagation()
    const { numValues, position } = this.state
    const { onSlide } = this.props
    const width = this.containerRef.current.offsetWidth
    const rect = this.slider.current.getBoundingClientRect()
    const coord = e.clientX
    let diff = null
    let index = 0
    let indexVal = 0
    let nValue = width - (rect.right - coord)

    numValues.forEach((val, i) => {
      const ndiff = Math.abs(numValues[i] - nValue)
      if (ndiff < diff || diff === null) {
        diff = ndiff
        indexVal = numValues[i]
        index = i
      }
    })

    nValue = Math.floor((indexVal / width) * 100)
    if (index >= numValues.length - 1) nValue = 100
    this.setState({ value: nValue, position: coord })

    if (onSlide) {
      onSlide(nValue, index, position - coord)
    }
  }
}
