import React, { Fragment } from 'react'
import { chroma, CopyToClipboardButton, Element, theme, Typography } from '@fortressiq/fiq-ds'
import { shape, string } from 'prop-types'

import { ellipsisCSS } from 'styles/utils'

const { Text } = Typography

const SignatureToClipboard = ({ icon, title, signature = 'alongishstringtogohere' }) => {
  const background = theme['darker-bg']

  return (
    <Element
      style={{
        alignItems: 'center',
        background,
        border: `1px solid ${chroma(background).darken(0.05).css('hsl')}`,
        borderRadius: 2,
        color: theme['dark-lighter-txt'],
        display: 'flex',
        fontSize: theme['font-size-base'],
        height: theme['default-spacer-lg'],
        justifyContent: 'space-between',
        margin: theme['default-spacer-xs'],
        padding: !signature
          ? theme['default-spacer-sm']
          : `${theme['default-spacer-sm']} ${theme['default-spacer-md']} ${theme['default-spacer-sm']} ${theme['default-spacer-sm']}`,
        ...ellipsisCSS(),
      }}
    >
      {icon}
      <Text style={{ color: theme.white, ...(!!signature && { marginRight: theme['default-spacer-md'] }) }}>
        {title}
      </Text>
      {!!signature && (
        <Fragment>
          <Text
            style={{
              color: theme['dark-contrast'],
              display: 'inline-block',
              width: `calc(100% - ${theme['default-spacer-lg']})`,
              ...ellipsisCSS('hidden', 'ellipsis', 'pre', 'break-all'),
            }}
          >
            {signature}
          </Text>
          <CopyToClipboardButton buttonProps={{ size: 'small', type: 'link' }} textToCopy={signature} />
        </Fragment>
      )}
    </Element>
  )
}

SignatureToClipboard.defaultProps = {
  icon: '',
  title: '',
  signature: '',
}

SignatureToClipboard.propTypes = {
  icon: shape({}),
  title: string,
  signature: string,
}

export default SignatureToClipboard
