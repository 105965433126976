import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { useFela, Typography, Spin } from '@fortressiq/fiq-ds'

import Main from 'components/main/Main'

import AdminMenu from './AdminMenu'
import DashboardCard from './dashboardCard/DashboardCard'
import TagsFilter from './TagsFilter'

import store from '../ClientDashboardStore'
import { useHeaderDispatch } from '../../header/HeaderContext'

import { containerCss, cardContainerCss } from '../processViewDashboard/styles/dashboard.js'

const pageTitle = 'Client Dashboard'

const { Title } = Typography

const Dashboard = observer(() => {
  const { css } = useFela()

  const [filterTags, setFilterTags] = useState([])
  const headerDispatch = useHeaderDispatch()

  useEffect(() => {
    store.fetchCards()
    store.fetchTags()
  }, [])

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: pageTitle,
      heading: pageTitle,
      toolbarActions: [<AdminMenu key='admin-menu' />],
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  const visibleCards = store.dashboardCardsArray.filter(projectCardProps => {
    if (filterTags.length !== 0) {
      const tagsInCard = projectCardProps.tags?.map(tag => tag.id)
      if (!filterTags.every(e => tagsInCard?.includes(e))) {
        return false
      }
    }
    return true
  })

  const deactivateDeletedTag = () => {
    if (filterTags.length !== 0) {
      // if user delete an active filter, deactivate the filter
      const tagIds = store.tags.map(tag => tag.value)
      setFilterTags(filterTags.filter(tag => tagIds.includes(tag)))
    }
  }

  return (
    <Main className={css(containerCss)}>
      <Title level={5}>Tags</Title>
      <TagsFilter filterTags={filterTags} setFilterTags={setFilterTags} />
      <Title level={5}>Processes</Title>
      <div className={css(cardContainerCss)}>
        {store.isLoading && visibleCards.length === 0 && <Spin>Loading</Spin>}
        {!store.isLoading && visibleCards.size && <Spin>No processes currently added.</Spin>}
        {visibleCards.map(projectCardProps => (
          <DashboardCard
            key={`dashboard-card-${projectCardProps.id}`}
            deactivateDeletedTag={deactivateDeletedTag}
            {...projectCardProps}
          />
        ))}
      </div>
    </Main>
  )
})

export default Dashboard
