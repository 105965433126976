import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useNotification } from '@fortressiq/fiq-ds'
import shortid from 'shortid'

import api from 'lib/Api'

import { FLASH_LEVELS, sidebarWidthPlusSpacer } from 'components/flash/Flash'

const StateManager = ({ match, location }) => {
  const { addNotification } = useNotification()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const setData = async () => {
      const { params } = match
      const taskId = location.search
      const url = `/processes/${params.processId}/state_actions/${params.state}${taskId}`

      const { data } = await api.put(url)

      addNotification({
        align: { left: sidebarWidthPlusSpacer },
        description: data.message,
        key: shortid.generate(),
        type: FLASH_LEVELS.INFO,
        position: 'bottom-left',
      })
      setLoading(false)
    }
    setData()
  }, [match, location.search, addNotification])

  return loading ? null : <Redirect to={`/processes/${match.params.processId}`} />
}

export default StateManager
