import React from 'react'
import PropTypes from 'prop-types'

import { LazyImage, Empty, Icon, Spin, theme, useFela } from '@fortressiq/fiq-ds'
import { emptyWrapperCSS, loadingWrapperCSS, galleryWrapperCSS, galleryImageCSS } from './styles/index.js'

const Gallery = ({ currentImages, isLoadingImages }) => {
  const { css } = useFela()
  if (isLoadingImages) {
    return <Spin containerStyle={loadingWrapperCSS}>Loading images...</Spin>
  }

  if (currentImages?.length > 0) {
    return (
      <div className={css(galleryWrapperCSS)}>
        {currentImages.map((screenshot, i) => (
          <div className={css(galleryImageCSS)} key={`screenshot-${i}`}>
            <LazyImage bordered={true} height={400} fluid={true} src={screenshot} threshold={400} width={800} />
          </div>
        ))}
      </div>
    )
  }

  return (
    <Empty
      className={css(emptyWrapperCSS)}
      customImage={<Icon fill={theme['warning-color']} icon='alerttriangle' size={66} />}
      description='No screenshots found'
    />
  )
}

Gallery.propTypes = {
  currentImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoadingImages: PropTypes.bool.isRequired,
}

export default Gallery
