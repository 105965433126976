import React, { Fragment, useState } from 'react'
import { observer } from 'mobx-react'
import { Element, Label, TextArea, theme } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

import Tags from 'components/tags/Tags'
import ProcessDashCard from '../components/ProcessDashCard'
import TooltipButton from '../components/TooltipButton'

const UserMetadata = ({ userMetaData: { id, notes: notesProps, tags, graphType }, updateProcess }) => {
  const [notes, setNotes] = useState(notesProps)
  const [editNotes, setEditNotes] = useState(false)

  const onChange = e => setNotes(e.target.value)

  const saveNote = async () => {
    if (graphType === 'graphProcess') {
      const {
        data: { graphProcess },
      } = await api.put(`/graph_processes/${id}`, { notes })
      updateProcess(graphProcess)
    }
    if (graphType === 'flowGraph') {
      api.put(`/df_graph/${id}`, {
        notes: notes,
      })
      updateProcess()
    }
    setEditNotes(false)
  }
  const cancelSaveNote = () => {
    setNotes(notesProps)
    setEditNotes(false)
  }

  const editIconContainer = { position: 'absolute', right: theme['default-spacer-xs'], top: '-22px' }

  return (
    <ProcessDashCard title='User Entered Metadata' loading={false}>
      <Label
        htmlFor='notes'
        style={{
          display: 'inline-block',
          marginBottom: theme['default-spacer-sm'],
          marginRight: theme['default-spacer-sm'],
        }}
      >
        Notes
      </Label>

      <Element style={{ minWidth: '300px', position: 'relative' }}>
        {editNotes && (
          <Fragment>
            <Element style={editIconContainer}>
              <TooltipButton title='Cancel' icon='remove' action={cancelSaveNote} />
              <TooltipButton title='Save' icon='save' action={saveNote} />
            </Element>
            <TextArea
              autoFocus={true}
              id='notes'
              onChange={onChange}
              placeholder='Enter notes…'
              rows={4}
              style={{ padding: `${theme['default-spacer-sm']} 10px` }}
              value={notes || ''}
            />
          </Fragment>
        )}

        {!editNotes && (
          <Fragment>
            <Element style={editIconContainer}>
              <TooltipButton title='Edit Notes' icon='edit' action={() => setEditNotes(!editNotes)} />
            </Element>
            <Element
              onClick={() => setEditNotes(true)}
              style={{
                border: `1px solid ${theme['legacy-color-gray1']}`,
                borderRadius: theme['default-spacer-xs'],
                height: '94px',
                overflow: 'auto',
                padding: `${theme['default-spacer-sm']} 10px`,
              }}
            >
              {notes && (
                <Element as='pre' style={{ fontFamily: 'Inter, sans-serif' }}>
                  {notes}
                </Element>
              )}
              {!notes && (
                <Element as='span' style={{ color: theme['legacy-color-gray2'], fontStyle: 'italic' }}>
                  No Notes Yet
                </Element>
              )}
            </Element>
          </Fragment>
        )}
        <Element
          style={{
            alignItems: 'baseline',
            borderTop: `1px solid ${theme['legacy-color-gray1']}`,
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: theme['default-spacer-md'],
            maxWidth: '700px',
            padding: `${theme['default-spacer-md']} 0`,
          }}
        >
          <Element as='span' style={{ fontWeight: '500', marginRight: theme['default-spacer-sm'] }}>
            Tags:
          </Element>
          <Tags taggableId={id} tags={tags} taggableType={graphType} />
        </Element>
      </Element>
    </ProcessDashCard>
  )
}

export default observer(UserMetadata)
