import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, TextArea, theme, Tooltip } from '@fortressiq/fiq-ds'

import css from './comment.scss'

const Comment = ({ saveComment, comments, buttonText, style }) => {
  const [isInputVisible, setIsInputVisible] = useState(false)
  return isInputVisible ? (
    <TextArea
      style={{ ...style }}
      rows='2'
      autoFocus={true}
      onClick={e => e.stopPropagation()}
      onBlur={e => {
        saveComment(e.target.value || '')
        setIsInputVisible(false)
      }}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          saveComment(e.target.value || '')
          setIsInputVisible(false)
        }
      }}
      defaultValue={comments || ''}
    />
  ) : (
    <div>
      {comments ? (
        <div className={css.flexCenter} style={{ ...style }}>
          <div className={css.editTrigger}>
            <Tooltip title='edit'>
              <Icon
                onClick={e => {
                  setIsInputVisible(true)
                  e.stopPropagation()
                }}
                icon='edit'
                size={15}
              />
            </Tooltip>
          </div>
          <p className={css.listComment}>{comments}</p>
        </div>
      ) : (
        <Button
          onClick={e => {
            setIsInputVisible(true)
            e.stopPropagation()
          }}
          style={{
            background: theme.white,
            borderStyle: 'dashed',
          }}
        >
          <Icon icon='add' size={14} style={{ marginRight: '4px' }} />
          {buttonText}
        </Button>
      )}
    </div>
  )
}

Comment.defaultProps = {
  comments: undefined,
  buttonText: 'Add comment',
}

Comment.propTypes = {
  saveComment: PropTypes.func.isRequired,
  comments: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  buttonText: PropTypes.string,
}

export default Comment
