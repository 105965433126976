/* eslint-disable import/prefer-default-export */
import { helpers, theme } from '@fortressiq/fiq-ds'

const { addImportant } = helpers

export const queryBuilderCSS = () => ({
  '> .ruleGroup > .ruleGroup-header': {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'flex-start',
    '> button': {
      position: 'relative',
      top: '0',
    },
  },
})

export const ruleGroupCSS = () => ({
  background: 'hsla(0, 0%, 94.1%, 0.5)',
  border: `1px solid ${theme['legacy-color-gray1']}`,
  padding: theme['default-spacer-sm'],
  '& .ruleGroup': {
    margin: `${theme['default-spacer-sm']} 0`,
    '&:last-child': {
      marginBottom: '0',
    },
  },
})

export const ruleCSS = () => ({
  alignItems: 'flex-start',
  display: 'flex',
  margin: `${theme['default-spacer-sm']} 0`,
})

export const combinatorsCSS = () => ({
  display: 'inline-block',
  marginRight: theme['default-spacer-xs'],
  outline: 'none',
})

export const removeCSS = () => ({
  display: 'inline-flex',
  marginLeft: theme['default-spacer-xs'],
  marginTop: theme['default-spacer-sm'],
})

const sharedCSS = {
  display: 'inline-block',
  marginRight: theme['default-spacer-xs'],
}
export const fieldsCSS = () => ({
  ...sharedCSS,
  width: addImportant('160px'),
})
export const operatorsCSS = () => ({ ...sharedCSS, width: addImportant('100px') })
export const valueCSS = () => ({ ...sharedCSS, width: addImportant('100%') })
