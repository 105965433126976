import React, { Fragment, useState } from 'react'
import Proptypes from 'prop-types'
import { Upload, useFela, Spin } from '@fortressiq/fiq-ds'
import { DirectUpload } from '@rails/activestorage'

const fileHash = {}

const FileDrop = ({
  accept,
  action,
  beforeUpload,
  directUpload,
  fileSizeLimit,
  onChange,
  onComplete,
  onCompleteAll,
  onDropAccepted,
  onError,
  onProgress,
  onRemove,
  onStart,
  ...props
}) => {
  const [fileList, setFileList] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const { renderer, theme } = useFela()

  renderer.renderStatic({ zIndex: theme['z-modal-below'] }, '#layers')

  const handleComplete = async event => {
    await onDropAccepted(event)
    onCompleteAll()
    setIsUploading(false)
  }

  const handleProgress = file => {
    setFileList(Object.values(fileHash))
    onProgress(file)
  }

  const uploadProgress = file => ({
    directUploadWillStoreFileWithXHR: ({ upload }) => {
      const progressHandler = event => {
        if (!fileHash[file.uid]) {
          upload.removeEventListener('progress', progressHandler)
          return null
        }
        const percent = Math.round((event.loaded / event.total) * 100)
        fileHash[file.uid].percent = percent
        handleProgress(fileHash[file.uid])
        if (percent === 100) {
          const timer = setTimeout(() => {
            if (fileHash[file.uid]) {
              fileHash[file.uid].status = 'done'
              setFileList(Object.values(fileHash))
            }
            clearTimeout(timer)
          }, 2000)
        }
      }
      upload.addEventListener('progress', progressHandler)
    },
  })

  const handleUpload = files => {
    Array.from(files).forEach(f => {
      setIsUploading(true)
      const upload = new DirectUpload(f, '/rails/active_storage/direct_uploads', uploadProgress(f))

      upload.create((error, response) => {
        if (error) {
          onError(error)
        } else {
          handleComplete(response)
        }
      })
    })
  }

  const fileDropProps = { ...props }

  if (directUpload) {
    Object.assign(fileDropProps, {
      fileList,
      onDrop: handleUpload,
    })
  } else {
    Object.assign(fileDropProps, { onDrop: onChange, onRemove, action })
  }

  return isUploading ? (
    <Fragment>
      <Spin size={60} />
      Uploading File(s)
    </Fragment>
  ) : (
    <Upload
      accept={accept}
      bordered={false}
      maxSize={fileSizeLimit}
      noDragEventsBubbling={true}
      showDetails={true}
      showThumbnails={false}
      width='100%'
      {...fileDropProps}
    />
  )
}

const noop = () => null

FileDrop.propTypes = {
  onComplete: Proptypes.func,
  onCompleteAll: Proptypes.func,
  onError: Proptypes.func,
  onProgress: Proptypes.func,
  onRemove: Proptypes.func,
  onStart: Proptypes.func,
  /**
   * fileSize in bytes
   */
  fileSizeLimit: Proptypes.number,
}

FileDrop.defaultProps = {
  onComplete: noop,
  onCompleteAll: noop,
  onError: noop,
  onProgress: noop,
  onRemove: noop,
  onStart: noop,
  fileSizeLimit: 0,
}

export default FileDrop
