// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ControlsBar FlowsControl component should render without throwing an error 1`] = `
<div
  className={{}}
>
  <Row
    noEndGutter={true}
    noOfCols={2}
    type="grid"
  >
    <Col>
      Display Paths by:
    </Col>
    <Col
      justify="flex-end"
    >
      <Select
        onChange={[Function]}
        options={
          [
            {
              "label": "Duration",
              "value": "duration",
            },
            {
              "label": "Instances",
              "value": "frequency",
            },
          ]
        }
        size="small"
        style={
          {
            "width": "150px",
          }
        }
      />
    </Col>
  </Row>
  <div
    className={{}}
  />
</div>
`;
