import React from 'react'
import DashCard from './DashCard'

import main from '../styles.scss'

import css from './eventStatsCardStyle.scss'

const EventStatsCard = ({ onClose, eventStats }) => (
  <DashCard title='Observers' onClose={onClose}>
    {Object.keys(eventStats).map((item, i) => {
      return (
        <React.Fragment key={i}>
          <div className={main.surveyCardTitle}> {item.toUpperCase()} </div>
          <div className={main.surveyCardValue}> {eventStats[item] || 0} </div>
        </React.Fragment>
      )
    })}
    {!eventStats && <div className={`${main.none} ${css.none}`}>No Event Details</div>}

    <div className={css.link}>
      <a href='/events'>Explore Logged Events</a>
    </div>
    <div className={main.mb20} />
  </DashCard>
)

export default EventStatsCard
