import React, { useState, useEffect } from 'react'
import { useFela } from '@fortressiq/fiq-ds'
import api from 'lib/Api'
import UnredactedImageViewerWithOption from 'components/image/UnredactedImageViewerWithOption'

const ScreenshotViewer = ({ match }) => {
  const [eventLog, setEventLog] = useState(null)
  const [images, setImages] = useState(null)
  const { css } = useFela()

  useEffect(() => {
    async function getEventLog() {
      const { data } = await api.get(`/event_logs/${match.params.eventLogId}`)
      setEventLog(data.event)
    }
    getEventLog()
  }, [])

  useEffect(() => {
    if (!eventLog) return
    const imageInfo = [
      {
        centered: true,
        fluid: true,
        src: eventLog.screenshotUrl,
        svgInfo: {
          box: eventLog.bounding_box || eventLog.boundingBox,
          x: eventLog.coordX || eventLog.coord_x,
          y: eventLog.coordY || eventLog.coord_y,
        },
      },
    ]
    setImages(imageInfo)
  }, [eventLog])

  return (
    <div className={css({ padding: '2%' })}>
      {images && (
        <UnredactedImageViewerWithOption
          clickImageToFullscreen={true}
          dark={false}
          images={images}
          maxHeight='100%'
          openFullscreenOnLoad={false}
          selectedIndex={0}
          fullscreenEnabled={true}
          style={{ zIndex: '1' }}
        />
      )}
    </div>
  )
}

export default ScreenshotViewer
