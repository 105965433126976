import React from 'react'
import { Icon, Tooltip } from '@fortressiq/fiq-ds'

import css from './tooltipButtonStyle.scss'

const TooltipButton = ({ title, icon, action }) => (
  <Tooltip title={title}>
    <Icon icon={icon} size={12} onClick={action} className={css.icon} />
  </Tooltip>
)

export default TooltipButton
