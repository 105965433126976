import React from 'react'
import { bool, string, objectOf, oneOfType, object, number } from 'prop-types'
import { Icon, Tooltip, theme } from '@fortressiq/fiq-ds'

const InfoTooltip = ({ small, title, size = 16, style, containerStyle }) => {
  const infoStyle = {
    display: 'flex',
    flexDirection: 'column',
    ...(!small && { width: '300px' }),
  }
  const infoContainerStyle = {
    cursor: 'help',
    display: 'inline-block',
    marginLeft: theme['default-spacer-sm'],
    position: 'relative',
    top: '2px',
  }

  return (
    <Tooltip style={{ ...infoStyle, ...style }} title={title}>
      <Icon icon='infocircle' size={size} stroke='currentColor' style={{ ...infoContainerStyle, ...containerStyle }} />
    </Tooltip>
  )
}

InfoTooltip.propTypes = {
  small: bool,
  title: oneOfType([string, object]).isRequired,
  size: number,
  style: objectOf(oneOfType([string, number, object])),
  containerStyle: objectOf(oneOfType([string, number, object])),
}

export default InfoTooltip
