import React from 'react'
import PropTypes from 'prop-types'

import { format, useForm, theme, Radio, useNotification, Element, Typography } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

import { BASE_DATE_FORMAT } from 'constants/app-constants'

import FieldWrapper from 'components/form/FieldWrapper'
import ControlledRangePicker from 'components/form/ControlledRangePicker'
import ControlledRadioGroup from 'components/form/ControlledRadioGroup'

import FormFooter from './subComponents/FormFooter'

import { formWrapperCSS } from './styles'

const { Paragraph } = Typography

const radioTitleStyle = {
  fontWeight: 500,
  display: 'inline',
}

export const recipeMap = {
  automatically_grouped: 'Automatically Grouped',
  unique_observer: 'Unique Observer',
}

const initialValues = {
  recipeType: 'automatically_grouped',
}

const RecipesForm = ({ setShowing }) => {
  const { addNotification } = useNotification()

  const { watch, handleSubmit, control, formState } = useForm({
    defaultValues: initialValues,
  })

  const formValues = watch()

  const onSubmitHandler = async e => {
    const { recipeType, observedAtRange } = e
    const fromDate = observedAtRange?.[0]
    const toDate = observedAtRange?.[1]

    const params = {
      recipe_type: recipeType,
      args: {
        observed_start: fromDate ? format(fromDate, BASE_DATE_FORMAT) : '',
        observed_end: toDate ? format(toDate, BASE_DATE_FORMAT) : '',
      },
    }

    await api.post('/mining_runs/create_from_recipe', params)
    addNotification({
      description: `Recipe ${recipeMap[recipeType]} started, gathering mining run parameters.`,
      message: 'Started',
      type: 'info',
    })

    setShowing(false)
  }

  return (
    <Element>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Element style={formWrapperCSS}>
          <ControlledRadioGroup
            control={control}
            name='recipeType'
            style={{ marginBottom: theme['default-spacer-md'], whiteSpace: 'break-spaces' }}
          >
            <Radio value='automatically_grouped'>
              <Paragraph noMargin={true} style={radioTitleStyle}>
                Automatically Grouped
              </Paragraph>
              <br />
              <Paragraph noMargin={true}>
                Uses AI to analyze the work of all observers and groups them into machine-driven cohorts of similar
                workflows, and then creates individual mining runs for each of these groups.
              </Paragraph>
            </Radio>

            <Radio value='unique_observer'>
              <Paragraph noMargin={true} style={radioTitleStyle}>
                Unique Observer
              </Paragraph>
              <Paragraph noMargin={true}>
                Treats each observer as an entirely unique datasource, creating an individual mining run for every
                observer that finds processes that exist in that single observer&apos;s data.
              </Paragraph>
            </Radio>
          </ControlledRadioGroup>
          <FieldWrapper htmlFor='observedAtRange' label='Observed At' error={formState.errors.observedAtRange}>
            <ControlledRangePicker control={control} id='observedAtRangePicker' name='observedAtRange' />
          </FieldWrapper>
        </Element>
        <FormFooter formValues={formValues} onCancel={() => setShowing(false)} />
      </form>
    </Element>
  )
}

RecipesForm.propTypes = {
  setShowing: PropTypes.func.isRequired,
}

export default RecipesForm
