import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import SparkChart from 'components/cards/public/SparkChart'
import { useFela } from '@fortressiq/fiq-ds'
import numAbbr from 'number-abbreviate'

import { generateURLQueryfromRules } from 'components/query_builder/utils'

import { sparkTextCSS } from '../styles/index.js'

const MiniSparkEvent = ({ observerName, data, id, total }) => {
  const { css } = useFela()

  const rules = [
    {
      field: 'observer_name',
      value: observerName,
      operator: '=',
    },
  ]

  const query = generateURLQueryfromRules({ combinator: 'and', rules: rules })

  return (
    <Fragment>
      <Link to={`/events?${query}`} className={css(sparkTextCSS)}>{`${numAbbr(total, 1)} Events`}</Link>
      <SparkChart id={id} width={200} height={50} data={data} />
    </Fragment>
  )
}

MiniSparkEvent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      total: PropTypes.number,
    })
  ).isRequired,
  total: PropTypes.number.isRequired,
}

export default MiniSparkEvent
