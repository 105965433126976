import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar, DateCell, Element } from '@fortressiq/fiq-ds'

import TrinityTable, { columnWidths } from 'components/TrinityTable'
import callbackStore from 'stores/callback'

import StatusBadge from 'components/StatusBadge/StatusBadge'
import { roleActionCheck } from 'components/can/Can'
import { useUserState } from 'context/UserContext'
import { useHeaderDispatch } from '../header/HeaderContext'
import ParamsColumn from './ParamsColumn'

const title = 'Signature Service Jobs'

const sortKeyMap = {
  application: 'applications.display_name',
  createdAt: 'created_at',
  createdBy: 'members.first_name',
  cycle: 'cycles.name',
}

const SignatureServiceJobList = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [updateQueue, setUpdateQueue] = useState()
  const headerDispatch = useHeaderDispatch()
  const { roles } = useUserState()

  const tableRef = useRef(null)

  const callbackId = 'taskMonitoringStatus'

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: title,
      heading: title,
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  // sometimes the update comes before the table is loaded
  // so we are saving the last update in updateQueue and update the table when its loaded
  useEffect(() => {
    const onSignatureProgress = ({ id, status, payload }) => {
      setUpdateQueue({ id, status, payload })
    }
    callbackStore.add(onSignatureProgress, callbackId, true)
    return () => {
      callbackStore.remove(callbackId)
    }
  }, [])

  useEffect(() => {
    if (!isLoading && updateQueue) {
      const {
        id,
        status,
        payload: { percent_done: percentDone, details },
      } = updateQueue
      tableRef.current.updateRowData('id', id, { status, percentDone, details })
    }
  }, [isLoading, updateQueue])

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: columnWidths.id,
    },
    {
      Header: 'Type',
      accessor: 'jobType',
      width: 100,
      disableSortBy: true,
      Cell: ({ value }) => (
        <Element as='span' style={{ textTransform: 'capitalize' }}>
          {value || 'provisional'}
        </Element>
      ),
    },
    {
      Header: 'Application',
      accessor: 'application',
      width: 100,
      disableSortBy: true,
      Cell: ({ value }) => (value ? value.displayName : ''),
    },
    {
      Header: 'Cycle',
      accessor: 'cycle',
      width: 100,
    },
    {
      Header: 'Strategy',
      accessor: 'strategy',
      Cell: ({
        value: strategyName,
        row: {
          original: { cycleId, application },
        },
      }) => {
        const url = cycleId ? `/applications/${application?.id}/${cycleId}` : `/applications/${application?.id}`
        return (
          <Link key={application?.id} to={url}>
            {strategyName}
          </Link>
        )
      },
    },
    {
      Header: 'Params',
      accessor: 'params',
      disableSortBy: true,
      Cell: ({ value }) => <ParamsColumn value={value} />,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      width: columnWidths.date,
      Cell: ({ value }) => <DateCell timestamp={value} />,
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
      width: 60,
      Cell: ({
        value: createdBy,
        row: {
          original: { createdByAvatarUrl, createdByFullName },
        },
      }) =>
        createdBy && (
          <Avatar src={createdByAvatarUrl} name={createdByFullName} alt={`${createdByFullName} (${createdBy.email})`} />
        ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 150,
      Cell: ({
        value,
        row: {
          original: { percentDone, details },
        },
      }) => {
        let statusMessage = null
        const percentageString = percentDone ? `${percentDone}%` : ''
        const detailsMessage = details?.message ? `${details.message}` : ''

        if (
          detailsMessage !== '' &&
          percentageString !== '' &&
          roleActionCheck('/signature-service-jobs/:view-job-status', roles)
        ) {
          statusMessage = `${value} (${percentageString} ${detailsMessage})`
        } else if (detailsMessage !== '' && roleActionCheck('/signature-service-jobs/:view-job-status', roles)) {
          statusMessage = `${value} (${detailsMessage})`
        } else if (percentageString !== '') {
          statusMessage = `${value} (${percentageString})`
        }

        return <StatusBadge status={value} statusMessage={statusMessage} />
      },
    },
  ]

  return (
    <div>
      <TrinityTable
        key='signature-service-jobs-table'
        sortKeyMap={sortKeyMap}
        columns={columns}
        url='/signature_service_jobs'
        dataKey='signatureServiceJobs'
        tableId='signature-service-jobs-table'
        ref={tableRef}
        onLoaded={() => setIsLoading(false)}
      />
    </div>
  )
}

export default SignatureServiceJobList
