import React from 'react'
import { Handle, Position } from 'reactflow'
import { theme, Element } from '@fortressiq/fiq-ds'
import dfgColors from '../dfgColors'

const nodeStyle = {
  height: '50px',
  width: '50px',
  borderRadius: '100%',
  backgroundColor: dfgColors.startGreen,
  display: 'flex',
  alignItems: 'center',
}

const playStyles = {
  width: 0,
  height: 0,
  border: '10px solid transparent',
  borderLeft: `15px solid ${theme.white}`,
  position: 'relative',
  left: '19px',
}

const StartNode = ({ frequency }) => {
  return (
    <React.Fragment>
      <Handle type='target' position={Position.Top} style={{ opacity: 0 }} />
      <Element style={{ textAlign: 'center' }}>
        <Element style={{ fontWeight: 700 }}>Start</Element>
        <Element style={{ marginBottom: theme['default-spacer-sm'] }}>{frequency}</Element>
        <Element style={nodeStyle}>
          <Element style={playStyles} />
        </Element>
      </Element>
      <Handle type='source' id='a' position={Position.Bottom} style={{ opacity: 0 }} />
      <Handle type='source' id='b' position={Position.Bottom} style={{ opacity: 0 }} />
    </React.Fragment>
  )
}

export default StartNode
