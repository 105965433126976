export const isOneOf = (roles, roleRequirements) =>
  roleRequirements.some(roleRequirement => {
    // only supports '!' operator for now
    // use hash for handling other operators
    const exclude = roleRequirement.charAt(0) === '!'
    const roleName = exclude ? roleRequirement.substring(1) : roleRequirement
    const isRequirementMet = roles.includes(roleName)
    return exclude ? !isRequirementMet : isRequirementMet
  })

export default { isOneOf }
