import { theme } from '@fortressiq/fiq-ds'

const sharedTextStyles = {
  fontFamily: 'IBM Plex Mono',
  fontSize: '12px',
  margin: 0,
}

export const metaTextCSS = () => ({
  ...sharedTextStyles,
  wordBreak: 'break-all',
})
export const metaLabelCSS = () => ({
  textTransform: 'capitalize',
})

export const metaValueCSS = () => ({
  color: theme['text-light'],
})

export const metaValueFilterWrapCSS = () => ({
  marginLeft: theme['default-spacer-md'],
})

export const metaValueFilterCombinatorCSS = () => ({
  ...sharedTextStyles,
  textTransform: 'uppercase',
})

export const metaTitlesCSS = () => ({
  fontWeight: '700',
  fontSize: '10px',
  marginTop: theme['default-spacer-sm'],
})
