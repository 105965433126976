/* eslint-disable import/prefer-default-export */
import { theme } from '@fortressiq/fiq-ds'
import { addPXSuffix } from 'helpers'

export const drawerWidth = addPXSuffix(16 * 19)
export const topNavHeight = '60px'
export const customEasing = 'cubic-bezier(0.7, 0.3, 0.1, 1)'
export const eventGroupHeaderHeight = '54px'

export const eventSliderCSS = () => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  maxWidth: '100%',
  overflow: '-moz-scrollbars-none',
  overflowX: 'scroll',
  paddingBottom: '50px',
  width: '100%',
  '&::-webkit-scrollbar': {
    height: '0 !important',
  },
  '> div > div > img': {
    boxShadow: `0 0 ${theme['default-spacer-sm']} ${theme['drop-shadow']}`,
    cursor: 'pointer',
    margin: 'auto',
    maxHeight: '100%',
    maxWidth: `calc(100% - ${theme['default-spacer-md']})`,
    objectFit: 'scale-down',
    userSelect: 'none',
  },
})

export const eventSlideImageCSS = () => ({
  boxShadow: `0 0 ${theme['default-spacer-sm']} ${theme['drop-shadow']}`,
  cursor: 'pointer',
  margin: 'auto',
  maxHeight: '100%',
  maxWidth: `calc(100% - ${theme['default-spacer-md']})`,
  objectFit: 'scale-down',
  userSelect: 'none',
})

export const eventSlideUnredactedImageContainerCSS = () => ({
  height: 'calc(100% - 90px)',
})

export const eventSlideCSS = () => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '100%',
  minWidth: '100%',
  position: 'relative',
})

export const eventSlideImgWrapCSS = () => ({
  alignItems: 'flex-start',
  display: 'flex',
  height: 'calc(100% - 46px)',
  justifyContent: 'center',
  position: 'absolute',
  top: '0',
  width: '100%',
  padding: theme['default-spacer-md'],
})

export const eventDetailsCSS = () => ({
  alignItems: 'center',
  bottom: '-80px',
  color: theme.white,
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  width: '100%',
  padding: theme['default-spacer-md'],
})

export const eventDetailsRowCSS = () => ({
  display: 'flex',
  flex: '1',
  flexBasis: '100%',
  flexDirection: 'column',
})

export const eventDetailsListCSS = () => ({
  listStyle: 'none',
  margin: '0',
  padding: '0',
  textAlign: 'left',
  '&:last-child': {
    textAlign: 'right',
  },
})

export const screenshotCSS = () => ({
  alignItems: 'center',
  bottom: '0',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  left: '0',
  position: 'absolute',
  right: '0',
  textAlign: 'center',
  top: '0',
  '&> img': {
    cursor: 'pointer',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'scale-down',
    userSelect: 'none',
  },
})

export const screenViewerHeaderCSS = () => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '> div': {
    marginRight: theme['default-spacer-md'],
  },
})

export const screenViewerHeaderSignatureCSS = () => ({
  whiteSpace: 'pre',
})

export const headerColumnCSS = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100%',
  padding: `${theme['default-spacer-md']} ${theme['default-spacer-md']} ${theme['default-spacer-md']} ${theme['default-spacer-lg']}`,
}

export const verticalFlexCSS = () => ({
  flexDirection: 'column',
  position: 'relative',
})

export const screenshotRow = () => ({
  position: 'relative',
  width: '100%',
  minHeight: '100%',
})

export const screenshotRowIcon = ({ isSelected }) => ({
  ...(isSelected && {
    borderColor: theme['outline-color'],
  }),
  color: `${theme['legacy-color-gray2']}`,
  transition: `color ${theme['animation-speed-base']} ease-in-out`,

  '&:hover': {
    color: theme.white,
  },
})

export const eventGroupWrapCSS = () => ({
  flex: '1',
  position: 'relative',
  transition: `margin-right ${theme['animation-speed-base']} ${customEasing}`,
})

export const eventGroupsHeaderCSS = () => ({
  color: theme.white,
  display: 'block',
  height: eventGroupHeaderHeight,
  lineHeight: eventGroupHeaderHeight,
  margin: '0',
  textAlign: 'center',
})

export const screenshotsCSS = () => ({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100% - ${eventGroupHeaderHeight})`,
  maxWidth: '100%',
  overflowY: 'auto',
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.white,
  },
})

export const viewerLayoutCSS = () => ({
  alignContent: 'stretch',
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${topNavHeight})`,
  justifyContent: 'flex-start',
})

export const screenshotsContainerCSS = () => ({
  alignContent: 'stretch',
  alignItems: 'stretch',
  backgroundColor: theme['dark-bg'],
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  justifyContent: 'flex-start',
  maxWidth: '100%',
  overflow: 'hidden',
  position: 'relative',
})

export const screenControlsDrawerCSS = ({ isDrawerOpen }) => ({
  backgroundColor: theme['dark-input-border'],
  color: theme['dark-txt'],
  display: 'flex',
  flexDirection: 'column',
  transition: `width ${theme['animation-speed-base']} ${customEasing}`,
  width: '0',
  ...(isDrawerOpen && {
    width: drawerWidth,
  }),
})

export const eventGroupDrawerIconWrapCSS = () => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  right: theme['default-spacer-md'],
  top: theme['default-spacer-md'],
  zIndex: '0',
})

export const eventGroupsHeaderTitleCSS = () => ({
  backgroundColor: theme['darker-bg'],
  color: theme['dark-title-txt'],
  display: 'flex',
  fontSize: theme['default-spacer-md'],
  justifyContent: 'space-between',
  lineHeight: eventGroupHeaderHeight,
  minWidth: drawerWidth,
  padding: `0 ${theme['default-spacer-md']}`,
  transition: `width ${theme['animation-speed-base']} ${customEasing}`,
  zIndex: '1',
})

export const screenControlsDrawerInnerCSS = ({ isMinimapEnabled }) => ({
  flex: '1',
  minWidth: drawerWidth,
  overflow: 'hidden',
  paddingLeft: '0',
  position: 'relative',
  transition: `padding-left ${theme['animation-speed-base']} ${customEasing}`,
  ...(isMinimapEnabled && {
    paddingLeft: '10px',
  }),
  '> h4': {
    marginBottom: 0,
  },
  '> *': {
    marginRight: theme['default-spacer-md'],
  },
  '> label': {
    marginRight: theme['default-spacer-sm'],
    marginTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
  },
  '> input': {
    marginRight: theme['default-spacer-sm'],
  },
  '&:first-child': {
    flex: '40%',
  },
  '&:not(:last-child)': {
    borderLeft: `1px solid ${theme['table-border-color']}`,
  },
  '&:last-child': {
    alignItems: 'flex-end',
    paddingLeft: 0,
    paddingRight: 0,
  },
})
