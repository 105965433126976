import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TagList } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

const taggableTypeMap = {
  graphProcess: 'Graph::Process',
  flowGraph: 'DfGraph::Process',
  miningRun: 'Mining::Run',
  observer: 'Observer',
  application: 'TenantApplication',
  dashboardCard: 'DashboardCard',
}

const Tags = ({ taggableType, taggableId, tags, tagType, onTagsUpdate }) => {
  const [tagList, setTagList] = useState(tags.map(tag => ({ label: tag.name, id: tag.id })))

  const onRemove = async tagObj => {
    setTagList(tagList.filter(tag => tag.id !== tagObj.id))
    const { data } = await api.post('/tags/remove_tag', {
      taggable_id: taggableId,
      name: tagObj.label,
      taggable_type: taggableTypeMap[taggableType],
      tag_type: tagType,
      id: tagObj.id,
    })

    if (onTagsUpdate) onTagsUpdate(data)
  }

  const onAdd = async newTagName => {
    const { data } = await api.post('/tags/add_tag', {
      taggable_id: taggableId,
      name: newTagName.trim(),
      taggable_type: taggableTypeMap[taggableType],
      tag_type: tagType,
    })
    if (data?.tag && !tagList.some(el => el.id === data.tag.id)) {
      setTagList([...tagList, { label: data.tag.name, id: data.tag.id }])
    }

    if (onTagsUpdate) onTagsUpdate(data)
  }

  return <TagList overlayID={`${taggableType}-${taggableId}`} tags={tagList} onRemove={onRemove} onAdd={onAdd} />
}

Tags.defaultProps = {
  tagType: 'tag',
  tags: [],
}

Tags.propTypes = {
  tagType: PropTypes.string,
  taggableType: PropTypes.string.isRequired,
  taggableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
}

export default React.memo(Tags)
