import React, { useEffect } from 'react'
import { Overlay } from '@fortressiq/fiq-ds'

import CycleForm from './form'

const CycleFormModal = ({
  reloadCycles,
  showCycleFormModal: showing,
  setShowCycleFormModal: setShowing,
  clonedCycle,
  setClonedCycle,
  editId,
  setEditId,
}) => {
  const placement = {
    overflow: { adjustX: true, adjustY: true },
    points: ['br', 'tr'],
    targetOffset: [0, 4],
    useCssTransform: false,
  }

  const trigger = {
    showing,
    setShowing,
    text: 'New Cycle',
    type: 'secondary',
  }

  const heights = {
    height: 659,
  }

  useEffect(() => {
    if (!showing) {
      setEditId()
      setClonedCycle()
    }
  }, [showing, setClonedCycle, setEditId])

  return (
    <Overlay
      config={{ disableOutsideClick: true }}
      draggable={true}
      closeIcon={true}
      basic={false}
      content={
        <CycleForm reloadCycles={reloadCycles} setShowing={setShowing} clonedCycle={clonedCycle} editId={editId} />
      }
      heights={heights}
      id='create-web-application-modal'
      placement={placement}
      trigger={trigger}
      noPadding={true}
    />
  )
}

export default CycleFormModal
