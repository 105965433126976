import React from 'react'
import { Heading, Row, Col, theme } from '@fortressiq/fiq-ds'

const Stats = ({ steps, paths, instances, averageDuration, totalDuration }) => {
  const gutter = 16
  const stats = [
    {
      title: 'Steps',
      count: steps,
    },
    {
      title: 'Paths',
      count: paths,
    },
    {
      title: 'Instances',
      count: instances,
      style: {
        borderRight: '0',
      },
    },
    {
      title: 'Avg. Dur.',
      count: averageDuration,
    },
    {
      title: 'Tot. Dur.',
      count: totalDuration,
    },
  ]

  const colStyle = {
    borderRight: `1px solid ${theme['legacy-color-gray1']}`,
    fontSize: '12px',
    lineHeight: '15px',
  }

  const rowStyle = {
    borderBottom: `1px solid ${theme['legacy-color-gray1']}`,
    margin: '0 0 20px 0',
    padding: `${theme['default-spacer-sm']} 0 20px 0`,
  }

  return (
    <Row noOfCols={3} gutter={gutter} style={rowStyle}>
      {stats.map(({ title, count, style = {} }, index) => (
        <Col key={`col-${index}`} style={{ ...colStyle, ...style }}>
          <Heading level={4}>{count}</Heading>
          {title}
        </Col>
      ))}
    </Row>
  )
}

export default Stats
