import React from 'react'
import { Link } from 'react-router-dom'
import { generateURLQueryfromRules } from 'components/query_builder/utils'

import { RULE_TYPES } from '../../Constants'

const generateQuery = (value, field) => {
  const safeValue = encodeURIComponent(value)
  return generateURLQueryfromRules({
    rules: [
      {
        id: 'r-browserEvents',
        operator: '=',
        field: 'is_browser',
        value: true,
      },
      {
        id: `r-${field}`,
        operator: '=',
        field,
        value: safeValue,
      },
    ],
  })
}
const UnclaimedExploreLink = ({ title, url, count, groupType = RULE_TYPES.title }) => (
  <Link to={`/events?${generateQuery(title || url, groupType.toLowerCase())}`}>{count || '0'}</Link>
)

export default UnclaimedExploreLink
