import React, { useEffect, useState, useCallback, memo } from 'react'
import {
  useNotification,
  AdvancedCell,
  Button,
  DateCell,
  Menu,
  MenuItem,
  EditableLabel,
  Popconfirm,
} from '@fortressiq/fiq-ds'
import camelCase from 'lodash/camelCase'
import { Link } from 'react-router-dom'
import qs from 'qs'
import shortid from 'shortid'

import api from 'lib/Api'
import StatusBadge from 'components/StatusBadge/StatusBadge'
import Flash, { FLASH_LEVELS } from 'components/flash/Flash'

import ExportMenuItem from 'components/ExportButton/ExportMenuItem'

import TrinityTable, { columnWidths } from 'components/TrinityTable'
import ActionMenu from 'components/TrinityTable/ActionMenu/ActionMenu'

import callbackStore from 'stores/callback'

import { useHeaderDispatch } from '../../header/HeaderContext'

const pageTitle = 'Event Log Exports'

export const downloadEventLogExport = async eventLogExportId => {
  const { data } = await api.get(`/event_log_exports/${eventLogExportId}/zip_url`)
  window.open(data, '_blank')
}

let eventLogExportListCallback
export const eventLogExportSuccess = ({ eventLogExport, graphProcessName }) => {
  if (eventLogExportListCallback) {
    eventLogExportListCallback()
  }

  const messageText = [
    `Event log ${eventLogExport.exportType} export`,
    graphProcessName && `for ${eventLogExport.name || graphProcessName}`,
    'is ready.',
  ]
    .filter(Boolean)
    .join(' ')
  const id = shortid.generate()
  Flash.success(
    {
      description: messageText,
      message: 'Event log export',
      cta: (
        <Button
          onClick={() => {
            downloadEventLogExport(eventLogExport.id)
            Flash.remove(id)
          }}
          size='small'
        >
          Download Now
        </Button>
      ),
    },
    { duration: 0, key: id }
  )
}

const sortKeyMap = {
  exportType: 'export_type',
  createdAt: 'created_at',
}

const exportsMap = {
  cycle_events: { displayName: 'Cycle', originUrl: '/cycles', idParam: 'cycleIds' },
  flowgraph_process: { displayName: 'Flow Process', originUrl: '/processes', idParam: 'graphProcessIds' },
  graph_process: { displayName: 'Path Process', originUrl: '/processes', idParam: 'graphProcessIds' },
  mining_run: { displayName: 'Mining', originUrl: '/mining', idParam: 'miningRunIds' },
}

const geOriginUrl = (recordTypeKey, exportRecords) => {
  const IDs = [exportRecords.map(({ record_id: rId }) => rId)]
  return `${exportsMap[recordTypeKey].originUrl}?globalFilter={"${exportsMap[recordTypeKey].idParam}":[${IDs}]}`
}

const EventLogExportList = () => {
  const [reFetch, setRefetch] = useState(0)
  const [deletingEventLogExport, setDeletingEventLogExport] = useState(null)
  const headerDispatch = useHeaderDispatch()
  const { addNotification } = useNotification()

  eventLogExportListCallback = () => {
    setRefetch(reFetch + 1)
  }

  const deleteEventLogExport = useCallback(
    async eventLogExportId => {
      if (deletingEventLogExport) {
        return null
      }
      setDeletingEventLogExport(eventLogExportId)
      const { data } = await api.delete(`/event_log_exports/${eventLogExportId}`)

      addNotification({
        message: 'Delete Successful',
        description: `Deleted "${data.name}"`,
        type: FLASH_LEVELS.SUCCESS,
      })
      setDeletingEventLogExport(null)
      setRefetch(reFetch + 1)
    },
    [deletingEventLogExport, setDeletingEventLogExport, addNotification, reFetch]
  )

  const handleUpdateExportName = ({ id, value: exportName }) => api.put(`/event_log_exports/${id}`, { exportName })

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      align: 'left',
      width: columnWidths.id,
      minWidth: columnWidths.id,
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 300,
      Cell: ({
        value: name,
        row: {
          original: { id },
        },
      }) => (
        <EditableLabel
          key={`name${id}`}
          value={name}
          render={AdvancedCell}
          showEditIcon={false}
          onUpdate={({ target: { value } }) => handleUpdateExportName({ value, id })}
        />
      ),
    },
    {
      Header: 'Type',
      accessor: 'exportType',
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: ({ value: timestamp }) => <DateCell timestamp={timestamp} />,
    },
    {
      accessor: 'filesize',
      disableSortBy: true,
      Header: 'Size',
      width: 90,
    },
    {
      accessor: 'status',
      Header: 'Status',
      width: 80,
      Cell: ({ value: status }) => <StatusBadge status={status.message ? status.message : status} />,
    },
    {
      align: 'center',
      accessor: 'action',
      disableSortBy: true,
      Header: 'File',
      width: 60,
      Cell: ({
        row: {
          original: { name, status, exportType, filterRules: filter = {}, id, recordsByType },
        },
      }) => {
        const isDeleting = deletingEventLogExport === id
        const menu = (
          <Menu bordered={false} dark={true}>
            {status === 'complete' && (
              <ExportMenuItem
                itemText='Export Events as .csv'
                customExportAjax={() => downloadEventLogExport(id)}
                exportName={name}
                callbackStore={callbackStore}
              />
            )}
            {!!Object.keys(filter).length && (
              <MenuItem key='browse-search-results'>
                <Link to={{ pathname: '/events', search: qs.stringify({ filter }) }}>View Search</Link>
              </MenuItem>
            )}
            {['cycle_events', 'graph_process', 'flowgraph_process', 'mining_run'].includes(exportType)
              ? Object.entries(recordsByType).map(([recordTitle, exportRecords]) => {
                  const recordTypeKey = camelCase(recordTitle)
                  return (
                    <MenuItem key={`${recordTypeKey}-records`}>
                      <Link to={geOriginUrl(exportType, exportRecords)}>
                        View {exportsMap[exportType].displayName || exportType}
                      </Link>
                    </MenuItem>
                  )
                })
              : null}
            <MenuItem key='delete-export-trigger' onClick={event => event.stopPropagation()}>
              <Popconfirm
                okText='Yes'
                onConfirm={() => !isDeleting && deleteEventLogExport(id)}
                placement='left-center'
              >
                {isDeleting ? 'Deleting' : 'Delete'}
              </Popconfirm>
            </MenuItem>
          </Menu>
        )
        return <ActionMenu menu={menu} />
      },
    },
  ]

  useEffect(() => {
    headerDispatch({
      type: 'set',
      heading: pageTitle,
      title: pageTitle,
    })

    return () => {
      eventLogExportListCallback = null
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  return (
    <TrinityTable
      key='exports-table'
      sortKeyMap={sortKeyMap}
      columns={columns}
      reFetch={reFetch}
      url='/event_log_exports'
      dataKey='exports'
      tableId='event-log-exports-table'
    />
  )
}

export default memo(EventLogExportList)
