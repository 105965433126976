import React from 'react'
import css from './spinner.scss'

// CREDIT: https://github.com/tobiasahlin/SpinKit

const Spinner = props => {
  const { className, style } = props
  return (
    <span className={`${css.spinner} ${className || ''}`} style={style}>
      <div className={css.bounce1} />
      <div className={css.bounce2} />
      <div className={css.bounce3} />
    </span>
  )
}

export default Spinner
