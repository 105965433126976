const parseApplicationData = processData => {
  // get application information from process data
  const applications = {}
  let total = 0

  const parseData = data => {
    if (data?.activities && data.activities.length > 0) {
      data.activities.forEach(activity => {
        if (applications[activity.application_name]) {
          applications[activity.application_name].count += 1
        } else {
          applications[activity.application_name] = {
            count: 1,
            icon: activity.application_icon_url,
          }
        }
        total += 1
      })
    }
    if (data?.children && data.children.length > 0) {
      data.children.forEach(child => parseData(child))
    }
  }

  parseData(processData)
  const order = Object.keys(applications).sort((a, b) => applications[b].count - applications[a].count)
  return { applications, total, order }
}

export default parseApplicationData
