//TODO will be moved to DS
import React from 'react'
import { bool, object, oneOfType, shape, string } from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Icon, useFela } from '@fortressiq/fiq-ds'

import { sidebarEasing } from './Sidebar'

import { activeAccent, colors, iconWrap, menuItem, menuTitle } from './styles'

const MenuItem = ({ collapsedSidebar, item: { icon, to, label }, isActive }) => {
  const { css } = useFela({
    collapsedSidebar,
    isActive,
  })

  return (
    <Link className={css(menuItem)} data-id={`nav-${to}`} to={to}>
      <div className={css(activeAccent)} />
      <div className={css(iconWrap({ collapsedSidebar, isActive }))}>
        {icon && (
          <Icon
            color={colors.color}
            fill={colors.background}
            icon={icon?.icon || icon}
            size={18}
            stroke='none'
            {...(!!icon?.icon && icon)}
          />
        )}
      </div>
      <AnimatePresence initial={false}>
        {!collapsedSidebar && (
          <motion.span
            key='content'
            initial='closed'
            animate='open'
            exit='closed'
            variants={{
              open: { opacity: 1, transition: { duration: 0.6, ease: sidebarEasing } },
              closed: { opacity: 0, transition: { duration: 0.3, ease: sidebarEasing } },
            }}
            className={css(menuTitle)}
          >
            {label}
          </motion.span>
        )}
      </AnimatePresence>
    </Link>
  )
}

MenuItem.propTypes = {
  collapsedSidebar: bool.isRequired,
  item: shape({
    label: string.isRequired,
    icon: oneOfType([object, string]).isRequired,
    to: string.isRequired,
  }).isRequired,
  isActive: bool.isRequired,
}

export default MenuItem
