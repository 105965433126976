/* eslint-disable import/prefer-default-export */
import { addPXSuffix } from 'helpers'

export const cardCSS = () => ({
  width: addPXSuffix(320),
  minHeight: addPXSuffix(200),
})

export const titleRowCSS = ({ theme }) => ({
  alignContent: 'center',
  borderBottom: `1px solid ${theme['legacy-color-gray2']}`,
  display: 'flex',
  height: addPXSuffix(42),
  justifyContent: 'space-between',
  padding: `${theme['default-spacer-md']} ${theme['default-spacer-md']} ${theme['default-spacer-sm']}`,
})

export const titleCSS = ({ theme }) => ({
  color: theme['text-dark'],
  fontSize: theme['font-size-base'],
  fontWeight: '600',
  textTransform: 'uppercase',
})
