import React from 'react'
import { Checkbox as CheckboxDS, Element, theme } from '@fortressiq/fiq-ds'

import { checkboxLabel } from '../../styles'

import { useDefaultFieldValue } from './hooks'

const Checkbox = ({ defaultValue, handleChange, inputName, label, setValue, name }) => {
  useDefaultFieldValue(defaultValue, setValue, inputName, name)

  const handleCheckbox = e => {
    handleChange(inputName, +e)
  }

  return (
    <CheckboxDS
      defaultChecked={!!defaultValue}
      onCheck={handleCheckbox}
      style={{
        justifyContent: 'flex-start',
        '&:not(:last-child)': { marginBottom: theme['default-spacer-sm'] },
      }}
      value={1}
    >
      <Element as='span' style={checkboxLabel}>
        {label}
      </Element>
    </CheckboxDS>
  )
}

export default Checkbox
