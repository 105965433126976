/* eslint-disable max-len */
export const AGGREGATION_STRATEGIES = [
  { value: '00:00:00', label: 'Continuous' },
  { value: '08:00:00', label: 'Every 8 Hours' },
  { value: '24:00:00', label: 'Daily' },
]

export const TENANT_TYPES = [
  { label: 'Customer', value: 'Customer' }, //default value
  { label: 'Partner', value: 'Partner' },
  { label: 'POC', value: 'POC' },
  { label: 'Internal', value: 'Internal' },
]

export const validCoeUrl =
  /^https:\/\/util(.eu.|.ap.|.)(shibumi).(com)\/customers\/aa\/coe-manager-intake\/index(-v\d+(.\d+)*|)\.php\?k=[A-Za-z]+&ent_id=[^_\W]{8}-[^_\W]{4}-[^_\W]{4}-[^_\W]{4}-[^_\W]{12}&program_id=\d+&uguid=[^_\W]{8}-[^_\W]{4}-[^_\W]{4}-[^_\W]{4}-[^_\W]{12}&appName=App+_\d+__app(&language=([a-zA-Z]){0,2})?$/ //NOSONAR
