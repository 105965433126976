import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import filesize from 'filesize'
import sum from 'lodash/sum'
import round from 'lodash/round'
import { Group, useFela } from '@fortressiq/fiq-ds'
import { sub, startOfDay } from 'date-fns'
import { useMount } from 'react-use'

import api from 'lib/Api'

import LoadingContainer from 'components/LoadingContainer'
import PieMetricCard from 'components/cards/public/PieMetricCard'
import MetricCardBigText from 'components/cards/public/MetricCardBigText'
import { constructStats, timeSteps } from '../common/Util'

const DataMetricsCards = React.memo(({ statsRange }) => {
  const [observerEventTotals, setObserverEventTotals] = useState([])
  const [eventsAndSizes, setEventsAndSizes] = useState({ events: [], sizes: [] })
  const [allEventsAndSizes, setAllEventsAndSizes] = useState({ events: [], sizes: [] })
  const [dayEventsAndSizes, setDayEventsAndSizes] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useMount(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const { data } = await api.get('/observers/stats', {
        step: timeSteps.DAY,
        range: 365,
        end_date: 'now',
      })

      const { events: allEvents, sizes: allSizes } = constructStats(
        data.stats,
        sub(startOfDay(new Date()), { days: 364 }),
        timeSteps.DAY,
        365
      )

      setObserverEventTotals(
        data.observer_event_totals
          .map(observer => ({ label: observer.name, value: observer.event_count }))
          .sort((a, b) => b.value - a.value)
      )

      setAllEventsAndSizes({ events: allEvents, sizes: allSizes })
      setEventsAndSizes({ events: allEvents, sizes: allSizes, observerEventTotals })
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const { data } = await api.get('/observers/stats', {
        step: timeSteps.HOUR,
        range: 24,
        end_date: 'now',
      })

      const { events: allEvents, sizes: allSizes } = constructStats(
        data.stats,
        sub(new Date(), { hours: 24 }),
        timeSteps.HOUR,
        24
      )

      setDayEventsAndSizes({ events: allEvents, sizes: allSizes })
      setEventsAndSizes({ events: allEvents, sizes: allSizes })
      setIsLoading(false)
    }
    if (statsRange === 1) {
      if (!dayEventsAndSizes) {
        fetchData()
      } else {
        setEventsAndSizes(dayEventsAndSizes)
      }
    } else {
      setEventsAndSizes(allEventsAndSizes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsRange])

  const arrLength = statsRange === 1 ? 24 : statsRange

  return (
    <LoadingContainer loading={isLoading}>
      <Cards
        recentEventCounts={eventsAndSizes.events.slice(-1 * arrLength)}
        recentSizeCounts={eventsAndSizes.sizes.slice(-1 * arrLength)}
        observerEventTotals={observerEventTotals}
      />
    </LoadingContainer>
  )
})

const Cards = React.memo(({ recentEventCounts, recentSizeCounts, observerEventTotals }) => {
  const { css } = useFela()

  const recentEventCountsTotal = () => sum(recentEventCounts.map(i => parseInt(i.value, 10)))

  const memoSumDataCounts = useMemo(() => {
    const sumDataCounts = () => {
      return filesize(round(sum(recentSizeCounts.map(i => parseFloat(i.value))), 2), { fullform: true }).split(' ')
    }

    return sumDataCounts()
  }, [recentSizeCounts])

  return (
    <div
      className={css({
        margin: 'auto',
        position: 'relative',
        width: 'calc(90% + 15px)',
      })}
    >
      <Group
        noEndGutter={true}
        gutter={32}
        justify='space-between'
        noOfCols={12}
        span={4}
        style={{
          '> .fiqds-col > .fiqds-card': {
            width: '100%',
          },
        }}
        type='flex'
      >
        <PieMetricCard title='Observers Connected' observerEventTotals={observerEventTotals} />
        <MetricCardBigText
          id='data-collected-sparkline'
          value={recentSizeCounts ? memoSumDataCounts[0] : 0}
          subLabel={memoSumDataCounts[1]}
          label='Data Collected'
          data={recentSizeCounts}
        />
        <MetricCardBigText
          id='events-collected-sparkline'
          value={recentEventCounts ? recentEventCountsTotal() : 0}
          subLabel='Events'
          label='Events Collected'
          data={recentEventCounts}
        />
      </Group>
    </div>
  )
})

DataMetricsCards.proptypes = {
  statsRange: PropTypes.number.isRequired,
}

export default DataMetricsCards
