import { theme } from '@fortressiq/fiq-ds'

export const mainContentHeightPx = 'calc(100vh - 60px)' /* height with just the main navigation bar */
export const mainContentHeight = value => ({ height: `calc(100vh - ${value})` })

export const ellipsisCSS = (overflow, textOverflow, whiteSpace, wordBreak) => ({
  overflow: overflow || 'hidden',
  textOverflow: textOverflow || 'ellipsis',
  whiteSpace: whiteSpace || 'nowrap',
  wordBreak: wordBreak || 'break-all',
})
export const ellipsisPreCSS = ellipsisCSS('hidden', 'ellipsis', 'pre', 'break-all')

export const dropDownTriggerCSS = () => ({
  padding: `0 ${theme['default-spacer-md']}`,
  '&:hover': {
    cursor: 'pointer',
  },
})
