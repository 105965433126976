import { theme } from '@fortressiq/fiq-ds'

export const empty = () => ({
  '> div': {
    width: '300px',
    height: '200px',
  },
  '> div > span': {
    width: '300px',
    height: '200px',
  },
})

export const section = () => ({
  borderBottom: `1px solid ${theme['gray-tints-0']}`,
  fontSize: '12px',
  padding: '10px 9px',

  '&::after': {
    clear: 'both',
    content: '',
    display: 'block',
    overflow: 'auto',
  },
})

const maxHeight = { maxHeight: '300px' }

export const shot = () => ({
  marginTop: theme['default-spacer-sm'],
  ...maxHeight,
  minHeight: '300px',
  overflow: 'hidden',
  width: '100%',

  '& .fiqds-imageviewer-slideshow': { ...maxHeight, maxWidth: '100%' },
  'article > div': {
    ...maxHeight,
  },

  img: {
    ...maxHeight,
    maxWidth: '100%',
  },
})

export const processOverview = () => ({
  '& > div:not(:last-child)': {
    marginBottom: '12px',
  },
})

export const tooltipWidth = 240

export const tooltip = () => ({
  'background-color': theme['dark-bg'],
  'border-radius': theme['default-spacer-xs'],
  color: theme.white,
  display: 'flex',
  'flex-direction': 'column',
  'font-size': '11px',
  'justify-content': 'space-between',
  padding: theme['default-spacer-sm'],
  position: 'absolute',
  'overflow-y': 'visible',
  'overflow-wrap': 'break-word',
  'text-align': 'center',
  width: `${tooltipWidth}px`,
  'word-break': 'break-word',
  'z-index': '1002',
})

const sharedTipArrowStyles = {
  position: 'absolute',
  width: 0,
  height: 0,
  bottom: theme['default-spacer-sm'],
}

//TODO: figure out why the theme colors don't work in this case
// innerHTML content using these styles
export const tipArrowLeft = {
  ...sharedTipArrowStyles,
  left: '-14px',
  border: '6px solid transparent',
  'border-right': '8px solid #434956',
}

export const tipArrowRight = {
  ...sharedTipArrowStyles,
  right: '-14px',
  border: '6px solid transparent',
  'border-left': '8px solid #434956',
}

export const toolbarCSS = () => ({
  borderBottom: `1px solid ${theme['ant-card-border']}`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme['default-spacer-md']} ${theme['default-spacer-lg']}`,
})

export const toggleAppButtonCSS = () => ({
  borderRadius: theme['border-radius-base'],
  height: theme['default-spacer-lg'],
})

export const chartContainerCSS = () => ({
  background: theme['legacy-color-gray'],
  display: 'flex',
  height: 'calc(100vh - 125px)',
  position: 'relative',
  width: '100%',
})
