export const modalWrapperCSS = ({ theme }) => ({
  margin: `-${theme['default-spacer-sm']}`,
  padding: theme['default-spacer-sm'],
})
export const scrollWrapperCSS = ({ theme }) => ({
  flex: 1,
  margin: `-${theme['default-spacer-sm']} -${theme['default-spacer-sm']} -1px`,
  maxHeight: '50vh',
  overflow: 'auto',
  padding: `${theme['default-spacer-sm']} ${theme['default-spacer-sm']} 0`,
})

export const formFooterCSS = ({ theme }) => ({
  background: theme.white,
  borderTop: `1px solid ${theme['dividing-line']}`,
  margin: `0 -${theme['default-spacer-sm']} -${theme['default-spacer-sm']}`,
  padding: theme['default-spacer-sm'],
})

export const tableWrapperCSS = ({ theme }) => ({
  margin: `${theme['default-spacer-sm']} -${theme['default-spacer-sm']} 0`,
})

export const applicationSelectWrapperCSS = () => ({
  display: 'flex',
})

export const applicationSelectCSS = ({ theme }) => ({
  marginRight: theme['default-spacer-sm'],
  width: '415px',
})
