import React from 'react'
import classNames from 'classnames'
import { Collapse } from '@fortressiq/fiq-ds'

// eslint-disable-next-line prefer-destructuring
const Panel = Collapse.Panel

export default class Inspector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDetails: true,
    }
  }

  render() {
    const { showDetails } = this.state
    const { header, children, className } = this.props

    return (
      <Collapse
        className={classNames({
          [className]: className,
        })}
        openKeys={['1']}
        expandIconPosition='right'
        style={{ padding: '0' }}
      >
        {showDetails && (
          <Panel header={header} panelKey='1'>
            {children}
          </Panel>
        )}
      </Collapse>
    )
  }
}
