import React, { Fragment } from 'react'
import { Button, useFela } from '@fortressiq/fiq-ds'

import dashCardCss from '../detailCardStyle.scss'
import Details from '../Details'

import { detailsColCss, nameCss, flexRowCss } from './styles/cardListing'

const CardListing = ({ title, name, details, buttonText, buttonComponent: ButtonComponent, viewUrl }) => {
  const { css } = useFela()

  return (
    <Fragment>
      <div className={dashCardCss.title}>{title}</div>
      <div className={css(flexRowCss)}>
        <div className={css(detailsColCss)}>
          <div className={css(nameCss)}>{name}</div>
          <Details details={details} />
        </div>
        {ButtonComponent ? (
          <ButtonComponent />
        ) : (
          <Button href={viewUrl} target='_blank'>
            {buttonText}
          </Button>
        )}
      </div>
    </Fragment>
  )
}

CardListing.defaultProps = {
  title: '',
  name: '--',
  details: '--',
  buttonText: 'View',
}

export default CardListing
