import React from 'react'
import classNames from 'classnames'
import { combineRules, Icon, theme, useFela } from '@fortressiq/fiq-ds'

import { containerCSS, cellCSS, controlCSS, controlLabelCSS, showCSS } from './styles/index'
import AnnotationControls from './AnnotationControls'

const AnnotationTopBar = ({
  selectedEvent,
  selectNextScreenshot,
  selectPriorScreenshot,
  showAnnotationTools,
  updateSelectedEvent,
}) => {
  const { css } = useFela({ showAnnotationTools })

  const iconClassName = css({
    alignSelf: 'flex-end',
  })
  return (
    <div className={classNames(css(combineRules(containerCSS, showCSS)), 'ant-dark')}>
      <div className={css(combineRules(controlCSS(false), cellCSS))} onClick={selectPriorScreenshot}>
        <Icon
          className={iconClassName}
          color={theme['btn-textColor']}
          fill={theme.white}
          icon='chevroncircledown'
          rotate={90}
        />
        <span className={css(controlLabelCSS(false))}>Prev</span>
      </div>

      {showAnnotationTools && (
        <AnnotationControls selectedEvent={selectedEvent} updateSelectedEvent={updateSelectedEvent} />
      )}
      <div className={css(combineRules(controlCSS(true), cellCSS))} onClick={selectNextScreenshot}>
        <span className={css(controlLabelCSS(true))}>Next</span>
        <Icon
          className={iconClassName}
          color={theme['btn-textColor']}
          fill={theme.white}
          icon='chevroncircledown'
          rotate={-90}
        />
      </div>
    </div>
  )
}

export default AnnotationTopBar
