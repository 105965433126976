// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ManageAccount component should render without throwing an error 1`] = `
<UserProvider
  init={
    {
      "roleNames": "superuser",
    }
  }
>
  <ManageAccount
    allowAllSettings={true}
    defaultTenant={
      {
        "aggregationFrequency": "",
        "appUrl": "",
        "coeIntakeUrl": "",
        "coeUserName": "",
        "createdAt": "",
        "emailFromAddress": "",
        "id": 123,
        "isActive": true,
        "isLite": false,
        "name": "",
        "smtpHost": "",
        "smtpPassword": "",
        "smtpPort": "",
        "smtpUsername": "",
        "updatedAt": "",
        "uuid": "",
      }
    }
    isLoading={false}
    setDefaultTenant={[Function]}
    setIsLoading={[Function]}
    tenantId="1"
  >
    <MainLoader>
      <Element
        className="loaderLayout"
      >
        <div
          className="loaderLayout"
        >
          <div
            className="loaderContainer"
          >
            <div
              className="lowerLeft"
            />
            <div
              className="lowerRight"
            />
            <div
              className="topRight"
            />
            <div
              className="topLeft"
            />
          </div>
        </div>
      </Element>
    </MainLoader>
  </ManageAccount>
</UserProvider>
`;
