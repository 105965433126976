import { useEffect, useState } from 'react'
import { isJsonValue } from '../../utilities'

export function useRegisterField(inputName, register, unregister, validate) {
  const [registerInput, setRegisterInput] = useState()

  useEffect(() => {
    const registerOptions = validate ? { validate } : {}
    setRegisterInput(register(inputName, registerOptions))
  }, [inputName, register, setRegisterInput, validate])

  return registerInput
}

export function useDefaultFieldValue(defaultValue, setValue, inputName, name) {
  useEffect(() => {
    if (typeof defaultValue !== 'undefined') {
      setValue(inputName, isJsonValue(name) ? JSON.stringify(defaultValue) : defaultValue)
    }
  }, [defaultValue, setValue, inputName, name])
}
