import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import uiStore from 'lib/UiStore'
import { Alert, useController, Button, Group, theme, Element, Typography, Label } from '@fortressiq/fiq-ds'

import RecursiveSignaturesGroup from './RecursiveSignaturesGroup'

import { secondarySignatureLevelLabelMap } from '../../helpers'

const { Text } = Typography

export const signaturesMustHaveDefault = {
  condition: 'and',
  signatures: [
    {
      primary: '',
    },
  ],
}

export const signaturesMustHaveEmptyDefaultGroup = {
  condition: 'and',
  signatures: [],
  nested_signatures: [
    {
      condition: 'and',
      signatures: [{ primary: '' }],
    },
  ],
}

const signatureGroupCSS = () => ({
  background: 'hsla(0, 0%, 94%, 0.5)',
  border: `1px solid ${theme['legacy-color-gray1']}`,
  padding: theme['default-spacer-md'],
  marginBottom: theme['default-spacer-md'],
})

const NestedSignatureBuilder = ({
  control,
  description,
  groupsEnabled,
  min,
  name,
  primarySignatureLabel,
  register,
  setMustIncludesSignature,
  signatureLevel,
  title,
  watch,
  isValidQuery,
  setIsValidQuery,
  mustIncludesSignature,
}) => {
  const showSecondary = signatureLevel === 'screen_signature' && !uiStore?.tenant?.isLite

  // clear any error messages and enable submitting of form
  useEffect(() => {
    let isValid = true
    function getIsValid(group) {
      group?.signatures?.forEach(sig => {
        if (!sig.primary) {
          isValid = false
        }
        if (sig.secondary_signature_level && sig.secondary_signature_level !== 'none' && !sig.secondary) {
          isValid = false
        }
      })
      group?.nested_signatures?.forEach(nGroup => getIsValid(nGroup))
    }

    getIsValid(mustIncludesSignature)
    if (isValid) setIsValidQuery(true)
  }, [mustIncludesSignature])

  const getList = nestedPath => {
    if (!mustIncludesSignature) {
      return undefined
    }
    let list = mustIncludesSignature
    const nestedPathIndexs = [...`${nestedPath}`]
    nestedPathIndexs.forEach(nestedIndex => {
      if (list.nested_signatures === undefined) return
      list = list?.nested_signatures[nestedIndex]
    })
    return list
  }
  const addGroup = (e, nestedPath) => {
    e.preventDefault()
    const list = getList(nestedPath)
    if (!list) {
      const defaultClone = cloneDeep(signaturesMustHaveEmptyDefaultGroup)
      setMustIncludesSignature(defaultClone)
      return
    }
    if (list.nested_signatures === undefined) list.nested_signatures = []
    const defaultClone = cloneDeep(signaturesMustHaveDefault)
    list.nested_signatures.push(defaultClone)
    setMustIncludesSignature({ ...mustIncludesSignature })
  }

  const removeGroup = (e, nestedPath, index) => {
    e.preventDefault()
    if (nestedPath.length === 0) {
      setMustIncludesSignature(undefined)
      return
    }

    const parentPath = nestedPath.slice(0, -1)
    const parentList = getList(parentPath)
    parentList.nested_signatures.splice(index, 1)
    setMustIncludesSignature({ ...mustIncludesSignature })
  }

  const addSignature = (e, nestedPath) => {
    e.preventDefault()
    const list = getList(nestedPath)
    if (!list) {
      const defaultClone = cloneDeep(signaturesMustHaveDefault)
      setMustIncludesSignature(defaultClone)
      return
    }
    list.signatures.push({
      primary: '',
    })
    setMustIncludesSignature({ ...mustIncludesSignature })
  }

  const onChangeSignature = (value, nestedPath, key, index) => {
    const list = getList(nestedPath)
    list.signatures[index][key] = value
    setMustIncludesSignature({ ...mustIncludesSignature })
  }

  const removeSignature = (e, nestedPath, signaturesIndex) => {
    e.preventDefault()
    const list = getList(nestedPath)

    //remove group if last signature
    if (
      (list.signatures.length === 1 && list?.nested_signatures?.length === 0) ||
      (list.signatures.length === 1 && list.nested_signatures === undefined)
    ) {
      removeGroup(e, nestedPath)
      return
    }
    list.signatures.splice(signaturesIndex, 1)
    const deepCloneSigs = cloneDeep(mustIncludesSignature)
    setMustIncludesSignature(deepCloneSigs)
  }

  const onSelectAndOr = (value, nestedPath, key, index) => {
    const list = getList(nestedPath)
    if (!list) return
    list.condition = value
    setMustIncludesSignature({ ...mustIncludesSignature })
  }

  return (
    <Element as='fieldset' style={signatureGroupCSS}>
      <Label>{title}</Label>
      {description && (
        <Text noMargin={true} style={{ display: 'block', marginBottom: theme['default-spacer-xs'] }}>
          {description}
        </Text>
      )}
      {!isValidQuery && <Alert type='error' message='All Must Includes fields are required.' />}
      <RecursiveSignaturesGroup
        groupsEnabled={groupsEnabled}
        min={min}
        name={name}
        primarySignatureLabel={primarySignatureLabel}
        setMustIncludesSignature={setMustIncludesSignature}
        showSecondary={showSecondary}
        signatureLevel={signatureLevel}
        title={title}
        group={mustIncludesSignature}
        control={control}
        nestedPath=''
        addGroup={addGroup}
        addSignature={addSignature}
        onChangeSignature={onChangeSignature}
        removeGroup={removeGroup}
        removeSignature={removeSignature}
        onSelectAndOr={onSelectAndOr}
      />
    </Element>
  )
}

NestedSignatureBuilder.defaultProps = {
  description: undefined,
  min: undefined,
  signatureLevel: undefined,
}

NestedSignatureBuilder.propTypes = {
  control: PropTypes.shape({}).isRequired,
  description: PropTypes.string,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  primarySignatureLabel: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  signatureLevel: PropTypes.string,
  title: PropTypes.string.isRequired,
  watch: PropTypes.func.isRequired,
}

export default NestedSignatureBuilder
