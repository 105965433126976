import React from 'react'
import { Menu, MenuItem, Popconfirm } from '@fortressiq/fiq-ds'
import { func, shape } from 'prop-types'

import { useUserState } from 'context/UserContext'

import ActionMenu from 'components/TrinityTable/ActionMenu/ActionMenu'

const sharedPopconfirmProps = {
  closeOnOutsideClick: true,
  dark: true,
  okText: 'Yes',
  placement: 'left-center',
}

const Actions = ({ user, addUserModal, addKibanaPasswordModal, resetPassword, toggleActiveUser }) => {
  const currentUser = useUserState()
  const { id, active, provider } = user

  const menu = (
    <Menu bordered={false} dark={true}>
      <MenuItem key='edit-user' onClick={() => addUserModal({ currentUser: user })}>
        Edit
      </MenuItem>
      {currentUser.id !== +id && (
        <MenuItem key='deactivate-user' onClick={event => event.stopPropagation()}>
          <Popconfirm {...sharedPopconfirmProps} onConfirm={() => toggleActiveUser({ userId: id, active: !active })}>
            {active ? 'Deactivate' : 'Activate'} User
          </Popconfirm>
        </MenuItem>
      )}
      {currentUser.id === +id && (
        <MenuItem key='set-event-reporting-password' onClick={() => addKibanaPasswordModal({ currentUserId: user.id })}>
          Set Event Reporting Password
        </MenuItem>
      )}
      {provider !== 'saml' && (
        <MenuItem key='reset-password-user' onClick={event => event.stopPropagation()}>
          <Popconfirm {...sharedPopconfirmProps} onConfirm={() => resetPassword(id)}>
            Force Password Reset
          </Popconfirm>
        </MenuItem>
      )}
    </Menu>
  )

  return <ActionMenu menu={menu} />
}

Actions.propTypes = {
  addUserModal: func.isRequired,
  resetPassword: func.isRequired,
  toggleActiveUser: func.isRequired,
  user: shape({}).isRequired,
}

export default Actions
