import { getStartStrategy } from './getMiningParamsPath'

const getMiningParamsReport = (miningTypeLabel = 'screen signature') => [
  {
    label: `Number of start ${miningTypeLabel}s`,
    description: `The max number of start ${miningTypeLabel}s to return.`,
    key: 'num_processes',
    type: 'number',
    default: '20',
    rules: { min: 1 },
  },
  {
    label: `Number of end ${miningTypeLabel}s`,
    description: `The max number of end ${miningTypeLabel}s to return.`,
    key: 'num_ends',
    type: 'number',
    default: '20',
    rules: { min: 1 },
  },
  getStartStrategy(miningTypeLabel),
  {
    label: 'End Strategy',
    description: `Determines which end finding strategy we use when not given a end ${miningTypeLabel}.`,
    key: 'end_strategy',
    type: 'select',
    options: [
      { value: 'dependency', label: 'Dependency' },
      { value: 'field_match', label: 'Field Match' },
    ],
    default: 'dependency',
  },
]

export default getMiningParamsReport
