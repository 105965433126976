import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { func, string, shape, bool, oneOfType, number, arrayOf } from 'prop-types'

import { Select, theme, Label } from '@fortressiq/fiq-ds'

const SignatureSelect = ({ isLoading, label, name, onChange, options, value }) => {
  const [localValue, setLocalValue] = useState()

  const onChangeHandler = useCallback(
    e => {
      const obj = {
        target: {
          name,
          value: e,
        },
      }
      onChange(obj)
      if (!e.length) {
        // of value is empty set empty local value directly
        setLocalValue([])
      }
    },
    [name, onChange]
  )

  useEffect(() => {
    if (!isLoading) {
      if (options?.length && value?.length) {
        // Sets local value when editing, cloning or options are removed by changing applications

        // Remove values not in options, return object(value, label)
        const transformedFormState = options.filter(option => value.includes(option.value))

        setLocalValue(transformedFormState)

        // check if a selected value is removed from options
        if (transformedFormState.length !== value.length) {
          onChangeHandler(transformedFormState)
        }
      } else if (!options?.length && value?.length) {
        // if options are empty but form state has values, clear the form state and localValue
        setLocalValue([])
        onChangeHandler([])
      }
    }
  }, [options, value, onChangeHandler, isLoading])

  if (!options?.length) return null
  return (
    <Fragment>
      <Label style={{ marginTop: theme['default-spacer-sm'] }}>{label}</Label>
      <Select
        isDisabled={!options?.length}
        isLoading={isLoading}
        isMulti={true}
        onChange={onChangeHandler}
        options={options}
        placeholder='Select signatures'
        styles={{ menu: { zIndex: theme['z-overdrive'] } }}
        value={localValue}
      />
    </Fragment>
  )
}

SignatureSelect.defaultProps = {
  options: undefined,
  value: undefined,
}

SignatureSelect.propTypes = {
  isLoading: bool.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  options: arrayOf(shape({ label: string, value: oneOfType([string, number]) })),
  value: oneOfType(string),
}

export default SignatureSelect
