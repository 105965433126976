import React, { useEffect, useRef, useState, Fragment, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import localStorage from 'lib/Storage'
import { Element } from '@fortressiq/fiq-ds'

import EventGroupRow from './EventGroupRow'
import ScreenViewerDrawer from './ScreenViewerDrawer'
import { minimapEnabledStorageKey, keys, tween, scrollTimeout } from './common'
import Controls from './controls/Controls'

import { eventGroupsHeaderCSS, eventGroupWrapCSS, screenshotsCSS } from './styles'

const { UP, DOWN, SPACE } = keys
const storedMinimapSetting = localStorage.get(minimapEnabledStorageKey)

let scrollingTimeout
let isScrolling = false
let slideAnimation = null

const scrollYToRef = (activeGroup, isAnimated = !isScrolling) => {
  const { parentElement: eventGroups } = activeGroup
  const scrollTop = activeGroup.offsetTop - eventGroups.offsetTop

  window.cancelAnimationFrame(slideAnimation)
  if (isAnimated) {
    isScrolling = true

    slideAnimation = tween({
      from: eventGroups.scrollTop,
      to: scrollTop,
      onUpdate: v => {
        eventGroups.scrollTop = v
      },
      onComplete: () => {
        slideAnimation = window.cancelAnimationFrame(slideAnimation)
        isScrolling = false
      },
    })
  } else {
    const index = [...eventGroups.children].indexOf(activeGroup)
    eventGroups.scrollTop = activeGroup.offsetHeight * index
  }
}

const EventGroups = ({
  eventGroups,
  setEventGroups,
  isLoading,
  applicationId,
  screen,
  tenantApplicationId,
  cycleId,
}) => {
  const eventGroupsRef = useRef()
  const [activeGroupIndex, setActiveGroupIndex] = useState(0)
  const [isMinimapEnabled, setIsMinimapEnabledState] = useState(storedMinimapSetting)
  const [selectedEventGroupIndexes, setSelectedEventGroupIndexes] = useState(new Set())
  const eventGroupCount = eventGroups.length
  const eventGroupIndexes = Object.keys(eventGroups).map(Number)
  const setIsMinimapEnabled = checked => {
    setIsMinimapEnabledState(checked)
    localStorage.set(minimapEnabledStorageKey, checked)
  }
  const activeGroupIndexFromScroll = () =>
    eventGroupsRef.current ? Math.round(eventGroupsRef.current.scrollTop / eventGroupsRef.current.offsetHeight) : 0
  const getEventGroupRef = index => eventGroupsRef.current.children[index]

  const handlePrevActiveGroup = useCallback(() => {
    const prevIndex = activeGroupIndexFromScroll() - 1
    const prevEventGroup = getEventGroupRef(prevIndex)
    if (prevEventGroup) {
      const isAnimated = !isScrolling && !slideAnimation
      setActiveGroupIndex(prevIndex)
      scrollYToRef(prevEventGroup, isAnimated)
    }
  }, [])

  const handleNextActiveGroup = useCallback(() => {
    const nextIndex = activeGroupIndexFromScroll() + 1
    const nextEventGroup = getEventGroupRef(nextIndex)
    if (nextEventGroup) {
      const isAnimated = !isScrolling && !slideAnimation
      setActiveGroupIndex(nextIndex)
      scrollYToRef(nextEventGroup, isAnimated)
    }
  }, [])

  const toggleSelectedRow = useCallback(
    (eventIndex = activeGroupIndex) => {
      const action = selectedEventGroupIndexes.has(eventIndex) ? 'delete' : 'add'
      selectedEventGroupIndexes[action](eventIndex)
      return setSelectedEventGroupIndexes(new Set(selectedEventGroupIndexes))
    },
    [activeGroupIndex, selectedEventGroupIndexes]
  )

  const toggleAllSelectedEvent = () =>
    setSelectedEventGroupIndexes(
      new Set(selectedEventGroupIndexes.size === eventGroups.length ? [] : eventGroupIndexes)
    )

  const keyHandlerMap = useMemo(
    () => ({
      [UP]: handlePrevActiveGroup,
      [DOWN]: handleNextActiveGroup,
      [SPACE]: () => toggleSelectedRow(),
    }),
    [handleNextActiveGroup, handlePrevActiveGroup, toggleSelectedRow]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keydownHandler = useCallback(
    throttle(
      e => {
        if (e.preventDefault) {
          e.preventDefault()
        }
        return keyHandlerMap[e.which] && keyHandlerMap[e.which]()
      },
      Math.round(scrollTimeout / 2),
      { leading: false }
    ),
    [keyHandlerMap]
  )

  const eventGroupHeaderText = isLoading
    ? 'Loading Event Groups'
    : `Event Group ${activeGroupIndex + 1} of ${eventGroups.length}`

  const getGroupSelectedMeta = groupIndex => ({
    isSelected: selectedEventGroupIndexes.has(groupIndex),
    isPrevSelected: selectedEventGroupIndexes.has(groupIndex - 1),
    isNextSelected: selectedEventGroupIndexes.has(groupIndex + 1),
  })

  useEffect(() => {
    const { current: currentRef } = eventGroupsRef
    const scrollHandler = () => {
      isScrolling = true
      window.clearTimeout(scrollingTimeout)
      const onScrollStop = () => {
        if (currentRef) {
          const activeIndex = activeGroupIndexFromScroll()
          setActiveGroupIndex(activeIndex)
          scrollYToRef(currentRef.children[activeIndex], true)
          isScrolling = false
        }
      }
      scrollingTimeout = setTimeout(onScrollStop, scrollTimeout)
    }

    currentRef.addEventListener('scroll', scrollHandler, false)
    window.addEventListener('keydown', keydownHandler, true)

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', scrollHandler, false)
        window.removeEventListener('keydown', keydownHandler, true)
      }
    }
  }, [eventGroups, keydownHandler])

  return (
    <Fragment>
      <Element style={eventGroupWrapCSS}>
        <Element as='h3' style={eventGroupsHeaderCSS}>
          {eventGroupHeaderText}
        </Element>
        <Element style={screenshotsCSS} ref={eventGroupsRef}>
          {eventGroups.map((eventGroup, eventGroupIndex) => (
            <EventGroupRow
              {...eventGroup}
              isSelected={selectedEventGroupIndexes.has(eventGroupIndex)}
              isActiveGroup={activeGroupIndex === eventGroupIndex}
              isAdjacentToActiveGroup={
                activeGroupIndex === eventGroupIndex - 3 || activeGroupIndex === eventGroupIndex + 3
              }
              eventGroupIndex={eventGroupIndex}
              toggleSelectedRow={toggleSelectedRow}
              key={`eventGroup-${eventGroupIndex}`}
              cycleId={cycleId}
            />
          ))}
        </Element>
      </Element>
      {!isLoading && (
        <ScreenViewerDrawer activeGroupIndex={activeGroupIndex} isMinimapEnabled={isMinimapEnabled}>
          <Controls
            {...{
              activeGroupIndex,
              applicationId,
              eventGroupCount,
              eventGroupHeaderText,
              eventGroupIndexes,
              eventGroups,
              getGroupSelectedMeta,
              handleNextActiveGroup,
              handlePrevActiveGroup,
              isMinimapEnabled,
              screen,
              selectedEventGroupIndexes,
              setEventGroups,
              setIsMinimapEnabled,
              setSelectedEventGroupIndexes,
              toggleAllSelectedEvent,
              toggleSelectedRow,
              tenantApplicationId,
            }}
          />
        </ScreenViewerDrawer>
      )}
    </Fragment>
  )
}

EventGroups.propTypes = {
  cycleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  eventGroups: PropTypes.any,
  setEventGroups: PropTypes.any,
  isLoading: PropTypes.any,
  applicationId: PropTypes.any,
  screen: PropTypes.any,
  tenantApplicationId: PropTypes.any,
}
export default EventGroups
