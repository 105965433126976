import React from 'react'
import { Element } from '@fortressiq/fiq-ds'

const FlexContainer = ({ as, end, padded, children, style, ...props }) => {
  const Container = as || Element

  return (
    <Container
      style={({ theme }) => ({
        display: 'flex',
        ...(end && {
          justifyContent: 'flex-end',
          marginTop: theme['default-spacer-md'],
        }),
        ...(padded && {
          flexDirection: 'column',
          padding: theme['default-spacer-md'],
        }),
        ...style,
      })}
      {...props}
    >
      {children}
    </Container>
  )
}

export default FlexContainer
