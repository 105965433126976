import React from 'react'

import CirclePlus from 'images/circlePlus.svg'
import GroupMenuItem from './GroupMenuItem'
import css from './menuItem.scss'

class ProcessMenuItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { opened: true }
  }

  render() {
    const { process } = this.props
    const { name, groups, stepCount } = process
    const { opened } = this.state

    return (
      <React.Fragment>
        <div className={css.row}>
          <div className={css.name} onClick={this.toggleSubMenu}>
            {name}
          </div>
          <div className={css.steps}>{stepCount}</div>
          <div className={css.add} onClick={this.processClick}>
            <img className={css.addIcon} src={CirclePlus} />
          </div>
        </div>
        {groups.length > 0 && opened && (
          <div className={css.subMenu}>
            {groups.map(group => (
              <GroupMenuItem key={group.id} group={group} groupClick={() => this.groupClick(group)} />
            ))}
          </div>
        )}
      </React.Fragment>
    )
  }

  processClick = () => {
    const { processClick, name } = this.props
    processClick(name)
  }

  groupClick = group => {
    const { groupClick } = this.props
    groupClick(group)
  }

  toggleSubMenu = () => {
    this.setState(prevState => ({ opened: !prevState.opened }))
  }
}

export default ProcessMenuItem
