import React from 'react'
import { arrayOf, func, number, shape, string } from 'prop-types'
import { Button, ButtonGroup, Element, Group, Label, Select, theme } from '@fortressiq/fiq-ds'

import { errorCSS } from './styles/index'

import { LIST_TYPES } from './Constants'

const AllowBlockListSection = ({
  lists,
  accessValue,
  accessTypeOnChange,
  accessTypeCategory,
  selectValue,
  selectOnChange,
  style,
}) => {
  const options = lists
    .filter(option => option.accessType === accessValue)
    .map(({ id, name }) => ({ value: id, label: name }))

  const selected = selectValue && options.find(item => item.value === selectValue)
  const flex = {
    display: 'flex',
    flexDirection: 'column',
  }

  return (
    <Element style={{ ...flex, ...style }}>
      <Label>{accessTypeCategory} Block and Allow List</Label>
      <Group gutter={24} noEndGutter={true} justify='space-between'>
        <ButtonGroup>
          {Object.keys(LIST_TYPES).map(typeKey => {
            const { val } = LIST_TYPES[typeKey]

            const onClick = () => accessTypeOnChange(`${accessTypeCategory}ListAccessType`, accessTypeCategory, val)
            const disabled = lists.length === 0 && val !== LIST_TYPES.NONE.val
            const type = accessValue === val && 'primary'

            return (
              <Button disabled={disabled} key={val} onClick={onClick} type={type}>
                {LIST_TYPES[typeKey].text}
              </Button>
            )
          })}
        </ButtonGroup>
        <Select
          isDisabled={accessValue === LIST_TYPES.NONE.val}
          menuPortalTarget={document.body}
          menuShouldBlockScroll={true}
          onChange={val => selectOnChange(`${accessTypeCategory}ListValue`, val.value)}
          options={options}
          placeholder={`Select ${accessTypeCategory} list...`}
          style={{ flexGrow: '1' }}
          styles={{ menuPortal: { zIndex: theme['z-modal-above'] } }}
          value={selected}
        />
      </Group>
      {lists.length === 0 && <Element style={errorCSS}>There are no existing allow or block list.</Element>}
    </Element>
  )
}

AllowBlockListSection.propTypes = {
  lists: arrayOf(shape()).isRequired,
  accessValue: string.isRequired,
  accessTypeOnChange: func.isRequired,
  accessTypeCategory: string.isRequired,
  selectValue: number,
  selectOnChange: func.isRequired,
}

export default AllowBlockListSection
