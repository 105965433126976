import React from 'react'

import FileDrop from 'components/FileDrop/FileDrop'

// @todo pull from tenant configuration
export const accept = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls, .xlt, .xla
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-powerpoint', // .ppt, .pot, .pps, .ppa
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/zip',
  'image/svg+xml',
  'image/png',
  'text/csv',
  'text/plain',
]

const UploadsModal = ({ closeModal, onCompleteAll, ...props }) => (
  <FileDrop
    accept={accept.join(',')}
    directUpload={true}
    fileSizeLimit={20971520}
    onCompleteAll={onCompleteAll || closeModal}
    {...props}
  />
)

export default UploadsModal
