import { get, theme } from '@fortressiq/fiq-ds'

import { addImportant } from 'helpers'
import { ellipsisCSS } from 'styles/utils'

export const widths = {
  closed: 48,
  open: 240,
}
export const padding = theme['default-spacer-sm']

const border = `1px solid ${theme['legacy-ant-card-border']}`
export const colors = {
  accent: '#ffbc03',
  active: {
    background: theme.black,
    color: theme.white,
  },
  background: '#333',
  color: '#ccc',
  hover: {
    background: '#121212',
  },
}

const { md, sm, xs } = get.numericValues('spacer')
const height = xs * 10

export const sidebarHeader = ({ collapsedSidebar }) => ({
  alignItems: 'center',
  background: colors.background,
  display: 'flex',
  height: collapsedSidebar ? height : height * 2,
  top: 0,
  width: widths.open,
  zIndex: theme['z-overdrive'],
})

export const tenantName = {
  color: colors.color,
  display: 'inline-block',
  fontSize: 18,
  fontWeight: 500,
  marginLeft: padding,
  maxWidth: 150,
  textTransform: 'capitalize',
  ...ellipsisCSS(),
}

export const sidebarWrapper = {
  background: colors.background,
  borderRight: border,
  boxShadow: '3px 2px 10px 0 rgba(0, 0, 0, 0.1)',
  height: '100vh',
  left: 0,
  overflow: 'hidden',
  position: 'fixed',
  top: 0,
  width: widths.open,
  zIndex: theme['z-modal-below'],
  '& a': {
    color: colors.color,
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'none',
    },
  },
}

export const sidebarScroller = ({ collapsedSidebar }) => ({
  height: '100%',
  overflowX: 'visible',
  overflowY: 'auto',
  position: 'relative',
  width: widths.open,
  ...(collapsedSidebar
    ? {
        overflow: 'hidden',
      }
    : {
        paddingTop: md,
      }),
  '&::-webkit-scrollbar': {
    width: addImportant(0),
  },
})

const accentBorderRadius = 100
export const activeAccent = ({ isActive }) => ({
  background: isActive ? colors.accent : 'transparent',
  borderTopRightRadius: accentBorderRadius,
  borderBottomRightRadius: accentBorderRadius,
  display: 'inline-flex',
  height: 24,
  width: xs,
})

export const subMenuArrow = ({ collapsedSidebar }) => ({
  alignItems: 'start',
  display: 'inline-flex',
  transition: 'all 0.2s ease-in-out',
  ...(collapsedSidebar
    ? {
        opacity: 0,
        width: xs,
      }
    : { paddingLeft: sm - 2, opacity: 1 }),
})

const hover = {
  background: colors.hover.background,
  color: colors.accent,
  cursor: 'pointer',
  '> a': {
    color: colors.accent,
  },
}
const activeColor = {
  color: colors.active.color,
}
export const menuItem = ({ isActive, isOpen, isSubMenu }) => ({
  alignItems: 'center',
  color: colors.color,
  display: 'flex',
  fontSize: 14,
  fontWeight: 600,
  marginBottom: sm,
  position: 'relative',
  ...(isActive && !isSubMenu && colors.active),
  ':hover': hover,
  ...(isOpen &&
    !isSubMenu && {
      color: colors.active.color,
      cursor: 'pointer',
    }),
})

export const subMenuItem = ({ isActive }) => ({
  display: 'flex',
  fontSize: 14,
  padding: '6px 0',
  transition: 'all 0.2s ease-in-out',
  ':active, :focus': activeColor,
  ':hover': hover,
  ...(isActive && colors.active),
  '> span:last-child': {
    color: !isActive ? colors.color : colors.active.color,
    lineHeight: '25px',
    paddingLeft: 67,
  },
})

export const iconWrap = ({ collapsedSidebar, isActive, isSubMenu = false }) => ({
  alignItems: 'center',
  borderRadius: '50%',
  color: colors.color,
  display: 'flex',
  height: 40,
  justifyContent: 'center',
  transition: 'all 0.2s ease-in-out',
  width: 40,
  '& svg': {
    transition: 'all 0.2s ease-in-out',
  },
  ...(isActive && { color: colors.active.color }),
  ...(!collapsedSidebar && !isSubMenu && { marginLeft: md }),
})

export const subMenuTitle = {
  '&.collapsed': {
    display: 'none',
    opacity: 0,
  },
}

export const menuTitle = ({ isActive }) => {
  const active = {
    color: colors.active.color,
  }

  return {
    marginLeft: sm + xs,
    ':hover': hover,
    ...(isActive && active),
  }
}

export const subMenu = {
  overflow: 'hidden',
  '> a:last-child': {
    marginBottom: sm + xs,
  },
}

export const collapseIcon = {
  left: sm * 3,
  position: 'absolute',
}
