import React, { Fragment } from 'react'
import { Dropdown, Icon, useFela } from '@fortressiq/fiq-ds'

const ActionMenu = ({ menu, disabled }) => {
  const containerID = 'trinityTable-actionMenu-dropdown-container'
  const { css, theme } = useFela()

  return (
    <Fragment>
      <div id={containerID} className={css({ zIndex: theme['z-modal-below'] })} />
      <Dropdown
        layer={{
          auto: false,
          container: containerID,
          placement: 'bottom-end',
          snap: false,
          triggerOffset: 8,
        }}
        overlay={menu}
        trigger={{ type: 'click' }}
      >
        <Icon clickable={true} icon='chevron' size='small' style={{ cursor: disabled ? 'not-allowed' : 'cursor' }} />
      </Dropdown>
    </Fragment>
  )
}

export default ActionMenu
