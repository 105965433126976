// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`BoundingBoxTag component should render without throwing an error 1`] = `
<div
  className="boundingBoxTag"
>
  <Memo(Tag)
    closable={true}
    color={false}
    onClose={[Function]}
    onMouseEnter={[Function]}
    onMouseLeave={[Function]}
    style={
      {
        "marginRight": "8px",
      }
    }
  >
    1
  </Memo(Tag)>
</div>
`;
