import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import api from 'lib/Api'

import QueryBuilderSelect from './QueryBuilderSelect'

const filterMap = {
  observer_name: { url: '/observers/filters', param: null },
  user: { url: '/observers/filters', param: { field: 'windows_username' } },
  application_name: { url: '/applications/filters', param: null },
  application_id: { url: '/applications/filters', param: { field: 'application_id' } },
  observer_id: { url: '/observers/filters', param: { field: 'id' } },
  machine: { url: '/observers/filters', param: { field: 'machine' } },
}

const QueryBuilderAutocomplete = ({ field, ...props }) => {
  const [options, setOptions] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true)
      const { data: fetchedData } = await api.get(filterMap[field].url, filterMap[field].param)
      const transformedData = fetchedData
        .filter(option => option.label && option.value)
        .map(option => ({
          label: option.label.toString(),
          value: option.label.toString(),
        }))
      setOptions(transformedData)
      setIsLoading(false)
    }
    getData()
  }, [field])

  return <QueryBuilderSelect {...props} isLoading={isLoading} options={options} field={field} />
}

QueryBuilderAutocomplete.propTypes = {
  field: PropTypes.string.isRequired,
}

export default QueryBuilderAutocomplete
