import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { Button, Input, useForm, useFela } from '@fortressiq/fiq-ds'

import ControlledSelect from 'components/form/ControlledSelect'
import FieldWrapper from 'components/form/FieldWrapper'
import ControlledSwitch from 'components/form/ControlledSwitch'

import api from 'lib/Api'

import wrapperCSS from './wrapperCSS'

const typeOptions = [
  { value: 'Tenant', label: 'Tenant' },
  { value: 'Observer', label: 'Observer' },
  { value: 'Application', label: 'Application' },
  { value: 'Cycle', label: 'Cycle' },
  { value: 'Process', label: 'Process' },
  { value: 'Mining Run', label: 'Mining Run' },
  { value: 'Event', label: 'Event' },
]

const transportOptions = [
  { value: 'HTTP', label: 'HTTP' },
  { value: 'HTTPS', label: 'HTTPS' },
]

const methodOptions = [
  { value: 'GET', label: 'GET' },
  { value: 'POST', label: 'POST' },
  { value: 'PUT', label: 'PUT' },
  { value: 'DELETE', label: 'DELETE' },
]

const authorizationTypeOptions = [
  { value: 'None', label: 'None' },
  { value: 'Basic', label: 'Basic' },
  { value: 'Bearer', label: 'Bearer' },
]

const generateDefaultValues = editValues => {
  // Remove none form fields: createdAt, id, tenantId, updatedAt,
  const { createdAt, id, tenantId, updatedAt, integrationType, transport, method, authorizationType, ...formFields } =
    editValues
  return {
    ...formFields,
    // get value label obj for select inputs, based on value from options array
    integrationType: typeOptions.filter(option => option.value === integrationType)[0],
    transport: transportOptions.filter(option => option.value === transport)[0],
    method: methodOptions.filter(option => option.value === method)[0],
    authorizationType: authorizationTypeOptions.filter(option => option.value === authorizationType)[0],
  }
}

const IntegrationFormModal = ({ removeModal, handleReFetch, editId, editValues }) => {
  const { css } = useFela()
  const isEditing = !!editId

  const { register, watch, setValue, handleSubmit, control, formState } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true, // Will unregister conditional fields
    defaultValues: editValues ? { ...generateDefaultValues(editValues) } : undefined,
  })

  const onSubmitHandler = async formValues => {
    let { endpoint } = formValues
    if (endpoint.toLowerCase().startsWith('https://')) {
      endpoint = endpoint.substring(8)
    } else if (endpoint.toLowerCase().startsWith('http://')) {
      endpoint = endpoint.substring(7)
    }
    const params = {
      integration_type: formValues.integrationType.value,
      authorization_type: formValues.authorizationType.value,
      endpoint: endpoint,
      method: formValues.method.value,
      transport: formValues.transport.value,
      title: formValues.title,
      is_active: formValues.isActive,
      ...(formValues.authorizationType.value === 'Bearer' &&
        formValues.bearerToken && { api_token: formValues.bearerToken }),
      ...(formValues.authorizationType.value === 'Basic' &&
        formValues.password && {
          password: formValues.password,
        }),
      ...(formValues.authorizationType.value === 'Basic' && {
        user_name: formValues.userName,
      }),
    }

    // If editId exist, we are in edit mode and use api.put instead of post
    const data = isEditing
      ? api.put(`/dynamic_integrations/${editId}`, params)
      : api.post('/dynamic_integrations', params)
    await data
    handleReFetch()
    removeModal()
  }

  const validatePassword = value => {
    // If editing allow empty password
    if ((isEditing && editValues.authorizationType === 'Basic') || value !== '') {
      return true
    }
    return false
  }

  // authorizationType bearer and basic has some additional fields
  // Need to watch authorizationType to determine what fields to display
  const watchAuthorizationField = watch('authorizationType')

  const help = debounce(val => {
    const url = val.toLowerCase()
    if (url.startsWith('https:')) {
      setValue('transport', { value: 'HTTPS', label: 'HTTPS' }, { shouldDirty: true })
    } else if (url.startsWith('http:')) {
      setValue('transport', { value: 'HTTP', label: 'HTTP' }, { shouldDirty: true })
    }
  }, 300)

  const endPointHelper = evt => {
    help(evt.target.value)
  }

  return (
    <div className={css(wrapperCSS)}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <FieldWrapper
          error={formState.errors.integrationType}
          htmlFor='integrationType'
          isRequired={true}
          label='Integration Type'
        >
          <ControlledSelect
            control={control}
            isMulti={false}
            name='integrationType'
            options={typeOptions}
            portal={true}
            rules={{ required: 'This field is required' }}
          />
        </FieldWrapper>
        <FieldWrapper error={formState.errors.title}>
          <Input
            {...register('title', { required: 'This is required.' })}
            isRequired={true}
            label='Button Label'
            placeholder='Label associated with integration.'
          />
        </FieldWrapper>
        <FieldWrapper
          error={formState.errors.endpoint && { message: 'Endpoint is required and needs to include %id%' }}
          helperText='To use an object ID in the url, use /%id%/'
          htmlFor='endpoint'
        >
          <Input
            label='Endpoint'
            placeholder='For example: automationanywhere.com/%id%'
            {...register('endpoint', {
              validate: value => value.includes('%id%'),
            })}
            onChange={endPointHelper}
          />
        </FieldWrapper>
        <FieldWrapper htmlFor='transport' label='Transport' error={formState.errors.transport} isRequired={true}>
          <ControlledSelect
            control={control}
            isMulti={false}
            name='transport'
            options={transportOptions}
            portal={true}
            rules={{ required: 'This field is required' }}
          />
        </FieldWrapper>
        <FieldWrapper htmlFor='method' label='Method' error={formState.errors.method} isRequired={true}>
          <ControlledSelect
            control={control}
            isMulti={false}
            name='method'
            options={methodOptions}
            portal={true}
            rules={{ required: 'This field is required' }}
          />
        </FieldWrapper>
        <FieldWrapper
          error={formState.errors.authorizationType}
          htmlFor='authorizationType'
          isRequired={true}
          label='Authorization Type'
        >
          <ControlledSelect
            control={control}
            isMulti={false}
            name='authorizationType'
            options={authorizationTypeOptions}
            portal={true}
            rules={{ required: 'This field is required' }}
          />
        </FieldWrapper>
        {watchAuthorizationField && watchAuthorizationField.value === 'Bearer' && (
          <FieldWrapper
            error={
              formState.errors.bearerToken && { message: 'Bearer Token is required for Authorization Type Bearer.' }
            }
            htmlFor='bearerToken'
          >
            <Input
              label='Bearer Token'
              prefixIcon='keyhole'
              type='password'
              {...register('bearerToken', {
                validate: value => validatePassword(value),
              })}
            />
          </FieldWrapper>
        )}
        {watchAuthorizationField && watchAuthorizationField.value === 'Basic' && (
          <Fragment>
            <FieldWrapper htmlFor='userName' error={formState.errors.userName}>
              <Input
                {...register('userName', { required: 'User Name is required for Authorization Type Basic.' })}
                label='User Name'
              />
            </FieldWrapper>
            <FieldWrapper
              error={formState.errors.password && { message: 'Password is required for Authorization Type Basic' }}
              htmlFor='password'
            >
              <Input
                label='Password'
                prefixIcon='keyhole'
                type='password'
                {...register('password', {
                  validate: value => validatePassword(value),
                })}
              />
            </FieldWrapper>
          </Fragment>
        )}
        <FieldWrapper htmlFor='isActive' label='Is Active' align='right'>
          <ControlledSwitch control={control} name='isActive' />
        </FieldWrapper>
        <Button align='right' htmlType='submit' type='primary'>
          {isEditing ? 'Save' : 'Create'} Integration
        </Button>
      </form>
    </div>
  )
}

IntegrationFormModal.defaultProps = {
  editId: undefined,
  editValues: undefined,
}

IntegrationFormModal.propTypes = {
  removeModal: PropTypes.func.isRequired,
  handleReFetch: PropTypes.func.isRequired,
  editId: PropTypes.number,
  editValues: PropTypes.shape({
    authorizationType: PropTypes.string,
    endpoint: PropTypes.string,
    integrationType: PropTypes.string,
    isActive: PropTypes.bool,
    method: PropTypes.string,
    title: PropTypes.string,
    transport: PropTypes.string,
  }),
}

export default IntegrationFormModal
