import icon from 'images/process_explore/decision-icon.svg'

export default ({ nodeEnter }) => {
  nodeEnter
    .append('svg:image')
    .attr('xlink:href', icon)
    .attr('width', 25)
    .attr('height', 25)
    .attr('x', 19)
    .attr('y', 13)

  const textPath = nodeEnter
    .append('g')
    .attr('id', d => `dlabel${d.data.id}`)
    .attr('class', 'dLabel')
    .attr('width', 15)
    .attr('height', 15)
    .attr('transform', 'translate(2,59)')

  const fo = textPath.append('foreignObject')

  const div = fo.append('xhtml:div').attr('class', 'dynamicPathLabel center')

  div
    .append('xhtml:div')
    .attr('class', 'content')
    .html(d => d.labelNum || '?')
}
