import React from 'react'
import { Button, Empty, Icon, useFela, Group, Typography } from '@fortressiq/fiq-ds'
import ApplicationIconCell from 'app/applications/ApplicationIconCell'

import TrinityTable from 'components/TrinityTable'

import ReviewHeader from './ReviewHeader'

import ReviewSidebar from './Sidebar'

const { Paragraph, Title } = Typography

const Review = ({
  match: {
    params: { observerID },
  },
}) => {
  const { css, theme } = useFela()

  const params = observerID ? { observer_id: observerID } : undefined

  const columns = [
    {
      Header: 'Application',
      accessor: 'display_name',
      disableSortBy: true,
      Cell: ({
        value: displayName,
        row: {
          original: { application_id: applicationId, icon_url: iconUrl, application_uuid: applicationUUID },
        },
      }) => (
        <Group>
          <ApplicationIconCell
            applicationId={applicationId}
            imageUrl={iconUrl}
            displayName={displayName || 'NULL'}
            applicationUUID={applicationUUID}
          />
          <span>{displayName}</span>
        </Group>
      ),
    },
    {
      Header: 'Total Events Recorded',
      accessor: 'total_count',
      align: 'center',
      disableSortBy: true,
      width: 100,
    },
    {
      Header: 'Events Reviewed',
      accessor: 'reviewed_count',
      align: 'center',
      disableSortBy: true,
      width: 80,
      Cell: ({
        value: reviewedCount,
        row: {
          original: {
            application_review_id: applicationReviewId,
            event_annotations_reviewed: eventAnnotationsReviewed,
          },
        },
      }) => {
        if (eventAnnotationsReviewed && eventAnnotationsReviewed.length !== 0)
          return (
            <Button href={`/review/${observerID}/${applicationReviewId}/${eventAnnotationsReviewed[0]}`}>
              {reviewedCount}
            </Button>
          )
        return <Paragraph>0</Paragraph>
      },
    },
    {
      Header: 'Action',
      accessor: 'application_review_id',
      disableSortBy: true,
      align: 'center',
      width: 90,
      Cell: ({
        value: applicationReviewId,
        row: {
          original: { application_id: applicationId, event_annotations_not_reviewed: eventAnnotationsNotReviewed },
        },
      }) => {
        let link

        if (!applicationReviewId) {
          link = `/review/${observerID}/new?appID=${applicationId}`
        } else if (eventAnnotationsNotReviewed[0]) {
          link = `/review/${observerID}/${applicationReviewId}/${eventAnnotationsNotReviewed[0]}`
        } else {
          return <Icon icon='colorcheck' />
        }
        return (
          <Button href={link} type='default'>
            Review Images
          </Button>
        )
      },
    },
  ]

  return (
    <div className={css({ display: 'flex', flex: '1', height: 'calc(100vh - 60px)' })}>
      <ReviewHeader currentObserver={observerID} title='Data Review' />
      <div
        className={css({
          borderRight: `1px solid ${theme['legacy-product-gray-3']}`,
          flex: '1',
          marginRight: theme['default-spacer-xs'],
        })}
      >
        {params ? (
          <TrinityTable
            tableId='application-review-table'
            url='/application_reviews'
            dataKey='applications'
            params={params}
            columns={columns}
          />
        ) : (
          <Empty
            style={{ paddingTop: theme['default-spacer-lg'] }}
            customImage={<Icon icon='alerttriangle' size='medium' />}
            description='No observer selected'
          />
        )}
      </div>
      <ReviewSidebar>
        <small>GUIDE</small>
        <Title level={3}>Select an Application to Evaluate Screens</Title>
        <Paragraph>
          Each application you use is comprised of a set of screens: the unique pages on which different kinds of work
          take place.
        </Paragraph>

        <Paragraph>
          One of the most important parts of modeling a process is understanding what separates one screen from another.
          Process Discovery uses a few metrics to define a screen, but the words on that screen are often the most
          accurate and unique characteristic.
        </Paragraph>

        <Paragraph>
          Select an application at left to review a small set of sample screenshots. In each, we&apos;ll ask you if the
          selection of words Process Discovery has made is the best choice for defining what that screen is. If
          it&apos;s incorrect, you&apos;ll be able to select a better option on the screenshot itself.
        </Paragraph>
      </ReviewSidebar>
    </div>
  )
}

export default Review
