import { theme } from '@fortressiq/fiq-ds'

const cardsCSS = {
  boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)',
}

export const dashWrapperCSS = () => ({
  background: theme['typography-code-color'],
  boxShadow: `0 0 0 ${theme['default-spacer-sm']} ${theme['typography-code-color']}`,
  display: 'grid',
  gridGap: theme['default-spacer-md'],
  gridTemplateColumns: '30% 1fr',
  gridTemplateRows: 'minmax(32px, auto) 110px 1fr',
  height: '100%',
  minHeight: '300px',
  overflow: 'auto',
  padding: theme['default-spacer-md'],
  width: '100%',
})

export const controlsWrapper = () => ({
  alignItems: 'end',
  gridColumn: '1 / span 2',
  gridRow: '1 / 1',
})

export const countsBox = () => ({
  gridColumn: '1 / 1',
  gridRow: '2 / 2',
  display: 'flex',
  justifyContent: 'space-between',
})

export const countBox = ({ cardColor }) => ({
  backgroundColor: cardColor,
  borderRadius: theme['default-spacer-xs'],
  color: theme.white,
  overflow: 'hidden',
  padding: theme['default-spacer-md'],
  position: 'relative',
  width: '49%',
  ...cardsCSS,
})

export const countBoxLoadContainer = () => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%',
})

export const chartWrapperCSS = () => ({
  gridColumn: '2 / 2',
  gridRow: '2 / span 2',
  overflow: 'hidden',
  ...cardsCSS,
})

export const tableWrapperCSS = () => ({
  gridColumn: '1 / 1',
  gridRow: '3 / 3',
  minHeight: '0',
})

export const tableCard = () => ({
  overflow: 'auto',
  maxHeight: '100%',
  width: '100%',

  ...cardsCSS,

  '& > div:first-child': {
    minHeight: 'initial !important',
  },
})

export const statTitleCSS = () => ({
  fontWeight: '600',
  display: 'block',
  position: 'absolute',
  bottom: theme['default-spacer-sm'],
  left: theme['default-spacer-md'],
  zIndex: 1,
})

export const statValueCSS = () => ({
  color: theme['gray-tint-0'],
  display: 'inline-block',
  fontSize: '40px',
  fontWeight: '100',
  paddingBottom: theme['default-spacer-md'],
  width: '85%',
})

export const statBackIconCSS = () => ({
  position: 'absolute',
  right: '-28px',
  bottom: '-18px',
  margin: `0 ${theme['default-spacer-xs']}`,
  zIndex: '0',
})
