import React, { useEffect, useState } from 'react'
import { AdvancedCell, DateCell, Element } from '@fortressiq/fiq-ds'

import TrinityTable, { columnWidths } from 'components/TrinityTable'
import { useQueryParams, JsonParam } from 'use-query-params'

import { useHeaderDispatch } from 'app/header/HeaderContext'
import { Filter } from 'app/mining/tableComponents/MiningRunDetails'
import EditableCell from 'components/TrinityTable/EditableCell'
import api from 'lib/Api'
import { useFetch } from 'lib/hooks'
import ActionsMenu from './ActionsMenu'
import CycleFormModal from './FormModal'
import { filterWrapCSS } from './styles'

const sortKeyMap = {
  exportType: 'export_type',
  createdAt: 'created_at',
  updatedAt: 'updated_at',
}

const Cycles = () => {
  const [showCycleFormModal, setShowCycleFormModal] = useState(false)
  const [clonedCycle, setClonedCycle] = useState()
  const [reFetch, setReFetch] = useState(0)

  const [editId, setEditId] = useState()

  const [filterParams] = useQueryParams({
    globalFilter: JsonParam,
  })

  const { data: { integrations = [] } = {}, isLoadingIntegrations } = useFetch('/dynamic_integrations/cycle/list')

  const headerDispatch = useHeaderDispatch()

  const handleClone = cycle => {
    setClonedCycle(cycle)
    setShowCycleFormModal(true)
  }

  const handleEdit = cycle => {
    setClonedCycle(cycle)
    setEditId(cycle.id)
    setShowCycleFormModal(true)
  }

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      align: 'left',
      width: columnWidths.id,
    },
    {
      Header: 'Name',
      accessor: 'name',
      align: 'left',
      width: 150,
      Cell: ({ value }) => <AdvancedCell>{value || '-'}</AdvancedCell>,
    },
    {
      Header: 'Last Modified',
      accessor: 'updatedAt',
      align: 'left',
      width: columnWidths.date,

      Cell: ({ value }) => <DateCell timestamp={value} />,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      align: 'left',
      width: columnWidths.date,
      Cell: ({ value }) => <DateCell timestamp={value} />,
    },
    {
      Header: 'Permitted Users',
      accessor: 'cycleUsers',
      align: 'left',
      disableSortBy: true,
      Cell: ({ value }) => value.reduce((a, b) => `${a}${['', ', '][+!!a.length]}${b.name}`, ''),
    },
    {
      Header: 'Created By',
      accessor: 'createdByFirstName',
      align: 'left',
      disableSortBy: true,
      Cell: ({
        value,
        row: {
          original: { createdByLastName },
        },
      }) => `${value} ${createdByLastName}`,
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      align: 'left',
      disableSortBy: true,
      Cell: ({
        value,
        row: {
          index,
          original: { id },
        },
        column: { id: columnId },
        updateCellData,
      }) => (
        <EditableCell
          type='comment'
          value={value}
          id={id}
          columnId={columnId}
          rowIndex={index}
          updateCellData={updateCellData}
          updateData={newValue =>
            api.post(`/cycles/${id}/notes`, {
              notes: newValue,
            })
          }
        />
      ),
    },
    {
      Header: 'Details',
      accessor: 'filterRules',
      disableSortBy: true,
      minWidth: 300,
      Cell: ({
        value,
        row: {
          original: { startSignatures, endSignatures },
        },
      }) => {
        if (!value) return null
        const extraLines = [
          ...(startSignatures.length !== 0
            ? [{ title: 'Start Screen_signature', value: startSignatures.join(', ') }]
            : []),
          ...(endSignatures.length !== 0 ? [{ title: 'End Screen_signature', value: endSignatures.join(', ') }] : []),
        ].filter(Boolean)
        return (
          <Element style={filterWrapCSS}>
            <Filter filterRules={value} extraLines={extraLines} />
          </Element>
        )
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      align: 'center',
      disableSortBy: true,
      width: columnWidths.actionMenu,
      Cell: ({ row: { original } }) => (
        <ActionsMenu
          reFetch={() => setReFetch(reFetch + 1)}
          cycle={original}
          handleEdit={handleEdit}
          handleClone={handleClone}
          integrations={integrations}
          isLoadingIntegrations={isLoadingIntegrations}
        />
      ),
    },
  ]

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: 'Cycles',
      heading: 'Cycles',
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  return (
    <TrinityTable
      toolbarActions={[
        <CycleFormModal
          key='cycle-modal-button'
          reloadCycles={() => setReFetch(reFetch + 1)}
          showCycleFormModal={showCycleFormModal}
          setShowCycleFormModal={setShowCycleFormModal}
          clonedCycle={clonedCycle}
          setClonedCycle={setClonedCycle}
          editId={editId}
          setEditId={setEditId}
        />,
      ]}
      tableId='cycles-table'
      sortKeyMap={sortKeyMap}
      columns={columns}
      reFetch={reFetch}
      url='/cycles'
      dataKey='cycles'
      params={
        filterParams?.globalFilter?.cycleIds ? { filters: { cycleIds: filterParams.globalFilter.cycleIds } } : undefined
      }
    />
  )
}

export default Cycles
