import React from 'react'
import { arrayOf, bool, number, func, shape, string } from 'prop-types'
import { Input, TextArea, theme } from '@fortressiq/fiq-ds'

import FieldWrapper from 'components/form/FieldWrapper'
import ControlledSelect from 'components/form/ControlledSelect'

const selectStyles = {
  borderRadius: theme['default-spacer-xs'],
  border: `1px solid ${theme['text-vlight']}`,
  height: '32px',
  padding: theme['default-spacer-xs'],
  width: '100%',
}

const inputWrapperStyles = { margin: `0 0 ${theme['default-spacer-md']} 0` }

const Question = ({
  label,
  attribute,
  type,
  options,
  trigger,
  onChangeFormTrigger,
  pageIndex,
  register,
  control,
  formState,
  specName,
}) => {
  const onChangeWithTrigger = value => {
    if (trigger) onChangeFormTrigger(pageIndex, `${attribute}_${value}`)
  }

  const renderField = () => {
    switch (type) {
      case 'select':
        return (
          <ControlledSelect
            control={control}
            isMulti={false}
            name={attribute}
            options={options}
            portal={true}
            onChangeCallback={onChangeWithTrigger}
            constructSelectedValue={true}
            rules={{ required: 'This field is required' }}
          />
        )
      case 'textarea':
        return <TextArea name={attribute} {...register(attribute, { required: 'This field is required.' })} />
      case 'file':
        return (
          <div
            style={{
              fontStyle: 'italic',
              position: 'relative',
              top: `-${theme['default-spacer-xs']}`,
              cursor: 'not-allowed',
            }}
          >{`${specName}.pdf`}</div>
        )
      default:
        return <Input name={attribute} type={type} {...register(attribute, { required: 'This field is required.' })} />
    }
  }

  return (
    <div style={inputWrapperStyles}>
      <FieldWrapper htmlFor={attribute} label={label} error={formState.errors[attribute]}>
        {renderField()}
      </FieldWrapper>
    </div>
  )
}

Question.propTypes = {
  label: string,
  attribute: string,
  type: string,
  options: arrayOf(
    shape({
      label: string,
      value: string,
    })
  ),
  trigger: bool,
  onChangeFormTrigger: func,
  pageIndex: number,
  specName: string,
}

const FormPage = ({
  pageIndex,
  page,
  switchVisibility,
  onChangeFormTrigger,
  register,
  control,
  title,
  formState,
  specName,
}) => {
  return (
    <React.Fragment>
      {title && <div style={{ fontSize: '13px', marginBottom: theme['default-spacer-md'], width: '90%' }}>{title}</div>}
      {page.map((questionProps, i) => (
        <Question
          key={`question_${questionProps.label}`}
          {...questionProps}
          pageIndex={pageIndex}
          onChangeFormTrigger={onChangeFormTrigger}
          register={register}
          control={control}
          formState={formState}
          specName={specName}
        />
      ))}
    </React.Fragment>
  )
}

FormPage.propTypes = {
  page: arrayOf(
    shape({
      label: string,
      attribute: string,
      operator: string,
      value: string,
      type: string,
    })
  ),
  pageIndex: number,
  switchVisibility: func,
  onChangeFormTrigger: func,
  title: string,
  specName: string,
}

export default FormPage
