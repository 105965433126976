import React, { Fragment } from 'react'
import { graphColorNames, Input, Select } from '@fortressiq/fiq-ds'

import store from '../../stores/processExplorerStore'

const GroupForm = () => {
  const { activeGroup } = store.groups
  const { baseColor, baseColorName, groupName } = activeGroup

  const selectOptions = graphColorNames.map(({ hex, name }) => ({ label: name, value: hex }))
  const colorValue = { label: baseColorName, value: baseColor }

  return (
    <Fragment>
      <Input
        placeholder='Group Name'
        value={groupName}
        onChange={store.groups.changeActiveGroupName}
        tabIndex={0}
        onMouseDown={e => e.stopPropagation()}
        style={{ width: '250px' }}
      />

      <Select
        onChange={e => {
          store.groups.changeActiveGroupColor(e)
        }}
        options={selectOptions}
        placeholder='Group Color'
        style={{ width: '170px' }}
        value={colorValue}
      />
    </Fragment>
  )
}

export default GroupForm
