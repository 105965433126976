import React, { Fragment, useRef, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Overlay,
  Element,
  get,
  Heading,
  JSONEditor,
  Icon,
  Popover,
  theme,
  Typography,
} from '@fortressiq/fiq-ds'
import { useClickAway } from 'react-use'
import { useHistory } from 'react-router-dom'

import localizer from 'lib/Localizer'

import { cloneStrategy, strategyOptionsMap } from '../utilities'

import { appHeadingCSS } from '../styles'

import {
  buttonGroupStyle,
  defaultBorder,
  defaultMargin,
  defaultPadding,
  historyDropdownContainerMargin,
  historyDropdownContainerStyle,
  historyOptionStyle,
  textContainerStyle,
} from './styles'

const { Text } = Typography

const PROMOTION_ABILITY_ENUM = { done: 0, error: 1, pending: 2 }

const History = ({ appId, cycleId, formatAndSetParams, handlePromoteClick, modalIsOpen, signatureServiceJobsList }) => {
  const history = useHistory()
  const ref = useRef(null)

  const signatureServiceJobs = !signatureServiceJobsList
    ? undefined
    : {
        jobs: signatureServiceJobsList,
        options: Object.entries(signatureServiceJobsList).map(job => {
          const { createdAt, id, params, status, strategySlug, jobType } = job[1]

          return {
            createdAt,
            label: `id: ${id} - ${strategyOptionsMap[strategySlug]}`,
            params,
            status,
            strategySlug,
            jobId: id,
            jobType,
          }
        }),
      }

  const isDisabled = signatureServiceJobs?.options?.length === 0
  const [showing, setShowing] = useState(false)
  useClickAway(ref, () => setShowing(modalIsOpen))

  // Overlay setup:
  const config = {
    content: {
      style: {
        border: `1px solid ${theme['border-color-base']}`,
        position: 'absolute',
      },
    },
    disableOutsideClick: true,
    trigger: { button: { disabled: isDisabled, size: 'small' } },
  }
  const placement = {
    overflow: {
      adjustX: true,
      adjustY: true,
    },
    points: ['tl', 'bl'],
    targetOffset: [0, -get.numericValues('spacer').sm],
  }
  const trigger = {
    dropdown: true,
    showing: showing,
    setShowing: setShowing,
    text: 'Select Strategy from History...',
  }

  const selectChange = e => {
    formatAndSetParams(e.strategySlug, JSON.parse(e.params))
    setShowing(false)
  }

  const firstPromotableJob = signatureServiceJobs?.options.findIndex(({ jobType }) => jobType === 'provisional')

  const onCreate = async jobId => {
    await cloneStrategy(jobId, appId, cycleId)
    history.push('/signature-service-jobs')
  }
  const promoteBtnProps = {
    mouseEnterDelay: 3000,
    placement: 'top-center',
  }

  return (
    <Fragment>
      <Heading level={4} style={appHeadingCSS({ isDisabled })}>
        History
      </Heading>
      <Overlay
        basic={false}
        closeIcon={false}
        closeOnScroll={true}
        config={config}
        content={
          <Element margin={historyDropdownContainerMargin} ref={ref} style={historyDropdownContainerStyle}>
            {signatureServiceJobs?.options.map(({ createdAt, params, status, strategySlug, jobId, jobType }, i) => {
              const canPromote = i === firstPromotableJob
              const promotionAbility = PROMOTION_ABILITY_ENUM[status]
              const showIcon = Object.keys(params).length > 2
              const stratID = `strat-${i}`

              const promoteTooltipProps = (promotionAbility === PROMOTION_ABILITY_ENUM.pending ||
                promotionAbility === PROMOTION_ABILITY_ENUM.error) && {
                ...promoteBtnProps,
                title:
                  promotionAbility === PROMOTION_ABILITY_ENUM.pending
                    ? 'Please wait for this to finish before promoting.'
                    : 'Cannot promote.',
              }

              return (
                <Element
                  border={defaultBorder}
                  id={stratID}
                  key={stratID}
                  margin={defaultMargin}
                  padding={defaultPadding}
                  style={historyOptionStyle}
                >
                  <Element style={textContainerStyle}>
                    <Text strong={true} uppercase={true}>
                      {jobId} - {strategyOptionsMap[strategySlug]}
                    </Text>
                    {!!params && showIcon && getPopoverDetails(params, stratID)}
                  </Element>
                  <Fragment>
                    {createdAt && localizer.asShortestDateTime(createdAt)} | {jobType} | {status}
                  </Fragment>

                  <ButtonGroup size='small' style={buttonGroupStyle}>
                    {canPromote && (
                      <Button
                        disabled={promotionAbility !== PROMOTION_ABILITY_ENUM.done}
                        loading={promotionAbility === PROMOTION_ABILITY_ENUM.pending}
                        onClick={handlePromoteClick}
                        tooltipProps={promoteTooltipProps}
                        type='primary'
                      >
                        {promotionAbility === PROMOTION_ABILITY_ENUM.done && 'Promote'}
                        {promotionAbility === PROMOTION_ABILITY_ENUM.pending && 'Pending...'}
                        {promotionAbility === PROMOTION_ABILITY_ENUM.error && 'Error'}
                      </Button>
                    )}
                    <Button onClick={() => onCreate(jobId)} type='secondary'>
                      Create
                    </Button>
                    <Button onClick={() => selectChange({ params, strategySlug })} type='default'>
                      Edit
                    </Button>
                  </ButtonGroup>
                </Element>
              )
            })}
          </Element>
        }
        disabled={isDisabled}
        id='application-history-menu'
        noPadding={true}
        placement={placement}
        scrollable={false}
        trigger={trigger}
      />
    </Fragment>
  )
}

History.propTypes = {}

export default History

const getPopoverDetails = (params, stratID) => (
  <Popover
    content={<JSONEditor disabled={true} src={JSON.parse(params)} style={{ maxHeight: '500px', overflow: 'auto' }} />}
    customLayerProps={{ container: stratID }}
    placement='right-start'
    styles={{ container: { maxWidth: '50vw' } }}
    title='Strategy Parameters'
    trigger='click'
  >
    <Icon
      color={theme['text-dark']}
      fill={theme.white}
      icon='info'
      size={get.numericValues('spacer').lg - get.numericValues('spacer').sm}
      stroke={theme['ui-line-default']}
      style={{ cursor: 'help', placeSelf: 'flex-start' }}
    />
  </Popover>
)
