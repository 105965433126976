import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { defaultModalConfig, Menu, MenuItem, Typography, useModal } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import handleIntegration from 'app/admin/integrations/helpers/handleIntegration'

import ExportMenuItem from 'components/ExportButton/ExportMenuItem'
import ActionMenu from 'components/TrinityTable/ActionMenu/ActionMenu'
import callbackStore from 'stores/callback'

const vizBaseUrl = {
  butterfly: '/visualizations/butterfly',
  path: '/process-explorer',
  flowgraph: '/flow-graph',
}

const ActionsMenu = ({ graphId, processName, exportIds, reFetch, graphType, isLoadingIntegrations, integrations }) => {
  const { addModal } = useModal()

  const handleDeleteProcess = async (id, type) => {
    if (type === 'flowgraph') {
      await api.delete(`/df_graph/${id}`)
    } else {
      await api.delete(`/graph_processes/${id}`)
    }

    reFetch()
  }

  const deleteModalID = 'action-menu-delete-modal'
  const modalOptions = {
    children: (
      <Fragment>
        Are you sure you want to delete this process:
        <br />
        <Typography.Text strong={true}>{processName || 'no name'}</Typography.Text>
      </Fragment>
    ),
    config: {
      ...defaultModalConfig,
      okButton: { ...defaultModalConfig?.okButton, children: 'Delete', type: 'danger' },
    },
    header: 'Delete Process?',
    id: deleteModalID,
    onOk: () => handleDeleteProcess(graphId, graphType),
    style: { width: '400px' },
  }

  const exportArgs = {
    graphId,
    name: processName,
    exportType: graphType === 'flowgraph' ? 'flowgraph_process' : 'graph_process',
    graphType,
  }

  const exportURLParams = exportIds.map(exportId => `ids=${exportId}`).join('&')
  const exportUrlString = `/event-log-exports?${exportURLParams}`
  const existingExportUrl = !!exportIds.length && exportUrlString

  const menu = (
    <Menu bordered={false} dark={true}>
      {graphType !== 'butterfly' && (
        <MenuItem key='link-details'>
          <Link to={`/processes/${graphId}${graphType === 'flowgraph' ? '?type=flowgraph' : ''}`}>View Details</Link>
        </MenuItem>
      )}
      <MenuItem key='link-visualize'>
        <Link to={`${vizBaseUrl[graphType]}/${graphId}`}>Visualize Process</Link>
      </MenuItem>
      <MenuItem key='link-instances-viewer'>
        <Link to={`/instances-viewer/${graphId}?graphType=${graphType}`}>View Instances</Link>
      </MenuItem>
      {graphType !== 'butterfly' && (
        <Fragment>
          <ExportMenuItem
            callbackStore={callbackStore}
            itemText='Export Process as .csv'
            exportType='graph'
            exportArgs={exportArgs}
          />
          {existingExportUrl && (
            <MenuItem key='link-view-exports'>
              <Link to={existingExportUrl}>View Exports ({exportIds?.length})</Link>
            </MenuItem>
          )}
        </Fragment>
      )}
      {graphType !== 'butterfly' && (
        <MenuItem key='link-delete-process' onClick={() => addModal(modalOptions)}>
          Delete
        </MenuItem>
      )}
      {!isLoadingIntegrations &&
        integrations.map(integration => (
          <MenuItem
            key={`integration-${integration.id}`}
            onClick={() => handleIntegration({ integrationId: integration.id, objectId: graphId })}
          >
            {integration.title}
          </MenuItem>
        ))}
    </Menu>
  )

  return <ActionMenu menu={menu} />
}

ActionsMenu.defaultProps = {
  isLoadingIntegrations: undefined,
  integrations: undefined,
  exportIds: undefined,
}

ActionsMenu.propTypes = {
  graphId: PropTypes.number.isRequired,
  processName: PropTypes.string.isRequired,
  exportIds: PropTypes.arrayOf(PropTypes.number),
  graphType: PropTypes.string.isRequired,
  reFetch: PropTypes.func.isRequired,
  isLoadingIntegrations: PropTypes.bool,
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ),
}

export default ActionsMenu
