import React, { useEffect, useState, useRef } from 'react'

import { DateCell, Button, Typography, Group } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

import getMiningParamsReport from 'lib/getMiningParamsReport'
import getMiningParamsPath from 'lib/getMiningParamsPath'
import getMiningParamsButterfly from 'lib/getMiningParamsButterfly'
import getMiningParamsFlowGraph from 'lib/getMiningParamsFlowGraph'
import graphTypeDisplayNameMap from 'lib/graphTypeDisplayNameMap'

import TrinityTable, { columnWidths } from 'components/TrinityTable'
import FavoriteToggle from 'components/FavoriteToggle/FavoriteToggle'
import StatusBadge from 'components/StatusBadge/StatusBadge'
import Tags from 'components/tags/Tags'

import EditableCell from 'components/TrinityTable/EditableCell'
import callbackStore from 'stores/callback'

import { useFetch, useApplicationsMap } from 'lib/hooks'

import { useHeaderDispatch } from '../header/HeaderContext'

import MiningRunModal from './form/MiningRunModal'

import ProcessesTable from './ProcessesTable'
import ActionsMenu from './tableComponents/ActionsMenu'
import MiningRunDetails from './tableComponents/MiningRunDetails'

const { Paragraph } = Typography

const sortKeyMap = {
  id: 'mining.mined_runs.id',
  createdAt: 'mining.mined_runs.created_at',
  status: 'mining.mined_runs.status',
}

// make an object with key and default value from report and advanced miningParams arrays
// used in details column to display mining params that are not equal to default value

const getMiningDefaultMap = params => {
  return params.reduce((obj, item) => {
    return {
      ...obj,
      [item.key]: { value: item.default, label: item.label },
    }
  }, {})
}

export const miningParamsDefaults = {
  default: getMiningDefaultMap(getMiningParamsPath()),
  report: getMiningDefaultMap(getMiningParamsReport()),
  butterfly: getMiningDefaultMap(getMiningParamsButterfly()),
  flowgraph: getMiningDefaultMap(getMiningParamsFlowGraph()),
}

const callbackId = 'graphProcessImportSuccess'

const MiningRunList = () => {
  const [reFetch, setReFetch] = useState(0)
  const [showFormModal, setShowFormModal] = useState(false)
  const [clonedMiningRun, setClonedMiningRun] = useState()

  const { data: { integrations = [] } = {}, isLoadingIntegrations } = useFetch('/dynamic_integrations/mining_run/list')
  const { applicationsMap, isLoading: isLoadingApplicationsMap } = useApplicationsMap()

  const renderRowSubComponent = ({ row }) => (
    <ProcessesTable miningRunId={row.original.id} reFetchMiningRuns={reFetch} setReFetchMiningRuns={setReFetch} />
  )

  const handleRefetch = () => {
    setReFetch(reFetch + 1)
  }

  const headerDispatch = useHeaderDispatch()

  const tableRef = useRef(null)

  useEffect(() => {
    const onImportProgress = ({ miningRunId: runId, processCount, status }) => {
      tableRef.current.updateRowData('id', runId, { status: status, graphProcessCount: processCount })
    }
    callbackStore.add(onImportProgress, callbackId, true)
    return () => {
      callbackStore.remove(callbackId)
    }
  }, [])

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: 'Mining Runs',
      heading: 'Mining Runs',
    })
    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 170,
      minWidth: 130,
      Cell: ({
        value: id,
        row: {
          original: { favorited, signatureLevel, miningType },
        },
      }) => (
        <Group>
          <FavoriteToggle key={`favorite-toggle-${id}`} id={id} type='miningRun' favorited={favorited} />
          {id}
          <Paragraph capitalize={true} noMargin={true}>
            {signatureLevel}
          </Paragraph>
          <Paragraph capitalize={true} noMargin={true}>
            {graphTypeDisplayNameMap[miningType]}
          </Paragraph>
        </Group>
      ),
    },
    {
      Header: 'Tags',
      accessor: 'tags',
      disableSortBy: true,
      Cell: ({
        value: tags,
        row: {
          original: { id },
        },
      }) => <Tags key={`tags-${id}`} taggableType='miningRun' taggableId={id} tags={tags} />,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      width: columnWidths.date,
      Cell: ({ value: createdAt }) => <DateCell timestamp={createdAt} />,
    },
    {
      Header: 'Created By',
      accessor: 'createdByFirstName',
      disableSortBy: true,
      width: 140,
      Cell: ({
        value,
        row: {
          original: { createdByLastName },
        },
      }) => `${value} ${createdByLastName}`,
    },
    {
      Header: 'Comment',
      accessor: 'comments',
      disableSortBy: true,
      Cell: ({
        value,
        row: {
          index,
          original: { id },
        },
        column: { id: columnId },
        updateCellData,
      }) => (
        <EditableCell
          type='comment'
          value={value}
          id={id}
          columnId={columnId}
          rowIndex={index}
          updateCellData={updateCellData}
          updateData={newValue =>
            api.post(`/mining_runs/${id}/comments`, {
              comment: newValue,
            })
          }
        />
      ),
    },
    {
      Header: 'Details',
      accessor: 'details',
      disableSortBy: true,
      width: 300,
      Cell: ({ row: { original } }) => (
        <MiningRunDetails
          miningRun={original}
          miningParamsDefaults={miningParamsDefaults[original.miningType]}
          applicationsMap={applicationsMap}
        />
      ),
    },
    {
      Header: 'Processes',
      accessor: 'graphProcessCount',
      disableSortBy: true,
      width: 100,
      Cell: ({
        value,
        row: {
          isExpanded,
          getToggleRowExpandedProps,
          original: { miningType },
        },
      }) => (
        <Group>
          {value !== 0 && (
            <Button size='small' {...getToggleRowExpandedProps()}>
              {isExpanded ? '-' : '+'}
            </Button>
          )}
          {miningType === 'report' ? '-' : value}
        </Group>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value: status }) => <StatusBadge status={status} />,
    },
    {
      align: 'center',
      Header: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      width: 64,
      Cell: ({ row: { original } }) => (
        <ActionsMenu
          key={`action-menu-${original.id}`}
          miningRun={original}
          setShowFormModal={setShowFormModal}
          setClonedMiningRun={setClonedMiningRun}
          reFetch={handleRefetch}
          integrations={integrations}
          isLoadingIntegrations={isLoadingIntegrations}
        />
      ),
    },
  ]

  return (
    <TrinityTable
      toolbarActions={[
        <MiningRunModal
          clonedMiningRun={clonedMiningRun}
          fetchData={handleRefetch}
          key='new-button'
          setClonedMiningRun={setClonedMiningRun}
          setShowFormModal={setShowFormModal}
          showFormModal={showFormModal}
        />,
      ]}
      ref={tableRef}
      key='mining-table'
      columns={columns}
      reFetch={reFetch}
      updateData=''
      url='/mining_runs'
      dataKey='miningRuns'
      sortKeyMap={sortKeyMap}
      renderRowSubComponent={renderRowSubComponent}
      tableId='mining-run-table'
      isDependencyLoading={isLoadingApplicationsMap || isLoadingIntegrations}
      globalFilterOptions={[
        'favorited',
        'miningRunCreatedDates',
        'miningType',
        'miningRunTags',
        'miningRunIds',
        'graphProcessIds',
        'graphProcessNames',
        'applications',
        'createdBy',
      ]}
    />
  )
}

export default MiningRunList
