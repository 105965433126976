/************
 * renders the shape of the node (sits underneath subprocs/groups/node data)
 *
 *************/

import * as d3 from 'd3'
import { theme } from '@fortressiq/fiq-ds'

import store from '../../stores/processExplorerStore'

import { getShape } from '../utils'

const colorRedLight = 'hsl(0, 23.8%, 95.9%)'

export default ({ node, nodeEnter, nodeUpdate, nodeHeight, iconWidth, duration, collapsedHeight }) => {
  const { nodeWidth } = store

  // Add box for the nodes
  nodeEnter
    .append('path')
    .attr('id', d => `node${d.id}`)
    .attr('class', d =>
      d.hiddenChildren
        ? `baseNode node expand node${d.depth} node_id${d.id} ${d.data.type === 'start' && ' startNode'}`
        : `baseNode node node${d.depth}  node_id${d.data.id}  ${d.data.type === 'start' && ' startNode'}`
    )
    .attr('d', d => getShape(d, nodeHeight, collapsedHeight, nodeWidth, iconWidth))
    .attr('r', 1e-6)
    .attr('rx', '3')
    .attr('ry', '3')
    .style('stroke', d => nodeStrokeColor(d))
    .style('fill', d => nodeColor(d))

  // Transition to the proper position for the node
  nodeUpdate
    .transition()
    .duration(duration)
    .attr('transform', d => {
      return `translate(${d.y},${d.x - 25})`
    })

  nodeUpdate
    .selectAll('path.node')
    .attr('r', 10)
    .style('fill', d => nodeColor(d))
    .style('stroke', d => nodeStrokeColor(d))
    .attr('d', d => getShape(d, nodeHeight, collapsedHeight, nodeWidth, iconWidth))
    .attr(
      'class',
      d =>
        `baseNode node node_id${d.data.id} ${d.data.type === 'start' && ' startNode'} ${
          d.hiddenChildren && d.hiddenChildren.length > 0 ? 'expand' : 'retract'
        }`
    )
    .on('mouseenter', (d, index, rects) => {
      d3.select(rects[index]).style('stroke', de => nodeStrokeColor(de))
    })
    .on('mouseout', (d, index, rects) => {
      d3.select(rects[index]).style('stroke', de => nodeStrokeColor(de))
    })

  // Remove any exiting nodes
  const nodeExit = node.exit()

  nodeExit.transition().duration(duration).style('opacity', 0).remove()

  // On exit reduce the node circles size to 0
  nodeExit.select('rect').attr('r', 1e-6)

  // On exit reduce the opacity of text labels
  nodeExit.select('text').style('fill-opacity', 1e-6)
}

const nodeStrokeColor = d => {
  if (d.data.isInSubprocess) return theme['legacy-color-blue-light']
  if (d.data.type === 'start') return theme['legacy-color-green-light']
  if (d.data.type === 'end') return theme['fiq-red']
  if (store.viewingNode && store.viewingNode.id === d.id) return theme['legacy-color-blue-light']

  return theme['legacy-color-gray3']
}

const nodeColor = d => {
  if (d.data.isInSubprocess) return theme['legacy-color-blue-light2']
  if (d.data.type === 'start') return theme['legacy-color-green-light']
  if (d.data.type === 'end') return colorRedLight
  if (store.viewingNode && store.viewingNode.id === d.id) return theme['legacy-color-blue-light2']
  if (d.highlight) return theme['legacy-cscale-10']
  return d.hiddenChildren && d.hiddenChildren.length > 0 ? theme['field-bg-light'] : theme.white
}
