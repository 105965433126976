import React, { Fragment } from 'react'

import Minimap from './Minimap'
import EventGroupNav from './EventGroupNav'
import EventSelection from './EventSelection'
import EventMoveSelector from './EventMoveSelector'
import ScreenViewerSettings from './ScreenViewerSettings'

const Controls = ({
  activeGroupIndex,
  applicationId,
  eventGroupCount,
  eventGroupHeaderText,
  eventGroupIndexes,
  eventGroups,
  getGroupSelectedMeta,
  handleNextActiveGroup,
  handlePrevActiveGroup,
  isMinimapEnabled,
  screen,
  selectedEventGroupIndexes,
  setEventGroups,
  setIsMinimapEnabled,
  setSelectedEventGroupIndexes,
  toggleAllSelectedEvent,
  toggleSelectedRow,
  tenantApplicationId,
}) => {
  const oldScores = [...selectedEventGroupIndexes].map(index => eventGroups[index].provisionalScreenSignatureScore)
  const [{ provisionalScreenSignature: oldSignature } = {}] = eventGroups

  const removeSelectedEventGroups = () => {
    const updatedGroups = eventGroups.reduce((groups, _, index) => {
      groups.splice(index, 1)
      return groups
    }, eventGroups.slice())
    setSelectedEventGroupIndexes(new Set())
    setEventGroups(updatedGroups)
  }

  return (
    <Fragment>
      <Minimap
        {...{
          isMinimapEnabled,
          eventGroupIndexes,
          getGroupSelectedMeta,
          selectedEventGroupIndexes,
        }}
      />
      <EventGroupNav
        {...{
          eventGroupCount,
          activeGroupIndex,
          eventGroupHeaderText,
          onNextGroup: handleNextActiveGroup,
          onPrevGroup: handlePrevActiveGroup,
        }}
      />
      <EventSelection {...{ toggleSelectedRow, toggleAllSelectedEvent }} />
      <EventMoveSelector
        {...{
          screen,
          applicationId,
          oldScores,
          oldSignature,
          eventGroupCount,
          removeSelectedEventGroups,
          tenantApplicationId,
        }}
      />
      <ScreenViewerSettings {...{ isMinimapEnabled, setIsMinimapEnabled }} />
    </Fragment>
  )
}

export default Controls
