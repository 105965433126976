import React from 'react'
import { observer } from 'mobx-react'

import DashRow from './DashRow'
import CalendarCard from '../cards/CalendarCard'

@observer
class CalendarStats extends React.Component {
  render() {
    const { openModal, calendar } = this.props

    return (
      <DashRow title='Calendar' type='Events' openModal={openModal}>
        <CalendarCard data={calendar} />
      </DashRow>
    )
  }
}

export default CalendarStats
