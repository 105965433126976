import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Element, Icon, Input, Label, theme } from '@fortressiq/fiq-ds'

import useInputValidation from './validationHooks'

const ProcessModal = ({ instances, saveProcess, removeInstance }) => {
  /* Validator configuration */
  const validateInputName = useInputValidation(true)
  /* Currently: errorMsg for on real time validation (flag false), errorMessageSubmit when submitting */
  const { inputValue, onChangeInputValue, inputIsValid, errorMessageSubmit } = validateInputName
  const validationError = errorMessageSubmit

  let instancesList = null
  let disableSaveButton = true
  const instanceDefErr = 'Please, add an Instance to process!'

  const onSubmitHandler = e => {
    e.preventDefault()

    validateInputName.onSubmitForm()

    if (inputIsValid && instances.length) saveProcess(inputValue)
  }

  const validationErrorCSS = {
    color: theme['error-color'],
    fontWeight: 'bold',
  }

  if (instances.length) {
    instancesList = instances.map((instance, i) => {
      const { events, label } = instance

      return (
        <Element key={i} style={{ display: 'flex', width: '100%' }}>
          {!!label && <Element style={{ justifySelf: 'flex-start', width: '50%' }}>{label}</Element>}
          <Element
            style={{
              ...(!label && { marginLeft: '50%' }),
              justifySelf: 'flex-end',
              width: !label ? '100%' : '50%',
            }}
          >
            {events.length} Events &nbsp;
            <Icon after={true} clickable={true} icon='remove' onClick={() => removeInstance(instance)} size='nested' />
          </Element>
        </Element>
      )
    })

    // To replace when we have a global validation
    // We set the bool for enabling/disabling save btn
    if (inputIsValid) disableSaveButton = false
  } else {
    instancesList = <Element style={validationErrorCSS}>{instanceDefErr}</Element>
  }

  return (
    <Fragment>
      <p>
        <Input
          autoFocus={true}
          label='Name'
          name='name'
          onChange={onChangeInputValue}
          placeholder='Name the Process'
          tabIndex={0}
          value={inputValue}
        />
      </p>
      <Element style={validationErrorCSS}>{validationError}</Element>
      <Element style={{ padding: '0.5rem', width: '50%' }}>
        <Label>Attached Instances ({instances.length})</Label>
        <Element>{instancesList}</Element>
      </Element>
      <Element style={{ textAlign: 'right' }}>
        <Button disabled={disableSaveButton} onClick={e => onSubmitHandler(e)} type='secondary'>
          Save Process
        </Button>
      </Element>
    </Fragment>
  )
}

ProcessModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  instances: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeInstance: PropTypes.func.isRequired,
  saveProcess: PropTypes.func.isRequired,
}

export default ProcessModal
