import React from 'react'
import { shallow } from 'enzyme'
import { UserProvider } from 'context/UserContext'
import { HeaderProvider } from '../../../header/HeaderContext'

import TenantCreator from '../TenantCreator'

jest.mock('@fortressiq/fiq-ds', () => {
  const mockComponent = props => <div {...props} />

  return {
    useNotification: () => ({
      addNotification: () => {},
    }),
    Typography: { Text: () => {} },
    useForm: () => ({
      register: () => {},
      handleSubmit: () => {},
      control: {},
      formState: {},
      setValue: () => {},
    }),
    Button: mockComponent,
    Col: mockComponent,
    Element: mockComponent,
    Input: mockComponent,
    Row: mockComponent,
  }
})

describe('TenantCreator component', () => {
  beforeEach(() => {})
  it('should render without throwing an error', () => {
    const component = shallow(
      <UserProvider init={{ roleNames: 'superuser' }}>
        <HeaderProvider>
          <TenantCreator />
        </HeaderProvider>
      </UserProvider>
    )
    expect(component).toMatchSnapshot()
    expect(() => component).not.toThrow()
  })
})
