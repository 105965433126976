import React from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem, Popconfirm } from '@fortressiq/fiq-ds'
import { Link } from 'react-router-dom'

import api from 'lib/Api'
import uiStore from 'lib/UiStore'

import handleIntegration from 'app/admin/integrations/helpers/handleIntegration'
import ActionMenu from 'components/TrinityTable/ActionMenu/ActionMenu'

const isLite = uiStore?.tenant?.isLite

const ActionsMenu = ({
  isLoadingIntegrations,
  integrations,
  applicationId,
  reportId,
  processBoundaryStatus,
  handleReFetch,
}) => {
  const processBoundaryStart = async () => {
    await api.post('/process-boundary/start', { application_id: applicationId })
    handleReFetch()
  }

  const menu = (
    <Menu bordered={false} dark={true}>
      {!isLoadingIntegrations &&
        integrations.length !== 0 &&
        integrations.map(integration => (
          <MenuItem
            key={`integration-${integration.id}`}
            onClick={() => handleIntegration({ integrationId: integration.id, objectId: applicationId })}
          >
            {integration.title}
          </MenuItem>
        ))}
      {!isLite &&
        (reportId ? (
          <MenuItem onClick={e => e.stopPropagation()}>
            <Popconfirm
              cancelText='No'
              okText='Yes'
              onConfirm={processBoundaryStart}
              placement='left-center'
              dark={true}
              title={
                processBoundaryStatus === 'done'
                  ? 'Start/end suggestions for this application already exist, do you want to replace them?'
                  : 'Start/end suggestions are already being generated, do you want to start over?'
              }
            >
              Re-Generate start/end suggestions
            </Popconfirm>
          </MenuItem>
        ) : (
          <MenuItem onClick={() => processBoundaryStart()}>Generate start/end suggestions</MenuItem>
        ))}
      {!isLite && reportId && processBoundaryStatus === 'done' && (
        <MenuItem>
          <Link to={`/process-boundary/${reportId}`}>View Process Boundary Detection</Link>
        </MenuItem>
      )}
    </Menu>
  )

  let menuEmpty = false
  if (menu.props.children.filter(child => child).length === 0) menuEmpty = true

  const disabled = integrations.length === 0 || menuEmpty

  return <ActionMenu disabled={disabled} menu={menu} />
}

ActionsMenu.defaultProps = {
  isLoadingIntegrations: undefined,
  integrations: undefined,
  applicationId: undefined,
  reportId: undefined,
  processBoundaryStatus: undefined,
}

ActionsMenu.propTypes = {
  isLoadingIntegrations: PropTypes.bool,
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  applicationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reportId: PropTypes.number,
  processBoundaryStatus: PropTypes.string,
  handleReFetch: PropTypes.func.isRequired,
}

export default ActionsMenu
