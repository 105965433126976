import React, { useEffect, useState } from 'react'
import { Button, ImageViewer, useFela } from '@fortressiq/fiq-ds'

import { useUserState } from 'context/UserContext'
import { imageContainerCss } from './styles'

const UnredactedImageViewerWithOption = ({ containerCss, images, ...imageProps }) => {
  const currentUser = useUserState()
  const { canViewUnredactedImages } = currentUser
  const [showingUnredacted, setShowingUnredacted] = useState(false)
  const [imagesToShow, setImagesToShow] = useState(images)
  const [currentImage, setCurrentImage] = useState(images[0])

  const { css } = useFela()

  useEffect(() => {
    setShowingUnredacted(false)
    setImagesToShow(images)
    setCurrentImage(images[0])
  }, [images])

  const currentImageScr = currentImage?.image?.src || currentImage.src

  if (!canViewUnredactedImages || !currentImageScr || currentImageScr?.length === 0)
    return (
      <ImageViewer
        images={imagesToShow}
        {...imageProps}
        getImageData={setCurrentImage}
        fullscreenEnabled={currentImageScr || currentImageScr?.length > 0}
      />
    )
  else
    return (
      <div className={css(containerCss, imageContainerCss)}>
        <ImageViewer images={imagesToShow} {...imageProps} getImageData={setCurrentImage} />
        <Button
          onClick={() => {
            if (showingUnredacted) {
              setImagesToShow(images)
              setShowingUnredacted(false)
            } else {
              setImagesToShow(images.map(image => ({ ...image, src: `${image.src}?unredacted=1` })))
              setShowingUnredacted(true)
            }
          }}
        >
          Show {showingUnredacted ? 'redacted' : 'unredacted'} Image
        </Button>
      </div>
    )
}

export default UnredactedImageViewerWithOption
