import getMiningParamsPath from 'lib/getMiningParamsPath'
import getMiningParamsButterfly from 'lib/getMiningParamsButterfly'
import getMiningParamsReport from 'lib/getMiningParamsReport'
import cloneDeep from 'lodash/cloneDeep'

const getValues = values => values.map(({ value }) => value).join(',')
const getLabels = labels => labels.map(({ label }) => label).join(',')

// Generate query filter from basic mining run
export const generateFilter = ({
  applications,
  applicationRule = 'all',
  observers,
  observerRule = 'all',
  observedAtRange,
} = {}) => {
  return {
    combinator: 'and',
    not: false,
    rules: [
      applications?.length > 0 && {
        field: 'application_name',
        label: applications.constructor === Array ? getLabels(applications) : applications,
        value: applications.constructor === Array ? getValues(applications) : applications,
        operator: applicationRule,
      },
      observers?.length > 0 && {
        field: 'observer_name',
        label: observers.constructor === Array ? getLabels(observers) : observers,
        value: observers.constructor === Array ? getValues(observers) : observers,
        operator: observerRule,
      },
      observedAtRange &&
        observedAtRange[0] && {
          id: 'observered_at_from',
          field: 'observed_at',
          value: observedAtRange[0],
          operator: '>=',
        },
      observedAtRange &&
        observedAtRange[1] && {
          id: 'observered_at_to',
          field: 'observed_at',
          value: observedAtRange[1],
          operator: '<=',
        },
    ].filter(Boolean),
  }
}

const constructSignaturesParams = ({ signatures }) => {
  return signatures.map(signature => ({
    ...signature,
    secondary_signature_level:
      signature.secondary_signature_level === 'none' ? undefined : signature.secondary_signature_level,
  }))
}
const constructSignaturesDefaultValues = ({ signatures }) => {
  return signatures
    ? signatures.map(signature => ({
        ...signature,
        secondary_signature_level: signature.secondary_signature_level || 'none',
      }))
    : []
}

// Generate api params for basic form
export const generateBasicParams = formState => ({
  miningParams: {
    // if no signatures, empty structure required by backend schema
    start_signatures: formState.startSignatures
      ? { signatures: constructSignaturesParams(formState.startSignatures) }
      : {
          signatures: [],
        },
    end_signatures: formState.endSignatures
      ? { signatures: constructSignaturesParams(formState.endSignatures) }
      : {
          signatures: [],
        },
    must_include_signature: {
      condition: 'and',
      signatures: [],
    },
    butterfly_signatures: {
      signatures: [],
    },
    signature_level: formState.signatureLevel,
  },
  filter: generateFilter(formState),
})

// Helper function for converting string to array
const stringParamToArray = paramValue => {
  if (Array.isArray(paramValue)) {
    return paramValue
  }
  if (paramValue === '') {
    return []
  }
  return paramValue.split(',').map(value => value.trim())
}
// Helper function for converting string to number
const stringParamToNumber = paramValue => +paramValue

// Get format for all miningParams
const advancedMiningParamsFormat = [
  ...getMiningParamsPath(),
  ...getMiningParamsButterfly(),
  ...getMiningParamsReport(),
].reduce((o, cur) => {
  if (cur.type === 'number') return { ...o, [cur.key]: stringParamToNumber }
  if (cur.type === 'array') return { ...o, [cur.key]: stringParamToArray }
  return { ...o }
}, {})

// Call formatter for all mining params
const formatMiningParams = miningParams =>
  Object.entries(miningParams).reduce((acc, [key, val]) => {
    const formatter = advancedMiningParamsFormat[key]
    acc[key] = formatter ? formatter(val) : val
    return acc
  }, {})

//Ensures that the observed_at fields contain the correct ids
// bug where older filters rules did not contain this information
const transformFilter = rules => {
  if (!rules) return
  const newRules = cloneDeep(rules)
  newRules?.rules?.forEach(rule => {
    if (rule.field === 'observed_at' && rule.operator === '>=') {
      rule.id = 'observered_at_to'
    }
    if (rule.field === 'observed_at' && rule.operator === '<=') {
      rule.id = 'observered_at_from'
    }
  })
  return newRules
}

// Generate api params for advanced mining form
export const generateAdvancedParams = (formState, mustIncludesSignature) => {
  const { miningParams, filterRules, miningType } = formState
  console.log(mustIncludesSignature)
  return {
    miningParams: {
      ...formatMiningParams(miningParams),
      // if no signatures, empty structure required by backend schema
      start_signatures: miningParams.start_signatures
        ? { signatures: constructSignaturesParams(miningParams.start_signatures) }
        : {
            signatures: [],
          },
      end_signatures: miningParams.end_signatures
        ? { signatures: constructSignaturesParams(miningParams.end_signatures) }
        : {
            signatures: [],
          },
      must_include_signature: mustIncludesSignature,
      butterfly_signatures: miningParams.butterfly_signatures
        ? { signatures: constructSignaturesParams(miningParams.butterfly_signatures) }
        : {
            signatures: [],
          },
    },
    filter: filterRules,
    miningType,
  }
}

// Generate api params for report mining form
export const generateReportParams = formState => {
  const { miningParams, filterRules } = formState
  return {
    miningParams: {
      ...formatMiningParams(miningParams),
    },
    filter: filterRules,
  }
}

// Generate form values from clone or cycle for basic form
export const generateBasicDefaultValues = ({ initialValues, defaultValues }) => {
  if (!defaultValues) {
    return initialValues
  }
  const { filterRules, startSignatures, endSignatures, miningParams } = defaultValues

  const observedAtRangeArray = []
  const transformedFilterRules = filterRules?.rules.reduce((obj, item) => {
    if (item.field === 'observed_at' && item.operator === '>=') {
      observedAtRangeArray[0] = new Date(item.value)
      return {
        ...obj,
        observedAtRange: observedAtRangeArray,
      }
    }
    if (item.field === 'observed_at' && item.operator === '<=') {
      observedAtRangeArray[1] = new Date(item.value)
      return {
        ...obj,
        observedAtRange: observedAtRangeArray,
      }
    }
    if (item.field === 'observer_name') {
      return {
        ...obj,
        observers: item.value.split(',').map(observer => ({ label: observer, value: observer })),
        observerRule: item.operator,
      }
    }
    if (item.field === 'application_name') {
      return {
        ...obj,
        applications: item.value.split(',').map(application => ({ label: application, value: application })),
        applicationRule: item.operator,
      }
    }
    return obj
  }, {})
  return {
    ...initialValues,
    ...transformedFilterRules,
    startSignatures: startSignatures
      ? // from cycle
        { signatures: startSignatures?.map(sig => ({ primary: sig })) }
      : // from cloned mining run
        {
          signatures:
            miningParams.signature_level === 'screen_signature'
              ? constructSignaturesDefaultValues(miningParams.start_signatures)
              : [],
        },
    endSignatures: endSignatures
      ? // from cycle
        { signatures: endSignatures?.map(sig => ({ primary: sig })) }
      : // from cloned mining run
        {
          signatures:
            miningParams.signature_level === 'screen_signature'
              ? constructSignaturesDefaultValues(miningParams.end_signatures)
              : [],
        },
  }
}

// Genereate form values from clone or cycle for advanced form
export const generateAdvancedDefaultValues = ({ initialValues, defaultValues }) => {
  if (!defaultValues) {
    return initialValues
  }
  const { filterRules, startSignatures, endSignatures, miningParams, miningType } = defaultValues
  return {
    filterRules: transformFilter(filterRules),
    miningType: miningType || initialValues.miningType,
    miningParams: {
      ...(miningParams
        ? // from cloned mining run
          {
            ...miningParams,
            signature_level: miningParams.signature_level || initialValues.miningParams.signature_level,
            start_signatures: { signatures: constructSignaturesDefaultValues(miningParams.start_signatures) },
            end_signatures: { signatures: constructSignaturesDefaultValues(miningParams.end_signatures) },
            butterfly_signatures: { signatures: constructSignaturesDefaultValues(miningParams.butterfly_signatures) },
          }
        : // from cycle and initial values
          {
            signature_level: initialValues.miningParams.signature_level,
            start_signatures: { signatures: startSignatures ? startSignatures.map(sig => ({ primary: sig })) : [] },
            end_signatures: { signatures: endSignatures ? endSignatures.map(sig => ({ primary: sig })) : [] },
          }),
    },
  }
}

// Helper function for checking if cloned mining is advanced
const hasNestedRules = obj => {
  // Basic form does not have nested rules
  let res = JSON.stringify(obj).replace(/[^{|^}]/g, '')
  while (/}{/g.test(res)) {
    res = res.replace(/}{/g, '')
  }
  return res.replace(/}/g, '').length > 3
}

const basicFields = ['observed_at', 'observer_name', 'application_name']

const hasAdvancedFields = filterRules => {
  const fields = filterRules?.rules.map(rule => rule.field)
  // Basic form has max 4 rules
  if (fields && fields.length > 4) {
    return true
  }
  // Basic form only contains the basicFields
  if (fields && !fields.every(v => basicFields.includes(v))) {
    return true
  }
  return false
}

export const checkIsAdvanced = clonedMiningRun => {
  if (
    clonedMiningRun.miningType !== 'flowgraph' ||
    clonedMiningRun.miningParams?.signature_level !== 'screen_signature' ||
    clonedMiningRun.miningParams?.merge_starts === 1 ||
    clonedMiningRun.miningParams?.shortest_path === 1 ||
    clonedMiningRun.miningParams?.must_include_signature?.signatures?.length !== 0 ||
    clonedMiningRun.miningParams?.must_include_signature?.nested_signatures?.length !== 0 ||
    hasNestedRules(clonedMiningRun.filterRules) ||
    hasAdvancedFields(clonedMiningRun.filterRules)
  ) {
    return true
  }
  return false
}

export const signatureLevelLabelMap = {
  application: 'Application',
  screen_signature: 'Screen Signature',
  screen: 'Screen Signature', // added to catch old mining runs if migration is not run
  signature: 'Signature',
}
export const secondarySignatureLevelLabelMap = {
  none: 'None',
  field: 'Field',
  value: 'Control Key',
}
