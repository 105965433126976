import { getDefaultParams } from './getMiningParamsPath'

const getMiningParamsButterfly = (screenOrEventText = 'screens', miningTypeLabel = 'screen signature') => [
  {
    label: `Number of ${screenOrEventText} before center`,
    key: 'butterfly_count_before',
    type: 'number',
    default: '5',
    rules: { min: 1 },
    validators: { required: 'This field is required' },
  },
  {
    label: `Number of ${screenOrEventText} after center`,
    key: 'butterfly_count_after',
    type: 'number',
    default: '5',
    rules: { 5: 1 },
    validators: { required: 'This field is required' },
  },
  ...getDefaultParams(miningTypeLabel),
]

export default getMiningParamsButterfly
