import React, { useState } from 'react'
import { Overlay, theme } from '@fortressiq/fiq-ds'
import PropTypes from 'prop-types'

import Filter from './Filter'

const FiltersControls = ({ handleFilterChange, globalFilterOptions, controlledState, setControlledState }) => {
  const [showing, setShowing] = useState(false)
  const placement = {
    overflow: { adjustX: true, adjustY: true },
    points: ['br', 'tr'],
    targetOffset: [0, 4],
    useCssTransform: false,
  }

  const trigger = {
    showing,
    setShowing,
    text: `Filters (${controlledState?.globalFilter ? Object.keys(controlledState.globalFilter).length : 0})`,
    type: 'default',
  }

  const heights = {
    min: 'auto',
  }

  return (
    <Overlay
      basic={true}
      config={{ disableOutsideClick: true }}
      closeIcon={true}
      content={
        <Filter
          controlledState={controlledState}
          globalFilterOptions={globalFilterOptions}
          handleFilterChange={handleFilterChange}
          setControlledState={setControlledState}
          setShowing={setShowing}
        />
      }
      draggable={true}
      heights={heights}
      id='table-filter-overlay'
      key='table-filter-overlay'
      placement={placement}
      trigger={trigger}
      zIndex={theme['z-modal']}
    />
  )
}

FiltersControls.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  globalFilterOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default FiltersControls
