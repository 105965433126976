import React from 'react'
import shortid from 'shortid'
import { Button } from '@fortressiq/fiq-ds'

import Flash from 'components/flash/Flash'

const processBoundaryToast = ({ runId }) => {
  const id = shortid.generate()

  if (status === 'done') {
    Flash.success(
      {
        message: 'Start/Stop suggestions generated',
        cta: (
          <Button type='default' href={`/process-boundary/${runId}`} size='small'>
            View Suggestions
          </Button>
        ),
      },
      { duration: 0, key: id }
    )
  }
}

export default processBoundaryToast
