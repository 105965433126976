//DropShadowRenderer.js

/****
 *. Applies drop shadows to the supplied d3 selector
 *. drop shadows are currently hard coded styles in this function
 ****/
const DropShadowRenderer = ({ svg, d3Selection }) => {
  const defs = svg.append('defs')

  const filter = defs.append('filter').attr('id', 'drop-shadow').attr('height', '160%').attr('width', '160%')

  filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 3).attr('result', 'blur')

  filter.append('feOffset').attr('in', 'blur').attr('dx', 2).attr('dy', 2).attr('result', 'offsetBlur')

  const feTransfer = filter.append('feComponentTransfer')
  feTransfer.append('feFuncA').attr('type', 'linear').attr('slope', 0.3)

  filter
    .append('feFlood')
    .attr('flood-opacity', 0.3)
    .attr('flood-color', 'rgb(137, 149, 173)')
    .attr('result', 'offsetColor')

  filter
    .append('feComposite')
    .attr('in2', 'offsetColor')
    .attr('in', 'offsetBlur')
    .attr('operator', 'in')
    .attr('result', 'offsetBlur')

  const feMerge = filter.append('feMerge')

  feMerge.append('feMergeNode').attr('in', 'offsetBlur')
  feMerge.append('feMergeNode').attr('in', 'SourceGraphic')

  // apply the drop shadow to the d3 selection
  svg.selectAll(d3Selection).style('filter', 'url(#drop-shadow)')
}

export default DropShadowRenderer
