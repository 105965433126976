import React, { useState } from 'react'
import { Icon, Overlay, theme, useModal } from '@fortressiq/fiq-ds'
import PropTypes from 'prop-types'
import { stringify } from 'query-string'

import { addPXSuffix } from 'helpers'
import handleIntegration from 'app/admin/integrations/helpers/handleIntegration'
import api from 'lib/Api'
import ConfigureModal from '../ConfigureModal'

const ActionsMenu = ({
  id,
  name,
  rowIndex,
  updateCellData,
  neoActive,
  user,
  tenantObserverSettings,
  setTenantObserverSettings,
  reFetch,
  integrations,
  isLoadingIntegrations,
  observersModalConfig,
}) => {
  const [actionsShowing, setActionsShowing] = useState({})

  const { addModal, removeModal } = useModal()
  const modalID = 'configure-observer-modal'

  const setShowing = showing => {
    setActionsShowing({ ...actionsShowing, [id]: showing })
  }

  const handleConfigureObserver = () => {
    removeModal(modalID)
  }
  const handleCancelConfigureObserver = () => {
    removeModal(modalID)
  }

  const openConfigureModal = () => {
    addModal({
      children: (
        <ConfigureModal
          observerCount={1}
          onCancel={handleCancelConfigureObserver}
          onOk={handleConfigureObserver}
          observerId={id}
          windowsUser={user}
          neoConfigObserverId={tenantObserverSettings && tenantObserverSettings[user]?.neo_config_observer_id}
          reFetch={reFetch}
          tenantObserverSettings={tenantObserverSettings && tenantObserverSettings[user]}
        />
      ),
      ...observersModalConfig,
      header: `Configure ${name}`,
      id: modalID,
    })
  }

  const toggleRecordAllForUser = async () => {
    const currentRecordSetting = tenantObserverSettings[user]?.activate_new_observers || false
    const encodedWindowUser = encodeURIComponent(user).replace(/\./g, '%252E')
    const { data } = await api.put(`/windows_username/${encodedWindowUser}`, {
      windows_username: encodedWindowUser,
      activate_new_observers: !currentRecordSetting,
    })
    setTenantObserverSettings({ ...tenantObserverSettings, [user]: data })
  }

  const setIfBaseConfig = async () => {
    const currentUserNeoConfigSetting = tenantObserverSettings[user]?.neo_config_observer_id || false
    const currentRecordSetting = tenantObserverSettings[user]?.activate_new_observers || false
    const encodedWindowUser = encodeURIComponent(user).replace(/\./g, '%252E')
    if (currentUserNeoConfigSetting) {
      await api.put(`/windows_username/${encodedWindowUser}`, {
        windows_username: encodedWindowUser,
        neo_config_observer_id: null,
        activate_new_observers: currentRecordSetting,
      })
    } else {
      const filters = { observers: [id] }
      const { data } = await api.get('/observers/neo_config_policies', { filters: filters })
      const {
        clipboardAllowed,
        keystrokeAllowed,
        screenshotAllowed,
        titleAllowed,
        allowBlockLists,
        windowProcessCaptures,
      } = data[0]?.data?.attributes || {}

      const params = {
        clipboardAllowed,
        keystrokeAllowed,
        screenshotAllowed,
        titleAllowed,
        allowBlockLists,
        windowProcessCaptures,
      }
      await api.put(
        `/observers/neo_config_policies?${stringify({ filters: JSON.stringify({ usernames: [user] }) })}`,
        params,
        false
      )

      await api.put(`/windows_username/${encodedWindowUser}`, {
        windows_username: encodedWindowUser,
        neo_config_observer_id: id,
        activate_new_observers: currentRecordSetting,
      })
    }
    reFetch()
  }

  const setNeoStatus = async active => {
    const { data } = await api.put(`/observers/${id}/neo`, { active })
    updateCellData(rowIndex, 'neoActive', data.active)
  }

  const widthOfDropdown = 420
  const widthofArrowCol = 28
  const sharedTooltipProps = {
    mouseEnterDelay: 3000,
    placement: 'left-center',
  }
  const menuTexts = [
    'Start Recording',
    'Stop Recording',
    tenantObserverSettings && tenantObserverSettings[user]?.neo_config_observer_id
      ? "Do Not Use Configuration for This User's Observers"
      : "Use Configuration for This User's Observers",
    tenantObserverSettings && tenantObserverSettings[user]?.activate_new_observers
      ? 'Do Not Automatically Record New Observers For This User'
      : 'Automatically Record New Observers For This User',
    'Configure',
  ]

  return (
    <Overlay
      config={{
        disableOutsideClick: false,
        content: {
          style: {
            '> .fiqds-overlay-dropdown-container': {
              position: 'relative',
            },
          },
        },
        dropdown: {
          closeOnRowClick: true,
        },
      }}
      dark={true}
      dropdownStyle={{
        left: addPXSuffix(-widthOfDropdown + 26),
        overflow: 'auto',
        position: 'relative',
        top: 0,
        width: addPXSuffix(widthOfDropdown),
      }}
      id={`observer-${id}-actions`}
      menu={[
        {
          disabled: neoActive,
          icon: 'playcircle',
          iconProps: {
            fill: 'transparent',
            color: neoActive ? theme['text-vlight'] : theme.white,
            size: 12,
            textColor: neoActive ? theme['text-vlight'] : theme.white,
          },
          onClick: () => setNeoStatus(true),
          text: menuTexts[0],
          tooltip: {
            ...sharedTooltipProps,
            title: menuTexts[0],
          },
        },
        {
          disabled: !neoActive,
          icon: 'stop',
          iconProps: {
            fill: 'transparent',
            color: !neoActive ? theme['text-vlight'] : theme.white,
            size: 12,
          },
          onClick: () => setNeoStatus(false),
          text: menuTexts[1],
          tooltip: {
            ...sharedTooltipProps,
            title: menuTexts[1],
          },
        },
        {
          icon: 'playcircle',
          iconProps: {
            fill:
              tenantObserverSettings && tenantObserverSettings[user]?.neo_config_observer_id
                ? theme['success-color']
                : theme['fiq-red'],
            color: 'transparent',
            size: 12,
            textColor:
              tenantObserverSettings && tenantObserverSettings[user]?.neo_config_observer_id
                ? theme['success-color']
                : theme['fiq-red'],
          },
          onClick: setIfBaseConfig,
          text: menuTexts[2],
          tooltip: {
            ...sharedTooltipProps,
            title: menuTexts[2],
          },
        },
        {
          onClick: toggleRecordAllForUser,
          icon: 'playcircle',
          iconProps: {
            fill:
              tenantObserverSettings && tenantObserverSettings[user]?.activate_new_observers
                ? theme['success-color']
                : theme['fiq-red'],
            color: 'transparent',
            size: 12,
            textColor:
              tenantObserverSettings && tenantObserverSettings[user]?.activate_new_observers
                ? theme['success-color']
                : theme['fiq-red'],
          },
          text: menuTexts[3],
          tooltip: {
            ...sharedTooltipProps,
            title: menuTexts[3],
          },
        },
        {
          onClick: openConfigureModal,
          text: menuTexts[4],
          tooltip: {
            ...sharedTooltipProps,
            title: menuTexts[4],
          },
        },
        ...(!isLoadingIntegrations &&
          integrations.map(integration => ({
            onClick: () => handleIntegration({ integrationId: integration.id, objectId: id }),
            text: integration.title,
            tooltip: {
              ...sharedTooltipProps,
              title: integration.title,
            },
          }))),
      ]}
      trigger={{
        setShowing: setShowing,
        node: (
          <Icon
            clickable={true}
            icon={actionsShowing[id] ? 'arrowup' : 'chevron'}
            size='small'
            style={{ marginRight: addPXSuffix(8), position: 'relative', left: addPXSuffix(8) }}
          />
        ),
        showing: actionsShowing[id],
        type: 'primary',
      }}
      widths={{
        max: 'auto',
        min: widthofArrowCol,
        width: widthofArrowCol,
      }}
    />
  )
}

ActionsMenu.defaultProps = {
  isLoadingIntegrations: undefined,
  tenantObserverSettings: undefined,
  integrations: undefined,
}

ActionsMenu.propTypes = {
  id: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  updateCellData: PropTypes.func.isRequired,
  neoActive: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
  tenantObserverSettings: PropTypes.shape(),
  reFetch: PropTypes.func.isRequired,
  isLoadingIntegrations: PropTypes.bool,
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ),
}

export default ActionsMenu
