import React, { useState, Fragment } from 'react'
import { Overlay, Typography, theme } from '@fortressiq/fiq-ds'

import { useHistory } from 'react-router-dom'
import AdvancedForm from '../mining/form/AdvancedForm'

const { Title } = Typography

const FormOverlay = ({ startStopRankings, miningParams, filterRules }) => {
  const [showing, setShowing] = useState(false)
  const history = useHistory()

  const startSignatures = startStopRankings
    .filter(item => item.user_selected && item.type === 'start')
    .map(item => ({ primary: item.screen_signature, secondary_signature_level: 'none' }))
  const endSignatures = startStopRankings
    .filter(item => item.user_selected && item.type === 'end')
    .map(item => ({ primary: item.screen_signature, secondary_signature_level: 'none' }))
  const clonedMiningRun = {
    miningType: 'default',
    fromReport: true,
    miningParams: {
      ...miningParams,
      signature_level: 'screen_signature',
      end_signatures: { signatures: endSignatures },
      start_signatures: { signatures: startSignatures },
    },
    filterRules,
  }

  const placement = {
    overflow: { adjustX: true, adjustY: true },
    points: ['br', 'tr'],
    targetOffset: [0, 4],
    useCssTransform: false,
  }

  const trigger = {
    showing,
    setShowing,
    text: 'Populate Mining Run',
    type: 'secondary',
  }

  const heights = {
    min: 'auto',
  }

  return (
    <Overlay
      config={{ disableOutsideClick: true }}
      closeIcon={false}
      basic={false}
      content={
        <Fragment>
          <Title
            style={{
              padding: `0 ${theme['default-spacer-md']} ${theme['default-spacer-sm']}`,
              marginBottom: theme['default-spacer-sm'],
              borderBottom: `1px solid ${theme['table-border-color']}`,
            }}
            level={5}
            noMargin={true}
          >
            Create a Mining Run
          </Title>
          <AdvancedForm
            fetchData={() => history.push('/mining')}
            setShowing={setShowing}
            defaultValues={clonedMiningRun}
            fromReport={true}
          />
        </Fragment>
      }
      draggable={true}
      heights={heights}
      id='mining-modal'
      placement={placement}
      trigger={trigger}
      noPadding={true}
      zIndex={1040}
    />
  )
}

export default FormOverlay
