import React, { useEffect, useState } from 'react'
import { Button, useModal } from '@fortressiq/fiq-ds'

import { useHeaderDispatch } from 'app/header/HeaderContext'

import TrinityTable, { columnWidths } from 'components/TrinityTable'

import IntegrationFormModal from './SubComponents/IntegrationFormModal'
import ActionsMenu from './SubComponents/ActionsMenu'

const sortKeyMap = {
  exportType: 'export_type',
  createdAt: 'created_at',
  updatedAt: 'updated_at',
}

const Integrations = () => {
  const [reFetch, setReFetch] = useState(1)
  const headerDispatch = useHeaderDispatch()
  const { addModal, removeModal } = useModal()

  const handleReFetch = () => {
    setReFetch(reFetch + 1)
  }

  useEffect(() => {
    headerDispatch({
      type: 'set',
      title: 'Integrations',
      heading: 'Integrations',
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  const columns = [
    {
      accessor: 'id',
      align: 'center',
      Header: 'ID',
      maxWidth: columnWidths.id,
    },
    {
      Header: 'Button Label',
      accessor: 'title',
      disableSortBy: true,
    },
    {
      Header: 'Endpoint',
      accessor: 'endpoint',
      disableSortBy: true,
    },
    {
      Header: 'Transport',
      accessor: 'transport',
      disableSortBy: true,
      maxWidth: 80,
    },
    {
      Header: 'Integration Type',
      accessor: 'integrationType',
      disableSortBy: true,
      maxWidth: 100,
    },
    {
      Header: 'Authorization Type',
      accessor: 'authorizationType',
      disableSortBy: true,
      maxWidth: 100,
    },
    {
      accessor: 'method',
      align: 'center',
      Header: 'Method',
      maxWidth: 50,
      disableSortBy: true,
    },
    {
      accessor: 'isActive',
      align: 'center',
      Header: 'Active',
      disableSortBy: true,
      maxWidth: 50,
      Cell: ({ value }) => (value ? 'True' : 'False'),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      align: 'center',
      disableSortBy: true,
      width: 60,
      Cell: ({ row: { original } }) => <ActionsMenu handleReFetch={handleReFetch} integration={original} />,
    },
  ]
  const modalID = 'integrations-form-modal'

  return (
    <TrinityTable
      toolbarActions={[
        <Button
          key={modalID}
          onClick={() =>
            addModal({
              children: <IntegrationFormModal handleReFetch={handleReFetch} removeModal={() => removeModal(modalID)} />,
              header: 'Create a new integration',
              footer: false,
              id: modalID,
              style: { width: '400px' },
            })
          }
          type='secondary'
        >
          Create Integration
        </Button>,
      ]}
      reFetch={reFetch}
      tableId='integrations-table'
      sortKeyMap={sortKeyMap}
      columns={columns}
      url='/dynamic_integrations'
      dataKey='integrations'
    />
  )
}

export default Integrations
