/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment } from 'react'
import { AdvancedCell, Button, CopyToClipboardButton, Element, Group, Popover, theme } from '@fortressiq/fiq-ds'

const popoverStyle = {
  '> .fiqds-popover-content': {
    padding: 0,
    overflow: 'auto',
  },
}
const popoverContentStyle = {
  margin: '0',
  maxHeight: '70vh',
  maxWidth: 600,
  padding: theme['default-spacer-sm'],
  whiteSpace: 'break-spaces',
}

const ParamsColumn = ({ value }) => {
  const valueString = typeof value === 'string' ? value : JSON.stringify(value)

  return (
    <Fragment>
      <AdvancedCell textToCopy={valueString} tooltipText='Copy'>
        {valueString}
      </AdvancedCell>
      {valueString?.length > 2 && (
        <Popover
          content={
            <Element as='pre' style={popoverContentStyle}>
              {JSON.stringify(typeof value === 'string' ? JSON.parse(value) : value, null, 2)}
            </Element>
          }
          placement='top-center'
          style={popoverStyle}
          title={
            <Group align='center' noEndGutter={true} justify='space-between' type='flex'>
              <Fragment>Params</Fragment>
              <CopyToClipboardButton
                buttonProps={{ size: 'small' }}
                textToCopy={valueString}
                tooltipProps={{ title: 'Copy' }}
              />
            </Group>
          }
          trigger='click'
        >
          <Button
            noBorder={true}
            flat={true}
            suffixIcon='search'
            suffixIconProps={{ size: 12, stroke: theme['link-color'] }}
            tooltipProps={{ placement: 'right-end', title: 'Click here to view all params.' }}
          />
        </Popover>
      )}
    </Fragment>
  )
}

export default ParamsColumn
