import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Element, Icon, Typography, Spin, Statistic, useFela, theme } from '@fortressiq/fiq-ds'
import { countsBox, countBox, countBoxLoadContainer, statTitleCSS, statValueCSS, statBackIconCSS } from './styles'

import { cardTitleStyle, colorThemes, creditsDataProp } from './constants'

const { Text } = Typography

const StatCard = ({ icon, iconBack, color, title, value, loading }) => {
  const { css } = useFela({ cardColor: colorThemes[color].card })

  return (
    <div className={css(countBox)}>
      <Icon
        icon={iconBack}
        fill={colorThemes[color].icon}
        color={colorThemes[color].card}
        size={100}
        style={statBackIconCSS}
      />
      {loading && <Spin containerStyle={countBoxLoadContainer} size={15} strokeWidth={1} />}
      {!loading && (
        <Fragment>
          <Element style={{ display: 'flex', position: 'absolute', bottom: cardTitleStyle.height, zIndex: 2 }}>
            <Statistic
              valueStyle={statValueCSS}
              value={value}
              suffix={<Icon icon={icon} color={colorThemes[color].icon} fill={theme.white} />}
            />
          </Element>
          <Text style={statTitleCSS}>{title}</Text>
        </Fragment>
      )}
    </div>
  )
}

const CreditsCounts = ({ creditsData, loading }) => {
  const { css } = useFela()

  const aggregateCounts = () => {
    let credits = 0
    let users = 0
    const usersHash = {}
    creditsData.forEach(datem => {
      credits += datem.credit
      usersHash[datem.windows_username] = true
    })
    users = Object.keys(usersHash).length
    return [credits, users]
  }

  return (
    <div className={css(countsBox)}>
      <StatCard
        color='blue'
        icon='visualizations'
        iconBack='visualizations'
        loading={loading}
        title='Credits Used'
        value={aggregateCounts()[0]}
      />
      <StatCard
        color='green'
        icon='fte'
        iconBack='fte'
        loading={loading}
        title='User Count'
        value={aggregateCounts()[1]}
      />
    </div>
  )
}

CreditsCounts.propTypes = {
  creditsData: creditsDataProp.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default CreditsCounts
