import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Element,
  Button,
  DateTimePickerRange,
  Label,
  Input,
  Row,
  TextArea,
  theme,
  Typography,
} from '@fortressiq/fiq-ds'
import shortid from 'shortid'
import api from 'lib/Api'

import { formBackgroundCSS, formContainerCSS, observerGroupCSS, titleSectionCSS } from 'app/mining/form/styles'

import { DATE_TIME_FORMAT_TWENTYFOUR_HOUR } from 'constants/app-constants'

import AsyncSelect from 'components/form/AsyncSelect'
import constructApplicationOptions from 'components/form/helpers/constructApplicationOptions'
import SignatureBuilder from './SignatureBuilder'

import { addImportant, noop } from '../../../helpers/index'
import { cycleButtonSectionCSS, scrollSectionCSS } from '../styles'

const { Title } = Typography

const filterRulesMap = filterRules => {
  const initialValue = {}
  if (!filterRules.rules) {
    return null
  }
  return filterRules.rules.reduce((obj, item) => {
    if (item.field === 'observed_at' && item.operator === '>=') {
      return {
        ...obj,
        observedAtFrom: item.value,
      }
    }
    if (item.field === 'observed_at' && item.operator === '<=') {
      return {
        ...obj,
        observedAtTo: item.value,
      }
    }
    if (item.field === 'observer_name') {
      return {
        ...obj,
        observers: item.value.split(','),
        observerRule: item.operator,
      }
    }
    if (item.field === 'application_name') {
      return {
        ...obj,
        applications: item.value.split(','),
        applicationRule: item.operator,
      }
    }
    if (item.field === 'user') {
      return {
        ...obj,
        users: item.value.split(','),
        usersRule: item.operator,
      }
    }
    return obj
  }, initialValue)
}

const defaultFormState = {
  name: '',
  observedAtFrom: null,
  observedAtTo: null,
  observers: undefined,
  observerRule: 'in',
  applications: undefined,
  applicationRule: 'in',
  users: undefined,
  usersRule: 'in',
  notes: '',
  startSignature: undefined,
  endSignature: undefined,
  signatureApplicationIds: undefined,
  user_ids: null,
}

const spacer = theme['default-spacer-sm']
export const labelProps = {
  style: { marginTop: spacer },
}

const CycleForm = ({ clonedCycle, editId, reloadCycles, setShowing }) => {
  const [formState, setFormState] = useState(
    clonedCycle
      ? {
          ...defaultFormState,
          name: clonedCycle.name,
          notes: clonedCycle.notes,
          startSignature: clonedCycle.startSignatures,
          endSignature: clonedCycle.endSignatures,
          user_ids: clonedCycle.cycleUsers.map(user => user.id),
          ...filterRulesMap(clonedCycle.filterRules),
        }
      : { ...defaultFormState }
  )
  const [dates, setDates] = useState(() => {
    const from = formState?.observedAtFrom ? new Date(formState.observedAtFrom) : null
    const to = formState?.observedAtTo ? new Date(formState.observedAtTo) : null

    return [from, to]
  })

  const canSubmit = [
    formState.observedAtFrom,
    formState.observedAtTo,
    formState.observers?.length > 0 || formState.users?.length > 0,
    formState.name.trim(),
  ].every(Boolean)

  const onDateChange = ds => {
    setDates(ds)
    setFormState({
      ...formState,
      observedAtFrom: ds[0] || null,
      observedAtTo: ds[1] || null,
    })
  }

  const handleObserverTagChange = async ({ target: { value } }) => {
    const values = value.map(items => items.value)
    const {
      data: { observers },
    } = await api.get('/observers', { filters: { tags: values } })
    const observersSelectArray = observers.map(observer => observer.name)
    setFormState({ ...formState, observers: observersSelectArray })
  }

  const onSelectChange = ({ target: { name, value, optionsValueKey = 'value' } }) => {
    const values = value.map(items => items[optionsValueKey])
    onInputChange({ target: { name, value: values } })
  }

  const onInputChange = ({ target: { name, value } }) => setFormState({ ...formState, [name]: value })

  const onSubmit = async e => {
    e.preventDefault()
    const params = {
      name: formState.name.trim(),
      notes: formState.notes,
      start_signatures: formState.startSignature,
      stop_signatures: formState.endSignature,
      user_ids: formState.user_ids,
      filter: {
        combinator: 'and',
        not: false,
        id: shortid.generate(),
        rules: [
          formState.observers?.length > 0 && {
            field: 'observer_name',
            id: shortid.generate(),
            value: formState.observers.join(),
            operator: formState.observerRule,
          },
          formState.applications?.length > 0 && {
            field: 'application_name',
            value: formState.applications.join(),
            id: shortid.generate(),
            operator: formState.applicationRule,
          },
          formState.observedAtFrom && {
            field: 'observed_at',
            id: shortid.generate(),
            value: formState.observedAtFrom,
            operator: '>=',
          },
          formState.observedAtTo && {
            field: 'observed_at',
            id: shortid.generate(),
            value: formState.observedAtTo,
            operator: '<=',
          },
          formState.users?.length > 0 && {
            field: 'user',
            id: shortid.generate(),
            value: formState.users.join(),
            operator: formState.usersRule,
          },
        ].filter(Boolean),
      },
    }
    const apiUrl = editId ? api.put(`/cycles/${editId}`, params) : api.post('/cycles', params)
    await apiUrl
    await reloadCycles()
    setShowing(false)
  }
  const width = '610px'

  return (
    <Element style={{ width }}>
      <Element style={titleSectionCSS}>
        <Title level={5} noMargin={true}>
          {editId ? `Edit Cycle ${editId}` : 'Create a Cycle'}
        </Title>
      </Element>
      <Element style={{ ...formContainerCSS(), width }}>
        <Element style={scrollSectionCSS}>
          <Element style={formBackgroundCSS}>
            <Input
              id='cycle-name-input'
              isRequired={true}
              label='Cycle Name'
              name='name'
              onChange={onInputChange}
              value={formState.name}
            />
            <Label {...labelProps} ID='user_ids'>
              Permitted Users
            </Label>
            <AsyncSelect
              url='/users/filters'
              params={{ field: 'role', roles: ['power', 'user', 'virtual_interviewer'] }}
              placeholder='Select permitted users'
              optionsValueKey='value'
              values={formState.user_ids}
              onChange={onSelectChange}
              name='user_ids'
            />
            <DateTimePickerRange
              labels={[{ label: 'Observed At', labelProps: { isRequired: true, ...labelProps } }]}
              bordered={true}
              dateFormat={DATE_TIME_FORMAT_TWENTYFOUR_HOUR}
              id='observedAt'
              label='Observed At'
              onChange={ds => onDateChange(ds)}
              styles={{ popperStyle: { zIndex: theme['z-overdrive'] } }}
              value={dates}
            />
            <Element style={observerGroupCSS}>
              <Label {...labelProps} isRequired={!formState.users}>
                Observers
              </Label>
              <Row align='flex-start' gutter={8} noOfCols={12} noEndGutter={true} type='flex'>
                <Col span={4}>
                  <AsyncSelect
                    url='/tags/filters'
                    params={{ taggable_type: 'Observer' }}
                    placeholder='Observers from tag'
                    onChange={handleObserverTagChange}
                    name='observerTags'
                    style={{ width: '100%' }}
                    controlled={false}
                  />
                </Col>
                <Col
                  justify='flex-end'
                  span={8}
                  style={{ marginRight: addImportant(0), width: addImportant('66.6667%') }}
                >
                  <AsyncSelect
                    name='observers'
                    onChange={onSelectChange}
                    optionsValueKey='label'
                    placeholder='Select observers'
                    style={{ width: '100%' }}
                    url='/observers/filters'
                    values={formState.observers}
                  />
                </Col>
              </Row>
              <Label {...labelProps} ID='users' isRequired={true}>
                Observer Users
              </Label>
              <AsyncSelect
                url='/observers/filters'
                params={{ field: 'windows_username' }}
                placeholder='Select user'
                optionsValueKey='label'
                values={formState.users}
                onChange={onSelectChange}
                name='users'
              />
            </Element>
            <Label {...labelProps} ID='users'>
              Application Names
            </Label>
            <AsyncSelect
              url='/applications/filters'
              placeholder='Select applications'
              values={formState.applications}
              onChange={onSelectChange}
              name='applications'
              params={{ service: true }}
              constructData={constructApplicationOptions}
            />
            <SignatureBuilder formState={formState} onSelectChange={onSelectChange} />
            <TextArea
              containerProps={{ style: { marginTop: theme['default-spacer-sm'] } }}
              defaultValue={formState.notes}
              label='Notes'
              name='notes'
              onChange={onInputChange}
              rows={5}
            />
          </Element>
        </Element>
        <Element style={cycleButtonSectionCSS}>
          <Button
            onClick={e => {
              e.preventDefault()
              setShowing(false)
            }}
          >
            Cancel
          </Button>
          <Button disabled={!canSubmit} onClick={canSubmit ? onSubmit : noop}>
            {editId ? 'Update Cycle' : 'Create Cycle'}
          </Button>
        </Element>
      </Element>
    </Element>
  )
}

CycleForm.propTypes = {
  reloadCycles: PropTypes.func.isRequired,
  setShowing: PropTypes.func.isRequired,
}

export default CycleForm
