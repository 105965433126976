import GroupStore from '../GroupsStore'

import { graphColorNames, graphColors, graphColorsLight } from '../../../../lib/test_helpers/mockGraphColors.js'

jest.mock('lib/Api', () => ({
  token: '',
}))

jest.mock('d3', () => ({
  selectAll: [],
}))

const mockColors = {
  graphColorNames,
  graphColors,
  graphColorsLight,
}
jest.mock('@fortressiq/fiq-ds-themes/dist/js/graphColors.js', () => mockColors)

describe('Process Epxlorer GroupStore', () => {
  it('should make all groups visible when we highlightAll()', () => {
    const store = new GroupStore()
    store.groupList = [
      { visible: false, nodeIds: [1, 2, 3] },
      { visible: false, nodeIds: [1, 2, 5] },
    ]
    store.highlightAll()
    expect(store.groupList.filter(o => o.visible).length === 2)

    store.groupList = [
      { visible: false, nodeIds: [1, 2, 3] },
      { visible: true, nodeIds: [1, 2, 5] },
    ]
    store.highlightAll()
    expect(store.groupList.filter(o => o.visible).length === 2)

    store.groupList = [
      { visible: true, nodeIds: [1, 2, 3] },
      { visible: true, nodeIds: [1, 2, 5] },
    ]
    store.highlightAll()
    expect(store.groupList.filter(o => o.visible).length === 2)
  })
})
