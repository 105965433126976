import React from 'react'
import { Element, Statistic, theme, Typography } from '@fortressiq/fiq-ds'

const { Title } = Typography

const RunDetail = ({ title, value }) => {
  const valueStyle = { color: theme['legacy-color-gray11'], fontSize: '18px', lineHeight: '20px' }

  return (
    <Element style={{ marginRight: theme['default-spacer-lg'] }}>
      <Title level={5} style={{ fontSize: theme['font-size-xs'] }} uppercase={true}>
        {title}
      </Title>
      {typeof value === 'number' ? (
        <Statistic value={value} valueStyle={valueStyle} />
      ) : (
        <Element as='span' style={valueStyle}>
          {value}
        </Element>
      )}
    </Element>
  )
}

export default RunDetail
