/* eslint-disable import/prefer-default-export */

import { addPXSuffix } from 'helpers'

export const sidePanelWidth = 450
export const sidebarEasingTime = '0.4s'
export const sidebarEasingFunc = 'ease-in-out'

const br = { borderRadius: '0' }

export const controlsCSS = ({ collapse, theme }) => ({
  background: theme.white,
  borderLeft: `1px solid ${theme['legacy-color-gray1']}`,
  display: 'inline-block',
  overflowY: 'auto',
  transition: `width ${sidebarEasingTime} ${sidebarEasingFunc}`,
  width: collapse ? '0' : addPXSuffix(sidePanelWidth),
  willChange: 'width',
  zIndex: '1',
  '> div': {
    minWidth: addPXSuffix(sidePanelWidth),
  },
  '> .fiqds-collapse > .fiqds-collapse-panel': {
    ...br,
    '> header': {
      border: `1px solid ${theme['dividing-line']}`,
      ...br,
      '&:first-child': {
        borderTopWidth: '0',
      },
    },
  },
})

export const panelsCSS = ({ theme }) => ({
  background: theme.white,
  borderLeft: '0',
  borderRight: '0',
  borderTop: '0',
  '> div': {
    borderColor: theme['legacy-color-gray1'],
  },
})

export const toggleCSS = ({ collapse, theme }) => ({
  background: theme.white,
  bottom: theme['default-spacer-sm'],
  cursor: 'pointer',
  position: 'absolute',
  right: collapse ? '0' : addPXSuffix(sidePanelWidth - 1),
  transition: `right ${sidebarEasingTime} ${sidebarEasingFunc}`,
  zIndex: '2',
})
