export const captureIconSwitchCSS = ({ theme }) => ({
  marginRight: theme['default-spacer-sm'],
})

export const errorCSS = ({ theme }) => ({
  color: theme['color-red'],
  fontSize: theme['font-size-xs'],
})

export const sharedColStyle = { justifyContent: ' flex-end' }
export const sharedLabelStyle = { cursor: 'default' }
export const sharedSwitchStyle = { placeSelf: 'flex-end' }
