import { theme } from '@fortressiq/fiq-ds'

const sm = theme['default-spacer-sm']

export const gridCSS = () => ({
  height: '100%',
  overflow: 'auto',
  padding: sm,

  '@media all and (min-width: 1200px)': {
    display: 'flex',
  },
})

const md = theme['default-spacer-md']
export const navigationContainer = () => ({
  marginLeft: md,
  position: 'absolute',
  top: '82px',

  '& > a': {
    textDecoration: 'none',
    color: theme['gray-tints-12'],
    fontWeight: 600,
    marginRight: md,
    paddingBottom: sm,
  },
})

export const activeStyles = () => ({
  color: theme['blue-tints-10'],
  borderBottom: `2px solid ${theme['blue-tints-10']}`,
})

export const columnCSS = () => ({
  padding: md,

  '@media all and (min-width: 1200px)': {
    width: '50%',
  },
})

export const containerCss = { display: 'flex', flexDirection: 'column', overflow: 'hidden', height: '100%' }

export const contentCss = {
  flex: 1,
  overflow: 'auto',
  '@media all and (min-width: 1200px)': {
    display: 'flex',
    padding: `${md} 0`,
  },
}

export const coeErrorCss = {
  marginLeft: 0,
  position: 'aboslute',
  paddingRight: '60px',
}

export const footerCss = {
  alignSelf: 'flex-end',
  display: 'flex',
  marginRight: md,
  marginBottom: md,
}

export const observerConfigContainer = () => ({
  padding: `${sm} ${md} ${md}`,
  marginBottom: theme['default-spacer-lg'],
})

// To be used when we add color delineation into manage account, presently unused:
export const sectionContainer = () => ({
  background: theme['field-bg-light'],
  border: `1px solid ${theme['field-bg-dark']}`,
  borderRadius: theme['border-radius-base'],
  padding: md,
  marginBottom: theme['default-spacer-lg'],
})
