import { theme } from '@fortressiq/fiq-ds'

export const tableContainerConfig = {
  style: {
    background: theme.white,
    borderTop: `1px solid ${theme['antd-card-border']}`,
    '> div .fiqds-spin-container': {
      zIndex: theme['z-base-above'],
    },
    '> div .fiqds-table-thead': {
      background: theme.white,
      position: 'sticky',
      top: 0,
      zIndex: theme['z-base-above'],
    },
    '> .fiqds-table-pagination-container': {
      background: theme.white,
      borderTop: `1px solid ${theme['antd-card-border']}`,
      bottom: 0,
      position: 'sticky',
      zIndex: theme['z-base-above'],
    },
  },
  as: 'div',
}

export const splitPaneStyle = () => ({
  '& > .Pane': {
    height: '100%',
    overflow: 'auto',
  },
})
