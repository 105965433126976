import React from 'react'
import { ActionCableConsumer } from 'components/actioncable-provider'
import Flash from 'components/flash/Flash'
import Callback from 'stores/callback'

const notificationTypeMap = {
  toast: ({ status, message, title, statusMessage }) =>
    Flash[status] &&
    Flash[status]({
      message: title || `${status.charAt(0).toUpperCase() + status.slice(1)}:`,
      description: `${message || statusMessage}.`,
    }),
  callback: ({ callbackId, ...args }) => Callback.call(callbackId, args),
}

const UserNotifications = () => {
  const handleReceived = ({ type, ...msg }) => notificationTypeMap[type] && notificationTypeMap[type](msg)
  return <ActionCableConsumer channel={{ channel: 'UserNotificationsChannel' }} onReceived={handleReceived} />
}

export default UserNotifications
