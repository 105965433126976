import React from 'react'
import { Button, ButtonGroup, Col } from '@fortressiq/fiq-ds'
import { ControlBlock, ControlRow } from './ControlHelpers'

const EventSelection = ({ toggleAllSelectedEvent, toggleSelectedRow }) => (
  <ControlBlock>
    <ControlRow>
      <Col span={{ xs: 4, sm: 4, md: 4 }}>
        <span>Select:</span>
      </Col>
      <Col span={{ xs: 8, sm: 8, md: 8 }}>
        <ButtonGroup>
          <Button size='small' type='secondary' onClick={() => toggleSelectedRow()}>
            Current Group
          </Button>
          <Button size='small' type='secondary' onClick={toggleAllSelectedEvent}>
            All Groups
          </Button>
        </ButtonGroup>
      </Col>
    </ControlRow>
  </ControlBlock>
)

export default EventSelection
