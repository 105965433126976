import React, { useState, useEffect } from 'react'
import { Group, Tag, useFela } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import CreditsCounts from './CreditsCounts'
import CreditsChart from './CreditsChart'
import CreditsFilter from './CreditsFilter'
import DownloadCreditsButton from './DownloadCreditsButton'
import UserBreakdownTable from './UserBreakdownTable'

import { controlsWrapper, dashWrapperCSS } from './styles'

import { filtersInit } from './constants'

const ManageCredits = () => {
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState(filtersInit())
  const [creditsData, setCreditsData] = useState([])
  const [userBreakdownData, setUserBreakdownData] = useState([])
  const [userBreakdownExpandHash, setUserBreakdownExpandHash] = useState({})
  const [chartData, setChartData] = useState([])
  const { css } = useFela()

  useEffect(() => {
    const fetchData = async () => {
      const windowsUsernames = filters.windows_username.map(user => user.label).join(',')
      const params = { ...filters, windows_username: windowsUsernames }
      const { data } = await api.get('/v1/credit_usages', params)
      setCreditsData(data || [])
      getUserBreakdownAndCounts(data)
      setLoading(false)
    }
    fetchData()
  }, [filters])

  const getUserBreakdownAndCounts = data => {
    const userTableData = []
    const userHash = {}
    const expandData = {}
    const myChartData = []

    let index = 0
    data.forEach(datem => {
      // get user aggregate (user table data)
      if (userTableData[userHash[datem.windows_username]]) {
        userTableData[userHash[datem.windows_username]].creditCount += datem.credit
        userTableData[userHash[datem.windows_username]].eventCount += datem.event_log_count
      } else {
        userTableData.push({
          user: datem.windows_username,
          creditCount: datem.credit,
          eventCount: datem.event_log_count,
          userExpand: [],
        })
        userHash[datem.windows_username] = index
        index += 1
      }

      // get user dates breakout data (sub tables)
      if (!expandData[datem.windows_username]) {
        expandData[datem.windows_username] = []
      }
      expandData[datem.windows_username].push(datem)

      //get date aggregate (bar chart data)
      const currentCount = myChartData[datem.observed_at_day] || 0
      myChartData[datem.observed_at_day] = currentCount + datem.credit
    })

    setUserBreakdownData(userTableData)
    setUserBreakdownExpandHash(expandData)
    setChartData(myChartData)
  }

  return (
    <section className={css(dashWrapperCSS)}>
      <Group justify='space-between' className={css(controlsWrapper)}>
        <Group align='flex-end'>
          <CreditsFilter filters={filters} setFilters={setFilters} />
          <DownloadCreditsButton creditsData={creditsData} filters={filters} />
        </Group>
        <Group align='flex-end' noEndGutter={true} justify='flex-end'>
          <Tag key='credits-filter-range'>{`${filters.start_day} - ${filters.end_day}`}</Tag>
          {filters.windows_username.map(username => (
            <Tag key={username}>{username.label}</Tag>
          ))}
        </Group>
      </Group>
      <CreditsCounts loading={loading} creditsData={creditsData} />
      <UserBreakdownTable
        loading={loading}
        userBreakdownData={userBreakdownData}
        userBreakdownExpandHash={userBreakdownExpandHash}
      />
      <CreditsChart loading={loading} chartData={chartData} />
    </section>
  )
}

export default ManageCredits
