import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Button, ButtonGroup, Group, Popconfirm, theme, useModal } from '@fortressiq/fiq-ds'

import api from 'lib/Api'

import store from '../store/DiagramComposerStore'
import SaveModal from './SaveModal'
import generatePdd from './generatePdd'

const ControlsBar = ({ autoLayout, specId }) => {
  const history = useHistory()
  const { id: flowchartId } = useParams()
  const { addModal, removeModal } = useModal()
  const modalID = 'diagram-composer-save-modal'

  const generate = (includeFullImages = false) => {
    if (specId) {
      api.delete(`/process_specs/${specId}/delete_pdd`)
    }
    generatePdd({ flowchartId, specId, includeFullImages })
    history.push('/pdds')
  }

  const generateWithImages = () => {
    generate(true)
  }

  return (
    <Group
      gutter={8}
      style={{
        margin: 0,
        position: 'absolute',
        right: theme['default-spacer-md'],
        top: theme['default-spacer-md'],
        zIndex: 10,
      }}
      type='flex'
    >
      <ButtonGroup>
        <Button onClick={autoLayout} size='small'>
          Auto Layout
        </Button>
        {flowchartId !== 'new' && (
          <Popconfirm
            cancelText='No'
            okText='Yes'
            onCancel={generate}
            onConfirm={generateWithImages}
            placement='left-center'
            title='Include an image appendix?'
          >
            <Button size='small'>Generate PDD</Button>
          </Popconfirm>
        )}
      </ButtonGroup>
      <Button
        onClick={() =>
          addModal({
            children: (
              <SaveModal
                flowchartId={flowchartId}
                diagramName={store.diagramName}
                onClose={() => removeModal(modalID)}
              />
            ),
            footer: false,
            header: 'Save a Process Specification',
            id: modalID,
            style: { maxWidth: '400px' },
          })
        }
        size='small'
        type='secondary'
      >
        Save
      </Button>
    </Group>
  )
}

export default ControlsBar
