import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Element, theme } from '@fortressiq/fiq-ds'
import localizer from 'lib/Localizer'
import PanelMetrics from 'components/PanelMetrics/PanelMetrics'
import { useDebounce } from 'lib/hooks'
import { fetchEventCount } from 'app/events/fetchEventCount'
import { generateFilter } from '../helpers'

const FormFooter = ({ onCancel, formValues, isValid }) => {
  const [isFetchingEvents, setIsFetchingEvents] = useState(false)
  const [eventCount, setEventCount] = useState(0)
  const debouncedFormState = useDebounce(formValues, 500)

  const updateEventCount = async filter => {
    setIsFetchingEvents(true)
    setEventCount(await fetchEventCount(filter))
    setIsFetchingEvents(false)
  }

  useEffect(() => {
    const filter = debouncedFormState?.rules ? debouncedFormState : generateFilter(debouncedFormState)
    updateEventCount(filter)
  }, [debouncedFormState])

  const handleCancel = e => {
    e.preventDefault()
    onCancel()
  }

  return (
    <Element
      style={{
        alignItems: 'center',
        borderTop: `1px solid ${theme['table-border-color']}`,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme['default-spacer-md'],
        position: 'sticky',
        bottom: 0,
      }}
    >
      <Button onClick={handleCancel}>Cancel</Button>
      <PanelMetrics
        style={{ height: 'auto' }}
        metrics={[{ title: 'Event Count', value: localizer.asCount(eventCount) }]}
      />
      <Button disabled={eventCount === 0 || !isValid} loading={isFetchingEvents}>
        Create &amp; Run
      </Button>
    </Element>
  )
}

FormFooter.defaultProps = {
  formValues: undefined,
  isValid: true,
}
FormFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}),
  isValid: PropTypes.bool,
}

export default FormFooter
