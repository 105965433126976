import { defaultDivergentScale, theme } from '@fortressiq/fiq-ds'
import FusionCharts from 'fusioncharts'
import Widgets from 'fusioncharts/fusioncharts.widgets'
import ReactFC from 'react-fusioncharts'
import Charts from 'fusioncharts/fusioncharts.charts'
import PowerCharts from 'fusioncharts/fusioncharts.powercharts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import Treemap from 'fusioncharts/fusioncharts.treemap'
import Zoomline from 'fusioncharts/fusioncharts.zoomline'
import Gantt from 'fusioncharts/fusioncharts.gantt'

FusionCharts.options.license({
  key: document.getElementById('fusion:charts_key').getAttribute('content'),
  creditLabel: false,
})

export const defaultChartConfigProps = {
  dataFormat: 'json',
  height: '100%',
  width: '100%',
}

export const zeroChartMarginPadding = {
  canvasPadding: '0',
  chartLeftMargin: '0',
  chartTopMargin: '0',
  chartRightMargin: '0',
  chartBottomMargin: '0',
}

export const defaultDataSourceChartProps = {
  alignCaptionWithCanvas: '1',
  captionHorizontalPadding: '16',
  captionOnTop: '1',
  captionAlignment: 'left',
  captionFont: 'Inter, sans-serif',
  captionFontSize: '16',
  captionFontColor: theme['text-dark'],
  captionFontBold: '0',
  paletteColors: String(defaultDivergentScale.colors(9)),
  subCaptionHorizontalPadding: '16',
  subCaptionAlignment: 'left',
  subCaptionFont: 'Inter, sans-serif',
  subCaptionFontSize: '13',
  subCaptionFontColor: theme['text-light'],
  subCaptionFontBold: '0',
  ...zeroChartMarginPadding,
  theme: 'fusion',
}

// eslint-disable-next-line import/prefer-default-export
export { Charts, FusionCharts, FusionTheme, Gantt, PowerCharts, ReactFC, Treemap, Widgets, Zoomline }
