import React, { createContext, useContext, useReducer } from 'react'

const EventsStateContext = createContext()
const EventsDispatchContext = createContext()

function eventsReducer(state, action) {
  if (action.type === 'set-export-columns') {
    return { ...state, columns: action.columns }
  }
  throw new Error(`Unhandled action type: ${action.type}`)
}

function EventsProvider({ children }) {
  const [state, dispatch] = useReducer(eventsReducer, { columns: [] })
  return (
    <EventsStateContext.Provider value={state}>
      <EventsDispatchContext.Provider value={dispatch}>{children}</EventsDispatchContext.Provider>
    </EventsStateContext.Provider>
  )
}

function useEventsState() {
  const context = useContext(EventsStateContext)
  if (context === undefined) {
    throw new Error('useEventsState must be used within a EventsProvider')
  }
  return context
}

function useEventsDispatch() {
  const context = React.useContext(EventsDispatchContext)
  if (context === undefined) {
    throw new Error('useEventsDispatch must be used within a EventsProvider')
  }
  return context
}

export { EventsDispatchContext, EventsProvider, useEventsState, useEventsDispatch }
