export const sideBarWidth = '48px'

export const appGridCSS = ({ theme }) => ({
  background: theme.white,
  display: 'flex',
  height: '100vh',
  marginLeft: sideBarWidth,
  width: `calc(100vw - ${sideBarWidth})`,
})

export const rightColCSS = {
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
}

export const appContentCSS = {
  flex: 1,
  overflow: 'auto',
}
