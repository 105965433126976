/* eslint-disable import/prefer-default-export */
import { theme } from '@fortressiq/fiq-ds'

export const sliderCSS = () => ({
  borderRadius: theme['default-spacer-sm'],
  boxSizing: 'border-box',
  height: '9px',
  margin: 'auto',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
})

export const segmentCSS = {
  cursor: 'pointer',
  display: 'inline-block',
  height: '100%',
  opacity: '0.6',
  verticalAlign: 'top',
}

export const thumbContainerCSS = () => ({
  height: '1px',
  position: 'absolute',
  top: '-3px',
  width: '100%',
})

export const thumbCSS = {
  background: theme?.white,
  border: `2px solid ${theme ? theme['legacy-color-blue2'] : 'blue'}`, //Workaround for stupid test
  borderRadius: '50%',
  cursor: 'pointer',
  height: '15px',
  left: '10px',
  position: 'absolute',
  top: '0px',
  width: '15px',
}
