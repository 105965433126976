import React, { useState, useEffect } from 'react'
import { Select, theme } from '@fortressiq/fiq-ds'
import { useFetch } from 'lib/hooks'

import { func, string, shape, bool, oneOfType, number, arrayOf } from 'prop-types'

const AsyncSelect = ({
  constructData,
  controlled,
  name,
  onChange,
  optionsValueKey,
  params,
  placeholder,
  portal,
  portalTarget,
  style,
  url,
  values,
  value,
  isDisabled,
  isMulti = true,
}) => {
  const { data = [], isLoading } = useFetch(url, params)
  const [selectOptions, setSelectOptions] = useState()
  const [selectValues, setSelectValues] = useState()

  useEffect(() => {
    if (constructData) {
      setSelectOptions(constructData(data))
    } else {
      const transformedOptions = data.map(item => ({
        label: item.label,
        value: item.value,
      }))
      setSelectOptions(transformedOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, constructData])

  useEffect(() => {
    if (selectOptions?.length && isMulti && values?.length) {
      const transformedDefaultValues = selectOptions.filter(option => values.includes(option[optionsValueKey]))
      setSelectValues(transformedDefaultValues)
    } else if (selectOptions?.length && !isMulti && value) {
      const transformedDefaultValue = selectOptions.find(option => option[optionsValueKey].toString() === value)
      setSelectValues(transformedDefaultValue)
    } else {
      setSelectValues(undefined)
    }
  }, [selectOptions, optionsValueKey, values, value, isMulti])

  const onChangeHandler = e => {
    const obj = {
      target: {
        name,
        value: e,
        optionsValueKey,
      },
    }
    if (!controlled) {
      setSelectValues(e)
    }
    onChange(obj)
  }

  const portalProps = portal
    ? {
        menuPortalTarget: document.getElementById(portalTarget) || document.body,
        menuShouldBlockScroll: true,
      }
    : {}
  const styles = {
    ...(!portal && { menu: { zIndex: theme['z-base-above'] } }),
    ...(portal && { menuPortal: { zIndex: theme['z-modal-above'] } }),
  }

  return (
    <Select
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={isMulti}
      onChange={onChangeHandler}
      options={selectOptions}
      placeholder={placeholder}
      style={style}
      styles={styles}
      value={selectValues}
      {...portalProps}
    />
  )
}

AsyncSelect.defaultProps = {
  constructData: undefined,
  controlled: true,
  optionsValueKey: 'value',
  params: undefined,
  portal: false,
  style: undefined,
  values: undefined,
}

AsyncSelect.propTypes = {
  constructData: func,
  controlled: bool,
  name: string.isRequired,
  onChange: func.isRequired,
  optionsValueKey: string,
  params: shape({}),
  placeholder: string.isRequired,
  portal: bool,
  style: shape({}),
  url: string.isRequired,
  values: arrayOf(oneOfType([string, number])),
}

export default AsyncSelect
