import React, { useState } from 'react'
import { bool } from 'prop-types'

import SidePanel from 'components/detailsSidePanel/SidePanel'
import PathStatistics from './PathStatistics'
import PathsOverview from './PathsOverview'
import StepDetails from './StepDetails'

import {
  pathsDataPropTypes,
  activePathsPropTypes,
  highlightedPathsPropTypes,
  handleHighlightPathPropTypes,
  handlePathSelectPropTypes,
  processIdPropTypes,
  graphStatsPropTypes,
  selectedStepsPropTypes,
} from '../commonPropTypes'

const Sidebar = ({
  activePaths,
  graphStats,
  handleHighlightPath,
  handlePathSelect,
  highlightedPaths,
  isLoadingPaths,
  pathsData,
  processId,
  selectedStep,
}) => {
  const [showSidePanel, setShowSidePanel] = useState(true)
  const [sidePanelActiveKey, setSidePanelActiveKey] = useState(['path-statistics', 'paths-overview', 'step-details'])

  const numberOfPathsString = activePaths?.length ? `(${activePaths?.length} displayed)` : 'Loading'
  const panels = [
    {
      header: 'Step details',
      key: 'step-details',
      panel: <StepDetails isLoadingPaths={isLoadingPaths} selectedStep={selectedStep} />,
    },
    {
      header: `Paths overview ${numberOfPathsString}`,
      key: 'paths-overview',
      panel: <PathsOverview isLoadingPaths={isLoadingPaths} graphStats={graphStats} />,
    },
    {
      header: `Path Statistics ${numberOfPathsString}`,
      key: 'path-statistics',
      panel: (
        <PathStatistics
          activePaths={activePaths}
          handleHighlightPath={handleHighlightPath}
          handlePathSelect={handlePathSelect}
          highlightedPaths={highlightedPaths}
          isLoadingPaths={isLoadingPaths}
          pathsData={pathsData}
          processId={processId}
        />
      ),
    },
  ]
  return (
    <SidePanel
      collapse={!showSidePanel}
      openKeys={sidePanelActiveKey}
      onChange={setSidePanelActiveKey}
      panels={panels}
      toggleCollapse={() => setShowSidePanel(!showSidePanel)}
    />
  )
}

Sidebar.propTypes = {
  activePaths: activePathsPropTypes,
  graphStats: graphStatsPropTypes,
  handleHighlightPath: handleHighlightPathPropTypes,
  handlePathSelect: handlePathSelectPropTypes,
  highlightedPaths: highlightedPathsPropTypes,
  isLoadingPaths: bool.isRequired,
  pathsData: pathsDataPropTypes,
  processId: processIdPropTypes,
  selectedStep: selectedStepsPropTypes,
}

export default Sidebar
