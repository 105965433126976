import React, { useContext } from 'react'

import UnredactedImageWithOption from 'components/image/UnredactedImageWithOption'

import { Context } from './Context'
import css from './eventTimeline.scss'
import { imageContainerCss } from './styles'

const Slide = ({ event: { screenshotUrl } }) => {
  const { viewScreenshot } = useContext(Context)
  return (
    <div className={css.slide} onClick={viewScreenshot}>
      <div className={css.imgWrap}>
        <UnredactedImageWithOption src={screenshotUrl} containerCss={imageContainerCss} />
      </div>
    </div>
  )
}

export default Slide
