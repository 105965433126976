import uniqueOptionsByDate from './uniqueOptionsByDate'

const constructApplicationOptions = data => {
  const applicationsArr = uniqueOptionsByDate(data)
  return applicationsArr.map(({ label, value, unstructured }) => ({
    label: unstructured ? `${label} (unstructured)` : label,
    value: label,
    id: value,
  }))
}

export default constructApplicationOptions
