import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { AdvancedCell, ApplicationIcon, ApplicationIconsList, Group, DateCell } from '@fortressiq/fiq-ds'

import ActionsContainer from './ActionsContainer'

export const NameCell = ({ value }) => (value ? <AdvancedCell>{value}</AdvancedCell> : '--')
NameCell.propTypes = {
  value: PropTypes.string.isRequired,
}

export const GeneratedOnCell = ({ value }) => <DateCell timestamp={value} />
GeneratedOnCell.propTypes = {
  value: PropTypes.string.isRequired,
}

export const SubmittedToCoECell = ({ value }) => {
  const lastOpportunity = value[value.length - 1]
  return <DateCell timestamp={lastOpportunity?.completedAt} />
}
SubmittedToCoECell.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      completedAt: PropTypes.string,
    })
  ).isRequired,
}

export const CreatedByCell = ({ value }) =>
  value ? (
    <Group gutter={6} key={value}>
      <ApplicationIcon name={value} size={28} />
      {value}
    </Group>
  ) : (
    '--'
  )
CreatedByCell.propTypes = {
  value: PropTypes.string.isRequired,
}

export const IncludesProcessesCell = ({ value: processes }) => {
  return processes.length !== 0 ? (
    <div>
      {processes.map(({ id, name }, index) => (
        <Fragment key={id}>
          {index ? ', ' : ' '}
          <Link to={`/process-explorer/${id}`}>{name}</Link>
        </Fragment>
      ))}
    </div>
  ) : (
    '--'
  )
}
IncludesProcessesCell.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    })
  ).isRequired,
}

export const ActionsCell = ({ original, handleDeleteSpec }) => (
  <ActionsContainer record={original} handleDeleteSpec={handleDeleteSpec} />
)
ActionsCell.propTypes = {
  original: PropTypes.objectOf(PropTypes.shape).isRequired,
  handleDeleteSpec: PropTypes.func.isRequired,
}

export const ApplicationsCell = ({ value: applications }) => {
  const list = applications.map(app => ({ name: app.display_name || '' }))
  return <ApplicationIconsList size={28} list={list} />
}
ApplicationsCell.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      display_name: PropTypes.string,
    })
  ).isRequired,
}
