import { addImportant } from 'helpers'
import { ellipsisCSS } from 'styles/utils'

/* eslint-disable import/prefer-default-export */
export const menuLinkCSS = ({ theme }) => ({
  alignItems: 'center',
  color: theme['legacy-color-dark'],
  display: 'flex',
  flexDireciton: 'row',
  justifyContent: 'space-bewtween',
  overflow: 'hidden',
  padding: theme['default-spacer-sm'],
  textDecoration: 'none',
  textOverflow: 'ellipsis',
  transition: `background-color ${theme['animation-speed-base']}`,
  userSelect: 'none',
  whiteSpace: 'nowrap',
  width: '100%',
  willChange: 'background-color, color',
  wordBreak: 'break-all',
  '&:hover': {
    backgroundColor: theme['toolbar-btn-hover-background'],
    color: theme['legacy-color-dark'],
    textDecoration: 'none',
  },
})

export const dropdownContainerCSS = ({ theme }) => ({
  backgroundColor: theme.white,
  borderBottomLeftRadius: theme['border-radius-base'],
  border: `1px solid ${theme['antd-card-border']}`,
  borderTop: '0',
  marginTop: theme['default-spacer-xs'],
})

export const dropdownItemCSS = ({ theme }) => ({
  '&:last-child': {
    '> a': {
      borderRadius: `0 0 ${theme['border-radius-base']} ${theme['border-radius-base']}`,
    },
  },
})

export const signOutCSS = ({ theme }) => ({
  borderTop: `1px solid ${theme['antd-card-border']}`,
  cursor: 'pointer',
  padding: theme['default-spacer-md'],
})

export const tenantWrapCSS = ({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme['heading-color'],
  borderRadius: '20px',
  display: 'flex',
  height: '23px',
  padding: addImportant('0'),
  transition: `background-color ${theme['animation-speed-base']} ease-in-out`,
  '&:hover': {
    backgroundColor: theme.black,
  },
})

export const tenantNameCSS = ({ theme }) => ({
  color: theme.white,
  display: 'block',
  fontSize: '12px',
  fontWeight: '500',
  maxWidth: '150px',
  padding: `0 ${theme['default-spacer-md']}`,
  textDecoration: 'none',
  ...ellipsisCSS(),
  '&:hover': {
    color: theme.white,
    textDecoration: 'none',
  },
})
