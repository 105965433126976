import * as d3 from 'd3'
import { theme } from '@fortressiq/fiq-ds'
import { tooltipWidth, tipArrowLeft, tipArrowRight } from './styles'
import store from './stores/ButterflyProcessStore'

const NodeRenderer = ({ nodes, svg, dragged, dragEnd, sankey, that, margins }) => {
  const dragHandler = d3.drag().on('drag', dragged).on('end', dragEnd)

  // add in the nodes
  const node = svg
    .append('g')
    .selectAll('.node')
    .data(nodes)
    .join('g')
    .attr('class', 'node')
    .attr('transform', d => {
      return `translate(${d.x},${d.y})`
    })
    .style('cursor', 'pointer')

  dragHandler(node)

  // add the rectangles for the nodes
  node
    .append('rect')
    .attr('height', d => {
      return d.dy + 12
    })
    .attr('width', sankey.nodeWidth() + 15)
    .attr('rx', 8)
    .attr('ry', 8)
    .style('fill', d => {
      let color = theme.white
      const nodeId = d.id.replace(/(_\d+)$/g, '')
      if (
        store.selectedSteps.find(key => {
          return key === nodeId
        })
      ) {
        color = theme['legacy-product-blue-light']
      }
      return color
    })
    .style('stroke', d => {
      let color = theme['gray-tints-3']
      const nodeId = d.id.replace(/(_\d+)$/g, '')
      if (store.selectedLog.nodeId === +nodeId) {
        color = theme['blue-tints-0']
      }
      return color
    })
    .style('stroke-width', d => {
      let width = '1px'
      const nodeId = d.id.replace(/(_\d+)$/g, '')
      if (store.selectedLog.nodeId === +nodeId) {
        width = '3px'
      }
      return width
    })

  // add frequency "value" for nodes
  node
    .append('text')
    .attr('x', 25)
    .attr('y', 10)
    .attr('dy', '.35em')
    .attr('fill', '#000')
    .attr('text-anchor', 'middle')
    .style('font-size', '10px')
    .style('font-family', 'sans-serif')
    .text(d => `freq.${d.value}`)

  // add in the title for the nodes
  node
    .append('text')
    .attr('class', 'nodeText')
    .attr('x', 27)
    .attr('y', 23)
    .attr('dy', '.35em')
    .attr('text-anchor', 'middle')
    .attr('transform', null)
    .attr('fill', '#000')
    .style('font-size', '9px')
    .style('font-family', 'sans-serif')
    .text(d => {
      if (d.name.length > 12) {
        return d.name.slice(0, 12).concat('...')
      }
      return d.name
    })

  node
    .append('text')
    .attr('x', 25)
    .attr('y', 35)
    .attr('dy', '.35em')
    .attr('fill', '#000')
    .attr('text-anchor', 'middle')
    .style('font-size', '9px')
    .style('font-family', 'sans-serif')
    .text(d => d.data.applications[0].application_name)

  svg
    .selectAll('.node')
    .on('click', (event, d) => {
      store.setSelectedSteps({ key: d.id.replace(/_\d$/g, ''), isShift: event.shiftKey, ...d })
    })
    .on('mouseover', (event, d) => {
      const nodeWidth = 50
      const tooltipMargin = 8

      const butterflyContainer = document.getElementById('ButterflyMainContent')
      const mouseY = d3.pointer(event, butterflyContainer)[1]
      const { right: chartRight } = butterflyContainer.getBoundingClientRect()

      const { left: nodeX } = event.currentTarget.getBoundingClientRect()

      const arrowLeftString = JSON.stringify(tipArrowLeft).replace(/,/g, ';').replace(/[{}]/g, '').replace(/"/g, '')
      const arrowRightString = JSON.stringify(tipArrowRight).replace(/,/g, ';').replace(/[{}]/g, '').replace(/"/g, '')

      const tooltipContainer = document.getElementById('ButterflyTooltip')
      if (tooltipContainer) {
        tooltipContainer.style.top = `${mouseY + margins.top / 2}px`

        let arrowStyles = arrowLeftString
        let tooltipX = nodeX
        if (nodeX >= chartRight - (margins.left + tooltipWidth)) {
          tooltipX -= nodeWidth + tooltipWidth + tooltipMargin
          arrowStyles = arrowRightString
        }

        tooltipContainer.style.left = `${tooltipX}px`
        tooltipContainer.style.visibility = 'visible'

        const tipHTML =
          `<div><p>Step Screen Signature: ${d.name}</p>` +
          `<p>Application: ${d.data.applications[0].application_name}</p></div>` +
          `<i style='color: ${theme['dark-contrast']}'>` +
          'shift-click step for more details (shown in side panel)</i>' +
          `<div style='${arrowStyles}'></div>`
        tooltipContainer.innerHTML = tipHTML
      }
    })
    .on('mouseout', () => {
      const tooltipContainer = document.getElementById('ButterflyTooltip')

      if (tooltipContainer) {
        tooltipContainer.style.visibility = 'hidden'
      }
    })

  return node
}

export default NodeRenderer
