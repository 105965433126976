import React from 'react'
import { string, func, shape, oneOfType, bool } from 'prop-types'
import { EditableLabel, Typography, theme } from '@fortressiq/fiq-ds'

const { Text } = Typography

const TextToInput = ({ name, value, onEnter, emptyText, textStyle = {}, tooltipProps }) => (
  <EditableLabel
    value={value}
    onUpdate={({ target: { value: newValue } }) => onEnter(name, newValue)}
    inputProps={{
      containerProps: { style: { width: '100%' } },
    }}
    tooltipProps={tooltipProps}
    render={({ children }) => {
      return (
        <Text
          ellipsis={true}
          as='span'
          style={{
            ...textStyle,
            ...(!children
              ? {
                  display: 'block',
                  fontStyle: 'italic',
                  color: theme['typography-disabled-color'],
                }
              : {}),
          }}
        >
          {children || emptyText || 'Empty'}
        </Text>
      )
    }}
  />
)

TextToInput.propTypes = {
  name: string.isRequired,
  emptyText: string,
  value: string.isRequired,
  onEnter: func.isRequired,
  textStyle: oneOfType([shape, bool]),
}

export default TextToInput
