/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { Fragment, useMemo, useRef, useState } from 'react'
import {
  Alert,
  Button,
  ButtonGroup,
  Checkbox,
  Element,
  Group,
  Label,
  Table,
  theme,
  Typography,
  useFela,
} from '@fortressiq/fiq-ds'
import { useList, useMap } from 'react-use'
import { arrayOf, func, object, string } from 'prop-types'

import api from 'lib/Api'

import callbackStore from 'stores/callback'
import FlexContainer from 'components/containers/FlexContainer'

import { overlayTitleCSS, suggestedWordsCSS } from '../../styles'

const { Paragraph, Title } = Typography

export const defaultParams = {
  punctuation: false,
  numbers: false,
  stopwords: false,
  entities: false,
  use_ocr_text: false,
}
export const defaultParamValues = ['punctuation', 'numbers', 'stopwords', 'entities', 'use_ocr_text']

// Table Setup:
const columns = [
  {
    accessor: 'gram',
    Header: 'NGram',
  },
  {
    accessor: 'count',
    align: 'center',
    Header: 'Count',
    width: 40,
  },
  {
    accessor: 'type',
    Header: 'Type',
    width: 60,
  },
]
const tableStyles = {
  paginationSelectDropdown: { zIndex: 2001 },
  table: {
    tr: {
      cursor: 'pointer',
      '&:hover': { '&:not(.fiqds-table-tr-selected)': { backgroundColor: theme['nav-btn-hover-bg'] } },
    },
  },
}

const NGramOverlay = ({
  id: htmlID,
  ngramProps,
  params = defaultParams,
  paramsValues = defaultParamValues,
  setFindingWords,
  setShowing,
  handleUpdateValue,
  applicationId,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [selected, setSelected] = useState([])
  const tableRef = useRef(null)
  const callbackId = 'ngramPayload'
  // Setup Parameters:

  const [map, { setAll }] = useMap(params)
  const [tableData, { clear, set }] = useList([])

  const updateTableData = data => {
    set(data)
    setIsError(false)
    setIsLoading(false)
    setShowTable(true)
  }

  const getData = data => {
    if (data?.payload.length > 0) {
      updateTableData(data.payload)
    } else {
      setIsLoading(false)
      setIsError(true)
    }
  }

  const getDataOnClick = async () => {
    clear()
    setIsError(false)
    setIsLoading(true)
    const { data } = await api.post(`/applications/${applicationId}/suggested_allow_lists`, map, 'PUT')

    if (data.length > 0) {
      updateTableData(data)
    }

    callbackStore.add(getData, callbackId, true)
  }

  const memoData = useMemo(() => (showTable ? tableData : []), [showTable, tableData])

  // Setup event handlers:
  const onParamCheckHandler = (checked, value) => {
    const updatedMap = { ...map, [`${value}`]: checked }

    return setAll(updatedMap)
  }

  const handleOnRowClick = item =>
    setSelected(prevState => (prevState.includes(item) ? prevState.filter(i => i !== item) : [...prevState, item]))

  const closing = () => {
    setShowing(false)
    setFindingWords(false)
  }

  const onSelectAllHandler = () => {
    const all = tableData.map(item => item.gram)
    const strings = getStrings(all)

    handleUpdateValue(strings)

    closing()
  }

  const onSubmitHandler = () => {
    const strings = getStrings(selected)

    handleUpdateValue(strings)
    closing()
  }

  // Misc Setup:
  const { css } = useFela({ isError, showTable })
  const tableRowSelection = {
    selectedRowColor: theme['nav-btn-selected-bg'],
    selectedRowKey: 'gram',
    selectedRows: selected,
  }
  const buttonTextModifier = selected.length !== 1 ? 's' : ''
  const buttonText = (
    <Fragment>
      Add <strong>{selected.length}</strong> suggestion{buttonTextModifier}
    </Fragment>
  )

  return (
    <Element id={htmlID} {...props} {...ngramProps}>
      <Title level={2} style={overlayTitleCSS}>
        Allow List Find Suggested Words
      </Title>
      <FlexContainer padded={true} style={{ overflow: 'auto' }}>
        <Label>Parameters</Label>
        <Paragraph style={{ color: theme['text-light'], fontWeight: 300, whiteSpace: 'break-spaces' }}>
          Create n-gram frequencies from title or ocr text. A checked value for punctuation, number, stopwords, or
          entities will remove those values.
        </Paragraph>
        <Group noEndGutter={true} justify='space-between'>
          {paramsValues.map((v, i) => {
            const checkboxID = `ngram-checkbox-${i}`
            const label = v?.includes('_') ? 'Use OCR Text' : v

            return (
              <Checkbox
                checked={map[v]}
                id={checkboxID}
                key={checkboxID}
                onCheck={onParamCheckHandler}
                style={{ textTransform: 'capitalize' }}
                value={v}
              >
                {label}
              </Checkbox>
            )
          })}
        </Group>
        <FlexContainer end={true}>
          <Button disabled={isLoading} loading={isLoading} onClick={getDataOnClick} type='secondary'>
            Request{isLoading ? 'ing' : ''} Suggested Words
          </Button>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer className={css(suggestedWordsCSS)} padded={true}>
        {!isError ? (
          <Fragment>
            <Label>Suggested Words</Label>
            <Table
              columns={columns}
              data={memoData}
              initialState={{ pageSize: 5 }}
              loading={isLoading}
              onRowClick={({ gram }) => handleOnRowClick(gram)}
              ref={tableRef}
              rowSelection={tableRowSelection}
              styles={tableStyles}
            />
            <FlexContainer
              as={ButtonGroup}
              end={true}
              style={{
                background: theme.white,
                bottom: 0,
                margin: 0,
                padding: `${theme['default-spacer-sm']} 0`,
                position: 'sticky',
              }}
            >
              <Button onClick={onSelectAllHandler} type='primary'>
                Add all suggestions
              </Button>
              <Button disabled={selected.length === 0} onClick={onSubmitHandler} type='primary'>
                {buttonText}
              </Button>
            </FlexContainer>
          </Fragment>
        ) : (
          <Alert
            description='Please try different parameters.'
            message='No suggested words found'
            style={{ margin: '0 auto' }}
            type='error'
          />
        )}
      </FlexContainer>
    </Element>
  )
}

const getStrings = strings => `["${strings.toString().split(',').join('","')}"]`

NGramOverlay.propTypes = {
  id: string.isRequired,
  params: object,
  paramsValues: arrayOf(string),
  setShowing: func.isRequired,
  handleUpdateValue: func.isRequired,
}

export default NGramOverlay
