import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Input, Select, useNotification } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import { useFetch } from 'lib/hooks'
import { ControlRow, ControlBlock } from './ControlHelpers'
import { loadLimit as limit } from '../common'

const formatScreenOptions = (screens, screen) =>
  screens.reduce(
    (acc, s) => {
      if (s.signature !== screen.signature) {
        acc.push({
          label: `${s.name || s.signature} (${s.count})`,
          value: s.signature,
        })
      }
      return acc
    },
    [{ label: 'New Screen', value: 'new' }]
  )

const EventMoveSelector = ({
  applicationId,
  screen,
  oldScores,
  oldSignature,
  eventGroupCount,
  removeSelectedEventGroups,
  tenantApplicationId,
}) => {
  const history = useHistory()
  const selectedEventGroupCount = oldScores.length
  const [selectedScreenOption, setSelectedScreenOption] = useState(null)
  const [newScreenName, setNewScreenName] = useState('')
  const { addNotification } = useNotification()

  const {
    data = { screens: [] },
    isLoading,
    reFetch: reloadScreens,
  } = useFetch(`/applications/${tenantApplicationId}/screens`, { applicationUuid: tenantApplicationId, ...limit })

  const handleEventGroupMove = async () => {
    let newSignature = selectedScreenOption.value

    if (newSignature === 'new') {
      newSignature = `${applicationId}_${newScreenName}`

      await api.post(`/applications/${tenantApplicationId}/screens`, {
        screen: { name: newScreenName, signature: newSignature },
        applicationUuid: tenantApplicationId,
      })

      addNotification({
        cta: (
          <Button
            onClick={() => history.push(`/applications/${tenantApplicationId}/screens/${newScreenName}`)}
            size='small'
          >
            View Screen
          </Button>
        ),
        description: `${newScreenName} has been created successfully!`,
        message: 'Success',
        type: 'success',
      })
    }

    const params = {
      oldScores,
      oldSignature,
      applicationUuid: tenantApplicationId,
    }
    await api.post(`/applications/${tenantApplicationId}/screens/${newSignature}/apply_to_events`, params)
    setSelectedScreenOption(null)
    removeSelectedEventGroups()
    reloadScreens()

    if (!(eventGroupCount - oldScores.length > 0)) {
      history.push(`/applications/${tenantApplicationId}/screens/${newSignature}`)
    }
  }

  const screenOptions = formatScreenOptions(data.screens, screen)

  return (
    <ControlBlock>
      <ControlRow>
        <Col span={{ xs: 4, sm: 4, md: 4 }}>Move to:&nbsp;</Col>
        <Col span={{ xs: 8, sm: 8, md: 8 }}>
          <Select
            dark={true}
            options={screenOptions}
            onChange={e => setSelectedScreenOption(e)}
            placeholder='Screen'
            size='small'
            isLoading={isLoading}
            style={{ minWidth: '172px' }}
            value={selectedScreenOption}
          />
        </Col>
      </ControlRow>
      {selectedScreenOption?.value === 'new' && (
        <ControlRow>
          <Col span={{ xs: 4, sm: 4, md: 4 }}>New Name: </Col>
          <Col span={{ xs: 8, sm: 8, md: 8 }}>
            <Input
              autoFocus={true}
              name='newScreenName'
              onChange={({ target: { value } }) => setNewScreenName(value)}
              size='small'
              value={newScreenName}
            />
          </Col>
        </ControlRow>
      )}
      <ControlRow>
        <Col span={{ xs: 4, sm: 4, md: 4 }}>&nbsp;</Col>
        <Col span={{ xs: 8, sm: 8, md: 8 }}>
          <Button
            disabled={!selectedEventGroupCount || !selectedScreenOption}
            onClick={handleEventGroupMove}
            size='small'
            type='secondary'
          >
            Move {selectedEventGroupCount} Event Groups
          </Button>
        </Col>
      </ControlRow>
    </ControlBlock>
  )
}

export default EventMoveSelector
