//dashboardStore.js
import { observable, action } from 'mobx'

//for now we will have a list of available visualization here
//hard coded
const SPENDING_ALL = { fte: true, dollars: true, processFTE: true, processDollars: true, topApps: true }
const EVENTS_ALL = { upcoming: true, observed: true, latestRuns: true }
const CALENDAR_ALL = { calendar: true }

class DashboardStore {
  @observable engageVis

  @observable spendingVis

  @observable eventsVis

  @observable calendarVis

  constructor() {
    this.spendingVis = SPENDING_ALL
    this.eventsVis = EVENTS_ALL
    this.calendarVis = CALENDAR_ALL
  }

  @action
  addVis(name, type) {
    this[`${type.toLowerCase()}Vis`][name] = true
  }

  @action
  removeVis(name, type) {
    this[`${type.toLowerCase()}Vis`][name] = false
  }
}

export default new DashboardStore()
