import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useNotification, useForm, Button, Col, Input, Row } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import FieldWrapper from 'components/form/FieldWrapper'
import ControlledSelect from 'components/form/ControlledSelect'

import { useUserState } from 'context/UserContext'
import { useHeaderDispatch } from '../../header/HeaderContext'
import { gutter, topMarginStyle } from '../common/constants'
import SwitchSection from './SwitchSection'

import { TENANT_TYPES } from './constants'

const FormRowDouble = ({ left, right }) => (
  <Row gutter={gutter} type='grid' noOfCols={2} style={topMarginStyle}>
    {left}
    {right}
  </Row>
)

const FormRowSingle = ({ children }) => (
  <Row gutter={gutter} type='grid' noOfCols={1} style={topMarginStyle}>
    {children}
  </Row>
)

const FormHeader = ({ register, formState }) => {
  return (
    <FieldWrapper label='Tenant Name' htmlFor='tenant_name' error={formState.errors.tenant_name}>
      <Input {...register('tenant_name', { required: 'Tenant Name is required.' })} />
    </FieldWrapper>
  )
}

const pageTitle = 'Create a Tenant'
const defaultFormState = {
  tenant_name: '',
  tenant_type: TENANT_TYPES[0]['value'],
  username: '',
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  is_lite_customer: false,
}

const TenantCreator = () => {
  const { addNotification } = useNotification()
  const history = useHistory()
  const { roles } = useUserState()
  const { register, handleSubmit, control, formState, setValue } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultFormState,
  })

  const onSwitchFiqLite = ({ SWITCH_STATE }) => setValue('is_lite_customer', SWITCH_STATE)
  const headerDispatch = useHeaderDispatch()

  useEffect(() => {
    headerDispatch({
      type: 'set',
      heading: pageTitle,
      title: pageTitle,
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  const onSave = async values => {
    const { data } = await api.post('/tenants', values)
    if (data.ok) {
      addNotification({
        description: `${values.tenant_name} successfully created.`,
        message: 'Success',
        type: 'success',
      })
    }
    history.push('/admin/tenants')
  }

  return (
    <form style={{ maxWidth: '800px', margin: '24px 18px' }} onSubmit={handleSubmit(onSave)}>
      <FormHeader register={register} formState={formState} />

      <FieldWrapper label='Tenant Type' htmlFor='tenant_type' error={formState?.errors?.tenant_type}>
        <ControlledSelect
          control={control}
          isDisabled={!roles.includes('superuser')}
          isMulti={false}
          name='tenant_type'
          options={TENANT_TYPES}
          portal={true}
          rules={{ required: 'Tenant Type is required' }}
          constructSelectedValue={true}
        />
      </FieldWrapper>

      <FieldWrapper label='Admin Username' htmlFor='username' error={formState?.errors?.username}>
        <Input type='text' {...register('username', { required: 'Admin Username is required.' })} />
      </FieldWrapper>
      <FormRowDouble
        left={
          <FieldWrapper label='Admin Email' htmlFor='email' error={formState?.errors?.email}>
            <Input type='email' {...register('email', { required: 'Admin Email is required.' })} />
          </FieldWrapper>
        }
        right={
          <FieldWrapper label='Admin Password' htmlFor='password' error={formState?.errors?.password}>
            <Input type='password' {...register('password', { required: 'Admin Password is required.' })} />
          </FieldWrapper>
        }
      />
      <FormRowDouble
        left={
          <FieldWrapper label='Admin First Name' htmlFor='first_name' error={formState?.errors?.first_name}>
            <Input {...register('first_name', { required: 'Admin First Name is required.' })} />
          </FieldWrapper>
        }
        right={
          <FieldWrapper label='Admin Last Name' htmlFor='last_name' error={formState?.errors?.last_name}>
            <Input {...register('last_name', { required: 'Admin Last Name is required.' })} />
          </FieldWrapper>
        }
      />
      <SwitchSection label='FIQ Lite' id='fiqLiteSwitch' onSwitch={onSwitchFiqLite} />
      <Row gutter={gutter} type='flex' noOfCols={1} style={topMarginStyle}>
        <Col>
          <Button type='secondary'>Save</Button>
        </Col>
      </Row>
    </form>
  )
}

export default TenantCreator
