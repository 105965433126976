/*********************
 * renders node data
 *
 **********************/
import * as d3 from 'd3'
import { theme } from '@fortressiq/fiq-ds'

import sourceTypes from 'components/dragAndDrop/sourceTypes'

import SVGDropZoneStore from 'components/dragAndDrop/SVGDropZoneStore'

import ActivityNode from './nodes/ActivityNode'
import CollapsedNode from './nodes/CollapsedNode'
import DecisionNode from './nodes/DecisionNode'
import EndNode from './nodes/EndNode'

import store from '../../stores/processExplorerStore'
import subprocessStore from '../../stores/subprocessStore'

export default ({ node, duration, nodeEnter, nodeUpdate, iconWidth, nodeActions }) => {
  const dragHandler = d3.drag().on('drag', dragged).on('end', dragEnd)

  nodeEnter.on('click', (event, d) => nodeActions(event, d))

  const collapsedNodes = nodeEnter.filter(
    d => (d.data.activities.length > 1 && d.data.type !== 'decision') || d.data.type === 'end'
  )
  CollapsedNode({
    nodeEnter: collapsedNodes,
    iconWidth: iconWidth,
  })

  const regularNodes = nodeEnter.filter(
    d => d.data.activities.length < 2 && d.data.type !== 'decision' && d.data.type !== 'end'
  )

  dragHandler(regularNodes)

  ActivityNode({
    nodeEnter: regularNodes,
    iconWidth: iconWidth,
  })

  const decisionNodes = nodeEnter.filter(d => d.data.type === 'decision')
  DecisionNode({
    nodeEnter: decisionNodes,
  })

  const endNodes = nodeEnter.filter(d => d.data.type === 'end')
  EndNode({
    nodeEnter: endNodes,
    iconWidth: iconWidth,
  })

  // /*** node update ***/
  nodeUpdate.selectAll('.viewingDot').style('fill', d => {
    return store.viewingNode && store.viewingNode.id === d.id ? theme.black : 'transparent'
  })

  nodeUpdate.select('image.add').style('opacity', d => {
    if (![null, undefined].includes(d.hiddenChildren) && d.hiddenChildren.length > 0) {
      return '1'
    }
    return '.1'
  })

  nodeUpdate.select('image.remove').style('opacity', d => {
    if (![null, undefined].includes(d.children) && d.children.length > 0) {
      return '1'
    }
    return '.1'
  })

  nodeUpdate.select('image.addAll').style('display', d => {
    if (![null, undefined].includes(d.hiddenChildren) && d.hiddenChildren.length > 0) {
      return 'inline'
    }
    return 'none'
  })

  nodeUpdate.select('image.collapseNode').style('display', d => {
    if (d.parent) {
      return 'inline'
    }
    return 'none'
  })

  nodeUpdate.select('.endNode').text(d => {
    return d.pathName || d.pathKey
  })

  nodeUpdate.select('.nodeDurationInfo').text(d => {
    return d.hideDuration ? '--' : d.pathDuration
  })

  nodeUpdate.select('.eventFOhtml').html(d => {
    return d.isRequired === true
      ? `<Element as='span' style=={{ color: theme['legacy-color-red'] }}>*</Element> <span>${d.data.name}</span>`
      : d.data.name
  })

  // Remove any exiting nodes
  const nodeExit = node.exit()

  nodeExit.transition().duration(duration).style('opacity', 0).remove()

  // On exit reduce the node circles size to 0
  nodeExit.select('rect').attr('r', 0)

  // On exit reduce the opacity of text labels
  nodeExit.select('text').style('fill-opacity', 0)

  /////////////////////////////
  ////// drag events

  //Called when the drag event occurs (object should be moved)
  //we actully want to get some sort of 'copy' of the node and animate that,
  //not move the node itself
  //the thing is that we need to implement the same drag for the
  //DOM object too in case the user 'dropped' it and decides to continue the drag withthe DOM object
  function dragged(d) {
    if (!store.groups.groupsMode && !subprocessStore.subprocessMode) return
    const dragContainer = document.getElementById('dragContainer')
    SVGDropZoneStore.draggingNode = true
    SVGDropZoneStore.draggingType = sourceTypes.NODE
    SVGDropZoneStore.draggingDetails = d.subject

    const offset = 100 //not sure what causes this offset but seems to work across use cases
    SVGDropZoneStore.draggingNodeX = d.sourceEvent.pageX - offset
    SVGDropZoneStore.draggingNodeY = d.sourceEvent.pageY - offset
  }

  function dragEnd(d) {
    if (SVGDropZoneStore.inDropZone.get('groupStart')) {
      store.groups.activeGroup.startNode = d.subject
      store.groups.handleNodeDrop()
    }
    if (SVGDropZoneStore.inDropZone.get('groupEnd')) {
      store.groups.activeGroup.endNode = d.subject
      store.groups.handleNodeDrop()
    }
    if (SVGDropZoneStore.inDropZone.get('subprocessStart')) {
      subprocessStore.setActiveStartNode(d.subject)
    }
    if (SVGDropZoneStore.inDropZone.get('subprocessEnd')) {
      subprocessStore.setActiveEndNodes(d.subject)
    }
    SVGDropZoneStore.draggingNode = false
    SVGDropZoneStore.draggingType = null
  }
}

const setDraggable = () => {
  // draggable is appended when subprocess and group modes are on
  return this.classList.contains('draggable')
}
