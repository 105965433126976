//jointJS actions that initialize event handlers for the diagram composer
import rappid from 'vendor/rappid.js'

import { handles } from './config/config_halo'

import store from './store/DiagramComposerStore'

export const initInspector = () => {
  store.paper.on('element:pointerclick', elementView => store.updateViewing(elementView))
  store.paper.on('link:pointerup', elementView => store.updateViewing(elementView))
}

export const initHalo = () => {
  store.paper.on('element:pointerclick', elementView => {
    if (!elementView.model.hasPorts()) {
      // Only shapes without ports will have the "link" handle in the Halo control panel.
      // Shapes with ports can be connected by "dragging" ports.
      handles.push({
        name: 'link',
        position: 'e',
        events: { pointerdown: 'startLinking', pointermove: 'doLink', pointerup: 'stopLinking' },
      })
    }
    const halo = new rappid.ui.Halo({
      cellView: elementView,
      handles: handles,
    }).render()

    halo.on('action:openInNewTab:pointerdown', event => handleOpenInNewTab(event, elementView.model))

    const freeTransform = new rappid.ui.FreeTransform({ cellView: elementView, allowRotation: false })
    freeTransform.render()
  })
}

const handleOpenInNewTab = async (event, model) => {
  event.stopPropagation()
  window.open(`/diagrams/new?graph_process_id=${model.id}`)
}

export const initLinks = () => {
  store.paper.on(
    {
      'element:pointerup': elementView => {
        const element = elementView.model
        if (store.selection.collection.contains(element)) return
        new rappid.ui.FreeTransform({
          cellView: elementView,
          allowRotation: false,
          preserveAspectRatio: !!element.get('preserveAspectRatio'),
          allowOrthogonalResize: element.get('allowOrthogonalResize') !== false,
        }).render()

        new rappid.ui.Halo({
          cellView: elementView,
          handles: handles,
        }).render()

        store.selection.collection.reset([])
        store.selection.collection.add(element, { silent: true })
        store.paper.removeTools()
      },
      'link:pointerup': linkView => {
        const link = linkView.model
        const ns = rappid.linkTools
        const toolsView = new rappid.dia.ToolsView({
          name: 'link-pointerdown',
          tools: [
            new ns.Vertices(),
            new ns.SourceAnchor(),
            new ns.TargetAnchor(),
            new ns.SourceArrowhead(),
            new ns.TargetArrowhead(),
            new ns.Segments(),
            new ns.Boundary({ padding: 15 }),
            new ns.Remove({ offset: -20, distance: 40 }),
          ],
        })

        store.selection.collection.reset([])
        store.selection.collection.add(link, { silent: true })

        const { paper } = store
        rappid.ui.Halo.clear(paper)
        rappid.ui.FreeTransform.clear(paper)
        paper.removeTools()
        linkView.addTools(toolsView)
      },

      'link:mouseenter': linkView => {
        // Open tool only if there is none yet
        if (linkView.hasTools()) return

        const ns = rappid.linkTools
        const toolsView = new rappid.dia.ToolsView({
          name: 'link-hover',
          tools: [new ns.Vertices({ vertexAdding: false }), new ns.SourceArrowhead(), new ns.TargetArrowhead()],
        })
        linkView.addTools(toolsView)
      },

      'link:mouseleave': linkView => {
        // Remove only the hover tool, not the pointerdown tool
        if (linkView.hasTools('link-hover')) {
          linkView.removeTools()
        }
      },
    },
    store
  )
}
