import React, { Fragment } from 'react'
import { arrayOf, bool, func, number, string, shape } from 'prop-types'
import { Col, Fieldset, Icon, Label, Row, Switch, theme } from '@fortressiq/fiq-ds'

import { LIST_CATEGORIES } from './Constants'

import AllowBlockListSection from './AllowBlockListSection'

import { captureIconSwitchCSS, sharedColStyle, sharedLabelStyle, sharedSwitchStyle } from './styles'

const ConfigureObserver = ({
  screenshotAllowed,
  titleAllowed,
  keystrokeAllowed,
  clipboardAllowed,
  urlLists,
  urlListValue,
  urlListAccessType,
  accessTypeOnChange,
  applicationLists,
  applicationListValue,
  applicationListAccessType,
  onChange,
  styled = true,
}) => {
  const sharedIconProps = {
    size: 15,
    style: captureIconSwitchCSS,
  }
  const sm = theme['default-spacer-sm']

  const fieldsetStyle = styled
    ? { background: theme['contrast-background-color'] }
    : {
        borderColor: theme['btn-borderAlpha'],
        padding: `${theme['default-spacer-sm']} ${theme['default-spacer-md']} ${theme['default-spacer-sm']}`,
      }
  const legendStyle = {
    color: theme['btn-textColor'],
    display: 'flex',
    fontSize: '12px',
    fontWeight: 600,
    padding: `0 ${theme['default-spacer-sm']}`,
  }
  return (
    <Fragment>
      <Fieldset legend='Capture' legendStyle={legendStyle} style={fieldsetStyle}>
        <Row gutter={16} style={{ marginBottom: sm }}>
          <Col style={sharedColStyle}>
            <Label htmlFor='screenshots' style={sharedLabelStyle}>
              <Icon icon='paginatefilterpicture' {...sharedIconProps} />
              Screenshots
            </Label>
            <Switch
              checked={screenshotAllowed}
              id='screenshots'
              onSwitch={({ SWITCH_STATE }) => onChange('screenshotAllowed', SWITCH_STATE)}
              style={sharedSwitchStyle}
            />
          </Col>
          <Col style={sharedColStyle}>
            <Label htmlFor='keystrokes' style={sharedLabelStyle}>
              <Icon icon='typing' {...sharedIconProps} /> Keystrokes
            </Label>
            <Switch
              checked={keystrokeAllowed}
              id='keystrokes'
              onSwitch={({ SWITCH_STATE }) => onChange('keystrokeAllowed', SWITCH_STATE)}
              style={sharedSwitchStyle}
            />
          </Col>
          <Col style={sharedColStyle}>
            <Label htmlFor='title' style={sharedLabelStyle}>
              <Icon icon='appwindowtype' {...sharedIconProps} />
              Title
            </Label>
            <Switch
              checked={titleAllowed}
              id='title'
              onSwitch={({ SWITCH_STATE }) => onChange('titleAllowed', SWITCH_STATE)}
              style={sharedSwitchStyle}
            />
          </Col>
          <Col style={sharedColStyle}>
            <Label htmlFor='clipboard' style={sharedLabelStyle}>
              <Icon icon='taskchecklist' {...sharedIconProps} />
              Clipboard
            </Label>
            <Switch
              checked={clipboardAllowed}
              id='clipboard'
              onSwitch={({ SWITCH_STATE }) => onChange('clipboardAllowed', SWITCH_STATE)}
              style={sharedSwitchStyle}
            />
          </Col>
        </Row>
      </Fieldset>
      <AllowBlockListSection
        lists={urlLists}
        accessValue={urlListAccessType}
        accessTypeOnChange={accessTypeOnChange}
        accessTypeCategory={LIST_CATEGORIES.URL}
        selectValue={urlListValue}
        selectOnChange={onChange}
      />
      <AllowBlockListSection
        lists={applicationLists}
        accessValue={applicationListAccessType}
        accessTypeOnChange={accessTypeOnChange}
        accessTypeCategory={LIST_CATEGORIES.APPLICATION}
        selectValue={applicationListValue}
        selectOnChange={onChange}
        style={{ marginTop: sm }}
      />
    </Fragment>
  )
}

ConfigureObserver.propTypes = {
  screenshotAllowed: bool.isRequired,
  titleAllowed: bool.isRequired,
  keystrokeAllowed: bool.isRequired,
  clipboardAllowed: bool.isRequired,
  urlLists: arrayOf(shape()).isRequired,
  urlListValue: number,
  urlListAccessType: string.isRequired,
  accessTypeOnChange: func.isRequired,
  applicationLists: arrayOf(shape()).isRequired,
  applicationListValue: number,
  applicationListAccessType: string.isRequired,
  onChange: func.isRequired,
  styled: bool,
}

export default ConfigureObserver
