import React from 'react'
import css from './subprocessModalStyle.scss'

class SavedSubprocessDialogue extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className={css.saved}>Subprocess Successfully Saved</div>
        <div onClick={this.openNewProcess} className={css.viewNew}>
          view new subprocess
        </div>
      </React.Fragment>
    )
  }

  openNewProcess = () => {
    const { savedSubprocessId } = this.props
    window.open(`/process-explorer/${savedSubprocessId}`)
  }
}

export default SavedSubprocessDialogue
