import React from 'react'
import css from './menuItem.scss'

const DecisionMenuItem = ({ onClick }) => (
  <div className={css.row} onClick={onClick}>
    <div className={css.shape}>
      <svg width={20} height={13}>
        <path d='M0 6 L10 12 L20 6 L10 0Z' fill='#8995ad' stroke='transparent' />
      </svg>
    </div>
    <div className={css.name}>Decision Point</div>
    <div className={css.steps} />
  </div>
)

export default DecisionMenuItem
